import React, { useState } from "react";
import { getUser, isUserPermission } from "../../services/auth.service";
import {
  getDarInfoResponse,
  GetDarInfo_Query,
  PersonInfo,
  ProfileReportPersonInfo,
} from "../../interfaces/getDarInfo_interface";
import { Account } from "../../interfaces/auth_interface";
import { useLocation } from "react-router-dom";
import { RunDarQuery } from "../../services/getDar.service";
import { JAOS } from "../../services/JAOS.service";
import { handleSearchTime } from "../../services/formatDate.service";
import SearchResults from "../../components/njport/volunteerhire/volunteerSearchResult";
import InvestigationReportSearch from "../../components/njport/volunteerhire/volunteerReportSearch";

import { searchInvestigation, searchVolunteerHire } from "../../services/njport.service";
import { VolunteerHireObject, VolunteerHireQuery, VolunteerHirenResponse } from "../../interfaces/NJPORT/VolunteerHire.interface";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function VolunteerHire() {
  let [darTbl, setDarTbl] = useState<VolunteerHireObject[]>();
  let [user, setUser] = useState<Account>(getUser());
  let query = useQuery();
  let [personData, setPersonData] = useState<any>(null);
  const searchRef = React.useRef<any>(null);
  let [noData, setNoData] = useState<boolean>(false);
  let [initialBuild, setInitialBuild] = useState<boolean>(false);

  React.useEffect(() => {
    if (!initialBuild) {
      setInitialBuild(true);
      checkPersonData();
      localStorage.setItem('voluntercreate', 'false');
    }
  }, []);

  const checkPersonData = () => {
    let person = query.get("person");
    let data = {};
    if (person) {
      let l = new JAOS();
      data = l.objFromStack(person);
      l.clearStack();
    }
    if (Object.keys(data).length > 0) {
      setPersonData(data);
    }
    return true;
  };

  const SubmitSearch = async (params: VolunteerHireQuery) => {
    let search_query: VolunteerHireQuery = {
      queryType: "VOLUNTEERHIRE",
      dateTypeValue: params.dateTypeValue ? params.dateTypeValue : 0,
      dateRange: true,
      fromDate: params.fromDate,
      toDate: params.toDate,
    };
    let result: VolunteerHirenResponse = await searchVolunteerHire(search_query);
    console.log(result);
    if (result && result.AnyTable && result.AnyTable.length > 0) {
      setDarTbl(result.AnyTable);
    } else {
      setDarTbl([]);
    }
  };

  const setStatusValue = (options: any[]) => {
    options.forEach((option: string, idx, array) => {
      switch (option) {
        case "In Progress":
          array[idx] = "InProgress";
          break;
        case "For Review":
          array[idx] = "ForReview";
          break;
        default:
          break;
      }
    });

    return options;
  };

  const handleSubmit = async () => {
    if (searchRef.current) {
      searchRef.current?.handleSubmit();
    }
  };

  return (
      <div id="ToolsArrest">
          {isUserPermission('Arrest') ? (
        <>
          <InvestigationReportSearch SubmitSearch={SubmitSearch} />
          <SearchResults
            handleSubmit={handleSubmit}
            data={darTbl}
            Persons={personData}
            SubmitSearch={SubmitSearch}
          />
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default VolunteerHire;
