import React, {useEffect} from 'react'

// @ts-ignore
function PinTimer({countdown}) {
    const [pinTimer, setPinTimer] = React.useState("");

    useEffect(() => {
        var mounted = true;
        var countDownTime = countdown;

        var x = setInterval(function () {
            var distance = countDownTime - 1;
            countDownTime = distance;
            
            var minutes = Math.floor(distance / 60);
            var seconds = distance % 60
            
            if(mounted){
                setPinTimer("PIN Expiration > " + minutes + " : " + (seconds < 10 ? '0' : '') + seconds)
            }

            if(countDownTime <= 0)
            {
                clearInterval(x)
                window.location.reload();
            }

        }, 1000);

        return() => {
            clearInterval(x)
        }
    }, []);

    return (
        <div>{pinTimer}</div>
    )
}

export default PinTimer
