import React from "react";
import { useLocation } from "react-router-dom";
import NewUser from "../../components/Admin/NewUser";
import UpdateUser from "../../components/Admin/UpdateUser";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function ModifyUser() {
    let query = useQuery();

    return (
        <div>
            {/* <UpdateUser ID={query.get("UniqueID")} CloseBox={CloseBox} EditStatus={"PartialEdit"}/>*/}
            <NewUser ID={query.get("UniqueID")} />
        </div>
    );
}

export default ModifyUser;