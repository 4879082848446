import React from "react";
import { CarjackFileOrImage, CarjackNotificationsAdditionalInfoObject, CarjackNotificationsDetailsObject, CarjackNotificationsIncidentObject, CarjackNotificationsObject, CarjackNotificationsSubjectObject, CarjackNotificationsSuspectObject, CarjackNotificationsVehicleDetailsObject, CarjackNotificationsWeaponObject } from "../../../interfaces/CarjackInfo.interface";
import { Alert, Autocomplete, Button, ButtonGroup, Checkbox, FormControlLabel, IconButton, Snackbar, TextField, Tooltip } from "@mui/material";
import htmlToDraft from "html-to-draftjs";
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from "draftjs-to-html";
import { EditorState, convertFromHTML, ContentState } from "draft-js";
import { getBTCarjackIncidentTypeList, getBTIncidentTypeList, getCityFromStateCounty, getCounty, getDropDownQuery, getIWW_Dissemination, getNJPOPCityFromStateCounty, getNJTraceCaliberList, getNJTraceWeaponMakeList } from "../../../services/getDropdown.service";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DeleteIcon from '@mui/icons-material/Delete';
import WeaponAddIcon from '@mui/icons-material/AddCircle';  //"../../../assets/Images/WeaponTrace.png";
//@ts-ignore
import TimeField from 'react-simple-timefield';
import DownloadIcon from '@mui/icons-material/Download';
import UndoIcon from '@mui/icons-material/Undo';
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { base64_to_blob } from "../../../services/detail.service";
import {GetFileContent} from "../../../services/getDar.service";
import {GetDefaultStateForSite} from "../../../interfaces/config.interface";
import {SiteName} from "../../../services/config.service";






interface CarjackNotificationsProps {
    handleReportSubmit: any,
    handleFormCancel:any,
    //isEdit: boolean,
    carjacking?: any
}
    const CarjackNotificationsInputForm: React.FC<CarjackNotificationsProps> = ({ handleReportSubmit, handleFormCancel, carjacking = null}) => {

        const [initialBuild, setInitialBuild] = React.useState<boolean>(false)
        const [params, setParams] = React.useState<CarjackNotificationsObject>({})
        const [editBuild, setEditBuild] = React.useState<boolean>(false)
        const [editorState, setEditorState] = React.useState<any>(EditorState.createEmpty())
        const[incidentTypeList, setIncidentTypeList]= React.useState<any>([])
        const[stateList, setStateList]= React.useState<any>([])
        const[caliberList, setCaliberList]= React.useState<any>([])
        const[makeList, setMakeList]= React.useState<any>([])
        const[recoveryStatus, setRecoveryStatus]= React.useState<any>([{key:'Recovered', value: 'Recovered'},{key:'Unrecovered', value: 'Unrecovered'}])
        const[vehicleStatus, setVehicleStatus]= React.useState<any>([{key:'Recovered', value: 'Recovered'},{key:'Unrecovered', value: 'Unrecovered'}])
        const[disseminationList, setDisseminationList]= React.useState<any>([])
        const[existingFiles, setExistingFiles] = React.useState<CarjackFileOrImage[]>([])
        const[addedFiles, setAddedFiles] = React.useState<CarjackFileOrImage[]>([])
        const[alertOpen, setAlertOpen] = React.useState<boolean>(false)
        const[alertText, setAlertText] = React.useState<String>('')
        


        

        React.useEffect(() => {
           
            handleInitialBuild()
                  
        }, [carjacking])
    
    
        const handleInitialBuild = async () => {
            await handleDropdowns()
            if (carjacking && carjacking?.Main?.ID) {
                setEditBuild(true)
                //await handleEditForm()
                setEditBuild(false)
            } else {
                await handleNewForm()
            }
        }




        const checkSubjectRequired = () => {
            let res = false
            let p = {...params}
            let s = p.subject ? [...p.subject] : []
            for(let subject of s){
                if(!subject?.SUBJECT || subject?.SUBJECT?.length === 0 ){
                    res = true
                }
                if(!subject?.STATE || subject?.STATE?.length === 0 ){
                    res = true
                }
                if(!subject?.COUNTY || subject?.COUNTY?.length === 0 ){
                    res = true
                }
                if(!subject?.CITY || subject?.CITY?.length === 0 ){
                    res = true
                }
            }
    
            return res
        }

        const handleValueChange = async (field:string, value:any, index:number = 0) => {
        
            //SET UP OBJECT IF IT DOESNT EXIST 
            let p = {...params}
            let s = p.subject ? [...p.subject] : []
            let d = p.details ? [...p.details] : []
            let sus = p.suspects ? [...p.suspects] : []
            let w = p.weapon ? [...p.weapon] : []
            let i = p.incidents ? [...p.incidents] : []
            let v = p.vehicleDetails ? [...p.vehicleDetails] : []
            let a = p.additionalInfo ? [...p.additionalInfo] : []
        
            switch(field)
            {
                //Update
                case 'Update Message':
                    p.UpdateMessage=value.replaceAll("<li>","<li style='list-style-type: disc; list-style-position: inside;'>")
                    break;
                //SUBJECT
                case 'Subject':
                    s[index].SUBJECT = value;
                    i[index].REC_INCIDENT_TYPE = value;
                    break;
                case 'SUB State':
                    s[index].STATE = value;
                    i[index].REC_STATE = value;
                    if(stateList && stateList.length > 0 && stateList.filter((e:any) => e.Key === value).length > 0) {
                        s[index].COUNTY_LIST = await getCounty(value, true);
                    }
                    if(!editBuild) {
                        s[index].COUNTY = '';
                        i[index].REC_COUNTY = '';
                        s[index].CITY = '';
                        i[index].REC_CITY = '';
                    }
                    break;
                case 'SUB County':
                    s[index].COUNTY = value;
                    i[index].REC_COUNTY = value;
                    let state = "";
    
                    if( s[index].STATE && s[index].STATE !== undefined)
                    {
                         state = s[index].STATE as string
                    }
                    if(s[index].COUNTY_LIST && s[index].COUNTY_LIST.length > 0 && s[index].COUNTY_LIST.filter((e:any) => e.Key === value).length > 0) {
                        //s[index].CITY_LIST = await getCityFromStateCounty(state, value, true);
                        s[index].CITY_LIST = await getNJPOPCityFromStateCounty(state, value, true);
                    }
                    break;
                case 'SUB City':
                    s[index].CITY = value;
                    i[index].REC_CITY = value;  
                    break;
    
                // INCIDENTS
                case 'Incident Type':
                    i[index].REC_INCIDENT_TYPE = value;
                    break;
                case 'Incident Date':
                    i[index].REC_DATE = value;
                    break;
                case 'Incident Time':
                    i[index].REC_TIME = value;
                    break;
                case 'Location':
                    i[index].INCIDENT_LOCATION = value;
                    break;
                case 'Inc State':
                    i[index].REC_STATE = value;
                    if(stateList && stateList.length > 0 && stateList.filter((e:any) => e.Key === value).length > 0) {
                        i[index].COUNTY_LIST = await getCounty(value, true);
                    }
                    if(!editBuild) {
                        i[index].REC_COUNTY = '';
                        i[index].REC_CITY = '';
                    }
                    break;
                case 'Inc County':
                    i[index].REC_COUNTY = value;
                    let inc_state = "";
                    if( i[index].REC_STATE && i[index].REC_STATE !== undefined)
                    {
                         inc_state = i[index].REC_STATE as string
                    }
                    if(i[index].COUNTY_LIST && i[index].COUNTY_LIST.length > 0 && i[index].COUNTY_LIST.filter((e:any) => e.Key === value).length > 0) {
                        i[index].CITY_LIST = await getCityFromStateCounty(inc_state, value, true);
                    }
                    break;
                case 'Inc City':
                    i[index].REC_CITY = value;
                    break;
                case 'Location Details':
                    i[index].LOCATION_DETAILS= value;
                    break;         
    
                // DETAILS
                case 'Details':
                    d[index].DETAILS= value.replaceAll("<li>","<li style='list-style-type: disc; list-style-position: inside;'>");
                    break;
                case 'Number of victims':
                    d[index].NUMBER_OF_VICTIMS= value;
                    break;
                case 'Words Spoken/Accent':
                    d[index].WORDS_SPOKEN= value;
                    break;
                    
                //SUSPECT
                case 'Physical Description':
                    sus[index].PER_PHYSICAL_DESCRIPTION= value;
                    break;
                case 'Clothing Description':
                    sus[index].PER_CLOTHING_DESCRIPTION= value;
                    break;
                case 'Direction of Flight':
                    sus[index].PER_DIRECTION_OF_FLIGHT= value;
                    break;
    
                //WEAPON
                case 'Caliber':
                    w[index].CALIBER= value;
                    break;
                case 'Make':
                    w[index].MAKE= value;
                    break;
                case 'Model':
                    w[index].MODEL= value;
                    break;
                case 'Recovery Status':
                    w[index].RECOVERY_STATUS= value;
                    break;
                case 'Description':
                    w[index].DESCRIPTION= value;
                    break;
                case 'Type':
                    w[index].TYPE= value;
                    break;
    
                //VEHICLE DETAILS
                case 'Vehicle Make':
                    v[index].MAKE= value;
                    break;
                case 'Vehicle Model':
                    v[index].MODEL= value;
                    break;
                case 'Color':
                    v[index].COLOR= value;
                    break;
                case 'Year':
                    v[index].YEAR= value;
                    break;
                case 'Plate Number':
                    v[index].PLATE_NUMBER= value;
                    break;
                case 'Plate State':
                    v[index].PLATE_STATE= value;
                    break;
                case 'Vehicle Description':
                    v[index].VICTIM_VEHICLE_DESCRIPTION= value;
                    break;
                case 'Vehicle Direction of Flight':
                    v[index].VEHICLE_DIR_OF_FLIGHT= value;
                    break;
                case 'Vehicle Registration':
                    v[index].VICTIM_VEHICLE_REGISTRATION= value;
                    break;
                case 'Vehicle Status':
                    v[index].VEHICLE_STATUS= value;
                    break;
    
                //ADDITIONAL INFORMATION
                case 'Source':
                    a[index].SOURCE= value;
                    break;
                case 'Information Notice':
                    a[index].INFORMATION_NOTICE= value;
                    break;
                case 'Authority':
                    a[index].AUTHORITY= value;
                    break;
                case 'Officer Name':
                    a[index].OFFICER_NAME= value;
                    break;
                case 'Badge Number':
                    a[index].BADGE= value;
                    break;
                case 'Contact Number':
                    a[index].CONTACT_NUMBER= value;
                    break;
                case 'Dissemination':
                    a[index].DISSEMINATION= value;
                    
                    a[index].DISSEMINATION_BCC = a[index].DISSEMINATION_BCC?.filter((res:any) => !value.includes(res))
                    break;
                case 'Dissemination BCC':
                    a[index].DISSEMINATION_BCC = value;
                    break;        
            }
            
            p.subject = s
            p.incidents = i
            p.details = d
            p.suspects = sus
            p.weapon = w
            p.vehicleDetails = v
            p.additionalInfo = a
            setParams(p)
            
        }

        const handleNewForm = async() => {
            let temp = {...params}
            let today= new Date()
            let date=today.getFullYear()+'-'+((today.getMonth() + 1).toString()).padStart(2,'0')+'-'+ ((today.getDate()).toString()).padStart(2,'0') 
            let time=today.getHours().toString().padStart(2,'0') + ':'+ (today.getMinutes().toString().padStart(2,'0'))  
    
            if(!temp.subject || temp.subject?.length === 0){
                temp.subject = [{
                    STATE:'NJ',
                    COUNTY_LIST: await getCounty(GetDefaultStateForSite(SiteName), true)
                }]
            }
            if(!temp.incidents || temp.incidents?.length === 0){
                temp.incidents = [{
                    REC_STATE:GetDefaultStateForSite(SiteName),
                    COUNTY_LIST: await getCounty(GetDefaultStateForSite(SiteName), true),
                    REC_DATE: date,
                    REC_TIME: time
                }]
            }
            if(!temp.details || temp.details?.length === 0){
                temp.details = [{}]
            }
            if(!temp.suspects || temp.suspects?.length === 0){
                temp.suspects = [{ID:0}]
            }
            if(!temp.weapon || temp.weapon?.length === 0){
                temp.weapon = [{ID:0}]
            }
            if(!temp.vehicleDetails || temp.vehicleDetails?.length === 0){
                temp.vehicleDetails = [{ID:0}]
            }
            if(!temp.additionalInfo || temp.additionalInfo?.length === 0){
                temp.additionalInfo = [{
                    INFORMATION_NOTICE:'This product contains information that is LAW ENFORCEMENT SENSITIVE (LES) http://www.njsp.org/les.html',
                    CONTACT_NUMBER: '(609) 963-6951',
                    DISSEMINATION: ['CJG'],
                }]
            }
            if(!temp.FileOrImages)
            {
                temp.FileOrImages = []
            }
            temp.EditStatus = '0'
            temp.Status = 'PENDING'
            setParams (temp)
    
        }
        

        const checkIncidentRequired = (row:number) =>{
            let res = false
            let p = {...params}
            let i = p.incidents ? [...p.incidents] : []
            
            for(let incident of i) {
                if (row === 1) {
                    if (!incident?.REC_INCIDENT_TYPE || incident?.REC_INCIDENT_TYPE?.length === 0) {
                        res = true
                    }
                    if (!incident?.REC_DATE || (incident?.REC_DATE as string)?.length === 0) {
                        res = true
                    }
                    if (!incident?.REC_TIME || incident?.REC_TIME?.length === 0) {
                        res = true
                    }
                }
                if (row === 2){
                    if (!incident?.INCIDENT_LOCATION || incident?.INCIDENT_LOCATION?.length === 0) {
                        res = true
                    }
                }
                if(row === 3) {
                    if (!incident?.REC_STATE || incident?.REC_STATE?.length === 0) {
                        res = true
                    }
                    if (!incident?.REC_COUNTY || incident?.REC_COUNTY?.length === 0) {
                        res = true
                    }
                    if (!incident?.REC_CITY || incident?.REC_CITY?.length === 0) {
                        res = true
                    }
                }
            }
            
            return res

        }

        const handleDropdowns= async () =>{
            setIncidentTypeList([])
            setMakeList([])
            setStateList([])
            setDisseminationList([])
    
            var incTypes = await getBTCarjackIncidentTypeList()
            var caliber = await getNJTraceCaliberList()
            var make = await getNJTraceWeaponMakeList()
            var state = await getDropDownQuery('State')
            //var dissemination = await getIWW_Dissemination()
      
    
            if(incTypes && incTypes?.length > 0 )
            {
                setIncidentTypeList(incTypes)
            }
            if(caliber && caliber?.length > 0 )
            {
                setCaliberList(caliber)
            }
            if(make && make?.length > 0 )
            {
                make.push({Key:'Other', Value:'Other'})
                setMakeList(make)
            }
            if(state && state?.length > 0 )
            {
                setStateList(state)
            }
        //    if(dissemination && dissemination?.length > 0 )
        //    {
        //        setDisseminationList(dissemination)
        //    }
    
        }

        const handleAdditionalFiles =  (e : any) => {
            let files = e.files
            for(var i = 0; i < files.length; i++){
                handleChangeFile(files[i])
            }
        };

        const handleChangeFile = (file:any) => {
            let fileData = new FileReader();
            fileData.onloadend = (e) => handleFile(e, file);
            fileData.readAsDataURL(file);
        }

        const handleFile = (e:any, file:any) => {
            let f = addedFiles ? [...addedFiles] : [] //Grab all newly added files
            let ex = existingFiles ? [...existingFiles] : [] //Grab all existingFiles (From Edit)
            let fIndex = f.findIndex((fil: any) => fil.FileName === file.name) //if file.name does not exist in list returns -1
            let exIndex = ex.findIndex((fil: any) => fil.FileName === file.name) //if file.name does not exist in list returns -1
            if( fIndex === -1 && exIndex === -1) //if is not already in list
            {
                f.push({
                    Content: e.target.result.split('base64,')[1],
                    FileName: file.name,
                    FileEncoding: file.type
                })
            }
            else
            {
                let name = file.name.split('.') //grabs NAME part of file temp.jpg -> [temp, jpg]
                let x = f.filter((res) => res.FileName?.includes(name[0]))?.length 
                let y = ex.filter((res) => res.FileName?.includes(name[0]))?.length 
                let fileName = name[0] + '(' + (x + y) + ')' //y > x ? name[0] + '(' + y + ')' : name[0] + '(' + x + ')'
                f.push({
                    Content: e.target.result.split('base64,')[1],
                    FileName: fileName + (name?.length > 1 ? '.' + name[1] : ''),
                    FileEncoding: file.type
                })
            }
            setAddedFiles(f)
        }
    

        const reinstateFile = (file:any, index:number) => {
            let temp:any[] = []
            if(file.ID)
            {
                temp = [...existingFiles]
                temp[index].Deleted = false
                setExistingFiles(temp)
            }
        }

        const removeFile = (file:any, index:number) => {
            let temp = []
            if(!file.ID)
            {
                temp = [...addedFiles]
                temp.splice(index,1)
                setAddedFiles(temp)
            }
            else
            {
                temp = [...existingFiles]
                temp[index].Deleted = true
                setExistingFiles(temp)
            }
        }

        const downloadFile = async (file:any, index:number) => {
            let temp = []
            if(file.Content != null) {
                await base64_to_blob(file.Content, file.FileEncoding).then(r => {
                    var blobUrl = URL.createObjectURL(r);
                    window.open(blobUrl, '_blank')
                })
            }
            else if(file.ID)
            {
                temp = [...existingFiles]
                await GetFileContent(temp[index])
            }
        }      

        const handleAddVehicle = () =>{
            let p = {...params}
            p.vehicleDetails?.push({})
            setParams(p)
        }

        const handleRemoveVehicle =(index : any)=>{
            let p ={...params}
            let v = p.vehicleDetails ? [...p.vehicleDetails] : []
            let f = v.filter(res => res.Deleted !== true)
            if((f.length - 1) !=0){
                v[index].Deleted = true
                p.vehicleDetails = v
                setParams(p)
            }
        }

        const handleAddSuspect = () => {
            let p = {...params}
            p.suspects?.push({})
            setParams(p)

        }

        const handleRemoveSuspect = (index : any) =>{
            let p = {...params}
            let s = p.suspects ? [...p.suspects] : []
            let f = s.filter(res => (res.Deleted !== true))
            if ((f.length - 1) != 0){
                s[index].Deleted = true
                p.suspects = s
                setParams(p)
            }
        }

        const handleAddWeapon = () =>{
            let p = {...params}
            p.weapon?.push({})
            setParams(p)
        }

        const handleRemoveFirearm = (index : any) => {
            let p = {...params}
            let w = p.weapon ? [...p.weapon] : []
            let f = w.filter(res => res.Deleted !== true)
            if((f.length - 1) !=0){
                w[index].Deleted = true
                p.weapon = w
                setParams(p)
            }
        }

        const handleSuspectUnknown = (index : any) => {
            let p = {...params}
            let s = p.suspects ? [...p.suspects] : []
            s[index].UNKNOWN = !s[index].UNKNOWN
            s[index].PER_PHYSICAL_DESCRIPTION = (s[index].UNKNOWN ? 'Unk.' : '')
            s[index].PER_CLOTHING_DESCRIPTION = ''
            s[index].PER_DIRECTION_OF_FLIGHT = ''
            p.suspects = s
            setParams(p)            
        }

        const handleWeaponUnkown = (index : any) => {
            let p = {...params}
            let w = p.weapon ? [...p.weapon] : []
            w[index].UNKNOWN = !w[index].UNKNOWN
            w[index].CALIBER = ''
            w[index].TYPE = (w[index].UNKNOWN ? 'Unk.': '')
            w[index].MAKE= ''
            w[index].MODEL = ''
            w[index].RECOVERY_STATUS = ''
            w[index].DESCRIPTION = ''
            p.weapon = w
            setParams(p)
        }

        const handleVehicleUnkown = (index : any) => {
            let p = {...params}
            let v = p.vehicleDetails ? [...p.vehicleDetails] : []
            v[index].UNKNOWN = !v[index].UNKNOWN
            v[index].MAKE = ''
            v[index].MODEL = ''
            v[index].COLOR = ''
            v[index].YEAR = ''
            v[index].PLATE_NUMBER = ''
            v[index].PLATE_STATE = ''
            v[index].PLATE_NUMBER = ''
            v[index].PLATE_STATE = ''
            v[index].VICTIM_VEHICLE_REGISTRATION = ''
            v[index].VEHICLE_STATUS=''
            v[index].VICTIM_VEHICLE_DESCRIPTION = (v[index].UNKNOWN ? 'Unk.' : '')
            v[index].VEHICLE_DIR_OF_FLIGHT = ''
            p.vehicleDetails = v
            setParams(p)
        }

        const checkAdditionalRequired = (row:number) =>  {
            let res = false
            let p = {...params}
            let a = p.additionalInfo ? [...p.additionalInfo] : []
    
            for(let additionalInfo of a){
                if(row === 1) {
                    if (!additionalInfo?.SOURCE || additionalInfo?.SOURCE?.length === 0) {
                        res = true
                    }
                }
                if(row === 2) {
                    if (!additionalInfo?.INFORMATION_NOTICE || additionalInfo?.INFORMATION_NOTICE?.length === 0) {
                        res = true
                    }
                }
                if(row === 3) {
                    if (!additionalInfo?.AUTHORITY || additionalInfo?.AUTHORITY?.length === 0) {
                        res = true
                    }
                    if (!additionalInfo?.CONTACT_NUMBER || additionalInfo?.CONTACT_NUMBER?.length === 0) {
                        res = true
                    }             
                }
                if(row === 4){
                    if (!additionalInfo?.DISSEMINATION || additionalInfo?.DISSEMINATION?.length === 0) {
                        res = true
                    }
                }
            }
    
            return res
        }

        const handleValidation = ()=>{
            let res = true
            let resUpdate=true
            let p = {...params}
            let s = p.subject ? [...p.subject] : []
            let d = p.details ? [...p.details] : []
            let i = p.incidents ? [...p.incidents] : []
            let a = p.additionalInfo ? [...p.additionalInfo] : []
           
            if(p.EditStatus && p?.EditStatus === '1')
            {
                // if(!p.UpdateMessage || p.UpdateMessage?.length === 0)
                // {
                //     res = false
                //     resUpdate = false
                // }
    
                //access first block in text box to validate 
                var text=htmlToDraft(p?.UpdateMessage ?? '')
                if(text.contentBlocks[0].getLength() === 0){
                    res = false
                    resUpdate = false
                    
                }
            }
            
           
    
            for(let subject of s){
                if(!subject?.SUBJECT || subject?.SUBJECT?.length === 0 ){
                    res = false              
                }
    
                if(!subject?.STATE || subject?.STATE?.length === 0 ){
                    res = false                
                }
    
                if(!subject?.COUNTY || subject?.COUNTY?.length === 0 ){
                    res = false               
                }
    
                if(!subject?.CITY || subject?.CITY?.length === 0 ){
                    res = false
                }
    
            }
    
            for(let incident of i){
                if(!incident?.REC_INCIDENT_TYPE || incident?.REC_INCIDENT_TYPE?.length === 0 ){
                    res = false              
                }
    
                if(!incident?.REC_DATE || (incident?.REC_DATE as string)?.length === 0 ){
                    res = false                
                }
    
                if(!incident?.REC_TIME || incident?.REC_TIME?.length === 0 ){
                    res = false               
                }
    
                if(!incident?.INCIDENT_LOCATION || incident?.INCIDENT_LOCATION?.length === 0 ){
                    res = false
                }
                if(!incident?.REC_STATE || incident?.REC_STATE?.length === 0 ){
                    res = false              
                }
    
                if(!incident?.REC_COUNTY || incident?.REC_COUNTY?.length === 0 ){
                    res = false                
                }
    
                if(!incident?.REC_CITY || incident?.REC_CITY?.length === 0 ){
                    res = false               
                }
            }
            
            for(let details of d){
                // if(!details?.DETAILS || details?.DETAILS?.length === 0 ){
                //     res = false              
                // }
                
                //access first block in text box to validate 
                var text=htmlToDraft(details?.DETAILS ?? '')
                if(text.contentBlocks[0].getLength() === 0){
                    res = false                
                }
            }
    
            for(let additionalInfo of a){
                if(!additionalInfo?.SOURCE || additionalInfo?.SOURCE?.length === 0 ){
                    res = false              
                }
    
                if(!additionalInfo?.INFORMATION_NOTICE || additionalInfo?.INFORMATION_NOTICE?.length === 0 ){
                    res = false                
                }
    
                if(!additionalInfo?.AUTHORITY || additionalInfo?.AUTHORITY?.length === 0 ){
                    res = false               
                }
    
                /*if(!additionalInfo?.OFFICER_NAME || additionalInfo?.OFFICER_NAME?.length === 0 ){
                    res = false
                }
    
                if(!additionalInfo?.BADGE || additionalInfo?.BADGE?.length === 0 ){
                    res = false              
                }*/
    
                if(!additionalInfo?.CONTACT_NUMBER || additionalInfo?.CONTACT_NUMBER?.length === 0 ){
                    res = false                
                }
    
                if(!additionalInfo?.DISSEMINATION || additionalInfo?.DISSEMINATION?.length === 0 ){
                    res = false               
                }
    
            }
            if (!resUpdate && !res){
                setAlertText('Please complete Update Message field')
                setAlertOpen(true)
            }
            else if( !res ){
                setAlertText('Please complete all fields in Subject, Incident, and Additional Information. Also the Details field')
                setAlertOpen(true)
            }
            return res
        }

        const handleCancel=()=>{
            handleFormCancel()
        }

        const handleSave=() =>{
            if(handleValidation()){
                let p = {...params}
                let added = [...addedFiles]
                let existing = [...existingFiles]
                p.FileOrImages = existing.concat(added)
                handleReportSubmit(p)
            }
        }
        



      return(
        <div className="border-2 border-gray-200 w-10/12 m-auto">
                {params.subject?.map((subject: CarjackNotificationsSubjectObject, indx) => {
                return(
                <div className=" border-2 border-gray-600 mx-4 mt-4">
                    <div className="border-b-2 border-gray-600 bg-blue-300 h-2/12 font-bold text-center text-xl">Subject</div>
                        <div className="my-3">
                            <div className={checkSubjectRequired() ? "grid grid-cols-12 gap-x-2 px-4 pt-3" : "grid grid-cols-12 gap-x-2 px-4 pt-3 pb-4"}>
                                <div className="col-span-3">
                                    <Autocomplete
                                        freeSolo
                                        disableClearable
                                        id="Subject"                           
                                        size={"small"}
                                        options={incidentTypeList.map((option: any) => option.Description)}
                                        onInputChange={(event, value) => handleValueChange('Subject', value, indx)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Subject"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    type: 'search',
                                                    style: { textAlign: 'center' },
                                                }}
                                                error={!subject?.SUBJECT || subject?.SUBJECT == undefined}
                                                helperText={!subject?.SUBJECT || subject?.SUBJECT == undefined ? 'Required' : ''}
                                            />
                                        )}
                                        value={subject?.SUBJECT ? subject?.SUBJECT : ''}
                                    />
                                </div>      
                                <div className="col-span-3">
                                    <Autocomplete
                                        freeSolo
                                        disableClearable
                                        id="SUB State"                           
                                        size={"small"}
                                        options={stateList.map((option: any) => option.Key)}
                                        onInputChange={(event, value) => handleValueChange('SUB State', value, indx)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="State"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    type: 'search',
                                                    style: { textAlign: 'center' },
                                                }}
                                                error={!subject?.STATE || subject?.STATE == undefined}
                                                helperText={!subject?.STATE || subject?.STATE == undefined ? 'Required' : ''}
                                            />
                                        )}
                                        value={subject?.STATE ? subject?.STATE : ''}
                                    />
                                </div>
                                <div className="col-span-3">
                                    <Autocomplete
                                        freeSolo
                                        disableClearable
                                        id="SUB County"                           
                                        size={"small"}
                                        options={subject?.COUNTY_LIST ? subject?.COUNTY_LIST?.map((option: any) => option.Key) : []}
                                        onInputChange={(event, value) => handleValueChange('SUB County', value, indx)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="County"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    type: 'search',
                                                    style: { textAlign: 'center' },
                                                }}
                                                error={!subject?.STATE || subject?.STATE == undefined ? true : !subject?.COUNTY || subject?.COUNTY == undefined ? true : false} 
                                                helperText={!subject?.STATE || subject?.STATE == undefined ? 'Please Select A State' : !subject?.COUNTY || subject?.COUNTY == undefined ? 'Required' : ''}
                                            />
                                        )}
                                        value={subject?.COUNTY ? subject?.COUNTY : ''}
                                    />
                                </div>
                                <div className="col-span-3">
                                    <Autocomplete
                                        freeSolo
                                        disableClearable
                                        id="SUB City"                           
                                        size={"small"}
                                        options={subject?.CITY_LIST ? subject?.CITY_LIST?.map((option: any) => option.Key) : []}
                                        onInputChange={(event, value) => handleValueChange('SUB City', value, indx)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="City"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    type: 'search',
                                                    style: { textAlign: 'center' },
                                                }}
                                                error={!subject?.COUNTY || subject?.COUNTY == undefined ? true : !subject?.CITY || subject?.CITY == undefined ? true : false}
                                                helperText={!subject?.COUNTY || subject?.COUNTY == undefined ? 'Please Select A County' : !subject?.CITY || subject?.CITY == undefined ? 'Required' :''}
                                            />
                                        )}
                                        value={subject?.CITY ? subject?.CITY : ''}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
            )})}
            
            {params.incidents?.map((incident: CarjackNotificationsIncidentObject, indx) => {
                return(
        
                <div className=" border-2 border-gray-600 mx-4 mt-4">
                    <div className="border-b-2 border-gray-600 bg-blue-300 h-2/12 font-bold text-center text-xl">Incident 
                    </div>
                    <div className="my-4">
                            <div className="grid grid-cols-12 gap-x-2 px-4 pt-3">
                                <div className="col-span-4">
                                    <Autocomplete
                                        freeSolo
                                        disableClearable
                                        id="Incident Type"                           
                                        size={"small"}
                                        options={incidentTypeList.map((option: any) => option.Description)}
                                        onInputChange={(event, value) => handleValueChange('Incident Type', value, indx)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Incident Type"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    type: 'search',
                                                    style: { textAlign: 'center' },
                                                }}
                                                error={!incident?.REC_INCIDENT_TYPE || incident?.REC_INCIDENT_TYPE == undefined ? true : false}
                                                helperText={!incident?.REC_INCIDENT_TYPE || incident?.REC_INCIDENT_TYPE == undefined ? 'Required' : ''}
                                            />
                                        )}
                                        value={incident?.REC_INCIDENT_TYPE ? incident?.REC_INCIDENT_TYPE : ''}
                                    />
                                </div>
                                <div className="col-span-4">              
                                    <TextField variant="outlined" type="date" margin="none" fullWidth size={"small"} InputLabelProps={{ shrink: true }} label="Incident Date" value={incident?.REC_DATE} onChange = { (e) => handleValueChange('Incident Date', e.target.value , indx )} error={!incident?.REC_DATE || incident?.REC_DATE == undefined ? true : false} helperText={!incident?.REC_DATE || incident?.REC_DATE == undefined ? 'Required' : ''}/>                 
                                </div>
                                <div className="col-span-4">              
                                    <TimeField
                                        value={incident?.REC_TIME}
                                        onChange={ (e : any) => handleValueChange('Incident Time', e , indx )}
                                        input={<TextField label="Incident Time" value={incident?.REC_TIME} variant="outlined" margin="none" fullWidth size={"small"} error={!incident?.REC_TIME || incident?.REC_TIME == undefined ? true : false} helperText={!incident?.REC_TIME || incident?.REC_TIME == undefined ? 'Required' : ''}/>}
                                    />
                                </div>   
                            </div>
                        
                            <div className={checkIncidentRequired(1) ? "grid grid-flow-col auto-cols-auto gap-x-2 px-4 pt-1" : "grid grid-flow-col auto-cols-auto gap-x-2 px-4 pt-3"}>
                                <span className="align-middle flex inline mt-0">
                                    <TextField multiline={true} minRows={1} fullWidth margin='none' size={"small"} inputProps={{style: {fontSize: 14}}} label="Location" value={incident?.INCIDENT_LOCATION} onChange = { (e) => handleValueChange('Location', e.target.value , indx )} error={!incident?.INCIDENT_LOCATION || incident?.INCIDENT_LOCATION == undefined ? true : false} helperText={!incident?.INCIDENT_LOCATION || incident?.INCIDENT_LOCATION == undefined ? 'Required' : ''}/> 
                                </span> 
                            </div>
                        
                            <div className={checkIncidentRequired(2) ? "grid grid-cols-12 gap-x-2 px-4 pt-1" : "grid grid-cols-12 gap-x-2 px-4 pt-3"}>   
                                <div className="col-span-4">
                                    <Autocomplete
                                        freeSolo
                                        disableClearable
                                        id="Inc State"                           
                                        size={"small"}
                                        options={stateList.map((option: any) => option.Key)}
                                        onInputChange={(event, value) => handleValueChange('Inc State', value, indx)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="State"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    type: 'search',
                                                    style: { textAlign: 'center' },
                                                }}
                                                error={!incident?.REC_STATE || incident?.REC_STATE == undefined ? true : false}
                                                helperText={!incident?.REC_STATE || incident?.REC_STATE == undefined ? 'Required' : ''}
                                            />
                                        )}
                                        value={incident?.REC_STATE ? incident?.REC_STATE : ''}
                                    />
                                </div>                
                                <div className="col-span-4">
                                    <Autocomplete
                                        freeSolo
                                        disableClearable
                                        id="Inc County"                           
                                        size={"small"}
                                        options={incident?.COUNTY_LIST ? incident?.COUNTY_LIST?.map((option: any) => option.Key) : []}
                                        onInputChange={(event, value) => handleValueChange('Inc County', value, indx)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="County"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    type: 'search',
                                                    style: { textAlign: 'center' },
                                                }}
                                                error={!incident?.REC_STATE || incident?.REC_STATE == undefined ? true : !incident?.REC_COUNTY || incident?.REC_COUNTY == undefined ? true : false}
                                                helperText={!incident?.REC_STATE || incident?.REC_STATE == undefined ? 'Please Select A State' : !incident?.REC_COUNTY || incident?.REC_COUNTY == undefined ? 'Required' : ''}
                                            />
                                        )}
                                        value={incident?.REC_COUNTY ? incident?.REC_COUNTY : ''}
                                    />
                                </div>
                                <div className="col-span-4">
                                    <Autocomplete
                                        freeSolo
                                        disableClearable
                                        id="Inc City"                           
                                        size={"small"}
                                        options={incident?.CITY_LIST ? incident?.CITY_LIST?.map((option: any) => option.Key) : []}
                                        onInputChange={(event, value) => handleValueChange('Inc City', value, indx)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="City"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    type: 'search',
                                                    style: { textAlign: 'center' },
                                                }}
                                                error={!incident?.REC_COUNTY || incident?.REC_COUNTY == undefined ? true : !incident?.REC_CITY || incident?.REC_CITY == undefined ? true : false}
                                                helperText={!incident?.REC_COUNTY || incident?.REC_COUNTY == undefined ? 'Please Select A County' : !incident?.REC_CITY || incident?.REC_CITY == undefined ? 'Required' : ''}
                                            />
                                        )}
                                        value={incident?.REC_CITY ? incident?.REC_CITY : ''}
                                    />
                                </div>
                            </div>
                            <div className={checkIncidentRequired(3) ? "grid grid-flow-col auto-cols-auto gap-x-2 px-4 pt-1" : "grid grid-flow-col auto-cols-auto gap-x-2 px-4 pt-3 pb-4"}>       
                                <span className="align-middle flex inline mt-0">
                                    <TextField multiline={true} minRows={1} fullWidth margin='none' size={"small"} inputProps={{style: {fontSize: 14}}} label="Location Details" value={incident?.LOCATION_DETAILS} onChange = { (e) => handleValueChange('Location Details', e.target.value , indx )}/> 
                                </span> 
                            </div>

                        </div> 
                </div>
            )})}
            {params.details?.map((details: CarjackNotificationsDetailsObject, indx) => {
                return(            
                <div className=" border-2 border-gray-600 mx-4 mt-4">
                    <div className="border-b-2 border-gray-600 bg-blue-300 h-2/12 font-bold text-center text-xl">Details
                    </div>
                    <div className="my-4">
                        <div className="grid grid-flow-col auto-cols-auto gap-x-2 px-4 pt-3">
                            <span className={!details?.DETAILS || details?.DETAILS == undefined || htmlToDraft(details.DETAILS ?? '').contentBlocks[0].getLength() === 0  ? "px-3 rounded border-2 border-red-600" :"px-3 rounded border-2 border-gray-400"}>
                            {/* <span className="align-middle flex inline mt-0">  */}
                                {/* <TextField multiline={true} minRows={4} fullWidth margin='none' size={"small"} inputProps={{style: {fontSize: 14}}} label="Details" value={details?.DETAILS} onChange = { (e) => handleValueChange('Details', e.target.value , indx )} error={!details?.DETAILS || details?.DETAILS == undefined ? true : false} helperText={!details?.DETAILS || details?.DETAILS == undefined ? 'Required' : ''}/>  */}
                                {/* <RichTextEditorComponent enableXhtml={true} pasteCleanupSettings={pasteCleanupSettings} toolbarSettings={toolbarSettings} style={{minHeight:'150'}} placeholder="Details" enterKey="P"  value={getUpdateMessage(details?.DETAILS)} change={(e) => handleValueChange('Details', e.value , 0 )} >
                                        <Inject services={[HtmlEditor, PasteCleanup]} />
                                </RichTextEditorComponent>   */}
                                {/*@ts-ignore*/}
                                <Editor 
                                    onChange={(event)=>{handleValueChange('Details', draftToHtml(event) , indx )}}
                                    editorState={editorState}
                                    onEditorStateChange={(e)=>{setEditorState(e)}}
                                    toolbar={{
                                        options:['list'],
                                        list:{options:['unordered']}
                                    }}
                                />
                                <span style={{color:'red'}}>
                                    {!details?.DETAILS || details?.DETAILS == undefined || htmlToDraft(details.DETAILS ?? '').contentBlocks[0].getLength() === 0 ? "Details Required" : ""}
                                </span>
                            </span>
                        </div>
                        <div className={!details?.DETAILS || details?.DETAILS == undefined ? "grid grid-flow-col auto-cols-auto gap-x-2 px-4 pt-1" : "grid grid-flow-col auto-cols-auto gap-x-2 px-4 pt-3" }>       
                            <span className="align-middle flex inline mt-2">
                                {/* <TextField multiline={true} minRows={1} fullWidth margin='none' size={"small"} inputProps={{style: {fontSize: 14}}} label="Number of victims" value={details?.NUMBER_OF_VICTIMS} onChange = { (e) => handleValueChange('Number of victims', e.target.value , indx )} />  */}
                                <TextField multiline={true} minRows={1} fullWidth margin='none' size={"small"} inputProps={{style: {fontSize: 14}}} label="Words Spoken/Accent" value={details?.WORDS_SPOKEN} onChange = { (e) => handleValueChange('Words Spoken/Accent', e.target.value , indx )} /> 
                            </span>
                            {/* <span className="align-middle flex inline mt-0">
                                <TextField multiline={true} minRows={1} fullWidth margin='none' size={"small"} inputProps={{style: {fontSize: 14}}} label="Suspect Physical Description" value={details?.PER_PHYSICAL_DESCRIPTION} onChange = { (e) => handleValueChange('Suspect Physical Description', e.target.value , indx )}/> 
                            </span>
                            <span className="align-middle flex inline mt-0">
                                <TextField multiline={true} minRows={1} fullWidth margin='none' size={"small"} inputProps={{style: {fontSize: 14}}} label="Suspect Clothing Description" value={details?.PER_CLOTHING_DESCRIPTION} onChange = { (e) => handleValueChange('Suspect Clothing Description', e.target.value , indx )}/> 
                            </span>
                            <span className="align-middle flex inline mt-0">
                                <TextField multiline={true} minRows={1} fullWidth margin='none' size={"small"} inputProps={{style: {fontSize: 14}}} label="Suspect Direction of Flight" value={details?.PER_DIRECTION_OF_FLIGHT} onChange = { (e) => handleValueChange('Suspect Direction of Flight', e.target.value , indx )}/> 
                            </span> */}
                        </div>
                    </div>
                </div>
            )})}

            {params.suspects?.map((suspect: CarjackNotificationsSuspectObject, indx) => {
                return(
                    (suspect?.Deleted != true ?
                <div className=" border-2 border-gray-600 mx-4 mt-4">
                    <div className="border-b-2 border-gray-600 bg-blue-300 h-2/12 font-bold text-center text-xl">Suspect(s)
                        <span className="inline">
                            <IconButton size={"small"} onClick={() => handleRemoveSuspect(indx)} color={"error"} title={"Remove Suspect"}> <DeleteIcon /> </IconButton>
                        </span> 
                    </div>
                    <div className="my-4">
                        <div className="px-4">
                            <FormControlLabel control={<Checkbox value={suspect?.UNKNOWN} defaultChecked={suspect?.UNKNOWN ? suspect?.UNKNOWN : false} onChange = { (e) => handleSuspectUnknown(indx)} />} label="Unknown"/>
                        </div>
                        <div className="grid grid-flow-col auto-cols-auto gap-x-2  px-4 pt-3 pb-3">
                            <div className="col-span-4">
                                <TextField disabled={suspect?.UNKNOWN ? suspect?.UNKNOWN : false} variant="outlined" margin="none" fullWidth size={"small"}  label="Physical Description" value={suspect?.PER_PHYSICAL_DESCRIPTION} onChange = { (e) => handleValueChange('Physical Description', e.target.value , indx )}/> 
                            </div>
                            <div className="col-span-3">
                                <TextField disabled={suspect?.UNKNOWN ? suspect?.UNKNOWN : false} variant="outlined" margin="none" fullWidth size={"small"}  label="Clothing Description" value={suspect?.PER_CLOTHING_DESCRIPTION} onChange = { (e) => handleValueChange('Clothing Description', e.target.value , indx )}/> 
                            </div>
                            <div className="col-span-3">
                                <TextField disabled={suspect?.UNKNOWN ? suspect?.UNKNOWN : false} variant="outlined" margin="none" fullWidth size={"small"}  label="Direction of Flight" value={suspect?.PER_DIRECTION_OF_FLIGHT} onChange = { (e) => handleValueChange('Direction of Flight', e.target.value , indx )}/> 
                            </div>
                        </div>
                    </div>
                </div>
                : <></>)
            )})}
            {(params?.suspects && params.suspects.filter(res => !res.Deleted).length > 0 
                ? <div className="mx-4 mb-2">
                        <IconButton size={"large"} onClick={handleAddSuspect} color={"inherit"} title={"Add Additional Suspect"}> <PersonAddIcon /> </IconButton>
                </div>
                : <></>)}

            {params.weapon?.map((weapon: CarjackNotificationsWeaponObject, indx) => {
                return(
                    (weapon?.Deleted != true ?
                <div className=" border-2 border-gray-600 mx-4 mt-4">
                    <div className="border-b-2 border-gray-600 bg-blue-300 h-2/12 font-bold text-center text-xl">Type of Firearm(s)
                        <span className="inline">
                            <IconButton size={"small"} onClick={() => handleRemoveFirearm(indx)} color={"error"} title={"Remove Weapon"}> <DeleteIcon /> </IconButton>
                        </span> 
                    </div>
                    <div className="my-4">
                        <div className="px-4">
                                <FormControlLabel control={<Checkbox value={weapon?.UNKNOWN} defaultChecked={weapon?.UNKNOWN ? weapon?.UNKNOWN : false} onChange = { (e) => handleWeaponUnkown(indx)} />} label="Unknown"/>
                        </div>
                
                        <div className="grid grid-flow-col auto-cols-auto gap-x-2  px-4 pt-3">
                            <div className="col-span-3">
                                    <Autocomplete
                                        freeSolo
                                        disabled={weapon?.UNKNOWN ? weapon?.UNKNOWN : false}
                                        disableClearable
                                        id="Caliber"                           
                                        size={"small"}
                                        options={caliberList.map((option: any) => option.Value)}
                                        onInputChange={(event, value) => handleValueChange('Caliber', value, indx)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Caliber"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    type: 'search',
                                                    style: { textAlign: 'center' },
                                                }}                                            
                                            />
                                        )}
                                        value={weapon?.CALIBER ? weapon?.CALIBER : ''}
                                    />
                            </div>     
                            <div className="col-auto">
                                <TextField disabled={weapon?.UNKNOWN ? weapon?.UNKNOWN : false} variant="outlined" margin="none" fullWidth size={"small"} label="Type" value={weapon?.TYPE} onChange = { (e) => handleValueChange('Type', e.target.value , indx )}/> 
                            </div>
                            <div className="col-span-3">
                                    <Autocomplete
                                        freeSolo
                                        disabled={weapon?.UNKNOWN ? weapon?.UNKNOWN : false}
                                        disableClearable
                                        id="Make"                           
                                        size={"small"}
                                        options={makeList.map((option: any) => option.Value)}
                                        onInputChange={(event, value) => handleValueChange('Make', value, indx)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Make"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    type: 'search',
                                                    style: { textAlign: 'center' },
                                                }}
                                            />
                                        )}
                                        value={weapon?.MAKE ? weapon?.MAKE : ''}
                                    />
                                </div>     
                            <div className="col-auto">
                                <TextField disabled={weapon?.UNKNOWN ? weapon?.UNKNOWN : false} variant="outlined" margin="none" fullWidth size={"small"}  label="Model" value={weapon?.MODEL} onChange = { (e) => handleValueChange('Model', e.target.value , indx )}/> 
                            </div>
                            <div className="col-span-3">
                                    <Autocomplete
                                        freeSolo
                                        disabled={weapon?.UNKNOWN ? weapon?.UNKNOWN : false}
                                        disableClearable
                                        id="Recovery Status"                           
                                        size={"small"}
                                        options={recoveryStatus.map((option: any) => option.value)}
                                        onInputChange={(event, value) => handleValueChange('Recovery Status', value, indx)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Recovery Status"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    type: 'search',
                                                    style: { textAlign: 'center' },
                                                }}
                                                //error={!weapon?.RECOVERY_STATUS || weapon?.RECOVERY_STATUS == undefined ? true : false} 
                                            />
                                        )}
                                        value={weapon?.RECOVERY_STATUS ? weapon?.RECOVERY_STATUS : ''}
                                    />
                            </div>     
                        </div>
                        <div className="grid grid-flow-col auto-cols-auto gap-x-2 px-4 pt-3 pb-3">       
                            <span className="align-middle flex inline mt-0">
                                <TextField disabled={weapon?.UNKNOWN ? weapon?.UNKNOWN : false} multiline={true} minRows={1} fullWidth margin='none' size={"small"} inputProps={{style: {fontSize: 14}}} label="Description" value={weapon?.DESCRIPTION} onChange = { (e) => handleValueChange('Description', e.target.value , indx )}/> 
                            </span>
                        </div>

                    </div>  
                </div>
                :<></>)
            )})}
            {(params?.weapon && params.weapon.filter(res => !res.Deleted).length > 0 
                ? <div className="mx-4 mb-2">
                        <IconButton size={"large"} onClick={handleAddWeapon} color={"inherit"} title={"Add Additional Type of Firearm(s)"}> <WeaponAddIcon /> </IconButton>
                </div>
                : <></>)}
            {params.vehicleDetails?.map((vehicleDetails: CarjackNotificationsVehicleDetailsObject, indx) => {
                return(
                    (vehicleDetails?.Deleted != true ?
                <div className=" border-2 border-gray-600 mx-4 mt-4">
                    <div className="border-b-2 border-gray-600 bg-blue-300 h-2/12 font-bold text-center text-xl">Vehicle Details 
                        <span className="inline">
                            <IconButton size={"small"} onClick={() => handleRemoveVehicle(indx)} color={"error"} title={"Remove Vehicle"}> <DeleteIcon /> </IconButton>
                        </span> 
                    </div>
                    <div className="my-4">
                        <div className="px-4">
                            <FormControlLabel control={<Checkbox value={vehicleDetails?.UNKNOWN} defaultChecked={vehicleDetails?.UNKNOWN ? vehicleDetails?.UNKNOWN : false} onChange = { (e) => handleVehicleUnkown(indx)} />} label="Unknown"/>
                        </div>
                        <div className="grid grid-flow-col auto-cols-auto gap-x-2  px-4 pt-3 pb-3">
                            <div className="col-span-4">
                                <TextField disabled={vehicleDetails?.UNKNOWN ? vehicleDetails?.UNKNOWN : false} variant="outlined" margin="none" fullWidth size={"small"}  label="Make" value={vehicleDetails?.MAKE} onChange = { (e) => handleValueChange('Vehicle Make', e.target.value , indx )}/> 
                            </div>
                            <div className="col-span-3">
                                <TextField disabled={vehicleDetails?.UNKNOWN ? vehicleDetails?.UNKNOWN : false} variant="outlined" margin="none" fullWidth size={"small"}  label="Model" value={vehicleDetails?.MODEL} onChange = { (e) => handleValueChange('Vehicle Model', e.target.value , indx )}/> 
                            </div>
                            <div className="col-span-3">
                                <TextField disabled={vehicleDetails?.UNKNOWN ? vehicleDetails?.UNKNOWN : false} variant="outlined" margin="none" fullWidth size={"small"}  label="Color" value={vehicleDetails?.COLOR} onChange = { (e) => handleValueChange('Color', e.target.value , indx )}/> 
                            </div>
                            <div className="col-span-3">
                                <TextField disabled={vehicleDetails?.UNKNOWN ? vehicleDetails?.UNKNOWN : false} variant="outlined" margin="none" fullWidth size={"small"}  label="Year" value={vehicleDetails?.YEAR} onChange = { (e) => handleValueChange('Year', e.target.value , indx )}/> 
                            </div>
                            <div className="col-span-3">
                                <TextField disabled={vehicleDetails?.UNKNOWN ? vehicleDetails?.UNKNOWN : false} variant="outlined" margin="none" fullWidth size={"small"} label="Plate Number" value={vehicleDetails?.PLATE_NUMBER} onChange = { (e) => handleValueChange('Plate Number', e.target.value , indx )}/> 
                            </div>
                            <div className="col-span-3">
                                <TextField disabled={vehicleDetails?.UNKNOWN ? vehicleDetails?.UNKNOWN : false} variant="outlined" margin="none" fullWidth size={"small"} label="Plate State" value={vehicleDetails?.PLATE_STATE} onChange = { (e) => handleValueChange('Plate State', e.target.value , indx )}/> 
                            </div>
                        </div>
                        <div className="grid grid-flow-col auto-cols-auto gap-x-2 px-4 pt-3">
                            <span className="align-middle flex inline mt-0">
                                <TextField disabled={vehicleDetails?.UNKNOWN ? vehicleDetails?.UNKNOWN : false} variant="outlined" margin='none' fullWidth size={"small"} label="Vehicle Registration" value={vehicleDetails?.VICTIM_VEHICLE_REGISTRATION} onChange = { (e) => handleValueChange('Vehicle Registration', e.target.value , indx )}/> 
                            </span>
                            <span className="col-span-1">
                                    <Autocomplete
                                        freeSolo
                                        disabled={vehicleDetails?.UNKNOWN ? vehicleDetails?.UNKNOWN : false}
                                        disableClearable
                                        id="Recovery Status"                           
                                        size={"small"}
                                        options={vehicleStatus.map((option: any) => option.value)}
                                        onInputChange={(event, value) => handleValueChange('Vehicle Status', value, indx)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Recovery Status"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    type: 'search',
                                                    style: { textAlign: 'center' },
                                                }}
                                                //error={!weapon?.RECOVERY_STATUS || weapon?.RECOVERY_STATUS == undefined ? true : false} 
                                            />
                                        )}
                                        value={vehicleDetails?.VEHICLE_STATUS ? vehicleDetails?.VEHICLE_STATUS : ''}
                                    />
                            </span>     
                            <span className="align-middle flex inline mt-0">
                                <TextField disabled={vehicleDetails?.UNKNOWN ? vehicleDetails?.UNKNOWN : false} variant="outlined" fullWidth margin='none' size={"small"} label="Vehicle Description" value={vehicleDetails?.VICTIM_VEHICLE_DESCRIPTION} onChange = { (e) => handleValueChange('Vehicle Description', e.target.value , indx )}/> 
                            </span>
                            <span className="align-middle flex inline mt-0">
                                <TextField disabled={vehicleDetails?.UNKNOWN ? vehicleDetails?.UNKNOWN : false} variant="outlined" fullWidth margin='none' size={"small"} label="Vehicle Direction of Flight" value={vehicleDetails?.VEHICLE_DIR_OF_FLIGHT} onChange = { (e) => handleValueChange('Vehicle Direction of Flight', e.target.value , indx )}/> 
                            </span>

                        </div>
                    </div>
                </div>
                : <></>)
            )})}
            {(params?.vehicleDetails && params.vehicleDetails.filter(res => !res.Deleted).length > 0 
                ? <div className="mx-4 mb-2">
                        <IconButton size={"large"} onClick={handleAddVehicle} color={"inherit"} title={"Add Additional Vehicles"}> <WeaponAddIcon /> </IconButton>
                </div>
                : <></>)}
            {params.additionalInfo?.map((additionalInfo: CarjackNotificationsAdditionalInfoObject, indx) => {
                return(
                <div className=" border-2 border-gray-600 mx-4 mt-4 mb-4">
                    <div className="border-b-2 border-gray-600 bg-blue-300 h-2/12 font-bold text-center text-xl">Additional Information
                    </div>
                    <div className="my-4">
                        <div className="grid grid-flow-col auto-cols-auto gap-x-2 px-4 pt-3">       
                            <span className="align-middle flex inline mt-0">
                                <TextField multiline={true} minRows={1} fullWidth margin='none' size={"small"} inputProps={{style: {fontSize: 14}}} label="Source" value={additionalInfo?.SOURCE} onChange = { (e) => handleValueChange('Source', e.target.value , indx )} error={!additionalInfo?.SOURCE || additionalInfo?.SOURCE == undefined} helperText={!additionalInfo?.SOURCE || additionalInfo?.SOURCE == undefined ? 'Required' : ''}/> 
                            </span>
                        </div>
                        <div className={checkAdditionalRequired(1) ? "grid grid-flow-col auto-cols-auto gap-x-2 px-4 pt-1" : "grid grid-flow-col auto-cols-auto gap-x-2 px-4 pt-3"}>       
                            <span className="align-middle flex inline mt-0">
                                <TextField multiline={true} minRows={1} fullWidth margin='none' size={"small"} inputProps={{style: {fontSize: 14}}} label="Information Notice" value={additionalInfo?.INFORMATION_NOTICE} onChange = { (e) => handleValueChange('Information Notice', e.target.value , indx )} error={!additionalInfo?.INFORMATION_NOTICE || additionalInfo?.INFORMATION_NOTICE == undefined} helperText={!additionalInfo?.INFORMATION_NOTICE || additionalInfo?.INFORMATION_NOTICE == undefined ? 'Required' : ''}/> 
                            </span>
                        </div>

                        <div className={checkAdditionalRequired(2) ? "grid grid-cols-12 gap-x-2 px-4 pt-1" : "grid grid-cols-12 gap-x-2 px-4 pt-3"} >       
                            <span className="col-span-9">
                                <TextField multiline={true} minRows={1} fullWidth margin='none' size={"small"} inputProps={{style: {fontSize: 14}}} label="Authority" value={additionalInfo?.AUTHORITY} onChange = { (e) => handleValueChange('Authority', e.target.value , indx )} error={!additionalInfo?.AUTHORITY || additionalInfo?.AUTHORITY == undefined} helperText={!additionalInfo?.AUTHORITY || additionalInfo?.AUTHORITY == undefined ? 'Required' : ''}/> 
                            </span>
                            {/*<span className="col-span-3">
                                <TextField multiline={true} minRows={1} fullWidth margin='none' size={"small"} inputProps={{style: {fontSize: 14}}} label="Officer Name" value={additionalInfo?.OFFICER_NAME} onChange = { (e) => handleValueChange('Officer Name', e.target.value , indx )} error={!additionalInfo?.OFFICER_NAME || additionalInfo?.OFFICER_NAME == undefined} helperText={!additionalInfo?.OFFICER_NAME || additionalInfo?.OFFICER_NAME == undefined ? 'Required' : ''}/> 
                            </span>
                            <span className="col-span-3">
                                <TextField multiline={true} minRows={1} fullWidth margin='none' size={"small"} inputProps={{style: {fontSize: 14}}} label="Badge Number" value={additionalInfo?.BADGE} onChange = { (e) => handleValueChange('Badge Number', e.target.value , indx )} error={!additionalInfo?.BADGE || additionalInfo?.BADGE == undefined} helperText={!additionalInfo?.BADGE || additionalInfo?.BADGE == undefined ? 'Required' : ''}/> 
                            </span>*/}
                            <span className="col-span-3">
                                <TextField multiline={true} minRows={1} fullWidth margin='none' size={"small"} inputProps={{style: {fontSize: 14}}} label="Contact Number" value={additionalInfo?.CONTACT_NUMBER} onChange = { (e) => handleValueChange('Contact Number', e.target.value , indx )} error={!additionalInfo?.CONTACT_NUMBER || additionalInfo?.CONTACT_NUMBER == undefined} helperText={!additionalInfo?.CONTACT_NUMBER || additionalInfo?.CONTACT_NUMBER == undefined ? 'Required' : ''}/> 
                            </span>

                        </div>
                        
                        <div className={checkAdditionalRequired(3) ? "grid grid-flow-col auto-cols-auto gap-x-2 px-4 pt-1" : "grid grid-flow-col auto-cols-auto gap-x-2 px-4 pt-3 pb-3"}>
                            <Autocomplete
                                disableClearable
                                freeSolo
                                multiple
                                id="Dissemination"                           
                                size={"small"}
                                options={disseminationList.map((option: any) => option.Key)}
                                onChange={(event, value) => handleValueChange('Dissemination', value, indx)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Dissemination"
                                        InputProps={{
                                            ...params.InputProps,
                                            type: 'search',
                                            style: { textAlign: 'center' },
                                        }}
                                        error={!additionalInfo?.DISSEMINATION || additionalInfo?.DISSEMINATION == undefined  ||  (additionalInfo?.DISSEMINATION && additionalInfo?.DISSEMINATION.length === 0)} 
                                        helperText={!additionalInfo?.DISSEMINATION || additionalInfo?.DISSEMINATION == undefined ||  (additionalInfo?.DISSEMINATION && additionalInfo?.DISSEMINATION.length === 0) ? 'Required' : ''}
                                    />
                                )}
                                value={additionalInfo?.DISSEMINATION ? additionalInfo?.DISSEMINATION : []}
                            />
                        </div>
                    </div>
                </div>
            )})}

<div className = "px-4 mb-4">
            {existingFiles && existingFiles.filter(res => res.Deleted === false).length > 0
                ? <div className={"font-bold mb-3"}>
                    Files Attached to Carjacking Notification
                </div>
                :<></>
            }
            { existingFiles ?
                existingFiles.filter(res => res.Deleted === false).map((file: any, indx:number) => {
                    return(
                        <span className={"pr-2 border-2 rounded-lg border-green-500 pl-2 py-2 mr-1 mb-2"}>
                            <span>
                                <Tooltip title = "Download File" >
                                    <IconButton style={{margin:0, padding:0}} onClick = {() => downloadFile(file, indx)}><DownloadIcon/></IconButton>
                                </Tooltip>
                            </span>
                            <span className={"font-bold text-green-500 mr-1"} onClick = {() => downloadFile(file, indx)}>
                                {file.FileName}
                            </span>
                            <span>
                                <Tooltip title = "Delete File" >
                                    <IconButton color = "error" style={{margin:0, padding:0}} onClick = {() => removeFile(file, indx)}><DeleteIcon/></IconButton>
                                </Tooltip>
                            </span>
                        </span>
                    );
                }) : <></>}
        </div>

        <div className = "px-4 mb-4">
            {existingFiles && existingFiles.filter(res => res.Deleted === true).length > 0
                ? <div className={"font-bold mb-3"}>
                    Files Pending For Deletion
                </div>
                :<></>
            }
            { existingFiles ?
                existingFiles.filter(res => res.Deleted === true).map((file: any, indx:number) => {
                    return(
                        <span className={"pr-2 border-2 rounded-lg border-yellow-500 pl-2 py-2 mr-1 mb-2"}>
                            <span>
                                <Tooltip title = "Undo Deleting" >
                                    <IconButton style={{margin:0, padding:0}} onClick = {() => reinstateFile(file, indx)}><UndoIcon/></IconButton>
                                </Tooltip>
                            </span>
                            <span className={"font-bold text-yellow-500 mr-1"} onClick = {() => reinstateFile(file, indx)}>
                                {file.FileName}
                            </span>
                        </span>
                    );
                }) : <></>}
        </div>
        
        <div className = "px-4 mb-4">
            {addedFiles && addedFiles.length > 0
                ? <div className={"font-bold mb-3"}>
                    Files Pending for Upload to Carjacking Notification
                </div>
                :<></>
            }
            { addedFiles ?
                addedFiles.map((file: any, indx:number) => {
                    return(
                        <span className={"pr-2 border-2 rounded-lg border-blue-400 pl-2 py-2 mr-1 mb-2"}>
                            <span>
                                <Tooltip title = "Download File" >
                                    <IconButton style={{margin:0, padding:0}} onClick = {() => downloadFile(file, indx)}><DownloadIcon/></IconButton>
                                </Tooltip>
                            </span>
                            <span className={"font-bold text-blue-400 mr-1"} onClick = {() => downloadFile(file, indx)}>
                                {file.FileName}
                            </span>
                            <span>
                                <Tooltip title = "Delete File" >
                                    <IconButton color = "error" style={{margin:0, padding:0}} onClick = {() => removeFile(file, indx)}><DeleteIcon/></IconButton>
                                </Tooltip>
                            </span>
                        </span>
                    );
                }) : <></>}
        </div>
        
       {params?.FileOrImages ? 
            <div className = "px-4 pt-3 pb-4 mt-4">
                <label className =" border-2 border-gray-300 py-2 pl-2 text-xl rounded-xl text-gray-400" htmlFor="icon">
                    <input
                        id="icon"
                        hidden
                        className = "input"
                        multiple
                        onChange={e => { handleAdditionalFiles(e.target)}}
                        type="file"
                        accept = ".pdf, .txt, .doc, .docx, .png, .jpg"
                        value = ''
                    />
                    Attach Files to this Report
                    <IconButton color="primary" component="span">
                        <AttachFileIcon />
                    </IconButton>
                </label>
            </div>
        :<></>}
        <Snackbar open={alertOpen} autoHideDuration={7000} onClose={() => { setAlertOpen(false) }}>
            <Alert onClose={() => { setAlertOpen(false) }} severity={'error'} sx={{ width: '100%' }}>
                {alertText}
            </Alert>
        </Snackbar>
        <div className={"mr-4 mb-4"} style={{"display":"flex", "justifyContent":"end"}}>
            <ButtonGroup size="large" color="inherit" >
                <Button  type="submit" onClick={handleSave}>Save</Button>
                <Button  onClick={handleCancel}>Cancel</Button>
            </ButtonGroup>
        </div>           
         

        </div>
      );

    }

export default CarjackNotificationsInputForm;