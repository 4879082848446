import { TSC, TSCFiles } from "../interfaces/TSC.interface";
import {AxiosResponse} from "axios";
import {getDarInfoResponse} from "../interfaces/getDarInfo_interface";
import AuthenticatedAPIReqSplash from "./AuthenticatedAPIReqSplash.service";

export function GetGUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
        .replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
}

export async function SetTSCReport(report: any): Promise<any> {
    let res: AxiosResponse<getDarInfoResponse> = await AuthenticatedAPIReqSplash.post('/api/TSC/SetTSC', report)
    return (res.data)
}

export async function GetTSCPDF(id: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReqSplash.get('/api/TSC/GetTSCPDF?IDs=' + id, { responseType: "blob" })
    if (res && res != null) {
        const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', 'TSC.pdf');
        document.body.appendChild(link);
        link.click();
    }
}

export async function GetTSCReport(ID: any): Promise<any> {
    let res: AxiosResponse<getDarInfoResponse> = await AuthenticatedAPIReqSplash.get('/api/TSC/GetTSC?IDs='+ID)
    return (res.data)
}

export async function SetTSCFile(fileObj: TSCFiles): Promise<any> {
    let res: AxiosResponse<getDarInfoResponse> = await AuthenticatedAPIReqSplash.post('/api/TSC/SetTSCFile', fileObj)
    return (res.data)
}


export async function GetTSCContent(fileObj: TSCFiles): Promise<any> {
    let res: AxiosResponse<getDarInfoResponse> = await AuthenticatedAPIReqSplash.post('/api/TSC/TSCFileContent', fileObj)
    return (res.data)
}
