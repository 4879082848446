import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { Button } from '@mui/material';
import {Google_API_Key, SiteName} from "../../../services/config.service";
import { Pin } from '../../../interfaces/Pin.interface';
import Geocode from "react-geocode";
import {getCenterForSite} from "../../../interfaces/config.interface";

interface GeoCode_PositionProps {
    HandleGeoCodeClose: any,
    SetLatLng: any
    show: boolean,
    pin: Pin
}

export const GeoCode_Position: React.FC<GeoCode_PositionProps> = ({ HandleGeoCodeClose, show, pin,SetLatLng}) => {
    const [open, setOpen] = React.useState<boolean>(show)
    const [geoCodeDone, setGeoCodeDone] = React.useState<boolean>(false)
    const [mapKey, setMapKey] = React.useState<number>(0)
    const [map, setMap] = React.useState(null)
    const [center, setCenter] = React.useState<any>({lat:getCenterForSite(SiteName).lat, lng:getCenterForSite(SiteName).lng})
    Geocode.setApiKey(Google_API_Key);

    const handleOpen = () => {
        setOpen(!open)
    }
    
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: Google_API_Key
    })
    
    const containerStyle = {
        width: '1200px',
        height: '825px'
    };
    
    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])
    
    const getMarkerTitle = (pin:Pin):string => {
        let title = ""
        title += 'Name: ' + pin.name +'\n'
        title += 'Statute: ' + pin.statute +'\n'
        title += 'Offense Date: ' + pin.offense +'\n'
        title += 'City: ' + pin.city +'\n'
        return title
    }

    React.useEffect(() => {
        if(!open) {
            HandleGeoCodeClose()
        }
        if(!geoCodeDone){
            setGeoCodeDone(true)
            if( (pin.lat || pin.lng) ) {
                let lat = parseFloat(pin.lat)
                let lng = parseFloat(pin.lng)
                let centerTmp = getCenterForSite(SiteName)
                setCenter({
                    lat: lat ? lat : centerTmp.lat,
                    lng: lng ? lng : centerTmp.lng
                })
                setMapKey(mapKey + 1)
            }
            else
            {
            Geocode.fromAddress(pin.address + ',' + pin.city + ' ' + pin.state + ' ' + pin.zip).then(
                (response) => {
                    const {lat, lng} = response.results[0].geometry.location;
                    pin.lat = lat
                    pin.lng = lng
                    setCenter({
                        lat: parseFloat(lat),
                        lng: parseFloat(lng)
                    })
                    SetLatLng(lat, lng)
                    setMapKey(mapKey + 1)

                },
                (error) => {
                    console.error(error);
                }
            );
            }
        }
    },[open])

    const list = (anchor: 'right') => (
        <Box
            sx={{ width: 1200 }}
            role="presentation"
        >
            {(isLoaded) ? (
                <>
                    <Button onClick={handleOpen}>X</Button>
                    
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={10}
                        onUnmount={onUnmount}
                        key={mapKey}
                    >
                        { /* Child components, such as markers, info windows, etc. */}
                        <>
                            <Marker
                                draggable={true}
                                position={(pin.lat && pin.lng ? { lat: parseFloat(pin.lat), lng: parseFloat(pin.lng) } : { lat: getCenterForSite(SiteName).lat, lng: getCenterForSite(SiteName).lng })}
                                title ={getMarkerTitle(pin)}
                                onDragEnd={(e => {
                                    SetLatLng(e.latLng?.lat(),e.latLng?.lng())
                                })}
                            />
                        </>
                    </GoogleMap>
                    <span className = "pl-2 w-full pt-2 " style ={{display:"flex", justifyContent:"center"}}>Drag The Map Pin To Select A Location</span>
                </>
            ) : <></> }
        </Box>
    );

    return (
        <div>
            <React.Fragment key={'right'}>
                <Drawer
                    anchor={'right'}
                    open={open}
                    onClose={handleOpen}
                    variant="persistent"
                >
                    {list('right')}
                </Drawer>
            </React.Fragment>
        </div>
    );
}

export default GeoCode_Position