import React, {useState} from "react";
import {PersonInfo} from "../../interfaces/getDarInfo_interface";
import {RunGetNCIC, RunPOIList} from "../../services/account.service";
import POIs from "../../components/Admin/POIs";
import SearchResults from "../../components/Admin/_searchResultsPOIs";
import NCIC from "../../components/Admin/NCIC";
import NCICAttributes from "../../interfaces/NCIC.interface";
import {useLocation} from "react-router-dom";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function ManageNCIC() {
    let [personID,setPersonID] = React.useState<string>("0")
    let query = useQuery();
    
    //when this gets called grab from query?

    
    
    return (
        <div className="w-full">
            <NCIC ID={"0"} Hash={query.get("Hash")}/>
        </div>
    );
}

export default ManageNCIC;