import React from "react";
import { useLocation } from "react-router-dom";
import ALPRPDFReportInfo from "../../components/Detail/_ALPRPDFReportInfo";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}


function ALPRDetail() {
    let query = useQuery();

    return (
        <div>
            <ALPRPDFReportInfo ID={query.get("ID")} Dept={query.get("Dept")} maxRecords={query.get("maxRecords") } />
        </div>
    );
}

export default ALPRDetail;