import React from "react";
import { useLocation } from "react-router-dom";
import CadRmsDetailInfo from "../../components/Detail/_CadRmsDetailInfo";
import { SiteName } from "../../services/config.service";
import { SITE } from "../../interfaces/config.interface";
import M43OffenseIncidentDetailInfo from "../../components/Detail/_M43_OffenseIncidentDetailInfo";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}


function CadRmsDetail() {
    let query = useQuery();

    return (
        <div>
            {(SiteName == SITE.DCPROD || SiteName == SITE.DCDEMO || SiteName == SITE.DCLOCAL) ? 
                <M43OffenseIncidentDetailInfo ID={query.get("ID")} department={query.get("Dept")}/> :
                <CadRmsDetailInfo ID={query.get("ID")} department={query.get("Dept")} />
            }          
        </div>
    );
}

export default CadRmsDetail;