import React, {useState} from "react";
import ReactECharts from "echarts-for-react";
import { width } from "pdfkit/js/page";
import { rotate } from "pdfkit";
import { Box, Card, Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,Paper } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

interface ETraceProps {
    data:any,
}


const Top10PossessorWithFFLs: React.FC<ETraceProps> = ({data}) => {

    function Row(props: { row: any }) {
        const { row } = props;
        const [open, setOpen] = React.useState(false);

        const handleRowDealer=(row:any)=>{
            let temp:any=[]
            try{
                temp = JSON.parse(row)
            }
            catch(e){
                temp=[]
                console.log(e)
            }
            return temp
        }

        return (
          <React.Fragment>           
            <TableRow  sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                    >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {row?.INDIV_FIRSTNAME}
                    </TableCell>
                    <TableCell align="center">{row?.INDIV_LASTNAME}</TableCell>
                    <TableCell align="center">{row?.INDIV_DOB ? new Date(row?.INDIV_DOB)?.toLocaleString()?.split(',')[0] : ''}</TableCell>
                    <TableCell align="center">{row?.FBI}</TableCell>
                    <TableCell align="center">{row?.SBINo}</TableCell>
                    <TableCell align="center">{row?.Count}</TableCell>

                </TableRow>
                <TableRow >
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                        <Typography variant="h6" gutterBottom component="div">
                        FFL Details
                        </Typography>
                        <Table size="small" aria-label="ffls">
                        <TableHead>
                            <TableRow  sx={{'&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell align="center">ATF ID</TableCell>
                            <TableCell align="center">FFL Number</TableCell>
                            <TableCell align="center">FFL Name</TableCell>
                            <TableCell align="center">FFL Sequence</TableCell>
                            <TableCell align="center">TTC</TableCell>


                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {handleRowDealer(row?.Dealers)?.map((dealer:any)=>
                             <TableRow key={dealer?.ID}>
                                <TableCell component="th" scope="row" align="center">{dealer?.FTS_ID}</TableCell>
                                <TableCell  align="center">{dealer?.DEALER_FFL_NUMBER}</TableCell>
                                <TableCell  align="center">{dealer?.DEALER_LICENSEE_NAME}</TableCell>
                                <TableCell  align="center">{dealer?.DEALER_SEQ}</TableCell>
                                <TableCell  align="center">{dealer?.REC_TIME_TO_CRIME && dealer?.REC_TIME_TO_CRIME > 0 ? dealer?.REC_TIME_TO_CRIME : ''}</TableCell>

                            </TableRow>
                        )}
                        </TableBody>
                        </Table>
                    </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
          </React.Fragment>
        );
      }

return (
    <div>
        {
           data && data?.top10PossessorWithFFLs?.length > 0 ? 
            <Card variant="outlined">
            <Box sx={{ p: 2 }}>  
            <TableContainer component={Paper}>
                <Table size="small" aria-label="collapsible table">
                    <TableHead>
                    <TableRow sx={{ '& > *': { borderBottom: 'unset'}, '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell></TableCell>
                            <TableCell>First Name</TableCell>
                            <TableCell align="center">Last Name</TableCell>
                            <TableCell align="center">DOB</TableCell>
                            <TableCell align="center">FBI</TableCell>
                            <TableCell align="center">SBI</TableCell>
                            <TableCell align="center">Weapons with FFLs</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {data?.top10PossessorWithFFLs.map((row:any) => (
                        <Row key={row?.PersonID} row={row} />
                    ))}
                    </TableBody>
                </Table>
                </TableContainer>                                                                                                                  
            </Box>

        </Card>
        :<div className={"text-center text-3xl mt-6 mb-6"} style={{color:"red", fontWeight:500 , height:"100%"}}>No Data</div>
        }
        
    </div>
);
}

export default Top10PossessorWithFFLs;