import { PersonInfo } from "../../../interfaces/getDarInfo_interface";
import React from "react";
import { Disclosure } from '@headlessui/react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import WeaponTraceInfo from "../../../interfaces/WeaponTraceInfo.interface";
import { TablePagination, CircularProgress, Button } from "@mui/material";
import NJGunTraceIcon from "../../../assets/Images/NjGunTrace1.png";
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search
} from '@syncfusion/ej2-react-grids';
import { SiteName } from "../../../services/config.service";
import {GetStringNameForSite} from "../../../interfaces/config.interface";

interface PanelNJGunTraceProps {
    person: PersonInfo,
    NJWeaponTraces: WeaponTraceInfo[],
    isLoading: boolean
}

const PanelNJGunTrace: React.FC<PanelNJGunTraceProps> = ({ person, NJWeaponTraces, isLoading }) => {
    const [FormatData, setFormatData] = React.useState(0);

    const handleDataTyping = () => {
        let tempP: any = [...NJWeaponTraces]
        for (let p of tempP) {
            if (p.DateTimeIn) {
                p.DateTimeIn = new Date(p.DateTimeIn)
            }
            if (p.PurchaseDate) {
                p.PurchaseDate = new Date(p.PurchaseDate)
            }
        }
        setFormatData(tempP)
    }

    React.useEffect(() => {
        handleDataTyping()
    }, [NJWeaponTraces])

    return (
        <div className={"m-5 border"}>
            <Disclosure>
                {({ open }) => (
                    <>
                        <Disclosure.Button className="bg-blue-100 p-2 w-auto w-full text-left">
                            <span style={{ "display": "flex", "justifyContent": "space-between" }}>
                                <span>
                                    <KeyboardArrowDownIcon
                                        className={`${open ? "transform rotate-180" : ""}`}
                                    />
                                    <span className={"font-bold"}>{GetStringNameForSite(SiteName, 'NJTRACE')} <img src={NJGunTraceIcon} alt={''} style={{ display: 'inline' }} /> {(isLoading) ? <CircularProgress size={20} style={{ "marginLeft": "5px" }} /> : <></>}</span>
                                </span>
                                <span className={"font-bold mr-2"} >Name: {person?.FName?.toUpperCase()} {person?.MName?.toUpperCase()} {person?.LName?.toUpperCase()} &nbsp;&nbsp;Records: {NJWeaponTraces?.length}</span>
                            </span>
                        </Disclosure.Button>

                        <Disclosure.Panel className="text-gray-500 p-4">

                            <GridComponent
                                dataSource={FormatData}
                                allowPaging={true}
                                pageSettings={{ pageSize: 50, pageSizes: [10, 25, 50, 100] }}
                                allowSorting={true}
                                allowMultiSorting={true}
                                allowFiltering={true}
                                filterSettings={{ type: 'CheckBox' }}
                                allowTextWrap={true}
                                textWrapSettings={{ wrapMode: 'Both' }}
                                rowHeight={24}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective field='DateTimeIn' headerText='Recovery Date' format={{ type: 'date', format: 'MM/dd/yyyy' }}/>
                                    <ColumnDirective field='ATFNo' headerText='ID #' />
                                    <ColumnDirective field='AgencyCaseNo' headerText={GetStringNameForSite(SiteName,"Case #")} />
                                    <ColumnDirective field='RoleDesc' headerText='Role' />
                                    <ColumnDirective template={(item: any) => (<>({item?.MANUFACTURER_NAME} {item?.MODEL} {item?.SERIAL_NUMBER}</>)} headerText='Weapon (Make, Model, Serial #)' />
                                    <ColumnDirective field='CrimeHistory' headerText='Crime History' />
                                    <ColumnDirective field='DealerName' headerText='Trigger Lock Candidate' />
                                    <ColumnDirective template={(item: any) => (<>({item?.IncidentAddress} {item?.City} {item?.ST})</>)} headerText='Recovery Address' />
                                    <ColumnDirective field='Latitude' headerText='Latitude' visible={false} />
                                    <ColumnDirective field='Longitude' headerText='Longitude' visible={false} />
                                    {/*<ColumnDirective template={(item: any) => (<>({item.Latitude}, {item.Longitude})</>)} headerText='MSP (Latitude, Longitude)' visible={false} />*/}
                                    <ColumnDirective template={(item: any) => (<Button onClick={() => { window.open('/detail/trace?ID=' + item?.MainID + '&Dept=' + item?.ATFNo, '_blank') }} >View</Button>)} headerText='View' />
                                    {/* <ColumnDirective template={(item : any ) => (<Button onClick={ () => {console.log(item) }} >View</Button>)}  headerText='View' /> */}
                                </ColumnsDirective>
                                <Inject services={[Sort, Page, Filter]} />
                            </GridComponent>

                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>

    )
};

export default PanelNJGunTrace;