import { ColumnDirective, ColumnMenu, ColumnsDirective, DetailRow, ExcelExport, ExcelExportProperties, Filter, Grid, GridComponent, Inject, Page, PdfExport, Reorder, Resize, Search, Sort, Toolbar } from "@syncfusion/ej2-react-grids";
import React from "react";
import { GVRTFEmailUserInfo } from "../../interfaces/GVRTFEmailList.interface";
import { Alert, AlertColor, IconButton, Snackbar, Tooltip } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import { RunSetGRAILReferralEmail } from "../../services/getDar.service";


interface ResultsProps {
    Users: any[],
    HandleEditEmailUser : any,
    HandleDeleteEmailUser : any,
}


const GVRTFEmailUsers: React.FC<ResultsProps> = ({Users, HandleEditEmailUser, HandleDeleteEmailUser}) => {
    const [emailList, setEmailList] = React.useState<any>([])
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false)
    const [alertText, setAlertText] = React.useState<String>('')
    const [alertType, setAlertType] = React.useState<AlertColor>("error")
    
    let grid: Grid | null;
    
    React.useEffect(() => { 
        if(Users && Users !== emailList){
            handleInitialBuild()
        }
    }, [Users])
    

    const handleInitialBuild = async () => {
        if(Users){
            setEmailList(Users) 
        }
    } 

    const handleEditEmailUser  = (user:any) => {
        HandleEditEmailUser(user)   
    }

    const handleDeleteEmailUser = async (user: any) => {
        if(user){
            if (window.confirm("Do you want to remove this email user permanently?")){
                let temp : GVRTFEmailUserInfo ={}
                temp.ID = user.ID
                temp.FName = user.FName
                temp.LName = user.LName
                temp.Dept = user.Dept 
                temp.County = user.County 
                temp.Email = user.Email 
                temp.deleted = true
                temp.GroupID = user.GroupID 
                temp.GroupName = user.GroupName
                let res = await RunSetGRAILReferralEmail(temp)
                if(res.isValid){
                    if(grid){
                        //@ts-ignore
                        let tempList = [...grid?.dataSource]
                        if(res && res?.ID && res?.ID !== 0){
                            let index:number = tempList.findIndex((e:any) => e.ID === res.ID)
                            if(index >= 0){
                                tempList.splice(index,1)
                            }
                            grid.dataSource = tempList ?? []
                            grid?.refresh()   
                            handleAlert('emailDeleted')
                        }
                    }
                }                
            }            
        } 
    }

    const handleAlert = (type: string) =>{
        if (type === 'emailDeleted'){
            setAlertText('Email User has been deleted!')
            setAlertOpen(true)
            setAlertType("success")
        }
    }
    

    const emailUserActions = (item: any)=>{
        return(
            <span>               
                <Tooltip title="Edit" placement="top" arrow>
                    <IconButton onClick={() => handleEditEmailUser(item)} ><EditIcon /></IconButton>
                </Tooltip>
                <Tooltip title="Delete" placement="top" arrow>
                    <IconButton onClick={()=> handleDeleteEmailUser(item)}><DeleteIcon /></IconButton>
                </Tooltip>
            </span>
        )
    }

    const GridToolsClick = (args: ClickEventArgs) => {
        if (grid && args.item.text === "Excel Export") {
            let excelProp:ExcelExportProperties = {}
            excelProp.fileName = 'GVRTFEmailUsers.xlsx'
            grid.excelExport(excelProp);
        }else if (grid && args.item.text === 'PDF Export') {
            grid.pdfExport();
        }
    }
    
    return(
       <div>
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={alertOpen} autoHideDuration={6000} onClose={() => { setAlertOpen(false) }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertType} sx={{ width: '100%' }}>
                    {alertText}
                </Alert>
            </Snackbar>

            <GridComponent
            dataSource={emailList}
            allowPaging={true}
            pageSettings={{pageSize: 25, pageSizes: [10,25,50,100]}}
            allowSorting={true}
            allowMultiSorting={true}
            allowFiltering={true}
            filterSettings={{type: 'CheckBox'}}
            //detailTemplate={gridTemplate}
            allowTextWrap={true}
            textWrapSettings={{ wrapMode: 'Both' }}
            allowExcelExport={true}
            allowPdfExport={true}
            toolbar={[
                'ExcelExport',
                'Search'
            ]}
            toolbarClick={GridToolsClick}
            ref={(g) => { if (g) { grid = g } }}
            selectionSettings={{
                persistSelection: true,
                type: "Multiple",
                checkboxOnly: true,
                mode: "Row"}}
            allowSelection={true}
            allowResizing={true}
            rowHeight={24}
            //rowSelected = {(event:any) => {expandRow()}}
            rowDeselected = {(event:any) => {}}
            >
                <ColumnsDirective>
                    <ColumnDirective field='ID'  headerText='ID' visible={false} isPrimaryKey={true} />
                    <ColumnDirective field='FName' width={110} minWidth={110} maxWidth={110} headerText='First Name' customAttributes={{ class: ['e-attr']}}/>
                    <ColumnDirective field='LName' width={110} minWidth={110} maxWidth={110} headerText='Last Name' customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='Dept' width={110} minWidth={110} maxWidth={110} headerText='Agency' customAttributes={{ class: ['e-attr'] }}/>
                    <ColumnDirective field='County' width={110} minWidth={110} maxWidth={110} headerText='County' customAttributes={{ class: ['e-attr'] }}/>
                    <ColumnDirective field='Email' width={110} minWidth={110} maxWidth={110} headerText='Email Address' customAttributes={{ class: ['e-attr'] }}/>
                    <ColumnDirective template={emailUserActions} width={110} minWidth={110} maxWidth={110} headerText='Email User Actions' customAttributes={{ class: ['e-attr'] }}/>
                </ColumnsDirective>
                <Inject services={[ DetailRow,ColumnMenu, Sort, Page, Filter, Toolbar, ExcelExport,PdfExport,Search, Resize, Reorder]}/>

            </GridComponent>
       </div>
    );

}

export default GVRTFEmailUsers;