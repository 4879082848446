import { AxiosResponse } from "axios";
import {
  DropDown,
  InvestigationObject,
  InvestigationQuery,
  InvestigationResponse,
} from "../interfaces/NJPORT/InvestigationLog.interface";

import {
  TempCardObject,
  TempCardQuery,
  TempCardResponse,
  TempDropDown,
} from "../interfaces/NJPORT/TempCard.interface";
import AuthenticatedAPIReq from "./AuthenticatedAPIReq.service";
import {
  ShipEntryQuery,
  ShipEntryResponse,
  ShipEntyObject,
} from "../interfaces/NJPORT/ShipEntry.interface";
import {
  VolunteerHireObject,
  VolunteerHireQuery,
  VolunteerHirenResponse,
  VolunteerHirePoolResponse,
} from "../interfaces/NJPORT/VolunteerHire.interface";
import {
  StevedoreObject,
  StevedoreQuery,
  StevedoreResponse,
} from "../interfaces/NJPORT/Stevedore.interface";
import {
  PDOHireObject,
  PdoHirePoolResponse,
  PdoHireQuery,
  PdoHireResponse,
} from "../interfaces/NJPORT/PDOHire.interface";
import { AnalystObject } from "../interfaces/NJPORT/analyst.interface";
import { MoneyObject } from "../interfaces/NJPORT/money.interface";
import {
  ArrestObject,
  ArrestQuery,
  ArrestResponse,
} from "../interfaces/NJPORT/arrest.interface";
import {
  RenewalObject,
  RenewalQuery,
  RenewalResponse,
} from "../interfaces/NJPORT/renewal.interface";
import {
  TerminalHireObject,
  TerminalHireQuery,
  TerminalHireResponse,
} from "../interfaces/NJPORT/TerminalHire.interface";

export async function submitInvestigation(
  data: InvestigationObject,
): Promise<InvestigationObject> {
  const res: AxiosResponse<InvestigationObject> =
    await AuthenticatedAPIReq.post("/api/NJPORT/SetPortInvestigation", data);
  console.log(res.data);
  return res.data;
}

export async function searchInvestigation(
  data: InvestigationQuery,
): Promise<InvestigationResponse> {
  let res: AxiosResponse<InvestigationResponse> =
    await AuthenticatedAPIReq.post("/api/NJPORT/List", data);
  return res.data;
}

export async function DeleteInvestigationReport(data: any): Promise<any> {
  let res: AxiosResponse<any> = await AuthenticatedAPIReq.post(
    "/api/NJPORT/SetPortInvestigation",
    data,
  );
  return res.data;
}

export async function InvestigationDropDown(data: any): Promise<DropDown[]> {
  let res: AxiosResponse<DropDown[]> = await AuthenticatedAPIReq.get(
    "/api/Dropdown/PORT",
    data,
  );
  return res.data;
}

export async function SexDropdown(): Promise<DropDown[]> {
  let res: AxiosResponse<DropDown[]> = await AuthenticatedAPIReq.get(
    "/api/Dropdown/Sex",
    {
      headers: {
        AuthToken: localStorage.getItem("user") || "",
      },
    },
  );
  return res.data;
}

export async function RaceDropdown(): Promise<DropDown[]> {
  let res: AxiosResponse<DropDown[]> = await AuthenticatedAPIReq.get(
    "/api/Dropdown/ATF_Race",
    {
      headers: {
        AuthToken: localStorage.getItem("user") || "",
      },
    },
  );
  return res.data;
}

// TempCard

export async function submitTempCard(
  data: TempCardObject,
): Promise<TempCardObject> {
  const res: AxiosResponse<TempCardObject> = await AuthenticatedAPIReq.post(
    "/api/NJPORT/SetTempLicense",
    data,
  );
  console.log(res.data);
  return res.data;
}

export async function searchTempCard(
  data: TempCardQuery,
): Promise<TempCardResponse> {
  let res: AxiosResponse<TempCardResponse> = await AuthenticatedAPIReq.post(
    "/api/NJPORT/List",
    data,
  );
  return res.data;
}

export async function DeleteTempCard(data: any): Promise<any> {
  let res: AxiosResponse<any> = await AuthenticatedAPIReq.post(
    "/api/NJPORT/SetTempLicense",
    data,
  );
  return res.data;
}

export async function TempCardDropDown(data: any): Promise<DropDown[]> {
  let res: AxiosResponse<TempDropDown[]> = await AuthenticatedAPIReq.get(
    "/api/Dropdown/PORT",
    data,
  );
  return res.data;
}

// ShipEntry

export async function submitShipEntry(
  data: ShipEntyObject,
): Promise<ShipEntyObject> {
  console.log(data);
  const res: AxiosResponse<ShipEntyObject> = await AuthenticatedAPIReq.post(
    "/api/NJPORT/SetShipEntry",
    data,
  );
  console.log(res.data);
  return res.data;
}

export async function searchShipEntry(
  data: ShipEntryQuery,
): Promise<ShipEntryResponse> {
  let res: AxiosResponse<ShipEntryResponse> = await AuthenticatedAPIReq.post(
    "/api/NJPORT/List",
    data,
  );
  return res.data;
}

export async function DeleteShipEntry(data: any): Promise<any> {
  let res: AxiosResponse<any> = await AuthenticatedAPIReq.post(
    "/api/NJPORT/SetShipEntry",
    data,
  );
  return res.data;
}

// TerminalHire
export async function submitTerminalHires(
  data: TerminalHireObject,
): Promise<TerminalHireObject> {
  console.log(data);
  const res: AxiosResponse<TerminalHireObject> = await AuthenticatedAPIReq.post(
    "/api/NJPORT/SetTerminalHires",
    data,
  );
  console.log(res.data);
  return res.data;
}

export async function searchTerminalHire(
  data: TerminalHireQuery,
): Promise<TerminalHireResponse> {
  let res: AxiosResponse<TerminalHireResponse> = await AuthenticatedAPIReq.post(
    "/api/NJPORT/List",
    data,
  );
  return res.data;
}

export async function DeleteTerminalHire(data: any): Promise<any> {
  let res: AxiosResponse<any> = await AuthenticatedAPIReq.post(
    "/api/NJPORT/SetTerminalHires",
    data,
  );
  return res.data;
}

// VolunteerReport
export async function submitVolunteerHire(
  data: VolunteerHireObject,
): Promise<VolunteerHireObject> {
  console.log(data);
  const res: AxiosResponse<VolunteerHireObject> =
    await AuthenticatedAPIReq.post("/api/NJPORT/SetVolunteerHire", data);
  console.log(res.data);
  return res.data;
}

export async function searchVolunteerHire(
  data: VolunteerHireQuery,
): Promise<VolunteerHirenResponse> {
  let res: AxiosResponse<VolunteerHirenResponse> =
    await AuthenticatedAPIReq.post("/api/NJPORT/List", data);
  return res.data;
}

export async function getVolunteerHirePool(
  data: VolunteerHireQuery,
): Promise<VolunteerHirePoolResponse> {
  let res: AxiosResponse<VolunteerHirePoolResponse> =
    await AuthenticatedAPIReq.post("/api/NJPORT/List", data);
  return res.data;
}

export async function DeleteVolunteerHire(data: any): Promise<any> {
  let res: AxiosResponse<any> = await AuthenticatedAPIReq.post(
    "/api/NJPORT/SetVolunteerHire",
    data,
  );
  return res.data;
}

// Pdo Hire

export async function getPdoHirePool(
  data: PdoHireQuery,
): Promise<PdoHirePoolResponse> {
  let res: AxiosResponse<PdoHirePoolResponse> = await AuthenticatedAPIReq.post(
    "/api/NJPORT/List",
    data,
  );
  return res.data;
}

export async function submitPdoHire(
  data: PDOHireObject,
): Promise<PDOHireObject> {
  console.log(data);
  const res: AxiosResponse<PDOHireObject> = await AuthenticatedAPIReq.post(
    "/api/NJPORT/SetPDOHire",
    data,
  );
  console.log(res.data);
  return res.data;
}

export async function searchPdoHire(
  data: PdoHireQuery,
): Promise<PdoHireResponse> {
  let res: AxiosResponse<PdoHireResponse> = await AuthenticatedAPIReq.post(
    "/api/NJPORT/List",
    data,
  );
  return res.data;
}

export async function DeletePdoHire(data: any): Promise<any> {
  let res: AxiosResponse<any> = await AuthenticatedAPIReq.post(
    "/api/NJPORT/SetPDOHire",
    data,
  );
  return res.data;
}

// SteveDore

export async function submitSteveDore(
  data: StevedoreObject,
): Promise<StevedoreObject> {
  console.log(data);
  const res: AxiosResponse<any> = await AuthenticatedAPIReq.post(
    "/api/NJPORT/SetSteveDore",
    data,
  );
  console.log(res.data);
  return res.data;
}

export async function searchSteveDore(
  data: StevedoreQuery,
): Promise<StevedoreResponse> {
  let res: AxiosResponse<any> = await AuthenticatedAPIReq.post(
    "/api/NJPORT/List",
    data,
  );
  return res.data;
}

export async function DeleteSteveDore(data: any): Promise<any> {
  let res: AxiosResponse<any> = await AuthenticatedAPIReq.post(
    "/api/NJPORT/SetSteveDore",
    data,
  );
  return res.data;
}

export async function DeleteAnalystReport(data: any): Promise<any> {
  let res: AxiosResponse<any> = await AuthenticatedAPIReq.post(
    "/api/NJPORT/SetAnalystRequest",
    data,
  );
  return res.data;
}

export async function submitAnalyst(
  data: AnalystObject,
): Promise<AnalystObject> {
  const res: AxiosResponse<AnalystObject> = await AuthenticatedAPIReq.post(
    "/api/NJPORT/SetAnalystRequest",
    data,
  );
  console.log(res.data);
  return res.data;
}

export async function submitMoneyReport(
  data: MoneyObject,
): Promise<MoneyObject> {
  const res: AxiosResponse<MoneyObject> = await AuthenticatedAPIReq.post(
    "/api/NJPORT/SetMoneyOrder",
    data,
  );
  console.log(res.data);
  return res.data;
}

export async function searchArrestReport(
  data: ArrestQuery,
): Promise<ArrestResponse> {
  let res: AxiosResponse<ArrestResponse> = await AuthenticatedAPIReq.post(
    "/api/NJPORT/List",
    data,
  );
  return res.data;
}

export async function submitArrestReport(
  data: ArrestObject,
): Promise<ArrestObject> {
  const res: AxiosResponse<ArrestObject> = await AuthenticatedAPIReq.post(
    "/api/NJPORT/SetArrestInvestigation ",
    data,
  );
  console.log(res.data);
  return res.data;
}

export async function submitRenewalReport(
  data: RenewalObject,
): Promise<RenewalObject> {
  const res: AxiosResponse<RenewalObject> = await AuthenticatedAPIReq.post(
    "/api/NJPORT/SetApplicantRenewal ",
    data,
  );
  console.log(res.data);
  return res.data;
}

export async function searchRenewalReport(
  data: RenewalQuery,
): Promise<RenewalResponse> {
  let res: AxiosResponse<RenewalResponse> = await AuthenticatedAPIReq.post(
    "/api/NJPORT/List",
    data,
  );
  return res.data;
}
