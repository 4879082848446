import React, { useState, useEffect } from "react";
import { TextField, Checkbox, Button, ToggleButtonGroup, ToggleButton, Card, CardContent, TableContainer, Table, TableBody, TableRow, TableCell, Paper } from "@mui/material";
import InfocopDetail from "../../interfaces/Detail/InfocopDetail.interface"
import { RunInfocopDetailQuery } from "../../services/detail.service";
import { formatDate, formatDT } from "../../services/formatDate.service"

import './Detail.css'
import DetailMap from "./_DetailMap";

interface DetailProps {
    ID: string | null
    department: string | null
}


const InfocopDetailInfo: React.FC<DetailProps> = ({ ID, department }) => {
    const [DataLoaded, setDataLoaded] = React.useState<boolean>(false)
    const [Data, setData] = React.useState<InfocopDetail>({})
    const [pin, setPin] = React.useState<any>({})

    const loadData = async () => {
        
        if (!DataLoaded) {
            setDataLoaded(true)
            let res = await RunInfocopDetailQuery(ID, department);
            setData(res)
            createPin(res) 
        }
    }

    React.useEffect(() => {
        if (!DataLoaded) {
            setDataLoaded(true)
            loadData()
        }
    });

    const createPin = async (Data: any) => {
        let tPin: any = {}
        if (Data && Data?.Inc_Latitude && Data?.Inc_Longitude
            && (Data?.Inc_Latitude !== "-1.000000" && Data?.Inc_Longitude !== "-1.000000")
            && (Data?.Inc_Latitude !== "-994.000000" && Data?.Inc_Longitude !== "-994.000000")
            && (Data?.Inc_Latitude !== "-995.000000" && Data?.Inc_Longitude !== "-995.000000")
            && (Data?.Inc_Latitude !== "-996.000000" && Data?.Inc_Longitude !== "-996.000000")
            && (Data?.Inc_Latitude !== "-997.000000" && Data?.Inc_Longitude !== "-997.000000")
            && (Data?.Inc_Latitude !== "-998.000000" && Data?.Inc_Longitude !== "-998.000000")
            && (Data?.Inc_Latitude !== "-999.000000" && Data?.Inc_Longitude !== "-999.000000")
            && (Data?.Inc_Latitude !== "0.000000" && Data?.Inc_Longitude !== "0.000000")
        ) {
            tPin = ({
                lat: Data?.Inc_Latitude,
                lng: Data?.Inc_Longitude,
                offense: Data?.ReqID,
                date: Data?.DateTimeIn,
                arrestedBy: Data?.DeptName,
                detail: "infocop"
            })
        }
        setPin(tPin)
    }

    return (
        <div className="bg-gray-100 ml-5 mr-5 rounded p-5 ">
            <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }} >
                <CardContent>
                    <h3 className="mb-4 font-bold font text-xl text-blue-400">Incident Details</h3>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">ID</span><span className="DetailTableData">{Data.ID}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Request ID</span><span className="DetailTableData">{Data.ReqID}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">SERVER</span><span className="DetailTableData">{Data.SERVER}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Incident Date</span><span className="DetailTableData">{formatDT(Data.DateTimeIn)}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Request Type</span><span className="DetailTableData">{Data.RequestType}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Request Key</span><span className="DetailTableData">{Data.RequestKey}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Incident Latitude</span><span className="DetailTableData">{Data.Inc_Latitude}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Incident Longitude</span><span className="DetailTableData">{Data.Inc_Longitude}</span></div>
                    {pin && pin.lat && pin.lng ? <div className="DetailTableRow border-2 border-black"> <DetailMap data={pin} detail="infocop" /> </div> : <></>}
                </CardContent>
            </Card>

            <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                <CardContent>
                    <h3 className="mb-5 font-bold font text-xl text-blue-400">Person Information</h3>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">First Name</span><span className="DetailTableData">{Data.FirstName}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Middle Name</span><span className="DetailTableData">{Data.MiddleName}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Last Name</span><span className="DetailTableData">{Data.LastName}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Suffix</span><span className="DetailTableData">{Data.Suffix}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">DOB</span><span className="DetailTableData">{formatDate(Data.DOB)}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Sex</span><span className="DetailTableData">{Data.Sex}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Eyes</span><span className="DetailTableData">{Data.Eyes}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Height</span><span className="DetailTableData">{Data.Height}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Weight</span><span className="DetailTableData">{Data.Weight}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Age</span><span className="DetailTableData">{Data.Age}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Points</span><span className="DetailTableData">{Data.Points}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">AKA Last Name</span><span className="DetailTableData">{Data.AKALName}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">AKA First Name</span><span className="DetailTableData">{Data.AKAFName}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Race</span><span className="DetailTableData">{Data.Race}</span></div>
                </CardContent>
            </Card>
            <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                <CardContent>
                    <h3 className="mb-4 font-bold font text-xl text-blue-400">Person Address</h3>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Address</span><span className="DetailTableData">{Data.Address}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">City</span><span className="DetailTableData">{Data.City}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">State</span><span className="DetailTableData">{Data.State}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Zip</span><span className="DetailTableData">{Data.Zip}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Resi. Latitude</span><span className="DetailTableData">{Data.Res_Latitude}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Resi. Longitude</span><span className="DetailTableData">{Data.Res_Longitude}</span></div>
                </CardContent>
            </Card>
            <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                <CardContent>
                    <h3 className="mb-4 font-bold font text-xl text-blue-400">Person DL</h3>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">DL Number</span><span className="DetailTableData">{Data.DLNumber}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">DL State</span><span className="DetailTableData">{Data.DLState}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">DL Expiry</span><span className="DetailTableData">{Data.DLExp}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">DL Class</span><span className="DetailTableData">{Data.DLClass}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Restrictions</span><span className="DetailTableData">{Data.Restrictions}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">SSN</span><span className="DetailTableData">{Data.SSN}</span></div>
                </CardContent>
            </Card>
            <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                <CardContent>
                    <h3 className="mb-4 font-bold font text-xl text-blue-400">Person Vehicle</h3>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Plate Num</span><span className="DetailTableData">{Data.PlateNum}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Plate State</span><span className="DetailTableData">{Data.PlateState}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Plate Year</span><span className="DetailTableData">{Data.PlateYear}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">VIN</span><span className="DetailTableData">{Data.VIN}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">PasGvwLen</span><span className="DetailTableData">{Data.PasGvwLen}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Vehicle Year</span><span className="DetailTableData">{Data.VehYear}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Vehicle Make</span><span className="DetailTableData">{Data.VehMake}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Vehicle Model</span><span className="DetailTableData">{Data.VehModel}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Vehicle Body</span><span className="DetailTableData">{Data.VehBody}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Vehicle Color</span><span className="DetailTableData">{Data.VehColor}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">VIN VehYear</span><span className="DetailTableData">{Data.VINVehYear}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">VIN VehMake</span><span className="DetailTableData">{Data.VINVehMake}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">VIN VehModel</span><span className="DetailTableData">{Data.VINVehModel}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">VIN VehBody</span><span className="DetailTableData">{Data.VINVehBody}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Axel Props</span><span className="DetailTableData">{Data.AxelProps}</span></div>
                </CardContent>
            </Card>
            <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                <CardContent>
                    <h3 className="mb-4 font-bold font text-xl text-blue-400">Officer Information</h3>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">ID</span><span className="DetailTableData">{Data.EmpID}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">BadgeNbr</span><span className="DetailTableData">{Data.OfficerBadgeNbr}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">FirstName</span><span className="DetailTableData">{Data.OfficerFirstName}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">MI</span><span className="DetailTableData">{Data.OfficerMiddleName}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">LastName</span><span className="DetailTableData">{Data.OfficerLastName}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">AuthLevel</span><span className="DetailTableData">{Data.OfficerAuthLevel}</span></div>
                </CardContent>
            </Card>

            <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                <CardContent>
                    <h3 className="mb-4 font-bold font text-xl text-blue-400">Agency Information</h3>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">DeptID</span><span className="DetailTableData">{Data.DeptID}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Dept Name</span><span className="DetailTableData">{Data.DeptName}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Dept Address</span><span className="DetailTableData">{Data.DeptAddress}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Dept City</span><span className="DetailTableData">{Data.DeptCity}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Dept State</span><span className="DetailTableData">{Data.DeptState}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Dept Zip</span><span className="DetailTableData">{Data.DeptZip}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Dept Phone</span><span className="DetailTableData">{Data.DeptPhone}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Dept Fax</span><span className="DetailTableData">{Data.DeptFax}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Dept Person</span><span className="DetailTableData">{Data.DeptPerson}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Dept PersonPhone</span><span className="DetailTableData">{Data.DeptPersonPhone}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Dept PersonEmail</span><span className="DetailTableData">{Data.DeptPersonEmail}</span></div>
                </CardContent>
            </Card>

            <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                <CardContent>
                    <h3 className="mb-4 font-bold font text-xl text-blue-400">Device Information</h3>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Device ORI</span><span className="DetailTableData">{Data.DeviceORI}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Device Description</span><span className="DetailTableData">{Data.DeviceDescription}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Device Type</span><span className="DetailTableData">{Data.DeviceType}</span></div>
                </CardContent>
            </Card>
        </div>
    );
}

export default InfocopDetailInfo;