import React, {useState, useEffect} from "react";
import CadRmsDetail, { CadRmsDetailPersonObject } from "../../interfaces/Detail/CadRmsDetail.interface"
import {RunCadRmsDetailQuery, RunM43RecordRMSDetailQuery} from "../../services/detail.service";
import {Box, Checkbox, FormControl, FormControlLabel, FormGroup, Tab, Tabs} from "@mui/material";
import { fontSize } from "pdfkit";
import { style } from "@mui/system";
import {convertUTCtoLocalTimeRange} from "../../services/formatDate.service";
import M43OffenseReport from "./M43/_M43_OffenseReport";
import _M43_ArrestReport from "./M43/_M43_ArrestReport";
import M43ArrestReport from "./M43/_M43_ArrestReport";


interface DetailProps {
    ID: string | null
    department: string | null
}
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const M43OffenseIncidentDetailInfo: React.FC<DetailProps> = ({ID, department}) => {

    const [DataLoaded, setDataLoaded] = React.useState<boolean>(false)
    const [data, setData] = React.useState<any[]>([])
    const [pin, setPin] = React.useState<any>({})
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };
    
    const loadData = async () => {
            
        if (!DataLoaded) {
            setDataLoaded(true)
            let res = await RunM43RecordRMSDetailQuery(ID, department);
            let tData = []
            if(res && res.length > 0){
                for(let i = 0; i < res.length; i++){
                    if(res[i].data)
                        tData.push(JSON.parse(res[i].data))
                }
            }
            setData(tData)
            console.log(tData)
           
        }
    }

    React.useEffect(() => {
        if (!DataLoaded) {
            setDataLoaded(true)
            loadData()
        }
    });

    const TabPanel = (props: TabPanelProps) => {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <>
                       {children}
                    </>
                )}
            </div>
        );
    }
    
    return (
        <>
            <div className="bg-white p-2 max-w-4xl mx-auto border border-gray-300 mt-10 ">
                <Tabs 
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    centered
                >
                {data.map((rpt, idx)=> {
                    if(rpt.externalReportType === "OFFENSE"){
                        return (
                            <Tab label={`CCN# ${rpt.reportNumber} - Offense/Incident Report`} value={idx} />
                        )
                    } else if(rpt.externalReportType === "ARREST"){
                        return (
                            <Tab label={`ARREST # ${rpt.recordNumber} (${rpt?.externalArrest?.defendant?.lastName}, ${rpt?.externalArrest?.defendant?.firstName})`} value={idx} />
                        )
                    }
                })}
                </Tabs>
            </div>
            
            
            {data.map((rpt, idx)=> {
                if(rpt.externalReportType === "OFFENSE"){
                    return (
                        <TabPanel value={value} index={idx}>
                            <M43OffenseReport rpt={rpt} key={idx} />
                        </TabPanel>
                    )
                } else if(rpt.externalReportType === "ARREST"){
                    return (
                        <TabPanel value={value} index={idx}>
                            <M43ArrestReport rpt={rpt} key={idx} />
                        </TabPanel>
                    )
                }
            })}
        </>
    );
}

export default M43OffenseIncidentDetailInfo;