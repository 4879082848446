import React, { useEffect, useState } from "react";
import {Account} from "../../interfaces/auth_interface";
import {getUser} from "../../services/auth.service";
import FAQSearch from "../../components/Support/_FAQSearch";

interface FAQsProps {
}

const FAQs: React.FC<FAQsProps> = () => {
    let [initialRun, setInitialRun] = React.useState<boolean>(false)
    let [user, setUser] = useState<Account>(getUser())

    return (
        <div>
            <FAQSearch />
        </div>
    );
}

export default FAQs;