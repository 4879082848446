import React from 'react'
import {
    Box,
    Button, ButtonGroup, Grid as MUIGrid, Dialog, DialogContent, DialogTitle, IconButton, Tooltip, TableCell, TableRow, styled, tableCellClasses, TableContainer, Paper, Table, TableHead, TableBody, useTheme, TableFooter, TablePagination, alpha, Collapse, InputBase, TextField, Input, FormControl, TableSortLabel, Card, CardHeader, CardContent, FormControlLabel, Checkbox
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import {PersonInfo} from "../../../interfaces/getDarInfo_interface";
import {
    GridComponent,
    Grid,
    Page,
    ExcelExport,
    Toolbar as SyncToolbar,
    ExcelExportProperties,
    SortSettingsModel, Column} from '@syncfusion/ej2-react-grids';
import { TransitionProps } from '@mui/material/transitions';
import { RunNCICBatch } from '../../../services/account.service';
import {getUser} from "../../../services/auth.service";
import { Account } from '../../../interfaces/auth_interface';
import { signalRConnection } from '../../../services/signalr.service';
import { HubConnectionState } from '@microsoft/signalr';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Toolbar from '@mui/material/Toolbar';
import DownloadIcon from '@mui/icons-material/Download';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SearchIcon from '@mui/icons-material/Search';
import { visuallyHidden } from '@mui/utils';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import NCIC from '../../Admin/NCIC';
import { Person } from '@mui/icons-material';

const { v4: uuidv4 } = require('uuid');

interface NCICMultipleOccurrenceProps {
    Persons?: PersonInfo[] | null,
}

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
      event: React.MouseEvent<HTMLButtonElement>,
      newPage: number,
    ) => void;
}

const TablePaginationActions = (props: TablePaginationActionsProps) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>,
    ) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

const NCICMultipleOccurrence: React.FC<NCICMultipleOccurrenceProps> = ({Persons = null})=> {
    const [typedPersons, setTypedPersons] = React.useState<any[]>([]);
    const [personList, setPersonList] = React.useState<any[]>([]);
    const [initialLoading, setInitialLoading] = React.useState(false);
    const [recordProcessingCount, setRecordProcessingCount] = React.useState<number>(0);
    let [user, setUser] = React.useState<Account>(getUser())
    const [ncicResponseResult, setNCICResponseResult] = React.useState<any>({});
    const [guid, setGUID] = React.useState<string>("");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [searchedText, setSearchedText] = React.useState<string>("");
    const [searchedTextClear, setSearchedTextClear] = React.useState<boolean>(false);
    const [disableDelete, setDisableDelete] = React.useState(false);
    const [enableFilter, setEnableFilter] = React.useState(true);
    const [expand, setExpand] = React.useState<boolean[]>([]);
    //Table sort
    const DEFAULT_ORDER = 'asc';
    const DEFAULT_ORDER_BY = 'FName';

    type Order = 'asc' | 'desc';

    const [order, setOrder] = React.useState<Order>(DEFAULT_ORDER);
    const [orderBy, setOrderBy] = React.useState<any>(DEFAULT_ORDER_BY);

    function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
        if (b[orderBy] < a[orderBy]) {
          return -1;
        }
        if (b[orderBy] > a[orderBy]) {
          return 1;
        }
        return 0;
    }

    function getComparator<Key extends keyof any>(
        order: Order,
        orderBy: Key,
      ): (
        a: { [key in Key]: number | string },
        b: { [key in Key]: number | string },
      ) => number {
        return order === 'desc'
          ? (a, b) => descendingComparator(a, b, orderBy)
          : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const onRequestSort = React.useCallback(
        (event: any, newOrderBy: any, rows: any[]) => {
          const isAsc = orderBy === newOrderBy && order === 'asc';
          const toggledOrder = isAsc ? 'desc' : 'asc';
          setOrder(toggledOrder);
          setOrderBy(newOrderBy);
    
          const sortedRows = stableSort(rows, getComparator(toggledOrder, newOrderBy));
          setTypedPersons(sortedRows);
        },
        [order, orderBy, page, rowsPerPage],
    );

    const createSortHandler = (newOrderBy: any) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, newOrderBy, typedPersons);
    };

    function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
        const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
        stabilizedThis.sort((a, b) => {
          const order = comparator(a[0], b[0]);
          if (order !== 0) {
            return order;
          }
          return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    //Table pagination
    let emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - typedPersons.length) : 0;

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDataTyping = async () =>
    {
            if (Persons && Persons.length > 0) {
                let tempP: any = [...Persons]
                let tempR: any[] = [];
                let tempE: boolean[] = [];
                for (let p of tempP) {
                     let temp = createNCICBatchReq(p);
                    if (temp.DOB) {
                        temp.DOB = new Date(temp.DOB)
                    }
                    else {
                        temp.DOB = '';
                    }

                    temp.Status = 'Not Started';
                    temp.Wanted = '';
                    temp.AOC = '';
                    tempR.push(temp);
                    tempE.push(false);
                }
                setPersonList(tempR);
                emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tempR.length) : 0;

                const sortedRows = stableSort(tempR, getComparator(DEFAULT_ORDER, DEFAULT_ORDER_BY));
                setTypedPersons(sortedRows); 
            }
            else {
                setTypedPersons([]);
                setPersonList([]);
            }
    }

    
    React.useEffect(() => {
        if(!initialLoading){
            setInitialLoading(true);
            let guid = uuidv4();
            handleDataTyping(); 
            if (Persons && Persons.length > 0) {
                let tempP: any = [...Persons]
                let tempR: any[] = [];
                for (let p of tempP){
                    let temp = createNCICBatchReq(p);
                    if (temp.DOB) {
                        temp.DOB = new Date(temp.DOB)
                    }
                    else {
                        temp.DOB = '';
                    }

                    temp.Status = 'Not Started';
                    temp.Wanted = '';
                    temp.AOC = '';
                    tempR.push(temp);
                
                } 
                setTypedPersons(tempR)
                setRecordProcessingCount(0);
                setDisableDelete(true);  
                RunNCICBatch(guid, tempR)
            }
            setupSignalRConnection(guid);
        }
    },[])

    React.useEffect(() => {
        if(ncicResponseResult && ncicResponseResult.TableID && ncicResponseResult.TableType) {
            let p = [...typedPersons];
            let idx = p.findIndex(o => o.TableID.toString() === ncicResponseResult.TableID.toString() && o.TableType.toString() === ncicResponseResult.TableType.toString());
            if(idx >= 0) {
                p[idx] = ncicResponseResult;
                if (p[idx].DOB) {
                    p[idx].DOB = new Date(p[idx].DOB)
                }
                else {
                    p[idx].DOB = '';
                }
                if(p[idx].Status !== '' && p[idx].Status === 'Completed') {
                    if (recordProcessingCount + 1 >= typedPersons.length) 
                        setDisableDelete(false);
                }

                if(ncicResponseResult.Response && ncicResponseResult.Response.length > 0) {
                    let response = JSON.parse(ncicResponseResult.Response);
                    p[idx].Keywords = response?.Keywords;
                    p[idx].FormattedDMVRsp = response?.FormattedDMVRsp;
                    p[idx].FormattedNCICRsp = response?.FormattedNCICRsp;
                }
            }
            setTypedPersons(p);  

            //Update Main list
            p = [...personList];
            idx = p.findIndex(o => o.TableID.toString() === ncicResponseResult.TableID.toString() && o.TableType.toString() === ncicResponseResult.TableType.toString());
            if(idx >= 0) {
                p[idx] = ncicResponseResult;
                if (p[idx].DOB) {
                    p[idx].DOB = new Date(p[idx].DOB)
                }
                else {
                    p[idx].DOB = '';
                }
                if(p[idx].Status !== '' && p[idx].Status === 'Completed') {
                    if (recordProcessingCount + 1 >= typedPersons.length) 
                        setDisableDelete(false);
                    setRecordProcessingCount(recordProcessingCount + 1);
                }

                if(ncicResponseResult.Response && ncicResponseResult.Response.length > 0) {
                    let response = JSON.parse(ncicResponseResult.Response);
                    p[idx].Keywords = response?.Keywords;
                    p[idx].FormattedDMVRsp = response?.FormattedDMVRsp;
                    p[idx].FormattedNCICRsp = response?.FormattedNCICRsp;
                }
            }
            setPersonList(p);
        }
        else if(ncicResponseResult && ncicResponseResult.PersonID){
            let p = [...typedPersons];
            let idx = p.findIndex(o => o.PersonID === ncicResponseResult.PersonID);
            if(idx >= 0) {
                p[idx] = ncicResponseResult;
                if (p[idx].DOB) {
                    p[idx].DOB = new Date(p[idx].DOB)
                }
                else {
                    p[idx].DOB = '';
                }
                if(p[idx].Status !== '' && p[idx].Status === 'Completed') {
                    if (recordProcessingCount + 1 >= typedPersons.length) 
                        setDisableDelete(false);
                }

                if(ncicResponseResult.Response && ncicResponseResult.Response.length > 0) {
                    let response = JSON.parse(ncicResponseResult.Response);
                    p[idx].Keywords = response?.Keywords;
                    p[idx].FormattedDMVRsp = response?.FormattedDMVRsp;
                    p[idx].FormattedNCICRsp = response?.FormattedNCICRsp;
                }
            }
            setTypedPersons(p);  

            //Update Main list
            p = [...personList];
            idx = p.findIndex(o => o.PersonID === ncicResponseResult.PersonID);
            if(idx >= 0) {
                p[idx] = ncicResponseResult;
                if (p[idx].DOB) {
                    p[idx].DOB = new Date(p[idx].DOB)
                }
                else {
                    p[idx].DOB = '';
                }
                if(p[idx].Status !== '' && p[idx].Status === 'Completed') {
                    if (recordProcessingCount + 1 >= typedPersons.length) 
                        setDisableDelete(false);
                    setRecordProcessingCount(recordProcessingCount + 1);
                }

                if(ncicResponseResult.Response && ncicResponseResult.Response.length > 0) {
                    let response = JSON.parse(ncicResponseResult.Response);
                    p[idx].Keywords = response?.Keywords;
                    p[idx].FormattedDMVRsp = response?.FormattedDMVRsp;
                    p[idx].FormattedNCICRsp = response?.FormattedNCICRsp;
                }
            }
            setPersonList(p);
        }
    }, [ncicResponseResult])

    const handleSearch = async () => {
        if(typedPersons && typedPersons.length > 0) {
            setRecordProcessingCount(0);
            setDisableDelete(true);            
            let res = await RunNCICBatch(guid, typedPersons);
        }
    }

    const setupSignalRConnection = async (guid: string) => {        
        setGUID(guid);
        let test:any = []
        let _signalRConnection = signalRConnection("/ncicbatchhub");
        _signalRConnection.on('NCICBatchResponse', (response: any) => {
            if (response?.guid?.toString() === guid?.toString()) {
                if (response?.ncic) {
                    setNCICResponseResult(response?.ncic);
                    test.push(response?.ncic)
                }
            }
        });

        try {
            await _signalRConnection.start();
        } catch (e) {
            console.log('setupSignalRConnection', e);
        }

        if (_signalRConnection.state === HubConnectionState.Connected) {
            await _signalRConnection.invoke('SubscribeToNCICBatch', guid?.toString()).catch((err: Error) => {
                return console.error('SubscribeToNCICBatch', err.toString());
            });
        }

        _signalRConnection.onclose(() => {
            _signalRConnection.stop()
            handleDisconnect(test, guid)
        })
    };

    const handleDisconnect = (test:any[], p_guid:any) => {
        setTimeout(() => { // wait 10 seconds for the server to restart.
            if(Persons) {
                let newList = Persons.filter((p: any) => {
                   return (test.filter((t: any) => t.TableID === p.TableID && t.Status.toUpperCase() === 'COMPLETED').length === 0)
                })
                let completedCount = test.filter((t:any) => t.Status.toUpperCase() === 'COMPLETED').length
                if (newList && newList.length > 0) {
                    let newRunList: any[] = [];
                    for (let p of newList){
                        let ncicPer = createNCICBatchReq(p);
                        if (ncicPer.DOB) {
                            ncicPer.DOB = new Date(ncicPer.DOB)
                        }
                        else {
                            ncicPer.DOB = '';
                        }
    
                        ncicPer.Status = 'Not Started';
                        ncicPer.Wanted = '';
                        ncicPer.AOC = '';
                        newRunList.push(ncicPer);
    
                    }
                    setRecordProcessingCount(completedCount);
                    setDisableDelete(true);
                    RunNCICBatch(p_guid, newRunList)
                }
                setupSignalRConnection(p_guid);
            }
        }, 10000)
    }

    const createNCICBatchReq = (record: any) => {
        let today: string = new Date().toLocaleDateString();
        var NCIC = {
            Status: 'Not Started',
            Selected: false,
            Wanted: '',
            AOC: '',
            UniqueID: 0,
            PlateNumber: '',
            PlateSt: '',
            PlateNumber2: '',
            PlateSt2: '',
            PlateNumber3: '',
            PlateSt3: '',
            PlateType: '',
            VehicleType: '',
            DLNo: '',
            DLSt: '',
            SSN: record?.SSN1 ? record?.SSN1 : '',
            VIN: '',
            FBINo: record?.FBI ? record?.FBI : '',
            SBINo: record?.SBINo ? record?.SBINo : '',
            SBISt: '',
            FName: record?.FName ? record?.FName : "",
            MName: record?.MName ? record?.MName : "",
            LName: record?.LName ? record?.LName : "",
            DOB: record?.DOB ? record?.DOB : '',
            SEX: record?.SEX ? record?.SEX : "",
            CaseNo: '',
            SearchNotes: '',
            Response: '',
            SearchType: '',
            EmpID: user.EmpID,
            DeptID: user.Dept,
            DeviceORI: '',
            SiteName: '',
            CreateUID: '',
            CreateDate: null,
            UpdUID: '',
            UpdDate: null,
            Active: '',
            EditStatus: 'PartialEdit',
            City: record?.City,
            DarID: record?.DarID ? record?.DarID.toString() : null,
            DeptORI: user.DeptORI,
            FromDate: record?.PercentileDate ? record.PercentileDate.toLocaleString() : today ,
            ToDate: record?.PercentileDate ? record.PercentileDate.toLocaleString() : today,
            GANGRELATED: record?.GANGRELATED,
            PersonID: record?.PersonID,
            CrumbScorePercentile: record?.CrumbScorePercentile,
            TableID: record?.TableID,
            TableType: record?.TableType,
        };
        return NCIC;
    }

    

    const deleteRecord = (row: any) => {
        let rows = [...typedPersons];
        var result = rows.findIndex(o => o.TableID === row.TableID && o.TableType === row.TableType);
        
        if(result >= 0) {
            rows.splice(result, 1);
            setTypedPersons(rows);
        }
        
        rows = [...personList];
        result = rows.findIndex(o => o.TableID === row.TableID && o.TableType === row.TableType);
        
        if(result >= 0) {
            rows.splice(result, 1);
            setPersonList(rows);
        }   
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          color: theme.palette.common.black,
        },
        [`&.${tableCellClasses.body}`]: {
        },
      }));
      
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
        //   backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
    }));

    const exportToExcel = () => {
        Grid.Inject(SyncToolbar, ExcelExport, Page);
        let tempGrid = new GridComponent({ 
            dataSource: typedPersons, 
            allowPaging: true,
            allowExcelExport: true,
            toolbar: ['ExcelExport'],
            columns: [ 
                { field: "DLNo", headerText: "DL", width: 140 },
                { field: "FName", headerText: "First Name", width: 140 },
                { field: "MName", headerText: "MI", width: 140 },
                { field: "LName", headerText: "Last Name", width: 140 },
                { field: "DOB", headerText: "DOB", type: 'date', format: 'MM/dd/yyyy', width: 140 },
                { field: "FBINo", headerText: "FBI", width: 140 },
                { field: "SBINo", headerText: "SBI", width: 140 },
                { field: "Status", headerText: "Status", width: 140 },
                { field: "Wanted", headerText: "Wanted", width: 140 },
                { field: "AOC", headerText: "AOC Warrant", width: 140 },
            ],
        });

        tempGrid.toolbarClick = (args: any) => {
            if (args['item'].id === 'Grid_excelexport') {
                tempGrid.excelExport();
            }
        }

        tempGrid.appendTo('#Grid');

        let excelProp: ExcelExportProperties = {}
        excelProp.fileName = 'NCIC_Batch.xlsx';

        (tempGrid.getColumnByField("DLNo") as Column).visible = true;
        (tempGrid.getColumnByField("FName") as Column).visible = true;
        (tempGrid.getColumnByField("MName") as Column).visible = true;
        (tempGrid.getColumnByField("LName") as Column).visible = true;
        (tempGrid.getColumnByField("DOB") as Column).visible = true;
        (tempGrid.getColumnByField("FBINo") as Column).visible = true;
        (tempGrid.getColumnByField("SBINo") as Column).visible = true;
        (tempGrid.getColumnByField("Status") as Column).visible = true;
        (tempGrid.getColumnByField("Wanted") as Column).visible = true;
        (tempGrid.getColumnByField("AOC") as Column).visible = true;

        tempGrid.excelExport(excelProp);
    }

    const filterGrid = (searchedVal: string) => {
        if(searchedVal && searchedVal.length > 0) {
            setSearchedTextClear(true);
            setSearchedText(searchedVal);
            const filteredRows = personList.filter((row) => {
                return (row.FName.toLowerCase().includes(searchedVal.toLowerCase()) || 
                row.MName.toLowerCase().includes(searchedVal.toLowerCase()) ||
                row.LName.toLowerCase().includes(searchedVal.toLowerCase()) ||
                row.FBINo.toLowerCase().includes(searchedVal.toLowerCase()) ||
                row.SBINo.toLowerCase().includes(searchedVal.toLowerCase()) ||
                row.Status.toLowerCase().includes(searchedVal.toLowerCase()) ||
                row.DLNo.toLowerCase().includes(searchedVal.toLowerCase())
                );
            });

            setTypedPersons(filteredRows);
        }
        else {
            setSearchedText("");
            setSearchedTextClear(false);
            const sortedRows = stableSort(personList, getComparator(order, orderBy));
            setTypedPersons(sortedRows);
        }
    }

    const clearFilter = () => {
        setSearchedText("");
        setSearchedTextClear(false);
        const sortedRows = stableSort(personList, getComparator(order, orderBy));
        setTypedPersons(sortedRows);
    }

    const handleFilter =  () => {
        setEnableFilter(!enableFilter)
    }


    function CollapsableRow(props: { row: ReturnType<any>, idx : number}) {
        const { row , idx } = props;
        const ControlExpand = () => {
            let tempExpand : boolean[]= [...expand]
            tempExpand[idx]= !tempExpand[idx]
            setExpand(tempExpand)
        }
        return (
          <React.Fragment>
            <StyledTableRow key={row.id} sx={{ '& > *': { borderBottom: 'unset' } }}>
                <StyledTableCell component="th" scope="row" width={140}>
                {row && (row.Status === 'Completed' || row.Status === 'Failed') ? 
                    <IconButton
                    style={{'paddingLeft': '0px'}}
                    aria-label="expand row"
                    onClick={() => ControlExpand()}
                >
                    {expand[idx] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }
                </IconButton>: <></>}
                    <span style={{'paddingLeft':'2px'}}>{row.DLNo}</span></StyledTableCell>
                <StyledTableCell align="center" width={140}>{row.FName}</StyledTableCell>
                <StyledTableCell align="center" width={110}>{row.MName}</StyledTableCell>
                <StyledTableCell align="center" width={140}>{row.LName}</StyledTableCell>
                <StyledTableCell align="center" width={115}>{row.DOB !== '' ? row.DOB.toLocaleDateString() : row.DOB}</StyledTableCell>
                <StyledTableCell align="center" width={140}>{row.FBINo}</StyledTableCell>
                <StyledTableCell align="center" width={140}>{row.SBINo}</StyledTableCell>
                <StyledTableCell align="center" width={140}>{row.Status}</StyledTableCell>
                <StyledTableCell align="center" width={120}>{row.Wanted}</StyledTableCell>
                <StyledTableCell align="center" width={140}>{row.AOC}</StyledTableCell>
                <StyledTableCell align="center" width={80}>
                    <Tooltip title="Delete" placement="top" arrow>
                        <span><IconButton disabled={disableDelete} onClick={(e: any) => {deleteRecord(row)}}><DeleteIcon fontSize='small' color='error'/></IconButton></span>
                    </Tooltip>
                </StyledTableCell>
            </StyledTableRow>
            <TableRow>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <Collapse in={expand[idx]} timeout="auto" unmountOnExit>
                  <Card sx={{ margin: 1 }} className="mx-auto mt-5" variant="outlined">
                    {row?.ErrorMessage && row?.ErrorMessage?.length !== 0 ? <div className="text-red-400 font-bold text-base text-center mt-1">{row?.ErrorMessage.join(",")}</div> : <>
                    <CardHeader className="bg-black text-blue-400 font-bold text-xl " title="NCIC Response" />
                    <CardContent className="bg-black">
                    <MUIGrid container spacing={2} className="text-white">
                        <MUIGrid item xs={12} sx={{"display": "flex", whiteSpace: "pre-wrap", overflowWrap: "break-word"}}>
                            {row?.Keywords}
                        </MUIGrid>
                        <MUIGrid item xs={12} sx={{"display": "flex", whiteSpace: "pre-wrap", overflowWrap: "break-word"}}>
                            {row?.FormattedDMVRsp}
                        </MUIGrid>
                        <MUIGrid item xs={12} sx={{"display": "flex", whiteSpace: "pre-wrap", overflowWrap: "break-word"}}>
                            {row?.FormattedNCICRsp}
                        </MUIGrid>
                    </MUIGrid></CardContent></>}           
                  </Card>
                </Collapse>
              </TableCell>
            </TableRow>
          </React.Fragment>
        );
    }

    return (
        <div className="m-5 p-5">
            <Box className="pt-3" style={{ justifyContent:'center' }}>
                <span style={{'paddingLeft': '3rem'}}>{recordProcessingCount} of {typedPersons.length} records completed</span>
                <div className={"pl-10 pr-10 mt-5"}>
                    <Toolbar className='customTable_toolbar'>
                            {personList && personList.length>0 ? 
                                <ButtonGroup size="medium" color="inherit" >
                                        <Button variant="outlined" onClick={exportToExcel} startIcon={<DownloadIcon />}>Excel Export</Button>
                            </ButtonGroup> : <></>}
                            <div style={{ 'position': 'relative', 'left': '10px' }}>
                                <Paper
                                    component="form"
                                        sx={{ display: 'flex', alignItems: 'center', width: 300 }}
                                    >
                                    <FormControl sx={{ width: '25ch' }}>
                                        <InputBase
                                            key="search_input"
                                            sx={{ ml: 1, flex: 1 }}
                                            placeholder="Search"
                                            onChange={(e:any) => filterGrid(e.target.value)}
                                            value={searchedText}
                                        />
                                    </FormControl>
                                    {!searchedTextClear ? 
                                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                        <SearchIcon />
                                    </IconButton>
                                :
                                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={clearFilter}>
                                        <CloseIcon />
                                    </IconButton>}
                                            
                                </Paper>
                        </div>
                        <div style={{'position':'absolute', 'right': '0px'}}>
                            <FormControlLabel
                                control={<Checkbox defaultChecked/>}
                                label="NCIC HIT/AOC (Y)"
                                onClick={handleFilter}
                            
                            
                            />
                        </div>
                    </Toolbar>          
                            {personList && personList.length>0 ? <>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 700 }} aria-label="customized table" size='small'>
                                        <TableHead>
                                            <TableRow>
                                                {/* <TableCell width={50}></TableCell> */}
                                                <StyledTableCell key='DLNo' align="center" width={140} sortDirection={orderBy === 'DLNo' ? order : false}>
                                                    <TableSortLabel
                                                        active={orderBy === 'DLNo'}
                                                        direction={orderBy === 'DLNo' ? order : 'asc'}
                                                        onClick={createSortHandler('DLNo')}
                                                        >
                                                        DL
                                                        {orderBy ==='DLNo' ? (
                                                            <Box component="span" sx={visuallyHidden}>
                                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                            </Box>
                                                        ) : null}
                                                    </TableSortLabel>
                                                </StyledTableCell>
                                                <StyledTableCell key='FName' align="center" width={140} sortDirection={orderBy === 'FName' ? order : false} >
                                                    <TableSortLabel
                                                            active={orderBy === 'FName'}
                                                            direction={orderBy === 'FName' ? order : 'asc'}
                                                            onClick={createSortHandler('FName')}
                                                            >
                                                            First Name
                                                            {orderBy ==='FName' ? (
                                                                <Box component="span" sx={visuallyHidden}>
                                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                                </Box>
                                                            ) : null}
                                                        </TableSortLabel></StyledTableCell>
                                                <StyledTableCell key='MName' align="center" width={110} sortDirection={orderBy === 'MName' ? order : false}>
                                                    <TableSortLabel
                                                            active={orderBy === 'MName'}
                                                            direction={orderBy === 'MName' ? order : 'asc'}
                                                            onClick={createSortHandler('MName')}
                                                            >
                                                            MI
                                                            {orderBy ==='MName' ? (
                                                                <Box component="span" sx={visuallyHidden}>
                                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                                </Box>
                                                            ) : null}
                                                        </TableSortLabel></StyledTableCell>
                                                <StyledTableCell key='LName' align="center" width={140} sortDirection={orderBy === 'LName' ? order : false} >
                                                    <TableSortLabel
                                                            active={orderBy === 'LName'}
                                                            direction={orderBy === 'LName' ? order : 'asc'}
                                                            onClick={createSortHandler('LName')}
                                                            >
                                                            Last Name
                                                            {orderBy ==='LName' ? (
                                                                <Box component="span" sx={visuallyHidden}>
                                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                                </Box>
                                                            ) : null}
                                                        </TableSortLabel></StyledTableCell>
                                                <StyledTableCell key='DOB' align="center" width={115} sortDirection={orderBy === 'DOB' ? order : false}>
                                                    <TableSortLabel
                                                            active={orderBy === 'DOB'}
                                                            direction={orderBy === 'DOB' ? order : 'asc'}
                                                            onClick={createSortHandler('DOB')}
                                                            >
                                                            DOB
                                                            {orderBy ==='DOB' ? (
                                                                <Box component="span" sx={visuallyHidden}>
                                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                                </Box>
                                                            ) : null}
                                                        </TableSortLabel></StyledTableCell>
                                                <StyledTableCell key='FBINo' align="center" width={140} sortDirection={orderBy === 'FBINo' ? order : false}>
                                                    <TableSortLabel
                                                            active={orderBy === 'FBINo'}
                                                            direction={orderBy === 'FBINo' ? order : 'asc'}
                                                            onClick={createSortHandler('FBINo')}
                                                            >
                                                            FBI
                                                            {orderBy ==='DLFBINoNo' ? (
                                                                <Box component="span" sx={visuallyHidden}>
                                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                                </Box>
                                                            ) : null}
                                                        </TableSortLabel></StyledTableCell>
                                                <StyledTableCell key='SBINo' align="center" width={140} sortDirection={orderBy === 'SBINo' ? order : false}>
                                                    <TableSortLabel
                                                            active={orderBy === 'SBINo'}
                                                            direction={orderBy === 'SBINo' ? order : 'asc'}
                                                            onClick={createSortHandler('SBINo')}
                                                            >
                                                            SBI
                                                            {orderBy ==='SBINo' ? (
                                                                <Box component="span" sx={visuallyHidden}>
                                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                                </Box>
                                                            ) : null}
                                                        </TableSortLabel></StyledTableCell>
                                                <StyledTableCell key='Status' align="center" width={140} sortDirection={orderBy === 'Status' ? order : false}>
                                                    <TableSortLabel
                                                            active={orderBy === 'Status'}
                                                            direction={orderBy === 'Status' ? order : 'asc'}
                                                            onClick={createSortHandler('Status')}
                                                            >
                                                            Status
                                                            {orderBy ==='Status' ? (
                                                                <Box component="span" sx={visuallyHidden}>
                                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                                </Box>
                                                            ) : null}
                                                        </TableSortLabel></StyledTableCell>
                                                <StyledTableCell key='Wanted' align="center" width={120} sortDirection={orderBy === 'Wanted' ? order : false}>
                                                    <TableSortLabel
                                                            active={orderBy === 'Wanted'}
                                                            direction={orderBy === 'Wanted' ? order : 'asc'}
                                                            onClick={createSortHandler('Wanted')}
                                                            >
                                                            NCIC HIT
                                                            {orderBy ==='Wanted' ? (
                                                                <Box component="span" sx={visuallyHidden}>
                                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                                </Box>
                                                            ) : null}
                                                        </TableSortLabel></StyledTableCell>
                                                <StyledTableCell key='AOC' align="center" width={140} sortDirection={orderBy === 'AOC' ? order : false}>
                                                    <TableSortLabel
                                                            active={orderBy === 'AOC'}
                                                            direction={orderBy === 'AOC' ? order : 'asc'}
                                                            onClick={createSortHandler('AOC')}
                                                            >
                                                            AOC Warrant
                                                            {orderBy ==='AOC' ? (
                                                                <Box component="span" sx={visuallyHidden}>
                                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                                </Box>
                                                            ) : null}
                                                        </TableSortLabel></StyledTableCell>
                                                <StyledTableCell key='Action' align="center" width={80}>Action</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {(rowsPerPage > 0 
                                            ? (enableFilter ? typedPersons?.filter((person) => person.Wanted === 'Y' || person.AOC === 'Y' || person.Status != 'Completed') : typedPersons).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            : (enableFilter ? typedPersons?.filter((person) => person.Wanted === 'Y' || person.AOC === 'Y' || person.Status != 'Completed') : typedPersons)
                                            // ? typedPersons.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            // : typedPersons
                                        ).map((row, idx) => (<>
                                            <CollapsableRow key={row.id} row={row} idx={idx}></CollapsableRow>
                                            </>))}
                                        {emptyRows > 0 && (
                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                // style={{'display': 'inline'}}
                                                rowsPerPageOptions={[10, 25, 50, 100]}
                                                colSpan={15}
                                                count={typedPersons.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                SelectProps={{
                                                    inputProps: {
                                                    'aria-label': 'rows per page',
                                                    },
                                                    native: true,
                                                }}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationActions}
                                                />
                                            </TableRow>
                                            </TableFooter>
                                    </Table>
                                </TableContainer>
                                <div style={{'display': 'none'}} id='Grid'></div>
                                <div className={"pt-3"} style={{"display": "flex"}}>
                                    <MUIGrid container spacing={2}>
                                        <MUIGrid item xs={12} sx={{"justifyContent":"end", "display": "flex"}}>
                                            <ButtonGroup size="large" color="inherit" >
                                                <Button style={{'minWidth': '125px'}} type="submit" onClick={handleSearch}>Search</Button>
                                            </ButtonGroup>
                                        </MUIGrid>
                                    </MUIGrid>
                                </div>
                            </>:<div className="font-bold text-center text-lg pt-3">NO Records Found</div>}
                </div>
            </Box>
        </div>
    );
};

export default NCICMultipleOccurrence;