import React, {useEffect, useRef, useState} from "react";
import {
    GetChainNibinJSON, GetLinkAnalysisData,
    GetPADetailsRun,
    GetPersonAnalysis, GetPersonAnalysis_GenNIBINNodes,
    GetPersonAnalysis_GetPersonInfo, GetPersonAnalysis_PlateRegisteredOwner,
    GetPersonImage,
} from "../../services/linkanalysis.service";
import {useLocation} from "react-router-dom";
import {isUserPermission} from "../../services/auth.service";
import {Chart, Timebar} from "../../react-keylines";
import {
    Button,
    Card,
    CardContent,
    Checkbox
} from "@mui/material";
import {formatDate, formatDT} from "../../services/formatDate.service";
import {JAOS} from "../../services/JAOS.service";
import {OpenNIBINPDF, OpenNJPOPPDF} from "../../services/detail.service";
import InfoCopIcon from "../../assets/Images/ic1.png";
import PersonPhotoIcon from "../../assets/Images/photo1.png";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {SiteName} from "../../services/config.service";
import {GetStringNameForSite, SITE} from "../../interfaces/config.interface";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function PersonAnalysis() {
    let query = useQuery();

    // chartLoc is used OUTSIDE (aka custom) of a Keylines function. chartRef is used INSIDE keylines functions.
    var chartLoc: any = null
    var timeLoc: any = null
    
    const [chartRef, setChartRef] = React.useState<any>(null)
    const [timeRef, setTimeRef] = React.useState<any>(null)
    
    const [data, setData] = React.useState<any>({type: 'LinkChart', items: []})
    const [loading, setLoading] = React.useState<boolean>(true)

    //For ToolTop Box
    const [toolTip, setToolTip] = React.useState<any>()
    const [toolTipStyle, setToolTipStyle] = React.useState<any>({})
    const myContainer = React.useRef<any>(null)

    //Hide Checkboxes
    let [isFilterDialogOpen, setFilterDialogOpen] = React.useState<boolean>(false)

    const [showAssocPeople, setShowAssocPeople] = React.useState<boolean>(true)
    const [showAssocPeopleAgencyCase, setShowAssocPeopleAgencyCase] = React.useState<boolean>(false)
    const [showAssocPeopleIncidentAddress, setShowAssocPeopleIncidentAddress] = React.useState<boolean>(false)
    const [showAssocPeopleHomeAddressAFIS, setShowAssocPeopleHomeAddressAFIS] = React.useState<boolean>(false)
    const [showAssocPeopleHomeAddressDMV, setShowAssocPeopleHomeAddressDMV] = React.useState<boolean>(false)
    const [showAssocPeoplePlateETicket, setShowAssocPeoplePlateETicket] = React.useState<boolean>(false)
    const [showAssocPeopleCODISPerson, setShowAssocPeopleCODISPerson] = React.useState<boolean>(true)
    const [showAssocPeopleCADRMSCase, setShowAssocPeopleCADRMSCase] = React.useState<boolean>(false)
    const [showAssocPeopleCADRMSPlate, setShowAssocPeopleCADRMSPlate] = React.useState<boolean>(false)
    const [showAssocPeopleCADRMSVIN, setShowAssocPeopleCADRMSVIN] = React.useState<boolean>(false)
    const [showAssocPeopleGunTraceCase, setShowAssocPeopleGunTraceCase] = React.useState<boolean>(true)
    const [showAssocPeopleShootingCase, setShowAssocPeopleShootingCase] = React.useState<boolean>(true)
    const [showAssocPeopleNIBINCase, setShowAssocPeopleNIBINCase] = React.useState<boolean>(true)
    const [showAssocPeopleDL, setShowAssocPeopleDL] = React.useState<boolean>(false)
    const [showAssocPeopleFBI, setShowAssocPeopleFBI] = React.useState<boolean>(false)
    const [showAssocPeopleSBI, setShowAssocPeopleSBI] = React.useState<boolean>(false)
    const [showAssocPeopleTowedPlate, setShowAssocPeopleTowedPlate] = React.useState<boolean>(false)
    const [showAssocPeopleTowedVIN, setShowAssocPeopleTowedVIN] = React.useState<boolean>(false)
    const [showAssocPeopleDMV, setShowAssocPeopleDMV] = React.useState<boolean>(false)
    const [showArrest, setShowArrest] = React.useState<boolean>(false)
    const [showALPR, setShowALPR] = React.useState<boolean>(false)
    const [showPlate, setShowPlate] = React.useState<boolean>(false)
    const [showCADRMS, setShowCADRMS] = React.useState<boolean>(false)
    const [showDNA, setShowDNA] = React.useState<boolean>(true)
    const [showETicket, setShowETicket] = React.useState<boolean>(false)
    const [showETrace, setShowETrace] = React.useState<boolean>(true)
    const [showNIBIN, setShowNIBIN] = React.useState<boolean>(true)
    const [showNJPOP, setShowNJPOP] = React.useState<boolean>(true)
    const [shownjtrace, setShownjtrace] = React.useState<boolean>(true)
    const [showParkMobile, setShowParkMobile] = React.useState<boolean>(false)
    const [showParole, setShowParole] = React.useState<boolean>(true)
    const [showPrison, setShowPrison] = React.useState<boolean>(true)
    const [showProfession, setShowProfession] = React.useState<boolean>(false)
    const [showViolent, setShowViolent] = React.useState<boolean>(false)
    const [showInfoCop, setShowInfoCop] = React.useState<boolean>(false)
    const [showWeapon, setShowWeapon] = React.useState<boolean>(true)
    const [showTimeline, setShowTimeline] = React.useState<boolean>(false)
    const [showJail, setShowJail] = React.useState<boolean>(false)
    const [showMissingPerson, setShowMissingPerson] = React.useState<boolean>(false)
    
    function saveFilterState(){
        localStorage.setItem('showAssocPeople', showAssocPeople.toString());
        localStorage.setItem('showAssocPeopleAgencyCase', showAssocPeopleAgencyCase.toString());
        localStorage.setItem('showAssocPeopleIncidentAddress', showAssocPeopleIncidentAddress.toString());
        localStorage.setItem('showAssocPeopleHomeAddressAFIS', showAssocPeopleHomeAddressAFIS.toString());
        localStorage.setItem('showAssocPeopleHomeAddressDMV', showAssocPeopleHomeAddressDMV.toString());
        localStorage.setItem('showAssocPeoplePlateETicket', showAssocPeoplePlateETicket.toString());
        localStorage.setItem('showAssocPeopleCODISPerson', showAssocPeopleCODISPerson.toString());
        localStorage.setItem('showAssocPeopleCADRMSCase', showAssocPeopleCADRMSCase.toString());
        localStorage.setItem('showAssocPeopleCADRMSPlate', showAssocPeopleCADRMSPlate.toString());
        localStorage.setItem('showAssocPeopleCADRMSVIN', showAssocPeopleCADRMSVIN.toString());
        localStorage.setItem('showAssocPeopleGunTraceCase', showAssocPeopleGunTraceCase.toString());
        localStorage.setItem('showAssocPeopleShootingCase', showAssocPeopleShootingCase.toString());
        localStorage.setItem('showAssocPeopleNIBINCase', showAssocPeopleNIBINCase.toString());
        localStorage.setItem('showAssocPeopleDL', showAssocPeopleDL.toString());
        localStorage.setItem('showAssocPeopleFBI', showAssocPeopleFBI.toString());
        localStorage.setItem('showAssocPeopleSBI', showAssocPeopleSBI.toString());
        localStorage.setItem('showAssocPeopleTowedPlate', showAssocPeopleTowedPlate.toString());
        localStorage.setItem('showAssocPeopleTowedVIN', showAssocPeopleTowedVIN.toString());
        localStorage.setItem('showAssocPeopleDMV', showAssocPeopleDMV.toString());
        localStorage.setItem('showArrest', showArrest.toString());
        localStorage.setItem('showALPR', showALPR.toString());
        localStorage.setItem('showPlate', showPlate.toString());
        localStorage.setItem('showCADRMS', showCADRMS.toString());
        localStorage.setItem('showDNA', showDNA.toString());
        localStorage.setItem('showETicket', showETicket.toString());
        localStorage.setItem('showETrace', showETrace.toString());
        localStorage.setItem('showNIBIN', showNIBIN.toString());
        localStorage.setItem('showNJPOP', showNJPOP.toString());
        localStorage.setItem('shownjtrace', shownjtrace.toString());
        localStorage.setItem('showParkMobile', showParkMobile.toString());
        localStorage.setItem('showParole', showParole.toString());
        localStorage.setItem('showPrison', showPrison.toString());
        localStorage.setItem('showProfession', showProfession.toString());
        localStorage.setItem('showViolent', showViolent.toString());
        localStorage.setItem('showInfoCop', showInfoCop.toString());
        localStorage.setItem('showWeapon', showWeapon.toString());
        localStorage.setItem('showJail', showJail.toString());
        localStorage.setItem('showMissingPerson', showMissingPerson.toString());
    }

    function loadFilterState(){
        setShowAssocPeople(localStorage.getItem('showAssocPeople')=== 'true')
        setShowAssocPeopleAgencyCase(localStorage.getItem('showAssocPeopleAgencyCase')=== 'true')
        setShowAssocPeopleIncidentAddress(localStorage.getItem('showAssocPeopleIncidentAddress')=== 'true')
        setShowAssocPeopleHomeAddressAFIS(localStorage.getItem('showAssocPeopleHomeAddressAFIS')=== 'true')
        setShowAssocPeopleHomeAddressDMV(localStorage.getItem('showAssocPeopleHomeAddressDMV')=== 'true')
        setShowAssocPeoplePlateETicket(localStorage.getItem('showAssocPeoplePlateETicket')=== 'true')
        setShowAssocPeopleCODISPerson(localStorage.getItem('showAssocPeopleCODISPerson')=== 'true')
        setShowAssocPeopleCADRMSCase(localStorage.getItem('showAssocPeopleCADRMSCase')=== 'true')
        setShowAssocPeopleCADRMSPlate(localStorage.getItem('showAssocPeopleCADRMSPlate')=== 'true')
        setShowAssocPeopleCADRMSVIN(localStorage.getItem('showAssocPeopleCADRMSVIN')=== 'true')
        setShowAssocPeopleGunTraceCase(localStorage.getItem('showAssocPeopleGunTraceCase',)=== 'true')
        setShowAssocPeopleShootingCase(localStorage.getItem('showAssocPeopleShootingCase')=== 'true')
        setShowAssocPeopleNIBINCase(localStorage.getItem('showAssocPeopleNIBINCase')=== 'true')
        setShowAssocPeopleDL(localStorage.getItem('showAssocPeopleDL')=== 'true')
        setShowAssocPeopleFBI(localStorage.getItem('showAssocPeopleFBI')=== 'true')
        setShowAssocPeopleSBI(localStorage.getItem('showAssocPeopleSBI')=== 'true')
        setShowAssocPeopleTowedPlate(localStorage.getItem('showAssocPeopleTowedPlate')=== 'true')
        setShowAssocPeopleTowedVIN(localStorage.getItem('showAssocPeopleTowedVIN')=== 'true')
        setShowAssocPeopleDMV(localStorage.getItem('showAssocPeopleDMV')=== 'true')
        setShowArrest(localStorage.getItem('showArrest')=== 'true')
        setShowALPR(localStorage.getItem('showALPR')=== 'true')
        setShowPlate(localStorage.getItem('showPlate')=== 'true')
        setShowCADRMS(localStorage.getItem('showCADRMS')=== 'true')
        setShowDNA(localStorage.getItem('showDNA')=== 'true')
        setShowETicket(localStorage.getItem('showETicket')=== 'true')
        setShowETrace(localStorage.getItem('showETrace')=== 'true')
        setShowNIBIN(localStorage.getItem('showNIBIN')=== 'true')
        setShowNJPOP(localStorage.getItem('showNJPOP')=== 'true')
        setShownjtrace(localStorage.getItem('shownjtrace')=== 'true')
        setShowParkMobile(localStorage.getItem('showParkMobile')=== 'true')
        setShowParole(localStorage.getItem('showParole')=== 'true')
        setShowPrison(localStorage.getItem('showPrison')=== 'true')
        setShowProfession(localStorage.getItem('showProfession')=== 'true')
        setShowViolent(localStorage.getItem('showViolent')=== 'true')
        setShowInfoCop(localStorage.getItem('showInfoCop') === 'true')
        setShowWeapon(localStorage.getItem('showWeapon') === 'true')
        setShowJail(localStorage.getItem('showJail') === 'true')
        setShowJail(localStorage.getItem('showMissingPerson') === 'true')
    }
    
    function setToggle(item:string){
        switch(item){
            case 'WEAPON':
                setShowWeapon(v => !v)
                break;
        }
    }
    
    function runFilterOptions() {
        loadFilterState()
        let graph = chartLoc ? chartLoc : chartRef;
        if (graph) {
            if (!showArrest) {
                let ids: any = []
                graph.each({type: 'node'}, async (item: any) => {
                    if ((item.d.type == 'ARREST_NODE') || (item.d.type == 'PERSON_ARREST_CONTAINER'))
                        ids.push(item.id)
                });
                graph.hide(ids, {animate: false})
            } else {
                let ids: any = []
                graph.each({type: 'node'}, async (item: any) => {
                    if ((item.d.type == 'ARREST_NODE') || (item.d.type == 'PERSON_ARREST_CONTAINER'))
                        ids.push(item.id)
                });
                graph.show(ids, {animate: false})
            }

            if (!showALPR) {
                let ids: any = []
                graph.each({type: 'node'}, async (item: any) => {
                    if ((item.d.type == 'ALPR_NODE') || (item.d.type == 'PERSON_ALPR_CONTAINER'))
                        ids.push(item.id)
                });
                graph.hide(ids, {animate: false})
            } else {
                let ids: any = []
                graph.each({type: 'node'}, async (item: any) => {
                    if ((item.d.type == 'ALPR_NODE')  || (item.d.type == 'PERSON_ALPR_CONTAINER'))
                        ids.push(item.id)
                });
                graph.show(ids, {animate: false})
            }

            if (!showPlate) {
                let ids: any = []
                graph.each({type: 'node'}, async (item: any) => {
                    if ((item.d.type == 'PLATE_NODE') || (item.d.type == 'PERSON_Plate_CONTAINER'))
                        ids.push(item.id)
                });
                graph.hide(ids, {animate: false})
            } else {
                let ids: any = []
                graph.each({type: 'node'}, async (item: any) => {
                    if ((item.d.type == 'PLATE_NODE') || (item.d.type == 'PERSON_Plate_CONTAINER'))
                        ids.push(item.id)
                });
                graph.show(ids, {animate: false})
            }

            if (!showAssocPeople) {
                let ids: any = []
                graph.each({type: 'node'}, async (item: any) => {
                    if ((item.d.type == 'ASSOCPEOPLE_NODE') || (item.d.type == 'PERSON_ASSOCPEOPLE_CONTAINER'))
                        ids.push(item.id)
                });
                graph.hide(ids, {animate: false})
            } else {
                let ids: any = []
                let hide_ids: any = []
                graph.each({type: 'node'}, async (item: any) => {
                    if ((item.d.type == 'ASSOCPEOPLE_NODE') || (item.d.type == 'PERSON_ASSOCPEOPLE_CONTAINER'))
                        if (item.d.data.AType) {
                            switch (item.d.data.AType) {
                                case 'Agency Case':
                                    if (showAssocPeopleAgencyCase)
                                        ids.push(item.id)
                                    else
                                        hide_ids.push(item.id)
                                    break;
                                case 'Incident Address':
                                    if (showAssocPeopleIncidentAddress)
                                        ids.push(item.id)
                                    else
                                        hide_ids.push(item.id)
                                    break;
                                case 'Home Address(AFIS)':
                                    if (showAssocPeopleHomeAddressAFIS)
                                        ids.push(item.id)
                                    else
                                        hide_ids.push(item.id)
                                    break;
                                case 'Home Address(DMV)':
                                    if (showAssocPeopleHomeAddressDMV)
                                        ids.push(item.id)
                                    else
                                        hide_ids.push(item.id)
                                    break;
                                case 'Plate(ETicket)':
                                    if (showAssocPeoplePlateETicket)
                                        ids.push(item.id)
                                    else
                                        hide_ids.push(item.id)
                                    break;
                                case 'CODIS Person':
                                    if (showAssocPeopleCODISPerson)
                                        ids.push(item.id)
                                    else
                                        hide_ids.push(item.id)
                                    break;
                                case 'CAD/RMS Case':
                                    if (showAssocPeopleCADRMSCase)
                                        ids.push(item.id)
                                    else
                                        hide_ids.push(item.id)
                                    break;
                                case 'CAD/RMS Plate':
                                    if (showAssocPeopleCADRMSPlate)
                                        ids.push(item.id)
                                    else
                                        hide_ids.push(item.id)
                                    break;
                                case 'CAD/RMS VIN':
                                    if (showAssocPeopleCADRMSVIN)
                                        ids.push(item.id)
                                    else
                                        hide_ids.push(item.id)
                                    break;
                                case 'Gun-Trace Case':
                                    if (showAssocPeopleGunTraceCase)
                                        ids.push(item.id)
                                    else
                                        hide_ids.push(item.id)
                                    break;
                                case 'Shooting Case':
                                    if (showAssocPeopleShootingCase)
                                        ids.push(item.id)
                                    else
                                        hide_ids.push(item.id)
                                    break;
                                case 'NIBIN Case':
                                    if (showAssocPeopleNIBINCase)
                                        ids.push(item.id)
                                    else
                                        hide_ids.push(item.id)
                                    break;
                                case 'DL #':
                                    if (showAssocPeopleDL)
                                        ids.push(item.id)
                                    else
                                        hide_ids.push(item.id)
                                    break;
                                case 'FBI #':
                                    if (showAssocPeopleFBI)
                                        ids.push(item.id)
                                    else
                                        hide_ids.push(item.id)
                                    break;
                                case 'Towed Plate':
                                    if (showAssocPeopleTowedPlate)
                                        ids.push(item.id)
                                    else
                                        hide_ids.push(item.id)
                                    break;
                                case 'Towed VIN':
                                    if (showAssocPeopleTowedVIN)
                                        ids.push(item.id)
                                    else
                                        hide_ids.push(item.id)
                                    break;
                                case 'SBI #':
                                    if (showAssocPeopleSBI)
                                        ids.push(item.id)
                                    else
                                        hide_ids.push(item.id)
                                    break;
                                case 'DMV':
                                    if (showAssocPeopleDMV)
                                        ids.push(item.id)
                                    else
                                        hide_ids.push(item.id)
                                    break;
                                default:
                                    ids.push(item.id)
                                    break;

                            }
                        } else {
                            ids.push(item.id)
                        }
                });

                graph.show(ids, {animate: false}).then(() => {
                    graph.hide(hide_ids, {animate: false})
                })

            }

            if (!showCADRMS) {
                let ids: any = []
                graph.each({type: 'node'}, async (item: any) => {
                    if ((item.d.type == 'CADRMS_NODE') || (item.d.type == 'PERSON_CADRMS_CONTAINER')) {
                        ids.push(item.id)
                        ids.push(...Array.from(findChildNodes(item.id)))
                    }
                });
                graph.hide(ids, {animate: false})
            } else {
                let ids: any = []
                graph.each({type: 'node'}, async (item: any) => {
                    if ((item.d.type == 'CADRMS_NODE') || (item.d.type == 'PERSON_CADRMS_CONTAINER')) {
                        ids.push(item.id)
                        ids.push(...Array.from(findChildNodes(item.id)))
                    }
                });
                graph.show(ids, {animate: false})
            }
            if (!showDNA) {
                let ids: any = []
                graph.each({type: 'node'}, async (item: any) => {
                    if ((item.d.type == 'DNA_NODE') || (item.d.type == 'PERSON_DNA_CONTAINER')) {
                        ids.push(item.id)
                        ids.push(...Array.from(findChildNodes(item.id)))
                    }
                });
                graph.hide(ids, {animate: false})
            } else {
                let ids: any = []
                graph.each({type: 'node'}, async (item: any) => {
                    if ((item.d.type == 'DNA_NODE') || (item.d.type == 'PERSON_DNA_CONTAINER')) {
                        ids.push(item.id)
                        ids.push(...Array.from(findChildNodes(item.id)))
                    }
                });
                graph.show(ids, {animate: false})
            }
            if (!showETicket) {
                let ids: any = []
                graph.each({type: 'node'}, async (item: any) => {
                    if ((item.d.type == 'ETICKET_NODE')|| (item.d.type == 'PERSON_ETICKET_CONTAINER')) {
                        ids.push(item.id)
                        ids.push(...Array.from(findChildNodes(item.id)))
                    }
                });
                graph.hide(ids, {animate: false})
            } else {
                let ids: any = []
                graph.each({type: 'node'}, async (item: any) => {
                    if ((item.d.type == 'ETICKET_NODE')|| (item.d.type == 'PERSON_ETICKET_CONTAINER')) {
                        ids.push(item.id)
                        ids.push(...Array.from(findChildNodes(item.id)))
                    }
                });
                graph.show(ids, {animate: false})
            }
            if (!showETrace) {
                let ids: any = []
                graph.each({type: 'node'}, async (item: any) => {
                    if ((item.d.type == 'ETRACE_NODE') || (item.d.type == 'PERSON_ETRACE_CONTAINER')) {
                        ids.push(item.id)
                        ids.push(...Array.from(findChildNodes(item.id)))
                    }
                });
                graph.hide(ids, {animate: false})
            } else {
                let ids: any = []
                graph.each({type: 'node'}, async (item: any) => {
                    if ((item.d.type == 'ETRACE_NODE') || (item.d.type == 'PERSON_ETRACE_CONTAINER')) {
                        ids.push(item.id)
                        ids.push(...Array.from(findChildNodes(item.id)))
                    }
                });
                graph.show(ids, {animate: false})
            }
            if (!showNIBIN) {
                let ids: any = []

                graph.each({type: 'node'}, async (item: any) => {
                    if ((item.d.type == 'NIBIN_NODE')|| (item.d.type == 'PERSON_NIBIN_CONTAINER')) {
                        ids.push(item.id)
                        ids.push(...Array.from(findChildNodes(item.id)))
                    }
                });
                graph.hide(ids, {animate: false})
            } else {
                let ids: any = []
                graph.each({type: 'node'}, async (item: any) => {
                    if ((item.d.type == 'NIBIN_NODE') || (item.d.type == 'PERSON_NIBIN_CONTAINER')) {
                        ids.push(item.id)
                        ids.push(...Array.from(findChildNodes(item.id)))
                    }
                });
                graph.show(ids, {animate: false})
            }
            if (!showNJPOP) {
                let ids: any = []
                graph.each({type: 'node'}, async (item: any) => {
                    if ((item.d.type == 'NJPOP_NODE') || (item.d.type == 'PERSON_NJPOP_CONTAINER')) {
                        ids.push(item.id)
                        ids.push(...Array.from(findChildNodes(item.id)))
                    }
                });
                graph.hide(ids, {animate: false})
            } else {
                let ids: any = []
                graph.each({type: 'node'}, async (item: any) => {
                    if ((item.d.type == 'NJPOP_NODE') || (item.d.type == 'PERSON_NJPOP_CONTAINER')) {
                        ids.push(item.id)
                        ids.push(...Array.from(findChildNodes(item.id)))
                    }
                });
                graph.show(ids, {animate: false})
            }
            if (!shownjtrace) {
                let ids: any = []
                graph.each({type: 'node'}, async (item: any) => {
                    if ((item.d.type == 'NJTRACE_NODE') || (item.d.type == 'PERSON_NJTRACE_CONTAINER')) {
                        ids.push(item.id)
                        ids.push(...Array.from(findChildNodes(item.id)))
                    }
                });
                graph.hide(ids, {animate: false})
            } else {
                let ids: any = []
                graph.each({type: 'node'}, async (item: any) => {
                    if ((item.d.type == 'NJTRACE_NODE') || (item.d.type == 'PERSON_NJTRACE_CONTAINER')) {
                        ids.push(item.id)
                        ids.push(...Array.from(findChildNodes(item.id)))
                    }
                });
                graph.show(ids, {animate: false})
            }
            if (!showParkMobile) {
                let ids: any = []
                graph.each({type: 'node'}, async (item: any) => {
                    if ((item.d.type == 'PARKMOBILE_NODE') || (item.d.type == 'PERSON_PARKMOBILE_CONTAINER')) {
                        ids.push(item.id)
                        ids.push(...Array.from(findChildNodes(item.id)))
                    }
                });
                graph.hide(ids, {animate: false})
            } else {
                let ids: any = []
                graph.each({type: 'node'}, async (item: any) => {
                    if ((item.d.type == 'PARKMOBILE_NODE')|| (item.d.type == 'PERSON_PARKMOBILE_CONTAINER')) {
                        ids.push(item.id)
                        ids.push(...Array.from(findChildNodes(item.id)))
                    }
                });
                graph.show(ids, {animate: false})
            }
            if (!showParole) {
                let ids: any = []
                graph.each({type: 'node'}, async (item: any) => {
                    if ((item.d.type == 'PAROLE_NODE') || (item.d.type == 'PERSON_PAROLE_CONTAINER')) {
                        ids.push(item.id)
                        ids.push(...Array.from(findChildNodes(item.id)))
                    }
                });
                graph.hide(ids, {animate: false})
            } else {
                let ids: any = []
                graph.each({type: 'node'}, async (item: any) => {
                    if ((item.d.type == 'PAROLE_NODE') || (item.d.type == 'PERSON_PAROLE_CONTAINER')) {
                        ids.push(item.id)
                        ids.push(...Array.from(findChildNodes(item.id)))
                    }
                });
                graph.show(ids, {animate: false})
            }
            if (!showPrison) {
                let ids: any = []
                graph.each({type: 'node'}, async (item: any) => {
                    if ((item.d.type == 'PRISON_NODE') || (item.d.type == 'PERSON_PRISON_CONTAINER')) {
                        ids.push(item.id)
                        ids.push(...Array.from(findChildNodes(item.id)))
                    }
                });
                graph.hide(ids, {animate: false})
            } else {
                let ids: any = []
                graph.each({type: 'node'}, async (item: any) => {
                    if ((item.d.type == 'PRISON_NODE') || (item.d.type == 'PERSON_PRISON_CONTAINER')) {
                        ids.push(item.id)
                        ids.push(...Array.from(findChildNodes(item.id)))
                    }
                });
                graph.show(ids, {animate: false})
            }
            if (!showViolent) {
                let ids: any = []
                graph.each({type: 'node'}, async (item: any) => {
                    if ((item.d.type == 'VIOLENT_NODE')|| (item.d.type == 'PERSON_VIOLENT_CONTAINER')) {
                        ids.push(item.id)
                        ids.push(...Array.from(findChildNodes(item.id)))
                    }
                });
                graph.hide(ids, {animate: false})
            } else {
                let ids: any = []
                graph.each({type: 'node'}, async (item: any) => {
                    if ((item.d.type == 'VIOLENT_NODE')|| (item.d.type == 'PERSON_VIOLENT_CONTAINER')) {
                        ids.push(item.id)
                        ids.push(...Array.from(findChildNodes(item.id)))
                    }
                });
                graph.show(ids, {animate: false})
            }
            if (!showProfession) {
                let ids: any = []
                graph.each({type: 'node'}, async (item: any) => {
                    if ((item.d.type == 'PROFESSION_NODE')|| (item.d.type == 'PERSON_PROFESSION_CONTAINER')) {
                        ids.push(item.id)
                        ids.push(...Array.from(findChildNodes(item.id)))
                    }
                });
                graph.hide(ids, {animate: false})
            } else {
                let ids: any = []
                graph.each({type: 'node'}, async (item: any) => {
                    if ((item.d.type == 'PROFESSION_NODE')|| (item.d.type == 'PERSON_PROFESSION_CONTAINER')) {
                        ids.push(item.id)
                        ids.push(...Array.from(findChildNodes(item.id)))
                    }
                });
                graph.show(ids, {animate: false})
            }
            if (!showInfoCop) {
                let ids: any = []
                graph.each({type: 'node'}, async (item: any) => {
                    if ((item.d.type == 'INFOCOP_NODE')|| (item.d.type == 'PERSON_INFOCOP_CONTAINER')) {
                        ids.push(item.id)
                        ids.push(...Array.from(findChildNodes(item.id)))
                    }
                });
                graph.hide(ids, {animate: false})
            } else {
                let ids: any = []
                graph.each({type: 'node'}, async (item: any) => {
                    if ((item.d.type == 'INFOCOP_NODE')|| (item.d.type == 'PERSON_INFOCOP_CONTAINER')) {
                        ids.push(item.id)
                        ids.push(...Array.from(findChildNodes(item.id)))
                    }
                });
                graph.show(ids, {animate: false})
            }
            if (!showWeapon) {
                let ids: any = []
                graph.each({type: 'node'}, async (item: any) => {
                    if ((item.d.type == 'NIBIN_WEAPON')|| (item.d.type == 'WEAPON_ETRACE_FFL_NODE')) {
                        ids.push(item.id)
                        ids.push(...Array.from(findChildNodes(item.id)))
                    }
                });
                graph.hide(ids, {animate: false})
            } else {
                let ids: any = []
                graph.each({type: 'node'}, async (item: any) => {
                    if ((item.d.type == 'NIBIN_WEAPON')|| (item.d.type == 'WEAPON_ETRACE_FFL_NODE')) {
                        ids.push(item.id)
                        ids.push(...Array.from(findChildNodes(item.id)))
                    }
                });
                graph.show(ids, {animate: false})
            }
            if (!showJail) {
                let ids: any = []
                graph.each({type: 'node'}, async (item: any) => {
                    if ((item.d.type == 'JAIL_NODE') || (item.d.type == 'PERSON_JAIL_CONTAINER')) {
                        ids.push(item.id)
                        ids.push(...Array.from(findChildNodes(item.id)))
                    }
                });
                graph.hide(ids, {animate: false})
            } else {
                let ids: any = []
                graph.each({type: 'node'}, async (item: any) => {
                    if ((item.d.type == 'JAIL_NODE') || (item.d.type == 'PERSON_JAIL_CONTAINER')) {
                        ids.push(item.id)
                        ids.push(...Array.from(findChildNodes(item.id)))
                    }
                });
                graph.show(ids, {animate: false})
            }
            if (!showMissingPerson) {
                let ids: any = []
                graph.each({type: 'node'}, async (item: any) => {
                    if ((item.d.type == 'MISSINGPERSON_NODE') || (item.d.type == 'PERSON_MISSINGPERSON_CONTAINER')) {
                        ids.push(item.id)
                        ids.push(...Array.from(findChildNodes(item.id)))
                    }
                });
                graph.hide(ids, {animate: false})
            } else {
                let ids: any = []
                graph.each({type: 'node'}, async (item: any) => {
                    if ((item.d.type == 'MISSINGPERSON_NODE') || (item.d.type == 'PERSON_MISSINGPERSON_CONTAINER')) {
                        ids.push(item.id)
                        ids.push(...Array.from(findChildNodes(item.id)))
                    }
                });
                graph.show(ids, {animate: false})
            }
        }
    }

    useEffect(() => {
        saveFilterState()
        runFilterOptions()
    }, [
        showArrest,
        showALPR,
        showPlate,
        showAssocPeople,
        showCADRMS,
        showDNA,
        showETicket,
        showETrace,
        showNIBIN,
        showNJPOP,
        shownjtrace,
        showParkMobile,
        showParole,
        showPrison,
        showProfession,
        showViolent,
        showInfoCop,
        showAssocPeopleAgencyCase,
        showAssocPeopleIncidentAddress,
        showAssocPeopleHomeAddressAFIS,
        showAssocPeopleHomeAddressDMV,
        showAssocPeoplePlateETicket,
        showAssocPeopleCODISPerson,
        showAssocPeopleCADRMSCase,
        showAssocPeopleCADRMSPlate,
        showAssocPeopleCADRMSVIN,
        showAssocPeopleGunTraceCase,
        showAssocPeopleShootingCase,
        showAssocPeopleNIBINCase,
        showAssocPeopleDL,
        showAssocPeopleFBI,
        showAssocPeopleSBI,
        showAssocPeopleTowedPlate,
        showAssocPeopleTowedVIN,
        showAssocPeopleDMV,
        showWeapon,
        showJail,
        showMissingPerson,
    ])


    function layout() {
        let graph = chartLoc ? chartLoc : chartRef;
        if (graph)
            graph.layout('organic', {orientation: 'down', tightness: 5, animate: true});
    }

    useEffect(() => {
        if (query && query.get("Hash")) {
            let hash = query.get("Hash");
            let tmpData = JSON.parse(JSON.stringify(data));
            if (hash) {
                let personInfoObj = new JAOS().objFromStack(hash)
                tmpData.items.push(...GetPersonAnalysis(personInfoObj).items)
            }
            setData(tmpData)
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        if(showTimeline)
        {
            (timeLoc ?? timeRef)?.on('change', () => {
                applyTimeFilter();
            });
        }
        else
        {
            runFilterOptions()
        }
        
    }, [showTimeline]);


    async function processPersonImages() {
        let graph = chartLoc ? chartLoc : chartRef;
        if (graph) {
            graph.each({type: 'node'}, async (item: any) => {
                if ((item.d.type == 'ASSOCPEOPLE_NODE' || item.d.type == 'MISSINGPERSON_NODE') && !item.d.imageSet) {
                    let picture = await getPersonImage(item)
                    if (picture && picture[0]) {
                        await graph.setProperties({id: item.id, u: picture[0].Image, d: {...item.d, imageSet: true}})
                    } else {
                        await graph.setProperties({id: item.id, d: {...item.d, imageSet: true}})
                    }
                }
            });

        }
        return;
    }

    const getPersonImage = async (person: any) => {
        let APIKEY: string | null = query.get("APIKEY")
        let AUTHOTP: string | null = query.get("AUTHOTP")
        let pic: any = await GetPersonImage({
            FirstName: person?.d?.data?.FName ? person?.d?.data?.FName : null,
            MiddleName: person?.d?.data?.MName ? person?.d?.data?.MName : null,
            LastName: person?.d?.data?.LName ? person?.d?.data?.LName : null,
            DOB: person?.d?.data?.DOB ? person?.d?.data?.DOB : null,
            SBINo: person?.d?.data?.SBINo ? person?.d?.data?.SBINo : null,
            FBI: person?.d?.data?.FBI ? person?.d?.data?.FBI : null,
        }, APIKEY, AUTHOTP)
        return pic
    }

    const clickHandler = (item: any) => {
        processPersonImages()
    }
    
    useEffect(() => {
        processPersonImages().then(()=>{
            runFilterOptions()
        })
        //runLoadFullAssocPeople()
    }, [chartRef]);
    
    const runReset = () => {
        let graph = chartLoc ? chartLoc : chartRef;
        if (graph) {
            let nodes : string [] = []
            graph.each({}, async (item: any) => {
                if(item.id){
                    nodes.push(item.id)
                }
            })
            
            graph.show(nodes, {animate: false}).then( () => {
                runFilterOptions()
            })
        }
    }

     const runLoadFullAssocPeople = () => {
        let graph = chartLoc ? chartLoc : chartRef;
        if (graph) {
            graph.each({type: 'node'}, async (item: any) => {
                if ((item.d.type == 'ASSOCPEOPLE_NODE') && !item.d.get_all_nodes_loaded) {
                    await graph.setProperties({id: item.id, d: {...item.d, get_all_nodes_loaded: true}})
                    let person = item?.d?.data
                    if (person?.PersonID) {
                        GetPersonAnalysis_GetPersonInfo(person).then(res => {
                            try{
                                graph.expand([
                                    ...GetPersonAnalysis(res, true, item.id).items,
                                    {
                                        type: 'link',
                                        id: `${item.id}_LINK_ASSOCPEOPLE_NODE_${person.PersonID}`,
                                        id1: `ASSOCPEOPLE_NODE_${person.PersonID}`,
                                        id2: item.id,
                                        a1: true, w: 2, c: '#566573', t1: '', fb: true
                                    }
                                ], {animate: true, layout: {name: 'organic', orientation: 'down', tightness: 5}}).then(() => {
                                    //runFilterOptions()
                                });
                            }catch (e) {
                                console.log(e)
                            }
                            
                        })
                    }
                }
            });

        }
    }
    
    function findChildNodes(id:any, levels:number=25) : any {
        let nodeids: Set<any> = new Set()
        let graph = chartLoc ? chartLoc : chartRef;
        if(graph){
            //Get all the Node ID's that are directly connected to this node
            graph.each({type: 'link'}, async (item: any) => {
                if(item.id2 == id){
                    nodeids.add(item.id1)
                }
            })

            //Go 25 levels deep and hide all nodes
            for(let i=0; i<levels; i++){
                let s = Array.from(nodeids);
                for(let nodeid of s){
                    graph.each({type: 'link'}, async (item: any) => {
                        if(item.id2 == nodeid){
                            nodeids.add(item.id1)
                        }
                    })
                }
            }
        }
        return nodeids
    }

    async function onDoubleClick({id, preventDefault, button}: any) {
        try {
        
            if (!id || button !== 0) {
                return;
            }
            let node = chartLoc.getItem(id)
            
            if ( 
                node?.d?.type == 'PERSON_ASSOCPEOPLE_CONTAINER'
                || node?.d?.type == 'PERSON_CADRMS_CONTAINER'
                || node?.d?.type == 'PERSON_DNA_CONTAINER'
                || node?.d?.type == 'PERSON_ETICKET_CONTAINER'
                || node?.d?.type == 'PERSON_ETRACE_CONTAINER'
                || node?.d?.type == 'PERSON_NIBIN_CONTAINER'
                || node?.d?.type == 'PERSON_NJPOP_CONTAINER'
                || node?.d?.type == 'PERSON_NJTRACE_CONTAINER'
                || node?.d?.type == 'PERSON_PARKMOBILE_CONTAINER'
                || node?.d?.type == 'PERSON_PAROLE_CONTAINER'
                || node?.d?.type == 'PERSON_PRISON_CONTAINER'
                || node?.d?.type == 'PERSON_PROFESSION_CONTAINER'
                || node?.d?.type == 'PERSON_VIOLENT_CONTAINER'
                || node?.d?.type == 'PERSON_INFOCOP_CONTAINER'
                || node?.d?.type == 'PERSON_Plate_CONTAINER'
                || node?.d?.type == 'PERSON_ALPR_CONTAINER'
                || node?.d?.type == 'PERSON_ARREST_CONTAINER'
                || node?.d?.type == 'FFL_INFO_CONTAINER'
                || node?.d?.type == 'PERSON_JAIL_CONTAINER'
                || node?.d?.type == 'PERSON_MISSINGPERSON_CONTAINER'

            ){
                let nodeids: Set<any> = findChildNodes(id)
                
                if(node?.d?.hidden)
                    chartLoc.show(Array.from(nodeids), {animate: false})
                else{
                    chartLoc.hide(Array.from(nodeids), {animate: false})
                }
                await chartLoc.setProperties({id: id, d: {...node.d, hidden: !node.d.hidden}})
                return;
            }
            
            if (id.includes('ASSOCPEOPLE_NODE_')) {
                //a Person Node so do not close the combo
                preventDefault();
                
                let person = node?.d?.data
                if (person?.PersonID) {
                    GetPersonAnalysis_GetPersonInfo(person).then(res => {
                        chartLoc.expand([
                            ...GetPersonAnalysis(res, true, id).items,
                            {
                                type: 'link',
                                id: `${id}_LINK_ASSOCPEOPLE_NODE_${node.PersonID}`,
                                id1: `ASSOCPEOPLE_NODE_${node.PersonID}`,
                                id2: id,
                                a1: true, w: 2, c: '#566573', t1: '', fb: true
                            }
                        ], {animate: true, layout: {name: 'organic', orientation: 'down', tightness: 5}}).then(() => {
                            //runFilterOptions()
                        });
                    })
                }
            } else if (id.includes('ARREST_NODE_')) {
                preventDefault();
                let item = node?.d?.data
                window.open('/detail/arrest?ID=' + item?.ID + '&Dept=' + item?.ORIGINATINGORINAME, '_blank')
            } else if (id.includes('ALPR_NODE_')) {
                preventDefault();
                let item = node?.d?.data
                window.open('/detail/ALPR?ID=' + item?.LprID + '&Dept=' + item?.ServerDB, '_blank')
            } else if (id.includes('CADRMS_NODE_')) {
                preventDefault();
                let item = node?.d?.data
            } else if (id.includes('DNA_NODE_')) {
                preventDefault();
                let item = node?.d?.data
            } else if (id.includes('ETICKET_NODE_')) {
                preventDefault();
                let item = node?.d?.data
                window.open('/detail/ETicket?ID=' + item?.ID + '&Dept=' + item?.TicketNmbr, '_blank')
            } else if (id.includes('ETRACE_NODE_') || id.includes('NJTRACE_NODE_')) {
                preventDefault();
                let item = node?.d?.data
                //LOAD NIBINs
                if(item?.SERIAL_NUMBER){
                    GetChainNibinJSON('WPNSN',item.SERIAL_NUMBER).then((res:any) => {
                        if(res.Reports){
                            chartLoc.expand(GetPersonAnalysis_GenNIBINNodes(res.Reports, id), {animate: true, layout: {name: 'organic', orientation: 'down', tightness: 5}}).then(() => {
                                //runFilterOptions()
                            })
                        }
                        
                    })
                }
                
                //Load FFL
                if (item?.DealerNo) {
                    setShowWeapon(true);
                    GetPADetailsRun('ETRACE_FFL_INFO', {DLNo: item?.DealerNo}).then(res => {
                        if (res) {
                            for (let ffl of res) {
                                let pid = `FFL_NODE_ID${ffl?.FFL_Number}_FFL_CONTAINER`;
                                chartLoc.expand([
                                        {
                                            type: 'node',
                                            id: pid,
                                            u: 'icons/central_bank.svg',
                                            t: `${ffl?.FFL_NAME}`,
                                            d: {
                                                type: 'FFL_INFO_CONTAINER',
                                                hidden:false,
                                                data: ffl
                                            }
                                        },
                                        {
                                            type: 'link',
                                            id: `FFL_NODE_ID${ffl?.FFL_Number}_FFL_CONTAINER_LINK_${id}`,
                                            id1: pid,
                                            id2: id,
                                            a1: true, w: 2, c: '#566573', t1: '', fb: true
                                        }
                                    ]
                                    , {animate: true, layout: {name: 'organic', orientation: 'down', tightness: 5}})

                                if (ffl?.FFL_Number) {
                                    //Get all the Weapons linked to that FFL
                                    GetPADetailsRun('ETRACE_WEAPONS_FOR_FFL', {DLNo: ffl?.FFL_Number}).then(weaponList => {
                                       
                                        for (let weapon of weaponList) {
                                            let w = weapon;
                                            console.log(`WEAPON_ETRACE_FFL_NODE${w.WPN_SN}`)
                                            chartLoc.expand([
                                                    {
                                                        type: 'node',
                                                        id: `WEAPON_ETRACE_FFL_NODE${w.WPN_SN}`,
                                                        u: 'icons/weapon.png',
                                                        t: `${w.WPN_SN ? w.WPN_SN : ''} \n${w.MAN_NAME ? w.MAN_NAME : ''}  `,
                                                        d: {
                                                            type: 'WEAPON_ETRACE_FFL_NODE',
                                                            data: w,
                                                            imageSet: false,
                                                            get_all_nodes_loaded:false,
                                                        }
                                                    },
                                                    {
                                                        type: 'link',
                                                        id: `WEAPON_ETRACE_FFL_NODE${w.WPN_SN}_FFL_CONTAINER_LINK_${pid}`,
                                                        id1: `WEAPON_ETRACE_FFL_NODE${w.WPN_SN}`,
                                                        id2: pid,
                                                        a1: true, w: 2, c: '#566573', t1: '', fb: true
                                                    }
                                                ]
                                                , {
                                                    animate: true,
                                                    layout: {name: 'organic', orientation: 'down', tightness: 5}
                                                }).then(() => {
                                                if(w.WPN_SN){
                                                    GetPADetailsRun('ETRACE_PERSON_FFL_WPN_SN', {DLNo: ffl?.FFL_Number, DLSt: w?.WPN_SN }).then(peopleList => {
                                                      
                                                        for (let person of peopleList) {
                                                            let personID = person.PersonID ? person.PersonID : `${person.FName}${person.LName}${person.DOB}`;
                                                            chartLoc.expand([
                                                                    {
                                                                        type: 'node',
                                                                        id: `ASSOCPEOPLE_NODE_${personID}`,
                                                                        u: PersonPhotoIcon,
                                                                        t: `${person.FName ? person.FName : ''} ${person.MName ? person.MName + ' ' : ''}${person.LName ? person.LName : ''}\r\b${person?.ROLE}`,
                                                                        d: {
                                                                            type: 'ASSOCPEOPLE_NODE',
                                                                            data: person,
                                                                            imageSet: false,
                                                                            get_all_nodes_loaded:false,
                                                                        },
                                                                        ha0: {
                                                                            c: (person?.isOutOfStatePurchaseFlag === true? 'rgb(255, 0, 0)':null),
                                                                            r: 45,
                                                                            w: 5
                                                                        },
                                                                    },
                                                                    {
                                                                        type: 'link',
                                                                        id: `WEAPON_ETRACE_FFL_NODE${w.WPN_SN}_ASSOCPEOPLE_NODE_${personID}`,
                                                                        id1: `ASSOCPEOPLE_NODE_${personID}`,
                                                                        id2: `WEAPON_ETRACE_FFL_NODE${w.WPN_SN}`,
                                                                        a1: true, w: 2, c: '#566573', t1: '', fb: true
                                                                    }
                                                                ]
                                                                , {
                                                                    animate: true,
                                                                    layout: {name: 'organic', orientation: 'down', tightness: 5}
                                                                }).then(() => {
                                                                runFilterOptions()
                                                            });
                                                           
                                                        }

                                                        
                                                    })
                                                }
                                                runFilterOptions()
                                            });
                                            
                                           
                                            
                                        }
                                    })
                                    
                                    //Get All the Purchasers for that FFL
                                    //Disabled due to Change in BAC-2211 ~ JS
                                    // GetPADetailsRun('ETRACE_PURCHASER_FOR_FFL', {DLNo: ffl?.FFL_Number}).then(peopleList => {
                                    //     for (let person of peopleList) {
                                    //         let personID = person.PersonID ? person.PersonID : `${person.FName}${person.LName}${person.DOB}`;
                                    //
                                    //         chartLoc.expand([
                                    //                 {
                                    //                     type: 'node',
                                    //                     id: `ASSOCPEOPLE_NODE_${personID}`,
                                    //                     u: PersonPhotoIcon,
                                    //                     t: `${person.FName ? person.FName : ''} ${person.MName ? person.MName + ' ' : ''}${person.LName ? person.LName : ''}`,
                                    //                     d: {
                                    //                         type: 'ASSOCPEOPLE_NODE',
                                    //                         data: person,
                                    //                         imageSet: false,
                                    //                         get_all_nodes_loaded:false,
                                    //                     }
                                    //                 },
                                    //                 {
                                    //                     type: 'link',
                                    //                     id: `ASSOCPEOPLE_NODE_${personID}_FFL_CONTAINER_LINK_${pid}`,
                                    //                     id1: `ASSOCPEOPLE_NODE_${personID}`,
                                    //                     id2: pid,
                                    //                     a1: true, w: 2, c: '#566573', t1: '', fb: true
                                    //                 }
                                    //             ]
                                    //             , {
                                    //                 animate: true,
                                    //                 layout: {name: 'organic', orientation: 'down', tightness: 5}
                                    //             }).then(() => {
                                    //             runFilterOptions()
                                    //         });
                                    //     }
                                    // })
                                }
                            }
                        }
                    })
                }
            } else if (id.includes('NIBIN_NODE_')) {
                preventDefault();
                let item = node?.d?.data
                //LOAD NIBINs
                if(item?.NIBINNo){
                    GetLinkAnalysisData(item?.NIBINNo,null,null).then(response => {
                        chartLoc.expand(GetPersonAnalysis_GenNIBINNodes([...response], id), {animate: true, layout: {name: 'organic', orientation: 'down', tightness: 5}}).then(() => {
                            //runFilterOptions()
                        })
                    })
                }
                
            } else if (id.includes('NIBIN_Report')) {
                preventDefault();
                let item = node?.d?.data
                OpenNIBINPDF(item.ReportID)
            }else if (id.includes('NJPOP_NODE_')) {
                preventDefault();
                let item = node?.d?.data
                OpenNJPOPPDF(item.IncidentID)
            } else if (id.includes('PARKMOBILE_NODE_')) {
                preventDefault();
                let item = node?.d?.data
                window.open('/detail/ParkMobile?ID=' + item?.ParkDataID + '&Dept=' + item?.agencyshortname, '_blank')
            } else if (id.includes('PAROLE_NODE_')) {
                preventDefault();
                let item = node?.d?.data
            } else if (id.includes('PRISON_NODE_')) {
                preventDefault();
                let item = node?.d?.data
            } else if (id.includes('PROFESSION_NODE_')) {
                preventDefault();
                let item = node?.d?.data
            } else if (id.includes('VIOLENT_NODE_')) {
                preventDefault();
                let item = node?.d?.data
            }else if (id.includes('JAIL_NODE_')) {
                preventDefault();
                let item = node?.d?.data
            }else if (id.includes('MISSINGPERSON_NODE_')) {
                preventDefault();
                let item = node?.d?.data
            } else if (id.includes('INFOCOP_NODE_')) {
                preventDefault();
                let item = node?.d?.data
                window.open('/detail/Infocop?ID=' + item?.ReqID + '&Dept=' + item?.Dept, '_blank')
            } else if (id.includes('WEAPON_ETRACE_FFL_NODE')) {
                preventDefault();
                let item = node?.d?.data
                //LOAD NIBINs
                if(item?.WPN_SN){
                    GetChainNibinJSON('WPNSN',item.WPN_SN).then((res:any) => {
                        if(res.Reports){
                            chartLoc.expand(GetPersonAnalysis_GenNIBINNodes(res.Reports, id), {animate: true, layout: {name: 'organic', orientation: 'down', tightness: 5}}).then(() => {
                                //runFilterOptions()
                            })
                        }

                    })
                }
            } else if (id.includes('PLATE_NODE_')) {
                preventDefault();
                let item = node?.d?.data
                GetPersonAnalysis_PlateRegisteredOwner([item.PlateNo]).then((owners) => {
                    if(owners.AnyTable){
                        for (let person of owners.AnyTable) {
                            chartLoc.expand([
                                    {
                                        type: 'node',
                                        id: `ASSOCPEOPLE_NODE_${person.personID}`,
                                        u: PersonPhotoIcon,
                                        t: `${person.FName ? person.FName : ''} ${person.MName ? person.MName + ' ' : ''}${person.LName ? person.LName : ''}`,
                                        d: {
                                            type: 'ASSOCPEOPLE_NODE',
                                            data: person,
                                            imageSet: false,
                                            get_all_nodes_loaded:false,
                                        }
                                    },
                                    {
                                        type: 'link',
                                        id: `OWNER_NODE_${person.PersonID}_PLATE_CONTAINER_LINK_${id}`,
                                        id1: `ASSOCPEOPLE_NODE_${person.personID}`,
                                        t: 'DMV',
                                        id2: id,
                                        a1: true, w: 2, c: '#566573', t1: '', fb: true
                                    }
                                ]
                                , {
                                    animate: true,
                                    layout: {name: 'organic', orientation: 'down', tightness: 5}
                                }).then(() => {
                                //runFilterOptions()
                            });
                        }
                    }
                   
                })
            } else {
            }

        } catch (e) {

        }

    }

    const updateTooltipPosition = (item: any) => {
        if (item && item.id) {
            const node = chartLoc?.getItem(item.id);
            if (node && node.x) {
                const coordinates = chartLoc.viewCoordinates(node.x, node.y);
                setToolTipStyle({
                    transform: `scale(${Math.max(0.75, Math.min(2, chartLoc.viewOptions().zoom))}`,
                    left: coordinates.x + 40,
                    top: coordinates.y - (myContainer?.current?.clientHeight / 2),
                    position: 'absolute',
                    zIndex: 1000
                })
            }
        }
    }

    const closeTooltip = () => {
        setToolTip(<></>)
    }

    const createTooltip = (item: any) => {
        const node = chartLoc?.getItem(item.id);
        if (node) {
            updateTooltipPosition(item)
            if (node?.d?.type) {
                switch (node.d.type) {
                    case("PERSON_NODE"):
                        setToolTip(
                            <div>
                                <Card className="border-2 border-gray-700" sx={{maxWidth: 400, m: 'auto', mb: 1}}>
                                    <CardContent>
                                        <div><span className="font-bold">First Name: </span> {node?.d?.data?.FName}
                                        </div>
                                        <div><span className="font-bold">Middle Name: </span> {node?.d?.data?.MName}
                                        </div>
                                        <div><span className="font-bold">Last Name: </span> {node?.d?.data?.LName}</div>
                                        <div><span className="font-bold">DOB: </span> {node?.d?.data?.DOB} </div>
                                        <div><span className="font-bold">SEX: </span> {node?.d?.data?.SEX} </div>
                                        <div><span className="font-bold">ADDRESS: </span> {node?.d?.data?.Address}</div>
                                        <div><span className="font-bold">CITY: </span> {node?.d?.data?.City} </div>
                                        <div><span className="font-bold">STATE: </span> {node?.d?.data?.State} </div>
                                        <div><span className="font-bold">FBI: </span> {node?.d?.data?.FBI} </div>
                                        <div><span className="font-bold">SBI: </span> {node?.d?.data?.SBINo} </div>
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("ARREST_NODE"):
                        setToolTip(
                            <div>
                                <Card className="border-2 border-gray-700" sx={{maxWidth: 750, m: 'auto', mb: 1}}>
                                    <CardContent>
                                        <div><span
                                            className="font-bold">DATE OF ARREST: </span> {formatDT(node?.d?.data?.DTOFARREST)}
                                        </div>
                                        <div><span
                                            className="font-bold">ORIGINATING AGENCY : </span> {node?.d?.data?.ORIGINATINGORINAME}
                                        </div>
                                        <div><span
                                            className="font-bold">ARREST TYPE: </span> {node?.d?.data?.ARREST_TYPE}
                                        </div>
                                        <div><span className="font-bold">STATUTE: </span> {node?.d?.data?.STATUTE}</div>
                                        <div><span
                                            className="font-bold">STATUTE DESCRIPTION: </span> {node?.d?.data?.STATUTEDESCRIPTION}
                                        </div>
                                        <div><span
                                            className="font-bold">Incident Address: </span> {node?.d?.data?.IncidentAddress}
                                        </div>
                                        <div><span
                                            className="font-bold">Incident City: </span> {node?.d?.data?.IncidentCity}
                                        </div>
                                        <div><span
                                            className="font-bold">Incident State: </span> {node?.d?.data?.IncidentState}
                                        </div>
                                        <div><span
                                            className="font-bold">Incident Zip: </span> {node?.d?.data?.IncidentZip}
                                        </div>
                                        <div><span
                                            className="font-bold">Agency Case No: </span> {node?.d?.data?.AgencyCaseNo}
                                        </div>
                                        <div><span
                                            className="font-bold">Resident County: </span> {node?.d?.data?.ResidentCounty}
                                        </div>
                                        <div><span
                                            className="font-bold">Resident City: </span> {node?.d?.data?.ResidentCity}
                                        </div>
                                        <div><span
                                            className="font-bold">Arrested County: </span> {node?.d?.data?.ArrestedCounty}
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("ALPR_NODE"):
                        setToolTip(
                            <div>
                                <Card className="border-2 border-gray-700" sx={{maxWidth: 400, m: 'auto', mb: 1}}>
                                    <CardContent>
                                        <div><span className="font-bold">PLATE: </span> {node?.d?.data?.red_vrm} </div>
                                        <div><span
                                            className="font-bold">TIMESTAMP: </span> {formatDT(node?.d?.data?.red_TimeStamp)}
                                        </div>
                                        <div><span className="font-bold">Latitude: </span> {node?.d?.data?.Latitude}
                                        </div>
                                        <div><span className="font-bold">Longitude: </span> {node?.d?.data?.Longitude}
                                        </div>
                                        <div><span className="font-bold">Site Name: </span> {node?.d?.data?.ste_Name}
                                        </div>
                                        <div><span className="font-bold">Source Name: </span> {node?.d?.data?.src_Name}
                                        </div>
                                        <div><span
                                            className="font-bold">Source Description: </span> {node?.d?.data?.src_Description}
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("ASSOCPEOPLE_NODE"):
                        setToolTip(
                            <div>
                                <Card className="border-2 border-gray-700" sx={{maxWidth: 400, m: 'auto', mb: 1}}>
                                    <CardContent>
                                        <div><span
                                            className="font-bold">Type: </span> {node?.d?.data?.AType ? node?.d?.data?.AType : node?.d?.data?.ROLE}
                                        </div>
                                        <div><span className="font-bold">First Name: </span> {node?.d?.data?.FName}
                                        </div>
                                        <div><span className="font-bold">Middle Name: </span> {node?.d?.data?.MName}
                                        </div>
                                        <div><span className="font-bold">Last Name: </span> {node?.d?.data?.LName}</div>
                                        <div><span className="font-bold">DOB: </span> {formatDT(node?.d?.data?.DOB)}
                                        </div>
                                        <div><span className="font-bold">SEX: </span> {node?.d?.data?.SEX} </div>
                                        <div><span className="font-bold">ADDRESS: </span> {node?.d?.data?.Address}</div>
                                        <div><span className="font-bold">CITY: </span> {node?.d?.data?.City} </div>
                                        <div><span className="font-bold">STATE: </span> {node?.d?.data?.State} </div>
                                        <div><span className="font-bold">FBI: </span> {node?.d?.data?.FBI} </div>
                                        <div><span className="font-bold">SBI: </span> {node?.d?.data?.SBINo} </div>
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("CADRMS_NODE"):
                        setToolTip(
                            <div>
                                <Card className="border-2 border-gray-700" sx={{maxWidth: 400, m: 'auto', mb: 1}}>
                                    <CardContent>
                                        <div><span
                                            className="font-bold">Agency Case: </span> {node?.d?.data?.AgencyCaseNo}
                                        </div>
                                        <div><span
                                            className="font-bold">Timestamp:: </span> {formatDT(node?.d?.data?.DateTimeIn)}
                                        </div>
                                        <div><span
                                            className="font-bold">Case Status: </span> {node?.d?.data?.CaseStatus}</div>
                                        <div><span className="font-bold">STATUTE: </span> {node?.d?.data?.STATUTE}</div>
                                        <div><span
                                            className="font-bold">STATUTE DESCRIPTION: </span> {node?.d?.data?.STATUTEDESCRIPTION}
                                        </div>
                                        <div><span className="font-bold">City: </span> {node?.d?.data?.City} </div>
                                        <div><span
                                            className="font-bold">Incident Address: </span> {node?.d?.data?.IncidentAddress}
                                        </div>
                                        <div><span className="font-bold">STATE: </span> {node?.d?.data?.ST} </div>
                                        <div><span className="font-bold">ZIP: </span> {node?.d?.data?.ZIP} </div>
                                        <div><span className="font-bold">NIBRS: </span> {node?.d?.data?.NIBRSDesc}</div>
                                        <div><span className="font-bold">Role: </span> {node?.d?.data?.RoleDesc} </div>
                                        <div><span className="font-bold">Latitude: </span> {node?.d?.data?.Latitude}
                                        </div>
                                        <div><span className="font-bold">Longitude: </span> {node?.d?.data?.Longitude}
                                        </div>
                                    </CardContent>

                                </Card>
                            </div>
                        )
                        break;
                    case("DNA_NODE"):
                        setToolTip(
                            <div>
                                <Card className="border-2 border-gray-700" sx={{maxWidth: 400, m: 'auto', mb: 1}}>
                                    <CardContent>
                                        <div><span className="font-bold">Agency: </span> {node?.d?.data?.Agency} </div>
                                        <div><span
                                            className="font-bold">OffenseDate: </span> {formatDT(node?.d?.data?.OffenseDate)}
                                        </div>
                                        <div><span className="font-bold">Offense: </span> {node?.d?.data?.Offense}</div>
                                        <div><span className="font-bold">CaseNumber: </span> {node?.d?.data?.CaseNumber}
                                        </div>
                                        <div><span className="font-bold">Profile: </span> {node?.d?.data?.Profile}</div>
                                        <div><span className="font-bold">HitNumber: </span> {node?.d?.data?.HitNumber}
                                        </div>
                                        <div><span className="font-bold">County: </span> {node?.d?.data?.County} </div>
                                        <div><span className="font-bold">Region: </span> {node?.d?.data?.Region} </div>
                                        <div><span
                                            className="font-bold">Lab Specimen ID: </span> {node?.d?.data?.LabSpecimenID}
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("ETICKET_NODE"):
                        setToolTip(
                            <div>
                                <Card className="border-2 border-gray-700" sx={{maxWidth: 400, m: 'auto', mb: 1}}>
                                    <CardContent>
                                        <div><span
                                            className="font-bold">Issued: </span> {formatDT(node?.d?.data?.eCreated)}
                                        </div>
                                        <div><span
                                            className="font-bold">Municipality: </span> {node?.d?.data?.eMunicipality}
                                        </div>
                                        <div><span className="font-bold">Offense: </span> {node?.d?.data?.eOffenseStat}
                                        </div>
                                        <div><span
                                            className="font-bold">Violation: </span> {node?.d?.data?.eViolationDesc}
                                        </div>
                                        <div><span className="font-bold">Plate: </span> {node?.d?.data?.ePlateNbr}</div>
                                        <div><span className="font-bold">Plate St: </span> {node?.d?.data?.ePlateSt}
                                        </div>
                                        <div><span className="font-bold">DL: </span> {node?.d?.data?.eDefDL} </div>
                                        <div><span className="font-bold">DL St: </span> {node?.d?.data?.eDefDLSt} </div>
                                        <div><span className="font-bold">First Name: </span> {node?.d?.data?.eDefFName}
                                        </div>
                                        <div><span className="font-bold">Middle Name: </span> {node?.d?.data?.eDefMI}
                                        </div>
                                        <div><span className="font-bold">Last Name: </span> {node?.d?.data?.eDefLName}
                                        </div>
                                        <div><span className="font-bold">Ticket #: </span> {node?.d?.data?.TicketNmbr}
                                        </div>
                                        <div><span className="font-bold">Latitude: </span> {node?.d?.data?.Latitude}
                                        </div>
                                        <div><span className="font-bold">Longitude: </span> {node?.d?.data?.Longitude}
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("ETRACE_NODE"):
                        setToolTip(
                            <div>
                                <Card className="border-2 border-gray-700" sx={{maxWidth: 400, m: 'auto', mb: 1}}>
                                    <CardContent>
                                        <div><span
                                            className="font-bold">Agency Case No: </span> {node?.d?.data?.AgencyCaseNo}
                                        </div>
                                        <div><span
                                            className="font-bold">MANUFACTURER NAME: </span> {node?.d?.data?.MANUFACTURER_NAME}
                                        </div>
                                        <div><span className="font-bold">MODEL: </span> {node?.d?.data?.MODEL} </div>
                                        <div><span className="font-bold">CALIBER: </span> {node?.d?.data?.CALIBER}</div>
                                        <div><span
                                            className="font-bold">SERIAL NUMBER: </span> {node?.d?.data?.SERIAL_NUMBER}
                                        </div>
                                        <div><span
                                            className="font-bold">DESCRIPTION: </span> {node?.d?.data?.W_TYPE_DESC}
                                        </div>
                                        <div><span className="font-bold">City: </span> {node?.d?.data?.City} </div>
                                        <div><span
                                            className="font-bold">Incident Address: </span> {node?.d?.data?.IncidentAddress}
                                        </div>
                                        <div><span className="font-bold">ST: </span> {node?.d?.data?.ST} </div>
                                        <div><span className="font-bold">ZIP: </span> {node?.d?.data?.ZIP} </div>
{/*
                                        <div><span className="font-bold">Role: </span> {node?.d?.data?.RoleDesc} </div>
*/}
                                        <div><span className="font-bold">Latitude: </span> {node?.d?.data?.Latitude}
                                        </div>
                                        <div><span className="font-bold">Longitude: </span> {node?.d?.data?.Longitude}
                                        </div>
                                        <div><span className="font-bold">ATF #: </span> {node?.d?.data?.ATFNo} </div>
                                        <div><span
                                            className="font-bold">Crime History: </span> {node?.d?.data?.CrimeHistory}
                                        </div>
                                        <div><span className="font-bold">Dealer #: </span> {node?.d?.data?.DealerNo}
                                        </div>
                                        <div><span
                                            className="font-bold">Dealer Name: </span> {node?.d?.data?.DealerName}</div>
                                       {/* <div><span
                                            className="font-bold">Purchase Date: </span> {node?.d?.data?.PurchaseDate}
                                        </div>
                                        <div><span
                                            className="font-bold">Purchaser First Name: </span> {node?.d?.data?.P_FIRSTNAME}
                                        </div>
                                        <div><span
                                            className="font-bold">Purchaser Middle Name: </span> {node?.d?.data?.P_MI}
                                        </div>
                                        <div><span
                                            className="font-bold">Purchaser Last Name: </span> {node?.d?.data?.P_LASTNAME}
                                        </div>
                                        <div><span className="font-bold">Purchaser POB: </span> {node?.d?.data?.P_POB}
                                        </div>
                                        <div><span
                                            className="font-bold">Purchaser CrimeHistory: </span> {node?.d?.data?.P_CrimeHistory}
                                        </div>*/}
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("NIBIN_NODE"):
                        setToolTip(
                            <div>
                                <Card className="border-2 border-gray-700" sx={{maxWidth: 400, m: 'auto', mb: 1}}>
                                    <CardContent>
                                        <div><span className="font-bold">Agency: </span> {node?.d?.data?.Agency} </div>
                                        <div><span
                                            className="font-bold">OffenseDate: </span> {formatDT(node?.d?.data?.OffenseDate)}
                                        </div>
                                        <div><span className="font-bold">Offense: </span> {node?.d?.data?.Offense}</div>
                                        <div><span className="font-bold">CaseNumber: </span> {node?.d?.data?.CaseNumber}
                                        </div>
                                        <div><span className="font-bold">Profile: </span> {node?.d?.data?.Profile}</div>
                                        <div><span className="font-bold">NIBINNo: </span> {node?.d?.data?.NIBINNo}</div>
                                        <div><span className="font-bold">HitNumber: </span> {node?.d?.data?.HitNumber}
                                        </div>
                                        <div><span className="font-bold">County: </span> {node?.d?.data?.County} </div>
                                        <div><span className="font-bold">City: </span> {node?.d?.data?.City} </div>
                                        <div><span
                                            className="font-bold">IncidentAddress: </span> {node?.d?.data?.IncidentAddress}
                                        </div>
                                        <div><span className="font-bold">ST: </span> {node?.d?.data?.ST} </div>
                                        <div><span className="font-bold">ZIP: </span> {node?.d?.data?.ZIP} </div>
                                        <div><span className="font-bold">Latitude: </span> {node?.d?.data?.Latitude}
                                        </div>
                                        <div><span className="font-bold">Longitude: </span> {node?.d?.data?.Longitude}
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("NJPOP_NODE"):
                        setToolTip(
                            <div>
                                <Card className="border-2 border-gray-700" sx={{maxWidth: 400, m: 'auto', mb: 1}}>
                                    <CardContent>
                                        <div><span className="font-bold">Dept: </span> {node?.d?.data?.Dept} </div>
                                        <div><span
                                            className="font-bold">Agency Case No: </span> {node?.d?.data?.AgencyCaseNo}
                                        </div>
                                        <div><span
                                            className="font-bold">Timestamp: </span> {formatDT(node?.d?.data?.DateTimeIn)}
                                        </div>
                                        <div><span
                                            className="font-bold">Case Status: </span> {node?.d?.data?.CaseStatus}</div>
                                        <div><span className="font-bold">City: </span> {node?.d?.data?.City} </div>
                                        <div><span
                                            className="font-bold">Incident Address: </span> {node?.d?.data?.IncidentAddress}
                                        </div>
                                        <div><span className="font-bold">ST: </span> {node?.d?.data?.ST} </div>
                                        <div><span className="font-bold">ZIP: </span> {node?.d?.data?.ZIP} </div>
                                        <div><span className="font-bold">Role: </span> {node?.d?.data?.RoleDesc} </div>
                                        <div><span className="font-bold">Latitude: </span> {node?.d?.data?.Latitude}
                                        </div>
                                        <div><span className="font-bold">Longitude: </span> {node?.d?.data?.Longitude}
                                        </div>
                                        <div><span
                                            className="font-bold">Incident ID: </span> {node?.d?.data?.IncidentID}</div>
                                        <div><span
                                            className="font-bold">Incident Type: </span> {node?.d?.data?.IncidentType}
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("NJTRACE_NODE"):
                        setToolTip(
                            <div>
                                <Card className="border-2 border-gray-700" sx={{maxWidth: 400, m: 'auto', mb: 1}}>
                                    <CardContent>
                                        <div><span
                                            className="font-bold">Agency Case No: </span> {node?.d?.data?.AgencyCaseNo}
                                        </div>
                                        <div><span
                                            className="font-bold">MANUFACTURER NAME: </span> {node?.d?.data?.MANUFACTURER_NAME}
                                        </div>
                                        <div><span className="font-bold">MODEL: </span> {node?.d?.data?.MODEL} </div>
                                        <div><span className="font-bold">CALIBER: </span> {node?.d?.data?.CALIBER}</div>
                                        <div><span
                                            className="font-bold">SERIAL NUMBER: </span> {node?.d?.data?.SERIAL_NUMBER}
                                        </div>
                                        <div><span
                                            className="font-bold">DESCRIPTION: </span> {node?.d?.data?.W_TYPE_DESC}
                                        </div>
                                        <div><span className="font-bold">City: </span> {node?.d?.data?.City} </div>
                                        <div><span
                                            className="font-bold">Incident Address: </span> {node?.d?.data?.IncidentAddress}
                                        </div>
                                        <div><span className="font-bold">ST: </span> {node?.d?.data?.ST} </div>
                                        <div><span className="font-bold">ZIP: </span> {node?.d?.data?.ZIP} </div>
{/*
                                        <div><span className="font-bold">Role: </span> {node?.d?.data?.RoleDesc} </div>
*/}
                                        <div><span className="font-bold">Latitude: </span> {node?.d?.data?.Latitude}
                                        </div>
                                        <div><span className="font-bold">Longitude: </span> {node?.d?.data?.Longitude}
                                        </div>
                                        <div><span className="font-bold">ATF #: </span> {node?.d?.data?.ATFNo} </div>
                                        <div><span
                                            className="font-bold">Crime History: </span> {node?.d?.data?.CrimeHistory}
                                        </div>
                                        <div><span className="font-bold">Dealer #: </span> {node?.d?.data?.DealerNo}
                                        </div>
                                        <div><span
                                            className="font-bold">Dealer Name: </span> {node?.d?.data?.DealerName}</div>
                                        {/*<div><span
                                            className="font-bold">Purchase Date: </span> {formatDT(node?.d?.data?.PurchaseDate)}
                                        </div>
                                        <div><span
                                            className="font-bold">Purchaser First Name: </span> {node?.d?.data?.P_FIRSTNAME}
                                        </div>
                                        <div><span
                                            className="font-bold">Purchaser Middle Name: </span> {node?.d?.data?.P_MI}
                                        </div>
                                        <div><span
                                            className="font-bold">Purchaser Last Name: </span> {node?.d?.data?.P_LASTNAME}
                                        </div>
                                        <div><span className="font-bold">Purchaser POB: </span> {node?.d?.data?.P_POB}
                                        </div>
                                        <div><span
                                            className="font-bold">Purchaser CrimeHistory: </span> {node?.d?.data?.P_CrimeHistory}
                                        </div>*/}
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("PARKMOBILE_NODE"):
                        setToolTip(
                            <div>
                                <Card className="border-2 border-gray-700" sx={{maxWidth: 400, m: 'auto', mb: 1}}>
                                    <CardContent>
                                        <div><span className="font-bold">ZONE: </span> {node?.d?.data?.zoneid} </div>
                                        <div><span className="font-bold">PLATE: </span> {node?.d?.data?.plate} </div>
                                        <div><span className="font-bold">PLATE ST: </span> {node?.d?.data?.platestate}
                                        </div>
                                        <div><span
                                            className="font-bold">START: </span> {formatDT(node?.d?.data?.startdatetime)}
                                        </div>
                                        <div><span
                                            className="font-bold">END: </span> {formatDT(node?.d?.data?.enddatetime)}
                                        </div>
                                        <div><span className="font-bold">Latitude: </span> {node?.d?.data?.Latitude}
                                        </div>
                                        <div><span className="font-bold">Longitude: </span> {node?.d?.data?.Longitude}
                                        </div>
                                        <div><span
                                            className="font-bold">Agency Name: </span> {node?.d?.data?.agencyshortname}
                                        </div>

                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("PAROLE_NODE"):
                        setToolTip(
                            <div>
                                <Card className="border-2 border-gray-700" sx={{maxWidth: 400, m: 'auto', mb: 1}}>
                                    <CardContent>
                                        <div><span
                                            className="font-bold">Start Date: </span> {formatDT(node?.d?.data?.Start_Date)}
                                        </div>
                                        <div><span
                                            className="font-bold">End Date: </span> {formatDT(node?.d?.data?.End_Date)}
                                        </div>
                                        <div><span className="font-bold">STATUS: </span> {node?.d?.data?.STATUS} </div>
                                        <div><span className="font-bold">STATUTE: </span> {node?.d?.data?.STATUTE}</div>
                                        <div><span
                                            className="font-bold">STATUTE DESCRIPTION: </span> {node?.d?.data?.STATUTEDESCRIPTION}
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("PRISON_NODE"):
                        setToolTip(
                            <div>
                                <Card className="border-2 border-gray-700" sx={{maxWidth: 400, m: 'auto', mb: 1}}>
                                    <CardContent>
                                        <div><span className="font-bold">BOOKING NO: </span> {node?.d?.data?.BOOKING_NO}
                                        </div>
                                        <div><span
                                            className="font-bold">OFFENDER BOOK ID: </span> {node?.d?.data?.OFFENDER_BOOK_ID}
                                        </div>
                                        <div><span className="font-bold">CASE LOAD: </span> {node?.d?.data?.CASELOAD}
                                        </div>
                                        <div><span
                                            className="font-bold">RELEASE DATE: </span> {formatDT(node?.d?.data?.RELEASE_DATE)}
                                        </div>
                                        <div><span
                                            className="font-bold">MOVEMENT REASON CODE: </span> {node?.d?.data?.MOVEMENT_REASON_CODE}
                                        </div>
                                        <div><span className="font-bold">Parole: </span> {node?.d?.data?.Parole_supr}
                                        </div>
                                        <div><span className="font-bold">STATUS: </span> {node?.d?.data?.STATUS} </div>
                                        <div><span className="font-bold">MAXTERM: </span> {node?.d?.data?.MAXTERM}</div>
                                        <div><span
                                            className="font-bold">CONVICTION County: </span> {node?.d?.data?.CONVICTION_County}
                                        </div>
                                        <div><span
                                            className="font-bold">Admission Date: </span> {formatDT(node?.d?.data?.Admission_Date)}
                                        </div>
                                        <div><span className="font-bold">STATUTE: </span> {node?.d?.data?.STATUTE}</div>
                                        <div><span
                                            className="font-bold">STATUTE DESCRIPTION: </span> {node?.d?.data?.STATUTEDESCRIPTION}
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("PROFESSION_NODE"):
                        setToolTip(
                            <div>
                                <Card className="border-2 border-gray-700" sx={{maxWidth: 400, m: 'auto', mb: 1}}>
                                    <CardContent>
                                        <div><span
                                            className="font-bold">Issue Date: </span> {formatDT(node?.d?.data?.IssueDate)}
                                        </div>
                                        <div><span
                                            className="font-bold">Expiration Date: </span> {formatDT(node?.d?.data?.ExpirationDate)}
                                        </div>
                                        <div><span
                                            className="font-bold">Profession Name: </span> {node?.d?.data?.ProfessionName}
                                        </div>
                                        <div><span className="font-bold">License #: </span> {node?.d?.data?.LicenseNo}
                                        </div>
                                        <div><span
                                            className="font-bold">License Name: </span> {node?.d?.data?.LicenseName}
                                        </div>
                                        <div><span className="font-bold">Status: </span> {node?.d?.data?.Status} </div>
                                        <div><span className="font-bold">Street #: </span> {node?.d?.data?.StreetNo}
                                        </div>
                                        <div><span
                                            className="font-bold">Street Name: </span> {node?.d?.data?.StreetName}</div>
                                        <div><span className="font-bold">City: </span> {node?.d?.data?.City} </div>
                                        <div><span className="font-bold">State: </span> {node?.d?.data?.State} </div>
                                        <div><span className="font-bold">Zip: </span> {node?.d?.data?.Zip} </div>
                                        <div><span className="font-bold">Latitude: </span> {node?.d?.data?.Latitude}
                                        </div>
                                        <div><span className="font-bold">Longitude: </span> {node?.d?.data?.Longitude}
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("VIOLENT_NODE"):
                        setToolTip(
                            <div>
                                <Card className="border-2 border-gray-700" sx={{maxWidth: 400, m: 'auto', mb: 1}}>
                                    <CardContent>
                                        <div><span
                                            className="font-bold">Start Date: </span> {formatDT(node?.d?.data?.Start_Date)}
                                        </div>
                                        <div><span
                                            className="font-bold">Validation Date: </span> {formatDT(node?.d?.data?.ValidationDate)}
                                        </div>
                                        <div><span className="font-bold">Case #: </span> {node?.d?.data?.CaseNo} </div>
                                        <div><span className="font-bold">Dept ORI: </span> {node?.d?.data?.DeptORI}
                                        </div>
                                        <div><span className="font-bold">Criteria: </span> {node?.d?.data?.Criteria}
                                        </div>
                                        <div><span className="font-bold">Notes: </span> {node?.d?.data?.Notes} </div>
                                        <div><span
                                            className="font-bold">Medical Condition: </span> {node?.d?.data?.MedicalCondition}
                                        </div>
                                        <div><span
                                            className="font-bold">CODIS Notes: </span> {node?.d?.data?.CODIS_Notes}
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("INFOCOP_NODE"):
                        setToolTip(
                            <div>
                                <Card className="border-2 border-gray-700" sx={{maxWidth: 400, m: 'auto', mb: 1}}>
                                    <CardContent>
                                        <div><span
                                            className="font-bold">Plate Number: </span> {node?.d?.data?.PlateNumber}
                                        </div>
                                        <div><span className="font-bold">Plate St: </span> {node?.d?.data?.PlateSt}
                                        </div>
                                        <div><span className="font-bold">DL No: </span> {node?.d?.data?.DLNo} </div>
                                        <div><span className="font-bold">DL St: </span> {node?.d?.data?.DLSt} </div>
                                        <div><span
                                            className="font-bold">Timestamp: </span> {formatDT(node?.d?.data?.DateTimeIN)}
                                        </div>
                                        <div><span className="font-bold">Dept Name: </span> {node?.d?.data?.DeptName}
                                        </div>
                                        <div><span className="font-bold">Latitude: </span> {node?.d?.data?.Latitude}
                                        </div>
                                        <div><span className="font-bold">Longitude: </span> {node?.d?.data?.Longitude}
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("PLATE_NODE"):
                        setToolTip(
                            <div>
                                <Card className="border-2 border-gray-700" sx={{maxWidth: 400, m: 'auto', mb: 1}}>
                                    <CardContent>
                                        <div><span className="font-bold">Plate Number: </span> {node?.d?.data?.PlateNo}
                                        </div>
                                        <div><span className="font-bold">Plate St: </span> {node?.d?.data?.PlateSt}
                                        </div>
                                        <div><span className="font-bold">Source: </span> {node?.d?.data?.Source} </div>
                                        <div><span
                                            className="font-bold">Start Date: </span> {formatDT(node?.d?.data?.StartDate)}
                                        </div>
                                        <div><span
                                            className="font-bold">End Date: </span> {formatDT(node?.d?.data?.EndDate)}
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("FFL_INFO_CONTAINER"):
                        setToolTip(
                            <div>
                                <Card className="border-2 border-gray-700" sx={{maxWidth: 400, m: 'auto', mb: 1}}>
                                    <CardContent>
                                        <div><span className="font-bold">FFL #: </span> {node?.d?.data?.FFL_Number}
                                        </div>
                                        <div><span className="font-bold">NAME: </span> {node?.d?.data?.FFL_NAME} </div>
                                        <div><span className="font-bold">STREET: </span> {node?.d?.data?.FFL_STREET}
                                        </div>
                                        <div><span className="font-bold">CITY: </span> {node?.d?.data?.FFL_CITY} </div>
                                        <div><span className="font-bold">COUNTY: </span> {node?.d?.data?.FFL_COUNTY}
                                        </div>
                                        <div><span className="font-bold">STATE: </span> {node?.d?.data?.FFL_STATE}</div>
                                        <div><span className="font-bold">ZIP: </span> {node?.d?.data?.FFL_ZIP} </div>
                                        <div><span className="font-bold">COUNTRY: </span> {node?.d?.data?.FFL_COUNTRY}
                                        </div>
                                        <div><span className="font-bold">PHONE: </span> {node?.d?.data?.FFL_PHONE}</div>
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;

                    case("WEAPON_ETRACE_FFL_NODE"):
                        setToolTip(
                            <div>
                                <Card className="border-2 border-gray-700" sx={{maxWidth: 400, m: 'auto', mb: 1}}>
                                    <CardContent>
                                        <div><span className="font-bold">FFL #: </span> {node?.d?.data?.FFL_Number}
                                        </div>
                                        <div><span className="font-bold">MAKE: </span> {node?.d?.data?.MAN_CODE} </div>
                                        <div><span className="font-bold">CALIBER: </span> {node?.d?.data?.WPN_CBL}
                                        </div>
                                        <div><span className="font-bold">SERIAL: </span> {node?.d?.data?.WPN_SN} </div>
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;

                    case("NIBIN_INCIDENT"):
                        setToolTip(
                            <div>
                                <Card className="border-2 border-gray-700" sx={{maxWidth: 400, m: 'auto', mb: 1}}>
                                    <CardContent>
                                        {node.d.data.Inc_Location &&
                                            <div><span className="font-bold">Address:</span> {node.d.data.Inc_Location}</div>}
                                        {node.d.data.OccurrenceDate &&
                                            <div><span className="font-bold">Date-Time:</span> {formatDT(node.d.data.OccurrenceDate)}</div>}
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;

                    case("NIBIN_WEAPON"):
                        setToolTip(
                            <div>
                                <Card className="border-2 border-gray-700" sx={{maxWidth: 400, m: 'auto', mb: 1}}>
                                    <CardContent>
                                        {node.d.data.CALIBER &&
                                            <div><span className="font-bold">Caliber:</span> {node.d.data.CALIBER}</div>}
                                        {node.d.data.MAKE &&
                                            <div><span className="font-bold">Make:</span> {node.d.data.MAKE}</div>}
                                        {node.d.data.W_CLASS &&
                                            <div><span className="font-bold">Class:</span> {node.d.data.W_CLASS}</div>}
                                        {node.d.data.MODEL &&
                                            <div><span className="font-bold">Model:</span> {node.d.data.MODEL}</div>}
                                        {node.d.data.SERIAL_NUMBER &&
                                            <div><span className="font-bold">Serial Number:</span> {node.d.data.SERIAL_NUMBER}</div>}
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("NIBIN_BALLISTIC"):
                        setToolTip(
                            <div>
                                <Card className="border-2 border-gray-700" sx={{maxWidth: 400, m: 'auto', mb: 1}}>
                                    <CardContent>
                                        <div><span className="font-bold">Ballistic: </span> ({node?.d?.data?.Item_Quantity}) {node.t}</div>
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("JAIL_NODE"):
                        setToolTip(
                            <div>
                                <Card className="border-2 border-gray-700" sx={{maxWidth: 400, m: 'auto', mb: 1}}>
                                    <CardContent>
                                     
                                        <div><span className="font-bold">COMMITMENT COUNTY: </span> {node.d?.data?.COMMITMENT_COUNTY}</div>
                                        <div><span className="font-bold">COMMITMENT NUMBER: </span> {node.d?.data?.COMMITMENT_NUMBER}</div>
                                        <div><span className="font-bold">COMMITMENT DATE: </span> {formatDate(node.d?.data?.COMMITMENT_DATE)}</div>
                                        <div><span className="font-bold">COMMITMENT TIME: </span> {node.d?.data?.COMMITMENT_TIME}</div>
                                        <div><span className="font-bold">RELEASE DATE: </span> {formatDate(node.d?.data?.RELEASE_DATE)}</div>
                                        <div><span className="font-bold">RELEASE TIME: </span> {node.d?.data?.RELEASE_TIME}</div>
                                        <div><span className="font-bold">RELEASE REASON: </span> {node.d?.data?.RELEASE_REASON}</div>
                                        <div><span className="font-bold">LOCATION COUNTY: </span> {node.d?.data?.LOCATION_COUNTY}</div>
                                        <div><span className="font-bold">LOCATION UNIT: </span> {node.d?.data?.LOCATION_UNIT}</div>
                                        <div><span className="font-bold">LOCATION SECTION: </span> {node.d?.data?.LOCATION_SECTION}</div>
                                        <div><span className="font-bold">LOCATION CELL: </span> {node.d?.data?.LOCATION_CELL}</div>
                                        <div><span className="font-bold">LOCATION BED: </span> {node.d?.data?.LOCATION_BED}</div>
                                        <div><span className="font-bold">LOCATION NUMBER: </span> {node.d?.data?.LOCATION_NUMBER}</div>
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("MISSINGPERSON_NODE"):
                        setToolTip(
                            <div>
                                <Card className="border-2 border-gray-700" sx={{maxWidth: 400, m: 'auto', mb: 1}}>
                                    <CardContent>

                                        <div><span className="font-bold">NAME: </span> {node.d?.data?.FName + ' ' + node.d?.data?.LName}</div>
                                        <div><span className="font-bold">REPORT DATE: </span> {formatDate(node.d?.data?.ReportDate)}</div>
                                        <div><span className="font-bold">LAST KNOWN LOCATION: </span> {node.d?.data?.LastKnownLocation}</div>
                                        <div><span className="font-bold">{GetStringNameForSite(SiteName, "SBI")}: </span> {node.d?.data?.SBINo}</div>
                                        <div><span className="font-bold">FBI: </span> {node.d?.data?.FBI}</div>
                                        <div><span className="font-bold">ROLE: </span> {node.d?.data?.RoleDesc}</div>
                                        <div><span className="font-bold">STATUS: </span> {node.d?.data?.STATUS}</div>
                                        <div><span className="font-bold">RECORD NUMBER: </span> {node.d?.data?.recordNumber}</div>
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    default:
                        setToolTip(<></>)
                        break;
                }
            }

        } else {
            setToolTip(<></>)
        }
    }

    const exportChart = async () => {
        if (chartRef) {
            chartRef.toDataURL(null, null, {fit: 'chart', noScale: true, watermark: true,}).then((dataURL: any) => {
                fetch(dataURL)
                    .then(res => res.blob())
                    .then((blob: any) => {
                        var blobUrl = URL.createObjectURL(blob);
                        downloadPdf(blobUrl)
                    })
            });
        }
    }

    const downloadPdf = (image: any) => {
        const imageLink = document.createElement('a');
        imageLink.download = 'Link_Analysis.png'
        imageLink.href = image;
        imageLink.click();

        URL.revokeObjectURL(image);
    }

    
    const applyTimeFilter = () => {
        let timebar = timeLoc ?? timeRef;
        let chartObj = chartLoc ?? chartRef;
        let runFilter = showTimeline
        if (timebar && chartObj && runFilter) {
            chartObj.filter( (item:any) => {return (!(timebar.inRange(item)))}, { animate: false, type: 'node' }, () => {
                // and then adjust the chart's layout
                chartObj?.layout('organic', { mode: 'adaptive', animate: true, time: 1000 });
            });
        }

    }
    const loadedTimeBar = (timebar: any) => {
        timeLoc = timebar;
        setTimeRef(timebar)
    }
    const loadedChart = (chart: any) => {
        chartLoc = chart;
        setChartRef(chart)
        
        chart.layout('organic', {orientation: 'down', tightness: 5, animate: true});

        chart.on('view-change', (item: any) => {
            updateTooltipPosition(item)
        });
        chart.on('hover', createTooltip);
        chart.on('drag-move', updateTooltipPosition);
        // perform layout on combo open/close
        chart.on('double-click', onDoubleClick);

        //chart.on('selection-change', () => {
           // let timeChart = timeLoc ?? timeRef;
           // timeChart.selection({ id: chartRef.selection() });
        //});
        
        /*(timeLoc ?? timeRef)?.on('change', () => {
            applyTimeFilter();
        });*/
        
        chart.options({
            watermark: {
                a: 'bottom',
                fb: true,
                fs: 14,
                fc: '#d3d3d3',
                t: 'Provided By BackTrace Reports https://backtracereports.com'
            },
            combos: {
                shape: 'rectangle',
            }
        })

        runFilterOptions()

    }

    return (
        <>
            {isUserPermission('Arrest') && !loading ?
                <>
                    <div className="grid grid-cols-12 gap-2 h-screen">
                        <div className={"col-span-2 overflow-scroll"}>
                            <Button aria-label="delete" size="small" onClick={()=>exportChart()} variant="contained" style={{margin:'3px'}}>
                                {/*<PictureAsPdfIcon fontSize="small"  />*/}
                                Export as Image
                            </Button>
                            <Button aria-label="delete" size="small" onClick={()=>runReset()} variant="contained"  style={{margin:'3px'}}>
                                Rerun Filter
                            </Button>
                            <Button aria-label="delete" size="small" onClick={()=>layout()} variant="contained"  style={{margin:'3px'}}>
                                Reset Layout
                            </Button>
                            {/*<Button aria-label="delete" size="small" onClick={()=>toggleTimeline()} variant="contained"  style={{margin:'3px'}}>
                                {showTimeline ? 'Hide Timeline' : 'Show Timeline'}
                            </Button>*/}
                            {/*<Button aria-label="delete" size="small" onClick={()=>runLoadFullAssocPeople()}>
                                Expand Associated People
                            </Button>*/}
                            <hr />
                            
                            <Checkbox checked={showArrest} onChange={() => {
                                setShowArrest(!showArrest)
                            }}/>Arrest <br/>
                            <Checkbox checked={showALPR} onChange={() => {
                                setShowALPR(!showALPR)
                            }}/>ALPR <br/>
                            <Checkbox checked={showPlate} onChange={() => {
                                setShowPlate(!showPlate)
                            }}/>Plate <br/>
                            <Checkbox checked={showAssocPeople} onChange={() => {
                                setShowAssocPeople(!showAssocPeople)
                            }}/>Associated People <br/>
                            {showAssocPeople ? <>
                                <span style={{paddingLeft: '15px'}}> &#8627;<Checkbox 
                                    checked={showAssocPeopleAgencyCase} onChange={() => {
                                    setShowAssocPeopleAgencyCase(!showAssocPeopleAgencyCase)
                                }}/>Agency Case <br/></span>
                                <span style={{paddingLeft: '15px'}}> &#8627;<Checkbox
                                    checked={showAssocPeopleIncidentAddress} onChange={() => {
                                    setShowAssocPeopleIncidentAddress(!showAssocPeopleIncidentAddress)
                                }}/>Incident Address <br/></span>
                                <span style={{paddingLeft: '15px'}}> &#8627;<Checkbox
                                    checked={showAssocPeopleHomeAddressDMV} onChange={() => {
                                    setShowAssocPeopleHomeAddressDMV(!showAssocPeopleHomeAddressDMV)
                                }}/>Home Address(DMV) <br/></span>
                                <span style={{paddingLeft: '15px'}}> &#8627;<Checkbox
                                    checked={showAssocPeopleHomeAddressAFIS} onChange={() => {
                                    setShowAssocPeopleHomeAddressAFIS(!showAssocPeopleHomeAddressAFIS)
                                }}/>Home Address(AFIS) <br/></span>

                                {!(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) &&
                                    <span style={{paddingLeft: '15px'}}> &#8627;<Checkbox 
                                        checked={showAssocPeoplePlateETicket} onChange={() => {
                                        setShowAssocPeoplePlateETicket(!showAssocPeoplePlateETicket)
                                    }}/>Plate(ETicket) <br/></span>
                                }
                                {!(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) &&
                                <span style={{paddingLeft: '15px'}}> &#8627;<Checkbox 
                                        checked={showAssocPeopleDMV} onChange={() => {
                                        setShowAssocPeopleDMV(!showAssocPeopleDMV)
                                    }}/>DMV Registered Owner<br/></span>
                                }
                                {!(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) &&
                                <span style={{paddingLeft: '15px'}}> &#8627;<Checkbox checked={showAssocPeopleCODISPerson}
                                                                                   onChange={() => {
                                                                                       setShowAssocPeopleCODISPerson(!showAssocPeopleCODISPerson)
                                                                                   }}/>CODIS Person <br/></span>}
                                
                                <span style={{paddingLeft: '15px'}}> &#8627;<Checkbox checked={showAssocPeopleCADRMSCase}
                                                                                    onChange={() => {
                                                                                        setShowAssocPeopleCADRMSCase(!showAssocPeopleCADRMSCase)
                                                                                    }}/>{`${GetStringNameForSite(SiteName,'CAD/RMS')}`} Case <br/></span>
                                <span style={{paddingLeft: '15px'}}> &#8627;<Checkbox checked={showAssocPeopleCADRMSPlate}
                                                                                    onChange={() => {
                                                                                        setShowAssocPeopleCADRMSPlate(!showAssocPeopleCADRMSPlate)
                                                                                    }}/>{`${GetStringNameForSite(SiteName,'CAD/RMS')}`} Plate <br/></span>
                                <span style={{paddingLeft: '15px'}}> &#8627;<Checkbox 
                                    checked={showAssocPeopleCADRMSVIN} onChange={() => {
                                    setShowAssocPeopleCADRMSVIN(!showAssocPeopleCADRMSVIN)
                                }}/>{`${GetStringNameForSite(SiteName,'CAD/RMS')}`} VIN <br/></span>
                                
                                <span style={{paddingLeft: '15px'}}>  &#8627;<Checkbox
                                    checked={showAssocPeopleGunTraceCase} onChange={() => {
                                    setShowAssocPeopleGunTraceCase(!showAssocPeopleGunTraceCase)}}
                                />Gun-Trace Case <br/></span>
                                <span style={{paddingLeft: '15px'}}> &#8627;<Checkbox
                                    checked={showAssocPeopleShootingCase} onChange={() => {
                                    setShowAssocPeopleShootingCase(!showAssocPeopleShootingCase)
                                }}/>Shooting Case <br/></span>
                                <span style={{paddingLeft: '15px'}}> &#8627;
                                    <Checkbox checked={showAssocPeopleNIBINCase}
                                        onChange={() => {
                                        setShowAssocPeopleNIBINCase(!showAssocPeopleNIBINCase)}}
                                    />
                                    NIBIN Case <br/>
                                </span>
                                <span style={{paddingLeft: '15px'}}> &#8627;
                                    <Checkbox checked={showAssocPeopleDL}
                                        onChange={() => {
                                        setShowAssocPeopleDL(!showAssocPeopleDL)}}
                                    />
                                    DL # <br/>
                                </span>
                                <span style={{paddingLeft: '15px'}}> &#8627;
                                    <Checkbox checked={showAssocPeopleFBI}
                                       onChange={() => {
                                       setShowAssocPeopleFBI(!showAssocPeopleFBI)}}
                                    />
                                    FBI #<br/>
                                </span>
                                <span style={{paddingLeft: '15px'}}> &#8627;
                                    <Checkbox checked={showAssocPeopleSBI}
                                       onChange={() => {
                                       setShowAssocPeopleSBI(!showAssocPeopleSBI)}}
                                    />
                                    {GetStringNameForSite(SiteName,'SBI #')} <br/>
                                </span>

                                {!(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) &&
                                <span style={{paddingLeft: '15px'}}> &#8627;
                                    <Checkbox checked={showAssocPeopleTowedPlate}
                                       onChange={() => {
                                       setShowAssocPeopleTowedPlate(!showAssocPeopleTowedPlate)}}
                                    />
                                    Towed Plate <br/>
                                </span>}

                                {!(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) &&
                                <span style={{paddingLeft: '15px'}}> &#8627;
                                    <Checkbox checked={showAssocPeopleTowedVIN}
                                       onChange={() => {
                                       setShowAssocPeopleTowedVIN(!showAssocPeopleTowedVIN)}}
                                    />
                                    Towed VIN <br/>
                                </span>}
                            </> : <></>}
                            <Checkbox checked={showCADRMS} onChange={() => {
                                setShowCADRMS(!showCADRMS)
                            }}/> {GetStringNameForSite(SiteName,'CAD/RMS')} <br/>
                            
                            {!(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) && <>
                                <Checkbox checked={showDNA} onChange={() => {
                                    setShowDNA(!showDNA)
                                }}/>CODIS (DNA) <br/>
                                <Checkbox checked={showETicket} onChange={() => {
                                    setShowETicket(!showETicket)
                                }}/>ETicket <br/>
                                <Checkbox checked={showETrace} onChange={() => {
                                    setShowETrace(!showETrace)
                                }}/>ETrace <br/>
                            </>}
                            
                            <Checkbox checked={showNIBIN} onChange={() => {
                                setShowNIBIN(!showNIBIN)
                            }}/>NIBIN <br/>
                            <Checkbox checked={showNJPOP} onChange={() => {
                                setShowNJPOP(!showNJPOP)
                            }}/>{GetStringNameForSite(SiteName, 'NJPOP')}<br/>
                            <Checkbox checked={shownjtrace} onChange={() => {
                                setShownjtrace(!shownjtrace)
                            }}/>{GetStringNameForSite(SiteName, 'NJTRACE')} <br/>
                            <Checkbox checked={showParkMobile} onChange={() => {
                                setShowParkMobile(!showParkMobile)
                            }}/>Park Mobile <br/>

                            {!(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) && <>
                                <Checkbox checked={showParole} onChange={() => {
                                    setShowParole(!showParole)
                                }}/>Parole <br/>
                                <Checkbox checked={showPrison} onChange={() => {
                                    setShowPrison(!showPrison)
                                }}/>Prison <br/>
                                <Checkbox checked={showJail} onChange={() => {
                                    setShowJail(!showJail)
                                }}/>Jail <br/>
                                <Checkbox checked={showProfession} onChange={() => {
                                    setShowProfession(!showProfession)
                                }}/>Profession <br/>
                                <Checkbox checked={showViolent} onChange={() => {
                                    setShowViolent(!showViolent)
                                }}/>Violent <br/>
                                <Checkbox checked={showInfoCop} onChange={() => {
                                    setShowInfoCop(!showInfoCop)
                                }}/>InfoCop <br/>
                            </>}
                            <Checkbox checked={showWeapon} onChange={() => {
                                setToggle('WEAPON')
                            }}/>Weapon <br/>
                            {(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) && 
                            <>
                                <Checkbox checked={showMissingPerson} onChange={() => {
                                    setShowMissingPerson(!showMissingPerson)
                                }}/>Missing Person <br/>
                            </>}
                        </div>

                        <div className={"col-span-10"} >
                            <div chart-container style={{height:(showTimeline ? '80vh' : '100vh')}}> {/*style={{height:'85vh'}}*/}
                                <div ref={myContainer} style={toolTipStyle}>{toolTip}</div>
                                <Chart
                                    click={clickHandler}
                                    data={data}
                                    ready={loadedChart}
                                    containerClassName="PersonAnalysisChartContainer"
                                />
                            </div>
                            <div style={{height:'10vh', display: (!showTimeline ? 'none' : '')}}>
                                <div style={{position: 'relative', bottom:0, left:0}}>
                                    <Timebar
                                        data={data}
                                        ready={loadedTimeBar}
                                        containerClassName="PersonAnalysisTimeBarContainer"
                                    />
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </>
                : <></>}
        </>
    );
}

export default PersonAnalysis;