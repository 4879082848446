import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { NJPortDashboardDataApi } from "../../../services/njport.dashboard.service";

const ShipEntryBarChartMonthly = () => {
  const [data, setData] = useState<any[]>([]);
  const [option, setOption] = useState<any>({});

  useEffect(() => {
    const payload = {
      queryType: "SHIPENTRY_MONTH_START",
      dateTypeValue: 1,
    };
    NJPortDashboardDataApi(payload).then((qRes) => {
      setData(qRes?.AnyTable);
    });
  }, []);

  useEffect(() => {
    if (data && data.length) {
      const formattedData = handleFormatData(data);

      setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        toolbox: {
          feature: {
            saveAsImage: { show: true },
          },
        },
        grid: {
          left: "1%",
          right: "1%",
          bottom: "1%",
          top: "3%",
          containLabel: true,
        },
        legend: {
          data: ["Arrived"],
        },
        xAxis: {
          type: "category",
          axisLabel: {
            interval: 0,
            rotate: 45,
            fontSize: 8,
          },
          data: formattedData.xAxis,
        },
        yAxis: {
          type: "value",
          axisLabel: {
            show: true,
          },
          axisLine: {
            show: true,
          },
          splitLine: {
            show: true,
          },
          axisTick: {
            show: true,
          },
        },
        series: [
          {
            name: "Arrived",
            type: "bar",
            stack: "Total",
            color: "#1e3a8a",
            label: {
              show: true,
              position: "inside",
              rotate: 20,
              fontSize: 11,
            },
            data: formattedData.arrivedData,
          },
          
          
        ],
      });
    }
  }, [data]);

  const handleFormatData = (data: any[]) => {
    const xAxis: any[] = [];
    const arrivedData: any[] = [];

    data.forEach((item) => {
      const terminalDesc = item.TerminalDesc;
      // if (!xAxis.includes(terminalDesc)) {
        xAxis.push(terminalDesc);
      // }
      arrivedData.push(item.Available || 0);
    });

    return { xAxis, arrivedData };
  };

  return (
    <>
      {data && data.length !== 0 ? (
        <div>
          <ReactECharts option={option} style={{ height: "400px" }} />
        </div>
      ) : (
        <div
          className={"text-center text-base xl:text-3xl mt-6 mb-1"}
          style={{ color: "red", fontWeight: 500 }}
        >
          No Data
        </div>
      )}
    </>
  );
};

export default ShipEntryBarChartMonthly;
