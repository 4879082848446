import React from "react";
import { useLocation } from "react-router-dom";
import LPRDetailInfo from "../../components/Detail/_LPRDetailInfo";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}


function LPRDetail() {
    let query = useQuery();

    return (
        <div>
            <LPRDetailInfo ID={query.get("ID")} ALPRObject={query.get("ALPRObject")} department={query.get("Dept")} />
        </div>
    );
}

export default LPRDetail;