import {PersonInfo} from "../../interfaces/getDarInfo_interface";
import React, {useEffect, useState} from "react";
import {SearchDL} from "../../interfaces/searchDL.interface";
import {
    RunPerson_ImageQuery,
} from "../../services/getDar.service";
import PersonImageInfo from "../../interfaces/PersonImageInfo.interface";
import NoPersonImage from '../../assets/Images/noImageAvailable.png'
import {Account} from "../../interfaces/auth_interface";
import {getUser} from "../../services/auth.service";
interface BTPersonGridImageProps {
    person: PersonInfo,
}

const BTPersonGridImage: React.FC<BTPersonGridImageProps> = ({ person}) => {
    let [user, setUser] = useState<Account>(getUser())
    let [personMiniImageInfo, setPersonMiniImageInfo] = useState<PersonImageInfo[]>([])
    let [personMiniImage, setPersonMiniImage] = useState<string>(NoPersonImage)
    
    React.useEffect(()=>{
        let today: string = new Date().toLocaleDateString();
        let query: SearchDL = {
            City: person?.City,
            DLNo: person?.DLNo,
            DLSt: person?.DLSt,
            DOB: person?.DOB ? (person?.DOB)?.toLocaleString() : "",
            DarID: person?.DarID ? person?.DarID.toString() : null,
            Dept: user.Dept,
            DeptORI: user.DeptORI,
            ETicket: 'OneImageOnly',  //Grid should disply ONLY one Image. API should return only 1 image
            EmpID: user.EmpID,
            FBI: person?.FBI,
            FirstName: person?.FName,
            FromDate: person?.PercentileDate ? person.PercentileDate.toLocaleString() : today ,
            ToDate: person?.PercentileDate ? person.PercentileDate.toLocaleString() : today,
            GANGRELATED: person?.GANGRELATED,
            LastName: person?.LName,
            LoggedDept: null,
            LoggedEmpID: null,
            MiddleName: person?.MName,
            PersonID: person?.PersonID,
            QueryType: null,
            CrumbScorePercentile: person?.CrumbScorePercentile,
            SBINo: person?.SBINo,
            SEX: person?.SEX
        }
        
        RunPerson_ImageQuery(query).then(response => {
            if(response.AnyData){
                setPersonMiniImageInfo(response.AnyData);
                let res_img = (response.AnyData as PersonImageInfo[])
                if(res_img && res_img.length>0 ){
                    setPersonMiniImage(res_img[0]?.Image)
                }
            }
        })
    }, [person])
    
    return (<img src={personMiniImage} style={{"width":"120px", "height":"100px"}} alt={"Person Image"}/>)
};

export default BTPersonGridImage;