import AuthenticatedAPIReq from "./AuthenticatedAPIReq.service";
import { AxiosResponse } from "axios";
import AuthenticatedDashboardReqService from "./AuthenticatedDashboardReq.service";
import AuthenticatedDashboardAPIReq from "./AuthenticatedDashboardReq.service";
import { GetDefaultStateForSite } from "../interfaces/config.interface";
import { SiteName } from "./config.service";

export async function NJPortDashboardDataApi(param: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedDashboardAPIReq.post('/api/NJPort/Dashboard', param)
    return (res.data)
}

export async function VolunteerDashboardDataApi(param: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedDashboardAPIReq.post('/api/NJPort/Dashboard', param)
    return (res.data)
}

export async function PDohireDashboardDataApi(param: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedDashboardAPIReq.post('/api/NJPort/Dashboard', param)
    return (res.data)
}