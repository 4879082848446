import {Box, Card, CardContent} from "@mui/material";
import React, { useEffect, useState } from "react";
import {BallTriangle, Bars, Watch} from 'react-loader-spinner'
import Logo from "../../assets/Images/bt-logo-transparent.png";
import {useLocation} from "react-router-dom";
import {RunSharedToken} from "../../services/account.service";
import BTPersonReport from "../shared/_BTPersonReport";
import VerifyingAuthentication from "../shared/_VerifyingAuthentication";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function SecuredView() {
    let query = useQuery();
    let [id , setID] = React.useState<any>(query.get("ID"))
    let [dept , setDept] = React.useState<any>(query.get("Dept"))
    let [initialRun, setInitialRun] = React.useState<boolean>(false)
    let [personInfo, setPersonInfo] = React.useState<any>(null)
    
    
    React.useEffect(()=>{
        if(!initialRun)
        {
            setInitialRun(true)
            handleGetExpiryToken()
        }
        }, [])
    
    const handleGetExpiryToken = async () => {
        RunSharedToken(id,dept).then((res) => {
            if(res.AuthToken && res.AuthToken.length > 0) {
                if (localStorage.getItem('user') == null) {
                    localStorage.setItem('user', res.AuthToken)
                }
            }
            if(res.AnyData) {
                let temp = JSON.parse(res.AnyData)
                temp.FName = temp.FirstName
                temp.MName = temp.MiddleName
                temp.LName = temp.LastName
                setPersonInfo(temp)
            }
        })
    }
    
    return (
        <div>
            {personInfo != null  ?
                <div className = "m-auto w-10/12">
                    <BTPersonReport person={personInfo}/>
                </div>
                :
                <VerifyingAuthentication />
            }
        </div>
    );
}

export default SecuredView;