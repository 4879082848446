import React, {useState} from "react";
import ReactECharts from "echarts-for-react";

interface ShootingsDashboardProps {
    location:string,
    dbData:any,
}


const ShootingVictimDashboardGraph: React.FC<ShootingsDashboardProps> = ({location, dbData}) => {
    var option;
    
    const handleFormatXAxis = () => {
        let xAxis:any = []
        if(dbData.YearlyData)
        {
            for(let year of dbData.YearlyData)
            {
                xAxis.push(year.Year)
            }
        }
        return xAxis
    }

    const handleSeries = () => {
        let alive:any = []
        let dead:any = []
        let series:any = []
        let total = []
        
        if(dbData.YearlyData)
        {
            for(let year of dbData.YearlyData)
            {
                alive.push(year.Alive)
                dead.push(year.Dead)
                total.push( 0 )
            }
            series.push({
                name: 'Non-Fatal',
                type: 'bar',
                stack: true,
                color:'#1e3a8a',
                label: {
                    show: true
                },
                emphasis: {
                    focus: 'series'
                },
                data: alive
            },)
            series.push({
                name: 'Fatal',
                type: 'bar',
                stack: true,
                color:'#8E1600',
                label: {
                    show: true,
                },
                emphasis: {
                    focus: 'series'
                },
                data: dead
            },)
            series.push({
                type: 'bar',
                stack: true,
                color:'black',
                label: {
                    show: true,
                    formatter: genFormatter(series),
                    position: 'top',
                    fontSize: '14px',
                    fontWeight: 'bold',
                },
                data: total
            },)
        }
        return series
    }

    const genFormatter = (series:any) => {
        return (param:any) => {
            let sum = 0;
            series.forEach((item:any) => {
                sum += item.data[param.dataIndex];
            });
            return sum
        }
    };
    
    option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                // Use axis to trigger tooltip
                type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
            }
        },
        height:'200',
        width:'425',
        toolbox: {
            feature: {
                saveAsImage: { show: true }
            }
        },
        legend: {
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            top:'15%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            data: handleFormatXAxis(),
        },
        yAxis: {
            type: 'value'
        },
        series: handleSeries()
    };

    return (
        <div className = "border-gray-900 border-2 h-72">
            <div className="bg-blue-900 font-bold text-yellow-400 text-center h-8 text-lg">Shooting Victims Per Year</div>
            {dbData && dbData?.YearlyData?.length !== 0 ?
                <div className = "border-gray-900 border-t-2">
                    <ReactECharts option={option} />
                </div>
                :
                <div>No Data</div>
            }
        </div>
    );
}

export default ShootingVictimDashboardGraph;