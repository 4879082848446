import "./styles.css"
import React, { useCallback, useRef } from "react";
import {
    Alert,
    AlertColor,
    Autocomplete,
    Avatar,
    AvatarGroup,
    Box,
    Button,
    ButtonGroup,
    Card,
    CardContent, Checkbox,
    Chip,
    CircularProgress,
    debounce,
    Dialog,
    DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    List,
    ListItem,
    MenuItem,
    Select, Slide, Snackbar,
    Stack, Switch,
    TextareaAutosize,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import PrintIcon from '@mui/icons-material/Print';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import noImage from "../../../assets/Images/cbimage.png";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from "@mui/icons-material/Delete";
import { createFilterOptions } from '@mui/material/Autocomplete';
import {
    additionalInfo,
    assocObject,
    deconObject, editRecord, personImportObject,
    profileObject,
    PersonReportFile,
    profileReportObject
} from "../../../interfaces/PersonReport.interface";
import {JAOS} from "../../../services/JAOS.service";
import {
    CheckDuplicateReportNum,
    GetProfileReportFiles,
    GetProfileReportPreview,
    SetProfileReport,
} from "../../../services/account.service";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Account } from "../../../interfaces/auth_interface";
import { getUser } from "../../../services/auth.service";
import { getDropDownCity, getDropDownQuery } from "../../../services/getDropdown.service";
import { TransitionProps } from "@mui/material/transitions";
import PhoneInput from 'react-phone-number-input/input'
import { ContentBlock, ContentState, convertFromHTML, convertFromRaw, EditorState } from "draft-js";
import { Editor } from 'react-draft-wysiwyg';
import { stateToHTML } from "draft-js-export-html";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';
import { width } from "pdfkit/js/page";
import DownloadIcon from '@mui/icons-material/Download';
import { base64_to_blob } from "../../../services/detail.service";
import { NoiseAware } from "@mui/icons-material";
import { templateCompiler } from "@syncfusion/ej2-react-grids";
import { promises } from "dns";
import {GetDefaultStateForSite, GetStringNameForSite} from "../../../interfaces/config.interface";
import {SiteName} from "../../../services/config.service";



interface ProfileReportProps {
    handleReportSubmit: any,
    editReport: any,
    personDetails: any
}

interface dropdown {
    value:string,
    key:string
}

interface docItem {
    name:string,
    size:number,
    type:string
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ProfileReportInputForm: React.FC<ProfileReportProps> = ({handleReportSubmit, editReport = null, personDetails = null}) => {


    const [report, setReport] = React.useState<profileObject>({})
    const [heightDD, setHeightDD] = React.useState<dropdown[]>([]);
    const [hairDD, setHairDD] = React.useState<string[]>(["Bald","Black","Blond", "Blue","Brown", "Gray", "Green", "Orange", "Pink", "Purple", "Red", "Sandy", "White", "Unknown"]);
    const [eyesDD, setEyesDD] = React.useState<string[]>(["Black","Blue","Brown", "Gray", "Green", "Hazel", "Maroon", "Multicolored", "Pink", "Unknown"]);
    const [raceDD, setRaceDD] = React.useState<string[]>(["American Indian","Alaskan Native", "Asian", "Black","Hispanic", "Pacific Islander", "White","Unknown"]);
    const [genderDD, setGenderDD] = React.useState<string[]>(["Male","Female","Transgender", "Non-Binary","Unknown"])
    const [DDAAgency, setDDAAgency] = React.useState<any[]>([]);
    const [reportUrl, setReportUrl] = React.useState<string | undefined>(undefined);
    const [user, setUser] = React.useState<Account>(getUser())
    const [addedFiles, setAddedFiles] = React.useState<PersonReportFile[]>([])
    const [existingFiles, setExistingFiles] = React.useState<PersonReportFile[]>([])
    const [stateList, setStateList] = React.useState<any>([])
    const [cityList, setCityList]= React.useState<any>([])
    const [editorCommentState, setEditorCommentState] = React.useState<any>(EditorState.createEmpty())
    const [editorScarState, setEditorScarState] = React.useState<any>(EditorState.createEmpty())
    const [editorPriorChargeState, setEditorPriorChargeState] = React.useState<any>(EditorState.createEmpty())
    const [editorArrestAgencyState, setEditorArrestAgencyState] = React.useState<any>(EditorState.createEmpty())
    const [editorPendingCaseState, setEditorPendingCaseState] = React.useState<any>(EditorState.createEmpty())
    const [editorStateCustodyState, setEditorStateCustodyState] = React.useState<any>(EditorState.createEmpty())
    const [editorCountyCustodyState, setEditorCountyCustodyState] = React.useState<any>(EditorState.createEmpty())
    const [editorMVCState, setEditorMVCState] = React.useState<any>(EditorState.createEmpty())
    const [editorContactsState, setEditorContactsState] = React.useState<any>(EditorState.createEmpty())
    const [editorTripleState, setEditorTripleState] = React.useState<any>(EditorState.createEmpty())
    const [editorALPRState, setEditorALPRState] = React.useState<any>(EditorState.createEmpty())
    const [editorHoldsState, setEditorHoldsState] = React.useState<any>(EditorState.createEmpty())
    const [editorParoleState, setEditorParoleState] = React.useState<any>(EditorState.createEmpty())
    const [editorEmpHistoryState, setEditorEmpHistoryState] = React.useState<any>(EditorState.createEmpty())
    const [editorDomViolenceState, setEditorDomViolenceState] = React.useState<any>(EditorState.createEmpty())
    const [editorJuvRegistryState, setEditorJuvRegistryState] = React.useState<any>(EditorState.createEmpty())
    const [editorSMState, setEditorSMState] = React.useState<any>(EditorState.createEmpty())
    const [editorArrestState, setEditorArrestState] = React.useState<any>(EditorState.createEmpty())
    const [editorInterviewState, setEditorInterviewState] = React.useState<any>(EditorState.createEmpty())
    const [editorPoliceState, setEditorPoliceState] = React.useState<any>(EditorState.createEmpty())
    const [openDialog, setOpenDialog] = React.useState(false)
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false)
    const [alertText, setAlertText] = React.useState<String>('')
    const [autoSaveToggle, setAutoSaveToggle] = React.useState<boolean>(false);
    const [alertType, setAlertType] = React.useState<any>("success")
    const [activeUsers, setActiveUsers] = React.useState<string[]>([]);



    React.useEffect(() => {
        handleInitialBuild()
    }, [personDetails])

    const handleInitialBuild = async () =>{
        await handleDropdowns()
        if(editReport || personDetails){
            await handleProfileReportObj()
        }
        else{
            await handleNewForm()
        }

    }

    React.useEffect(() => {
        let interval: NodeJS.Timer | null = null;
        if (autoSaveToggle) {
            interval = setInterval(() => {
                let temp = {...report}

                if(addedFiles && addedFiles.length > 0){
                    handleAutoSaveItems()
                }
                else if (temp.DeconObject && temp.DeconObject.find(o => o.ID === 0)){
                    handleAutoSaveItems()
                }
                else if (temp.AdditionalInfo && temp.AdditionalInfo.find(o => o.ID === 0)){
                    handleAutoSaveItems()
                }
                else if (temp.ReviewedBy && temp.ReviewedBy.find(o => o.ID === 0)){
                    handleAutoSaveItems()
                }
                else if (temp.PreparedBy && temp.PreparedBy.find(o => o.ID === 0)){
                    handleAutoSaveItems()
                }
                else{
                    handleReportSubmit(temp , false)
                }

            }, 60000);
        } else if (!autoSaveToggle && interval) {
            clearInterval(interval);
        }
        return () => {
            if(interval)
                clearInterval(interval);
        }
    }, [autoSaveToggle, report.DeconObject, report.AdditionalInfo, report.ReviewedBy, report.PreparedBy, addedFiles])


    const handleAutoSaveItems = async () => {
        let temp = {...report}
        if (autoSaveToggle && addedFiles && addedFiles.length > 0){
            let added = [...addedFiles]
            let existing = [...existingFiles]
            temp.Files = existing.concat(added)
            let saveRequest = await SetProfileReport(temp)
            setReport(saveRequest)
            setAddedFiles([])
            setExistingFiles(saveRequest.Files)

        }
        else {
            //let saveRequest = await SetProfileReport(temp)
            await SetProfileReport(temp).then(response => {setReport(response)})
            //console.log(saveRequest)
            //setReport(saveRequest)
        }

    }


    const handleValueChange = async (obj:string, field:string, value:any, index: number = 0) =>{

        //set up objects if they don't exist
        let temp = {...report}

        let d = temp.DeconObject ? [...temp.DeconObject] : []
        let a = temp.AdditionalInfo ? [...temp.AdditionalInfo] : []
        let p = temp.PreparedBy ? [...temp.PreparedBy] : []
        let r = temp.ReviewedBy ? [...temp.ReviewedBy] : []

        if(!temp.ProfileReportObject){
            temp.ProfileReportObject = {}
        }
        if(!temp.PersonObject){
            temp.PersonObject = {}
        }
        if(!temp.DeconObject || temp.DeconObject.length === 0){
            temp.DeconObject =[{}]
        }
        if(!temp.CustodyHistoryObject){
            temp.CustodyHistoryObject = {}
        }
        if(!temp.PoliceContactLocations){
            temp.PoliceContactLocations = {}
        }
        if(!temp.CriminalActivityObject){
            temp.CriminalActivityObject = {}
        }
        if(!temp.ReviewedBy){
            temp.ReviewedBy = [{}]
        }
        if(!temp.PreparedBy){
            temp.PreparedBy = [{}]
        }
        if(!temp.PersonDetailsObject){
            temp.PersonDetailsObject = {}
        }

        switch(obj){
            case 'ProfileReport':
                if(temp.ProfileReportObject){
                    switch(field){
                        case 'ReportType':
                            temp.ProfileReportObject.ReportType = value
                            break
                        case 'Pic':
                            temp.ProfileReportObject.Pic = value
                            break
                        case 'Comments':
                            temp.ProfileReportObject.Comments = value.replaceAll('&nbsp;','')
                            //temp.ProfileReportObject.Comments = value
                            break
                        case 'DeconChecked':
                            temp.ProfileReportObject.DeconChecked = value
                            temp.DeconChecked = value
                            break
                        case 'CENum':
                            temp.ProfileReportObject.CENum = value
                            break
                        case 'ReportNum':
                            temp.ProfileReportObject.ReportNum = value
                            break
                        case 'ReportDate':
                            temp.ProfileReportObject.ReportDate = value
                    }
                }
                break

            case 'PersonObject':
                if(temp.PersonObject){
                    switch(field){
                        case 'FirstName':
                            temp.PersonObject.FirstName = value
                            break
                        case 'MiddleName':
                            temp.PersonObject.MiddleName = value
                            break
                        case 'LastName':
                            temp.PersonObject.LastName = value
                            break
                        case 'Address':
                            temp.PersonObject.Address = value
                            break
                        case 'City':
                            temp.PersonObject.City = value
                            break
                        case 'State':
                            temp.PersonObject.State = value
                            if(temp.PersonObject.State !== 'NJ'){
                                setCityList([])
                                var city = await getDropDownCity(GetDefaultStateForSite(SiteName))
                                let tempList = city?.filter(x=> x.State === temp.PersonObject?.State)
                                const ids = tempList.map(({ City }) => City);
                                const filtered = tempList.filter(({ City }, index) =>
                                    !ids.includes(City, index + 1));
                                setCityList(filtered)
                            }
                            break
                        case 'DOB':
                            temp.PersonObject.DOB = value
                            break
                        case 'SBI':
                            temp.PersonObject.SBI = value
                            break
                        case 'FBI':
                            temp.PersonObject.FBI = value
                            break
                        case 'Alias':
                            temp.PersonObject.Alias = value
                            break
                        case 'Gang':
                            temp.PersonObject.Gang = value
                            break
                        case 'Phone':
                            temp.PersonObject.Phone = value
                            break
                        case 'DNA':
                            temp.PersonObject.DNA = value
                            break
                        case 'Height':
                            temp.PersonObject.Height = value
                            break
                        case 'Weight':
                            temp.PersonObject.Weight = value
                            break
                        case 'Hair':
                            temp.PersonObject.Hair = value
                            break
                        case 'Eyes':
                            temp.PersonObject.Eyes = value
                            break
                        case 'Race':
                            temp.PersonObject.Race = value
                            break
                        case 'Gender':
                            temp.PersonObject.Gender = value
                            break
                        case 'Scars':
                            temp.PersonObject.Scars = value.replaceAll('&nbsp;','')
                            break
                    }
                }
                break
            case 'DeconObject':
                if(temp.DeconObject){
                    switch(field){
                        case 'CaseAgent':
                            d[index].CaseAgent = value
                            break
                        case 'CaseAgency':
                            d[index].CaseAgency = value
                            break
                        case 'Phone':
                            d[index].Phone = value
                            break
                        case 'Email':
                            d[index].Email = value
                            break
                        case 'CaseNum':
                            d[index].CaseNum = value
                            break
                    }
                }
                break
            case 'CustodyHistoryObject':
                if(temp.CustodyHistoryObject){
                    switch(field){
                        case 'StateCustody':
                            temp.CustodyHistoryObject.StateCustody = value.replaceAll('&nbsp;','')
                            break
                        case 'CountyCustody':
                            temp.CustodyHistoryObject.CountyCustody = value.replaceAll('&nbsp;','')
                            break
                    }
                }
                break

            case 'PoliceContactLocations':
                if(temp.PoliceContactLocations){
                    switch(field){
                        case 'Arrests':
                            temp.PoliceContactLocations.Arrests = value.replaceAll('&nbsp;','')
                            break
                        case 'FieldInterviews':
                            temp.PoliceContactLocations.FieldInterviews = value.replaceAll('&nbsp;','')
                            break
                        case 'Other':
                            temp.PoliceContactLocations.Other = value.replaceAll('&nbsp;','')
                            break
                    }
                }
                break
            case 'CriminalActivityObject':
                if(temp.CriminalActivityObject){
                    switch(field){
                        case 'TotalArrest':
                            temp.CriminalActivityObject.TotalArrest = value
                            break
                        case 'Convictions':
                            temp.CriminalActivityObject.Convictions = value
                            break
                        case 'PriorCharges':
                            temp.CriminalActivityObject.PriorCharges = value.replaceAll('&nbsp;','')
                            break
                        case 'ArrestingAgency':
                            temp.CriminalActivityObject.ArrestingAgency = value.replaceAll('&nbsp;','')
                            break
                        case 'PendingCases':
                            temp.CriminalActivityObject.PendingCases = value.replaceAll('&nbsp;','')
                    }
                }
                break
            case 'AdditionalInfo':
                if(temp.AdditionalInfo){
                    switch(field){
                        case 'Content':
                            a[index].Content = value
                            break
                        case 'Type':
                            a[index].Type = value
                            break
                    }
                }
                break
            case 'PreparedBy':
                p[index].Content = value
                break
            case 'ReviewedBy':
                r[index].Content = value
                break
            case 'PersonDetailsObject':
                if(temp.PersonDetailsObject){
                    switch(field){
                        case 'MVCStatus':
                            temp.PersonDetailsObject.MVCStatus = value.replaceAll('&nbsp;','')
                            break
                        case 'AssociateContacts':
                            temp.PersonDetailsObject.AssociateContacts = value.replaceAll('&nbsp;','')
                            break
                        case 'Triple':
                            temp.PersonDetailsObject.Triple = value.replaceAll('&nbsp;','')
                            break
                        case 'ALPR':
                            temp.PersonDetailsObject.ALPR = value.replaceAll('&nbsp;','')
                            break
                        case 'Holds':
                            temp.PersonDetailsObject.Holds = value.replaceAll('&nbsp;','')
                            break
                        case 'Parole':
                            temp.PersonDetailsObject.Parole = value.replaceAll('&nbsp;','')
                            break
                        case 'EmploymentHistory':
                            temp.PersonDetailsObject.EmploymentHistory = value.replaceAll('&nbsp;','')
                            break
                        case 'DomesticViolence':
                            temp.PersonDetailsObject.DomesticViolence = value.replaceAll('&nbsp;','')
                            break
                        case 'JuvenileRegistry':
                            temp.PersonDetailsObject.JuvenileRegistry = value.replaceAll('&nbsp;','')
                            break
                        case 'SocialMedia':
                            temp.PersonDetailsObject.SocialMedia = value.replaceAll('&nbsp;','')
                            break
                    }
                }
                break
        }

        setReport(temp)
    }

    const handleNewForm = async () =>{
        let temp = {...report}
        if(!temp.PersonObject || !temp.PersonObject.State){
            temp.PersonObject = {
                ID:0,
                ProfileID : 0,
                State : 'NJ',
                City: '',
                DOB:''
            }

        }

        if(!temp.DeconObject || temp.DeconObject.length === 0){
            temp.DeconObject = [
                {
                    ID:0,
                    ProfileID:0,
                    CaseAgency:"",
                    CaseAgent:"",
                    Deleted: false,
                    Email:"",
                    Phone:"",
                }
            ]

        }

        if (!temp.AdditionalInfo || temp.AdditionalInfo.length === 0){
            temp.AdditionalInfo = [
                {
                    ID:0,
                    ProfileID:0,
                    Type:"Additional Aliases:",
                    Content:"",
                    Deleted: false,
                    Default: true
                },
                {
                    ID:0,
                    ProfileID:0,
                    Type:"Additional Addresses:",
                    Content:"",
                    Deleted: false,
                    Default: true
                },
                {
                    ID:0,
                    ProfileID:0,
                    Type:"Additional Telephone Numbers:",
                    Content:"",
                    Deleted: false,
                    Default: true
                },

            ]
        }
        if(!temp.PreparedBy || temp.PreparedBy.length === 0){
            temp.PreparedBy= [
                {
                    ID:0,
                    ProfileID:0,
                    Type:'PreparedBy',
                    Content:user.Firstname + ' ' + user.Lastname,
                    //Deleted: false

                }
            ]
        }
        if(!temp.ReviewedBy || temp.ReviewedBy.length === 0){
            temp.ReviewedBy= [
                {
                    ID:0,
                    ProfileID:0,
                    Type:'ReviewedBy',
                    Content:'',
                    //Deleted: false

                }
            ]
        }

        // if(!temp.Files || temp.Files.length === 0){
        //     temp.Files= [
        //         {
        //             FileName: '',

        //             Encoding: '',
        //             Content: '',
        //             Deleted: false
        //         }
        //     ]
        // }

        if(!temp.PicObject || !temp.PicObject.Pic){
            temp.PicObject ={
                ID:0,
                ProfileID:0,
                Pic:null
            }
        }

        if (!temp.ProfileReportObject?.EditStatus){
            temp.ProfileReportObject ={
                EditStatus:0
            }
        }
        if(!temp.ProfileReportObject?.ReportDate){
            temp.ProfileReportObject.ReportDate = new Date();
        }

        setReport(temp)
    }

    const handleProfileReportObj = async () =>{
        let temp = {...report}
        if(editReport){
            temp = editReport
            //Handles import existing report and add new report from person summary into new report sets ID/ProfileID = 0
            if('newReport' in temp)
            {
                if(temp.PersonObject){
                    temp.PersonObject.ID = 0
                    temp.PersonObject.ProfileID = 0
                }

                if(temp.ProfileReportObject){
                    temp.ProfileReportObject.ID = 0
                    temp.ProfileReportObject.ProfileID = 0
                    temp.ProfileReportObject.ReportNum = ''
                    temp.ProfileReportObject.ReportDate = new Date();
                }

                if(temp.CriminalActivityObject){
                    temp.CriminalActivityObject.ID = 0
                    temp.CriminalActivityObject.ProfileID = 0
                }

                if(temp.CustodyHistoryObject){
                    temp.CustodyHistoryObject.ID = 0
                    temp.CustodyHistoryObject.ProfileID = 0
                }

                if(temp.PersonDetailsObject){
                    temp.PersonDetailsObject.ID = 0
                    temp.PersonDetailsObject.ProfileID = 0
                }

                if(temp.PoliceContactLocations){
                    temp.PoliceContactLocations.ID = 0
                    temp.PoliceContactLocations.ProfileID = 0
                }

                if(temp.PicObject){
                    temp.PicObject.ID = 0
                    temp.PicObject.ProfileID = 0
                    //temp.PicObject.Pic = temp.PicObject.Pic ? 'data:image/jpeg;base64,' + temp.PicObject?.Pic : noImage
                    if(temp.PicObject.Pic && temp.PicObject.Pic.includes('data:image/jpeg;base64')){
                        temp.PicObject.Pic = temp.PicObject.Pic
                    }
                    else if (temp.PicObject.Pic && !temp.PicObject.Pic.includes('data:image/jpeg;base64')){
                        temp.PicObject.Pic = 'data:image/jpeg;base64,' + temp.PicObject?.Pic
                    }
                    else {
                        temp.PicObject.Pic = noImage
                    }
                }
                else {
                    temp.PicObject = {}
                }


                temp.AdditionalInfo = [
                    {
                        ID:0,
                        ProfileID:0,
                        Type:"Additional Aliases:",
                        Content:"",
                        Deleted: false,
                        Default: true
                    },
                    {
                        ID:0,
                        ProfileID:0,
                        Type:"Additional Addresses:",
                        Content:"",
                        Deleted: false,
                        Default: true
                    },
                    {
                        ID:0,
                        ProfileID:0,
                        Type:"Additional Telephone Numbers:",
                        Content:"",
                        Deleted: false,
                        Default: true
                    }
                ]

                temp.PreparedBy = [
                    {
                        ID:0,
                        ProfileID:0,
                        Type:'PreparedBy',
                        Content:user.Firstname + ' ' + user.Lastname,
                        Deleted: false
                    }
                ]

                temp.ReviewedBy = [
                    {
                        ID:0,
                        ProfileID:0,
                        Type:'ReviewedBy',
                        Content:'',
                        Deleted: false
                    }
                ]

                if(temp.DeconObject){
                    for(let deconobj of temp.DeconObject){
                        deconobj.ID = 0
                        deconobj.ProfileID = 0
                    }
                }

                if(temp.AdditionalInfo){
                    for (let addInfo of temp.AdditionalInfo){
                        addInfo.ID = 0
                        addInfo.ProfileID = 0
                        addInfo.Default = true
                    }
                }

                if (temp.Files){
                    for (let file of temp.Files){
                        file.ID = 0
                        file.ProfileID = 0
                    }
                }

            }
            else
            {
                //Handle edit report
                // temp.PicObject = {
                //     ID:0,
                //     Pic: temp.PicObject ? 'data:image/jpeg;base64,' + temp.PicObject?.Pic : noImage,
                //     ProfileID:0
                // }
                if(temp.PicObject){
                    //temp.PicObject.Pic = temp.PicObject.Pic ? 'data:image/jpeg;base64,' + temp.PicObject?.Pic : noImage
                    if(temp.PicObject.Pic && temp.PicObject.Pic.includes('data:image/jpeg;base64')){
                        temp.PicObject.Pic = temp.PicObject.Pic
                    }
                    else if (temp.PicObject.Pic && !temp.PicObject.Pic.includes('data:image/jpeg;base64')){
                        temp.PicObject.Pic = 'data:image/jpeg;base64,' + temp.PicObject?.Pic
                    }
                    else {
                        temp.PicObject.Pic = noImage
                    }
                }

                if(temp.ProfileReportObject){
                    temp.ProfileReportObject.EditStatus = 1
                }

                if(temp.AdditionalInfo){
                    for(let addInfo of temp.AdditionalInfo){
                        addInfo.Default = true
                    }
                }

            }
        }
        else
        {
            await handleNewForm()
        }

        //setting rich textbox values

        if (temp?.Files) {
            setExistingFiles(temp?.Files)
        }
        if(temp.ProfileReportObject?.Comments){
            var drft = htmlToDraft(temp.ProfileReportObject.Comments)
            setEditorCommentState(EditorState.createWithContent(ContentState.createFromBlockArray(drft.contentBlocks, drft.entityMap)))
        }
        if(temp.PersonObject?.Scars){
            var drft = htmlToDraft(temp?.PersonObject?.Scars)
            setEditorScarState(EditorState.createWithContent(ContentState.createFromBlockArray(drft.contentBlocks, drft.entityMap)))
        }
        if (temp.CriminalActivityObject?.PriorCharges){
            var drft = htmlToDraft(temp?.CriminalActivityObject?.PriorCharges)
            setEditorPriorChargeState(EditorState.createWithContent(ContentState.createFromBlockArray(drft.contentBlocks, drft.entityMap)))

        }
        if (temp.CriminalActivityObject?.ArrestingAgency){
            var drft = htmlToDraft(temp?.CriminalActivityObject?.ArrestingAgency)
            setEditorArrestAgencyState(EditorState.createWithContent(ContentState.createFromBlockArray(drft.contentBlocks, drft.entityMap)))

        }
        if(temp.CriminalActivityObject?.PendingCases){
            var drft = htmlToDraft(temp?.CriminalActivityObject?.PendingCases)
            setEditorPendingCaseState(EditorState.createWithContent(ContentState.createFromBlockArray(drft.contentBlocks, drft.entityMap)))
        }
        if(temp.CustodyHistoryObject?.StateCustody){
            var drft = htmlToDraft(temp?.CustodyHistoryObject?.StateCustody)
            setEditorStateCustodyState(EditorState.createWithContent(ContentState.createFromBlockArray(drft.contentBlocks, drft.entityMap)))
        }
        if(temp.CustodyHistoryObject?.CountyCustody){
            var drft = htmlToDraft(temp?.CustodyHistoryObject?.CountyCustody)
            setEditorCountyCustodyState(EditorState.createWithContent(ContentState.createFromBlockArray(drft.contentBlocks, drft.entityMap)))
        }
        if(temp.PersonDetailsObject?.MVCStatus){
            var drft = htmlToDraft(temp.PersonDetailsObject?.MVCStatus)
            setEditorMVCState(EditorState.createWithContent(ContentState.createFromBlockArray(drft.contentBlocks, drft.entityMap)))
        }
        if(temp.PersonDetailsObject?.AssociateContacts){
            var drft = htmlToDraft(temp.PersonDetailsObject?.AssociateContacts)
            setEditorContactsState(EditorState.createWithContent(ContentState.createFromBlockArray(drft.contentBlocks, drft.entityMap)))
        }
        if(temp.PersonDetailsObject?.Triple){
            var drft = htmlToDraft(temp.PersonDetailsObject?.Triple)
            setEditorTripleState(EditorState.createWithContent(ContentState.createFromBlockArray(drft.contentBlocks, drft.entityMap)))
        }
        if(temp.PersonDetailsObject?.ALPR){
            var drft = htmlToDraft(temp.PersonDetailsObject?.ALPR)
            setEditorALPRState(EditorState.createWithContent(ContentState.createFromBlockArray(drft.contentBlocks, drft.entityMap)))
        }
        if(temp.PersonDetailsObject?.Holds){
            var drft = htmlToDraft(temp.PersonDetailsObject?.Holds)
            setEditorHoldsState(EditorState.createWithContent(ContentState.createFromBlockArray(drft.contentBlocks, drft.entityMap)))
        }
        if(temp.PersonDetailsObject?.Parole){
            var drft = htmlToDraft(temp.PersonDetailsObject?.Parole)
            setEditorParoleState(EditorState.createWithContent(ContentState.createFromBlockArray(drft.contentBlocks, drft.entityMap)))
        }
        if(temp.PersonDetailsObject?.EmploymentHistory){
            var drft = htmlToDraft(temp.PersonDetailsObject?.EmploymentHistory)
            setEditorEmpHistoryState(EditorState.createWithContent(ContentState.createFromBlockArray(drft.contentBlocks, drft.entityMap)))
        }
        if(temp.PersonDetailsObject?.DomesticViolence){
            var html = htmlToDraft(temp.PersonDetailsObject?.DomesticViolence)
            setEditorDomViolenceState(EditorState.createWithContent(ContentState.createFromBlockArray(html.contentBlocks, html.entityMap)))
        }
        if(temp.PersonDetailsObject?.JuvenileRegistry){
            var drft = htmlToDraft(temp.PersonDetailsObject?.JuvenileRegistry)
            setEditorJuvRegistryState(EditorState.createWithContent(ContentState.createFromBlockArray(drft.contentBlocks, drft.entityMap)))
        }
        if(temp.PersonDetailsObject?.SocialMedia){
            var drft = htmlToDraft(temp.PersonDetailsObject?.SocialMedia)
            setEditorSMState(EditorState.createWithContent(ContentState.createFromBlockArray(drft.contentBlocks, drft.entityMap)))
        }
        if(temp.PoliceContactLocations?.Arrests){
            var drft = htmlToDraft(temp.PoliceContactLocations.Arrests)
            setEditorArrestState(EditorState.createWithContent(ContentState.createFromBlockArray(drft.contentBlocks, drft.entityMap)))
        }
        if(temp.PoliceContactLocations?.FieldInterviews){
            var drft = htmlToDraft(temp.PoliceContactLocations.FieldInterviews)
            setEditorInterviewState(EditorState.createWithContent(ContentState.createFromBlockArray(drft.contentBlocks, drft.entityMap)))
        }
        if(temp.PoliceContactLocations?.Other){
            var drft = htmlToDraft(temp.PoliceContactLocations.Other)
            setEditorPoliceState(EditorState.createWithContent(ContentState.createFromBlockArray(drft.contentBlocks, drft.entityMap)))
        }
        setReport(temp)

    }

    const handleDropdowns = async ()=>{
        setStateList([])
        var state = await getDropDownQuery('State')
        if(state && state?.length > 0){
            setStateList(state)
        }
        var city = await getDropDownCity(GetDefaultStateForSite(SiteName))
        let tempList = city?.filter(x=> x.State === GetDefaultStateForSite(SiteName))
        const ids = tempList.map(({ City }) => City);
        const filtered = tempList.filter(({ City }, index) =>
            !ids.includes(City, index + 1));
        setCityList(filtered)

        createHeightDropDown()

    }

    const createHeightDropDown = () => {
        let temp = heightDD
        for (var i = 20; i <= 108; i++ ){
            let h = "" + Math.floor(i/12) + "' " + i%12 + '"'
            temp.push({value:h, key:h})
        }
        setHeightDD(temp)
    }

    const addPreparedby = ()=>{
        let temp = {...report}
        temp.PreparedBy?.push({ID:0, ProfileID:0, Type:'PreparedBy'})
        setReport(temp)
    }

    const removePreparedby = (idx: any) =>{
        let temp = {...report}
        let r = temp.PreparedBy ? [...temp.PreparedBy] : []
        let f = r.filter(res => (res.Deleted != true))
        if(f.length > 0){
            r[idx].Deleted = true
            temp.PreparedBy = r
            setReport(temp)
        }
    }

    const addReviewedBy = ()=>{
        let temp = {...report}
        temp.ReviewedBy?.push({ID:0, ProfileID: 0, Type: 'ReviewedBy'})
        setReport(temp)
    }

    const removeReviewedBy = (idx : any)=>{
        let temp = {...report}
        let r = temp.ReviewedBy ? [...temp.ReviewedBy] : []
        let f = r.filter(res => (res.Deleted != true))
        if(f.length > 0){
            r[idx].Deleted = true
            temp.ReviewedBy = r
            setReport(temp)
        }
    }

    const handleAdditionalFiles = (e: any) => {
        let files = e.files
        for (var i = 0; i < files.length; i++) {
            handleChangeFile(files[i])
        }
    }

    const handleChangeFile = (file: any) => {
        let fileData = new FileReader();
        fileData.onloadend = (e) => handleFile(e, file);
        fileData.readAsDataURL(file);
    }

    const handleFile = (e : any, file: any) => {
        let temp = {...report}

        let f = addedFiles ? [...addedFiles] : [] //Grab all newly added files
        let ex = existingFiles ? [...existingFiles] : [] //Grab all existingFiles (From Edit)
        let fIndex = f.findIndex((fil: any) => fil.FileName === file.name) //if file.name does not exist in list returns -1
        let exIndex = ex.findIndex((fil: any) => fil.FileName === file.name) //if file.name does not exist in list returns -1
        if (fIndex === -1 && exIndex === -1) //if is not already in list
        {
            f.push({
                Content: e.target.result.split('base64,')[1],
                FileName: file.name,
                Encoding: file.type,
                Deleted: false
            })
        }
        else {
            let name = file.name.split('.') //grabs NAME part of file temp.jpg -> [temp, jpg]
            let x = f.filter((res) => res.FileName?.includes(name[0]))?.length
            let y = ex.filter((res) => res.FileName?.includes(name[0]))?.length
            let fileName = name[0] + '(' + (x + y) + ')' //y > x ? name[0] + '(' + y + ')' : name[0] + '(' + x + ')'
            f.push({
                Content: e.target.result.split('base64,')[1],
                FileName: fileName + (name?.length > 1 ? '.' + name[1] : ''),
                Encoding: file.type,
                Deleted: false
            })
        }
        setAddedFiles(f)
    }

    const downloadFile = async (file : any, index : any) => {
        let temp = []
        if (file.Content != null) {
            await base64_to_blob(file.Content, file.Encoding).then(r => {
                var blobUrl = URL.createObjectURL(r);
                window.open(blobUrl, '_blank')
            })
        }
        else if (file.ID) {
            temp = [...existingFiles]
            await GetProfileReportFiles(temp[index])
        }
    }

    const removeFile = (file : any, index : any) => {
        let tempFiles = []
        if (!file.ID) {
            tempFiles = [...addedFiles]
            tempFiles.splice(index, 1)
            setAddedFiles(tempFiles)
        }
        else {
            tempFiles = [...existingFiles]
            for (let item of tempFiles){
                if(item.ID === file.ID){
                    item.Deleted = true
                }
            }
            //tempFiles[index].Deleted = true
            setExistingFiles(tempFiles)
        }

    }

    const handlePersonImage = (input : any)=>{
        let img = input.files[0]
        let reader = new FileReader
        reader.onloadend = (e) => handleImage(e, img);
        reader.readAsDataURL(img);

    }
    const handleImage = (e: any , file: any)=>{
        let imgsrc = e.target.result
        let temp = {...report}
        if(temp.PicObject){
            temp.PicObject.Pic = imgsrc
        }
        //temp.PicObject = {Pic : imgsrc}
        setReport(temp)
    }

    const handleDialogClose = () => {
        setOpenDialog(false)
    }

    const addDeconfliction = () => {
        let temp ={...report}

        if(temp.DeconObject == null)
            temp.DeconObject = []

        temp?.DeconObject?.push({ID: 0, ProfileID:0, CaseAgent:"", CaseAgency:"", Phone:"", Email:"", CaseNum:"", Deleted: false});
        setReport(temp);
    }

    const removeDeconfliction = (idx : any) => {
        let temp = {...report}
        let r = temp.DeconObject ? [...temp.DeconObject] : []
        let f = r.filter(res => (res.Deleted != true))
        if(f.length > 0){
            r[idx].Deleted = true
            temp.DeconObject = r
            setReport(temp)
        }

    }

    const handleSave = async () =>{
        let dupReportNum
        if(!report.ProfileReportObject?.EditStatus || report.ProfileReportObject.EditStatus !==1 && report.ProfileReportObject.ID === 0){
            dupReportNum = await checkDupReportNum()
        }
        else{
            dupReportNum = false
        }

        if(handleValidation() && !dupReportNum){
            let temp = {...report}
            let added = [...addedFiles]
            let existing = [...existingFiles]
            temp.Files = existing.concat(added)

            handleReportSubmit(temp , true)
        }


    }

    const handleValidation = () => {
        let res = true
        let temp = {...report}

        if (temp.PersonObject){
            if(!temp.PersonObject.FirstName || temp.PersonObject.FirstName.trim().length === 0)
                res = false
            if(!temp.PersonObject.LastName || temp.PersonObject.LastName.trim().length === 0)
                res = false
            if(!temp.PersonObject.DOB)
                res = false
            // if(!temp.PersonObject.SBI || temp.PersonObject.SBI.trim().length === 0)
            //     res = false
            // if(!temp.PersonObject.FBI || temp.PersonObject.FBI.trim().length === 0)
            //     res = false
        }
        if(temp.ProfileReportObject){
            // if(!temp.ProfileReportObject.ReportType)
            //     res = false
            if(!temp.ProfileReportObject.ReportNum)
                res = false
        }

        if(!res){
            setAlertText('Please complete First Name, Last Name , DOB , and Report # before saving')
            setAlertOpen(true)
            setAlertType('error')
        }

        return(res)

    }


    const addAdditionalInfo = ()=>{
        let temp = {...report}
        if(temp?.AdditionalInfo == null)
        {
            temp.AdditionalInfo = []
        }
        temp.AdditionalInfo.push({ID:0, ProfileID:0, Type:"Additional Information:", Content:"", Deleted: false, Default: true})
        setReport(temp)
    }

    const removeAdditionalOption = (idx : any)=>{
        let temp = {...report}
        let a = temp.AdditionalInfo ? [...temp.AdditionalInfo] : []
        let f = a.filter(res => (res.Deleted != true))
        if(f.length > 0){
            a[idx].Deleted = true
            temp.AdditionalInfo = a
            setReport(temp)
        }
    }

    const showPrintPreview = async() =>{
        let temp = {...report}
        let added = [...addedFiles]
        let existing = [...existingFiles]
        temp.Files = existing.concat(added)

        //remove deleted items from preview
        if(temp.DeconObject){
            let tempDecon=[]
            for (let i = 0 ; i < temp.DeconObject?.length; i++){
                if(!temp.DeconObject[i].Deleted){
                    tempDecon.push(temp.DeconObject[i])
                }
            }
            temp.DeconObject = tempDecon
        }
        if(temp.AdditionalInfo){
            let tempAddInfo=[]
            for(let i = 0; i< temp.AdditionalInfo.length; i++){
                if(!temp.AdditionalInfo[i].Deleted){
                    tempAddInfo.push(temp.AdditionalInfo[i])
                }
            }
            temp.AdditionalInfo = tempAddInfo
        }
        let PreviewURL = await GetProfileReportPreview(temp)
        if (PreviewURL){
            setReportUrl(PreviewURL);
            setOpenDialog(true);
        }
    }

    const handleAutoSaveToggle = async () => {
        if(!autoSaveToggle && handleValidation()){
            let temp ={...report}
            let dupReportNum = await checkDupReportNum()
            if(!dupReportNum) {
                setAlertText('Auto Save Enabled')
                setAlertType('success')
                setAlertOpen(true)
                //handleReportSubmit(temp, false)
                if(!temp.ProfileReportObject || !temp.ProfileReportObject.ID || temp.ProfileReportObject?.ID === 0){
                    let saveRequest = await SetProfileReport(temp)
                    if(saveRequest.PicObject){
                        //temp.PicObject.Pic = temp.PicObject.Pic ? 'data:image/jpeg;base64,' + temp.PicObject?.Pic : noImage
                        if(saveRequest.PicObject.Pic && saveRequest.PicObject.Pic.includes('data:image/jpeg;base64')){
                            saveRequest.PicObject.Pic = saveRequest.PicObject.Pic
                        }
                        else if (saveRequest.PicObject.Pic && !saveRequest.PicObject.Pic.includes('data:image/jpeg;base64')){
                            saveRequest.PicObject.Pic = 'data:image/jpeg;base64,' + saveRequest.PicObject?.Pic
                        }
                        else {
                            saveRequest.PicObject.Pic = noImage
                        }
                    }
                    if(!saveRequest.ProfileReportObject.EditStatus){
                        saveRequest.ProfileReportObject.EditStatus=1
                    }
                    // if (res.AdditionalInfo && res.addAdditionalInfo.length > 0){
                    //     for (let i = 0; i < res.AdditionalInfo.length; i++){
                    //         if(res.AdditionalInfo[i].default === false){
                    //             res.AdditionalInfo[i].default = true
                    //         }
                    //     }
                    // }

                    setReport(saveRequest)

                }
                setAutoSaveToggle(true)
            }
        }
        else
        {
            setAutoSaveToggle(false)
        }
    }

    const checkDupReportNum = async () => {
        if (handleValidation()){
            let temp = {...report}
            if (temp.ProfileReportObject?.ReportNum){
                let res = false
                if(temp.ProfileReportObject.EditStatus !== 1){
                    res = await CheckDuplicateReportNum(0, temp.ProfileReportObject.ReportNum)
                }
                if(res){
                    setAlertText('Duplicate Report Number')
                    setAlertOpen(true)
                    setAlertType('error')
                }
                return res
            }
        }

    }



    const stringAvatar = (name: string) => {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        }
    }

    const stringToColor = (string: string) => {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */
        return color;
    }

    return (
        <div>
            <div className={"grid grid-cols-12 gap-x-1 px-2"} >
                <div className={"col-span-9"}>
                    <div className={"grid grid-cols-12 gap-x-1 px-2"}>
                        <div className={ "2xl:col-span-2" }>
                            <ButtonGroup className="ml-4 pl-4 mb-3" size="large" color="inherit" >
                                <Button variant="contained" onClick={showPrintPreview} endIcon={<PrintIcon />}>Preview</Button>
                            </ButtonGroup>
                        </div>
                        <div className={"col-span-2 2xl:col-start-3 col-start-5"}>
                            <FormControlLabel
                                control={
                                    <Switch checked={autoSaveToggle} onChange={handleAutoSaveToggle} name="aSave" color={'error'} />
                                }
                                label="Auto Save"
                            />
                        </div>
                    </div>
                </div>
                {activeUsers && activeUsers.length > 0 ?
                    <div className={"col-span-2"}>
                                <span className={"flex space-x-10"}>
                                    <span className={"mr-2 font-semibold "}>Active users:</span>
                                    <AvatarGroup max={4} spacing={85}>
                                        {activeUsers.map(name => (
                                            <Tooltip title={name} placement="top">
                                                <Avatar alt={name} {...stringAvatar(name)} />
                                            </Tooltip>
                                        ))}
                                    </AvatarGroup>
                                </span>
                    </div>
                    : <></>}
            </div>
            <Card sx={{ maxWidth: 1600, m: 'auto', mb: 1, border: !report.ProfileReportObject?.ID || report?.ProfileReportObject?.ID === 0 ? '3px solid red' : '3px solid green' }} >
                <CardContent>
                    <div className = "border-r-2 border-l-2 border-t-2 border-gray-700 bg-blue-300 h-11 font-bold" >
                        <FormControl size = "small" sx={{minWidth:"100%"}} style={{textAlign: 'center', height:8, verticalAlign:"text-top", justifyContent:"top", fontWeight:"bold"}}>
                            <Select
                                sx={{fontWeight:"bold"}}
                                className = "font-bold"
                                labelId="demo-simple-select-label"
                                id="profileReport_reportType"
                                // value={report?.ProfileReportObject?.ReportType}
                                value= {report.ProfileReportObject?.ReportType ? report.ProfileReportObject?.ReportType : ''}
                                label="Report Type"
                                onChange={(e:any) => handleValueChange("ProfileReport", "ReportType", e.target.value )}
                            >
                                <MenuItem value={"Person of Strategic Interest Report"}>Person of Strategic Interest Report</MenuItem>
                                <MenuItem value={"Victim Profile Report"}>Victim Profile Report</MenuItem>
                                <MenuItem value={"Suspect Profile Report"}>Suspect Profile Report</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <div className = "flex border-2 border-gray-700">
                            <div className = "flex border-r-2 pr-4 border-gray-700">
                                <div className = "mt-3 ml-10 inline max-h-56" style={{ maxWidth: 200 }}>
                                    <img src={report?.PicObject?.Pic ? report?.PicObject?.Pic : noImage} style={{height:250, width:200}} />
                                </div>
                                <span className="inline mt-1">
                                        <input
                                            id="icon-button-photo"
                                            //style={{display:"none"}}
                                            hidden
                                            className = "input"
                                            //onChange={e => {handleValueChange("ProfileReport", "Pic", e.target )}}
                                            onChange = {e => {handlePersonImage(e.target)}}
                                            type="file"
                                            accept = ".jpeg, .png, .jpg"
                                            value=''
                                        />
                                        <label htmlFor="icon-button-photo">
                                            <IconButton color="primary" component="span">
                                                <AttachFileIcon/>
                                            </IconButton>
                                        </label>
                                    </span>
                            </div>
                            <div className = "grid grid-cols-2 flex-1" style = {{clear:"both"}}>
                                <div className="border-r-2 border-b-2 border-gray-700 ">
                                    <h1 className="text-blue-600 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Name:</h1>
                                    <div style={{ "display": "flex", "justifyContent": "center" }}>
                                        <TextField error={!report?.PersonObject?.FirstName || report?.PersonObject?.FirstName == undefined ? true : false} variant="standard" margin='none' size={"medium"} label="Firstname" inputProps={{ style: {textAlign: 'center'} }} value={report?.PersonObject?.FirstName ? report.PersonObject.FirstName : ''} onChange = { (e) => handleValueChange("PersonObject", "FirstName", e.target.value )}/>
                                        <TextField  variant="standard" margin='none' size={"medium"} label="MI" inputProps={{ style: {textAlign: 'center'} }} value={report?.PersonObject?.MiddleName ?report?.PersonObject?.MiddleName : ''} onChange = { (e) => handleValueChange("PersonObject", "MiddleName", e.target.value )} />
                                        <TextField  error={!report?.PersonObject?.LastName || report?.PersonObject?.LastName == undefined ? true : false} variant="standard" margin='none' size={"medium"} label="Lastname" inputProps={{ style: {textAlign: 'center'} }} value={report?.PersonObject?.LastName ? report?.PersonObject?.LastName : ''} onChange = { (e) => handleValueChange("PersonObject", "LastName", e.target.value )}/>
                                    </div>
                                </div>
                                <div className="border-b-2 border-gray-700">
                                    <h1 className="text-blue-600 font-bold text-center">Last Known Address:</h1>
                                    <div style={{ "display": "flex-auto", "justifyContent": "center" , width:"100%" }}>
                                        <div className="grid grid-cols-12">
                                            <div className="col-span-5">
                                                <TextField fullWidth variant="standard" margin='none'  style={{width:"100%"}} label="Address" inputProps={{ style: {textAlign: 'center'} }} value={report?.PersonObject?.Address ? report?.PersonObject?.Address : ''} onChange = { (e) => handleValueChange("PersonObject", "Address", e.target.value )}/>
                                            </div>
                                            <div className="col-span-5">
                                                <FormControl fullWidth variant="standard" sx={{textAlign:"center"}} >
                                                    <Autocomplete
                                                        id="free-solo-demo"
                                                        style={{width:"100%"}}
                                                        freeSolo
                                                        autoComplete = {true}
                                                        //filterOptions = {autoFilterOptions}
                                                        sx={{textAlign:"center", justifyContent:"center"}}
                                                        options={cityList.map((option: any) => option.City)}
                                                        value={report?.PersonObject?.City ? report?.PersonObject?.City : ''}
                                                        onChange = { (e, value) => handleValueChange("PersonObject", "City", value )}
                                                        renderInput={(params) => <TextField style={{width:"100%"}} variant={"standard"} {...params} onChange = { (e) => handleValueChange("PersonObject", "City", e.target.value )} label="City"/>}
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className="col-span-2">
                                                <FormControl fullWidth variant="standard" sx={{textAlign:"center"}} >
                                                    <Autocomplete
                                                        id="free-solo-demo"
                                                        style={{width:"100%"}}
                                                        freeSolo
                                                        autoComplete = {true}
                                                        sx={{textAlign:"center", justifyContent:"center"}}
                                                        options={stateList.map((option: any) => option.Key)}
                                                        value={report?.PersonObject?.State ? report.PersonObject.State : ''}
                                                        onChange = { (e, value) => handleValueChange("PersonObject", "State", value )}
                                                        renderInput={(params) => <TextField style={{width:"100%"}} variant={"standard"} {...params} onChange = { (e) => handleValueChange("PersonObject", "State", e.target.value )} label="State"/>}
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="border-r-2 border-b-2 border-gray-700 ">
                                    <div className = "flex">
                                        <div className = "border-r-2 border-gray-700 ml-5 pr-5">
                                            <h1 className="text-blue-600 font-bold" style={{ "display": "flex", "justifyContent": "center"}} >DOB:</h1>
                                            <div className="">
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker
                                                        label="DOB"
                                                        value={report?.PersonObject?.DOB ? report?.PersonObject?.DOB : ' ' }
                                                        onChange={(e) => {handleValueChange("PersonObject", "DOB", e)}}
                                                        renderInput={(params) => <TextField margin='none' size={"small"} variant={"standard"} type="date" label="DOB"  InputLabelProps={{ shrink: true }} {...params} />}
                                                    />
                                                </LocalizationProvider>
                                            </div>
                                        </div>
                                        <div className = "border-r-2 border-gray-700 ml-5 pr-5">
                                            <h1 className="text-blue-600 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>SBI:</h1>
                                            {/* <TextField error={!report?.PersonObject?.SBI || report?.PersonObject?.SBI == undefined ? true : false} variant="standard" margin='none' size={"small"} label={GetStringNameForSite(SiteName,"SBI #")} inputProps={{ style: {textAlign: 'center'}, maxLength: 12 }} value={report?.PersonObject?.SBI ? report?.PersonObject?.SBI  : ''} onChange = { (e) => handleValueChange("PersonObject", "SBI", e.target.value )} /> */}
                                            <TextField variant="standard" margin='none' size={"small"} label={GetStringNameForSite(SiteName,"SBI #")} inputProps={{ style: {textAlign: 'center'}, maxLength: 12 }} value={report?.PersonObject?.SBI ? report?.PersonObject?.SBI  : ''} onChange = { (e) => handleValueChange("PersonObject", "SBI", e.target.value )} />
                                        </div>
                                        <div className = "ml-5">
                                            <h1 className="text-blue-600 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>FBI:</h1>
                                            {/* <TextField error={!report?.PersonObject?.FBI || report?.PersonObject?.FBI == undefined ? true : false} variant="standard" margin='none' size={"small"} label="FBI" inputProps={{ style: {textAlign: 'center'}, maxLength: 12 }} value={report?.PersonObject?.FBI ?report?.PersonObject?.FBI : ''} onChange = { (e) => handleValueChange("PersonObject", "FBI", e.target.value )}/> */}
                                            <TextField variant="standard" margin='none' size={"small"} label="FBI" inputProps={{ style: {textAlign: 'center'}, maxLength: 12 }} value={report?.PersonObject?.FBI ?report?.PersonObject?.FBI : ''} onChange = { (e) => handleValueChange("PersonObject", "FBI", e.target.value )}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="border-b-2 border-gray-700 ">
                                    <h1 className="text-blue-600 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Alias/Aka/Street Names:</h1>
                                    <div><TextField fullWidth variant="standard" margin='none' size={"small"} label="Alias/AKA/Street Names" inputProps={{ style: {textAlign: 'center'} }} value={report?.PersonObject?.Alias ? report?.PersonObject?.Alias : ''} onChange = { (e) => handleValueChange("PersonObject", "Alias", e.target.value )} /></div>
                                </div>
                                <div className="border-b-2 border-r-2 border-gray-700  ">
                                    <h1 className="text-blue-600 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Gang Indicia:</h1>
                                    <div><TextField fullWidth variant="standard" margin='none' size={"small"} label="Gang Indicia" inputProps={{ style: {textAlign: 'center'} }} value={report?.PersonObject?.Gang ? report?.PersonObject?.Gang : ''} onChange = { (e) => handleValueChange("PersonObject", "Gang", e.target.value )}/></div>
                                </div>
                                <div className="border-b-2 border-gray-700 ">
                                    <div className = "flex">
                                        <div className = "border-r-2 border-gray-700" style={{width:312}}>
                                            <h1 className="text-blue-600 font-bold" style={{ "display": "flex", "justifyContent": "center"}} >Tel#:</h1>
                                            <div className="css-1a1fmpi-MuiInputBase-root-MuiInput-root"><PhoneInput className="phone-input" withCountryCallingCode placeholder='Tel#' country="US" maxLength={14} value={report?.PersonObject?.Phone ? report?.PersonObject?.Phone : ''} onChange = { (e) => handleValueChange("PersonObject", "Phone", e )} /></div>
                                            {/* <div><InputMask mask="(999)999-9999" value={report?.personObject?.phone} maskChar=" " >{() =><TextField fullWidth variant="standard" margin='none' size={"small"} label="Tel#" inputProps={{ style: {textAlign: 'center'}, type: 'tel', maxLength: 10 }} value={report?.personObject?.phone} onChange = { (e) => handleValueChange("personObject", "phone", e.target.value )}/>}</InputMask></div> */}
                                        </div>
                                        <div style={{width:310}}>
                                            <h1 className="text-blue-600 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>DNA on File:</h1>
                                            <div><TextField fullWidth variant="standard" margin='none' size={"small"} label="DNA on File" inputProps={{ style: {textAlign: 'center'} }} value={report?.PersonObject?.DNA ? report?.PersonObject?.DNA : ''} onChange = { (e) => handleValueChange("PersonObject", "DNA", e.target.value )}/></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="border-r-2 border-gray-700  ">
                                    <div className = "flex">
                                        <div className = "border-r-2 border-gray-700 ml-2 pr-2">
                                            <h1 className="text-blue-600 font-bold" style={{ "display": "flex", "justifyContent": "center"}} >Height:</h1>
                                            <FormControl variant="standard" sx={{minWidth: 140, textAlign:"center"}} size="small">
                                                <Autocomplete
                                                    id="free-solo-demo"
                                                    autoComplete = {true}
                                                    size = "small"
                                                    sx={{width:130, height:30, textAlign:"center", justifyContent:"center"}}
                                                    options={heightDD.map((option) => option.key)}
                                                    //filterOptions={heightFilterOptions}
                                                    value = {report?.PersonObject?.Height ? report?.PersonObject?.Height : ''}
                                                    onChange = { (e, value) => handleValueChange("PersonObject", "Height", value )}
                                                    renderInput={(params) => <TextField variant={"standard"} {...params} onChange = { (e) => handleValueChange("PersonObject", "Height", e.target.value )} label="Height"/>}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className = "border-r-2 border-gray-700 ml-2 pr-2">
                                            <h1 className="text-blue-600 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Weight:</h1>
                                            <TextField variant="standard" type="number" margin='none' size={"small"} label="Weight" inputProps={{ style: {textAlign: 'center'} }} value={report?.PersonObject?.Weight ? report?.PersonObject?.Weight : ''} onChange = { (e) => handleValueChange("PersonObject", "Weight", e.target.value )} />
                                        </div>
                                        <div className = "border-r-2 border-gray-700 ml-2 pr-2">
                                            <h1 className="text-blue-600 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Hair:</h1>
                                            <FormControl size="small" sx={{minWidth: 140, textAlign:"center"}}>
                                                <Autocomplete
                                                    id="free-solo-demo"
                                                    freeSolo
                                                    autoComplete = {true}
                                                    size = "small"
                                                    sx={{width:130, height:30, textAlign:"center", justifyContent:"center"}}
                                                    options={hairDD.map((option) => option)}
                                                    value = {report?.PersonObject?.Hair ? report?.PersonObject?.Hair : ''}
                                                    onChange = { (e, value) => handleValueChange("PersonObject", "Hair", value )}
                                                    renderInput={(params) => <TextField variant={"standard"} {...params} onChange = { (e) => handleValueChange("PersonObject", "Hair", e.target.value )} label="Hair"/>}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className = "ml-2">
                                            <h1 className="text-blue-600 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Eyes:</h1>
                                            <FormControl size="small" sx={{minWidth: 140, textAlign:"center"}}>
                                                <Autocomplete
                                                    id="free-solo-demo"
                                                    freeSolo
                                                    autoComplete = {true}
                                                    size = "small"
                                                    sx={{width:130, height:30, textAlign:"center", justifyContent:"center"}}
                                                    options={eyesDD.map((option) => option)}
                                                    value = {report?.PersonObject?.Eyes ? report.PersonObject.Eyes  : ''}
                                                    onChange = { (e, value) => handleValueChange("PersonObject", "Eyes", value )}
                                                    renderInput={(params) => <TextField variant={"standard"} {...params} onChange = { (e) => handleValueChange("PersonObject", "Eyes", e.target.value )} label="Eyes"/>}
                                                />
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                                <div className="border-gray-700 mr-3 ">
                                    <div className = "flex">
                                        <div className = "border-r-2 border-gray-700">
                                            <h1 className="text-blue-600 font-bold" style={{ "display": "flex", "justifyContent": "center"}} >Race:</h1>
                                            <FormControl size="small">
                                                <Autocomplete
                                                    id="free-solo-demo"
                                                    freeSolo
                                                    autoComplete = {true}
                                                    size = "small"
                                                    sx={{width:310, height:30, textAlign:"center", justifyContent:"center"}}
                                                    options={raceDD.map((option) => option)}
                                                    value = {report?.PersonObject?.Race ? report.PersonObject.Race : ''}
                                                    onChange = { (e, value) => handleValueChange("PersonObject", "Race", value )}
                                                    renderInput={(params) => <TextField variant={"standard"} {...params} onChange = { (e) => handleValueChange("PersonObject", "Race", e.target.value )} label="Race"/>}
                                                />
                                            </FormControl>
                                        </div>
                                        <div>
                                            <h1 className="text-blue-600 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Gender:</h1>
                                            <FormControl size="small">
                                                <Autocomplete
                                                    id="free-solo-demo"
                                                    freeSolo
                                                    size = "small"
                                                    sx={{width:310, textAlign:"center", justifyContent:"center"}}
                                                    options={genderDD.map((option) => option)}
                                                    value = {report?.PersonObject?.Gender ? report?.PersonObject?.Gender : ''}
                                                    onChange = { (e, value) => handleValueChange("PersonObject", "Gender", value )}
                                                    renderInput={(params) => <TextField variant={"standard"} {...params} onChange = { (e) => handleValueChange("PersonObject", "Gender", e.target.value )} label="Gender"/>}
                                                />
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="border-2 border-gray-700 mb-5">
                                <span className = "ml-2">
                                    <label className = "text-blue-600 font-bold">Scars/Marks/Tattoos:</label>
                                    <div className = "ml-2">
                                        {/* <RichTextEditorComponent enableXhtml={true} pasteCleanupSettings={pasteCleanupSettings} style={{minHeight:'150'}} placeholder="Scars/Marks/Tattoos" enterKey="P"  value = {handleRichTextNull(report?.PersonObject?.Scars)} change={(e) => handleValueChange("PersonObject", "Scars", e.value )}>
                                            <Inject services={[HtmlEditor, QuickToolbar, PasteCleanup]} />
                                        </RichTextEditorComponent>                                         */}
                                        {/*@ts-ignore*/}
                                        <Editor
                                            editorState={editorScarState}
                                            onChange={(event) => { handleValueChange('PersonObject', 'Scars', draftToHtml(event))}}
                                            onEditorStateChange={(e) => {setEditorScarState(e) }}
                                            toolbarHidden
                                        />
                                    </div>
                                </span>
                        </div>
                    </div>

                    <div>
                        <div className="border-2 border-gray-700 mb-5">
                            <div className = "border-b-2 border-gray-700 bg-blue-300 h-6 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Comments</div>
                            {/* <TextareaAutosize style={{width:"100%"}} minRows={6} maxRows={6} placeholder="Comments" value = {report?.comments} onChange={(e:any) => setreport({...report, comments: e.target.value})}/> */}
                            {/* <RichTextEditorComponent enableXhtml={true} pasteCleanupSettings={pasteCleanupSettings} style={{minHeight:'150'}} toolbarSettings={toolbarSettings} enterKey="P" placeholder="Comments" value = {handleRichTextNull(report?.Comments)} change={(e) => handleValueChange("ProfileReport", "Comments", e.value )}>
                                    <Inject services={[Toolbar, HtmlEditor, QuickToolbar, PasteCleanup]} />
                                </RichTextEditorComponent> */}
                            {/*@ts-ignore*/}
                            <Editor
                                editorState={editorCommentState}
                                onChange={(event) => { handleValueChange('ProfileReport','Comments', draftToHtml(event))}}
                                onEditorStateChange={(e) => { setEditorCommentState(e) }}
                                toolbar={{
                                    options: [ 'inline' ,'fontFamily', 'colorPicker', 'textAlign'],
                                    inline: { options: ['bold', 'italic','underline'] },
                                    colorPicker:{colors: ['rgb(255,0,0)']}
                                }}
                            />
                        </div>
                    </div>

                    <div>
                        <div className="border-2 border-gray-700 mb-5">
                            <div className = "border-b-2 border-gray-700 bg-blue-300 h-6 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Deconfliction</div>
                            <span>
                                    <Checkbox
                                        value={report?.ProfileReportObject?.DeconChecked && report.ProfileReportObject.DeconChecked}
                                        onChange={() => handleValueChange("ProfileReport", "DeconChecked", !report?.ProfileReportObject?.DeconChecked)}
                                        icon={<CancelPresentationIcon/>}
                                        checkedIcon={<CancelPresentationIcon/>}
                                        defaultChecked={report.ProfileReportObject?.DeconChecked ? report.ProfileReportObject?.DeconChecked : false }
                                    />
                                    <label className="font-bold font-large">{report?.ProfileReportObject?.DeconChecked ? ("Match") : ("No Match")} / CE#</label>
                                    <span className ="ml-2"><TextField variant="standard" margin='none' size={"small"} value = {report.ProfileReportObject?.CENum} onChange={(e:any) => handleValueChange("ProfileReport", "CENum", e.target.value)}/></span>
                                {report?.ProfileReportObject?.DeconChecked ? <Button onClick = {(e) => {addDeconfliction()}}><AddCircleOutlineIcon/></Button>: <></>}

                                {report && report?.ProfileReportObject?.DeconChecked ? report?.DeconObject?.map((obj:deconObject, indx:number) =>
                                    !obj.Deleted ?
                                        <div className = {report?.DeconObject ? indx == report?.DeconObject?.length - 1 ? "ml-2" : "ml-2 mb-4" : "" }>
                                            <div><label className = "font-bold">Case Agent: </label><TextField variant="standard" margin='none' size={"small"} value = {obj.CaseAgent} onChange={(e) => handleValueChange("DeconObject", "CaseAgent", e.target.value, indx )}/></div>
                                            <div><label className = "font-bold">Case Agency: </label>
                                                <FormControl sx={{minWidth: 170, textAlign:"center"}}>
                                                    <Autocomplete
                                                        id="free-solo-demo"
                                                        freeSolo
                                                        autoComplete = {true}
                                                        size = "small"
                                                        value = {obj.CaseAgency ? obj.CaseAgency : ''}
                                                        sx={{textAlign:"center", justifyContent:"center"}}
                                                        options={DDAAgency.map((option) => option.Value)}
                                                        onChange = { (e, value) => handleValueChange("DeconObject", "CaseAgency", value, indx )}
                                                        renderInput={(params) => <TextField variant={"standard"} {...params} onChange = { (e) => handleValueChange("DeconObject", "CaseAgency", e.target.value, indx )}/>}
                                                    />
                                                </FormControl>
                                            </div>
                                            <div><label className = "font-bold">Contact Phone Number: </label><TextField variant="standard" margin='none' size={"small"} value = {obj?.Phone ? obj?.Phone : ''} onChange={(e) => handleValueChange("DeconObject", "Phone", e.target.value, indx )}/>
                                                <span className="ml-20 inline">
                                                    <IconButton aria-label="delete" size="small" onClick={() => removeDeconfliction(indx)} color={"error"}>
                                                        <DeleteIcon/>
                                                    </IconButton>
                                                </span>
                                            </div>
                                            <div><label className = "font-bold">Contact Email Address: </label><TextField variant="standard" margin='none' size={"small"} value = {obj.Email} onChange={(e) => handleValueChange("DeconObject", "Email", e.target.value, indx )}/></div>
                                            <div><label className = "font-bold">Case Number: </label><TextField variant="standard" margin='none' size={"small"} value = {obj.CaseNum} onChange={(e) => handleValueChange("DeconObject", "CaseNum", e.target.value, indx )}/></div>
                                        </div> : <></>
                                ): <></> }
                                </span>
                        </div>
                    </div>

                    <div>
                        <div className="border-2 border-gray-700 mb-5">
                            <div className = "border-b-2 border-gray-700 bg-blue-300 h-6 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Criminal Activity - <span style={{"color":"red"}}>Violent Crimes in Red</span></div>
                            <span className = "border-b-2 border-gray-700" style={{ "display": "flex", "justifyContent": "center" }}>
                                    <span className = "mr-20"><label className = "font-bold">Total Arrests: </label><TextField variant="standard" margin='none' size={"small"} value = {report?.CriminalActivityObject?.TotalArrest} onChange={(e) => handleValueChange("CriminalActivityObject", "TotalArrest", e.target.value )} /></span>
                                    <span><label className = "font-bold">Felony Convictions: </label><TextField variant="standard" margin='none' size={"small"} value = {report?.CriminalActivityObject?.Convictions} onChange={(e) => handleValueChange("CriminalActivityObject", "Convictions", e.target.value )}/></span>
                                </span>
                            <span className = "ml-2">
                                    <label className = "font-bold">Prior Charges Include: </label>
                                    <span className = "ml-2">
                                        {/* <TextareaAutosize style={{width:"99%"}} minRows={1} maxRows={4} placeholder="Prior Charges Include:" value = {report?.criminalActivityObject?.priorCharges} onChange={(e) => handleValueChange("criminalActivityObject", "priorCharges", e.target.value )}/> */}
                                        {/* <RichTextEditorComponent enableXhtml={true} pasteCleanupSettings={pasteCleanupSettings} enterKey="P" toolbarSettings={toolbarSettings} style={{minHeight:'150'}}  value = {handleRichTextNull(report?.CriminalActivityObject?.PriorCharges)} change={(e) => handleValueChange("CriminalActivityObject", "PriorCharges", e.value )}>
                                            <Inject services={[Toolbar, Link, Image, HtmlEditor, QuickToolbar, PasteCleanup]} />
                                        </RichTextEditorComponent> */}
                                        {/*@ts-ignore*/}
                                        <Editor
                                            editorState={editorPriorChargeState}
                                            onChange={(event) => { handleValueChange('CriminalActivityObject','PriorCharges', draftToHtml(event)) }}
                                            onEditorStateChange={(e) => { setEditorPriorChargeState(e) }}
                                            toolbar={{
                                                options: [ 'inline' ,'fontFamily', 'colorPicker', 'textAlign'],
                                                inline: { options: ['bold', 'italic','underline'] },
                                                colorPicker:{colors: ['rgb(255,0,0)','rgb(0,0,0)']}
                                            }}
                                        />
                                    </span>
                                </span>
                            <span className = "ml-2">
                                    <label className = "font-bold">Arresting Agencies: </label>
                                    <span className = "ml-2">
                                        {/* <TextareaAutosize style={{width:"99%"}} minRows={1} maxRows={4} placeholder="Arresting Agencies:" value = {report?.criminalActivityObject?.arrestingAgency} onChange={(e) => handleValueChange("criminalActivityObject", "arrestingAgency", e.target.value )}/> */}
                                        {/* <RichTextEditorComponent enableXhtml={true} pasteCleanupSettings={pasteCleanupSettings} enterKey="P" toolbarSettings={toolbarSettings} style={{minHeight:'150'}}  value = {handleRichTextNull(report?.CriminalActivityObject?.ArrestingAgency)} change={(e) => handleValueChange("CriminalActivityObject", "ArrestingAgency", e.value )}>
                                            <Inject services={[Toolbar, Link, Image, HtmlEditor, QuickToolbar, PasteCleanup]} />
                                        </RichTextEditorComponent> */}
                                        {/*@ts-ignore*/}
                                        <Editor
                                            editorState={editorArrestAgencyState}
                                            onChange={(event) => { handleValueChange('CriminalActivityObject','ArrestingAgency', draftToHtml(event)) }}
                                            onEditorStateChange={(e) => { setEditorArrestAgencyState(e) }}
                                            toolbar={{
                                                options: [ 'inline' ,'fontFamily', 'colorPicker', 'textAlign'],
                                                inline: { options: ['bold', 'italic','underline'] },
                                                colorPicker:{colors: ['rgb(255,0,0)']}
                                            }}
                                        />
                                   </span>
                                </span>
                            <span className = "ml-2">
                                    <label className = "font-bold">Pending Cases: </label>
                                    <span className = "ml-2">
                                        {/* <TextareaAutosize style={{width:"99%"}} minRows={1} maxRows={4} placeholder="Pending Cases:" value = {report?.criminalActivityObject?.pendingCases} onChange={(e) => handleValueChange("criminalActivityObject", "pendingCases", e.target.value )}/></span> */}
                                        {/* <RichTextEditorComponent enableXhtml={true} pasteCleanupSettings={pasteCleanupSettings} toolbarSettings={toolbarSettings} style={{minHeight:'150'}} enterKey="P"  value = {handleRichTextNull(report?.CriminalActivityObject?.PendingCases)} change={(e) => handleValueChange("CriminalActivityObject", "PendingCases", e.value )}>
                                            <Inject services={[Toolbar, HtmlEditor, QuickToolbar, PasteCleanup]} />
                                        </RichTextEditorComponent> */}
                                        {/*@ts-ignore*/}
                                        <Editor
                                            editorState={editorPendingCaseState}
                                            onChange={(event) => { handleValueChange('CriminalActivityObject','PendingCases', draftToHtml(event)) }}
                                            onEditorStateChange={(e) => { setEditorPendingCaseState(e) }}
                                            toolbar={{
                                                options: [ 'inline' ,'fontFamily', 'colorPicker', 'textAlign'],
                                                inline: { options: ['bold', 'italic','underline'] },
                                                colorPicker:{colors: ['rgb(255,0,0)']}

                                            }}
                                        />
                                    </span>
                                </span>
                        </div>
                    </div>

                    <div>
                        <div className="border-2 border-gray-700 mb-5">
                            <div className = "border-b-2 border-gray-700 bg-blue-300 h-6 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Custody History</div>
                            <div className = "grid grid-cols-2 flex-1">
                                <div className="border-r-2 border-b-2 border-gray-700 ">
                                    <h1 className="font-bold" style={{ "display": "flex", "justifyContent": "center" }}>State Custody</h1>
                                </div>
                                <div className="border-b-2 border-gray-700">
                                    <h1 className="font-bold" style={{ "display": "flex", "justifyContent": "center" }}>County Custody (Most Recent)</h1>
                                </div>
                                <div className="border-r-2 border-b-2 border-gray-700 " >
                                    <div>
                                        {/* <TextField fullWidth variant="standard" margin='none' size={"small"} inputProps={{ style: {textAlign: 'center'} }} value={report?.custodyHistoryObject?.stateCustody} onChange = { (e) => handleValueChange("custodyHistoryObject", "stateCustody", e.target.value )} /> */}
                                        {/* <RichTextEditorComponent enableXhtml={true} pasteCleanupSettings={pasteCleanupSettings} toolbarSettings={toolbarSettings} style={{minHeight:'150'}} enterKey="P"  value = {handleRichTextNull(report?.CustodyHistoryObject?.StateCustody)} change={(e) => handleValueChange("CustodyHistoryObject", "StateCustody", e.value )}>
                                                <Inject services={[Toolbar, HtmlEditor, QuickToolbar, PasteCleanup]} />
                                            </RichTextEditorComponent> */}
                                        {/*@ts-ignore*/}
                                        <Editor
                                            editorState={editorStateCustodyState}
                                            onChange={(event) => { handleValueChange('CustodyHistoryObject','StateCustody',draftToHtml(event)) }}
                                            onEditorStateChange={(e) => { setEditorStateCustodyState(e) }}
                                            toolbar={{
                                                options: [ 'inline' ,'fontFamily', 'colorPicker', 'textAlign'],
                                                inline: { options: ['bold', 'italic','underline'] },
                                                colorPicker:{colors: ['rgb(255,0,0)']}
                                            }}
                                        />

                                    </div>
                                </div>
                                <div className="border-b-2 border-gray-700">
                                    <div>
                                        {/* <TextField fullWidth variant="standard" margin='none' size={"small"} inputProps={{ style: {textAlign: 'center'} }} value={report?.custodyHistoryObject?.countyCustody} onChange = { (e) => handleValueChange("custodyHistoryObject", "countyCustody", e.target.value )} /> */}
                                        {/* <RichTextEditorComponent enableXhtml={true} pasteCleanupSettings={pasteCleanupSettings} toolbarSettings={toolbarSettings} style={{minHeight:'150'}} enterKey="P"  value = {handleRichTextNull(report?.CustodyHistoryObject?.CountyCustody)} change={(e) => handleValueChange("CustodyHistoryObject", "CountyCustody", e.value )}>
                                                <Inject services={[Toolbar, HtmlEditor, QuickToolbar, PasteCleanup]} />
                                            </RichTextEditorComponent> */}
                                        {/*@ts-ignore*/}
                                        <Editor
                                            editorState={editorCountyCustodyState}
                                            onChange={(event) => { handleValueChange('CustodyHistoryObject','CountyCustody', draftToHtml(event)) }}
                                            onEditorStateChange={(e) => { setEditorCountyCustodyState(e) }}
                                            toolbar={{
                                                options: [ 'inline' ,'fontFamily', 'colorPicker', 'textAlign'],
                                                inline: { options: ['bold', 'italic','underline'] },
                                                colorPicker:{colors: ['rgb(255,0,0)']}
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="border-2 border-gray-700 mb-5">
                            <div className = "border-b-2 border-gray-700 bg-blue-300 h-6 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>MVC Status / Registered Vehicles</div>
                            {/* <TextareaAutosize style={{width:"100%", textAlign: 'center'}} minRows={2} maxRows={6} placeholder="MVC Status / Registered Vehicles" value={report?.personDetailsObject.mvcStatus} onChange={(e:any) => handleValueChange("personDetailsObject", "mvcStatus", e.target.value )} /> */}
                            {/* <RichTextEditorComponent enableXhtml={true} pasteCleanupSettings={pasteCleanupSettings} toolbarSettings={toolbarSettings} style={{minHeight:'150'}} enterKey="P"  placeholder="MVC Status / Registered Vehicles" value = {handleRichTextNull(report?.PersonDetailsObject?.MVCStatus)} change={(e) => handleValueChange("PersonDetailsObject", "MVCStatus", e.value )}>
                                     <Inject services={[Toolbar, HtmlEditor, QuickToolbar, PasteCleanup]} />
                                </RichTextEditorComponent> */}
                            {/*@ts-ignore*/}
                            <Editor
                                editorState={editorMVCState}
                                onChange={(event) => { handleValueChange('PersonDetailsObject','MVCStatus', draftToHtml(event)) }}
                                onEditorStateChange={(e) => { setEditorMVCState(e) }}
                                toolbar={{
                                    options: [ 'inline' ,'fontFamily', 'colorPicker', 'textAlign'],
                                    inline: { options: ['bold', 'italic','underline'] },
                                    colorPicker:{colors: ['rgb(255,0,0)']}
                                }}
                            />
                        </div>
                    </div>

                    <div>
                        <div className="border-2 border-gray-700 mb-5">
                            <div className = "border-b-2 border-gray-700 bg-blue-300 h-6 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Associate Contacts / Vehicles</div>
                            {/* <TextareaAutosize style={{width:"100%" , textAlign: 'center'}} minRows={2} maxRows={6} placeholder="Associate Contacts / Vehicles" value={report?.personDetailsObject.associateContacts} onChange={(e:any) => handleValueChange("personDetailsObject", "associateContacts", e.target.value )}/> */}
                            {/* <RichTextEditorComponent enableXhtml={true} pasteCleanupSettings={pasteCleanupSettings} toolbarSettings={toolbarSettings} style={{minHeight:'150'}} enterKey="P"  placeholder="MVC Status / Registered Vehicles" value = {handleRichTextNull(report?.PersonDetailsObject?.AssociateContacts)} change={(e) => handleValueChange("PersonDetailsObject", "AssociateContacts", e.value )}>
                                     <Inject services={[Toolbar, HtmlEditor, QuickToolbar, PasteCleanup]} />
                                </RichTextEditorComponent> */}
                            {/*@ts-ignore*/}
                            <Editor
                                editorState={editorContactsState}
                                onChange={(event) => { handleValueChange('PersonDetailsObject','AssociateContacts', draftToHtml(event)) }}
                                onEditorStateChange={(e) => { setEditorContactsState(e) }}
                                toolbar={{
                                    options: [ 'inline' ,'fontFamily', 'colorPicker', 'textAlign'],
                                    inline: { options: ['bold', 'italic','underline'] },
                                    colorPicker:{colors: ['rgb(255,0,0)']}
                                }}
                            />
                        </div>
                    </div>

                    <div>
                        <div className="border-2 border-gray-700 mb-5">
                            <div className = "grid grid-cols-2 flex-1">
                                <div className = "border-b-2 border-r-2 border-gray-700 bg-blue-300 h-6 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Triple I</div>
                                <div className = "border-b-2 border-gray-700 bg-blue-300 h-6 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>ALPR</div>
                                <div className="border-r-2 border-b-2 border-gray-700 " >
                                    <div>
                                        {/* <TextField fullWidth variant="standard" margin='none' size={"small"} inputProps={{ style: {textAlign: 'center'} }} value={report?.personDetailsObject.triple} onChange={(e:any) => handleValueChange("personDetailsObject", "triple", e.target.value )} /> */}
                                        {/* <RichTextEditorComponent enableXhtml={true} pasteCleanupSettings={pasteCleanupSettings} toolbarSettings={toolbarSettings} style={{minHeight:'150'}} enterKey="P"  value={handleRichTextNull(report?.PersonDetailsObject?.Triple)} change={(e) => handleValueChange("PersonDetailsObject", "Triple", e.value )}>
                                                <Inject services={[Toolbar, HtmlEditor, QuickToolbar, PasteCleanup]} />
                                            </RichTextEditorComponent> */}
                                        {/*@ts-ignore*/}
                                        <Editor
                                            editorState={editorTripleState}
                                            onChange={(event) => { handleValueChange('PersonDetailsObject','Triple', draftToHtml(event)) }}
                                            onEditorStateChange={(e) => { setEditorTripleState(e) }}
                                            toolbar={{
                                                options: [ 'inline' ,'fontFamily', 'colorPicker', 'textAlign'],
                                                inline: { options: ['bold', 'italic','underline'] },
                                                colorPicker:{colors: ['rgb(255,0,0)']}
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="border-b-2 border-gray-700">
                                    <div>
                                        {/* <TextField fullWidth  variant="standard" margin='none' size={"small"} inputProps={{ style: {textAlign: 'center'} }} value={report?.personDetailsObject.ALPR} onChange={(e:any) => handleValueChange("personDetailsObject", "ALPR", e.target.value )} /> */}
                                        {/* <RichTextEditorComponent enableXhtml={true} pasteCleanupSettings={pasteCleanupSettings} toolbarSettings={toolbarSettings} style={{minHeight:'150'}} enterKey="P"  value={handleRichTextNull(report?.PersonDetailsObject?.ALPR)} change={(e) => handleValueChange("PersonDetailsObject", "ALPR", e.value )}>
                                                <Inject services={[Toolbar, HtmlEditor, QuickToolbar, PasteCleanup]} />
                                            </RichTextEditorComponent>     */}
                                        {/*@ts-ignore*/}
                                        <Editor
                                            editorState={editorALPRState}
                                            onChange={(event) => { handleValueChange('PersonDetailsObject','ALPR',draftToHtml(event)) }}
                                            onEditorStateChange={(e) => { setEditorALPRState(e) }}
                                            toolbar={{
                                                options: [ 'inline' ,'fontFamily', 'colorPicker', 'textAlign'],
                                                inline: { options: ['bold', 'italic','underline'] },
                                                colorPicker:{colors: ['rgb(255,0,0)']}
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="border-2 border-gray-700 mb-5">
                            <div className = "grid grid-cols-2 flex-1">
                                <div className = "border-b-2 border-r-2 border-gray-700 bg-blue-300 h-6 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>ACS / ATS / NCIC Holds</div>
                                <div className = "border-b-2 border-gray-700 bg-blue-300 h-6 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Probation / Parole</div>
                                <div className="border-r-2 border-b-2 border-gray-700 " >
                                    {/* <TextareaAutosize style={{width:"100%" , textAlign: 'center'}} minRows={1} maxRows={6} placeholder="ACS / ATS / NCIC Holds" value={report?.personDetailsObject.holds} onChange={(e:any) => handleValueChange("personDetailsObject", "holds", e.target.value )}/> */}
                                    {/* <RichTextEditorComponent enableXhtml={true} pasteCleanupSettings={pasteCleanupSettings} toolbarSettings={toolbarSettings} style={{minHeight:'150'}} enterKey="P"  placeholder="ACS / ATS / NCIC Holds" value={handleRichTextNull(report?.PersonDetailsObject?.Holds)} change={(e) => handleValueChange("PersonDetailsObject", "Holds", e.value )} >
                                                <Inject services={[Toolbar, HtmlEditor, QuickToolbar, PasteCleanup]} />
                                        </RichTextEditorComponent> */}
                                    {/*@ts-ignore*/}
                                    <Editor
                                        editorState={editorHoldsState}
                                        onChange={(event) => { handleValueChange('PersonDetailsObject','Holds', draftToHtml(event)) }}
                                        onEditorStateChange={(e) => { setEditorHoldsState(e) }}
                                        toolbar={{
                                            options: [ 'inline' ,'fontFamily', 'colorPicker', 'textAlign'],
                                            inline: { options: ['bold', 'italic','underline'] },
                                            colorPicker:{colors: ['rgb(255,0,0)']}
                                        }}
                                    />
                                </div>
                                <div className="border-b-2 border-gray-700">
                                    {/* <TextareaAutosize style={{width:"100%" , textAlign: 'center'}} minRows={1} maxRows={6} placeholder="Probation / Parole" value={report?.personDetailsObject.parole} onChange={(e:any) => handleValueChange("personDetailsObject", "parole", e.target.value )}/> */}
                                    {/* <RichTextEditorComponent enableXhtml={true} pasteCleanupSettings={pasteCleanupSettings} toolbarSettings={toolbarSettings} style={{minHeight:'150'}} enterKey="P"  placeholder="Probation / Parole" value={handleRichTextNull(report?.PersonDetailsObject?.Parole)} change={(e) => handleValueChange("PersonDetailsObject", "Parole", e.value )} >
                                                <Inject services={[Toolbar, HtmlEditor, QuickToolbar, PasteCleanup]} />
                                        </RichTextEditorComponent> */}
                                    {/*@ts-ignore*/}
                                    <Editor
                                        editorState={editorParoleState}
                                        onChange={(event) => { handleValueChange('PersonDetailsObject','Parole', draftToHtml(event)) }}
                                        onEditorStateChange={(e) => { setEditorParoleState(e) }}
                                        toolbar={{
                                            options: [ 'inline' ,'fontFamily', 'colorPicker', 'textAlign'],
                                            inline: { options: ['bold', 'italic','underline'] },
                                            colorPicker:{colors: ['rgb(255,0,0)']}
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="border-2 border-gray-700 mb-5">
                            <div className = "grid grid-cols-2 flex-1">
                                <div className = "border-b-2 border-r-2 border-gray-700 bg-blue-300 h-6 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Employment History</div>
                                <div className = "border-b-2 border-gray-700 bg-blue-300 h-6 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Domestic Violence</div>
                                <div className="border-r-2 border-b-2 border-gray-700 " >
                                    {/* <TextareaAutosize style={{width:"100%" , textAlign: 'center'}} minRows={2} maxRows={6} placeholder="Employment History" value={report?.personDetailsObject.employmentHistory} onChange={(e:any) => handleValueChange("personDetailsObject", "employmentHistory", e.target.value )}/> */}
                                    {/* <RichTextEditorComponent enableXhtml={true} pasteCleanupSettings={pasteCleanupSettings} toolbarSettings={toolbarSettings} style={{minHeight:'150'}} enterKey="P"  placeholder="Employment History" value={handleRichTextNull(report?.PersonDetailsObject?.EmploymentHistory)} change={(e) => handleValueChange("PersonDetailsObject", "EmploymentHistory", e.value )} >
                                                <Inject services={[Toolbar, HtmlEditor, QuickToolbar, PasteCleanup]} />
                                        </RichTextEditorComponent> */}
                                    {/*@ts-ignore*/}
                                    <Editor
                                        editorState={editorEmpHistoryState}
                                        onChange={(event) => { handleValueChange('PersonDetailsObject','EmploymentHistory', draftToHtml(event)) }}
                                        onEditorStateChange={(e) => { setEditorEmpHistoryState(e) }}
                                        toolbar={{
                                            options: [ 'inline' ,'fontFamily', 'colorPicker', 'textAlign'],
                                            inline: { options: ['bold', 'italic','underline'] },
                                            colorPicker:{colors: ['rgb(255,0,0)']}
                                        }}
                                    />
                                </div>
                                <div className="border-b-2 border-gray-700">
                                    {/* <TextareaAutosize style={{width:"100%" , textAlign: 'center'}} minRows={2} maxRows={6} placeholder="Domestic Violence" value={report?.personDetailsObject.domesticViolence} onChange={(e:any) => handleValueChange("personDetailsObject", "domesticViolence", e.target.value )}/> */}
                                    {/* <RichTextEditorComponent enableXhtml={true} pasteCleanupSettings={pasteCleanupSettings} toolbarSettings={toolbarSettings} style={{minHeight:'150'}} enterKey="P"  placeholder="Domestic Violence" value={handleRichTextNull(report?.PersonDetailsObject?.DomesticViolence)} change={(e) => handleValueChange("PersonDetailsObject", "DomesticViolence", e.value )} >
                                                <Inject services={[Toolbar, HtmlEditor, QuickToolbar, PasteCleanup]} />
                                        </RichTextEditorComponent> */}
                                    {/*@ts-ignore*/}
                                    <Editor
                                        editorState={editorDomViolenceState}
                                        onChange={(event) => { handleValueChange('PersonDetailsObject','DomesticViolence' , draftToHtml(event)) }}
                                        onEditorStateChange={(e) => { setEditorDomViolenceState(e) }}
                                        toolbar={{
                                            options: [ 'inline' ,'fontFamily', 'colorPicker', 'textAlign'],
                                            inline: { options: ['bold', 'italic','underline'] },
                                            colorPicker:{colors: ['rgb(255,0,0)']}
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="border-2 border-gray-700 mb-5">
                            <div className = "grid grid-cols-2 flex-1">
                                <div className = "border-b-2 border-r-2 border-gray-700 bg-blue-300 h-6 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Juvenile Registry</div>
                                <div className = "border-b-2 border-gray-700 bg-blue-300 h-6 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Social Media</div>
                                <div className="border-r-2 border-b-2 border-gray-700 " >
                                    {/* <TextareaAutosize style={{width:"100%" , textAlign: 'center'}} minRows={2} maxRows={6} placeholder="Juvenile Registry" value={report?.personDetailsObject.juvenileRegistry} onChange={(e:any) => handleValueChange("personDetailsObject", "juvenileRegistry", e.target.value )}/> */}
                                    {/* <RichTextEditorComponent enableXhtml={true} pasteCleanupSettings={pasteCleanupSettings} toolbarSettings={toolbarSettings} style={{minHeight:'150'}} enterKey="P"  placeholder="Juvenile Registry" value={handleRichTextNull(report?.PersonDetailsObject?.JuvenileRegistry)} change={(e) => handleValueChange("PersonDetailsObject", "JuvenileRegistry", e.value )} >
                                                <Inject services={[Toolbar, HtmlEditor, QuickToolbar, PasteCleanup]} />
                                        </RichTextEditorComponent> */}
                                    {/*@ts-ignore*/}
                                    <Editor
                                        editorState={editorJuvRegistryState}
                                        onChange={(event) => { handleValueChange('PersonDetailsObject','JuvenileRegistry' ,draftToHtml(event)) }}
                                        onEditorStateChange={(e) => { setEditorJuvRegistryState(e) }}
                                        toolbar={{
                                            options: [ 'inline' ,'fontFamily', 'colorPicker', 'textAlign'],
                                            inline: { options: ['bold', 'italic','underline'] },
                                            colorPicker:{colors: ['rgb(255,0,0)']}
                                        }}
                                    />
                                </div>
                                <div className="border-b-2 border-gray-700">
                                    {/* <TextareaAutosize style={{width:"100%" , textAlign: 'center'}} minRows={2} maxRows={6} placeholder="Social Media" value={report?.personDetailsObject.socialMedia} onChange={(e:any) => handleValueChange("personDetailsObject", "socialMedia", e.target.value )}/> */}
                                    {/* <RichTextEditorComponent enableXhtml={true} pasteCleanupSettings={pasteCleanupSettings} toolbarSettings={toolbarSettings} style={{minHeight:'150'}} enterKey="P"  placeholder="Social Media" value={handleRichTextNull(report?.PersonDetailsObject?.SocialMedia)} change={(e) => handleValueChange("PersonDetailsObject", "SocialMedia", e.value )} >
                                                <Inject services={[Toolbar, HtmlEditor, QuickToolbar, PasteCleanup]} />
                                        </RichTextEditorComponent> */}
                                    {/*@ts-ignore*/}
                                    <Editor
                                        editorState={editorSMState}
                                        onChange={(event) => { handleValueChange('PersonDetailsObject', 'SocialMedia' ,draftToHtml(event)) }}
                                        onEditorStateChange={(e) => { setEditorSMState(e) }}
                                        toolbar={{
                                            options: [ 'inline' ,'fontFamily', 'colorPicker', 'textAlign'],
                                            inline: { options: ['bold', 'italic','underline'] },
                                            colorPicker:{colors: ['rgb(255,0,0)']}
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="border-2 border-gray-700 mb-5">
                            <div className = "border-b-2 border-gray-700 bg-blue-300 h-6 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Police Contact Locations (Start from the most recent dates)</div>
                            <div className = "grid grid-cols-3 flex-1">
                                <div className="border-r-2 border-b-2 border-gray-700 ">
                                    <h1 className="font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Arrests (Last 5 Years)</h1>
                                </div>
                                <div className="border-r-2  border-b-2 border-gray-700">
                                    <h1 className="font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Field Interviews (Last 5 Years)</h1>
                                </div>
                                <div className="border-b-2 border-gray-700">
                                    <h1 className="font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Other Police Contacts (Last 1 Year)</h1>
                                </div>
                                <div className="border-r-2 border-b-2 border-gray-700 " >
                                    {/* <TextareaAutosize style={{width:"100%" , textAlign: 'center'}} minRows={2} maxRows={8} placeholder="Arrests" value={report?.policeContactLocations?.arrests} onChange = { (e) => handleValueChange("policeContactLocations", "arrests", e.target.value )}/> */}
                                    {/* <RichTextEditorComponent enableXhtml={true} toolbarSettings={toolbarSettings} pasteCleanupSettings={pasteCleanupSettings} style={{minHeight:'150'}} enterKey="P"  placeholder="Arrests" value={handleRichTextNull(report?.PoliceContactLocations?.Arrests)} change={(e) => handleValueChange("PoliceContactLocations", "Arrests", e.value )} >
                                                <Inject services={[Toolbar, HtmlEditor, QuickToolbar, PasteCleanup]} />
                                        </RichTextEditorComponent> */}
                                    {/*@ts-ignore*/}
                                    <Editor
                                        editorState={editorArrestState}
                                        onChange={(event) => { handleValueChange('PoliceContactLocations','Arrests', draftToHtml(event)) }}
                                        onEditorStateChange={(e) => { setEditorArrestState(e) }}
                                        toolbar={{
                                            options: [ 'inline' ,'fontFamily', 'colorPicker', 'textAlign'],
                                            inline: { options: ['bold', 'italic','underline'] },
                                            colorPicker:{colors: ['rgb(255,0,0)']}
                                        }}
                                    />
                                </div>
                                <div className="border-r-2 border-b-2 border-gray-700">
                                    {/* <TextareaAutosize style={{width:"100%" , textAlign: 'center'}} minRows={2} maxRows={8} placeholder="Field Interviews" value={report?.policeContactLocations?.fieldInterviews} onChange = { (e) => handleValueChange("policeContactLocations", "fieldInterviews", e.target.value )}/> */}
                                    {/* <RichTextEditorComponent enableXhtml={true} toolbarSettings={toolbarSettings} pasteCleanupSettings={pasteCleanupSettings} style={{minHeight:'150'}} enterKey="P"  placeholder="Field Interviews" value={handleRichTextNull(report?.PoliceContactLocations?.FieldInterviews)} change={(e) => handleValueChange("PoliceContactLocations", "FieldInterviews", e.value )} >
                                                <Inject services={[Toolbar, HtmlEditor, QuickToolbar, PasteCleanup]} />
                                        </RichTextEditorComponent> */}
                                    {/*@ts-ignore*/}
                                    <Editor
                                        editorState={editorInterviewState}
                                        onChange={(event) => { handleValueChange('PoliceContactLocations','FieldInterviews',draftToHtml(event)) }}
                                        onEditorStateChange={(e) => { setEditorInterviewState(e) }}
                                        toolbar={{
                                            options: [ 'inline' ,'fontFamily', 'colorPicker', 'textAlign'],
                                            inline: { options: ['bold', 'italic','underline'] },
                                            colorPicker:{colors: ['rgb(255,0,0)']}
                                        }}
                                    />
                                </div>
                                <div className="border-b-2 border-gray-700">
                                    {/* <TextareaAutosize style={{width:"100%" , textAlign: 'center'}} minRows={2} maxRows={8} placeholder="Other Police Contacts (Last 1 Years)" value={report?.policeContactLocations?.other} onChange = { (e) => handleValueChange("policeContactLocations", "other", e.target.value )}/> */}
                                    {/* <RichTextEditorComponent enableXhtml={true} toolbarSettings={toolbarSettings} pasteCleanupSettings={pasteCleanupSettings} style={{minHeight:'150'}} enterKey="P"  placeholder="Other Police Contacts (Last 1 Years)" value={handleRichTextNull(report?.PoliceContactLocations?.Other)} change={(e) => handleValueChange("PoliceContactLocations", "Other", e.value )} >
                                                <Inject services={[Toolbar, HtmlEditor, QuickToolbar, PasteCleanup]} />
                                        </RichTextEditorComponent> */}
                                    {/*@ts-ignore*/}
                                    <Editor
                                        editorState={editorPoliceState}
                                        onChange={(event) => { handleValueChange('PoliceContactLocations','Other',draftToHtml(event)) }}
                                        onEditorStateChange={(e) => { setEditorPoliceState(e) }}
                                        toolbar={{
                                            options: [ 'inline' ,'fontFamily', 'colorPicker', 'textAlign'],
                                            inline: { options: ['bold', 'italic','underline'] },
                                            colorPicker:{colors: ['rgb(255,0,0)']}
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="border-2 border-gray-700 my-3">
                            <div className = "border-b-2 border-gray-700 bg-blue-300 h-6 font-bold mb-2" style={{ "display": "flex", "justifyContent": "center" }}>Additional Information</div>
                            {/* <div className ="ml-2 w-96 mb-5" >
                                        <label className="font-bold font-large ml-2"> Additional Aliases:</label>
                                        <TextField  fullWidth multiline variant="standard" margin='none' size={"medium"} value = {report?.AdditionalInfo} onChange={(e:any) => handleValueChange("ProfileReport", "CENum", e.target.value)}/>
                                    </div>
                                    <div className ="ml-2 w-96 mt-5">
                                        <label className="font-bold font-large ml-2"> Additional Addresses:</label>
                                        <TextField  fullWidth multiline variant="standard" margin='none' size={"small"} value = {report?.AdditionalInfo} onChange={(e:any) => handleValueChange("ProfileReport", "CENum", e.target.value)}/>
                                    </div>
                                    <div className ="ml-2 w-96 mt-5">
                                        <label className="font-bold font-large ml-2"> Additional Telephone Numbers:</label>
                                        <TextField  fullWidth multiline variant="standard" margin='none' size={"small"} value = {report?.AdditionalInfo} onChange={(e:any) => handleValueChange("ProfileReport", "CENum", e.target.value)}/>
                                    </div> */}

                            {report?.AdditionalInfo && report?.AdditionalInfo?.length > 0 ? report?.AdditionalInfo.map((item:additionalInfo, indx:number) => !item.Deleted ? (
                                <div>
                                        <span className = "ml-2">
                                            <span style={{display: 'flex', marginLeft: '0.5rem'}}>
                                                {!item.Default ? (<TextField variant="standard" style={{width:"30%"}} inputProps={{style: {fontSize: 15, padding:0, width:"100%", fontWeight: "bold"}}} value = {item?.Type} onChange={(e) => handleValueChange("AdditionalInfo", "Type", e.target.value, indx )} />):
                                                    <h1 className="font-bold">{item.Type}</h1>}
                                                {item.Type === 'Additional Information:' ? (<span className = "ml-4 inline"><IconButton size="small" onClick={() => removeAdditionalOption(indx)} color={"error"}><DeleteIcon/></IconButton></span>) : <></>}
                                            </span>
                                            <span className = "ml-2">
                                                <TextField multiline style={{width:"30%"}} variant="standard" placeholder={item?.Type ? item?.Type : ''} value = {item.Content} onChange={(e) => handleValueChange("AdditionalInfo", "Content", e.target.value, indx )}/>
                                            </span>
                                        </span>
                                </div>
                            ) :<></>) : <></>}

                            <div style={{"display":"inline-flex", "marginTop":"0.5rem"}}>
                                <div  onClick = {(e) => {addAdditionalInfo()}}><label className = "text-blue-600  font-bold ml-2"> Add Additional Information</label></div>
                                <Button onClick = {(e) => {addAdditionalInfo()}}><AddCircleOutlineIcon/></Button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="border-2 border-gray-700 mb-5">
                            <div className="grid grid-cols-4" >
                                <div className = "ml-2 border-r-2 border-gray-700 pr-4">
                                    <label className = "text-blue-600 font-bold">Prepared by: </label>
                                    <List>
                                        {report.PreparedBy && report?.PreparedBy?.length > 0 ? report?.PreparedBy
                                            .map((item:editRecord, indx:number) => !item.Deleted ?(
                                                <ListItem style={{'padding':'0'}}>
                                                <span className="" style={{'width': '100%'}}>
                                                    {item.ID && item.ID <= 0 ? <TextField variant="standard" sx={{'width': '80%'}} inputProps={{style: {fontSize: 15, padding:0, width:"100%", paddingLeft: "10px"}}} value = {item.Content} onChange={(e) => handleValueChange("PreparedBy", "", e.target.value, indx )} /> :
                                                        <TextField variant="standard" sx={{'width': '80%'}} inputProps={{style: {fontSize: 15, padding:0, width:"100%", paddingLeft: "10px"}}} value = {item.Content} onChange={(e) => handleValueChange("PreparedBy", "", e.target.value, indx )} />}
                                                    <span className = "ml-4 inline " style={{display: item.ID && item.ID > 0 ? "none" : "inline"}}><IconButton size="small" onClick={() => removePreparedby(indx)} color={"error"}><DeleteIcon/></IconButton></span>
                                                </span>
                                                </ListItem>
                                            ): <></>) : <></>}
                                        <ListItem style={{'padding':'0'}}>
                                            <div>
                                                <Button onClick = {(e) => {addPreparedby()}}><AddCircleOutlineIcon/></Button>
                                            </div>
                                        </ListItem>
                                    </List>
                                </div>
                                < div className = "ml-2 border-r-2 border-gray-700 pr-4" >
                                    <label className = "text-blue-600 font-bold">Reviewed by: </label>
                                    <List>
                                        {report.ReviewedBy && report?.ReviewedBy?.length > 0 ? report?.ReviewedBy
                                            .map((item:editRecord, indx:number) => !item.Deleted ? (
                                                <ListItem style={{'padding':'0'}}>
                                                <span className="" style={{'width': '100%'}}>
                                                    {item.ID && item.ID <= 0 ? <TextField variant="standard" sx={{'width': '80%'}} inputProps={{style: {fontSize: 15, padding:0, width:"100%", paddingLeft: "10px"}}} value = {item.Content} onChange={(e) => handleValueChange("ReviewedBy", "", e.target.value, indx )} /> :
                                                        <TextField variant="standard" sx={{'width': '80%'}} inputProps={{style: {fontSize: 15, padding:0, width:"100%", paddingLeft: "10px"}}} value = {item.Content} onChange={(e) => handleValueChange("ReviewedBy", "", e.target.value, indx )} />}
                                                    <span className = "ml-4 inline " style={{display: item.ID && item.ID > 0 ? "none" : "inline"}}><IconButton size="small" onClick={() => removeReviewedBy(indx)} color={"error"}><DeleteIcon/></IconButton></span>
                                                </span>
                                                </ListItem>
                                            ): <></>) : <></>}
                                        <ListItem style={{'padding':'0'}}>
                                            <div>
                                                <Button onClick = {(e) => {addReviewedBy()}}><AddCircleOutlineIcon/></Button>
                                            </div>
                                        </ListItem>
                                    </List>
                                </div>
                                <div className = "ml-2 border-r-2 border-gray-700 pr-4">
                                    <div><label className = "text-blue-600 font-bold">Date: </label></div>
                                    <div><span>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    //label="Date"
                                                    value={report?.ProfileReportObject?.ReportDate}
                                                    disableFuture = {true}
                                                    onChange = { (e) => handleValueChange("ProfileReport", "ReportDate", e )}
                                                    renderInput={(params) => <TextField margin='none' style={{width:"100%"}} variant={"standard"} type="date" label="Date"  InputLabelProps={{ shrink: true }} {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </span></div>
                                </div>
                                <div className = "ml-2 pr-4">
                                    <div><label className = "text-blue-600 font-bold">Report #: </label></div>
                                    {/* <TextareaAutosize value={report?.ProfileReportObject?.ReportNum ? report?.ProfileReportObject?.ReportNum : ''} aria-label="maximum height" minRows={2} style={{width:"100%"}} placeholder="Report #"  onChange = { (e) => handleValueChange("ProfileReport", "ReportNum", e.target.value )} /> */}
                                    <TextField
                                        fullWidth
                                        multiline
                                        value={report?.ProfileReportObject?.ReportNum ? report?.ProfileReportObject?.ReportNum : ''}
                                        error={!report?.ProfileReportObject?.ReportNum || report?.ProfileReportObject?.ReportNum == undefined ? true : false}
                                        minRows={2}
                                        onChange = { (e) => handleValueChange("ProfileReport", "ReportNum", e.target.value )}
                                        InputProps={{
                                            inputComponent: TextareaAutosize,
                                            inputProps: {
                                                style: {
                                                    resize: "both",
                                                    width: "100%"
                                                },
                                                placeholder:'Report #'

                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="px-4 mb-4">
                        {addedFiles && addedFiles.length > 0
                            ? <div className={"font-bold mb-3"}>
                                Files Pending for Upload to Profile Report
                            </div>
                            : <></>
                        }
                        {addedFiles ?
                            addedFiles.map((file: any, indx: number) => {
                                return (
                                    <span className={"pr-2 border-2 rounded-lg border-blue-400 pl-2 py-2 mr-1 mb-2"}>
                                <span>
                                    <Tooltip title="Download File" >
                                        <IconButton style={{ margin: 0, padding: 0 }} onClick={() => downloadFile(file, indx)}><DownloadIcon /></IconButton>
                                    </Tooltip>
                                </span>
                                <span className={"font-bold text-blue-400 mr-1"} onClick={() => downloadFile(file, indx)}>
                                    {file.FileName}
                                </span>
                                <span>
                                    <Tooltip title="Delete File" >
                                        <IconButton color="error" style={{ margin: 0, padding: 0 }} onClick={() => removeFile(file, indx)}><DeleteIcon /></IconButton>
                                    </Tooltip>
                                </span>
                            </span>
                                );
                            }) : <></>}
                    </div>
                    <div className="px-4 mb-4">
                        {report.Files && report.Files.filter(res => res.Deleted === false).length > 0
                            ? <div className={"font-bold mb-3"}>
                                Files Attached to Profile Report
                            </div>
                            : <></>
                        }
                        {report.Files ?
                            report.Files.filter(res => res.Deleted === false).map((file: any, indx: number) => {
                                return (
                                    <span className={"pr-2 border-2 rounded-lg border-green-500 pl-2 py-2 mr-1 mb-2"}>
                                <span>
                                    <Tooltip title="Download File" >
                                        <IconButton style={{ margin: 0, padding: 0 }} onClick={() => downloadFile(file, indx)}><DownloadIcon /></IconButton>
                                    </Tooltip>
                                </span>
                                <span className={"font-bold text-green-500 mr-1"} onClick={() => downloadFile(file, indx)}>
                                    {file.FileName}
                                </span>
                                <span>
                                    <Tooltip title="Delete File" >
                                        <IconButton color="error" style={{ margin: 0, padding: 0 }} onClick={() => removeFile(file, indx)}><DeleteIcon /></IconButton>
                                    </Tooltip>
                                </span>
                            </span>
                                );
                            }) : <></>}
                    </div>
                    <div>
                        {/* {report?.Files?.map((doc:any) => !doc.Deleted ? (
                                <div className = "tag-item text-green-600">
                                    {doc.FileName}

                                    <button
                                        type="button"
                                        className ="button text-black"
                                        style={{}}
                                        // onClick={() => handleFilesDelete(doc)}
                                    >
                                        &times;
                                    </button>
                                </div> ): <></>
                            )} */}
                        {/* {docList.map(doc =>
                        <div className = "tag-item text-yellow-600">
                            {doc.name}

                            <button
                                type="button"
                                className ="button text-black"
                                style={{}}
                                // onClick={() => handleDocDelete(doc)}
                            >
                                &times;
                            </button>
                        </div>)} */}
                        <div className = "mt-2">
                            <label className =" border-2 border-gray-300 py-2 pl-2 text-xl rounded-xl text-gray-400" htmlFor="icon">
                                <input
                                    id="icon"
                                    hidden
                                    className = "input"
                                    multiple
                                    onChange={e => {handleAdditionalFiles(e.target)}}
                                    type="file"
                                    accept = ".pdf, .doc, .docx, .jpeg, .png, .jpg"
                                />
                                Attach Files to this Report
                                <IconButton color="primary" component="span">
                                    <AttachFileIcon />
                                </IconButton>
                            </label>
                        </div>
                    </div>
                    <Snackbar open={alertOpen} autoHideDuration={7000} onClose={() => { setAlertOpen(false) }}>
                        <Alert onClose={() => { setAlertOpen(false) }} severity={alertType} sx={{ width: '100%' }}>
                            {alertText}
                        </Alert>
                    </Snackbar>
                    <div className={"pt-3"} style={{"display": "flex"}}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                            </Grid>
                            <Grid item xs={4} sx={{"justifyContent":"center", "display": "flex"}}>
                                <Chip size="medium" label={"Version: 1.0.1"} variant="outlined" sx={{fontSize: "1.2rem", width:"150px", height:"40px"}} />
                            </Grid>
                            <Grid item xs={4} sx={{"justifyContent":"end", "display": "flex"}}>
                                <ButtonGroup size="large" color="inherit" >
                                    <Button style={{'minWidth': '125px'}} type="submit" onClick={handleSave}>Save</Button>
                                </ButtonGroup>
                            </Grid>
                        </Grid>

                    </div>
                </CardContent>
            </Card>
            <Dialog
                open={openDialog}
                TransitionComponent={Transition}
                keepMounted
                fullWidth
                maxWidth={'xl'}
                style={{'justifyContent': 'center', 'height': '900px'}}
                onClose={handleDialogClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle style={{'display': 'flex', 'justifyContent': 'space-between', 'alignItems': 'center'}}><h2>Profile Report</h2><IconButton onClick={handleDialogClose}><CloseIcon fontSize='small'/></IconButton></DialogTitle>
                <DialogContent style={{'display': 'flex', 'justifyContent': 'center'}}>
                    {reportUrl && reportUrl !== undefined ? <>
                        <iframe src={reportUrl} style={{'width': '-webkit-fill-available', 'height': '900px'}}>
                        </iframe>
                    </>:<><CircularProgress /></>}
                </DialogContent>
            </Dialog>
            {/* <Dialog
                    open={openDupReportNum}
                    TransitionComponent={Transition}
                    keepMounted
                    fullWidth
                    maxWidth={'sm'}
                    style={{'justifyContent': 'center', 'height': '900px'}}
                    onClose={handleDupReportNumDialogClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle style={{'display': 'flex', 'justifyContent': 'space-between', 'alignItems': 'center'}}>{ missingReportNum ? <h2> Report Number missing </h2> : <h2> Duplicate Report Num </h2> } <IconButton onClick={handleDupReportNumDialogClose}><CloseIcon fontSize='small'/></IconButton></DialogTitle>
                    <DialogContent style={{'display': 'flex', 'justifyContent': 'center'}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography>The report number is missing. Please enter new report number.</Typography>
                            </Grid>
                            <Grid item xs={12}>
                            {!dupReportNumError ? <div><span><TextField style={{width:"100%"}} placeholder="Report #" value={dupReportNum} onChange = { (e) => setDupReportNum(e.target.value)}/></span></div> :
                            <div><span><TextField error style={{width:"100%"}} placeholder="Report #" helperText="Report number missing" value={dupReportNum} onChange = { (e) => setDupReportNum(e.target.value)}/></span></div>}
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={saveDupReportNum}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog> */}
            {/* <Dialog
                    open={autoSaveDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    maxWidth={'md'}
                    style={{'justifyContent': 'center', 'height': '900px'}}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle style={{'display': 'flex', 'justifyContent': 'space-between', 'alignItems': 'center'}}><h2 className={"underline"}> Auto Save Requirements </h2></DialogTitle>
                    <DialogContent>
                        <DialogContentText id="dialog-description">
                            <div className={"text-sm font-semibold mb-3 text-center"}>In order to enable auto save please fill out the following information</div>
                            <div className={"mb-2"}><TextField style={{width:"100%"}} label="Report #" inputProps={{ style: {textAlign: 'center'} }} value={report?.ReportNum} onChange = { (e) => handleValueChange("ProfileReport", "ReportNum", e.target.value )}/></div>
                            <div className={"text-sm font-semibold mb-3 text-center"}>At least one of the following Person details</div>
                            <div className={"mb-2"}><TextField style={{width:"100%"}} label={GetStringNameForSite(SiteName,"SBI #")} inputProps={{ style: {textAlign: 'center'}, maxLength: 12 }} value={report?.PersonObject?.SBI} onChange = { (e) => handleValueChange("PersonObject", "SBI", e.target.value )} /></div>
                            <div className={"mb-2"}><TextField style={{width:"100%"}} label="FBI" inputProps={{ style: {textAlign: 'center'}, maxLength: 12 }} value={report?.PersonObject?.FBI} onChange = { (e) => handleValueChange("PersonObject", "FBI", e.target.value )} /></div>
                            <div className={"grid grid-cols-3 gap-x-1"}>
                                <div>
                                    <TextField style={{width:"100%"}} label="Firstname" inputProps={{ style: {textAlign: 'center'} }} value={report?.PersonObject?.FirstName} onChange = { (e) => handleValueChange("PersonObject", "FirstName", e.target.value )}/>
                                </div>
                                <div>
                                    <TextField style={{width:"100%"}} label="Lastname" inputProps={{ style: {textAlign: 'center'} }} value={report?.PersonObject?.LastName} onChange = { (e) => handleValueChange("PersonObject", "LastName", e.target.value )}/>
                                </div>
                                <div>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="DOB"
                                            value={report?.PersonObject?.DOB}
                                            onChange={(e) => {handleValueChange("PersonObject", "DOB", e)}}
                                            renderInput={(params) => <TextField style={{width:"100%"}} type="date" label="DOB"  InputLabelProps={{ shrink: true }} {...params} />}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className={"mr-2 mb-2"}>
                        <Button variant={"outlined"} color="primary" onClick={() => handleAutoSave()}> Enable</Button>
                        <Button variant={"outlined"} color="error" onClick={() => setAutoSaveDialog(false)} autoFocus> Cancel </Button>
                    </DialogActions>

                </Dialog> */}
        </div>
    );
}

export default ProfileReportInputForm;

