import React, {useState} from "react";
import {useLocation} from "react-router-dom";
import GVRTFInputForm from "../../components/GRAIL/GVRTF_InputForm";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function GVRTF() {
    let query = useQuery();
    
    return (
        <div className="w-full">
            <GVRTFInputForm Hash={query.get("Hash")}/>
        </div>
    );
}

export default GVRTF;