import {
    Box,
    Button,
    ButtonGroup,
    Card,
    CardContent, Checkbox,
    Container,
    FormControl, FormControlLabel, InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import UserAttributes from "../../interfaces/UserAttributes.interface";
import { RunGetUser, RunSetUser } from "../../services/account.service";
import logo from '../../assets/Images/newbacktrace.png'
import ErrorIcon from '@mui/icons-material/Error';
import {isUserLoggedIn} from "../../services/auth.service";

interface NewUserProps {
    ID: string | null
}

const NewUser: React.FC<NewUserProps> = ({ ID }) => {
    const [params, setParams] = React.useState<UserAttributes>({})
    const [confPassword, setConfPassword] = React.useState<string>("")
    const [DOBError, setDOBError] = React.useState<string>("")
    const [submitError, setSubmitError] = React.useState<string>("")
    const [passwordError, setPasswordError] = React.useState<string>("")


    const LoadUserData = async (ID: string) => {
        let query = {
            "uniqueID": ID,
        }
        let r = await RunGetUser(query)
        if (r?.AnyData) {
            let p = r?.AnyData
            p.EditStatus = "PartialEdit"
            setParams(p)
        }

    }

    useEffect(() => {
        if (ID !== null)
            LoadUserData(ID)
    }, [ID]);

    const checkValidation = () => {
        let ret = true
        if (params?.DOB === "1900-01-01T00:00:00") {
            setDOBError("Please Input Your DOB!")
            ret = false
        }
        if (params?.Password?.length === 0 || confPassword?.length === 0) {
            setPasswordError("Please Input A Password!")
            ret = false
        }
        if (params?.Password !== confPassword) {
            setPasswordError("Passwords Do Not Match!")
            ret = false
        }
        return ret
    }

    const submitUser = async () => {
        setSubmitError("")
        setDOBError("")
        setPasswordError("")
        let p:any = { ...params }
        p.OldPassword = ""
        if (checkValidation()) {
            let r = await RunSetUser(p)
            if (r.AnyData.IsValid && r.AnyData.ErrorMessage?.length <= 0) {
                CloseBox()
            }
            else {
                let errors = r.AnyData.ErrorMessage?.join(",")
                setSubmitError(errors)
            }
        }
    }



    const CloseBox = () => {
        window.location.href = "/login"
    }

    return (
        <div className="min-w-screen min-h-screen bg-gray-800">
            <Container component="main">
                <Box
                    sx={{
                        marginTop: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}

                >
                    <Card sx={{
                        marginTop: 15,
                        boxShadow: 25,
                    }} >
                        <CardContent>
                            <div className = "flex justify-center">
                                {/*<img src={logo} height="100" width="300" alt="" />*/}
                            </div>
                            <div className="grid grid-cols-3 mt-5">
                                <div className="w-full">
                                    <TextField
                                        size="small"
                                        InputLabelProps={{ 'shrink': true }}
                                        className="w-full"
                                        margin='none'
                                        label="User First Name"
                                        value={params?.FirstName}
                                        onChange={e => setParams({ ...params, FirstName: e.target.value })}
                                    />
                                </div>
                                <div className="w-full pr-10 pl-10">
                                    <TextField
                                        size="small"
                                        InputLabelProps={{ 'shrink': true }}
                                        className="w-full"
                                        margin='none'
                                        label="User Middle Initial"
                                        value={params?.MiddleName}
                                        onChange={e => setParams({ ...params, MiddleName: e.target.value })}
                                    />
                                </div>
                                <div className="w-full">
                                    <TextField
                                        size="small"
                                        InputLabelProps={{ 'shrink': true }}
                                        className="w-full"
                                        margin='none'
                                        label="User Last Name"
                                        value={params?.LastName}
                                        onChange={e => setParams({ ...params, LastName: e.target.value })}
                                    />
                                </div>
                            </div>

                            <div className="grid grid-cols-1 mt-5">
                                <TextField
                                    size="small"
                                    InputLabelProps={{ 'shrink': true }}
                                    className="w-full"
                                    margin='none'
                                    label="EMail Address"
                                    value={params?.EmailID}
                                    onChange={e => setParams({ ...params, EmailID: e.target.value })}
                                />
                            </div>

                            <div className="grid grid-cols-3 gap-y-5 mt-5">

                                <div className="w-full pr-2">
                                    <TextField
                                        size="small"
                                        InputLabelProps={{ 'shrink': true }}
                                        className="w-full"
                                        margin='none'
                                        label="Cell Phone #"
                                        value={params?.CellPhone}
                                        onChange={e => setParams({ ...params, CellPhone: e.target.value })}
                                    />
                                </div>

                                <div className="w-full pr-1 pl-1">
                                    <TextField
                                        size="small"
                                        InputLabelProps={{ 'shrink': true }}
                                        className="w-full"
                                        margin='none'
                                        label="Work Phone #"
                                        value={params?.WorkPhone}
                                        onChange={e => setParams({ ...params, WorkPhone: e.target.value })}
                                    />
                                </div>
                                
                                <div className="w-full pl-2">
                                    <TextField
                                        size="small"
                                        type={"date"}
                                        InputLabelProps={{ 'shrink': true }}
                                        margin='none'
                                        className="w-full"
                                        label="DOB"
                                        color={DOBError.length > 0 ? "error" : "primary"}
                                        focused={DOBError.length > 0 ? true : undefined}
                                        autoFocus
                                        value={params?.DOB?.substring(0, 10)}
                                        onChange={e => setParams({ ...params, DOB: e.target.value })}
                                    />
                                    {DOBError.length > 0 ? <span style={{ color: "red", fontSize: 14 }}><ErrorIcon style={{ color: "red" }} fontSize='small' /> {DOBError}</span> : <></>}
                                </div>
                                
                            </div>

                            <div className="grid grid-cols-2 gap-y-5 mt-5">
                                <div className="w-full pr-5">
                                    <TextField
                                        size="small"
                                        InputLabelProps={{ 'shrink': true }}
                                        className="w-full"
                                        margin='none'
                                        label="New Password"
                                        type="password"
                                        color={passwordError.length > 0 ? "error" : "primary"}
                                        focused={passwordError.length > 0 ? true : undefined}
                                        value={params?.Password}
                                        onChange={e => setParams({ ...params, Password: e.target.value })}
                                    />
                                    {passwordError.length > 0 ? <span style={{ color: "red", fontSize: 14 }}><ErrorIcon style={{ color: "red" }} fontSize='small' /> {passwordError}</span> : <></>}
                                </div>

                                <div className="w-full pl-5">
                                    <TextField
                                        size="small"
                                        InputLabelProps={{ 'shrink': true }}
                                        className="w-full"
                                        margin='none'
                                        label="Confirm Password"
                                        type="password"
                                        color={passwordError.length > 0 ? "error" : "primary"}
                                        focused={passwordError.length > 0 ? true : undefined}
                                        value={confPassword}
                                        onChange={e => setConfPassword(e.target.value)}
                                    />
                                    {passwordError.length > 0 ? <span style={{ color: "red", fontSize: 14 }}><ErrorIcon style={{ color: "red" }} fontSize='small' /> {passwordError}</span> : <></>}
                                </div>

                            </div>

                            <div className="mt-8 flex justify-center">
                                <ButtonGroup variant="outlined" size={"large"} color={"inherit"} >
                                    <Button onClick={submitUser} >Save</Button>
                                    <Button onClick={CloseBox}>CANCEL</Button>
                                </ButtonGroup>
                            </div>

                            {submitError.length > 0 ? <span style={{ color: "red", fontSize: 14 }}><ErrorIcon style={{ color: "red" }} fontSize='small' /> {submitError}</span> : <></>}
                        </CardContent>
                    </Card>
                </Box>

            </Container>
        </div>
    );
}

export default NewUser;
