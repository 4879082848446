import './_PersonTimeLineVertical.scss'
import React from "react";
import {formatDate, formatDT} from "../../services/formatDate.service";
import {Card, CardContent} from "@mui/material";



interface PersonTimeLineVerticalProps {
    data: any,
}


const  _PersonTimeLineVertical: React.FC<PersonTimeLineVerticalProps> = ({data})=> {

    const createTooltip = (item: any, enableShadow: boolean = false) => {
        const node = item
        const sx: any = {maxWidth: 400, m: 'auto', mb: 1}
        const classNameStr: string = "border-2 border-blue-900 rounded-lg"
        const boxShadow: any = enableShadow? {boxShadow: '5px 5px 5px 3px lightgray'}: {height:'100%'};
        if (node) {
            if (node?.d?.type) {
                switch (node.d.type) {
                    case("PERSON_NODE"):
                        return (
                            <div>
                                <Card className={classNameStr} sx={sx} style={boxShadow}>
                                    <CardContent>
                                        <div><span className="font-bold">First Name: </span> {node?.d?.data?.FName}</div>
                                        <div><span className="font-bold">Middle Name: </span> {node?.d?.data?.MName}</div>
                                        <div><span className="font-bold">Last Name: </span> {node?.d?.data?.LName}</div>
                                        <div><span className="font-bold">DOB: </span> {formatDate(node?.d?.data?.DOB)} </div>
                                        <div><span className="font-bold">SEX: </span> {node?.d?.data?.SEX} </div>
                                        <div><span className="font-bold">ADDRESS: </span> {node?.d?.data?.Address}</div>
                                        <div><span className="font-bold">CITY: </span> {node?.d?.data?.City} </div>
                                        <div><span className="font-bold">STATE: </span> {node?.d?.data?.State} </div>
                                        <div><span className="font-bold">FBI: </span> {node?.d?.data?.FBI} </div>
                                        <div><span className="font-bold">SBI: </span> {node?.d?.data?.SBINo} </div>
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("ARREST_NODE"):
                        return (
                            <div>
                                <Card className={classNameStr} sx={sx} style={boxShadow}>
                                    <CardContent>
                                        <div><span
                                            className="font-bold">DATE OF ARREST: </span> {formatDT(node?.d?.data?.DTOFARREST)}
                                        </div>
                                        <div><span
                                            className="font-bold">ORIGINATING AGENCY : </span> {node?.d?.data?.ORIGINATINGORINAME}
                                        </div>
                                        <div><span
                                            className="font-bold">ARREST TYPE: </span> {node?.d?.data?.ARREST_TYPE}
                                        </div>
                                        <div><span className="font-bold">STATUTE: </span> {node?.d?.data?.STATUTE}</div>
                                        <div><span
                                            className="font-bold">STATUTE DESCRIPTION: </span> {node?.d?.data?.STATUTEDESCRIPTION}
                                        </div>
                                        <div><span
                                            className="font-bold">Incident Address: </span> {node?.d?.data?.IncidentAddress}
                                        </div>
                                        <div><span
                                            className="font-bold">Incident City: </span> {node?.d?.data?.IncidentCity}
                                        </div>
                                        <div><span
                                            className="font-bold">Incident State: </span> {node?.d?.data?.IncidentState}
                                        </div>
                                        <div><span
                                            className="font-bold">Incident Zip: </span> {node?.d?.data?.IncidentZip}
                                        </div>
                                        <div><span
                                            className="font-bold">Agency Case No: </span> {node?.d?.data?.AgencyCaseNo}
                                        </div>
                                        <div><span
                                            className="font-bold">Resident County: </span> {node?.d?.data?.ResidentCounty}
                                        </div>
                                        <div><span
                                            className="font-bold">Resident City: </span> {node?.d?.data?.ResidentCity}
                                        </div>
                                        <div><span
                                            className="font-bold">Arrested County: </span> {node?.d?.data?.ArrestedCounty}
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("ALPR_NODE"):
                        return (
                            <div>
                                <Card className={classNameStr} sx={sx} style={boxShadow}>
                                    <CardContent>
                                        <div><span className="font-bold">PLATE: </span> {node?.d?.data?.red_vrm} </div>
                                        <div><span
                                            className="font-bold">TIMESTAMP: </span> {formatDT(node?.d?.data?.red_TimeStamp)}
                                        </div>
                                        <div><span className="font-bold">Latitude: </span> {node?.d?.data?.Latitude}
                                        </div>
                                        <div><span className="font-bold">Longitude: </span> {node?.d?.data?.Longitude}
                                        </div>
                                        <div><span className="font-bold">Site Name: </span> {node?.d?.data?.ste_Name}
                                        </div>
                                        <div><span className="font-bold">Source Name: </span> {node?.d?.data?.src_Name}
                                        </div>
                                        <div><span
                                            className="font-bold">Source Description: </span> {node?.d?.data?.src_Description}
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("ASSOCPEOPLE_NODE"):
                        return (
                            <div>
                                <Card className={classNameStr} sx={sx} style={boxShadow}>
                                    <CardContent>
                                        <div><span
                                            className="font-bold">Type: </span> {node?.d?.data?.AType ? node?.d?.data?.AType : node?.d?.data?.ROLE}
                                        </div>
                                        <div><span className="font-bold">First Name: </span> {node?.d?.data?.FName}
                                        </div>
                                        <div><span className="font-bold">Middle Name: </span> {node?.d?.data?.MName}
                                        </div>
                                        <div><span className="font-bold">Last Name: </span> {node?.d?.data?.LName}</div>
                                        <div><span className="font-bold">DOB: </span> {formatDT(node?.d?.data?.DOB)}
                                        </div>
                                        <div><span className="font-bold">SEX: </span> {node?.d?.data?.SEX} </div>
                                        <div><span className="font-bold">ADDRESS: </span> {node?.d?.data?.Address}</div>
                                        <div><span className="font-bold">CITY: </span> {node?.d?.data?.City} </div>
                                        <div><span className="font-bold">STATE: </span> {node?.d?.data?.State} </div>
                                        <div><span className="font-bold">FBI: </span> {node?.d?.data?.FBI} </div>
                                        <div><span className="font-bold">SBI: </span> {node?.d?.data?.SBINo} </div>
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("CADRMS_NODE"):
                        return (
                            <div>
                                <Card className={classNameStr} sx={sx} style={boxShadow}>
                                    <CardContent>
                                        <div><span
                                            className="font-bold">Agency Case: </span> {node?.d?.data?.AgencyCaseNo}
                                        </div>
                                        <div><span
                                            className="font-bold">Timestamp:: </span> {formatDT(node?.d?.data?.DateTimeIn)}
                                        </div>
                                        <div><span
                                            className="font-bold">Case Status: </span> {node?.d?.data?.CaseStatus}</div>
                                        <div><span className="font-bold">STATUTE: </span> {node?.d?.data?.STATUTE}</div>
                                        <div><span
                                            className="font-bold">STATUTE DESCRIPTION: </span> {node?.d?.data?.STATUTEDESCRIPTION}
                                        </div>
                                        <div><span className="font-bold">City: </span> {node?.d?.data?.City} </div>
                                        <div><span
                                            className="font-bold">Incident Address: </span> {node?.d?.data?.IncidentAddress}
                                        </div>
                                        <div><span className="font-bold">STATE: </span> {node?.d?.data?.ST} </div>
                                        <div><span className="font-bold">ZIP: </span> {node?.d?.data?.ZIP} </div>
                                        <div><span className="font-bold">NIBRS: </span> {node?.d?.data?.NIBRSDesc}</div>
                                        <div><span className="font-bold">Role: </span> {node?.d?.data?.RoleDesc} </div>
                                        <div><span className="font-bold">Latitude: </span> {node?.d?.data?.Latitude}
                                        </div>
                                        <div><span className="font-bold">Longitude: </span> {node?.d?.data?.Longitude}
                                        </div>
                                    </CardContent>

                                </Card>
                            </div>
                        )
                        break;
                    case("DNA_NODE"):
                        return (
                            <div>
                                <Card className={classNameStr} sx={sx} style={boxShadow}>
                                    <CardContent>
                                        <div><span className="font-bold">Agency: </span> {node?.d?.data?.Agency} </div>
                                        <div><span
                                            className="font-bold">OffenseDate: </span> {formatDT(node?.d?.data?.OffenseDate)}
                                        </div>
                                        <div><span className="font-bold">Offense: </span> {node?.d?.data?.Offense}</div>
                                        <div><span className="font-bold">CaseNumber: </span> {node?.d?.data?.CaseNumber}
                                        </div>
                                        <div><span className="font-bold">Profile: </span> {node?.d?.data?.Profile}</div>
                                        <div><span className="font-bold">HitNumber: </span> {node?.d?.data?.HitNumber}
                                        </div>
                                        <div><span className="font-bold">County: </span> {node?.d?.data?.County} </div>
                                        <div><span className="font-bold">Region: </span> {node?.d?.data?.Region} </div>
                                        <div><span
                                            className="font-bold">Lab Specimen ID: </span> {node?.d?.data?.LabSpecimenID}
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("ETICKET_NODE"):
                        return (
                            <div>
                                <Card className={classNameStr} sx={sx} style={boxShadow}>
                                    <CardContent>
                                        <div><span
                                            className="font-bold">Issued: </span> {formatDT(node?.d?.data?.eCreated)}
                                        </div>
                                        <div><span
                                            className="font-bold">Municipality: </span> {node?.d?.data?.eMunicipality}
                                        </div>
                                        <div><span className="font-bold">Offense: </span> {node?.d?.data?.eOffenseStat}
                                        </div>
                                        <div><span
                                            className="font-bold">Violation: </span> {node?.d?.data?.eViolationDesc}
                                        </div>
                                        <div><span className="font-bold">Plate: </span> {node?.d?.data?.ePlateNbr}</div>
                                        <div><span className="font-bold">Plate St: </span> {node?.d?.data?.ePlateSt}
                                        </div>
                                        <div><span className="font-bold">DL: </span> {node?.d?.data?.eDefDL} </div>
                                        <div><span className="font-bold">DL St: </span> {node?.d?.data?.eDefDLSt} </div>
                                        <div><span className="font-bold">First Name: </span> {node?.d?.data?.eDefFName}
                                        </div>
                                        <div><span className="font-bold">Middle Name: </span> {node?.d?.data?.eDefMI}
                                        </div>
                                        <div><span className="font-bold">Last Name: </span> {node?.d?.data?.eDefLName}
                                        </div>
                                        <div><span className="font-bold">Ticket #: </span> {node?.d?.data?.TicketNmbr}
                                        </div>
                                        <div><span className="font-bold">Latitude: </span> {node?.d?.data?.Latitude}
                                        </div>
                                        <div><span className="font-bold">Longitude: </span> {node?.d?.data?.Longitude}
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("ETRACE_NODE"):
                        return (
                            <div>
                                <Card className={classNameStr} sx={sx} style={boxShadow}>
                                    <CardContent>
                                        <div><span
                                            className="font-bold">Agency Case No: </span> {node?.d?.data?.AgencyCaseNo}
                                        </div>
                                        <div><span
                                            className="font-bold">MANUFACTURER NAME: </span> {node?.d?.data?.MANUFACTURER_NAME}
                                        </div>
                                        <div><span className="font-bold">MODEL: </span> {node?.d?.data?.MODEL} </div>
                                        <div><span className="font-bold">CALIBER: </span> {node?.d?.data?.CALIBER}</div>
                                        <div><span
                                            className="font-bold">SERIAL NUMBER: </span> {node?.d?.data?.SERIAL_NUMBER}
                                        </div>
                                        <div><span
                                            className="font-bold">DESCRIPTION: </span> {node?.d?.data?.W_TYPE_DESC}
                                        </div>
                                        <div><span className="font-bold">City: </span> {node?.d?.data?.City} </div>
                                        <div><span
                                            className="font-bold">Incident Address: </span> {node?.d?.data?.IncidentAddress}
                                        </div>
                                        <div><span className="font-bold">ST: </span> {node?.d?.data?.ST} </div>
                                        <div><span className="font-bold">ZIP: </span> {node?.d?.data?.ZIP} </div>
                                        {/*
                                        <div><span className="font-bold">Role: </span> {node?.d?.data?.RoleDesc} </div>
*/}
                                        <div><span className="font-bold">Latitude: </span> {node?.d?.data?.Latitude}
                                        </div>
                                        <div><span className="font-bold">Longitude: </span> {node?.d?.data?.Longitude}
                                        </div>
                                        <div><span className="font-bold">ATF #: </span> {node?.d?.data?.ATFNo} </div>
                                        <div><span
                                            className="font-bold">Crime History: </span> {node?.d?.data?.CrimeHistory}
                                        </div>
                                        <div><span className="font-bold">Dealer #: </span> {node?.d?.data?.DealerNo}
                                        </div>
                                        <div><span
                                            className="font-bold">Dealer Name: </span> {node?.d?.data?.DealerName}</div>
                                        {/* <div><span
                                            className="font-bold">Purchase Date: </span> {node?.d?.data?.PurchaseDate}
                                        </div>
                                        <div><span
                                            className="font-bold">Purchaser First Name: </span> {node?.d?.data?.P_FIRSTNAME}
                                        </div>
                                        <div><span
                                            className="font-bold">Purchaser Middle Name: </span> {node?.d?.data?.P_MI}
                                        </div>
                                        <div><span
                                            className="font-bold">Purchaser Last Name: </span> {node?.d?.data?.P_LASTNAME}
                                        </div>
                                        <div><span className="font-bold">Purchaser POB: </span> {node?.d?.data?.P_POB}
                                        </div>
                                        <div><span
                                            className="font-bold">Purchaser CrimeHistory: </span> {node?.d?.data?.P_CrimeHistory}
                                        </div>*/}
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("NIBIN_NODE"):
                        return (
                            <div>
                                <Card className={classNameStr} sx={sx} style={boxShadow}>
                                    <CardContent>
                                        <div><span className="font-bold">Agency: </span> {node?.d?.data?.Agency} </div>
                                        <div><span
                                            className="font-bold">OffenseDate: </span> {formatDT(node?.d?.data?.OffenseDate)}
                                        </div>
                                        <div><span className="font-bold">Offense: </span> {node?.d?.data?.Offense}</div>
                                        <div><span className="font-bold">CaseNumber: </span> {node?.d?.data?.CaseNumber}
                                        </div>
                                        <div><span className="font-bold">Profile: </span> {node?.d?.data?.Profile}</div>
                                        <div><span className="font-bold">NIBINNo: </span> {node?.d?.data?.NIBINNo}</div>
                                        <div><span className="font-bold">HitNumber: </span> {node?.d?.data?.HitNumber}
                                        </div>
                                        <div><span className="font-bold">County: </span> {node?.d?.data?.County} </div>
                                        <div><span className="font-bold">City: </span> {node?.d?.data?.City} </div>
                                        <div><span
                                            className="font-bold">IncidentAddress: </span> {node?.d?.data?.IncidentAddress}
                                        </div>
                                        <div><span className="font-bold">ST: </span> {node?.d?.data?.ST} </div>
                                        <div><span className="font-bold">ZIP: </span> {node?.d?.data?.ZIP} </div>
                                        <div><span className="font-bold">Latitude: </span> {node?.d?.data?.Latitude}
                                        </div>
                                        <div><span className="font-bold">Longitude: </span> {node?.d?.data?.Longitude}
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("NJPOP_NODE"):
                        return (
                            <div>
                                <Card className={classNameStr} sx={sx} style={boxShadow}>
                                    <CardContent>
                                        <div><span className="font-bold">Dept: </span> {node?.d?.data?.Dept} </div>
                                        <div><span
                                            className="font-bold">Agency Case No: </span> {node?.d?.data?.AgencyCaseNo}
                                        </div>
                                        <div><span
                                            className="font-bold">Timestamp: </span> {formatDT(node?.d?.data?.DateTimeIn)}
                                        </div>
                                        <div><span
                                            className="font-bold">Case Status: </span> {node?.d?.data?.CaseStatus}</div>
                                        <div><span className="font-bold">City: </span> {node?.d?.data?.City} </div>
                                        <div><span
                                            className="font-bold">Incident Address: </span> {node?.d?.data?.IncidentAddress}
                                        </div>
                                        <div><span className="font-bold">ST: </span> {node?.d?.data?.ST} </div>
                                        <div><span className="font-bold">ZIP: </span> {node?.d?.data?.ZIP} </div>
                                        <div><span className="font-bold">Role: </span> {node?.d?.data?.RoleDesc} </div>
                                        <div><span className="font-bold">Latitude: </span> {node?.d?.data?.Latitude}
                                        </div>
                                        <div><span className="font-bold">Longitude: </span> {node?.d?.data?.Longitude}
                                        </div>
                                        <div><span
                                            className="font-bold">Incident ID: </span> {node?.d?.data?.IncidentID}</div>
                                        <div><span
                                            className="font-bold">Incident Type: </span> {node?.d?.data?.IncidentType}
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("NJTRACE_NODE"):
                        return (
                            <div>
                                <Card className={classNameStr} sx={sx} style={boxShadow}>
                                    <CardContent>
                                        <div><span
                                            className="font-bold">Agency Case No: </span> {node?.d?.data?.AgencyCaseNo}
                                        </div>
                                        <div><span
                                            className="font-bold">MANUFACTURER NAME: </span> {node?.d?.data?.MANUFACTURER_NAME}
                                        </div>
                                        <div><span className="font-bold">MODEL: </span> {node?.d?.data?.MODEL} </div>
                                        <div><span className="font-bold">CALIBER: </span> {node?.d?.data?.CALIBER}</div>
                                        <div><span
                                            className="font-bold">SERIAL NUMBER: </span> {node?.d?.data?.SERIAL_NUMBER}
                                        </div>
                                        <div><span
                                            className="font-bold">DESCRIPTION: </span> {node?.d?.data?.W_TYPE_DESC}
                                        </div>
                                        <div><span className="font-bold">City: </span> {node?.d?.data?.City} </div>
                                        <div><span
                                            className="font-bold">Incident Address: </span> {node?.d?.data?.IncidentAddress}
                                        </div>
                                        <div><span className="font-bold">ST: </span> {node?.d?.data?.ST} </div>
                                        <div><span className="font-bold">ZIP: </span> {node?.d?.data?.ZIP} </div>
                                        {/*
                                        <div><span className="font-bold">Role: </span> {node?.d?.data?.RoleDesc} </div>
*/}
                                        <div><span className="font-bold">Latitude: </span> {node?.d?.data?.Latitude}
                                        </div>
                                        <div><span className="font-bold">Longitude: </span> {node?.d?.data?.Longitude}
                                        </div>
                                        <div><span className="font-bold">ATF #: </span> {node?.d?.data?.ATFNo} </div>
                                        <div><span
                                            className="font-bold">Crime History: </span> {node?.d?.data?.CrimeHistory}
                                        </div>
                                        <div><span className="font-bold">Dealer #: </span> {node?.d?.data?.DealerNo}
                                        </div>
                                        <div><span
                                            className="font-bold">Dealer Name: </span> {node?.d?.data?.DealerName}</div>
                                        {/*<div><span
                                            className="font-bold">Purchase Date: </span> {formatDT(node?.d?.data?.PurchaseDate)}
                                        </div>
                                        <div><span
                                            className="font-bold">Purchaser First Name: </span> {node?.d?.data?.P_FIRSTNAME}
                                        </div>
                                        <div><span
                                            className="font-bold">Purchaser Middle Name: </span> {node?.d?.data?.P_MI}
                                        </div>
                                        <div><span
                                            className="font-bold">Purchaser Last Name: </span> {node?.d?.data?.P_LASTNAME}
                                        </div>
                                        <div><span className="font-bold">Purchaser POB: </span> {node?.d?.data?.P_POB}
                                        </div>
                                        <div><span
                                            className="font-bold">Purchaser CrimeHistory: </span> {node?.d?.data?.P_CrimeHistory}
                                        </div>*/}
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("PARKMOBILE_NODE"):
                        return (
                            <div>
                                <Card className={classNameStr} sx={sx} style={boxShadow}>
                                    <CardContent>
                                        <div><span className="font-bold">ZONE: </span> {node?.d?.data?.zoneid} </div>
                                        <div><span className="font-bold">PLATE: </span> {node?.d?.data?.plate} </div>
                                        <div><span className="font-bold">PLATE ST: </span> {node?.d?.data?.platestate}
                                        </div>
                                        <div><span
                                            className="font-bold">START: </span> {formatDT(node?.d?.data?.startdatetime)}
                                        </div>
                                        <div><span
                                            className="font-bold">END: </span> {formatDT(node?.d?.data?.enddatetime)}
                                        </div>
                                        <div><span className="font-bold">Latitude: </span> {node?.d?.data?.Latitude}
                                        </div>
                                        <div><span className="font-bold">Longitude: </span> {node?.d?.data?.Longitude}
                                        </div>
                                        <div><span
                                            className="font-bold">Agency Name: </span> {node?.d?.data?.agencyshortname}
                                        </div>

                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("PAROLE_NODE"):
                        return (
                            <div>
                                <Card className={classNameStr} sx={sx} style={boxShadow}>
                                    <CardContent>
                                        <div><span
                                            className="font-bold">Start Date: </span> {formatDT(node?.d?.data?.Start_Date)}
                                        </div>
                                        <div><span
                                            className="font-bold">End Date: </span> {formatDT(node?.d?.data?.End_Date)}
                                        </div>
                                        <div><span className="font-bold">STATUS: </span> {node?.d?.data?.STATUS} </div>
                                        <div><span className="font-bold">STATUTE: </span> {node?.d?.data?.STATUTE}</div>
                                        <div><span
                                            className="font-bold">STATUTE DESCRIPTION: </span> {node?.d?.data?.STATUTEDESCRIPTION}
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("PRISON_NODE"):
                        return (
                            <div>
                                <Card className={classNameStr} sx={sx} style={boxShadow}>
                                    <CardContent>
                                        <div><span className="font-bold">BOOKING NO: </span> {node?.d?.data?.BOOKING_NO}
                                        </div>
                                        <div><span
                                            className="font-bold">OFFENDER BOOK ID: </span> {node?.d?.data?.OFFENDER_BOOK_ID}
                                        </div>
                                        <div><span className="font-bold">CASE LOAD: </span> {node?.d?.data?.CASELOAD}
                                        </div>
                                        <div><span
                                            className="font-bold">RELEASE DATE: </span> {formatDT(node?.d?.data?.RELEASE_DATE)}
                                        </div>
                                        <div><span
                                            className="font-bold">MOVEMENT REASON CODE: </span> {node?.d?.data?.MOVEMENT_REASON_CODE}
                                        </div>
                                        <div><span className="font-bold">Parole: </span> {node?.d?.data?.Parole_supr}
                                        </div>
                                        <div><span className="font-bold">STATUS: </span> {node?.d?.data?.STATUS} </div>
                                        <div><span className="font-bold">MAXTERM: </span> {node?.d?.data?.MAXTERM}</div>
                                        <div><span
                                            className="font-bold">CONVICTION County: </span> {node?.d?.data?.CONVICTION_County}
                                        </div>
                                        <div><span
                                            className="font-bold">Admission Date: </span> {formatDT(node?.d?.data?.Admission_Date)}
                                        </div>
                                        <div><span className="font-bold">STATUTE: </span> {node?.d?.data?.STATUTE}</div>
                                        <div><span
                                            className="font-bold">STATUTE DESCRIPTION: </span> {node?.d?.data?.STATUTEDESCRIPTION}
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("PROFESSION_NODE"):
                        return (
                            <div>
                                <Card className={classNameStr} sx={sx} style={boxShadow}>
                                    <CardContent>
                                        <div><span
                                            className="font-bold">Issue Date: </span> {formatDT(node?.d?.data?.IssueDate)}
                                        </div>
                                        <div><span
                                            className="font-bold">Expiration Date: </span> {formatDT(node?.d?.data?.ExpirationDate)}
                                        </div>
                                        <div><span
                                            className="font-bold">Profession Name: </span> {node?.d?.data?.ProfessionName}
                                        </div>
                                        <div><span className="font-bold">License #: </span> {node?.d?.data?.LicenseNo}
                                        </div>
                                        <div><span
                                            className="font-bold">License Name: </span> {node?.d?.data?.LicenseName}
                                        </div>
                                        <div><span className="font-bold">Status: </span> {node?.d?.data?.Status} </div>
                                        <div><span className="font-bold">Street #: </span> {node?.d?.data?.StreetNo}
                                        </div>
                                        <div><span
                                            className="font-bold">Street Name: </span> {node?.d?.data?.StreetName}</div>
                                        <div><span className="font-bold">City: </span> {node?.d?.data?.City} </div>
                                        <div><span className="font-bold">State: </span> {node?.d?.data?.State} </div>
                                        <div><span className="font-bold">Zip: </span> {node?.d?.data?.Zip} </div>
                                        <div><span className="font-bold">Latitude: </span> {node?.d?.data?.Latitude}
                                        </div>
                                        <div><span className="font-bold">Longitude: </span> {node?.d?.data?.Longitude}
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("VIOLENT_NODE"):
                        return (
                            <div>
                                <Card className={classNameStr} sx={sx} style={boxShadow}>
                                    <CardContent>
                                        <div><span
                                            className="font-bold">Start Date: </span> {formatDT(node?.d?.data?.Start_Date)}
                                        </div>
                                        <div><span
                                            className="font-bold">Validation Date: </span> {formatDT(node?.d?.data?.ValidationDate)}
                                        </div>
                                        <div><span className="font-bold">Case #: </span> {node?.d?.data?.CaseNo} </div>
                                        <div><span className="font-bold">Dept ORI: </span> {node?.d?.data?.DeptORI}
                                        </div>
                                        <div><span className="font-bold">Criteria: </span> {node?.d?.data?.Criteria}
                                        </div>
                                        <div><span className="font-bold">Notes: </span> {node?.d?.data?.Notes} </div>
                                        <div><span
                                            className="font-bold">Medical Condition: </span> {node?.d?.data?.MedicalCondition}
                                        </div>
                                        <div><span
                                            className="font-bold">CODIS Notes: </span> {node?.d?.data?.CODIS_Notes}
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("INFOCOP_NODE"):
                        return (
                            <div>
                                <Card className={classNameStr} sx={sx} style={boxShadow}>
                                    <CardContent>
                                        <div><span
                                            className="font-bold">Plate Number: </span> {node?.d?.data?.PlateNumber}
                                        </div>
                                        <div><span className="font-bold">Plate St: </span> {node?.d?.data?.PlateSt}
                                        </div>
                                        <div><span className="font-bold">DL No: </span> {node?.d?.data?.DLNo} </div>
                                        <div><span className="font-bold">DL St: </span> {node?.d?.data?.DLSt} </div>
                                        <div><span
                                            className="font-bold">Timestamp: </span> {formatDT(node?.d?.data?.DateTimeIN)}
                                        </div>
                                        <div><span className="font-bold">Dept Name: </span> {node?.d?.data?.DeptName}
                                        </div>
                                        <div><span className="font-bold">Latitude: </span> {node?.d?.data?.Latitude}
                                        </div>
                                        <div><span className="font-bold">Longitude: </span> {node?.d?.data?.Longitude}
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("PLATE_NODE"):
                        return (
                            <div>
                                <Card className={classNameStr} sx={sx} style={boxShadow}>
                                    <CardContent>
                                        <div><span className="font-bold">Plate Number: </span> {node?.d?.data?.PlateNo}
                                        </div>
                                        <div><span className="font-bold">Plate St: </span> {node?.d?.data?.PlateSt}
                                        </div>
                                        <div><span className="font-bold">Source: </span> {node?.d?.data?.Source} </div>
                                        <div><span
                                            className="font-bold">Start Date: </span> {formatDT(node?.d?.data?.StartDate)}
                                        </div>
                                        <div><span
                                            className="font-bold">End Date: </span> {formatDT(node?.d?.data?.EndDate)}
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("FFL_INFO_CONTAINER"):
                        return (
                            <div>
                                <Card className={classNameStr} sx={sx} style={boxShadow}>
                                    <CardContent>
                                        <div><span className="font-bold">FFL #: </span> {node?.d?.data?.FFL_Number}
                                        </div>
                                        <div><span className="font-bold">NAME: </span> {node?.d?.data?.FFL_NAME} </div>
                                        <div><span className="font-bold">STREET: </span> {node?.d?.data?.FFL_STREET}
                                        </div>
                                        <div><span className="font-bold">CITY: </span> {node?.d?.data?.FFL_CITY} </div>
                                        <div><span className="font-bold">COUNTY: </span> {node?.d?.data?.FFL_COUNTY}
                                        </div>
                                        <div><span className="font-bold">STATE: </span> {node?.d?.data?.FFL_STATE}</div>
                                        <div><span className="font-bold">ZIP: </span> {node?.d?.data?.FFL_ZIP} </div>
                                        <div><span className="font-bold">COUNTRY: </span> {node?.d?.data?.FFL_COUNTRY}
                                        </div>
                                        <div><span className="font-bold">PHONE: </span> {node?.d?.data?.FFL_PHONE}</div>
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;

                    case("WEAPON_ETRACE_FFL_NODE"):
                        return (
                            <div>
                                <Card className={classNameStr} sx={sx} style={boxShadow}>
                                    <CardContent>
                                        <div><span className="font-bold">FFL #: </span> {node?.d?.data?.FFL_Number}
                                        </div>
                                        <div><span className="font-bold">MAKE: </span> {node?.d?.data?.MAN_CODE} </div>
                                        <div><span className="font-bold">CALIBER: </span> {node?.d?.data?.WPN_CBL}
                                        </div>
                                        <div><span className="font-bold">SERIAL: </span> {node?.d?.data?.WPN_SN} </div>
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;

                    case("NIBIN_INCIDENT"):
                        return (
                            <div>
                                <Card className={classNameStr} sx={sx} style={boxShadow}>
                                    <CardContent>
                                        {node.d.data.Inc_Location &&
                                            <div><span className="font-bold">Address:</span> {node.d.data.Inc_Location}
                                            </div>}
                                        {node.d.data.OccurrenceDate &&
                                            <div><span
                                                className="font-bold">Date-Time:</span> {formatDT(node.d.data.OccurrenceDate)}
                                            </div>}
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;

                    case("NIBIN_WEAPON"):
                        return (
                            <div>
                                <Card className={classNameStr} sx={sx} style={boxShadow}>
                                    <CardContent>
                                        {node.d.data.CALIBER &&
                                            <div><span className="font-bold">Caliber:</span> {node.d.data.CALIBER}
                                            </div>}
                                        {node.d.data.MAKE &&
                                            <div><span className="font-bold">Make:</span> {node.d.data.MAKE}</div>}
                                        {node.d.data.W_CLASS &&
                                            <div><span className="font-bold">Class:</span> {node.d.data.W_CLASS}</div>}
                                        {node.d.data.MODEL &&
                                            <div><span className="font-bold">Model:</span> {node.d.data.MODEL}</div>}
                                        {node.d.data.SERIAL_NUMBER &&
                                            <div><span
                                                className="font-bold">Serial Number:</span> {node.d.data.SERIAL_NUMBER}
                                            </div>}
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("NIBIN_BALLISTIC"):
                        return (
                            <div>
                                <Card className={classNameStr} sx={sx} style={boxShadow}>
                                    <CardContent>
                                        <div><span
                                            className="font-bold">Ballistic: </span> ({node?.d?.data?.Item_Quantity}) {node.t}
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;

                    default:
                        return (<></>)
                        break;
                }
            }

        } else {
            return (<></>)
        }
    }

    const getNodeType = (nodeType: string) => {
        switch (nodeType) {
            case("PERSON_NODE"):
                return ("PERSON")
                break;
            case("ARREST_NODE"):
                return ("ARREST")
                break;
            case("ASSOCPEOPLE_NODE"):
                return ("ASSOCIATED PEOPLE")
                break;
            case("CADRMS_NODE"):
                return ("CADRMS")
                break;
            case("DNA_NODE"):
                return ("CODIS")
                break;
            case("ETICKET_NODE"):
                return ("ETICKET")
                break;
            case("NJPOP_NODE"):
                return ("NJPOP")
                break;
            case("NJTRACE_NODE"):
                return ("NJTRACE")
                break;
            case("PARKMOBILE_NODE"):
                return ("PARKMOBILE")
                break;
            case("PAROLE_NODE"):
                return ("PAROLE")
                break;
            case("PRISON_NODE"):
                return ("PRISON")
                break;
            case("PROFESSION_NODE"):
                return ("PROFESSION")
                break;
            case("VIOLENT_NODE"):
                return ("VIOLENT OFFENDER")
                break;
            case("INFOCOP_NODE"):
                return ("INFOCOP")
                break;
            case("PLATE_NODE"):
            case("ALPR_NODE"):
                return ("ALPR")
                break;
            case("FFL_INFO_CONTAINER"):
            case("WEAPON_ETRACE_FFL_NODE"):
            case("ETRACE_NODE"):
                return ("ETRACE")
                break;
            case("NIBIN_NODE"):
            case("NIBIN_INCIDENT"):
            case("NIBIN_WEAPON"):
            case("NIBIN_BALLISTIC"):
                return ("NIBIN")
                break;

            default:
                return (<></>)
                break;
        }
    }


    return(
        <>
            <div style={{width:'80%', margin:'auto'}} className="items-center sm:flex">
                <div className="steps-list-looped">
                    {data?.map((node : any, index : any) => (
                        <div className="step-wrapper" key={index}>
                            <div className="step-content">
                                <h2 className="step-number">
                                    {node?.u ? <img src={node?.u} alt={""} width={50}/> : <></> }
                                </h2>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default _PersonTimeLineVertical;