import { Portal } from "@headlessui/react";
import {
  Alert,
  Box,
  breadcrumbsClasses,
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import React from "react";
import {
  DropDown,
} from "../../../interfaces/NJPORT/InvestigationLog.interface";
import {
  PDOHireObject,
  PdoHirePoolResponse,
  PdoHireQuery,
} from "../../../interfaces/NJPORT/PDOHire.interface";
import {
  getPdoHirePool,
  InvestigationDropDown,
} from "../../../services/njport.service";

interface InputFormProps {
  handleReportSubmit: any;
  editReport: PDOHireObject | null;
  isSubData: boolean;
}

var activePort: string | null | undefined;
var activeTerminal: string | null | undefined;

const PdoHireInputForm: React.FC<InputFormProps> = ({
  handleReportSubmit,
  editReport,
  isSubData,
}) => {
  //console.log({ editReport });
  const [report, setReport] = React.useState<PDOHireObject>(
    editReport ? editReport : {},
  );
  const [jobTitleDD, setJobTitleDD] = React.useState<DropDown[]>([]);
  const [defaultstatus, setDefaultStatus] = React.useState<any>("");
  const [shipTerminalDD, setShipTerminalDD] = React.useState<DropDown[]>([]);
  const [locationDD, setLocationDD] = React.useState<DropDown[]>([
    {
      Group: null,
      Key: "NJ",
      Value: "NewJersey",
      Additional1: "",
      Additional2: "",
      Additional3: null,
    },
    {
      Group: null,
      Key: "NYC",
      Value: "NYC",
      Additional1: "",
      Additional2: "",
      Additional3: null,
    },
  ]);
  const [portDD, setPortDD] = React.useState<DropDown[]>([]);
  const [pacCardDD, setPacCardDD] = React.useState<DropDown[]>([]);
  const [alertOpen, setAlertOpen] = React.useState<boolean>(false);
  const [alertText, setAlertText] = React.useState<String>("");
  const [alertType, setAlertType] = React.useState<any>("success");
  const [defaultjobTilte, setDefaultjobTilte] = React.useState<string>("");
  const [defaultPort, setDefaultPort] = React.useState<string>("");
  const [poolbegining, setDefaultPoolBegining] = React.useState<number>(editReport?.PoolBiginning!);
  const [remainingPool, setDefaultRemainingPool] = React.useState<number>(0);
  const [defaultTerminal, setDefaultTerminal] = React.useState<any>("");
  const [poolflag, setPoolflag] = React.useState<boolean>(isSubData);

  React.useEffect(() => {
    jobTitleDropdown();
    // pacCardDropdown();
    loadStartPool();
    
    if (editReport) {
        setReport(editReport);
        activePort = editReport?.Port;
        if (activePort && activePort !== undefined) {
            terminalDropDown(activePort);
        }
        activeTerminal = editReport?.ShipTerminal;
    }
    if(isSubData && editReport && editReport.Job_Title){
        portLocationDropDown(editReport.Job_Title);
    }
    defaultjobTilteDD(jobTitleDD);
    defaultjobTilteDD(shipTerminalDD);
    
  }, [editReport]);

    React.useEffect(() => {
        defaultTerminalDD(activeTerminal);
    }, [shipTerminalDD]);

  const loadStartPool = () => {
    let d = convertDate(report.HiringDate);
    setReport({...report, HiringDate: d, HiringTime: "00:00"});
    if(editReport === null){
      startPool(d);
    }
  }
  const defaultjobTilteDD = (d: DropDown[]) => {
    let v: string = d
      ? d.find((d) => d.Key == editReport?.Job_Code)?.Value!
      : "";
    setDefaultjobTilte(v);
  };
  const defaultPortDD = (d: DropDown[]) => {
    let v: string = d ? d.find((d) => d.Key == activePort)?.Value! : "";
    setDefaultPort(v);
  };

    const defaultTerminalDD = (key: any) => {
        let v = shipTerminalDD.find((d) => (d.Key === key));
        if (v && v !== undefined && v.Value && v.Value !== undefined) {
            setDefaultTerminal(v.Value);
        }
        else {
            setDefaultTerminal("");
        }
    };
  
  const portLocationDropDown = async (val : string | undefined) => {
    //console.log(val);
    let param = "PORT_LOCATION";
    if(val !== undefined && val == 'Checker'){
      param = param + "_"+val;
    }
    let data = {
      params: {
        DropDownName: param,
      },
    };
    let response: DropDown[] = await InvestigationDropDown(data);
    setPortDD(response);
    defaultPortDD(response);
  };

  const terminalDropDown = async (port: string | undefined) => {
    if(port !== undefined){
      let data = {
        params: {
          DropDownName: "PORT_SHIPTERMINAL" + "_" + port,
          State: "NJ",
        },
      };
      let response: DropDown[] = await InvestigationDropDown(data);
      setShipTerminalDD(response);
      setPoolflag(isSubData);
      //defaultTerminalDD(response);
    }
  };

  const jobTitleDropdown = async () => {
    let data = {
      params: {
        DropDownName: "PORT_JOBCODE_PDOONLY",
        // State: "NJ",
      },
    };
    let response: DropDown[] = await InvestigationDropDown(data);
    setJobTitleDD(response);
    defaultjobTilteDD(response);
  };

  const changeLocationDD = (title: string | undefined) => {
    if(title !== undefined){
      if (title === "Checker") {
        portLocationDropDown(title);
      } else {
        portLocationDropDown(undefined);
      }
    } 
  };

  const pacCardDropdown = async () => {
    let data = {
      params: {
        DropDownName: "YESNO",
        State: "NJ",
      },
    };
    let response: DropDown[] = await InvestigationDropDown(data);
    setPacCardDD(response);
  };

  const checkPoolBegining = (val : string) => {
    if(val.includes("CrossHarbor")){
      setPoolflag(false);
    }else{
      setPoolflag(true);
    }
  }

  const handleValueChange = (field: string, val: any) => {
    let temp = { ...report };
    switch (field) {
      case "caseNo":
        temp.CaseNo = val;
        break;
      case "hiringdate":
        temp.HiringDate = val;
        startPool(val);
        break;
      case "portLocation":
        activePort = val;
        activeTerminal = "";
        temp.Port = val;
        terminalDropDown(portDD.find(d => d.Key === val)?.Key);
        setDefaultPort(portDD.find((d) => d.Key === val)?.Value ?? "");
        break;
      case "job_Code":
        temp.Job_Code = val;
        break;
      case "shipTerminal":
        temp.ShipTerminal = val;
        activeTerminal = val;
        console.log(temp.ShipTerminal);
        let v = shipTerminalDD.find((d) => d.Key === val)
        setDefaultTerminal(v !== undefined && v.Value ?  v.Value : "");
        checkPoolBegining(val);
        break;
      case "job_Title":
        temp.Job_Code = val;
        changeLocationDD(jobTitleDD.find(d => d.Key === val)?.Value);
        setDefaultjobTilte(jobTitleDD.find((d) => d.Key === val)?.Value ?? "");
        break;
      case "available":
        temp.PoolBiginning = val;
        setDefaultPoolBegining(val);
        break;
        case "requested":
         let tmp = 0
         temp.RequestsFilled = val;
         if (poolbegining != 0) {
          tmp = poolbegining - Number(val)
          setDefaultRemainingPool(tmp);
        } else {
          tmp = Number(val)
          setDefaultRemainingPool(tmp);
        }
        temp.Remaining = tmp
        break;
      case "poolBeginning":
        temp.PoolBiginning = val;
        setDefaultPoolBegining(val);
        break;
      case "remaining":
        temp.Remaining = val;
        break;
      case "unfilled":
        temp.Unfilled = val;
        break;
    }
    if(temp.PoolBiginning===undefined){

      temp.PoolBiginning = poolbegining;
    }
    setReport(temp);
  };

  const handleSubmit = async () => {
    localStorage.setItem('pdocreate', 'true');
    let data = report;
    //console.log(editReport);
   
    if (editReport === null) data.EditStatus = "ADD";
    else{
      if(editReport.EditStatus=="ADD"){
        data.EditStatus = "ADD";
      }else{
        data.EditStatus = "EDIT";
      }
    } 
    
    
    if (!handleValidation()) handleReportSubmit(data);
  };

  const handleValidation = () => {
    let flag = false;
    let text = "";
    let temp = { ...report };
    if (temp) {
      if (flag) {
        setAlertText("Please complete Mandatory fields" + text);
        setAlertOpen(true);
        setAlertType("error");
      }
    }
    return flag;
  };

  const startPool = async (val: any) => {
    let search_query: PdoHireQuery = {
      queryType: "PDOHIRE_STARTPOOL",
      dateTypeValue: 1,
      dateRange: true,
      fromDate: val + "T00:00:00",
      toDate: val + "T23:59:59",
    };
    let result: PdoHirePoolResponse = await getPdoHirePool(search_query);
    if (result && result.AnyTable && result.AnyTable.length > 0) {
      if (result.AnyTable[0].StartPool) {
        report.PoolBiginning = result.AnyTable[0].StartPool;
        setDefaultPoolBegining(result.AnyTable[0].StartPool);
      }
    } else {
      setDefaultPoolBegining(0);
    }
  };

  const convertDate = (dateInput: string | null | undefined) => {
    if (!dateInput) {
      const today = new Date();
      return `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
    }
  
    const [year, month, day] = dateInput.split('-').map(Number);
    const localDate = new Date(year, month - 1, day);
    
    let a = localDate.getDate().toString().padStart(2, '0');
    let b = (localDate.getMonth() + 1).toString().padStart(2, '0');
    let d = `${localDate.getFullYear()}-${b}-${a}`;
    
    report.HiringDate = d;
    return d;
  };

  return (
    <Box className="pt-3" sx={{ flexGrow: 1 }}>
      <div className=" border-2 border-gray-600 mx-4 mt-4">
        <div className="border-b-2 border-gray-600 bg-blue-300 h-2/12 font-bold text-center text-xl">
          PDOHire
        </div>
        <div className="my-3">
          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
          <Grid item xs={4} sx={{ pr: 2 }}>
                <TextField
                  fullWidth
                  margin="none"
                  type="date"
                  label="Hiring Date"
                  defaultValue={convertDate(report.HiringDate)}
                  InputLabelProps={{ shrink: true }}
                  onBlur={(e) => handleValueChange("hiringdate", e.target.value)}
                  helperText={report.HiringDate ? "" : "Required"}
                  disabled={isSubData}
                />
              </Grid>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="jobTitle-Label">Job Title</InputLabel>
                <Select
                  labelId="jobTitle-Label"
                  id="jobTitle-select"
                  value={defaultjobTilte}
                  renderValue={() => defaultjobTilte}
                  label="JobTitle"
                  onChange={(e: any) =>
                    handleValueChange("job_Title", e.target.value)
                  }
                  error={report.Job_Code ? false : true}
                  disabled={isSubData}
                >
                  {jobTitleDD.map((d) => (
                    <MenuItem key={d.Key} value={d.Key}>
                      {d.Value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Pool Begining"
                value={poolbegining}
                onChange={(e) =>
                  handleValueChange("poolBeginning", e.target.value)
                }
                disabled={poolflag}
              />
            </Grid>

            <Grid item xs={6} sx={{ pr: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="port-Label">Port</InputLabel>
                <Select
                  labelId="port-Label"
                  id="Port"
                  value={defaultPort}
                  renderValue={() => defaultPort}
                  label="Port"
                  onChange={(e: any) =>
                    handleValueChange("portLocation", e.target.value)
                  }
                  error={report.Port ? false : true}
                  disabled={!isSubData}

                >
                  {portDD.map((d) => (
                    <MenuItem key={d.Key} value={d.Key}>
                      {d.Value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sx={{ pr: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="invStatus-Label">Ship Terminal</InputLabel>
                <Select
                  labelId="invStatus-Label"
                  id="Ship Terminal"
                  value={defaultTerminal}
                  renderValue={() => defaultTerminal}
                  label="InvStatus"
                  onChange={(e: any) =>
                    handleValueChange("shipTerminal", e.target.value)
                  }
                  error={report.ShipTerminal ? false : true}
                  disabled={!isSubData}
                >
                  {shipTerminalDD.map((d) => (
                    <MenuItem key={d.Key} value={d.Key}>
                      {d.Value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

          </Grid>
        </div>
      </div>
      <div className=" border-2 border-gray-600 mx-4 mt-4">
        <div className="border-b-2 border-gray-600 bg-blue-300 h-2/12 font-bold text-center text-xl">
          Job
        </div>
        <div className="my-3">
          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
            
            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                type="number"
                label={
                  (report.Job_Title ? report.Job_Title : "") + " Requested"
                }
                value={report.RequestsFilled}
                onChange={(e) => handleValueChange("requested", e.target.value)}
                disabled={!isSubData}
              />
            </Grid>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                type="number"
                label={(report.Job_Title ? report.Job_Title : "") + " Unfilled"}
                value={report.Unfilled}
                onChange={(e) => handleValueChange("unfilled", e.target.value)}
                disabled={!isSubData}
              />
            </Grid>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                type="number"
                label={
                  (report.Job_Title ? report.Job_Title : "") + " Remaining"
                }
                value={report.Remaining}
                onChange={(e) => handleValueChange("remaining", e.target.value)}
                disabled={!isSubData}
              />
            </Grid>
          </Grid>
        </div>
      </div>

      <Snackbar
        open={alertOpen}
        autoHideDuration={7000}
        onClose={() => {
          setAlertOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setAlertOpen(false);
          }}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {alertText}
        </Alert>
      </Snackbar>
      <Grid
        container
        spacing={1}
        sx={{ pl: 2, mt: 2, justifyContent: "flex-end" }}
      >
        <Grid item xs={8} sx={{ pr: 2 }}></Grid>
        <Grid item xs={2} sx={{ pr: 2 }}>
          <ButtonGroup fullWidth size="large" color="inherit">
            <Button type="submit" onClick={handleSubmit}>
              Submit
            </Button>
            <Button>CLEAR</Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PdoHireInputForm;
