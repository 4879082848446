import React, {useState} from "react";
import ReactECharts from "echarts-for-react";
import { width } from "pdfkit/js/page";
import { rotate } from "pdfkit";

interface ETraceProps {
    data:any,
}


const Top10ManufacturersRecovered: React.FC<ETraceProps> = ({data}) => {
    var option;

    const handleFormatXAxis = () => {
        let xAxis:any = []
        if('top10GunManufactureRecovered' in data && Array.isArray(data.top10GunManufactureRecovered))
        {
            for(let d of data.top10GunManufactureRecovered)
            {
                xAxis.push(d.ManufacturerName)
            }
        }
        return xAxis
    }

    const handleSeries = () => {
        let list:any = []
        let series:any = []

        if('top10GunManufactureRecovered' in data && Array.isArray(data.top10GunManufactureRecovered))
        {
            for(let d of data.top10GunManufactureRecovered)
            {
                list.push(d.Count)
            }
            series.push({
                type: 'bar',
                color:'#1e3a8a',
                emphasis: {
                    focus: 'series'
                },
                label: {
                    show: true,
                    position: 'inside',
                    rotate:20,
                    fontSize:10,
                },
                data: list
            },)
        }
        return series
    }

    option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                // Use axis to trigger tooltip
                type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
            }
        },
        eight:'200',
        width:'95%',
        toolbox: {
            feature: {
                saveAsImage: { show: true }
            }
        },
        legend: {
        },
        grid: {
            left: '5%',
            right: '3%',
            bottom: '1%',
            top:'10%',
            containLabel: true
        },
        xAxis: {
            type: 'category', 
            axisLabel: {
                margin: 5,
                rotate:45,
                fontSize:8,
                width: "90",
                overflow: "truncate",
            },
           
            data: handleFormatXAxis(),
        },
        yAxis: {
            type: 'value'
        },
        series: handleSeries()
    };

    return (
        <>
            {data && data?.top10GunManufactureRecovered?.length !== 0 ?
                <div>
                    <ReactECharts option={option} style={{height:'220px'}}/>
                </div>
                :
                <div className={"text-center text-3xl mt-6 mb-1"} style={{color:"red", fontWeight:500}}>No Data</div>
            }
        </>
    );
}

export default Top10ManufacturersRecovered;