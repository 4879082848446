import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import React from "react";
import {
  DropDown,
  InvestigationObject,
} from "../../../interfaces/NJPORT/InvestigationLog.interface";
import {
  getVolunteerHirePool,
  InvestigationDropDown,
} from "../../../services/njport.service";
import {
  VolunteerHireObject,
  VolunteerHirePoolResponse,
  VolunteerHireQuery,
} from "../../../interfaces/NJPORT/VolunteerHire.interface";

interface InputFormProps {
  handleReportSubmit: any;
  editReport: VolunteerHireObject | null;
  isSubData: boolean;
}

const VolunteerHireInputForm: React.FC<InputFormProps> = ({
  handleReportSubmit,
  editReport,
  isSubData,
}) => {
  const [report, setReport] = React.useState<VolunteerHireObject>(
    editReport ? editReport : {},
  );
  const [portDD, setPortDD] = React.useState<DropDown[]>([]);
  const [jobTitleDD, setJobTitleDD] = React.useState<DropDown[]>([]);
  const [alertOpen, setAlertOpen] = React.useState<boolean>(false);
  const [alertText, setAlertText] = React.useState<String>("");
  const [alertType, setAlertType] = React.useState<any>("success");
  const [defaultstatus, setDefaultStatus] = React.useState<any>("");
  const [defaultjobTilte, setDefaultjobTilte] = React.useState<string>("");
  const [defaultPort, setDefaultPort] = React.useState<string>("");
  const [timeDD, setTimeDD] = React.useState<DropDown[]>([]);
  const [shipTerminalDD, setShipTerminalDD] = React.useState<DropDown[]>([]);
  const [iscreating,setCreating]=React.useState<boolean>(false);
  const [defaultTime, setDefaultTime] = React.useState<string>("");
  const [poolbegining, setDefaultPoolBegining] = React.useState<number>(editReport?.PoolBiginning!);
  const [poolflag, setPoolflag] = React.useState<boolean>(isSubData);

  React.useEffect(() => {
    jobTitleDropDown();
    portLocationDropDown();
    hiringTimeDropDown();
    if (editReport) {
      //console.log(editReport);
      setReport(editReport);

      console.log(editReport?.ShipTerminal+"ship terminal");
        setDefaultStatus(editReport?.ShipTerminal);

    }
    defaultStatusDD(shipTerminalDD);
    defaultjobTilteDD(jobTitleDD);
    defaultPortDD(portDD);
    defaultTimeDD(timeDD);
    loadStartPool();

  }, []);

  const loadStartPool = () => {
    let d = convertDate(report.HiringDate);
    setReport({...report, HiringDate: d});
    if(editReport === null){
      startPool(d);
    }
  }
  
  const defaultStatusDD = (d: DropDown[]) => {
    let v: string = d
      ? d.find((d) => d.Key == editReport?.ShipTerminal)?.Value!
      : "";
    setDefaultStatus(v);
  };

  const defaultPortDD = (d: DropDown[]) => {
    let v: string = d ? d.find((d) => d.Key == editReport?.Port)?.Value! : "";
    setDefaultPort(v);
  };

  const defaultjobTilteDD = (d: DropDown[]) => {
    let v: string = d
      ? d.find((d) => d.Key == editReport?.Job_Code)?.Value!
      : "";
    setDefaultjobTilte(v);
  };

  const defaultTimeDD = (d: DropDown[]) => {
    let v: string = d
      ? d.find((d) => d.Key == editReport?.HiringTime)?.Value!
      : "";
    setDefaultTime(v);
  };

  const portTerminalDropDown = async (val : string | undefined | null) => {
    if(val !== undefined){
      let data = {
        params: {
          DropDownName: "PORT_SHIPTERMINAL_"+val,
        },
      };
      let response: DropDown[] = await InvestigationDropDown(data);
      setShipTerminalDD(response);
      defaultStatusDD(response);
      setPoolflag(isSubData);
    }
  };

  const portLocationDropDown = async () => {
    let param : string = "PORT_LOCATION"
    let data = {
      params: {
        DropDownName: param,
        // State: "NJ",
      },
    };
    let response: DropDown[] = await InvestigationDropDown(data);
    setPortDD(response);
    defaultPortDD(response);
    portTerminalDropDown(editReport?.Port)
  };

  const jobTitleDropDown = async () => {
    let data = {
      params: {
        DropDownName: "PORT_JOBCODE_VOLONLY",
        // State: "NJ",
      },
    };
    let response: DropDown[] = await InvestigationDropDown(data);
    setJobTitleDD(response);
    defaultjobTilteDD(response);
  };

  const hiringTimeDropDown = async () => {
    let data = {
      params: {
        DropDownName: "PORT_HIRINGTIME",
      },
    };
    let response: DropDown[] = await InvestigationDropDown(data);
    setTimeDD(response);
    defaultTimeDD(response);
  };

  const checkPoolBegining = (val : string) => {
    if(val.includes("NEWYORK")){
      setPoolflag(false);
    }else{
      setPoolflag(true);
    }
  }

  const handleValueChange = (field: string, val: any) => {
    let temp = { ...report };
    switch (field) {
      case "id":
        temp.ID = val;
        break;
      case "caseNo":
        temp.CaseNo = val;
        break;
      case "port":
        temp.Port = val;
        // setDefaultStatus(portDD.find((d) => d.Key === val)?.Value ?? "");
        break;
      case "hiringDate":
        temp.HiringDate = val;
        startPool(val);
        break;
      case "hiringtime":
        temp.HiringTime = val;
        setDefaultTime(timeDD.find((d) => d.Key === val)?.Value ?? "");
        break;
      case "jobTitle":
        temp.Job_Code = val;
        setDefaultjobTilte(jobTitleDD.find((d) => d.Key === val)?.Value ?? "");
        break;
      case "portLocation":
        temp.Port = val;
        checkPoolBegining(portDD.find((d) => d.Key === val)?.Value!);
        portTerminalDropDown(portDD.find((d) => d.Key === val)?.Key!);
        setDefaultPort(portDD.find((d) => d.Key === val)?.Value ?? "");
        break;
      case "poolBeginning":
        temp.PoolBiginning = val;
        setDefaultPoolBegining(val);
        break;
      case "CompanyHiring":
        temp.CompanyHiring = val ? parseInt(val) : 0;
        temp.RequestsFilled =
          parseInt(val) + (temp.THEICHiring ? temp.THEICHiring : 0);
        break;
      case "THEIC":
        temp.THEICHiring = val ? parseInt(val) : 0;
        temp.RequestsFilled =
          parseInt(val) + (temp.CompanyHiring ? temp.CompanyHiring : 0);
        break;
      case "shipTerminal":
        temp.ShipTerminal = val;
        setDefaultStatus(
          shipTerminalDD.find((d) => d.Key === val)?.Value ?? "",
        );
        break;
      case "Remaining":
        temp.Remaining = val;
        break;
      case "Unfilled":
        temp.Unfilled = val;
        break;
      case "JobRequests":
        temp.JobRequests = val;
        break;
      case "stillNotWorking":
        temp.StillWorking = val;
        break;
      case "didNotAnswer":
        temp.DidNotAnswer = val;
        break;
    }
    setReport(temp);
  };

  const handleSubmit = async () => {
    localStorage.setItem('voluntercreate', 'true');
    let data = report;
    //console.log(editReport);
   setCreating(true);

    if (editReport === null) data.EditStatus = "ADD";
    else{
      if(editReport.EditStatus==="ADD"){
        data.EditStatus = "ADD";
      }else{
        data.EditStatus = "EDIT";
      }
    } 
    
    
    if (!handleValidation()) handleReportSubmit(data);
  };


  const handleValidation = () => {
    let flag = false;
    let text = "";
    let temp = { ...report };
    if (temp) {
      if (flag) {
        setAlertText("Please complete Mandatory fields" + text);
        setAlertOpen(true);
        setAlertType("error");
      }
    }
    return flag;
  };

  const startPool = async (val: any) => {
    let search_query: VolunteerHireQuery = {
      queryType: "VOLUNTEERHIRE_STARTPOOL",
      dateTypeValue: 1,
      dateRange: true,
      fromDate: val + "T00:00:00",
      toDate: val + "T23:59:59",
    };
    let result: VolunteerHirePoolResponse = await getVolunteerHirePool(
      search_query,
    );
    if (result && result.AnyTable && result.AnyTable.length > 0) {
      if (result.AnyTable[0].StartPool) {
        report.PoolBiginning = result.AnyTable[0].StartPool;
        setDefaultPoolBegining(result.AnyTable[0].StartPool);
      }
    }
  };

  const convertDate = (dateInput: string | null | undefined): string => {
    
  
    if (!dateInput) {
      const today = new Date();
      return `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
    }
  
    // Parsing the date input assuming it's in ISO format
    const localDate = new Date(dateInput);
  
    // Check if the date parsing was successful
    if (isNaN(localDate.getTime())) {
      console.error('Invalid date input');
      return '';
    }
  
    const day = localDate.getDate().toString().padStart(2, '0');
    const month = (localDate.getMonth() + 1).toString().padStart(2, '0');
    const year = localDate.getFullYear();
  
    const formattedDate = `${year}-${month}-${day}`;
  
    // Assuming `report` is defined elsewhere in your scope
    report.HiringDate = formattedDate;
    
    return formattedDate;
  };

  
  function convertDatee(isoDateTime : string | null | undefined) {
    if(isoDateTime!==null){
      
      return isoDateTime?.split('T')[0];
    }
  }
  return (
    <Box className="pt-3" sx={{ flexGrow: 1 }}>
      <div className=" border-2 border-gray-600 mx-4 mt-4">
        <div className="border-b-2 border-gray-600 bg-blue-300 h-2/12 font-bold text-center text-xl">
          VolunteerHire Entry
        </div>
        <div className="my-3">
          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
          <Grid item xs={4} sx={{ pr: 2 }}>
          <TextField
                fullWidth
                margin="none"
                type="date"
                label="Hiring Date"
                defaultValue={convertDate(report?.HiringDateTime)}
                InputLabelProps={{ shrink: true }}
                onBlur={(e) => handleValueChange("hiringDate", e.target.value)}
                // error={report.HiringDateTime ? false : true}
                // helperText={report.HiringDateTime ? "" : "Required"}
                disabled={isSubData}
              />
            </Grid>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="hiringTime-Label">Hiring Time</InputLabel>
                <Select
                  labelId="hiringTime-Label"
                  id="HiringTime"
                  label="HiringTime"
                  onChange={(e: any) =>
                    handleValueChange("hiringtime", e.target.value)
                  }
                  value={defaultTime}
                  renderValue={() => defaultTime}
                  error={report.HiringTime ? false : true}
                  // helperText={report.HiringTime ? "" : "Required"}
                  disabled={isSubData}
                >
                  {timeDD.map((d) => (
                    <MenuItem key={d.Key} value={d.Key}>
                      {d.Value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Pool Begining"
                value={poolbegining}
                onChange={(e) =>
                  handleValueChange("poolBeginning", e.target.value)
                }
                disabled={poolflag}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
            <Grid item xs={6} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Declined/Did Not Answer"
                value={report.DidNotAnswer}
                onChange={(e) =>
                  handleValueChange("didNotAnswer", e.target.value)
                }
                disabled={isSubData}
              />
            </Grid>
            <Grid item xs={6} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Still Working"
                value={report.StillWorking}
                onChange={(e) =>
                  handleValueChange("stillNotWorking", e.target.value)
                }
                disabled={isSubData}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
          <Grid item xs={4} sx={{ pr: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="jobTitle-Label">Job Title</InputLabel>
                <Select
                  labelId="jobTitle-Label"
                  id="jobTitle"
                  value={defaultjobTilte}
                  label="JobCode"
                  renderValue={() => defaultjobTilte}
                  onChange={(e: any) =>
                    handleValueChange("jobTitle", e.target.value)
                  }
                  // error={report.Job_Code ? false : true}
                  disabled={!isSubData}
                >
                  {jobTitleDD.map((d) => (
                    <MenuItem key={d.Key} value={d.Key}>
                      {d.Value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="port-Label">Port</InputLabel>
                <Select
                  labelId="port-Label"
                  id="Port"
                  value={defaultPort}
                  renderValue={() => defaultPort}
                  label="Port"
                  onChange={(e: any) =>
                    handleValueChange("portLocation", e.target.value)
                  }
                  // error={report.Port ? false : true}
                  disabled={!isSubData}
                >
                  {portDD.map((d) => (
                    <MenuItem key={d.Key} value={d.Key}>
                      {d.Value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="invStatus-Label">Ship Terminal</InputLabel>
                <Select
                  labelId="invStatus-Label"
                  id="Ship Terminal"
                  value={defaultstatus}
                  renderValue={() => defaultstatus}
                  label="InvStatus"
                  onChange={(e: any) =>
                    handleValueChange("shipTerminal", e.target.value)
                  }
                  // error={report.ShipTerminal ? false : true}
                  disabled={!isSubData}
                >
                  {shipTerminalDD.map((d) => (
                    <MenuItem key={d.Key} value={d.Key}>
                      {d.Value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>   
          </Grid>

          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="No of Job Request"
                value={report.JobRequests}
                onChange={(e) =>
                  handleValueChange("JobRequests", e.target.value)
                }
                disabled={!isSubData}
              />
            </Grid>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Company Hiring"
                value={report.CompanyHiring}
                onChange={(e) =>
                  handleValueChange("CompanyHiring", e.target.value)
                }
                disabled={!isSubData}
                type="number"
              />
            </Grid>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="THEIC Hires"
                value={report.THEICHiring}
                onChange={(e) => handleValueChange("THEIC", e.target.value)}
                disabled={!isSubData}
                type="number"
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Requests Filled"
                defaultValue={0}
                value={report.RequestsFilled}
                disabled={!isSubData}
              />
            </Grid>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Remaining"
                value={report.Remaining}
                onChange={(e) => handleValueChange("Remaining", e.target.value)}
                disabled={!isSubData}
                type="number"
              />
            </Grid>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Unfilled"
                value={report.Unfilled}
                onChange={(e) => handleValueChange("Unfilled", e.target.value)}
                disabled={!isSubData}
                type="number"
              />
            </Grid>
          </Grid>
        </div>
      </div>

      <Snackbar
        open={alertOpen}
        autoHideDuration={7000}
        onClose={() => {
          setAlertOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setAlertOpen(false);
          }}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {alertText}
        </Alert>
      </Snackbar>
      <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
        <Grid item xs={8} sx={{ pr: 2 }}></Grid>
        <Grid item xs={2} sx={{ pr: 2 }}>
          <ButtonGroup fullWidth size="large" color="inherit">
            <Button type="submit" onClick={handleSubmit}>
              Submit
            </Button>
            <Button>CLEAR</Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    </Box>
  );
};

export default VolunteerHireInputForm;
