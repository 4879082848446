import React, { useState } from "react";
import { Card, CardContent, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import {
    GetDailyShooting,
    RunGetDashboardRefreshToken,
    RunGetDashboardToken,
    RunGetDefaultCity
} from "../../../services/dashboard.service";
import { DashboardMarqueeTimer, DashboardRefresh, DashboardTimer } from "../../../services/config.service";
import Marquee from "react-fast-marquee";
import { RunDarQuery } from "../../../services/getDar.service";
import { formatDT } from "../../../services/formatDate.service";
import { isUserPermission } from "../../../services/auth.service";
import ApplicationRecived from "./_applicationsReceived";
import { NJPortDashboardDataApi } from "../../../services/njport.dashboard.service";
import ApplicationWithdrawn from "./_applicationsWithdrawn";
import ApplicationTransfer from "./_applicationsTransferredToInves";
import RightApplicationWithdrawn from "./_rightApplicationWithdraw";
import RightApplicationTransfer from "./_rightApplicationTrnasferToInves";
import LeftApplicationReceived from "./_apprecceived";
import RightApplicationReceived from "./_rightApplicationReceived";

interface DashboardLayoutProps {

    data: any,
}


const INDashboardLayout: React.FC<DashboardLayoutProps> = ({ data }) => {
    const [location, setLocation] = React.useState<string>("Loading")
    const [county, setCounty] = React.useState<string>("")
    const [dateUpdate, setDateUpdate] = React.useState<boolean>(true)
    let today = new Date()
    let monthAgoDate = new Date();
    monthAgoDate.setDate(monthAgoDate.getDate() - 28);
    const [toDate, setToDate] = React.useState<Date>(today)
    const [fromDate, setFromDate] = React.useState<Date>(monthAgoDate)
    const [initialLoad, setInitialLoad] = React.useState<boolean>(false)
    const [shootingNot, setShootingNot] = React.useState<any[]>([{ time: "", description: "", address: "" }])
    const [cityList, setCityList] = React.useState<any[]>([])
    // const [data, setData] = useState<any>({})

    // left side usestate
    const [analysisdata, setAnalysisData] = useState<any>({});
    const [widthDrawdata, setWidthDrawData] = useState<any>({});
    const [transferdata, setTransferData] = useState<any>({})
    const [generdata, setGenderData] = useState<any>({});
    const [racedata, setRaceData] = useState<any>({})
    const [refferaldata, setRefferalData] = useState<any>({})
    const [pacissuedata, setPacissueData] = useState<any>({})
    const [collectdata, setCollectionData] = useState<any>({})
    const [duplicatedata, setDuplicateData] = useState<any>({})
    const [receivedata, setReceiveData] = useState<any>({})

    // right side API usestate
    const [rightReceivedata, setRightreceiveData] = useState<any>({})
    const [rightwidthDrawdata, setRightWidthDrawData] = useState<any>({})
    const [rightTransferdata, setRightTransferData] = useState<any>({})
    const [rightGenerdata, setRightGenerData] = useState<any>({})
    const [rightRacedata, setRightRaceData] = useState<any>({})
    const [rightRefferaldata, setRightRefferalData] = useState<any>({})
    const [rightPacissuedata, setRightPacissueData] = useState<any>({})
    const [rightCollectdata, setRightCollectData] = useState<any>({})
    const [rightDuplicatedata, setRightDuplicatedata] = useState<any>({})
    const [rightAnalysisdata, setRightAnalysisData] = useState<any>({})

    let test: any[] = []

    React.useEffect(() => {
        const payload = {
            queryType: "INVESTIGATION_RECEIVE_YEAR5_START",
            dateTypeValue: 1,
        };
        NJPortDashboardDataApi(payload).then((qRes) => {
            setAnalysisData(qRes);
            console.log(qRes?.AnyTable + "Analysts data");
        });
    }, []);
    // for widthDraw request
    React.useEffect(() => {
        const payload = {
            queryType: "INVESTIGATION_STATUS_YEAR5_START",
            dateTypeValue: 1,
        };
        NJPortDashboardDataApi(payload).then((qRes) => {
            setWidthDrawData(qRes);
            console.log(qRes?.AnyTable + "Analysts data");
        });
    }, []);

    // transfer
    React.useEffect(() => {
        const payload = {
            queryType: "INVESTIGATION_STATUS_YEAR5_START",
            dateTypeValue: 1,
        };
        NJPortDashboardDataApi(payload).then((qRes) => {
            setTransferData(qRes);
            console.log(qRes?.AnyTable + "Analysts data");
        });
    }, []);

    // left gender
    React.useEffect(() => {
        const payload = {
            queryType: "INVESTIGATION_GENDER_YEAR5_START",
            dateTypeValue: 1,
        };
        NJPortDashboardDataApi(payload).then((qRes) => {
            setGenderData(qRes);
            console.log(qRes?.AnyTable + "Analysts data");
        });
    }, []);

    // left race years
    React.useEffect(() => {
        const payload = {
            queryType: "INVESTIGATION_RACE_YEAR5_START",
            dateTypeValue: 1,
        };
        NJPortDashboardDataApi(payload).then((qRes) => {
            setRaceData(qRes);
            console.log(qRes?.AnyTable + "Analysts data");
        });
    }, []);

    // left refferal years
    React.useEffect(() => {
        const payload = {
            queryType: "INVESTIGATION_REFERRAL_YEAR5_START",
            dateTypeValue: 1,
        };
        NJPortDashboardDataApi(payload).then((qRes) => {
            setRefferalData(qRes);
            console.log(qRes?.AnyTable + "Analysts data");
        });
    }, []);

    // left pacissue years
    React.useEffect(() => {
        const payload = {
            queryType: "INVESTIGATION_PACISSUE_YEAR5_START",
            dateTypeValue: 1,
        };
        NJPortDashboardDataApi(payload).then((qRes) => {
            setPacissueData(qRes);
            console.log(qRes?.AnyTable + "Analysts data");
        });
    }, []);

    // left collect years
    React.useEffect(() => {
        const payload = {
            queryType: "MONEYORDER_COLLECT_YEAR5_START",
            dateTypeValue: 1,
        };
        NJPortDashboardDataApi(payload).then((qRes) => {
            setCollectionData(qRes);
            console.log(qRes?.AnyTable + "Money Order Datat");
        });
    }, []);

    // left duplicate
    React.useEffect(() => {
        const payload = {
            queryType: "RENEWAL_DUPLICATE_YEAR5_START",
            dateTypeValue: 1,
        };
        NJPortDashboardDataApi(payload).then((qRes) => {
            setDuplicateData(qRes);
            console.log(qRes?.AnyTable + "Analysts data");
        });
    }, []);

    // left recieve
    React.useEffect(() => {
        const payload = {
            queryType: "ANALYSTREQUEST_RECEIVE_YEAR5_START",
            dateTypeValue: 1,
        };
        NJPortDashboardDataApi(payload).then((qRes) => {
            setReceiveData(qRes);
            console.log(qRes?.AnyTable + "Analysts data");
        });
    }, []);


    // right side 

    //receive data
    React.useEffect(() => {
        const payload = {
            queryType: "INVESTIGATION_RECEIVE_YEAR_START",
            dateTypeValue: 1,
        };
        NJPortDashboardDataApi(payload).then((qRes) => {
            setRightreceiveData(qRes);
            console.log(qRes?.AnyTable + "Analysts data");
        });
    }, []);

    // withdraw

    React.useEffect(() => {
        const payload = {
            queryType: "INVESTIGATION_STATUS_YEAR_START",
            dateTypeValue: 1,
        };
        NJPortDashboardDataApi(payload).then((qRes) => {
            setRightWidthDrawData(qRes);
            console.log(qRes?.AnyTable + "Analysts data");
        });
    }, []);

    // trnsfer
    React.useEffect(() => {
        const payload = {
            queryType: "INVESTIGATION_STATUS_YEAR_START",
            dateTypeValue: 1,
        };
        NJPortDashboardDataApi(payload).then((qRes) => {
            setRightTransferData(qRes);
            console.log(qRes?.AnyTable + "Analysts data");
        });
    }, []);

    // male female
    React.useEffect(() => {
        const payload = {
            queryType: "INVESTIGATION_GENDER_YEAR_START",
            dateTypeValue: 1,
        };
        NJPortDashboardDataApi(payload).then((qRes) => {
            setRightGenerData(qRes);
            console.log(qRes?.AnyTable + "Analysts data");
        });
    }, []);

    // race
    React.useEffect(() => {
        const payload = {
            queryType: "INVESTIGATION_RACE_YEAR_START",
            dateTypeValue: 1,
        };
        NJPortDashboardDataApi(payload).then((qRes) => {
            setRightRaceData(qRes);
            console.log(qRes?.AnyTable + "Analysts data");
        });
    }, []);

    // refferal

    React.useEffect(() => {
        const payload = {
            queryType: "INVESTIGATION_REFERRAL_YEAR_START",
            dateTypeValue: 1,
        };
        NJPortDashboardDataApi(payload).then((qRes) => {
            setRightRefferalData(qRes);
            console.log(qRes?.AnyTable + "Analysts data");
        });
    }, []);

    // post
    React.useEffect(() => {
        const payload = {
            queryType: "INVESTIGATION_PACISSUE_YEAR_START",
            dateTypeValue: 1,
        };
        NJPortDashboardDataApi(payload).then((qRes) => {
            setRightPacissueData(qRes);
            console.log(qRes?.AnyTable + "Analysts data");
        });
    }, []);


    // collection

    React.useEffect(() => {
        const payload = {
            queryType: "MONEYORDER_COLLECT_YEAR_START",
            dateTypeValue: 1,
        };
        NJPortDashboardDataApi(payload).then((qRes) => {
            setRightCollectData(qRes);
            console.log(qRes?.AnyTable + "Analysts data");
        });
    }, []);

    // duplicate
    React.useEffect(() => {
        const payload = {
            queryType: "RENEWAL_DUPLICATE_YEAR_START",
            dateTypeValue: 1,
        };
        NJPortDashboardDataApi(payload).then((qRes) => {
            setRightDuplicatedata(qRes);
            console.log(qRes?.AnyTable + "Analysts data");
        });
    }, []);


    // analysis

    React.useEffect(() => {
        const payload = {
            queryType: "ANALYSTREQUEST_RECEIVE_YEAR_START",
            dateTypeValue: 1,
        };
        NJPortDashboardDataApi(payload).then((qRes) => {
            setRightAnalysisData(qRes);
            console.log(qRes?.AnyTable + "Analysts data");
        });
    }, []);

    React.useEffect(() => {
        const x = setInterval(async () => {
            let dashboard = await RunGetDashboardRefreshToken()
            if (dashboard !== null && dashboard?.AuthToken) {
                localStorage.setItem("dashboard", dashboard.AuthToken)
            }
        }, DashboardRefresh);
        return () => clearInterval(x);
    }, []);

    React.useEffect(() => {
        const intervalMarq = setInterval(() => {
            if (location !== "" && location !== "Loading") {
                let DarParam = {
                    QueryType: "6",
                    Dept: "CENTRAL",
                    EmpID: "matt@gtbm.com",
                    DeptORI: "NJ000000",
                    DateRange: true,
                    DateTypeValue: 0,
                    FromDate: new Date().toLocaleDateString(),
                    ToDate: new Date().toLocaleDateString(),
                    SearchType: "AND",
                    IndicatorOn: true,
                    TopArrestNumber: 0,
                    FNames: [],
                    MNames: [],
                    LNames: [],
                    DOBs: [],
                    SEX: [],
                    Address: "",
                    Cities: location ? (location === 'WHOLE COUNTY') ? cityList : (location === 'ALL NJ') ? [] : location?.replace(/CITY|BORO|TWP/gi, '').trim().split(",") : [],
                    States: [],
                    Zip: "",
                    Statutes: [],
                    Gang: [],
                    FBIs: [],
                    SBIs: [],
                    DLNos: [],
                    PlateNos: [],
                    VINs: [],
                    AgencyArrested: [],
                    InfocopDept: [],
                    Indicators: [],
                    Latitude: null,
                    Longitude: null,
                    Radius: 0,
                    ResidentCounty: [],
                    ArrestedCounty: [],
                    Arrest_Type: [],
                    CaseNumbers: [],
                    SSN: [],
                    PlaceOfBirth: [],
                    AgeRangeStart: null,
                    AgeRangeEnd: null,
                }
                GetDailyShooting(DarParam).then((response: any) => {
                    if (response.AnyTable) {
                        let p: any = []
                        for (var s of response.AnyTable) {
                            p.push({ time: (s?.DateTimeIN) ? s?.DateTimeIN : "", description: (s?.STATUTEDESCRIPTION) ? s?.STATUTEDESCRIPTION : "", address: (s?.Address) ? s?.Address : "" })
                        }
                        setShootingNot(p)
                    }
                })
            }
        }, DashboardMarqueeTimer);
        return () => clearInterval(intervalMarq);
    }, [location]);

    React.useEffect(() => {
        if (!initialLoad) {
            setInitialLoad(true)
            RunGetDashboardToken().then((dashboard) => {
                if (dashboard !== null && dashboard?.AuthToken) {
                    localStorage.setItem("dashboard", dashboard.AuthToken)
                    RunGetDefaultCity().then((res) => {

                        setLocation(res[0].city)
                    })
                }
            })
        }
    }, []);


    // React.useEffect(() => {
    //     if (location !== "" && location !== "Loading") {
    //         let params = {
    //             ToDate: toDate,
    //             FromDate: fromDate,
    //             City: location,
    //             Dept: county,
    //         }
    //         GetStats(params)
    //     }
    // }, [location, toDate, fromDate, county])

    React.useEffect(() => {
        if (location !== "" && location !== "Loading") {

            let DarParam = {
                QueryType: "6",
                Dept: "CENTRAL",
                EmpID: "matt@gtbm.com",
                DeptORI: "NJ000000",
                DateRange: true,
                DateTypeValue: 0,
                FromDate: new Date().toLocaleDateString(),
                ToDate: new Date().toLocaleDateString(),
                SearchType: "AND",
                IndicatorOn: true,
                TopArrestNumber: 0,
                FNames: [],
                MNames: [],
                LNames: [],
                DOBs: [],
                SEX: [],
                Address: "",
                Cities: location ? (location === 'WHOLE COUNTY') ? cityList : (location === 'ALL NJ') ? [] : location?.replace(/CITY|BORO|TWP/gi, '').trim().split(",") : [],
                States: [],
                Zip: "",
                Statutes: [],
                Gang: [],
                FBIs: [],
                SBIs: [],
                DLNos: [],
                PlateNos: [],
                VINs: [],
                AgencyArrested: [],
                InfocopDept: [],
                Indicators: [],
                Latitude: null,
                Longitude: null,
                Radius: 0,
                ResidentCounty: [],
                ArrestedCounty: [],
                Arrest_Type: [],
                CaseNumbers: [],
                SSN: [],
                PlaceOfBirth: [],
                AgeRangeStart: null,
                AgeRangeEnd: null,
            }
            GetDailyShooting(DarParam).then((response: any) => {
                if (response.AnyTable) {
                    let p: any = []
                    for (var s of response.AnyTable) {
                        p.push({ time: (s?.DateTimeIN) ? s?.DateTimeIN : "", description: (s?.STATUTEDESCRIPTION) ? s?.STATUTEDESCRIPTION : "", address: (s?.Address) ? s?.Address : "" })
                    }
                    setShootingNot(p)
                }
            })
        }
    }, [location, cityList])

    const UpdateCityList = (list: any[]) => {
        setCityList(list)
    }
    return (
        <div>
            {location !== "Loading" ? (
                <div>
                    {shootingNot && shootingNot.length > 0 ? (
                        <Marquee speed={50} pauseOnHover={true}>
                            {shootingNot.map((shot, idx) => (
                                <Card key={idx} sx={{ minWidth: 1000, maxWidth: 1000, maxHeight: 90 }}>
                                    <CardContent className="text-center">
                                        <Typography sx={{ fontSize: 14 }} variant="caption" component="div">
                                            <span>[{idx + 1}]</span> <span className="font-bold"> {shot.address} - {formatDT(shot.time)}</span>
                                        </Typography>
                                        <Typography sx={{ fontSize: 14 }} variant="h6" component="div">
                                            {shot.description}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            ))}
                        </Marquee>
                    ) : (
                        <div className="text-center font-bold text-3xl mt text-black mt-3">
                            <span>BackTrace Investigation Strategic Dashboard </span>
                            {/* <span className="text-yellow-400">{(location === "WHOLE COUNTY") ? county.replace(/([A-Z])/g, ' $1').trim() : location}</span> */}
                        </div>
                    )}

                    <div className="mt-3">
                        {/* <div className="grid xl:grid-cols-4 lg:grid-cols-3 grid-cols-2 md:gap-4">
                            <div className="col-span-1"><LeftApplicationReceived location={location} dbData={analysisdata} title=" Applications Received (Last 5 Years)" /></div>
                            <div className="col-span-1"><ApplicationWithdrawn location={location} dbData={widthDrawdata} title="Applications Withdrawn" /></div>
                            <div className="col-span-1"><RightApplicationReceived location={location} dbData={rightReceivedata} title="Applications Received (Current Year)" /></div>
                            <div className="col-span-1"><RightApplicationWithdrawn location={location} dbData={rightwidthDrawdata} title="Applications Withdrawn" /></div>


                            <div className="col-span-1"><ApplicationTransfer location={location} dbData={transferdata} title="Applications Transferred To Invest" /></div>

                            <div className="col-span-1"><ApplicationRecived location={location} dbData={generdata} title="Male Vs. Female Hiring" /></div>
                            <div className="col-span-1"><RightApplicationTransfer location={location} dbData={rightTransferdata} title="Applications Transferred To Invest" /></div>
                            <div className="col-span-1"><ApplicationRecived location={location} dbData={rightGenerdata} title="Male Vs. Female Hiring" /></div>


                            <div className="col-span-1"><ApplicationRecived location={location} dbData={racedata} title="Hiring by Race" /></div>
                            <div className="col-span-1"><ApplicationRecived location={location} dbData={refferaldata} title="Referral Source" /></div>
                            <div className="col-span-1"><ApplicationRecived location={location} dbData={rightRacedata} title="Hiring by Race" /></div>
                            <div className="col-span-1"><ApplicationRecived location={location} dbData={rightRefferaldata} title="Referral Source" /></div>


                            <div className="col-span-1"><ApplicationRecived location={location} dbData={pacissuedata} title="Port Access Cards Issued" /></div>
                            <div className="col-span-1"><ApplicationRecived location={location} dbData={collectdata} title="Money Orders Collected" /></div>
                            <div className="col-span-1"><ApplicationRecived location={location} dbData={rightPacissuedata} title="Port Access Cards Issued" /></div>
                            <div className="col-span-1"><ApplicationRecived location={location} dbData={rightCollectdata} title=" Money Orders Collected" /></div>

                            <div className="col-span-1"><ApplicationRecived location={location} dbData={duplicatedata} title="Duplicate PAC Card Issued" /></div>
                            <div className="col-span-1"><ApplicationRecived location={location} dbData={receivedata} title="Analyst Requested" /></div>
                            <div className="col-span-1"><ApplicationRecived location={location} dbData={rightDuplicatedata} title="Duplicate PAC Card Issued" /></div>
                            <div className="col-span-1"><ApplicationRecived location={location} dbData={rightAnalysisdata} title="AnalystRequested" /></div>
                        </div> */}
                        <div className="grid xl:grid-cols-4 lg:grid-cols-3 grid-cols-2 md:gap-4">
                            <div className="col-span-1"><LeftApplicationReceived location={location} dbData={analysisdata} title=" Applications Received (Last 5 Years)" /></div>
                            <div className="col-span-1"><ApplicationWithdrawn location={location} dbData={widthDrawdata} title="Applications Withdrawn" /></div>
                            <div className="col-span-1"><ApplicationTransfer location={location} dbData={transferdata} title="Applications Transferred To Invest" /></div>
                            <div className="col-span-1"><ApplicationRecived location={location} dbData={generdata} title="Male Vs. Female Hiring" /></div>

                            <div className="col-span-1"><ApplicationRecived location={location} dbData={racedata} title="Hiring by Race" /></div>
                            <div className="col-span-1"><ApplicationRecived location={location} dbData={refferaldata} title="Referral Source" /></div>
                            <div className="col-span-1"><ApplicationRecived location={location} dbData={pacissuedata} title="Port Access Cards Issued" /></div>
                            <div className="col-span-1"><ApplicationRecived location={location} dbData={collectdata} title="Money Orders Collected" /></div>

                            <div className="col-span-1"><ApplicationRecived location={location} dbData={duplicatedata} title="Duplicate PAC Card Issued" /></div>
                            <div className="col-span-1"><ApplicationRecived location={location} dbData={receivedata} title="Analyst Requested" /></div>


                            <div className="col-span-1"><RightApplicationReceived location={location} dbData={rightReceivedata} title="Applications Received (Current Year)" /></div>
                            <div className="col-span-1"><RightApplicationWithdrawn location={location} dbData={rightwidthDrawdata} title="Applications Withdrawn" /></div>
                            <div className="col-span-1"><RightApplicationTransfer location={location} dbData={rightTransferdata} title="Applications Transferred To Invest" /></div>
                            <div className="col-span-1"><ApplicationRecived location={location} dbData={rightGenerdata} title="Male Vs. Female Hiring" /></div>


                           
                            <div className="col-span-1"><ApplicationRecived location={location} dbData={rightRacedata} title="Hiring by Race" /></div>
                            <div className="col-span-1"><ApplicationRecived location={location} dbData={rightRefferaldata} title="Referral Source" /></div>
                            <div className="col-span-1"><ApplicationRecived location={location} dbData={rightPacissuedata} title="Port Access Cards Issued" /></div>
                            <div className="col-span-1"><ApplicationRecived location={location} dbData={rightCollectdata} title=" Money Orders Collected" /></div>

                           
                            <div className="col-span-1"><ApplicationRecived location={location} dbData={rightDuplicatedata} title="Duplicate PAC Card Issued" /></div>
                            <div className="col-span-1"><ApplicationRecived location={location} dbData={rightAnalysisdata} title="AnalystRequested" /></div>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
}

export default INDashboardLayout;
