import React, {useState} from "react";
import { Box, Card, Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,Paper, Stack, Divider, Button } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { JAOS } from "../../../services/JAOS.service";

interface ETraceProps {
    data:any,
}


const PersonBreakdown: React.FC<ETraceProps> = ({data}) => {

    const [priorGunLink, setPriorGunLink] = React.useState<string>("")

    const searchSBI = (personBreakdownLink:any , filterType:string) =>{
        if(personBreakdownLink && personBreakdownLink?.length > 0 && filterType){
            let personList=personBreakdownLink?.filter((e:any)=> e.Type == filterType)
            let obj = {SBI:""}
            if(personList && personList?.length > 0){
                for (let person of personList){
                    if(person?.SBINo){
                        obj.SBI += person.SBINo + ","
                    }
                }
            }
            if(obj?.SBI?.length > 0){
                let l = new JAOS()
                let hashID = l.objToStack(obj)
                window.open('/tools/arrest?personList=' + hashID, '_blank')
                    
            }

        }
    }
   

return (
    <div>
        {
           data && data?.personBreakdown?.length > 0  && data?.personBreakdownLink ? 
           <Card variant="outlined">
           <Box sx={{ p: 2 }}>
               <Stack  direction="row" justifyContent="space-between" alignItems="center">
                   <Typography  sx={{fontWeight:'bold'}}>Had Prior Gun Charges</Typography>
                 
                       <Typography  sx={{fontWeight:'bold'}}>                          
                            <Button style={{ backgroundColor: 'transparent' }} onClick={()=>searchSBI(data?.personBreakdownLink, 'PRIOR GUN')}>
                                {data?.personBreakdown.filter((e:any)=> e.Type == 'PRIOR GUN')?.length > 0 ?
                                data?.personBreakdown.filter((e:any)=> e.Type == 'PRIOR GUN')[0]?.Count 
                                : '0'}
                            </Button>                                                         
                       </Typography>                                                             
               </Stack>
               <Divider/>      
               <Stack  direction="row" justifyContent="space-between" alignItems="center">
                   <Typography  sx={{fontWeight:'bold'}}>Had Prior Arrests</Typography>
                   <Typography  sx={{fontWeight:'bold'}}>
                        <Button style={{ backgroundColor: 'transparent' }} onClick={()=>searchSBI(data?.personBreakdownLink, 'PRIOR ARRESTS')}>
                            {data?.personBreakdown.filter((e:any)=> e.Type == 'PRIOR ARRESTS')?.length > 0 ?
                            data?.personBreakdown.filter((e:any)=> e.Type == 'PRIOR ARRESTS')[0]?.Count : '0'}
                        </Button >
                    </Typography>
               </Stack>
               <Divider/>      
               <Stack  direction="row" justifyContent="space-between" alignItems="center">
                   <Typography  sx={{fontWeight:'bold'}}>Are Trigger Lock</Typography>
                   <Typography  sx={{fontWeight:'bold'}}>
                        <Button style={{ backgroundColor: 'transparent' }} onClick={()=>searchSBI(data?.personBreakdownLink, 'TRIGGER LOCK')}>
                            {data?.personBreakdown.filter((e:any)=> e.Type == 'TRIGGER LOCK')?.length > 0 ?
                            data?.personBreakdown.filter((e:any)=> e.Type == 'TRIGGER LOCK')[0]?.Count : '0'}
                        </Button>
                      </Typography>
               </Stack>
               <Divider/>      
               <Stack  direction="row" justifyContent="space-between" alignItems="center">
                   <Typography  sx={{fontWeight:'bold'}}>Were involved in Prior Shootings</Typography>
                   <Typography  sx={{fontWeight:'bold'}}>
                        <Button style={{ backgroundColor: 'transparent' }} onClick={()=>searchSBI(data?.personBreakdownLink, 'PRIOR SHOOTING')}>
                            {data?.personBreakdown.filter((e:any)=> e.Type == 'PRIOR SHOOTING')?.length > 0 ?
                            data?.personBreakdown.filter((e:any)=> e.Type == 'PRIOR SHOOTING')[0]?.Count : '0'}
                        </Button>
                      </Typography>
               </Stack>
               <Divider/>       
               {/* <Stack  direction="row" justifyContent="space-between" alignItems="center">
                   <Typography  sx={{fontWeight:'bold'}}>Were/Are Felons</Typography>
                   <Typography  sx={{fontWeight:'bold'}}>
                       {data?.personBreakdown.filter((e:any)=> e.Type == 'FELON')?.length > 0 ?
                      data?.personBreakdown.filter((e:any)=> e.Type == 'FELON')[0]?.Count : '0'}
                      </Typography>
               </Stack>
               <Divider/>    */}
               <Stack  direction="row" justifyContent="space-between" alignItems="center">
                   <Typography  sx={{fontWeight:'bold'}}>Were GVRTF Referrals</Typography>
                   <Typography  sx={{fontWeight:'bold'}}>
                        <Button style={{ backgroundColor: 'transparent' }} onClick={()=>searchSBI(data?.personBreakdownLink, 'REFERRAL')}>
                            {data?.personBreakdown.filter((e:any)=> e.Type == 'REFERRAL')?.length > 0 ?
                            data?.personBreakdown.filter((e:any)=> e.Type == 'REFERRAL')[0]?.Count : '0'}
                        </Button>
                      </Typography>
               </Stack>
           </Box>
            </Card>       
        :<div className={"text-center text-3xl mt-6 mb-6"} style={{color:"red", fontWeight:500 , height:"100%"}}>No Data</div>
        }
        
    </div>
);
}

export default PersonBreakdown;