import React from 'react';
import { Container, Box, Button, ButtonGroup, TextField, Grid, Typography, Autocomplete, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, AutocompleteRenderInputParams, IconButton } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import {
	ECrawlObject, ECrawlCarrierObject,
	ECrawlDriverObject, ECrawlPassengerObject,
	ECrawlTractorObject, ECrawlTrailerObject,
	ECrawlContactObject
} from "../../../interfaces/Detail/ECrawlDetail.interface";
import AddIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { getATFStreetSuffix, getATFStateList } from '../../../services/getDropdown.service';
import './ecrawl-styles.css';
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { Today } from '@mui/icons-material';

const states = [
	"AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS",
	"KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY",
	"NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV",
	"WI", "WY", "DC", "MX", "OTHER"
];

interface ECrawlProps {
	handleReportSubmit: any,
	isEdit: boolean,
	ecrawl?: any
}

const ECrawlInput: React.FC<ECrawlProps> = ({ handleReportSubmit, isEdit = null, ecrawl = null }) => {
	var today = new Date();
	var todayDate = today?.toISOString()?.split('T')[0];

	const [stateList, setStateList] = React.useState<any>([]);
	const [streetSuffixList, setStreetSuffixList] = React.useState<any>([]);
	const [params, setParams] = React.useState<ECrawlObject>({});
	const [carriers, setCarriers] = React.useState<ECrawlCarrierObject[]>([{}]);
	const [drivers, setDrivers] = React.useState<ECrawlDriverObject[]>([{}]);
	const [passengers, setPassengers] = React.useState<ECrawlPassengerObject[]>([{}]);
	const [tractors, setTractors] = React.useState<ECrawlTractorObject[]>([{}]);
	const [trailers, setTrailers] = React.useState<ECrawlTrailerObject[]>([{}]);
	const [contactInfo, setContactInfo] = React.useState<ECrawlContactObject[]>([{}]);

	const [saveOpen, setSaveOpen] = React.useState(false);
	const [clearOpen, setClearOpen] = React.useState(false);

	const handleClear = () => {
		setParams({});
		setCarriers([{}]);
		setDrivers([{}]);
		setPassengers([{}]);
		setTractors([{}]);
		setTrailers([{}]);
		setContactInfo([{}])
	}

	const handleRemoveCarrier = (index: number) => {
		var p = [...carriers]
		if ((carriers.filter((e: any) => !e.Deleted).length - 1) !== 0) {
			if (!p[index].ID) {
				p.splice(index, 1)
			}
			else {
				p[index].Deleted = true
			}
			setCarriers(p)
		}
	}

	const handleClearForm = () => {
		handleClear()
		setClearOpen(false)
	}

	const handleSaveOpen = () => {
		setSaveOpen(true);
	};

	const handleSaveClose = () => {
		setSaveOpen(false);
	};

	const handleClearOpen = () => {
		setClearOpen(true);
	};

	const handleClearClose = () => {
		setClearOpen(false);
	};

	const handleAddCarrier = () => {
		var p = [...carriers]
		p.push({});
		setCarriers(p);
	}

	const handleAddDriver = () => {
		var p = [...drivers]
		p.push({});
		setDrivers(p);
	}

	

	const handleRemoveDriver = (index: number) => {
		var p = [...drivers]
		if ((drivers.filter((e: any) => !e.Deleted).length - 1) !== 0) {
			if (!p[index].ID) {
				p.splice(index, 1)
			}
			else {
				p[index].Deleted = true
			}
			setDrivers(p)
		}
	}

	const handleGetDDLabel = (list: any[], option: any, listType: number) => {
		if (!option || option === '' || option === undefined)
			return ''
		if (listType && list && list.length > 0) {
			switch (listType) {
				case 1: //ID, Description
					if (option && option.hasOwnProperty('Description')) {
						return option.Description
					}
					else {
						let x = list.find((e: any) => { return (e.id === option) })
						if (x && x.hasOwnProperty('Description')) {
							return x.Description
						}
						else {
							return option
						}
					}
					break
				case 2:
					if (option && option.hasOwnProperty('Value')) {
						return option.Value
					}
					else {
						let x = list.find((e: any) => e.Key === option)
						if (x && x.hasOwnProperty('Value')) {
							return x.Value
						}
						else {
							return option
						}
					}
					break
				case 3: //ID, Description
					if (option && option.hasOwnProperty('Description')) {
						let str = option.Description
						if (option.hasOwnProperty('Country')) {
							str += " (" + option.Country + ")"
						}
						return str
					}
					else {
						let x = list.find((e: any) => { return (e.ID === option) })
						if (x && x.hasOwnProperty('Description')) {
							let str = x.Description
							if (x.hasOwnProperty('Country')) {
								str += " (" + x.Country + ")"
							}
							return str
						}
						else {
							return option
						}
					}
					break
			}
		}
		return option
	}

	const handleValueChange = async (field: string, value: any, index: number = 0) => {
		let p = { ...params };
		let c = [...carriers];
		let d = [...drivers];
		let pass = [...passengers];
		let trac = [...tractors];
		let trail = [...trailers];
		let contact = [...contactInfo];

		switch (field) {
			//CARRIER CASES
			case 'Carrier Name':
				c[index].CARRIER_NAME = value;
				break;
			case 'DOT Number':
				c[index].CARRIER_DOT_NUMBER = value;
				break;
			case 'Carrier Phone Number':
				c[index].CARRIER_PHONE_NUMBER = value;
				break;
			case 'Carrier Affiliates':
				c[index].CARRIER_AFFILIATES = value;
				break;
			case 'Carrier Street Number':
				c[index].CARRIER_STREET_NUMBER = value;
				break;
			case 'Carrier Street Name':
				c[index].CARRIER_STREET_NAME = value;
				break;
			case 'Carrier Street Suffix':
				c[index].CARRIER_STREET_SUFFIX = value;
				break;

			//DRIVER CASES
			case 'Driver First Name':
				d[index].DRIVER_FIRST_NAME = value;
				break;
			case 'Driver Middle Name':
				d[index].DRIVER_MIDDLE_NAME = value;
				break;
			case 'Driver Last Name':
				d[index].DRIVER_LAST_NAME = value;
				break;
			case 'Driver DOB':
				d[index].DRIVER_DOB = value;
				break;
			case 'Driver DL Number':
				d[index].DRIVER_DL_NUMBER = value;
				break;
			case 'Driver DL State':
				d[index].DRIVER_DL_STATE = value;
				break;
			case 'Driver Street Number':
				d[index].DRIVER_STREET_NUMBER = value;
				break;
			case 'Driver Street Name':
				d[index].DRIVER_STREET_NAME = value;
				break;
			case 'Driver Street Suffix':
				d[index].DRIVER_STREET_SUFFIX = value;
				break;
			case 'Driver Phone Number':
				d[index].DRIVER_PHONE_NUMBER = value;
				break;

			//PASSENGER CASES
			case 'Passenger First Name':
				pass[index].PASS_FIRST_NAME = value;
				break;
			case 'Passenger Middle Name':
				pass[index].PASS_MIDDLE_NAME = value;
				break;
			case 'Passenger Last Name':
				pass[index].PASS_LAST_NAME = value;
				break;
			case 'Passenger DOB':
				pass[index].PASS_DOB = value;
				break;
			case 'Passenger DL Number':
				pass[index].PASS_DL_NUMBER = value;
				break;
			case 'Passenger DL State':
				pass[index].PASS_DL_STATE = value;
				break;
			case 'Passenger Street Number':
				pass[index].PASS_STREET_NUMBER = value;
				break;
			case 'Passenger Street Name':
				pass[index].PASS_STREET_NAME = value;
				break;
			case 'Passenger Street Suffix':
				pass[index].PASS_STREET_SUFFIX = value;
				break;
			case 'Passenger Phone Number':
				pass[index].PASS_PHONE_NUMBER = value;
				break;

			//TRACTOR CASES
			case 'Tractor Identifiers':
				trac[index].TRACT_IDENTIFIERS = value;
				break;
			case 'Tractor VIN':
				trac[index].TRACT_VIN = value;
				break;
			case 'Tractor Plate Number':
				trac[index].TRACT_PLATE_NUMBER = value;
				break;
			case 'Tractor State':
				trac[index].TRACT_STATE = value;
				break;
			case 'Tractor Color':
				trac[index].TRACT_COLOR = value;
				break;
			case 'Tractor Make':
				trac[index].TRACT_MAKE = value;
				break;
			case 'Tractor Model':
				trac[index].TRACT_MODEL = value;
				break;

			//TRAILER CASES
			case 'Trailer Identifiers':
				trail[index].TRAILER_IDENTIFIERS = value;
				break;
			case 'Trailer VIN':
				trail[index].TRAILER_VIN = value;
				break;
			case 'Trailer Plate Number':
				trail[index].TRAILER_PLATE_NUMBER = value;
				break;
			case 'Trailer State':
				trail[index].TRAILER_STATE = value;
				break;
			case 'Trailer Commodities':
				trail[index].TRAILER_COMMODITY = value;
				break;
			case 'Trailer Broker':
				trail[index].TRAILER_BROKER = value;
				break;
			case 'Trailer Shipper':
				trail[index].TRAILER_SHIPPER = value;
				break;
			case 'Trailer Origin':
				trail[index].TRAILER_ORIGIN = value;
				break;
			case 'Trailer Destination':
				trail[index].TRAILER_DESTINATION = value;
				break;

			//CONTACT CASES
			case 'Contact Date':
				contact[index].CONTACT_DATE = value;
				break;
			case 'Contact Location':
				contact[index].CONTACT_LOCATION = value;
				break;
			case 'Items of Interest':
				contact[index].CONTACT_ITEM_INTEREST = value;
				break;
		}
		p.carriers = c[index];
		p.drivers = d[index];
		p.passengers = pass[index];
		p.tractors = trac[index];
		p.trailers = trail[index];
		p.contactInfo = contact[index];

		setParams(p);
		setCarriers(c);
		setDrivers(d);
		setPassengers(pass);
		setTractors(trac);
		setTrailers(trail);
		setContactInfo(contact);

	}

	const handleDropdowns = async () => {
		setStreetSuffixList([]);
		setStateList([]);

		var state = await getATFStateList('US');
		var streetSuffix = await getATFStreetSuffix();

		if (state && state?.length > 0) {
			setStateList(state);
		}

		if (streetSuffix && streetSuffix?.length > 0) {
			setStreetSuffixList(streetSuffix);
		}
	}

	React.useEffect(() => {
		handleDropdowns()
	}, [ecrawl])

	return (
		<Container>
			<div className="">
				{carriers.map((carrier: any, indx) => {
					return (
						<Box className="section">
							<Box className="header">
								<Typography variant="h5">
									Carrier Data
									<IconButton size={"small"} onClick={() => handleRemoveCarrier(indx)}
										color={"error"} title={"Remove Carrier"}><DeleteIcon /></IconButton>
								</Typography>
							</Box>
							<Box className="section-content">
								<Grid container spacing={1}>
									<Grid item xs={6}>
										<TextField fullWidth label="Carrier Name" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											value={carrier?.CARRIER_NAME || ''}
											onChange={(e) => handleValueChange('Carrier Name', e.target.value, indx)}
											error={!carrier?.CARRIER_NAME || carrier?.CARRIER_NAME?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="DOT#" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											value={carrier?.CARRIER_DOT_NUMBER || ''}
											onChange={(e) => handleValueChange('DOT Number', e.target.value, indx)}
											error={!carrier?.CARRIER_DOT_NUMBER || carrier?.CARRIER_DOT_NUMBER?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="Carrier Phone" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											value={carrier?.CARRIER_PHONE_NUMBER || ''}
											onChange={(e) => handleValueChange('Carrier Phone Number', e.target.value, indx)}
											error={!carrier?.CARRIER_PHONE_NUMBER || carrier?.CARRIER_PHONE_NUMBER?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="Affiliates" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											value={carrier?.CARRIER_AFFILIATES || ''}
											onChange={(e) => handleValueChange('Carrier Affiliates', e.target.value, indx)}
											error={!carrier?.CARRIER_AFFILIATES || carrier?.CARRIER_AFFILIATES?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="Street #" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											value={carrier?.CARRIER_STREET_NUMBER || ''}
											onChange={(e) => handleValueChange('Carrier Street Number', e.target.value, indx)}
											error={!carrier?.CARRIER_STREET_NUMBER || carrier?.CARRIER_STREET_NUMBER === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="Street Name" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											value={carrier?.CARRIER_STREET_NAME || ''}
											onChange={(e) => handleValueChange('Carrier Street Name', e.target.value, indx)}
											error={!carrier?.CARRIER_STREET_NAME || carrier?.CARRIER_STREET_NAME?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<Autocomplete
											popupIcon=""
											id="Carrier Street Suffix"
											disableClearable
											size={"small"}
											options={streetSuffixList}
											getOptionLabel={(option: any) => handleGetDDLabel(streetSuffixList, option, 1)}
											onChange={(event, value) => handleValueChange('Carrier Street Suffix', value.ID, indx)}
											onInputChange={(event, value: any) => value === '' ? handleValueChange('Carrier Street Suffix', '', indx) : () => { }}
											renderInput={(params) => (
												<TextField
													{...params}
													label="Street Type"
													InputProps={{
														...params.InputProps,
														type: 'search',
														style: { textAlign: 'center' }
													}}
													error={!carrier?.CARRIER_STREET_SUFFIX || carrier?.CARRIER_STREET_SUFFIX?.length === 0}
												/>
											)}
											value={carrier?.CARRIER_STREET_SUFFIX || ''}
										/>
									</Grid>
								</Grid>
							</Box>
							<div className="mx-4 mb-2">
								<IconButton size={"large"} onClick={handleAddCarrier} color={"inherit"}
									title={"Add Additional Carrier"}> <AddIcon />
								</IconButton>
								To add an additional carrier, click here
							</div>
						</Box>
					)
				})}
			</div>

			<div className="">
				{drivers.map((driver: any, indx) => {
					return (
						<Box className="section">
							<Box className="header">
								<Typography variant="h5">
									Driver Data
									<IconButton size={"small"} onClick={() => handleRemoveDriver(indx)}
										color={"error"} title={"Remove Driver"}><DeleteIcon /></IconButton>
								</Typography>
							</Box>
							<Box className="section-content">
								<Grid container spacing={1}>
									<Grid item xs={6}>
										<TextField fullWidth label="First Name" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											value={driver?.DRIVER_FIRST_NAME || ''}
											onChange={(e) => handleValueChange('Driver First Name', e.target.value, indx)}
											error={!driver?.DRIVER_FIRST_NAME || driver?.DRIVER_FIRST_NAME?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="Middle Name" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											value={driver?.DRIVER_MIDDLE_NAME || ''}
											onChange={(e) => handleValueChange('Driver Middle Name', e.target.value, indx)}
											error={!driver?.DRIVER_MIDDLE_NAME || driver?.DRIVER_MIDDLE_NAME?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="Last Name" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											value={driver?.DRIVER_LAST_NAME || ''}
											onChange={(e) => handleValueChange('Driver Last Name', e.target.value, indx)}
											error={!driver?.DRIVER_LAST_NAME || driver?.DRIVER_LAST_NAME?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField variant="outlined" margin='none' type={"date"} fullWidth
											size={"small"} label="Date of Birth"
											InputLabelProps={{ shrink: true }}
											inputProps={{ style: { textAlign: 'center' }, max: todayDate }}
											value={driver?.DRIVER_DOB || ''}
											onChange={(e) => handleValueChange('Driver DOB', e.target.value, indx)}
											error={driver?.DRIVER_DOB == undefined || driver?.DRIVER_DOB?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="DL #" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											value={driver?.DRIVER_DL_NUMBER || ''}
											onChange={(e) => handleValueChange('Driver DL Number', e.target.value, indx)}
											error={!driver?.DRIVER_DL_NUMBER || driver?.DRIVER_DL_NUMBER?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<Autocomplete
											popupIcon=""
											id="DL State"
											disableClearable
											size={"small"}
											options={stateList}
											getOptionLabel={(option: any) => handleGetDDLabel(stateList, option, 1)}
											onChange={(event, value) => handleValueChange('Driver DL State', value.ID, indx)}
											onInputChange={(event, value: any) => value === '' ? handleValueChange('DL State', '', indx) : () => { }}
											value={driver?.DRIVER_DL_STATE ?? ''} renderInput={(params) => (
												<TextField
													{...params}
													label="State"
													InputProps={{
														...params.InputProps,
														type: 'search',
														style: driver?.HIGHLIGHTED_FIELDS && driver?.HIGHLIGHTED_FIELDS?.length > 0 && driver?.HIGHLIGHTED_FIELDS.includes('DRIVER_DL_STATE') ? { textAlign: 'center', background: 'yellow' } : { textAlign: 'center' }
													}}
													error={!driver?.DRIVER_DL_STATE || driver?.DRIVER_DL_STATE?.length === 0}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="Street #" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											value={driver?.DRIVER_STREET_NUMBER || ''}
											onChange={(e) => handleValueChange('Driver Street Number', e.target.value, indx)}
											error={!driver?.DRIVER_STREET_NUMBER || driver?.DRIVER_STREET_NUMBER === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="Street Name" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											value={driver?.DRIVER_STREET_NAME || ''}
											onChange={(e) => handleValueChange('Driver Street Name', e.target.value, indx)}
											error={!driver?.DRIVER_STREET_NAME || driver?.DRIVER_STREET_NAME?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<Autocomplete
											popupIcon=""
											id="Driver Street Suffix"
											disableClearable
											size={"small"}
											options={streetSuffixList}
											getOptionLabel={(option: any) => handleGetDDLabel(streetSuffixList, option, 1)}
											onChange={(event, value) => handleValueChange('Driver Street Suffix', value.ID, indx)}
											onInputChange={(event, value: any) => value === '' ? handleValueChange('Driver Street Suffix', '', indx) : () => { }} renderInput={(params) => (
												<TextField
													{...params}
													label="Street Type"
													InputProps={{
														...params.InputProps,
														type: 'search',
														style: { textAlign: 'center' },
													}}
													error={!driver?.DRIVER_STREET_SUFFIX || driver?.DRIVER_STREET_SUFFIX?.length === 0}
												/>
											)}
											value={driver?.DRIVER_STREET_SUFFIX || ''} />
									</Grid>
									<Grid item xs={12}>
										<TextField fullWidth label="Phone" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											value={driver?.DRIVER_PHONE_NUMBER || ''}
											onChange={(e) => handleValueChange('Driver Phone Number', e.target.value, indx)}
											error={!driver?.DRIVER_PHONE_NUMBER || driver?.DRIVER_PHONE_NUMBER?.length === 0}
										/>
									</Grid>
								</Grid>
							</Box>
							<div className="mx-4 mb-2">
								<IconButton size={"large"} onClick={handleAddDriver} color={"inherit"}
									title={"Add Additional Driver"}> <AddIcon />
								</IconButton>
								To add an additional driver, click here
							</div>
						</Box>
					)

				})}
			</div>

			<div className="">
				{passengers.map((passenger: any, indx) => {
					return (
						<Box className="section">
							<Box className="header">
								<Typography variant="h5">
									Co-Driver/Passenger Data
								</Typography>
							</Box>
							<Box className="section-content">
								<Grid container spacing={1}>
									<Grid item xs={6}>
										<TextField fullWidth label="First Name" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											value={passenger?.PASS_FIRST_NAME || ''}
											onChange={(e) => handleValueChange('Passenger First Name', e.target.value)}
											error={!passenger?.PASS_FIRST_NAME || passenger?.PASS_FIRST_NAME?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="Middle Name" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											value={passenger?.PASS_MIDDLE_NAME || ''}
											onChange={(e) => handleValueChange('Passenger Middle Name', e.target.value)}
											error={!passenger?.PASS_MIDDLE_NAME || passenger?.PASS_MIDDLE_NAME?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="Last Name" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											value={passenger?.PASS_LAST_NAME || ''}
											onChange={(e) => handleValueChange('Passenger Last Name', e.target.value)}
											error={!passenger?.PASS_LAST_NAME || passenger?.PASS_LAST_NAME?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField variant="outlined" margin='none' type={"date"} fullWidth
											size={"small"} label="Date of Birth"
											InputLabelProps={{ shrink: true }}
											inputProps={{ style: { textAlign: 'center' }, max: todayDate }}
											value={passenger?.PASS_DOB || ''}
											onChange={(e) => handleValueChange('Passenger DOB', e.target.value, indx)}
											error={passenger?.PASS_DOB === undefined || passenger?.PASS_DOB?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="DL #" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											value={passenger?.PASS_DL_NUMBER || ''}
											onChange={(e) => handleValueChange('Passenger DL Number', e.target.value)}
											error={!passenger?.PASS_DL_NUMBER || passenger?.PASS_DL_NUMBER?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<Autocomplete
											popupIcon=""
											id="Passenger DL State"
											disableClearable
											size={"small"}
											options={stateList}
											getOptionLabel={(option: any) => handleGetDDLabel(stateList, option, 1)}
											onChange={(event, value) => handleValueChange('Passenger DL State', value.ID, indx)}
											onInputChange={(event, value: any) => value === '' ? handleValueChange('Passenger DL State', '', indx) : () => { }}
											value={passenger?.PASS_DL_STATE ?? ''} renderInput={(params) => (
												<TextField
													{...params}
													label="State"
													InputProps={{
														...params.InputProps,
														type: 'search',
														style: passenger?.HIGHLIGHTED_FIELDS && passenger?.HIGHLIGHTED_FIELDS?.length > 0 && passenger?.HIGHLIGHTED_FIELDS.includes('DRIVER_DL_STATE') ? { textAlign: 'center', background: 'yellow' } : { textAlign: 'center' }
													}}
													error={!passenger?.PASS_DL_STATE || passenger?.PASS_DL_STATE?.length === 0}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField fullWidth label="Street Number" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											value={passenger?.PASS_STREET_NUMBER || ''}
											onChange={(e) => handleValueChange('Passenger Street Number', e.target.value)}
											error={!passenger?.PASS_STREET_NUMBER || passenger?.PASS_STREET_NUMBER === 0}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField fullWidth label="Street Name" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											value={passenger?.PASS_STREET_NAME || ''}
											onChange={(e) => handleValueChange('Passenger Street Name', e.target.value)}
											error={!passenger?.PASS_STREET_NAME || passenger?.PASS_STREET_NAME?.length === 0}
										/>
									</Grid>
									<Grid item xs={12}>
										<Autocomplete
											popupIcon=""
											id="Passenger Street Suffix"
											disableClearable
											size={"small"}
											options={streetSuffixList}
											getOptionLabel={(option: any) => handleGetDDLabel(streetSuffixList, option, 1)}
											onChange={(event, value) => handleValueChange('Passenger Street Suffix', value.ID, indx)}
											onInputChange={(event, value: any) => value === '' ? handleValueChange('Passenger Street Suffix', '', indx) : () => { }} renderInput={(params) => (
												<TextField
													{...params}
													label="Street Type"
													InputProps={{
														...params.InputProps,
														type: 'search',
														style: { textAlign: 'center' },
													}}
													error={!passenger?.PASS_STREET_SUFFIX || passenger?.PASS_STREET_SUFFIX?.length === 0}
												/>
											)}
											value={passenger?.PASS_STREET_SUFFIX || ''} />
									</Grid>
									<Grid item xs={12}>
										<TextField fullWidth label="Phone" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											value={passenger?.PASS_PHONE_NUMBER || ''}
											onChange={(e) => handleValueChange('Passenger Phone Number', e.target.value)}
											error={!passenger?.PASS_PHONE_NUMBER || passenger?.PASS_PHONE_NUMBER?.length === 0}
										/>
									</Grid>
								</Grid>
							</Box>
						</Box>
					)
				})}
			</div>

			<div className="">
				{tractors.map((tractor: any, indx) => {
					return (
						<Box className="section">
							<Box className="header">
								<Typography variant="h5">
									Tractor Data
								</Typography>
							</Box>
							<Box className="section-content">
								<Grid container spacing={1}>
									<Grid item xs={6}>
										<TextField fullWidth label="Tractor Identifiers" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											value={tractor?.TRACT_IDENTIFIERS || ''}
											onChange={(e) => handleValueChange('Tractor Identifiers', e.target.value)}
											error={!tractor?.TRACT_IDENTIFIERS || tractor?.TRACT_IDENTIFIERS?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="Tractor VIN" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											value={tractor?.TRACT_VIN || ''}
											onChange={(e) => handleValueChange('Tractor VIN', e.target.value)}
											error={!tractor?.TRACT_VIN || tractor?.TRACT_VIN?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="Tractor Plate" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											value={tractor?.TRACT_PLATE_NUMBER || ''}
											onChange={(e) => handleValueChange('Tractor Plate Number', e.target.value)}
											error={!tractor?.TRACT_PLATE_NUMBER || tractor.TRACT_PLATE_NUMBER?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<Autocomplete
											popupIcon=""
											id="Tractor State"
											disableClearable
											size={"small"}
											options={stateList}
											getOptionLabel={(option: any) => handleGetDDLabel(stateList, option, 1)}
											onChange={(event, value) => handleValueChange('Tractor State', value.ID, indx)}
											onInputChange={(event, value: any) => value === '' ? handleValueChange('Tractor State', '', indx) : () => { }}
											value={tractor?.TRACT_STATE ?? ''} renderInput={(params) => (
												<TextField
													{...params}
													label="State"
													InputProps={{
														...params.InputProps,
														type: 'search',
														style: tractor?.HIGHLIGHTED_FIELDS && tractor?.HIGHLIGHTED_FIELDS?.length > 0 && tractor?.HIGHLIGHTED_FIELDS.includes('DRIVER_DL_STATE') ? { textAlign: 'center', background: 'yellow' } : { textAlign: 'center' }
													}}
													error={!tractor?.TRACT_STATE || tractor?.TRACT_STATE?.length === 0}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="Color" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											value={tractor?.TRACT_COLOR || ''}
											onChange={(e) => handleValueChange('Tractor Color', e.target.value)}
											error={!tractor?.TRACT_COLOR || tractor?.TRACT_COLOR?.length === 0}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField fullWidth label="Make" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											value={tractor?.TRACT_MAKE || ''}
											onChange={(e) => handleValueChange('Tractor Make', e.target.value)}
											error={!tractor?.TRACT_MAKE || tractor?.TRACT_MAKE?.length === 0}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField fullWidth label="Model" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											value={tractor?.TRACT_MODEL || ''}
											onChange={(e) => handleValueChange('Tractor Model', e.target.value)}
											error={!tractor?.TRACT_MODEL || tractor?.TRACT_MODEL?.length === 0}
										/>
									</Grid>
								</Grid>
							</Box>
						</Box>
					)
				})}
			</div>

			<div className="">
				{trailers.map((trailer: any, indx) => {
					return (
						<Box className="section">
							<Box className="header">
								<Typography variant="h5">
									Trailer Data
								</Typography>
							</Box>
							<Box className="section-content">
								<Grid container spacing={1}>
									<Grid item xs={6}>
										<TextField fullWidth label="Trailer Identifiers" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											value={trailer?.TRAILER_IDENTIFIERS || ''}
											onChange={(e) => handleValueChange('Trailer Identifiers', e.target.value)}
											error={!trailer?.TRAILER_IDENTIFIERS || trailer?.TRAILER_IDENTIFIERS?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="Trailer VIN" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											value={trailer?.TRAILER_VIN || ''}
											onChange={(e) => handleValueChange('Trailer VIN', e.target.value)}
											error={!trailer?.TRAILER_VIN || trailer?.TRAILER_VIN?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="Trailer Plate" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											value={trailer?.TRAILER_PLATE_NUMBER || ''}
											onChange={(e) => handleValueChange('Trailer Plate Number', e.target.value)}
											error={!trailer?.TRAILER_PLATE_NUMBER || trailer?.TRAILER_PLATE_NUMBER?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<Autocomplete
											popupIcon=""
											id="Trailer State"
											disableClearable
											size={"small"}
											options={stateList}
											getOptionLabel={(option: any) => handleGetDDLabel(stateList, option, 1)}
											onChange={(event, value) => handleValueChange('Trailer State', value.ID, indx)}
											onInputChange={(event, value: any) => value === '' ? handleValueChange('Trailer State', '', indx) : () => { }}
											value={trailer?.TRAILER_STATE ?? ''} renderInput={(params) => (
												<TextField
													{...params}
													label="State"
													InputProps={{
														...params.InputProps,
														type: 'search',
														style: trailer?.HIGHLIGHTED_FIELDS && trailer?.HIGHLIGHTED_FIELDS?.length > 0 && trailer?.HIGHLIGHTED_FIELDS.includes('DRIVER_DL_STATE') ? { textAlign: 'center', background: 'yellow' } : { textAlign: 'center' }
													}}
													error={!trailer?.TRAILER_STATE || trailer?.TRAILER_STATE?.length === 0}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="Commodity" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											value={trailer?.TRAILER_COMMODITY || ''}
											onChange={(e) => handleValueChange('Trailer Commodity', e.target.value)}
											error={!trailer?.TRAILER_COMMODITY || trailer?.TRAILER_COMMODITY?.length === 0}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField fullWidth label="Brokered By" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											value={trailer?.TRAILER_BROKER || ''}
											onChange={(e) => handleValueChange('Trailer Broker', e.target.value)}
											error={!trailer?.TRAILER_BROKER || trailer?.TRAILER_BROKER?.length === 0}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField fullWidth label="Shipper" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											value={trailer?.TRAILER_SHIPPER || ''}
											onChange={(e) => handleValueChange('Trailer Shipper', e.target.value)}
											error={!trailer?.TRAILER_SHIPPER || trailer?.TRAILER_SHIPPER?.length === 0}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField fullWidth label="Origin (City and State)" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											value={trailer?.TRAILER_ORIGIN || ''}
											onChange={(e) => handleValueChange('Trailer Origin', e.target.value)}
											error={!trailer?.TRAILER_ORIGIN || trailer?.TRAILER_ORIGIN?.length === 0}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField fullWidth label="Destination (City and State)" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											value={trailer?.TRAILER_DESTINATION || ''}
											onChange={(e) => handleValueChange('Trailer Destination', e.target.value)}
											error={!trailer?.TRAILER_DESTINATION || trailer?.TRAILER_DESTINATION?.length === 0}
										/>
									</Grid>
								</Grid>
							</Box>
						</Box>
					)
				})}
			</div>

			<div className="">
				{contactInfo.map((contact: any, indx) => {
					return (
						<Box className="section">
							<Box className="header">
								<Typography variant="h5">
									Contact Data
								</Typography>
							</Box>
							<Box className="section-content">
								<Grid container spacing={1}>
									<Grid item xs={6}>
										<TextField variant="outlined" margin='none' type={"date"} fullWidth
											size={"small"} label="Contact Date"
											InputLabelProps={{ shrink: true }}
											inputProps={{ style: { textAlign: 'center' }, max: todayDate }}
											value={contact?.CONTACT_DATE || ''}
											onChange={(e) => handleValueChange('Contact Date', e.target.value, indx)}
											error={contact?.CONTACT_DATE === undefined || contact?.CONTACT_DATE?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="Contact Location" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											value={contact?.CONTACT_LOCATION || ''}
											onChange={(e) => handleValueChange('Contact Location', e.target.value)}
											error={!contact?.CONTACT_LOCATION || contact?.CONTACT_LOCATION?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="Items of Interest" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											value={contact?.CONTACT_ITEM_INTEREST || ''}
											onChange={(e) => handleValueChange('Items of Interest', e.target.value)}
											error={!contact?.CONTACT_ITEM_INTEREST || contact?.CONTACT_ITEM_INTEREST?.length === 0}
										/>
									</Grid>
								</Grid>
							</Box>
						</Box>
					)
				})}
				<Box sx={{ mt: 4, textAlign: 'right' }}>
					<ButtonGroup size="large">
						<Button onClick={handleClearOpen} sx={{ bgcolor: 'white', color: 'red', border: 1, borderColor: 'black' }}>Cancel</Button>
						<Dialog
							open={clearOpen}
							onClose={handleClearClose}
						>
							<DialogTitle>
								{"Cancel submission?"}
							</DialogTitle>
							<DialogContent>
								<DialogContentText>
									Are you sure you would like to cancel your submission and clear the form?
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={handleClearClose}>No</Button>
								<Button onClick={handleClearForm} autoFocus>
									Yes
								</Button>
							</DialogActions>
						</Dialog>
						<Button onClick={handleSaveOpen} sx={{ bgcolor: "white", color: "blue", border: 1, borderColor: "black" }}>Save</Button>
						<Dialog
							open={saveOpen}
							onClose={handleSaveClose}
						>
							<DialogTitle>
								{"Create new record?"}
							</DialogTitle>
							<DialogContent>
								<DialogContentText>
									Are you sure you would like to submit a new record with this data?
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={handleSaveClose}>No</Button>
								<Button onClick={handleSaveClose} autoFocus>
									Yes
								</Button>
							</DialogActions>
						</Dialog>
					</ButtonGroup>
				</Box>
			</div>
		</Container>
	);
};

export default ECrawlInput;