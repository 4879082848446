import {PersonInfo} from "../../interfaces/getDarInfo_interface";
import React, {useEffect, useState} from "react";
import {Button, IconButton, Tooltip} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import cap5Logo from "../../assets/Images/NJCAP5.png";
import dcLogo from "../../assets/Images/dc_logo.png";
import {SiteName} from "../../services/config.service";
import {SITE} from "../../interfaces/config.interface";

interface BTPersonNavigatorProps {
    loadingStates: any,
    CloseNavigator: any
}

const BTPersonNavigator: React.FC<BTPersonNavigatorProps> = ({loadingStates, CloseNavigator}) => {
    //if CloseNavigator(true) then don't navigate.
    
    useEffect(() => {
        if(loadingStates.length > 0 && loadingStates.filter((e:any) => e?.State === true).length === 0)
        {
            CloseNavigator(false)
        }
    }, [loadingStates]);
    
    const getSiteLogo = () => {
        if(SiteName == SITE.DCPROD || SiteName == SITE.DCDEMO || SiteName == SITE.DCLOCAL)
            return dcLogo
        else 
            return cap5Logo
    }
    
    
    return (
        <div style={{border:'2px solid black', borderRadius:'4px', height:'30vh'}}>
            <div className={"mb-1 flex justify-end mr-2"} >
                <Tooltip title="Cancel" placement="top" arrow style={{backgroundColor:'black'}}>
                    <IconButton size={"small"} style={{ color: "red" }} onClick={() => CloseNavigator(true)}><CloseIcon/></IconButton>
                </Tooltip>
            </div>
            <div className={"px-12"} style={{backgroundImage:`url(${getSiteLogo()})`, backgroundRepeat:'no-repeat', backgroundSize:'contain', backgroundPosition:'center', height:'20vh', maxHeight:'20vh'}}>
                <div className="pb-12" style={{backgroundColor:'rgb(255,255,255,0.5)', height:'100%'}}>
                    <div className="text-center" style={{fontSize:'20px', fontWeight:'bolder'}}> Waiting on data to finish loading... </div>
                    <div className={"text-center mb-4"} style={{fontSize:'12px', fontWeight:'bold', borderBottom:'1px solid black'}}> Please wait your page is being prepared for you </div>
                    {loadingStates?.filter((e:any) => e?.State === true).map((state:any) => { 
                        return(
                            <div className={"text-center mb-2"}>
                                <span style={{fontSize:'18px', fontWeight:'bolder'}}>{state?.Type}: </span>
                                <span className={"text-red-700 font-bold"} style={{fontSize:'16px'}}> Loading... </span>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className={"text-center"} style={{fontSize:'12px'}}> <Button style={{backgroundColor:'rgb(30, 58, 138)'}} variant={'contained'} onClick={() => CloseNavigator(false)}>Continue without these sections</Button> </div>
        </div>
    )
};

export default BTPersonNavigator;