import React from "react";
import { InvestigationObject } from "../../../interfaces/NJPORT/InvestigationLog.interface";
import {
  ColumnDirective,
  ColumnsDirective,
  ColumnMenu,
  GridComponent,
  Grid,
  Inject,
  Page,
  Sort,
  Filter,
  DetailRow,
  ExcelExport,
  PdfExport,
  Toolbar,
  Search,
  ExcelExportProperties,
  Resize,
  Reorder,
  SortSettingsModel,
  Edit,
  CommandColumn,
} from "@syncfusion/ej2-react-grids";
import { ClickEventArgs } from "@syncfusion/ej2-navigations";
import { Alert, Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Slide, Snackbar, Tooltip, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { VolunteerHireObject } from "../../../interfaces/NJPORT/VolunteerHire.interface";
import DeleteIcon from "@mui/icons-material/Delete";
import { DeleteVolunteerHire } from "../../../services/njport.service";
import CloseIcon from "@mui/icons-material/Close";
import { TransitionProps } from "@mui/material/transitions";

type SearchSubResultsProps = {
  volunteer: VolunteerHireObject[];
  handleEditReport: (rowDataID: any, isSubData: boolean,isedit:boolean, rowMainRec: any) => void;
};
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const VolunteerSearchSubResults: React.FC<SearchSubResultsProps> = ({
  volunteer,
  handleEditReport,
}) => {
  const [typedPersons, setTypedPersons] =
    React.useState<VolunteerHireObject[]>(volunteer);
    const [deleteDialogID, setDeleteDialogID] = React.useState<number>(0);
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [alertType, setAlertType] = React.useState<any>("success");
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false);
    const [alertText, setAlertText] = React.useState<string>("");
  const handleDataTyping = () => {
    if (volunteer && volunteer.length > 0) {
      let tempP: any = [...volunteer];

      for (let p of tempP) {
        if (p.Date_Received) {
          p.Date_Received = new Date(p.Date_Received);
        }
        if (p.Date_Status_Change) {
          p.Date_Status_Change = new Date(p.Date_Status_Change);
        }
        if (p.DOB) {
          p.DOB = new Date(p.DOB);
        }
        if (p.Date_Fingerprint_Return) {
          p.Date_Fingerprint_Return = new Date(p.Date_Fingerprint_Return);
        }
        if (p.Date_PAC_Card_Issued) {
          p.Date_PAC_Card_Issued = new Date(p.Date_PAC_Card_Issued);
        }
        if (p.CreateDate) {
          p.CreateDate = new Date(p.CreateDate);
        }
        if (p.UpdateDate) {
          p.UpdateDate = new Date(p.UpdateDate);
        }
      }
      setTypedPersons(tempP);
    } else {
      setTypedPersons([]);
    }
  };
  React.useEffect(() => {
    handleDataTyping();
  }, [volunteer]);

  let grid: Grid | null;
  const refreshDataSource = () => {
    if (grid) {
      grid.dataSource = typedPersons;
    }
  };

  React.useEffect(() => {
    refreshDataSource();
  }, [typedPersons]);

  let sortSettings: SortSettingsModel = {
    columns: [{ field: "ID", direction: "Ascending" }],
  };

  const GridToolsClick = (args: ClickEventArgs) => {
    if (grid && args.item.text === "Excel Export") {
      let excelProp: ExcelExportProperties = {};
      excelProp.fileName = "Profile_Report.xlsx";

      grid.excelExport(excelProp);
    } else if (grid && args.item.text === "PDF Export") {
      grid.pdfExport();
    }
  };

  const deleteReport = async () => {
    let id: number = deleteDialogID;
    if (id && id > 0) {
      let data = {
        id: id,
        editStatus: "DELETE",
        deleted: 1,
      };
      let isDeleted = await DeleteVolunteerHire(data);
      //handleSubmit()
      if (isDeleted) {
        setAlertType("success");
        setAlertOpen(true);
        setAlertText(
          "Delete Successful (Changes Will Take Effect After The Next Search)",
        );
      } else {
        setAlertType("error");
        setAlertOpen(true);
        setAlertText("Unable to delete.");
      }
    }
    setOpenDeleteDialog(false);
  };
  const showActionView = (rowData: any): any => {
    return (
      <span>
        <ButtonGroup>
          <Tooltip title="Edit" placement="top" arrow>
            <IconButton
              onClick={() => {
                handleEditReport(rowData.ID, true,true, rowData.MainRec);
              }}
            >
              <EditIcon fontSize="small" color="primary" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete" placement="top" arrow>
            <IconButton
              onClick={() => {
                setOpenDeleteDialog(true);
                setDeleteDialogID(rowData.ID);
              }}
            >
              <DeleteIcon fontSize="small" color="error" />
            </IconButton>
          </Tooltip>
        </ButtonGroup>
      </span>
    );
  };

  return (
    <>
       <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={() => {
          setAlertOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setAlertOpen(false);
          }}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {alertText}
        </Alert>
      </Snackbar>
      {typedPersons && typedPersons.length > 0  ? (
        <GridComponent
          dataSource={typedPersons}
          allowPaging={true}
          pageSettings={{
            pageSize: 25,
            pageSizes: [10, 25, 50, 100],
          }}
          allowSorting={true}
          sortSettings={sortSettings}
          allowMultiSorting={true}
          allowFiltering={true}
          filterSettings={{ type: "CheckBox" }}
          allowTextWrap={true}
          textWrapSettings={{ wrapMode: "Both" }}
          allowExcelExport={true}
          allowPdfExport={true}
          toolbar={["ExcelExport", "Search"]}
          toolbarClick={GridToolsClick}
          ref={(g) => (grid = g)}
          allowSelection={true}
          allowResizing={true}
          rowHeight={24}
        >
          <ColumnsDirective>
            <ColumnDirective
              template={showActionView}
              width={125}
              minWidth={125}
              maxWidth={140}
              headerText="Action"
            />
             <ColumnDirective
              field="ID"
              width={140}
              minWidth={140}
              maxWidth={140}
              headerText="ID"
              customAttributes={{ class: ["e-attr"] }}
            />
            <ColumnDirective
              field="Port"
              width={140}
              minWidth={140}
              maxWidth={140}
              headerText="Port"
              customAttributes={{ class: ["e-attr"] }}
            />
            <ColumnDirective
              field="ShipTerminal"
              width={150}
              minWidth={150}
              maxWidth={150}
              headerText="ShipTerminal"
              customAttributes={{ class: ["e-attr"] }}
            />
            <ColumnDirective
              field="Job_Code"
              width={140}
              minWidth={140}
              maxWidth={140}
              headerText="Job Title"
              customAttributes={{ class: ["e-attr"] }}
            />

            <ColumnDirective
              field="JobRequests"
              width={160}
              minWidth={160}
              maxWidth={160}
              headerText="Job Requests"
              customAttributes={{ class: ["e-attr"] }}
            />
            <ColumnDirective
              field="CompanyHiring"
              width={180}
              minWidth={180}
              maxWidth={180}
              headerText="Company Hiring"
              customAttributes={{ class: ["e-attr"] }}
            />
            <ColumnDirective
              field="THEICHiring"
              width={160}
              minWidth={160}
              maxWidth={160}
              headerText="THEIC Hiring"
              customAttributes={{ class: ["e-attr"] }}
            />
            <ColumnDirective
              field="RequestsFilled"
              width={180}
              minWidth={180}
              maxWidth={180}
              headerText="Requests Filled"
              customAttributes={{ class: ["e-attr"] }}
            />
           
            <ColumnDirective
              field="Unfilled"
              width={140}
              minWidth={140}
              maxWidth={140}
              headerText="Unfilled"
              customAttributes={{ class: ["e-attr"] }}
            />
            <ColumnDirective
              field="Remaining"
              width={140}
              minWidth={140}
              maxWidth={140}
              headerText="Remaining"
              customAttributes={{ class: ["e-attr"] }}
            />
          </ColumnsDirective>
          <Inject
            services={[
              ColumnMenu,
              Sort,
              Page,
              Filter,
              DetailRow,
              Toolbar,
              ExcelExport,
              PdfExport,
              Search,
              Resize,
              Reorder,
              Edit,
              CommandColumn,
            ]}
          />
        </GridComponent>
      ) : (
        <></>
      )}
        <Dialog
        open={openDeleteDialog}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth={"sm"}
        scroll="paper"
        //onClose={handleDeleteDialogClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2>Delete Report</h2>
          <IconButton
            onClick={() => {
              setOpenDeleteDialog(false);
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ display: "flex", justifyContent: "center" }}>
          <Typography>
            Do you really want to delete this profile report? You can't undo
            this action.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setOpenDeleteDialog(false);
            }}
          >
            Cancel
          </Button>
          <Button onClick={deleteReport}>Delete Report</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default VolunteerSearchSubResults;
