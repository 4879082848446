import React from 'react'
import {Card, CardActions, CardContent, Typography, Button} from "@mui/material"; 

interface SystemNoticeProps
{
    AcceptSystemNotice:any;
}


const SystemNotice: React.FC<SystemNoticeProps> = ({AcceptSystemNotice}) => {
    const DeclineLogout = () => {
        window.location.reload()
    }


    const submitNotice = (e:any) => {
        e.preventDefault();
        AcceptSystemNotice()
    }

    return (
        <div>
            <form onSubmit={submitNotice}>
                <Card>
                    <CardContent>
                        <Typography sx={{ fontSize: 28, fontWeight:"bold" }} gutterBottom className={"text-center w-min-screen"}>
                            SYSTEM NOTICE
                        </Typography>
                        <Typography sx={{ fontSize: 18, fontWeight:"bold" }}>
                           By accepting this notice you agree to the following:
                        </Typography>
                        <ul style={{textAlign: 'left', listStyleType:"circle"}} className={"p-5"}>
                            <li>
                                You are accessing the Backtrace application that queries collated fact/event-based law enforcement information related to arrested or suspected offenders and provides tools to sort, filter, and analyze such information to support precision-based crime suppression and investigative initiatives.  Each source from which Backtrace draws data has its own existing use policies.  The use of Backtrace does not alter any existing policy.
                            </li>
                            <li>
                                Your use of the Backtrace application is restricted to criminal and threat related investigative and analytical purposes, and associated training.
                            </li>
                            <li>
                                No query of Backtrace may be made solely on ethnicity, race or religion of an individual or solely on the exercise of rights guaranteed by the First Amendment or the lawful exercise of any rights secured by the Constitution of the laws of the United States and New Jersey.
                            </li>
                            <li>
                                Information obtained through Backtrace shall not be disseminated for purposes outside of a law enforcement investigative purpose. Unauthorized or improper use of the system may result in disciplinary action, civil and criminal penalties. By using the system, you understand and consent to the following: You have no reasonable expectation of privacy regarding your use of the system. At any time, your use of the system may be monitored to ensure that rules are being followed. Any queries or communication transmitted through the system may be disclosed in compliance with an internal affairs inquiry.
                            </li>
                            <li>
                                Audits are routinely conducted on the system to ensure that rules are followed. Failure to comply with this agreement will result in the termination of your Backtrace account.
                            </li>
                            <li>
                                We use cookies on our website to see how you interact with it. By accepting, you agree to our use of such cookies. <a href="https://gtbm.com/privacy-policy" style={{ color: 'blue' }} target="_blank">Privacy Policy | GTBM </a>
                            </li>
                        </ul>
                    </CardContent>
                    <CardActions>
                        <Button style={{"margin":"5px"}} variant={"outlined"} color={"success"} type="submit" >
                            Accept
                        </Button>
                        <Button style={{ "margin": "5px" }} variant={"outlined"} color={"error"} type="button" onClick={DeclineLogout}>
                           Decline
                        </Button>
                    </CardActions>
                </Card>
            </form>
      </div>
    )
}

export default SystemNotice