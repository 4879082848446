import React, {useState} from "react";
import {Account} from "../../../interfaces/auth_interface";
import {getUser, isUserPermission} from "../../../services/auth.service";
import {RunGetDailyStats} from "../../../services/dashboard.service";
import {getDarInfoResponse} from "../../../interfaces/getDarInfo_interface";
import INDashboardLayout from "../../../components/Dashboard/INVESTIGATION/_dashboardLayout";

function InDashboard() {
    let [user, setUser] = useState<Account>(getUser())
    const [data, setData] = useState<any>({})

    // const GetStats = async ( params:any) => {
    //     let qRes:getDarInfoResponse = await RunGetDailyStats(params)
        
    //     setData(qRes)
    // }
    
    
return (
        <div>
            {isUserPermission('Arrest')?
                <>
                    <INDashboardLayout  data={data}/>
                </>
            :<></>}
        </div>
    );
}

export default InDashboard;