import React, { useState } from 'react';
import { SiteName } from '../../services/config.service';
import ECrawlSearchResults from '../../components/tools/ecrawl/_searchResultsECrawl';

function ECrawl() {
    return (
        <div id="ToolsArrest">
            <ECrawlSearchResults />
        </div>
    );
}

export default ECrawl;
