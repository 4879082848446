import React from 'react'
import {
    Alert,
    Box,
    Button, ButtonGroup, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Slide, Snackbar, Tab, Tabs, Tooltip, Typography
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import {ProfileReportPersonInfo} from "../../../interfaces/getDarInfo_interface";
import {
    ColumnDirective,
    ColumnsDirective,
    ColumnMenu,
    GridComponent,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search, ExcelExportProperties,
    Resize, Reorder, SortSettingsModel, Edit, CommandColumn, CommandModel, rowSelected
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import ProfileReportInputForm from './_profileReportInputForm';
import { JAOS } from '../../../services/JAOS.service';
import NoPersonImage from  '../../../assets/Images/noImageAvailable.png';
import { PostAdd, List, Person, ConstructionOutlined } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { TransitionProps } from '@mui/material/transitions';
import { DeleteProfileReport, GetProfileReport, GetProfileReportPDF, SetProfileReport } from '../../../services/account.service';
import { useHistory } from "react-router-dom";
import {isUserPermission} from "../../../services/auth.service";
import BTPersonIndicatorGrid from "../../shared/_PersonIndicators";
import {getValue} from "@syncfusion/ej2-base";
import { profileObject } from '../../../interfaces/PersonReport.interface';
import ProfessionDetailInfo from '../../Detail/_ProfessionDetailInfo';
import noImage from "../../../assets/Images/cbimage.png";

interface SearchResultsProps {
    data: ProfileReportPersonInfo[],
    Persons?: any,
    handleSubmit: any,
    SubmitSearch: any,

}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const SearchResults: React.FC<SearchResultsProps> = ({data, handleSubmit, Persons = null, SubmitSearch })=> {
    const [typedPersons, setTypedPersons] = React.useState<any[]>([]);
    //const [params, setParams] = React.useState({})
    const [params, setParams] = React.useState({ FirstName: "", MI: "", LastName: "", DOB: "", FBI: "", SBI: "", AgeRangeStart: "", AgeRangeEnd: "", toDate:"", fromDate:"", Gender: "", Status: "", ReportNum: ""})
    const [tabValue, setTabValue] = React.useState('1');
    const [editTabName, setEditTabName] = React.useState('New Report');
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
    const [openEditDialog, setOpenEditDialog] = React.useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [EditDialog, setEditDialog] = React.useState<boolean>(false)

    // const [editDialogID, setEditDialogID] = React.useState<number>(0);
    const [editDialogID, setEditDialogID] = React.useState<any>();
    const [deleteDialogID, setDeleteDialogID] = React.useState<number>(0);
    const [duplicateReport, setDuplicateReport] = React.useState<boolean>(false);
    const [reportUrl, setReportUrl] = React.useState<string | undefined>(undefined);
    const [hash, setHash] = React.useState<string | null>(null);
    const [editingRecord, setEditingRecord] = React.useState<profileObject | null>(null);
    const [personDetails, setPersonDetails] = React.useState<profileObject | null>(null);
    //const [personDetails, setPersonDetails] = React.useState<any>(null);
    const [alertType, setAlertType] = React.useState<any>("success")
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false)
    const [alertText, setAlertText] = React.useState<string>("")
    const [clear, setClear] = React.useState<boolean>(false);
    const [personList, setPersonList] = React.useState<any>();





    let grid: Grid | null;
    const history = useHistory();

    const [initialLoading, setInitialLoading] = React.useState(false);


    const handleDataTyping = () =>
    {
        if (data && data.length > 0) {
            let tempP: any = [...data]

            for (let p of tempP) {
                if (p.DOB) {
                    p.DOB = new Date(p.DOB)
                }
                if (p.ReportDate) {
                    p.ReportDate = new Date(p.ReportDate)
                }
                if(p.Pic) {
                    p.Pic = "data:image/jpeg;base64," + p.Pic;
                }
                if(p.Status) {
                    switch(p.Status) {
                        case ('InProgress'):
                            p.Status = 'In Progress'
                            break;
                        case ('ForReview'):
                            p.Status = 'For Review'
                            break;
                    }
                }
            }
            setTypedPersons(tempP);
        }

    }


    // const editReport = () => {
    //     history.push('/tools/profile_report');
    //     setOpenConfirmDialog(false);
    //     setDuplicateReport(true);
    //     let l = new JAOS();
    //     let hashID = l.objToStack(Persons);
    //     setHash(hashID);
    //     setEditTabName('Edit Report');
    //     setTabValue('2'); 
    // }

    const cloneReport = async (id : number | null = null) => {

        if (editDialogID && editDialogID > 0 || id && id > 0) {
            let record = {ProfileReportObject:{ID : editDialogID ? editDialogID : id}}
            let res = await GetProfileReport(record)
            res.newReport = true
            setEditingRecord(res)
            setEditTabName('New Report');
            setTabValue('2');
        }
        setOpenEditDialog(false);

    }

    React.useEffect(() => {
        handleDataTyping();
    },[data])

    React.useEffect(() => {
        handlePersonDetails();
    },[Persons])


    React.useEffect(() => {
        refreshDataSource();
    },[typedPersons])



    // const miniPersonImage = (person: any): any => {
    //     return (
    //         <img src={person?.Pic ? person?.Pic : NoPersonImage} style={{"width":"120px", "height":"100px"}} alt={"Person Image"}/>
    //     )
    // }

    const showActionView = (rowData: any): any => {
        return (
            <span>
                <ButtonGroup>
                    <Tooltip title="View" placement="top" arrow>
                            <IconButton onClick={() => {viewReport(rowData.ID) }}><VisibilityIcon fontSize='small' color='success'/></IconButton>
                    </Tooltip>
                    {isUserPermission('NJSP') ?
                        <Tooltip title="Edit" placement="top" arrow>
                            <IconButton onClick={() => {setOpenEditDialog(true); setEditDialogID(rowData.ID)}}><EditIcon fontSize='small' color='primary'/></IconButton>
                        </Tooltip>
                        : <></>}
                    {isUserPermission('NJSP') ?
                        <Tooltip title="Delete" placement="top" arrow>
                            <IconButton onClick={() => {setOpenDeleteDialog(true); setDeleteDialogID(rowData.ID) }}><DeleteIcon fontSize='small' color='error'/></IconButton>
                        </Tooltip>
                        : <></>}
                    
                </ButtonGroup>
            </span>);
    }

    const deleteReport = async () => {
        let id: number = deleteDialogID;
        if(id && id > 0){
            let isDeleted = await DeleteProfileReport(id.toString())
            //handleSubmit()
            if (isDeleted){
                setAlertType('success')
                setAlertOpen(true)
                setAlertText("Delete Successful (Changes Will Take Effect After The Next Search)")
            }
            else{
                setAlertType('error')
                setAlertOpen(true)
                setAlertText("Unable to delete.")
            }

        }
        setOpenDeleteDialog(false)
    };

    const viewReport = async (rowData: any) => {
        setReportUrl(undefined)
        let pdfUrl = await GetProfileReportPDF(rowData);
        if (pdfUrl) {
            setReportUrl(pdfUrl);
            setOpenDialog(true);
        }
    };


    const handleEditReport = async () => {
        setDuplicateReport(false);
        if (editDialogID && editDialogID > 0) {
            let record = {ProfileReportObject:{ID : editDialogID}}
            let res = await GetProfileReport(record)
            setEditingRecord(res)
            setEditTabName('Edit Report');
            setTabValue('2');
        }
        setOpenEditDialog(false);
    };

    const handlePersonDetails = async () =>{
        if (Persons && Persons.PersonInfo){

            params.FirstName= Persons.PersonInfo.FName
            params.LastName= Persons.PersonInfo.LName
            params.MI= Persons.PersonInfo.MName
            //params.DOB= Persons.PersonInfo.DOB
            params.FBI= Persons.PersonInfo.FBI
            params.SBI = Persons.PersonInfo.SBINo
            SubmitSearch(params, 'Entry Date', 'AND',[]).then((response:any) => {
                if (Persons && response && response.length > 0) {
                    //get index of lastest record 
                    let id = response[0].ID
                    cloneReport(id)

                }
                //if a profile report doesn't exist, create new object with existing info and populate report
                else if (Persons && !response || response.length === 0){
                    let tempObject : profileObject = {
                        PersonObject:{},
                        ProfileReportObject:{},
                        DeconObject:[],
                        CriminalActivityObject:{},
                        CustodyHistoryObject:{},
                        PersonDetailsObject:{},
                        PoliceContactLocations:{},
                        AdditionalInfo:[],
                        PicObject:{},
                        PreparedBy:[],
                        ReviewedBy:[],
                        Files:[],
                        newReport: true
                    }


                    if(tempObject.PersonObject){
                        tempObject.PersonObject.FirstName = Persons.PersonInfo.FName ? handleSentenceCase(Persons.PersonInfo.FName) : ''
                        tempObject.PersonObject.LastName = Persons.PersonInfo.LName ? handleSentenceCase(Persons.PersonInfo.LName) : ''
                        tempObject.PersonObject.MiddleName = Persons.PersonInfo.MName ? handleSentenceCase(Persons.PersonInfo.MName) : ''
                        tempObject.PersonObject.DOB = Persons.PersonInfo.DOB ? Persons.PersonInfo.DOB : ''
                        tempObject.PersonObject.SBI = Persons.PersonInfo.SBINo ? Persons.PersonInfo.SBINo : ''
                        tempObject.PersonObject.FBI = Persons.PersonInfo.FBI
                        //Negative RTCC Databases , Trinitarios (NCIC), Blood “Piru” (Newark PD)
                        tempObject.PersonObject.Gang = Persons.PersonInfo.GANGRELATED === 'Y' ? 'Yes' : 'No'
                        tempObject.PersonObject.Phone = Persons.PhoneInfo && Persons.PhoneInfo.lengh > 0 ? Persons.PhoneInfo[Persons.PhoneInfo.length - 1].Phone : ''
                        //DNA on file is DNA info?  ex Profile in CODIS , No,  Collected
                        tempObject.PersonObject.DNA = Persons.DNAInfo && Persons.DNAInfo.length > 0 ? 'Yes' : 'No'
                        tempObject.PersonObject.Address = Persons.PersonInfo.Address ? Persons.PersonInfo.Address : ''
                        tempObject.PersonObject.City = Persons.PersonInfo.City ? Persons.PersonInfo.City : ''
                        tempObject.PersonObject.State = Persons.PersonInfo.State ? Persons.PersonInfo.State : ''
                        //tempObject.PersonObject.Gender = Persons.PersonInfo.SEX ? Persons.PersonInfo.SEX : ''
                        if(Persons.PersonInfo.SEX ){
                            if(Persons.PersonInfo.SEX === 'M' || Persons.PersonInfo.SEX  === 'Male'){
                                tempObject.PersonObject.Gender = 'Male'
                            }
                            else if (Persons.PersonInfo.SEX === 'F' || Persons.PersonInfo.SEX  === 'Female'){
                                tempObject.PersonObject.Gender = 'Female'
                            }
                            else{
                                tempObject.PersonObject.Gender = ''
                            }
                        }

                    }

                    if(tempObject.PicObject){
                        tempObject.PicObject.Pic = Persons.ImageInfo && Persons.ImageInfo.length > 0 ? Persons.ImageInfo[Persons.ImageInfo.length - 1].Image : tempObject.PicObject.Pic = null
                    }

                    if(tempObject.CriminalActivityObject){
                        // Arrest Info or Person Info
                        let tempStatuteArr=[]
                        let tempAgencyArr:string[]=[]
                        let tempTotalArr:string[]=[]
                        if (Persons.ArrestInfo.length > 0 && Persons.ArrestInfo){
                            for (let item of Persons.ArrestInfo){
                                tempStatuteArr.push(item.STATUTEDESCRIPTION.trim())
                                tempAgencyArr.push(handleSentenceCase(item.ORIGINATINGORINAME))
                                tempTotalArr.push(handleSentenceCase(item.DTOFARREST))

                            }
                            tempObject.CriminalActivityObject.PriorCharges = tempStatuteArr.join(', ')
                            tempObject.CriminalActivityObject.ArrestingAgency = tempAgencyArr.filter((item , index) => tempAgencyArr.indexOf(item) === index ).join(', ')
                            tempObject.CriminalActivityObject.TotalArrest= tempTotalArr.filter((item , index) => tempTotalArr.indexOf(item) === index ).length

                        }
                    }
                    setEditingRecord(tempObject)
                    setEditTabName('New Report');
                    setTabValue('2');
                }

            })
        }


    }

    const gridTemplateIndicator = (person: any): any => {
        return (<>
            <BTPersonIndicatorGrid person={person} />
        </>);
    }


    const GridToolsClick = (args: ClickEventArgs) => {

        if (grid && args.item.text === "Excel Export") {

            let excelProp: ExcelExportProperties = {}
            excelProp.fileName = 'Profile_Report.xlsx';

            grid.excelExport(excelProp);

        } else if (grid && args.item.text === 'PDF Export') {
            grid.pdfExport();
        }
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        //history.push('/tools/profile_report');
        if (newValue == '1') {
            setEditTabName('New Report');
            setEditingRecord(null)
            setPersonDetails(null)
            setTabValue('1')
            //handleSubmit();
        }

        setTabValue(newValue);
    };


    const refreshDataSource = () => {
        if (grid) {
            grid.dataSource = typedPersons;
        }
    };

    const handleReportSubmit = async (submitObj : profileObject, changeTab : boolean) => {
        let saveRequest = await SetProfileReport(submitObj)
        if(saveRequest.IsValid && changeTab){
            setAlertType('success')
            setAlertText('Report has been saved!(Changes Will Take Effect After The Next Search)')
            setAlertOpen(true)
            setTabValue('1')
            setEditTabName('New Record')
            setEditingRecord({})

        }
        else if (saveRequest.IsValid && !changeTab){
            setAlertType('success')
            setAlertText('Report has been saved!')
            setAlertOpen(true)
        }
        else
        {
            setAlertType('error')
            setAlertText('Record Saved Unsuccessful ')
            setAlertOpen(true)
        }


    };



    const handleSentenceCase = (args: any) =>{
        //return(args[0].toUpperCase() + args.slice(1).toLowerCase())
        args = args.toLowerCase()
        args = args.split(' ')
        for (var i = 0; i < args.length; i++) {
            args[i] = args[i].charAt(0).toUpperCase() + args[i].slice(1)
        }
        return args.join(' ');


    }

    const rowSelected = (rowData: any) =>{
        if(rowData && rowData.data.ID){
            viewReport(rowData.data.ID)
        }

    }

    let sortSettings: SortSettingsModel = {
        columns: [{field: 'ID', direction: 'Descending' }]
    };

    return (
        <div className="m-5 p-5">
            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={ () => { setAlertOpen(false)}}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertType} sx={{ width: '100%' }}>
                    {alertText}
                </Alert>
            </Snackbar>
            <Box className="pt-3" style={{ justifyContent:'center' }}>
                <TabContext value={tabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleTabChange} variant="fullWidth">
                            <Tab icon={<List />} label="List View"  value={'1'}/>
                            {isUserPermission('NJSP') ?  <Tab icon={<PostAdd />} label={editTabName} value={'2'} /> : <></> }
                        </TabList>
                    </Box>
                    <TabPanel value='1'>
                        <div className={"pl-10 pr-10 mt-5"}>
                            {typedPersons && typedPersons.length > 0 ? <>
                                <h4 className={"font-bold pl-5"}> {typedPersons?.length} Records Found</h4>
                                <GridComponent
                                    dataSource={typedPersons}
                                    allowPaging={true}
                                    pageSettings={{pageSize: 25, pageSizes: [10,25,50,100]}}
                                    allowSorting={true}
                                    sortSettings={sortSettings}
                                    allowMultiSorting={true}
                                    allowFiltering={true}
                                    filterSettings={{type: 'CheckBox'}}
                                    allowTextWrap={true}
                                    textWrapSettings={{ wrapMode: 'Both' }}
                                    allowExcelExport={true}
                                    allowPdfExport={true}
                                    toolbar={['ExcelExport','Search']}
                                    toolbarClick={GridToolsClick}
                                    //excelExportComplete={excelExportComplete}
                                    ref={g=> grid = g}
                                    allowSelection={true}
                                    // selectionSettings={{
                                    //     persistSelection: true,
                                    //     type: "Single",
                                    //     mode: "Cell"}}
                                    allowResizing={true}
                                    rowHeight={24}
                                    rowSelected={rowSelected}
                                    //cellSelected = {(event:any) => {cellSelected(event)}}
                                    // rowDataBound={(args : any) => {
                                    //     if (args.row) {
                                    //         if (getValue('FoundJail', args.data)  === 2){
                                    //             args.row.classList.add('red');
                                    //         }
                                    //     }
                                    // }}
                                >
                                    <ColumnsDirective>
                                        <ColumnDirective field='ID' visible={false} width={140} minWidth={140} maxWidth={140} headerText='ID' customAttributes={{ class: ['e-attr']}}/>
                                        <ColumnDirective field='FName'  width={140} minWidth={140} maxWidth={140} headerText='First Name' customAttributes={{ class: ['e-attr']}}/>
                                        <ColumnDirective field='MName' width={110} minWidth={110} maxWidth={110} headerText='MI' customAttributes={{ class: ['e-attr'] }}/>
                                        <ColumnDirective field='LName' width={140} minWidth={140} maxWidth={140} headerText='Last Name' customAttributes={{ class: ['e-attr'] }}/>
                                        {/* <ColumnDirective field='DOB' width={115} minWidth={115} maxWidth={115}   format={{ type: 'date', format: 'MM/dd/yyyy' }} headerText='DOB' customAttributes={{ class: ['e-attr'] }} /> */}
                                        {/* <ColumnDirective field='Gender' width={110} minWidth={110} maxWidth={110} headerText='Sex' customAttributes={{ class: ['e-attr'] }}/> */}
                                        <ColumnDirective field='ReportDate' width={140} minWidth={140} maxWidth={140} format={{ type: 'date', format: 'MM/dd/yyyy' }} headerText='Report Date' customAttributes={{ class: ['e-attr'] }} />
                                        <ColumnDirective field='ReportType' width={140} minWidth={140} maxWidth={140} headerText='Type' customAttributes={{ class: ['e-attr'] }} />
                                        <ColumnDirective field='ReportNum' width={120} minWidth={120} maxWidth={120} headerText='Report #' customAttributes={{ class: ['e-attr'] }} />
                                        {/* <ColumnDirective field='Status' width={120} minWidth={120} maxWidth={120} headerText='Status' customAttributes={{ class: ['e-attr'] }} /> */}
                                        <ColumnDirective template={gridTemplateIndicator} maxWidth={300} headerText='Indicator' field={"Indicator"} customAttributes={{ class: ['e-attr'] }}/>
                                        <ColumnDirective template={showActionView} width={110} minWidth={110} maxWidth={120} headerText='Action'   />
                                    </ColumnsDirective>
                                    <Inject services={[ColumnMenu, Sort, Page, Filter, DetailRow, Toolbar, ExcelExport,PdfExport,Search, Resize, Reorder, Edit, CommandColumn]}/>
                                </GridComponent>
                            </>:<div className="font-bold text-center text-lg pt-3">NO Matching Records Found</div>}
                        </div>
                    </TabPanel>
                    {isUserPermission('NJSP') ?
                        <TabPanel value='2'>
                            {/* {tabValue && tabValue === '2' ? <ProfileReportInputForm handleReportSubmit={handleReportSubmit} duplicateReport={duplicateReport} person = {hash} editID = {editDialogID} /> : <></>} */}
                            {tabValue && tabValue === '2' ? <ProfileReportInputForm handleReportSubmit={handleReportSubmit} editReport={editingRecord}  personDetails={personDetails} /> : <></>}
                        </TabPanel>
                        : <></>}
                </TabContext>
            </Box>
            <Dialog
                open={openDialog}
                TransitionComponent={Transition}
                keepMounted
                fullWidth
                maxWidth={'xl'}
                style={{'justifyContent': 'center', 'height': '900px'}}
                //onClose={handleDialogClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle style={{'display': 'flex', 'justifyContent': 'space-between', 'alignItems': 'center'}}><h2>Profile Report</h2><IconButton onClick={() => {setOpenDialog(false)}}><CloseIcon fontSize='small'/></IconButton></DialogTitle>
                <DialogContent style={{'display': 'flex', 'justifyContent': 'center'}}>
                    {reportUrl && reportUrl !== undefined ? <>
                        <iframe src={reportUrl} style={{'width': '-webkit-fill-available', 'height': '900px'}}>
                        </iframe>
                    </>:<><CircularProgress /></>}
                </DialogContent>
            </Dialog>
            {/* <Dialog
                open={openConfirmDialog}
                TransitionComponent={Transition}
                keepMounted
                fullWidth
                maxWidth={'sm'}
                style={{'justifyContent': 'center', 'height': '900px'}}
                onClose={handleConfirmDialogClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle style={{'display': 'flex', 'justifyContent': 'space-between', 'alignItems': 'center'}}><h2>View report</h2><IconButton onClick={handleConfirmDialogClose}><CloseIcon fontSize='small'/></IconButton></DialogTitle>
                <DialogContent style={{'display': 'flex', 'justifyContent': 'center'}}>
                    <Typography>Profile report exist for selected person. Do you want to view report in PDF or create new report ?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={loadReport}>
                        View PDF
                    </Button>
                    <Button onClick={editReport}>
                        Create Report
                    </Button>
                </DialogActions>
            </Dialog> */}
            <Dialog
                open={openEditDialog}
                TransitionComponent={Transition}
                keepMounted
                fullWidth
                maxWidth={'sm'}
                style={{'justifyContent': 'center', 'height': '900px'}}
                //onClose={EditDialog}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle style={{'display': 'flex', 'justifyContent': 'space-between', 'alignItems': 'center'}}><h2>Edit report</h2><IconButton onClick={() => {setOpenEditDialog(false)}}><CloseIcon fontSize='small'/></IconButton></DialogTitle>
                <DialogContent style={{'display': 'flex', 'justifyContent': 'center'}}>
                    <Typography>Please select option to edit existing report or import to new report</Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleEditReport}>
                        Edit Report
                    </Button>
                    <Button onClick={() => cloneReport()}>
                        Import to new Report
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openDeleteDialog}
                TransitionComponent={Transition}
                keepMounted
                fullWidth
                maxWidth={'sm'}
                style={{'justifyContent': 'center', 'height': '900px'}}
                //onClose={handleDeleteDialogClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle style={{'display': 'flex', 'justifyContent': 'space-between', 'alignItems': 'center'}}><h2>Delete Report</h2><IconButton onClick={()=>{setOpenDeleteDialog(false)}}><CloseIcon fontSize='small'/></IconButton></DialogTitle>
                <DialogContent style={{'display': 'flex', 'justifyContent': 'center'}}>
                    <Typography>Do you really want to delete this profile report? You can't undo this action.</Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=> {setOpenDeleteDialog(false)}}>
                        Cancel
                    </Button>
                    <Button onClick={deleteReport}>
                        Delete Report
                    </Button>

                </DialogActions>
            </Dialog>
        </div>
    );
};

export default SearchResults;