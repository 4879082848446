import {
    Alert,
    AlertColor,
    Box,
    Button,
    ButtonGroup,
    Card,
    CardContent, Checkbox,
    Container,
    FormControl, FormControlLabel, InputLabel,
    MenuItem,
    Select,
    Snackbar,
    TextField,
    Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import UserAttributes from "../../interfaces/UserAttributes.interface";
import ErrorIcon from '@mui/icons-material/Error';
import { ForgotPasswordAPI, getUser } from "../../services/auth.service";
import { Account, ForgotPasswordResponse } from "../../interfaces/auth_interface";

interface ChangePasswordProps {
}

const ChangePassword: React.FC<ChangePasswordProps> = () => {
    const [params, setParams] = React.useState<any>({oldPassword:"", newPassword:"", confPassword:"", DOB:""})
    const [confPassword, setConfPassword] = React.useState<string>("")
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false)
    const [alertText, setAlertText] = React.useState<string>("")
    const [alertType, setAlertType] = React.useState<AlertColor>("success")

    const [oldPasswordError, setOldPasswordError] = React.useState<string>("")
    const [DOBError, setDOBError] = React.useState<string>("")
    const [submitError, setSubmitError] = React.useState<string>("")
    const [passwordError, setPasswordError] = React.useState<string>("")
    const [user, setUser] = useState<Account>(getUser())

    const checkValidation = () => {
        let ret = true
        if (params?.DOB.length === 0) {
            setDOBError("Please Enter Your DOB.")
            ret = false
        }
        if (params?.oldPassword?.length === 0) {
            setOldPasswordError("Please Enter Your Current Password.")
            ret = false
        }
        if (params?.newPassword?.length === 0 || params?.confPassword?.length === 0) {
            setPasswordError("Please Input A Password.")
            ret = false
        }
        if (params?.newPassword !== params?.confPassword) {
            setPasswordError("Passwords Do Not Match!")
            ret = false
        }

        return ret
    }

    const submitUser = async () => {
        setSubmitError("")
        setDOBError("")
        setOldPasswordError("")
        setPasswordError("")
        setAlertText("")
        if (checkValidation()) {
            let credsReq: any = {
                EmpID: user?.EmpID?.trim(),
                Dept: user?.Dept?.trim(),
                DOB: params.DOB,
                OldPassword: params.oldPassword,
                NewPassword: params.newPassword,
                ConfirmPassword: params.confPassword,
                Origination: 3,
            }
            let res: ForgotPasswordResponse = await ForgotPasswordAPI(credsReq)
            if (res.LoggedUser && res.LoggedUser.UserValidation) {
                setAlertType("success")
                setAlertText("Password Change Successful")
                setAlertOpen(true)
            }
            else if (res.LoggedUser) {
                setAlertType("error")
                setAlertText(res?.LoggedUser?.ErrorMessage ? res?.LoggedUser?.ErrorMessage : "")
                setAlertOpen(true)
            }
            else {
                setAlertType("error")
                setAlertText("An Error Occurred During Authentication")
                setAlertOpen(true)
            }
        }
    }



    const CloseBox = () => {
        window.location.href = "/"
    }

    return (
        <div className="flex justify-center m-10">
            <Snackbar open={alertOpen} autoHideDuration={7000} onClose={() => { setAlertOpen(false) }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertType} sx={{ width: '100%' }}>
                    {alertText}
                </Alert>
            </Snackbar>
            <Card className = "border-black border-2">
                <CardContent>
                    <div className = "mt-3 mb-3 flex justify-center">
                        <h2 className="font-bold text-lg">Change Password</h2>
                    </div>
                    <div>
                        <label className="font-bold">Emp ID</label>
                        <br />
                        <label>{user.EmpID}</label>
                    </div>
                    <div className="grid grid-cols-1 gap-y-8 mt-8">

                        <TextField
                            margin='none'
                            size={"small"}
                            type="date"
                            label="DOB"
                            autoFocus
                            color={DOBError.length > 0 ? "error" : "primary"}
                            focused={DOBError.length > 0 ? true : undefined}
                            InputLabelProps={{ shrink: true }}
                            value={params.DOB}
                            onChange={(e: any) => setParams({ ...params, DOB: e.target.value })}
                        />
                        {DOBError.length > 0 ? <span style={{ color: "red", fontSize: 14 }}><ErrorIcon style={{ color: "red" }} fontSize='small' /> {DOBError}</span> : <></>}
                        <TextField
                            size="small"
                            InputLabelProps={{ 'shrink': true }}
                            className="w-72"
                            margin='none'
                            label="Old Password"
                            type="password"
                            color={oldPasswordError.length > 0 ? "error" : "primary"}
                            focused={oldPasswordError.length > 0 ? true : undefined}
                            value={params?.oldPassword}
                            onChange={(e: any) => setParams({ ...params, oldPassword: e.target.value })}
                        />
                        {oldPasswordError.length > 0 ? <span style={{ color: "red", fontSize: 14 }}><ErrorIcon style={{ color: "red" }} fontSize='small' /> {oldPasswordError}</span> : <></>}
                        <TextField
                            size="small"
                            InputLabelProps={{ 'shrink': true }}
                            className="w-full"
                            margin='none'
                            label="New Password"
                            type="password"
                            color={passwordError.length > 0 ? "error" : "primary"}
                            focused={passwordError.length > 0 ? true : undefined}
                            value={params?.newPassword}
                            onChange={e => setParams({ ...params, newPassword: e.target.value })}
                        />
                        <TextField
                            size="small"
                            InputLabelProps={{ 'shrink': true }}
                            className="w-full"
                            margin='none'
                            label="Confirmed Password"
                            type="password"
                            color={passwordError.length > 0 ? "error" : "primary"}
                            focused={passwordError.length > 0 ? true : undefined}
                            value={params?.confPassword}
                            onChange={e => setParams({ ...params, confPassword: e.target.value })}
                        />
                        {passwordError.length > 0 ? <span style={{ color: "red", fontSize: 14 }}><ErrorIcon style={{ color: "red" }} fontSize='small' /> {passwordError}</span> : <></>}
                    </div>

                    <div className="mt-8 flex justify-center">
                        <ButtonGroup variant="outlined" size={"large"} color={"inherit"} >
                            <Button onClick={submitUser} >Save</Button>
                            <Button onClick={CloseBox}>CANCEL</Button>
                        </ButtonGroup>
                    </div>

                    {submitError.length > 0 ? <span style={{ color: "red", fontSize: 14 }}><ErrorIcon style={{ color: "red" }} fontSize='small' /> {submitError}</span> : <></>}
                </CardContent>
            </Card>
        </div>
    );
}

export default ChangePassword;
