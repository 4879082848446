import React from 'react'
import {
    Button, Collapse, IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow, Tooltip
} from "@mui/material";
import ReactECharts from 'echarts-for-react';
import { getGraphResponse } from '../../../interfaces/getDarInfo_interface';


import ControlPointIcon from '@mui/icons-material/ControlPoint';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FilterListRounded from '@mui/icons-material/FilterListRounded';
import FilterDropDown from '../../shared/_FilterDropDowns';
import { formatDate } from '../../../services/formatDate.service';


//type EChartsOption = echarts.EChartsOption;

interface SubmitGraphSearchProps {
    AnalysisGraph: getGraphResponse | null
}

const SearchResultGraph: React.FC<SubmitGraphSearchProps> = ({ AnalysisGraph }) => {    
    const [header, setHeader] = React.useState<any[]>([])
    //For Pagination
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [done, setDone] = React.useState<boolean>(false)

    //var chartDom = document.getElementById('main');
    //var myChart = echarts.init(chartDom);
    var option;

    option = {
        title: {
            text: 'Arrest Graph'
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            //data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine']
            data: []
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        toolbox: {
            feature: {
                saveAsImage: {}
            }
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            //data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
            data: AnalysisGraph?.XAxis ? AnalysisGraph?.XAxis : []
        },
        yAxis: [
            {
                axisLabel: {
                    textStyle: { fontSize: 10 }
                },
                axisLine: { show: false },
                axisTick: { show: false },
                name: "",
                splitLine: {
                    lineStyle: {
                        type: "dotted"
                    }
                },
                type: "value"
            }
        ],
        //yAxis: {
        //    type: 'value'
        //},
        series: AnalysisGraph?.Series ? AnalysisGraph?.Series : []
        //[
        //    {
        //        name: 'Email',
        //        type: 'line',
        //        stack: 'Total',
        //        data: [120, 132, 101, 134, 90, 230, 210]
        //    },
        //    {
        //        name: 'Union Ads',
        //        type: 'line',
        //        stack: 'Total',
        //        data: [220, 182, 191, 234, 290, 330, 310]
        //    },
        //    {
        //        name: 'Video Ads',
        //        type: 'line',
        //        stack: 'Total',
        //        data: [150, 232, 201, 154, 190, 330, 410]
        //    },
        //    {
        //        name: 'Direct',
        //        type: 'line',
        //        stack: 'Total',
        //        data: [320, 332, 301, 334, 390, 330, 320]
        //    },
        //    {
        //        name: 'Search Engine',
        //        type: 'line',
        //        stack: 'Total',
        //        data: [820, 932, 901, 934, 1290, 1330, 1320]
        //    }
        //]
    };

    return (
        <div>
            <ReactECharts option={option} />
        </div>
    )
};

export default SearchResultGraph