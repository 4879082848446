import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import {
  NJPortDashboardDataApi,
  PDohireDashboardDataApi,
} from "../../../services/njport.dashboard.service";

interface VolunteerRequestProps {
  data?: any;
}

const PDOHirePieChart: React.FC<VolunteerRequestProps> = () => {
  const [chartData, setChartData] = useState<any>([]);

  useEffect(() => {
    const payload = {
      queryType: "PDOHIRE_MONTH_START",
      dateTypeValue: 1,
    };
    NJPortDashboardDataApi(payload).then((qRes) => {
      setChartData(qRes?.AnyTable);

      const filteredData = qRes?.AnyTable?.filter(
        (item: any) =>
          item.JobID === "CK" &&
          (item.StateID === "NJ" || item.StateID === "NY"),
      );

      const groupedData = filteredData.reduce(
        (acc: any, item: any) => {
          // if (item.JobRequests !== null) {
            const port = item.StateID;
            if (!acc[port]) {
              acc[port] = 0;
            }
            acc[port] += item.Requested;
          // }
          return acc;
        },
        { NY: 0, NJ: 0 },
      );

      const formattedData = Object.keys(groupedData).map((port) => ({
        name: port,
        value: groupedData[port],
      }));

      setChartData(formattedData);
    });
  }, []);

  // useEffect(() => {
  //   const getMonthRange = (date: Date) => {
  //     const firstDay = new Date(date.getFullYear(), date.getMonth(), 2);
  //     const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);
  //     return { firstDay, lastDay };
  //   };

  //   const fetchDataForMonths = async () => {
  //     let allData: any[] = [];
  //     let currentDate = new Date();

  //     for (let i = 0; i < 6; i++) {
  //       let { firstDay, lastDay } = getMonthRange(
  //         new Date(currentDate.getFullYear(), currentDate.getMonth() - i),
  //       );

  //       let params = {
  //         dateTypeValue: 0,
  //         dateRange: true,
  //         fromDate: firstDay,
  //         toDate: lastDay,
  //         queryType: "PDOHIRE_MAIN",
  //       };

  //       const qRes = await PDohireDashboardDataApi(params);
  //       allData = [...allData, ...qRes?.AnyTable];
  //     }
  //     return allData;
  //   };

  //   fetchDataForMonths().then((allData) => {
  //     const filteredData = allData.filter(
  //       (item: any) =>
  //         item.JobID === "CK" &&
  //         (item.PortID === "NEW YORK" || item.PortID === "NEW JERSEY"),
  //     );

  //     const groupedData = filteredData.reduce(
  //       (acc: any, item: any) => {
  //         if (item.JobRequests !== null) {
  //           const port = item.PortID;
  //           if (!acc[port]) {
  //             acc[port] = 0;
  //           }
  //           acc[port] += item.JobRequests;
  //         }
  //         return acc;
  //       },
  //       { "NEW YORK": 0, "NEW JERSEY": 0 },
  //     );

  //     const formattedData = Object.keys(groupedData).map((port) => ({
  //       name: port,
  //       value: groupedData[port],
  //     }));

  //     setChartData(formattedData);
  //   });
  // }, []);

  const handleSeries = () => {
    return [
      {
        name: "PDO Hire",
        type: "pie",
        radius: "50%",
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        data: chartData,
      },
    ];
  };

  const option = {
    tooltip: {
      trigger: "item",
    },
    height: "180",
    width: "95%",
    toolbox: {
      feature: {
        saveAsImage: { show: true },
      },
    },
    series: handleSeries(),
  };

  return (
    <>
      {chartData && chartData.length !== 0 ? (
        <div>
          <ReactECharts option={option} style={{ height: "160px" }} />
        </div>
      ) : (
        <div
          className={"text-center text-base xl:text-3xl mt-6 mb-1"}
          style={{ color: "red", fontWeight: 500 }}
        >
          No Data
        </div>
      )}
    </>
  );
};

export default PDOHirePieChart;
