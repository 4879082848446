import {PersonInfo} from "../../../interfaces/getDarInfo_interface";
import React from "react";
import { Disclosure } from '@headlessui/react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PDInfo from "../../../interfaces/PDInfo.interface";
import { TablePagination, CircularProgress, Button } from "@mui/material";
import { Link } from "react-router-dom";
import ParkMobileIcon from "../../../assets/Images/pm1.png";
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent ,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search
} from '@syncfusion/ej2-react-grids';

interface PanelParkMobileProps {
    person: PersonInfo,
    PDInfos: PDInfo[],
    isLoading:boolean
}

const  PanelParkMobile: React.FC<PanelParkMobileProps> = ({person,PDInfos,isLoading})=> {
    const [FormatData, setFormatData] = React.useState(0);

    const handleDataTyping = () => {
        let tempP: any = [...PDInfos]
        for (let p of tempP) {
            if (p.startdatetime) {
                p.startdatetime = new Date(p.startdatetime)
            }
            if (p.enddatetime) {
                p.enddatetime = new Date(p.enddatetime)
            }
        }
        setFormatData(tempP)
    }

    React.useEffect(() => {
        handleDataTyping()
    }, [PDInfos])

    return(
        <div className={"m-5 border"}>
            <Disclosure>
                {({ open }) => (
                    <>
                        <Disclosure.Button className="bg-blue-100 p-2 w-auto w-full text-left">
                            <span style={{ "display": "flex", "justifyContent": "space-between" }}>
                                <span>
                                    <KeyboardArrowDownIcon
                                        className={`${open ? "transform rotate-180" : ""}`}
                                    />
                                    <span className={"font-bold"}>Park Data Information <img src={ParkMobileIcon} alt={''} style={{ display: 'inline' }} /> {(isLoading) ? <CircularProgress size={20} style={{"marginLeft" : "5px"}} /> : <></>}</span>
                                </span>
                                <span className={"font-bold mr-2"} >Name: {person?.FName?.toUpperCase()} {person?.MName?.toUpperCase()} {person?.LName?.toUpperCase()} &nbsp;&nbsp;Records: {PDInfos?.length}</span>
                            </span>
                        </Disclosure.Button>

                        <Disclosure.Panel className="text-gray-500 p-4">
                            <GridComponent
                                dataSource={FormatData}
                                allowPaging={true}
                                pageSettings={{pageSize: 50, pageSizes: [10,25,50,100]}}
                                allowSorting={true}
                                allowMultiSorting={true}
                                allowFiltering={true}
                                filterSettings={{type: 'CheckBox'}}
                                allowTextWrap={true}
                                textWrapSettings={{ wrapMode: 'Both' }}
                                rowHeight={24}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective field='startdatetime' headerText='Start DateTime' format={{ type: 'dateTime', format: 'MM/dd/yyyy HH:mm:ss' }}/>
                                    <ColumnDirective field='enddatetime' headerText='End DateTime' format={{ type: 'dateTime', format: 'MM/dd/yyyy HH:mm:ss' }}/>
                                    <ColumnDirective field='zoneid' headerText='City - Zone'/>
                                    <ColumnDirective field='platestate' headerText='Plate St'/>
                                    <ColumnDirective field='plate' headerText='Plate #'/>
                                    <ColumnDirective field='mobileparkid' headerText='MobilePark ID' />
                                    <ColumnDirective field='Latitude' headerText='Latitude' visible={false} />
                                    <ColumnDirective field='Longitude' headerText='Longitude' visible={false} />
                                    {/*<ColumnDirective template={(item: any) => (<>({item.Latitude}, {item.Longitude})</>)} headerText='MSP (Latitude, Longitude)' visible={false} />*/}
                                    <ColumnDirective template={(item: any) => (<Button onClick={() => { window.open('/detail/ParkMobile?ID=' + item?.ParkDataID + '&Dept=' + item?.agencyshortname, '_blank') }} >View</Button>)} headerText='View' />
                                    {/*<ColumnDirective template={(park:any)=>(<Link to={'/detail/ParkMobile?ID=' + park.ParkDataID + '&Dept=' + park.agencyshortname} target="_blank">{park.ParkDataID}</Link>)} headerText='ID'/>*/}                                
                                </ColumnsDirective>
                                <Inject services={[Sort, Page, Filter]}/>
                            </GridComponent>
                           
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>

    )
};

export default PanelParkMobile;