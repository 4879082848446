import React, { forwardRef } from "react";
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import {
    Autocomplete,
    Box,
    Button,
    ButtonGroup,
    Card,
    CardContent,
    Checkbox,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select, Snackbar,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import ArrestDropDowns, { VinDropDowns } from "../arrest/_arrestDropDowns";
import Slide, { SlideProps } from '@mui/material/Slide';
import StolenVehicleSearch from "../stolenVehicle/_stolenVehicleSearch";
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import { JAOS } from "../../../services/JAOS.service";
import { useHistory } from "react-router-dom";
import { SiteName } from "../../../services/config.service";
import { GetStringNameForSite } from "../../../interfaces/config.interface";
type TransitionProps = Omit<SlideProps, 'direction'>;

interface ProfileReportSearchProps {
    SubmitSearch: any,
    passedData?: any | null
}

interface ProfileReportSearchRef {
    handleSubmit: any
}

const ProfileReportSearch = forwardRef<ProfileReportSearchRef, ProfileReportSearchProps>(({ SubmitSearch, passedData = null }, ref) => {
    const [params, setParams] = React.useState({ FirstName: "", MI: "", LastName: "", DOB: "", FBI: "", SBI: "", AgeRangeStart: "", AgeRangeEnd: "", toDate: "", fromDate: "", Gender: "", Status: "", ReportNum: "", SystemCall: false })
    const [dateType, setDateType] = React.useState<string>("Entry Date");
    const [genderOptions, setGenderOptions] = React.useState<string[]>(["Male", "Female", "Transgender", "Non-Binary", "Unknown"]);
    const [statusOptions, setStatusOptions] = React.useState<string[]>(["In Progress", "Complete"]);
    const [andOrType, setAndOrType] = React.useState<string>("AND");
    const [dropDownParams, setDropDownParams] = React.useState<any>({});
    const [clear, setClear] = React.useState<boolean>(false);
    const [showALPR, setShowALPR] = React.useState<boolean>(false)
    const [initialRun, setInitialRun] = React.useState<boolean>(false)

    const history = useHistory();

    React.useEffect(() => {
        // if(passedData) {
        //         let p = params
        //         if(passedData.PersonInfo.FBI)
        //         {
        //             p.FBI = passedData.PersonInfo.FBI
        //         }
        //         if(passedData.PersonInfo.SBINo)
        //         {
        //             p.SBI = passedData.PersonInfo.SBINo
        //         }
        //         if (passedData.PersonInfo.DOB || passedData.PersonInfo.LName || passedData.PersonInfo.FName)
        //         {
        //             p.FirstName = passedData.PersonInfo.FName
        //             p.MI = passedData.PersonInfo.MName
        //             p.LastName = passedData.PersonInfo.LName
        //             p.DOB = passedData.PersonInfo.DOB
        //         }
        //         setParams(p)
        //         SubmitSearch(p, dateType, andOrType, dropDownParams);

        // }
        // else
        // {
        //     handleSearch()
        // }
        if (!initialRun) {
            setInitialRun(true)
            let p = { ...params }
            p.SystemCall = true
            SubmitSearch(p, dateType, andOrType, dropDownParams)
        }
    }, [])

    const handleDateType = (event: React.MouseEvent<HTMLElement>, dateType: string) => {
        if (dateType) {
            setDateType(dateType);
        }
    };

    const setStatusValue = (option: string) => {
        switch (option) {
            case 'In Progress':
                option = 'InProgress';
                break;
            case 'For Review':
                option = 'ForReview';
                break;
            default:
                break;
        }

        return option;
    }

    React.useEffect(() => {
        if (clear) {
            handleSearch();
            setClear(false);
        }
    }, [clear])

    React.useImperativeHandle(ref, () => ({
        handleSubmit: () => handleClear(),
    }))

    const handleClear = () => {
        setParams({ ...params, FirstName: "", MI: "", LastName: "", DOB: "", FBI: "", SBI: "", AgeRangeStart: "", AgeRangeEnd: "", toDate: "", fromDate: "", Gender: "", Status: "", ReportNum: "", SystemCall: false })
        setDateType("Entry Date")
        setAndOrType("AND")
        setShowALPR(false)
        setClear(true)
        history.push('/tools/profile_report');
    }

    const handleSearch = () => {
        //history.push('/tools/profile_report');
        SubmitSearch(params, dateType, andOrType, dropDownParams)
    }

    return (
        <div className="mt-5 ml-5 mr-5 p-5">
            <Box className="pt-3" sx={{ flexGrow: 1 }}>
                <Grid container spacing={1} sx={{ pl: 2 }}>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={2} sx={{ pr: 2 }}>
                        <ToggleButtonGroup
                            color="primary"
                            value={dateType}
                            exclusive
                            fullWidth
                            onChange={handleDateType}
                        >
                            <ToggleButton value="Report Date"  >Report Date</ToggleButton>
                            <ToggleButton value="Entry Date"  >Entry Date</ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                    <Grid item xs={2} sx={{ pr: 2 }}>
                    </Grid>
                    <Grid item xs={2} sx={{ pr: 2 }}>
                        <TextField fullWidth margin='none' type="date" label="From Date" InputLabelProps={{ shrink: true }} value={params.fromDate} onChange={(e: any) => setParams({ ...params, fromDate: e.target.value })} />
                    </Grid>
                    <Grid item xs={2} sx={{ pr: 2 }}>
                        <TextField fullWidth margin='none' type="date" label="To Date" InputLabelProps={{ shrink: true }} value={params.toDate} onChange={(e: any) => setParams({ ...params, toDate: e.target.value })} />
                    </Grid>
                </Grid>
                <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={2} sx={{ pr: 2 }} >
                        <TextField fullWidth margin='none' label="First Name" value={params.FirstName} onChange={(e: any) => setParams({ ...params, FirstName: e.target.value })} />
                    </Grid>
                    <Grid item xs={2} sx={{ pr: 2 }}>
                        <TextField fullWidth margin='none' label="Middle Initial" value={params.MI} onChange={(e: any) => setParams({ ...params, MI: e.target.value })} />
                    </Grid>
                    <Grid item xs={2} sx={{ pr: 2 }}>
                        <TextField fullWidth margin='none' label="Last Name" value={params.LastName} onChange={(e: any) => setParams({ ...params, LastName: e.target.value })} />
                    </Grid>
                    <Grid item xs={2} sx={{ pr: 2 }}>
                        <TextField fullWidth margin='none' type="date" label="DOB" InputLabelProps={{ shrink: true }} value={params.DOB} onChange={(e: any) => setParams({ ...params, DOB: e.target.value })} />
                    </Grid>
                </Grid>
                <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={2} sx={{ pr: 2 }} >
                        <TextField fullWidth margin='none' label="FBI #" value={params.FBI} onChange={(e: any) => setParams({ ...params, FBI: e.target.value })} />
                    </Grid>
                    <Grid item xs={2} sx={{ pr: 2 }}>
                        <TextField fullWidth margin='none' label={GetStringNameForSite(SiteName, "SBI #")} value={params.SBI} onChange={(e: any) => setParams({ ...params, SBI: e.target.value })} />
                    </Grid>
                    <Grid item xs={2} sx={{ pr: 2 }}>
                        <FormControl fullWidth>
                            <InputLabel id="Gender-Label">Gender</InputLabel>
                            <Select
                                id="demo-simple-select"
                                value={params.Gender}
                                label={"Gender"}
                                onChange={(e: any) => setParams({ ...params, Gender: e.target.value })}
                            >
                                {genderOptions.map((option, idxx) => (
                                    <MenuItem value={option} key={"key1" + option}>{option}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2} sx={{ pr: 2 }}>
                        <TextField fullWidth margin='none' label="Report Number" value={params.ReportNum} onChange={(e: any) => setParams({ ...params, ReportNum: e.target.value })} />
                        {/* <FormControl fullWidth>
                                <InputLabel id="Status-Label">Status</InputLabel>
                                <Select

                                    id="demo-simple-select"
                                    value = {params.Status}
                                    label={"Status"}
                                    onChange={(e:any) => setParams({...params, Status: e.target.value})}
                                    >
                                    {statusOptions.map((option, idxx) => (
                                        <MenuItem value={setStatusValue(option)} key={"key2"+option}>{option}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl> */}
                    </Grid>
                </Grid>
                <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
                    <Grid item xs={8} sx={{ pr: 2 }}></Grid>
                    <Grid item xs={2} sx={{ pr: 2 }}>
                        <ButtonGroup fullWidth size="large" color="inherit" >
                            <Button type="submit" onClick={handleSearch}>Search</Button>
                            <Button onClick={handleClear}>CLEAR</Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
})

export default ProfileReportSearch;