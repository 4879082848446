import React, { useState } from 'react';
import { SiteName } from '../../services/config.service';
import { getUser, isUserPermission } from "../../services/auth.service";
import { getDarInfoResponse, GetDarInfo_Query, PersonInfo, ProfileReportPersonInfo } from "../../interfaces/getDarInfo_interface";
import { Account } from "../../interfaces/auth_interface";
import { RunDarQuery } from "../../services/getDar.service";
import { JAOS } from "../../services/JAOS.service";
import PreliminaryReportSearchResults from '../../components/tools/preliminary_report/_searchResultsPreliminaryReport';
import PreliminaryReportSearch from '../../components/tools/preliminary_report/_preliminaryReportSearch';
import { SearchPIR } from "../../services/pir.service";
import { GetStringNameForSite, SITE } from "../../interfaces/config.interface";

function PreliminaryReport() {
    const [formData, setFormData] = useState<any[]>([]);
    const [reloadTriggerCount, setReloadTriggerCount] = useState(0);
    const searchRef = React.useRef<any>(null);
    
    const handleFormSubmit = (params:any, dateType:any, andOrType:any, dropDownParams:any) => {
        
        SearchPIR({
            SearchType: andOrType,
            DateType: dateType === 'Entry Date', 
            FromDate: params?.fromDate ?? null,
            ToDate: params?.toDate ?? null,
            FirstName: params?.firstName ?? null,
            MiddleName:  null,
            LastName:  params?.lastName ?? null,
            CaseNumber: params?.ccn ?? null,
            ReportNumber: null,
            District: null,
            PSA: params?.psa ?? null,
            IncidentType: params?.incidentType ?? null,
            Location: params?.location ?? null,
            FBI: params?.fbi ?? null,
            PDID: params?.pdid ?? null,
            DOB: null,
            DLNo: params?.dlNumber ?? null,
            DLSt: null,
            PltNo: params?.plateNum ?? null,
            PltSt: null,
        }).then((r:any)=> {
            setFormData(r);
        }).catch((e)=>{
            console.log(e)
        })
    }

    const  reloadData = () => {
        setReloadTriggerCount(reloadTriggerCount + 1);
    }
    return (
        <div id="ToolsArrest">
            {isUserPermission('Arrest') ?
                <>
                    {(SiteName == SITE.DCDEMO || SiteName == SITE.DCPROD || SiteName == SITE.DCLOCAL) && (
                        <>
                            <PreliminaryReportSearch SubmitSearch={handleFormSubmit} RefreshTrigger={reloadTriggerCount} />
                            <PreliminaryReportSearchResults Records={formData} RefreshTrigger={reloadData} />
                        </>
                    )}
                </>
                : <></>}
        </div>
    );
}

export default PreliminaryReport;