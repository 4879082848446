import React, {useState} from "react";
import {Account} from "../../../interfaces/auth_interface";
import {getUser, isUserPermission} from "../../../services/auth.service";
import {GetStolenVehicleDashboard, RunGetDailyStats} from "../../../services/dashboard.service";
import {getDarInfoResponse} from "../../../interfaces/getDarInfo_interface";
import SVDashboardLayout from "../../../components/Dashboard/StolenVehicle/_svLayout";
import {Category} from "@mui/icons-material";

function SVDashboard() {
    let [user, setUser] = useState<Account>(getUser())
    const [data, setData] = useState<any>({})
    const [stolenVehicles, setStolenVehicles] = useState<any>([])
    const [stolenPlates, setStolenPlates] = useState<any>([])
    const [recoveredVehicles, setRecoveredVehicles] = useState<any>([])
    const [recoveredPlates, setRecoveredPlates] = useState<any>([])
    const [top25StolenLocationVehicles, setTop25StolenLocationVehicles] = useState<any>([])
    const [top25StolenLocationPlates, setTop25StolenLocationPlates] = useState<any>([])
    const [top25StolenLocationBoth, setTop25StolenLocationBoth] = useState<any>([])
    const [top10Brands, setTop10Brands] = useState<any>([])
    const [heatMap, setHeatMap] = useState<any>([])
    const [sixHourBreakdownStolen, setSixHourBreakdownStolen] = useState<any>([])
    const [sixHourBreakdownRecovered, setSixHourBreakdownRecovered] = useState<any>([])
    const [twentyFourHourBreakdownStolen, setTwentyFourHourBreakdownStolen] = useState<any>([])
    const [twentyFourHourBreakdownRecovered, setTwentyFourHourBreakdownRecovered] = useState<any>([])
    const [periodComparisonVehicles, setPeriodComparisonVehicles] = useState<any>([])
    const [periodComparisonPlates, setPeriodComparisonPlates] = useState<any>([])
    const [avgRecoveryTimeVehicles, setAvgRecoveryTimeVehicles] = useState<any>(0)
    const [avgRecoveryTimePlates, setAvgRecoveryTimePlates] = useState<any>(0)
    const [avgRecoveryTimeBoth, setAvgRecoveryTimeBoth] = useState<any>(0)
    const [queryList, setQueryList] = useState<string[]>(
        ["Stolen", "Top25StolenLocation", "Recovered", "Top10Brands", "PeriodComparison", "AvgRecoveryTime", "HeatMap",
            "SixHourBreakdownStolen", "SixHourBreakdownRecovered", "TwentyFourHourBreakdownStolen", "TwentyFourHourBreakdownRecovered"])

    const GetStats = async ( params:any) => {
        for(let i of queryList){
            let temp = {...params}
            temp.Category = i
            switch(temp.Category){
                case "Stolen":
                    temp.StolenType = 'V'
                    GetStolenVehicleDashboard(temp).then((qRes) => {
                        setStolenVehicles(qRes ?? [])
                    })
                    temp.StolenType = 'P'
                    GetStolenVehicleDashboard(temp).then((qRes) => {
                        setStolenPlates(qRes ?? [])
                    })
                    break;
                case "Recovered":
                    temp.StolenType = 'V'
                    GetStolenVehicleDashboard(temp).then((qRes) => {
                        setRecoveredVehicles(qRes ?? [])
                    })
                    temp.StolenType = 'P'
                    GetStolenVehicleDashboard(temp).then((qRes) => {
                        setRecoveredPlates(qRes ?? [])
                    })
                    break;
                case "Top25StolenLocation":
                    temp.StolenType = 'V'
                    GetStolenVehicleDashboard(temp).then((qRes) => {
                        setTop25StolenLocationVehicles(qRes ?? [])
                    })
                    temp.StolenType = 'P'
                    GetStolenVehicleDashboard(temp).then((qRes) => {
                        setTop25StolenLocationPlates(qRes ?? [])
                    })
                    temp.StolenType = 'V,P'
                    GetStolenVehicleDashboard(temp).then((qRes) => {
                        setTop25StolenLocationBoth(qRes ?? [])
                    })
                    break;
                case "Top10Brands":
                    GetStolenVehicleDashboard(temp).then((qRes) => {
                        setTop10Brands(qRes ?? [])
                    })
                    break;
                case "PeriodComparison":
                    temp.StolenType = 'V'
                    GetStolenVehicleDashboard(temp).then((qRes) => {
                        setPeriodComparisonVehicles(qRes ?? [])
                    })
                    temp.StolenType = 'P'
                    GetStolenVehicleDashboard(temp).then((qRes) => {
                        setPeriodComparisonPlates(qRes ?? [])
                    })
                    break;
                case "AvgRecoveryTime":
                    temp.StolenType = 'V'
                    GetStolenVehicleDashboard(temp).then((qRes) => {
                        if(qRes && qRes.length > 0) {
                            let art = qRes[0].AVGRecoveryDate ?? []
                            setAvgRecoveryTimeVehicles(art)
                        }
                    })
                    temp.StolenType = 'P'
                    GetStolenVehicleDashboard(temp).then((qRes) => {
                        if(qRes && qRes.length > 0) {
                            let art = qRes[0].AVGRecoveryDate ?? []
                            setAvgRecoveryTimePlates(art)
                        }
                    })
                    temp.StolenType = 'V,P'
                    GetStolenVehicleDashboard(temp).then((qRes) => {
                        if(qRes && qRes.length > 0) {
                            let art = qRes[0].AVGRecoveryDate ?? []
                            setAvgRecoveryTimeBoth(art)
                        }
                    })
                    break;
                case "HeatMap":
                    GetStolenVehicleDashboard(temp).then((qRes) => {
                        setHeatMap(qRes ?? [])
                    })
                    break;
                case "SixHourBreakdownStolen":
                    temp.StolenType = 'V,P'
                    GetStolenVehicleDashboard(temp).then((qRes) => {
                        setSixHourBreakdownStolen(qRes ?? [])
                    })
                    break;
                case "SixHourBreakdownRecovered":
                    temp.StolenType = 'V,P'
                    GetStolenVehicleDashboard(temp).then((qRes) => {
                        setSixHourBreakdownRecovered(qRes ?? [])
                    })
                    break;
                case "TwentyFourHourBreakdownStolen":
                    temp.StolenType = 'V,P'
                    GetStolenVehicleDashboard(temp).then((qRes) => {
                        setTwentyFourHourBreakdownStolen(qRes ?? [])
                    })
                    break;
                case "TwentyFourHourBreakdownRecovered":
                    temp.StolenType = 'V,P'
                    GetStolenVehicleDashboard(temp).then((qRes) => {
                        setTwentyFourHourBreakdownRecovered(qRes ?? [])
                    })
                    break;
            }
        }
    }

    React.useEffect(() => {
        handleDashboardData()
    },[stolenVehicles,stolenPlates,recoveredVehicles,recoveredPlates,
        top25StolenLocationVehicles,top25StolenLocationPlates,top25StolenLocationBoth,
        top10Brands,periodComparisonVehicles, periodComparisonPlates, avgRecoveryTimeVehicles,avgRecoveryTimePlates, avgRecoveryTimeBoth,
        heatMap, sixHourBreakdownStolen, sixHourBreakdownRecovered, twentyFourHourBreakdownStolen, twentyFourHourBreakdownRecovered ])
    
    const handleDashboardData = () => {
        let data:any = {}
        
        data.StolenVehicles = stolenVehicles
        data.StolenPlates = stolenPlates
        data.Top25StolenLocationVehicles = top25StolenLocationVehicles
        data.Top25StolenLocationPlates = top25StolenLocationPlates
        data.Top25StolenLocationBoth = top25StolenLocationBoth
        data.RecoveredVehicles = recoveredVehicles
        data.RecoveredPlates = recoveredPlates
        data.Top10Brands = top10Brands
        data.PeriodComparisonVehicles = periodComparisonVehicles
        data.PeriodComparisonPlates = periodComparisonPlates
        data.AvgRecoveryTimeVehicles = avgRecoveryTimeVehicles
        data.AvgRecoveryTimePlates = avgRecoveryTimePlates
        data.AvgRecoveryTimeBoth = avgRecoveryTimeBoth
        data.HeatMap = heatMap
        data.SixHourBreakdownStolen = sixHourBreakdownStolen
        data.SixHourBreakdownRecovered = sixHourBreakdownRecovered
        data.TwentyFourHourBreakdownStolen = twentyFourHourBreakdownStolen
        data.TwentyFourHourBreakdownRecovered = twentyFourHourBreakdownRecovered
        
        setData(data)
    }


    return (
        <div>
            {isUserPermission('Arrest')?
                <>
                    <SVDashboardLayout GetStats={GetStats} data={data}/>
                </>
                :<></>}
        </div>
    );
}

export default SVDashboard;