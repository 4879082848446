import React from "react";
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import {
    Button,
    ButtonGroup,
    FormControl,
    InputLabel,
    MenuItem,
    Select, Snackbar,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import ArrestDropDowns, {StolenStateDropdown, VinDropDowns} from "../arrest/_arrestDropDowns";
import Slide, { SlideProps } from '@mui/material/Slide';
import {formatInputDate} from "../../../services/formatDate.service";
type TransitionProps = Omit<SlideProps, 'direction'>;

interface StolenVehicleSearchProps {
    SubmitSearch: any
}

interface searchObject {
    option : string
    value : any
    type : string
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TransitionDown(props: TransitionProps) {
    return <Slide {...props} direction="down" />;
}

const StolenVehicleSearch: React.FC<StolenVehicleSearchProps> = ({ SubmitSearch }) => {
    let today: Date = new Date();
    const [initialRun, setInitialRun] = React.useState<boolean>(false)
    const [params, setParams] = React.useState({ VIN: "", Plate: "", Case: "", toDate: formatInputDate(today), fromDate: formatInputDate(today), systemCall:false })
    const [dateType, setDateType] = React.useState<string>("Entry Date");
    const [vinType, setVinType] = React.useState<string>("No VIN");
    const [dropDownParams, setDropDownParams] = React.useState<any>({DD:{}, CDD:{}, SS:{}});
    const [clear, setClear] = React.useState<number>(0);
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false)
    
    const [options, setOptions] = React.useState<number>(1)
    const [optionList] = React.useState<string[]>(["Date", "ORI #", "Plate #", "Plate State", "Vehicle Details", "Stolen State"])
    const [searchOptions, setSearchOptions] = React.useState<searchObject[]>([{option:"Date", value:0, type:"Date"}, {option:"Stolen State", value:0, type:"Stolen State"}])

    React.useEffect(() => {
        if (!initialRun) {
            setInitialRun(true)
            let p = {...params}
            p.systemCall = true
            SubmitSearch(p, dateType, dropDownParams, searchOptions.some(e => e.option == "Date"))
        }
    }, [])
    const handleDateType = (event: React.MouseEvent<HTMLElement>, dateType: string) => {
        if (dateType) {
            setDateType(dateType);
        }
    };

    const handleVinType = (event: React.MouseEvent<HTMLElement>, vinType: string) => {
        if (vinType) {
            setVinType(vinType);
        }
    };

    const HandleDropDowns = (dropDowns: any) => {
        let temp = dropDownParams
        temp.DD = dropDowns
        setDropDownParams(temp)
    }

    const HandleDropDownsStolenState = (dropDowns: any) => {
        let temp = dropDownParams
        temp.SS = dropDowns
        setDropDownParams(temp)
    }
    
    const HandleDropDownsCar = (dropDowns: any) => {
        let temp = dropDownParams
        temp.CDD =dropDowns
        setDropDownParams(temp)
    }

    const handleClear = async () => {
        await setSearchOptions([])
        setParams({ ...params, VIN: "", Plate: "", Case: "", toDate: formatInputDate(today), fromDate: formatInputDate(today), systemCall:false })
        setDateType("Entry Date")
        setVinType("No VIN")
        setClear(clear + 1)
        setDropDownParams({DD:{}, CDD:{}, SS:{}})
        setSearchOptions([{option:"Date", value:0, type:"Date"}, {option:"Stolen State", value:0, type:"Stolen State"}])
        setOptions(options + 1)
    }

    const handleSearch = () => {
        if(!searchOptions.some(e => e.option == "")) {
            SubmitSearch(params, dateType, vinType, dropDownParams, searchOptions.some(e => e.option == "Date"))
        }
        else {
            setAlertOpen(true)
        }
    }
    
    const handleOptions = () => {
        let temp = searchOptions
        temp.push({option:"", value:"", type:""})
        setSearchOptions(temp)
        setOptions(options +1)
    }
    
    const removeOption = (indx:number) => {
        let temp = searchOptions 
        let del = temp.splice(indx, 1)
        if(del && del[0]?.option == 'Vehicle Details')
        {
            let ddtemp = dropDownParams
            ddtemp.CDD = {}
            setDropDownParams(ddtemp)
        }
        else if (del && del[0]?.option == 'Plate State' )
        {
            let ddtemp = dropDownParams
            ddtemp.DD = {}
            setDropDownParams(ddtemp)
        }
        else if (del && del[0]?.option == 'Stolen State' )
        {
            let ddtemp = dropDownParams
            ddtemp.SS = {}
            setDropDownParams(ddtemp)
        }
        handleValueChange(temp[indx]?.option, "", indx)
        setSearchOptions(temp)
        setOptions(options +1)
    }
    
    const changeOption = (selected:string, indx:number) => {
        let temp = searchOptions
        if(temp[indx]?.type == 'Stolen State')
        {
            let ddtemp = {...dropDownParams}
            ddtemp.SS = {}
            setDropDownParams(ddtemp)
        }
        else if(temp[indx]?.type == 'Vehicle Details')
        {
            let ddtemp = dropDownParams
            ddtemp.CDD = {}
            setDropDownParams(ddtemp)
        }
        else if (temp[indx]?.type == 'Plate State' )
        {
            let ddtemp = dropDownParams
            ddtemp.DD = {}
            setDropDownParams(ddtemp)
        }
        temp[indx] = {option:selected, value:"", type:selected == "Date" ? "Date" : selected == "Plate State" ? "Plate State" : selected == "Vehicle Details" ? "Vehicle Details" : selected == "Stolen State" ? "Stolen State" : "String"}
        handleValueChange(temp[indx]?.option, "", indx)
        setSearchOptions(temp)
        setOptions(options + 1)
    }

    const handleValueChange = (type:string, value:string, indx:number) =>{
        if(type && type.length > 0 ) {
            let t: string = type.split("#")[0]
            let temp = searchOptions
            temp[indx] = {option: temp[indx].option, value: value, type: temp[indx].type}
            switch (t.trim()) {
                case("ORI"):
                    setParams({...params, Case: value})
                    break
                case("Plate"):
                    setParams({...params, Plate: value})
                    break
                case("VIN"):
                    setParams({...params, VIN: value})
                    break
            }
            setSearchOptions(temp)
            setOptions(options + 1)
        }
    }
    
    return (
        <div className="bg-gray-100 ml-5 mr-5 rounded p-5">
            <Snackbar open={alertOpen} TransitionComponent={TransitionDown} anchorOrigin={{vertical:'top', horizontal:'center'}} autoHideDuration={6000} onClose={ () => { setAlertOpen(false)}}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={"error"} sx={{ width: '100%' }}>
                    {"One or More Search Condition is Invalid"}
                </Alert>
            </Snackbar>
            <div className = "flex">
                <ToggleButtonGroup
                    color="primary"
                    value={dateType}
                    exclusive
                    size="small"
                    onChange={handleDateType}
                    sx={{ mt: 1, mr: 2 }}
                >
                    <ToggleButton value="Stolen Date" sx={{ pr: 2, pl: 2 }} >Stolen Date</ToggleButton>
                    <ToggleButton value="Entry Date" sx={{ pr: 2, pl: 2 }} >Entry Date</ToggleButton>
                </ToggleButtonGroup>
                <div className = "mt-2">
                    <IconButton size={"large"} onClick={handleOptions} color ={"inherit"} title={"Add Search Condition"}> <AddIcon/> </IconButton>
                </div>
            </div>
            {searchOptions.length > 0 ?
                ((searchOptions) ? searchOptions.map((obj, indx) => (
                    <div className="mt-5 flex">
                        <FormControl sx={{minWidth: 300, marginRight: 2}} size="small">
                            <InputLabel id="search-option">Search Option</InputLabel>
                            <Select
                                labelId="search-option"
                                value={obj.option}
                                label="Search Option"
                                onChange={(e) => changeOption(e.target.value, indx)}
                            >
                                {optionList.map((option) => (
                                    <MenuItem value={option} disabled={searchOptions.some(e => e.option == option)}>{option}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {obj.option && obj.option.length > 0 ?
                            (obj.type == "Date" ?
                                    <span className={"flex gap-x-4"} style={{width: '30%'}}>
                                        <span className={"flex-1"}>
                                            <TextField variant="outlined" margin='none' type={"date"} fullWidth
                                                       size={"small"} label="From Date"
                                                       InputLabelProps={{shrink: true}}
                                                       inputProps={{style: {textAlign: 'center'}}}
                                                       value={params.fromDate}
                                                       onChange={(e: any) => {
                                                           setParams({...params, fromDate: e.target.value})
                                                       }}
                                            />
                                        </span>
                                        <span className={"flex-1"}>
                                            <TextField variant="outlined" margin='none' type={"date"} fullWidth
                                                       size={"small"} label="To Date"
                                                       InputLabelProps={{shrink: true}}
                                                       inputProps={{style: {textAlign: 'center'}}}
                                                       value={params.toDate}
                                                       onChange={(e: any) => {
                                                           setParams({...params, toDate: e.target.value})
                                                       }}
                                            />
                                        </span>
                                    </span>
                                    :
                                    (
                                        obj.type == "Plate State" ?
                                            <span className="inline">
                                                <ArrestDropDowns HandleDropDowns={HandleDropDowns} clear={clear}
                                                                 tool={"StolenVehicle"}/>
                                            </span>
                                            :
                                            obj.type == "Vehicle Details" ?
                                                <span className="inline">
                                                    <VinDropDowns HandleDropDowns={HandleDropDownsCar} clear={clear}
                                                                  tool={"StolenVehicle"}/>
                                                </span>
                                                :
                                                obj.type == "Stolen State" ?
                                                    <span className="inline">
                                                        <StolenStateDropdown
                                                            HandleDropDowns={HandleDropDownsStolenState} clear={clear}/>
                                                    </span>
                                                    :
                                                    <TextField
                                                        sx={{minWidth: 510}}
                                                        className="mx-2"
                                                        label={obj.option}
                                                        value={obj.value}
                                                        type={obj.type}
                                                        onChange={(e) => {
                                                            handleValueChange(obj.option, e.target.value, indx)
                                                        }}
                                                        size="small"
                                                    />
                                    )
                            )
                            :
                            <></>
                        }
                        <span className="ml-10 inline">
                            <IconButton aria-label="delete" size="small" onClick={() => removeOption(indx)}
                                        color={"error"} title="Remove Search Condition">
                                <DeleteIcon/>
                            </IconButton>
                        </span>
                    </div>

                )) : <></>)
                :
                <div className="text-lg text-gray-500 mt-5"
                     style={{"display": "flex", "justifyContent": "center"}}>Please add Search Condtions</div>
            }
            <div className={"pt-3"} style={{"display": "flex", "justifyContent": "end"}}>
                <ButtonGroup size="large" color="inherit">
                    <Button type="submit" onClick={handleSearch} disabled={searchOptions.length == 0}>Search</Button>
                    <Button onClick={handleClear}>CLEAR</Button>
                </ButtonGroup>
            </div>
        </div>
    );
}

export default StolenVehicleSearch;

