import React, {useState} from "react";
import PersonMainRecord from "../../components/Admin/UpdatePersonMainRecord";
import { useLocation } from "react-router-dom";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function ManagePersonMainRecord() {
    //let [personID,setPersonID] = React.useState<string>("0")
    let query = useQuery();
    
    //when this gets called grab from query?    
    
    return (
        <div className="w-full">
            <PersonMainRecord ID={"0"} Hash={query.get("Hash")}/>
        </div>
    );
}

export default ManagePersonMainRecord;