import React from "react";
import GVRTFOutputForm from "./GVRTF_OutputForm";
import { useLocation } from "react-router-dom";
import { RunGetEmailOutputGRAILReferral } from "../../services/getDar.service";

interface DetailProps {
    Person:any | null,
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function GVRTFEmailOutputForm() {
    const [data,setData] = React.useState<any[]>([])
    const [callData, setCallData] = React.useState<boolean>(false)
    const [params, setParams] = React.useState<any>({
        submitDate:"", fName:"", mName:"", lName:"", dob:"",sbi:"", fbi:"", eCDR:"", city:"", resCounty:"",
        custodyStatus:"N", custodyCounty:"", tArrests:0, gunArrests:0,
        felConvict:0, victimNum:0, suspectNum:0, njpopStatus:"N", njpop:"", gangStatus:"N", gang:"",
        paroleStatus:"N", paroleStart:"", paroleEnd:"", codisStatus:"N", codis:"", pendingCharges:0, pendingGunCharges:0, pic:"",
        referralStatus:"Preview", adoptedStatus:"N", referralCounty:"", distributionList:[], prosecutor:"", judge:"", prosecution:"", rtcc:"",
        nibinStatus:"N", nibin:"", caseExplorer:"", caseExplorerStatus:"N", infoShareStatus:"N", infoShare:"", analyticNotes:"", comments:"",
        editStatus:"Add", reportID:"", personID:"", tblgunpersonID:"", murderStatus:'N', murder:"", murderNum:0, violentPerson:"N" , NJPopNum: "", NJPopNumOld: "",shootingArrestStatus: "N"
    })

    let query = useQuery();
    let queryID = query.get('ID');
    let APIKEY = query.get('APIKEY');

    React.useEffect(() => {
        if(!callData)
        {
            localStorage.setItem('user',`${APIKEY}`)
            setCallData(true)
            let temp = params;
            temp.ID = queryID;
            setParams(temp)
            loadData()
        }
    },[])

    const loadData = async () => {

        if(queryID && queryID?.toString().length > 0){

            let res = await RunGetEmailOutputGRAILReferral(params,APIKEY)
            console.log(res)

            if(res.AnyTable.length > 0){
                setData([res.AnyTable[0]])
            }
        }    
    }

    return(
        
        <div>
            <GVRTFOutputForm Persons={data} />
        </div>
    )

}

export default GVRTFEmailOutputForm;