import {PersonInfo} from "../../../interfaces/getDarInfo_interface";
import React from "react";
import { Disclosure } from '@headlessui/react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import  ALPRInfo  from "../../../interfaces/ALPRInfo.interface";
import { TablePagination, CircularProgress, Button, IconButton, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import ALPRIcon from "../../../assets/Images/alpr1.png";
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent ,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search
} from '@syncfusion/ej2-react-grids';
import BTVehGridImage from "../../tools/alpr_vin_search/_VehImg";
interface PanelALPRProps {
    person: PersonInfo,
    ALPRInfo: ALPRInfo[],
    ALPRTrigger: any,
    isLoading: boolean
}

const  PanelALPR: React.FC<PanelALPRProps> = ({person, ALPRInfo, isLoading,ALPRTrigger})=> {

    const [FormatData, setFormatData] = React.useState(0);

    const handleDataTyping = () => {
        if (ALPRInfo) {
            let tempP: any = [...ALPRInfo]
            for (let p of tempP) {
                if (p.red_TimeStamp) {
                    p.red_TimeStamp = new Date(p.red_TimeStamp)
                }
            }
            setFormatData(tempP)
        }
    }

    React.useEffect(() => {
        handleDataTyping()
    }, [ALPRInfo])

    const miniVehImage = (lprRecord: any): any => {
        return (
            <BTVehGridImage lprRecord={lprRecord} origination='' />
        )
    }

    return(
        <div className={"m-5 border"}>
            <Disclosure defaultOpen={false}>
                {({ open }) => (
                    <>
                        {/*<Disclosure.Button className="bg-blue-100 p-2 w-auto w-full text-left" onClick={() => { ALPRTrigger() }}>*/}
                        <Disclosure.Button className="bg-blue-100 p-2 w-auto w-full text-left">
                            <span style={{ "display": "flex", "justifyContent": "space-between" }}>
                                <span>
                                    <KeyboardArrowDownIcon
                                        className={`${open ? "transform rotate-180" : ""}`}
                                    />
                                    {/*<span className={"font-bold"}>ALPR <img src={ALPRIcon} alt={''} style={{ display: 'inline' }} /> (Click here to get Latest ALPR)  {(isLoading) ? <CircularProgress size={20} style={{ "marginLeft": "5px" }} />  : <></>}</span>*/}

                                    <span className={"font-bold"}>LPR <img src={ALPRIcon} alt={''} style={{ display: 'inline' }} />
                                        <Tooltip title="It will get all the latest LPR data from all the vendors" placement="top" arrow>
                                            <Button style={{ "marginLeft": "10px" }} variant="outlined" onClick={() => { ALPRTrigger() }}>Click here to get Latest LPR</Button>
                                        </Tooltip>
                                        {(isLoading) ? <CircularProgress size={20} style={{ "marginLeft": "10px" }} /> : <></>}
                                    </span>
                                </span>
                                <span className={"font-bold mr-2"} >Name: {person?.FName?.toUpperCase()} {person?.MName?.toUpperCase()} {person?.LName?.toUpperCase()} &nbsp;&nbsp;Records: {ALPRInfo?.length}</span>
                            </span>
                        </Disclosure.Button>

                        <Disclosure.Panel className="text-gray-500 p-4">

                            <GridComponent
                                dataSource={FormatData}
                                allowPaging={true}
                                pageSettings={{ pageSize: 50, pageSizes: [10, 25, 50, 100] }}
                                allowSorting={true}
                                allowMultiSorting={true}
                                allowFiltering={true}
                                filterSettings={{ type: 'CheckBox' }}
                                allowTextWrap={true}
                                textWrapSettings={{ wrapMode: 'Both' }}
                                rowHeight={24}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective field='image' template={miniVehImage} minWidth={120} maxWidth={240}  headerText='Image' customAttributes={{ class: ['e-attr'] }}/>
                                    <ColumnDirective field='red_TimeStamp' headerText='Time Stamp' format={{ type: 'dateTime', format: 'MM/dd/yyyy HH:mm:ss' }} />
                                    <ColumnDirective field='red_vrm' headerText='Plate #' />
                                    <ColumnDirective field='src_Name' headerText='Department' />
                                    <ColumnDirective field='src_Description' headerText='Camera Name' />
                                    <ColumnDirective field='Latitude' headerText='Latitude' />
                                    <ColumnDirective field='Longitude' headerText='Longitude' />
                                    <ColumnDirective template={(item: any) => (<Button onClick={() => { window.open('/detail/ALPR?ID=' + item?.LprID + '&Dept=' + item?.ServerDB, '_blank') }} >View</Button>)} headerText='View' />
                                </ColumnsDirective>
                                <Inject services={[Sort, Page, Filter]} />
                            </GridComponent>

                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>

    )
};

export default PanelALPR;