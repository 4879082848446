import React from "react";
import NavBar from "../../components/NavBar/navbar";
import Footer from "../../components/NavBar/footer";

interface OldUIState {}
interface oldUIProps{ count:number}
export class OldUILayout extends React.Component<oldUIProps, OldUIState> {
    constructor(props:oldUIProps) {
        super(props);
        this.state={}
    }
    
    render () {
        return (
                <div>
                    <NavBar count={this.props.count}/>
                    {this.props.children}
                    <Footer />
                </div>
        );
    }
}
