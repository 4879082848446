import React, { useEffect, useState } from "react";
import ReactPlayer from 'react-player'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {Account} from "../../interfaces/auth_interface";
import {getUser} from "../../services/auth.service";
import {SiteName} from "../../services/config.service";
import {SITE} from "../../interfaces/config.interface";
import Accordion from "@mui/material/Accordion";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {AccordionDetails, Button, Typography} from "@mui/material";

const TRAINING: React.FC = () => {
    let [user, setUser] = useState<Account>(getUser())

    return (
        <div className="mb-2">
            {/*<div className="mx-auto mt-2" style={{
                display: 'flex',
                width: '100%',
                height: '5vh',
                justifyContent: 'center',
            }}>
                <Button variant="contained" style={{width: '40%', height: '80%', backgroundColor: '#1e3a8a', fontSize:'20px'}}
                        onClick={() => window.open("https://gtbm.com/trainingcalendar", '_blank')}>
                    Schedule Training <CalendarMonthIcon style={{marginLeft: '5px'}}/>
                </Button>
            </div>*/}
            <div className="w-full flex justify-center mb-2">
                <div className={"border-2 border-gray-400 rounded-lg mr-1"}>
                        <Accordion expanded={true}>
                            <div style={{marginLeft: '10px', paddingBottom: '5px', paddingTop: '5px'}}>
                                <div className="w-full grid grid-cols-12">
                                    <div className="text-md col-span-2 self-center ml-3"> Basic Training</div>
                                    <div className="text-sm col-start-12 col-span-1 text-end">42 Min</div>
                                    <div className="text-2xl font-bold col-span-12 self-center ml-3"> NJSNAP Basics
                                    </div>
                                </div>
                            </div>
                            <AccordionDetails>
                                <Typography>
                                    <div className="w-full grid grid-cols-12" style={{height: '35vh'}}>
                                        <div className='player-wrapper col-span-12 self-center'
                                             style={{height: '35vh'}}>
                                            <ReactPlayer
                                                className='react-player fixed-bottom'
                                                url='/NJSNAP-Basics.mp4'
                                                width='100%'
                                                height='100%'
                                                controls={true}

                                            />
                                        </div>
                                    </div>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                {((user.AuthLevel === 'B' || user.AuthLevel === 'S') && !(SiteName === SITE.DCDEMO || SiteName === SITE.DCPROD || SiteName === SITE.DCLOCAL)) &&
                    <div className={"border-2 border-gray-400 rounded-lg ml-1"}>
                        <Accordion expanded={true}>
                            <div style={{marginLeft: '10px', paddingBottom: '5px', paddingTop: '5px'}}>
                                <div className="w-full grid grid-cols-12">
                                    <div className="text-md col-span-2 self-center ml-3">User Management</div>
                                    <div className="text-sm col-start-12 col-span-1 text-end">17 Sec</div>
                                    <div className="text-2xl font-bold col-span-12 self-center ml-3"> How To Create New Users</div>
                                </div>
                            </div>
                            <AccordionDetails>
                                <Typography>
                                    <div className="w-full grid grid-cols-12" style={{height: '35vh'}}>
                                        <div className='player-wrapper col-span-12 self-center'
                                             style={{height: '35vh'}}>
                                            <ReactPlayer
                                                className='react-player fixed-bottom'
                                                url='/Add-User-NJCAP5.mp4'
                                                width='100%'
                                                height='100%'
                                                controls={true}

                                            />
                                        </div>
                                    </div>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                }
                </div>
        </div>
    );
}

export default TRAINING;