import React, { useState, useEffect } from "react";
import {
    TextField,
    Checkbox,
    Button,
    ToggleButtonGroup,
    ToggleButton,
    Card,
    CardContent,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    Dialog, Tooltip
} from "@mui/material";
import InfocopImageDetail from "../../interfaces/Detail/InfocopImageDetail.interface"
import { RunInfocopImageDetailQuery } from "../../services/detail.service";
import { formatDate } from "../../services/formatDate.service"
import './Detail.css'
import {TransitionProps} from "@mui/material/transitions";
import Slide from "@mui/material/Slide";


interface DetailProps {
    ID: string | null
    department: string | null
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="down" ref={ref} {...props} />;
});


const InfocopImageDetailInfo: React.FC<DetailProps> = ({ ID, department }) => {
    const [DataLoaded, setDataLoaded] = React.useState<boolean>(false)
    const [Data, setData] = React.useState<InfocopImageDetail[]>([])
    const [enlarged, setEnlarged] = React.useState<any>(null)
    const [open, setOpen] = React.useState<boolean>(false)

    const loadData = async () => {
        
        if (!DataLoaded) {
            setDataLoaded(true)
            let res:any[] = await RunInfocopImageDetailQuery(ID, department);
            setData(res)
        }
    }

    React.useEffect(() => {
        if (!DataLoaded) {
            setDataLoaded(true)
            loadData()
        }
    });

    return (
        <div className="bg-gray-100 ml-5 mr-5 rounded p-5 ">
            {enlarged ?
                <Dialog
                    fullWidth={true}
                    maxWidth={'md'}
                    onClose={()=>{setEnlarged(null)}}
                    open={open}
                    TransitionComponent={Transition}

                >
                    <Tooltip title={"Click to Shrink"}><img src={enlarged} width="full" height="full" onClick={()=>{setEnlarged(null)}}/></Tooltip>
                </Dialog>
                :
                <></>
            }
            <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }} >
                <CardContent>
                    <h3 className="mb-4 font-bold font text-xl text-blue-400">Person Image</h3>

                    <table style={{ "width": "100%" }}>
                        <thead className={"bg-blue-400 text-white text-start"}>
                            <tr>
                                <th className="mr-3">Image Source</th>
                                <th className="mr-3">Top Text</th>
                                <th className="mr-3">Up Bottom Text</th>
                                <th className="mr-3">Low Bottom Text</th>
                                <th className="mr-3">Picture</th>
                            </tr>
                        </thead>
                        <tbody className={"text-start"}>
                            {(Data) ? Data.map((item) => (
                                <tr>
                                    <td>{item.ImageSource}</td>
                                    <td>{item.TopText}</td>
                                    <td>{item.UpBtmText}</td>
                                    <td>{item.LowBtmText}</td>
                                    <td><Tooltip title={"Click to Enlarge"}><img src={item.Image} width="200" height="250" onClick={()=>{setEnlarged(item?.Image); setOpen(true)}}/></Tooltip></td>
                                </tr>
                            )) : <></>}
                        </tbody>
                    </table>
                </CardContent>
            </Card>
        </div>
    );
}

export default InfocopImageDetailInfo;