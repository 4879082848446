import React from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  ColumnMenu,
  GridComponent,
  Grid,
  Inject,
  Page,
  Sort,
  Filter,
  ExcelExport,
  PdfExport,
  Toolbar,
  Search,
  Resize,
  Reorder,
  SortSettingsModel,
  Edit,
  CommandColumn,
} from "@syncfusion/ej2-react-grids";
import { InvestigationObject } from "../../../interfaces/NJPORT/InvestigationLog.interface";
import { Alert, Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Slide, Snackbar, Tooltip, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { PDOHireObject } from "../../../interfaces/NJPORT/PDOHire.interface";
import { TransitionProps } from "@mui/material/transitions";
import { DeletePdoHire } from "../../../services/njport.service";
import CloseIcon from "@mui/icons-material/Close";

type SearchSubResultsProps = {
  pdo: InvestigationObject[];
  handleEditReport: (rowDataID: any, isSubData: boolean,isedit:boolean, rowMainRec: any) => void;
};
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const PdoSearchSubResults = ({
  pdo,
  handleEditReport,
}: SearchSubResultsProps) => {
  const [typedPersons, setTypedPersons] =
    React.useState<InvestigationObject[]>(pdo);
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [deleteDialogID, setDeleteDialogID] = React.useState<number>(0);
    const [alertType, setAlertType] = React.useState<any>("success");
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false);
    const [alertText, setAlertText] = React.useState<string>("");
    
  const handleDataTyping = () => {
    if (pdo && pdo.length > 0) {
      let tempP: any = [...pdo];

      for (let p of tempP) {
        if (p.Date_Received) {
          p.Date_Received = new Date(p.Date_Received);
        }
        if (p.Date_Status_Change) {
          p.Date_Status_Change = new Date(p.Date_Status_Change);
        }
        if (p.DOB) {
          p.DOB = new Date(p.DOB);
        }
        if (p.Date_Fingerprint_Return) {
          p.Date_Fingerprint_Return = new Date(p.Date_Fingerprint_Return);
        }
        if (p.Date_PAC_Card_Issued) {
          p.Date_PAC_Card_Issued = new Date(p.Date_PAC_Card_Issued);
        }
        if (p.CreateDate) {
          p.CreateDate = new Date(p.CreateDate);
        }
        if (p.UpdateDate) {
          p.UpdateDate = new Date(p.UpdateDate);
        }
      }
      setTypedPersons(tempP);
    } else {
      setTypedPersons([]);
    }
  };

  let grid: Grid | null;

  const refreshDataSource = () => {
    if (grid) {
      grid.dataSource = typedPersons;
    }
  };

  React.useEffect(() => {
    handleDataTyping();
  }, [pdo]);

  React.useEffect(() => {
    refreshDataSource();
  }, [typedPersons]);

  let sortSettings: SortSettingsModel = {
    columns: [{ field: "ID", direction: "Ascending" }],
  };
  // const filteredData = getFilteredData(typedPersons);
  const showActionView = (rowData: any): any => {
    return (
      <span>
        <ButtonGroup>
          <Tooltip title="Edit" placement="top" arrow>
            <IconButton
              onClick={() => {
                handleEditReport(rowData.ID, true,true, rowData.MainRec);
              }}
            >
              <EditIcon fontSize="small" color="primary" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete" placement="top" arrow>
            <IconButton
              onClick={() => {
                setOpenDeleteDialog(true);
                setDeleteDialogID(rowData.ID);
              }}
            >
              <DeleteIcon fontSize="small" color="error" />
            </IconButton>
          </Tooltip>
        </ButtonGroup>
      </span>
    );
  };
  const deleteReport = async () => {
    let id: number = deleteDialogID;
    if (id && id > 0) {
      let data = {
        id: id,
        editStatus: "DELETE",
        deleted: 1,
      };
      let isDeleted = await DeletePdoHire(data);
      if (isDeleted) {
        setAlertType("success");
        setAlertOpen(true);
        setAlertText(
          "Delete Successful (Changes Will Take Effect After The Next Search)",
        );
      } else {
        setAlertType("error");
        setAlertOpen(true);
        setAlertText("Unable to delete.");
      }
    }
    setOpenDeleteDialog(false);
  };

  return (
    <>
       <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={() => {
          setAlertOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setAlertOpen(false);
          }}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {alertText}
        </Alert>
      </Snackbar>
    {typedPersons.length > 0 ? (
      <GridComponent
        dataSource={typedPersons}
        allowPaging={true}
        pageSettings={{
          pageSize: 25,
          pageSizes: [10, 25, 50, 100],
        }}
        allowSorting={true}
        sortSettings={sortSettings}
        allowMultiSorting={true}
        allowFiltering={true}
        filterSettings={{ type: "CheckBox" }}
        allowTextWrap={true}
        textWrapSettings={{ wrapMode: "Both" }}
        allowExcelExport={true}
        allowPdfExport={false}
        toolbar={["ExcelExport", "Search"]}
        ref={(g) => (grid = g)}
        allowSelection={true}
        allowResizing={true}
        rowHeight={24}
      >
        <ColumnsDirective>
          <ColumnDirective
            template={showActionView}
            width={125}
            minWidth={125}
            maxWidth={140}
            headerText="Action"
          />
          <ColumnDirective
            field="ID"
            width={120}
            minWidth={90}
            maxWidth={120}
            headerText="ID"
            customAttributes={{ class: ["e-attr"] }}
          />
          <ColumnDirective
            field="Port"
            width={180}
            minWidth={180}
            maxWidth={180}
            headerText="Port"
            customAttributes={{ class: ["e-attr"] }}
          />
          <ColumnDirective
            field="ShipTerminal"
            width={180}
            minWidth={180}
            maxWidth={180}
            headerText="ShipTerminal"
            customAttributes={{ class: ["e-attr"] }}
          />
          <ColumnDirective
            field="RequestsFilled"
            width={140}
            minWidth={140}
            maxWidth={140}
            headerText="Requests"
            customAttributes={{ class: ["e-attr"] }}
          />
          <ColumnDirective
            field="Unfilled"
            width={145}
            minWidth={145}
            maxWidth={145}
            headerText="Unfilled"
            customAttributes={{ class: ["e-attr"] }}
          />
          <ColumnDirective
            field="Remaining"
            width={140}
            minWidth={140}
            maxWidth={140}
            headerText="Remaining"
            customAttributes={{ class: ["e-attr"] }}
          />
        </ColumnsDirective>
        <Inject
          services={[
            ColumnMenu,
            Sort,
            Page,
            Filter,
            Toolbar,
            ExcelExport,
            PdfExport,
            Search,
            Resize,
            Reorder,
            Edit,
            CommandColumn,
          ]}
        />
      </GridComponent>
    ) : (
      ""
    )}
        <Dialog
        open={openDeleteDialog}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth={"sm"}
        scroll="paper"
        //onClose={handleDeleteDialogClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2>Delete Report</h2>
          <IconButton
            onClick={() => {
              setOpenDeleteDialog(false);
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ display: "flex", justifyContent: "center" }}>
          <Typography>
            Do you really want to delete this profile report? You can't undo
            this action.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setOpenDeleteDialog(false);
            }}
          >
            Cancel
          </Button>
          <Button onClick={deleteReport}>Delete Report</Button>
        </DialogActions>
      </Dialog>
  </>
  );
};

export default PdoSearchSubResults;
