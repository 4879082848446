import React, { forwardRef } from "react";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import {
  DropDown,
} from "../../../interfaces/NJPORT/InvestigationLog.interface";
import { InvestigationDropDown } from "../../../services/njport.service";
import { TerminalHireQuery } from "../../../interfaces/NJPORT/TerminalHire.interface";

const TerminalHireReportSearch: React.FC<any> = ({ SubmitSearch }) => {
  let today: Date = new Date();
  var fromday: Date = new Date();
  if (today.getDay() === 1) {
    fromday.setDate(today.getDate() - 2);
  }

  const [terminaldata, setTerminalData] = React.useState<TerminalHireQuery>({
    fromDate: fromday.toISOString(),
    toDate: today.toISOString(),
  });
  const [initialRun, setInitialRun] = React.useState<boolean>(false);
  const [dateType, setDateType] = React.useState<string>("Entry Date");

  React.useEffect(() => {
    if (!initialRun) {
      setInitialRun(true);
      setDateValues();
      SubmitSearch(terminaldata);
    }
  }, [initialRun, terminaldata, SubmitSearch]);

  const convertDate = (date: Date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const setDateValues = () => {
    let data = { ...terminaldata };
    data.fromDate = convertDate(fromday);
    data.toDate = convertDate(today);
    setTerminalData(data);
  };

  const handleChange = (field: string, val: any) => {
    let data = { ...terminaldata };
    switch (field) {
      case "fromDate":
        data.fromDate = `${val}`;
        break;
      case "toDate":
        data.toDate = `${val}`;
        break;
    }
    setTerminalData(data);
  };
  const handleSubmit = () => {
    let data={...terminaldata};
    data.fromDate=`${data.fromDate}  00:00:00`;
    data.toDate=`${data.toDate}  23:59:59`;
    SubmitSearch(data);
  };
  const handleDateType = (
    event: React.MouseEvent<HTMLElement>,
    dateType: string
  ) => {
    if (dateType === "Entry Date") {
      setTerminalData({ ...terminaldata, dateTypeValue: 0 });
    } else {
      setTerminalData({ ...terminaldata, dateTypeValue: 1 });
    }
    if (dateType) {
      setDateType(dateType);
    }
  };
  const handleClear = () => {
    console.log("Clear button clicked");
    
    let data = { ...terminaldata };
    data.fromDate = convertDate(fromday);
    data.toDate = convertDate(today);
    
    setTerminalData(data);

  };
  return (
    <div className="mt-5 ml-5 mr-5 p-5">
      <Box className="pt-3" sx={{ flexGrow: 1 }}>
        <Grid container spacing={1} sx={{ pl: 2 }}>
          <Grid item xs={2} sx={{ pr: 2 }}>
            <ToggleButtonGroup
              color="primary"
              value={dateType}
              exclusive
              fullWidth
              onChange={handleDateType}
            >
              <ToggleButton value="Report Date">Report Date</ToggleButton>
              <ToggleButton value="Entry Date">Entry Date</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={5} sx={{ pr: 2 }}>
            <TextField
              fullWidth
              margin="none"
              type="date"
              name="fromDate"
              label="From Date"
              value={terminaldata.fromDate}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => handleChange("fromDate", e.target.value)}
            />
          </Grid>
          <Grid item xs={5} sx={{ pr: 2 }}>
            <TextField
              fullWidth
              margin="none"
              type="date"
              name="toDate"
              label="To Date"
              value={terminaldata.toDate}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => handleChange("toDate", e.target.value)}
            />
          </Grid>    
         </Grid> 
        <Grid container spacing={1} sx={{ pl: 2, mt: 2, justifyContent: 'flex-end' }}>
          <Grid item xs={8} sx={{ pr: 2 }}></Grid>
          <Grid item xs={2} sx={{ pr: 2 }}>
            <ButtonGroup fullWidth size="large" color="inherit">
              <Button type="submit" onClick={handleSubmit}>
                Search
              </Button>
              <Button onClick={handleClear} >CLEAR</Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default TerminalHireReportSearch;
