import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, TextField, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import { ALPRDropDowns, ETicketDropDowns, ParkMobileDropDowns, VinDropDowns } from "../arrest/_arrestDropDowns";
import MapIcon from '@mui/icons-material/Map';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
/*import { DateTimeRangeContainer } from 'react-advanced-datetimerange-picker';*/
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import GeoCode from "../../shared/GeoCodes/_GeoCode_Display";
import ErrorIcon from "@mui/icons-material/Error";
import { JAOS } from "../../../services/JAOS.service";
import { SiteName } from "../../../services/config.service";
import { GetStringNameForSite, SITE } from "../../../interfaces/config.interface";

interface VINSearchProps {
    SubmitSearch: any
    data?: any | null
}

const VinSearch: React.FC<VINSearchProps> = ({ SubmitSearch, data = null }) => {
    let todayDate = new Date();
    let startDate = new Date(new Date().getTime() - (6 * 60 * 60 * 1000));  //set from-datetime 6 hours before to-datetime
    //startDate.setHours(0, 0, 0, 0);
    const [params, setParams] = React.useState({
        PlateNo: "", PlateState: "", VehVINNo: "", ALPRCaseNumber: "",
        ALPR: (!(SiteName == SITE.NJPROD || SiteName == SITE.NJUAT || SiteName == SITE.NJDEMO || SiteName == SITE.LOCAL)),
        ETicket: false, ParkMobile: false,
        fromDate: startDate, toDate: todayDate
    })
    const [dropDownParams, setDropDownParams] = React.useState<any>({ DD: {}, ADD: {}, EDD: {}, PDD: {} });
    const [clear, setClear] = React.useState<number>(0);
    const [showGeoCode, setShowGeoCode] = React.useState<boolean>(false)
    const [allPins, setAllPins] = React.useState<any[]>([])
    const [mapVersion, setMapVersion] = useState<string>("??")
    const [submitError, setSubmitError] = React.useState<string>("")
    const [vinType, setVinType] = React.useState<string>("NoVIN");

    useEffect(() => {
        if (data) {
            handlePlateList()
        }
    }, [data]);

    const handlePlateList = async () => {
        let l = new JAOS()
        let plateList = l.objFromStack(data)
        let tParams = { ...params }
        tParams.PlateNo = plateList
        setParams({ ...params, PlateNo: plateList })
        SubmitSearch(tParams, dropDownParams, "VIN");
    }

    const HandleDropDowns = (dropDowns: any) => {
        let temp = dropDownParams
        temp.DD = dropDowns
        setDropDownParams(temp)
    }

    const HandleDropDownALPRs = (dropDowns: any) => {
        let temp = dropDownParams
        temp.ADD = dropDowns
        setDropDownParams(temp)
    }

    const HandleDropDownETickets = (dropDowns: any) => {
        let temp = dropDownParams
        temp.EDD = dropDowns
        setDropDownParams(temp)
    }

    const HandleDropDownParkMobiles = (dropDowns: any) => {
        let temp = dropDownParams
        temp.PDD = dropDowns
        setDropDownParams(temp)
    }

    const handleClear = () => {
        setSubmitError("");
        setParams({ ...params, PlateNo: "", PlateState: "", VehVINNo: "", ALPRCaseNumber: "", ALPR: (SiteName == SITE.NJPROD || SiteName == SITE.NJDEMO || SiteName == SITE.NJUAT || SiteName == SITE.LOCAL) ? false : true, ETicket: false, ParkMobile: false, fromDate: startDate, toDate: todayDate })
        setDropDownParams({ DD: {}, ADD: {}, EDD: {}, PDD: {} });
        setVinType("NoVIN")
        setClear(clear + 1)
    }

    const openGeoCode = () => {
        setMapVersion("alprCamera")
        setAllPins([])
        let pins: any[] = []
        if (dropDownParams.ADD.ALPRCameraLatLong && dropDownParams.ADD.ALPRCameraLatLong.length > 0) {
            for (let p of dropDownParams.ADD.ALPRCameraLatLong) {
                if (p.Latitude && p.Longitude) {
                    pins.push({
                        lat: p.Latitude,
                        lng: p.Longitude,
                        date: p.DateTimeIN,
                        alprSource: p.Source,
                        name: p.Name,
                        status: p.Status,
                        tool: "alprCamera"
                    })
                }
            }
        }
        setAllPins(pins);
        setShowGeoCode(true);
    }

    const HandleGeoCodeClose = () => {
        setShowGeoCode(false)
    }

    const handleALPR = (e: any) => {
        let show = params.ALPR
        //let pm = params.ParkMobile
        //, ParkMobile: (!show) ? true : pm
        setParams({ ...params, ALPR: !show })
    }

    const handleETicket = (e: any) => {
        let show = params.ETicket
        setParams({ ...params, ETicket: !show })
    }

    const handleParkMobile = (e: any) => {
        let show = params.ParkMobile
        setParams({ ...params, ParkMobile: !show })
    }

    const handleSearch = () => {
        if (handleValidation() && handleLPRSearchLimit())
            SubmitSearch(params, dropDownParams, vinType)
    }

    const handleLPRSearchLimit = () => {
        setSubmitError("")
        var val1 = true
        //console.log("test all condition", dropDownParams)
        const re = new RegExp("[%_\\-?\\^*\\[\\]]");
        if (params.ALPR) {
            let pl_match = re.exec(params.PlateNo) != null;
            var countyCount = dropDownParams.ADD?.ALPRServer.filter((item: string) => item?.endsWith('County')).length;
            var cityCount = dropDownParams.ADD?.ALPRServer.filter((item: string) => item?.includes("_")).length;
            //
            if ((params.PlateNo?.trim()?.length === 0 || (pl_match && params.PlateNo.length !== 0))
                && (dropDownParams?.ADD?.ALPRCamera?.length === 0 || dropDownParams?.ADD?.ALPRCamera?.length > 5)
                && (dropDownParams.ADD?.ALPRServer?.length === 0 && !dropDownParams.ADD?.ALPRServerAll)) {
                setSubmitError("ALPR (For no Plate or Wildcard Search): Please Select an ALPR Area (Max. 1 County) or Camera (Max. 5 cameras)\n");
                val1 = false
            }
            //
            if ((params.PlateNo?.trim()?.length === 0 || (pl_match && params.PlateNo.length !== 0))
                && (((countyCount === 0 && cityCount > 5)) || (countyCount > 1 && cityCount > 0) || dropDownParams?.ADD?.ALPRCamera?.length > 5)) {
                setSubmitError("ALPR (For no Plate or Wildcard Search): Please Select an ALPR Area (Max. 1 County) or Camera (Max. 5 cameras)\n");
                val1 = false
            }
        }
        return val1;
    }

    const handleValidation = () => {
        //Limits on LPR Search via GUI
        setSubmitError("")
        var val = true
        let checkFutureDate = 0
        let fDate = new Date(params.fromDate)
        let tDate = new Date(params.toDate)
        //Check Date difference
        if (fDate.getDate() === tDate.getDate()) {
            let diffInSeconds = (tDate.getTime() - fDate.getTime()) / 1000;
            //Ignore difference in seconds for the same date, hours and minutes
            //Currently resolution on the GUI is upto minutes
            if (diffInSeconds < 60) {
                checkFutureDate = 0
            }
        }
        else {
            checkFutureDate = (tDate.getTime() - fDate.getTime())
        }
        //
        let diffDateRange = Math.abs(tDate.getTime() - fDate.getTime())
        diffDateRange = diffDateRange / (1000 * 60 * 60 * 24)

        if (params.ALPR && checkFutureDate < 0) {
            setSubmitError("ALPR: From-Date-time should be before To-Date-time!\n")
            val = false
            return
        }

        if (params.ALPR && diffDateRange > 0.25 && params.PlateNo?.trim()?.length === 0) {
            setSubmitError("ALPR: Please Limit the Date Range to 6 Hours with no Plate in Plate Field.\n")
            val = false
            return
        }

        //With wildcard characters in plate field: Time Frame of Search should not exceed 30 days
        const re = new RegExp("[%_\\-?\\^*\\[\\]]");
        let pl_match2 = re.exec(params.PlateNo) != null;
        if (params.ALPR && diffDateRange > 0.25 && pl_match2 && params.PlateNo.length !== 0) {
            setSubmitError("ALPR: Please Limit the Date Range to 6 Hours for Wildcard with Alphanumeric Character in Plate Field.\n")
            val = false
            return
        }

        if (params.ALPR && diffDateRange > 365) {
            setSubmitError("ALPR: Please Limit the Date Range to a maximum of 365 Days.\n")
            val = false
            return
        }

        if (!params.ALPR && !params.ETicket && !params.ParkMobile && params.PlateNo?.trim()?.length === 0 && params.VehVINNo?.trim()?.length === 0) {
            setSubmitError("Please enter at least one search parameter (ALPR, ETicket, Parking, Plate # or VIN)\n");
            val = false;
        }

        if (params.ALPR && (dropDownParams.ADD?.ALPRReason?.length === 0 || params.ALPRCaseNumber?.trim().length === 0)) {
            setSubmitError("ALPR: Please enter ALPR Reason & " + GetStringNameForSite(SiteName, 'CASE #') + "\n");
            val = false
        }

        if (params.ALPR && diffDateRange > 0.25 && dropDownParams?.ADD?.ALPRCamera?.length > 5) {
            setSubmitError("ALPR: Please Limit the Date Range to 6 Hours and the Number of Selected Cameras to 5.\n")
            val = false
        }

        return val
    }

    const handleVINType = (event: React.MouseEvent<HTMLElement>, VinType: string) => {
        if (VinType) {
            setVinType(VinType);
            if (VinType === 'NoVIN') {
                setDropDownParams({ ...dropDownParams, DD: {}, ADD: dropDownParams.ADD, EDD: dropDownParams.EDD, PDD: dropDownParams.PDD })
            }
        }
    };

    /*
                {vinType == "VIN" ?
                    <div className="grid grid-cols-1 gap-0.5 gap-y-2" style={{ "marginTop": "10px" }}>
                        <VinDropDowns HandleDropDowns={HandleDropDowns} clear={clear} />
                    </div>
                    : <></>}
    */

    return (
        <div className="bg-gray-100 ml-5 mr-5 rounded p-5">
            {(SiteName == SITE.NJPROD || SiteName == SITE.NJDEMO || SiteName == SITE.NJUAT || SiteName == SITE.LOCAL) && <>

                <ToggleButtonGroup
                    color="primary"
                    value={vinType}
                    exclusive
                    size="small"
                    onChange={handleVINType}
                    sx={{ ml: 1 }}
                >
                    <ToggleButton value="NoVIN" sx={{ pr: 2, pl: 2 }} >Without Make & Model</ToggleButton>
                    <ToggleButton value="VIN" sx={{ pr: 2, pl: 2 }} >Make & Model</ToggleButton>
                </ToggleButtonGroup>

                {vinType == "VIN" ?
                    <div className="grid grid-cols-1 gap-0.5 gap-y-2" style={{ "marginTop": "10px" }}>
                        <VinDropDowns HandleDropDowns={HandleDropDowns} clear={clear} />
                    </div>
                    : <></>}
            </>}

            {(showGeoCode) ? <div><GeoCode HandleGeoCodeClose={HandleGeoCodeClose} show={showGeoCode} pins={allPins} mapVersion={mapVersion} /></div> : <></>}

            <div className="grid grid-cols-7 gap-x-4 gap-y-2 ml-2 mt-3" >
                <TextField margin='none' size={"small"} label="Plate #" value={params.PlateNo} onChange={(e: any) => setParams({ ...params, PlateNo: e.target.value })}
                    title="Search Sample: PL*E, PL%E, PL_TE, PL[A-E]TE, PL[^E-H]TE, 'PLATE1,PLATE2,PLATE3'" />
                <TextField margin='none' size={"small"} label="Plate State" value={params.PlateState} onChange={(e: any) => setParams({ ...params, PlateState: e.target.value })} />
                <TextField margin='none' size={"small"} label="VIN #" value={params.VehVINNo} onChange={(e: any) => setParams({ ...params, VehVINNo: e.target.value })} />
            </div>
            <div className="mb-2 ml-2 mt-5" style={{ display: "flex", justifyContent: "left" }} >
                <div className="font-medium">Date Range</div>
                <div className="border-gray-300 border-2 rounded ml-4 mr-2 w-48 pl-2 pr-2">
                    <DateTimePickerComponent
                        value={params.fromDate}
                        strictMode={true}
                        onChange={(e: { target: { value: any; }; }) => setParams({ ...params, fromDate: e.target.value })}
                        format='MM-dd-yyyy HH:mm'
                        floatLabelType="Auto"
                    />
                </div>
                <div className="border-gray-300 border-2 rounded ml-4 mr-2 w-48 pl-2 pr-2">
                    <DateTimePickerComponent
                        value={params.toDate}
                        strictMode={true}
                        onChange={(e: { target: { value: any; }; }) => setParams({ ...params, toDate: e.target.value })}
                        format='MM-dd-yyyy HH:mm'
                        floatLabelType="Auto"
                    />
                </div>
            </div>
            <div className={"ml-2"}>
                {(SiteName == SITE.NJPROD || SiteName == SITE.NJDEMO || SiteName == SITE.NJUAT || SiteName == SITE.LOCAL) &&
                    <Tooltip title="ALPR (For no Plate or Wildcard Search): ALPR Area (Max. 1 County) or Camera (Max. 5 cameras)" placement="top" arrow>
                        <div style={{ width: 20 }}>
                            <CheckBoxComponent label="ALPR" cssClass="e-info" checked={params.ALPR} onChange={handleALPR} />
                        </div>
                    </Tooltip>
                }
                {(params.ALPR) ?
                    <div className="mb-5 mt-5 grid grid-cols-8 gap-x-4 gap-y-2" >
                        <div className={"col-span-3"}><ALPRDropDowns HandleDropDowns={HandleDropDownALPRs} clear={clear} /></div>
                        <div><TextField margin='none' size={"small"} fullWidth label="Case Number" value={params.ALPRCaseNumber}
                            onChange={(e: any) => setParams({ ...params, ALPRCaseNumber: e.target.value })} /></div>
                        <div>
                            <Button variant="outlined" fullWidth color="inherit" onClick={() => { openGeoCode() }} title="Maps ALPR Cameras for selected Area">
                                <MapIcon color="success" className="mr-2" /><b>ALPR Camera</b>
                            </Button>
                        </div>
                    </div>
                    : <></>
                }
            </div>
            {(SiteName == SITE.NJPROD || SiteName == SITE.NJUAT || SiteName == SITE.NJDEMO || SiteName == SITE.LOCAL) &&
                <div className="ml-2">
                    <CheckBoxComponent label="ETicket" cssClass="e-info" checked={params.ETicket} onChange={handleETicket} />
                    {(params.ETicket) ?
                        <div className="mb-5 mt-5 grid grid-cols-8 gap-x-4 gap-y-2">
                            <ETicketDropDowns HandleDropDowns={HandleDropDownETickets} clear={clear} />
                        </div>
                        : <></>
                    }
                </div>}
            <div className="ml-2">
                <CheckBoxComponent label="Parking" cssClass="e-info" checked={params.ParkMobile} onChange={handleParkMobile} />
                {(params.ParkMobile) ?
                    <div className="mb-5 mt-5 grid grid-cols-8 gap-x-4 gap-y-2">
                        <ParkMobileDropDowns HandleDropDowns={HandleDropDownParkMobiles} clear={clear} alprSelected={params.ALPR} />
                    </div>
                    : <></>
                }
            </div>
            <div className={"pt-3"} style={{ "display": "flex", "justifyContent": "end" }}>
                <ButtonGroup size="large" color="inherit" >
                    <Button type="submit" onClick={handleSearch}>Search</Button>
                    <Button onClick={handleClear}>CLEAR</Button>
                </ButtonGroup>
            </div>
            <span style={{ "display": "flex", "justifyContent": "end" }} className="col-span-4">{submitError?.length > 0 ? <span style={{ color: "red", fontSize: 14 }}><ErrorIcon style={{ color: "red" }} fontSize='small' />{submitError}</span> : <></>}</span>
            {(SiteName == SITE.NJPROD || SiteName == SITE.NJUAT || SiteName == SITE.NJDEMO || SiteName == SITE.LOCAL) && <>
                <b>Note: Third Party vendors are included in LPR Search. Example: Bergen County will show reads from
                    Vigilant, PIPs, Packetalk etc. </b>
                <b>Stolen Car(s) are indicated in red color.</b>
            </>}

        </div>
    );
}

export default VinSearch;