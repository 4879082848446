import React from 'react'
import {
    Button, Tooltip
} from "@mui/material";
import {PersonInfo} from "../../../interfaces/getDarInfo_interface";
import PersonPhotoIcon from "../../../assets/Images/photo1.png";
import ArrestIcon from "../../../assets/Images/ai1.png";
import InfoCopIcon from "../../../assets/Images/ic1.png";
import ETicketIcon from "../../../assets/Images/et1.png";
import ParkMobileIcon from "../../../assets/Images/pm1.png";
import ALPRIcon from "../../../assets/Images/alpr1.png";
import CADRMSIcon from "../../../assets/Images/Opencase1.png";
import CODISIcon from "../../../assets/Images/DNA1.png";
import TowingIcon from "../../../assets/Images/towing1.png";
import PrisonReleaseIcon from "../../../assets/Images/jail1.png";
import GunTraceIcon from "../../../assets/Images/WeaponTrace1.png";
import ShootingIcon from "../../../assets/Images/Shooting.png";
import ShootingSuspectIcon from "../../../assets/Images/ShootingRed.png";
import ShootingVictimIcon from "../../../assets/Images/ShootingGreen.png";
import NibinIcon from "../../../assets/Images/nibin1.png";
import NJGunTraceIcon from "../../../assets/Images/NjGunTrace1.png";
import PrisonParoleInactiveIcon from "../../../assets/Images/parole_Inactive.png";
import PrisonParoleActiveIcon from "../../../assets/Images/parole1.png";
import ViolentIcon from "../../../assets/Images/Violent1.png";
import HumanTrafficIcon from "../../../assets/Images/HumanTraffic1.png";
import ProfesionIcon from "../../../assets/Images/profession1.png";
import ReferralIcon from "../../../assets/Images/Referral1.png";
import ReferralRearrestedIcon from "../../../assets/Images/ReferralRearrested1.png";
import BTPersonReport from "../../shared/_BTPersonReport";
import {
    ColumnDirective,
    ColumnsDirective,
    ColumnMenu,
    GridComponent,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search, ExcelExportProperties,
    Resize, Reorder, SortSettingsModel, Column
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import {showCrumbScore, SiteName} from '../../../services/config.service';
import {RunPerson_Duplicate} from "../../../services/getDar.service";
import PersonIcon from "../../../assets/Images/Person.png";
import VehicleIcon from "../../../assets/Images/Vehicle.png";
import {OpenCodisPDF, OpenNJPOPPDF} from "../../../services/detail.service";
import BTPersonGridImage from "../../shared/_PersonGridImage";
import {GetStringNameForSite, SITE} from "../../../interfaces/config.interface";


interface SearchResultsProps {
    Persons: PersonInfo[]
    showPlate?: boolean
}

const SearchResults: React.FC<SearchResultsProps> = ({Persons, showPlate=false})=> {
    const [typedPersons, setTypedPersons] = React.useState<any[]>([])

    const handleDataTyping = () =>
    {
        if(Persons) {
            if (Persons) {
                let tempP: any = [...Persons]

                for (let p of tempP) {
                    if (p.DOB) {
                        p.DOB = new Date(p.DOB)
                    }
                    if (p.DTOFARREST) {
                        p.DTOFARREST = new Date(p.DTOFARREST)
                    }
                    if (p.DTOFUPDATE) {
                        p.DTOFUPDATE = new Date(p.DTOFUPDATE)
                    }
                }
                setTypedPersons(tempP)
            }
        }

    }

    React.useEffect(() => {
        handleDataTyping()
    },[Persons])

    let grid: Grid | null;
    let gridD: Grid | null;

    const miniPersonImage = (person: any): any => {
        return (
            <BTPersonGridImage person={person} />
        )
    }

    const getPersonSummary = (p:any) =>
    {
        return (<div><BTPersonReport person={p}/></div>)
    }

    const showDuplicateView = (item: any): any => {
        return (
            //''01_ARREST'' 		TableType
            //''03_NJPOP'' 		    TableType
            //''04_CODIS'' 		    TableType
            //''05_CADRMS'' 		TableType
            //''05_NJTRACE'' 		TableType might need to add in future
            <span>
                {item?.TableType.length > 0 ?
                    item?.TableType?.toUpperCase() == '01_ARREST' ? <Button onClick={() => {window.open('/detail/arrest?ID=' + item?.IncidentID + '&Dept=' + item?.ORIGINATINGORINAME, '_blank') }} >View</Button>
                :
                    item?.TableType?.toUpperCase() == '03_NJPOP' ? <Button onClick={() => { OpenNJPOPPDF(item.IncidentID) }} >View</Button>
                :
                    item?.TableType?.toUpperCase() == '04_CODIS' ? <Button onClick={() => { OpenCodisPDF(item.IncidentID, 'ALL') }} >View</Button> 
                :
                    item?.TableType?.toUpperCase() == '05_CADRMS' ? <Button onClick={() => {console.log(item); window.open('/detail/cadrms?ID=' + item.IncidentID + '&Dept=' + item?.ORIGINATINGORINAME, '_blank') }} >View</Button>
                :
                    <></>
                :
                    <span className = "font-bold">No View Found</span>
                }
            </span>);
    }
    
    const showDuplicatePeople = (p:any) => 
    {
        let duplicates:any[] = []
        
        RunPerson_Duplicate(p).then( (response)=> {
                if(response && response.AnyData) 
                {
                    duplicates = response?.AnyData
                    for(var d of duplicates)
                    {
                        if (d?.DOB) {
                            d.DOB = new Date(d.DOB)
                        }
                        if (d?.DateTimeIN) {
                            d.DateTimeIN = new Date(d.DateTimeIN)
                        }
                    }
                }
                gridD?.setProperties({dataSource:duplicates})
            }
        )
        return (
            <div className={"pl-10 pr-10 mt-2 mb-5"}>
                    <GridComponent
                        dataSource={duplicates}
                        //dataBound={dataBound}
                        allowPaging={true}
                        pageSettings={{pageSize: 25, pageSizes: [10,25,50,100]}}
                        allowSorting={true}
                        sortSettings={sortSettings}
                        allowMultiSorting={true}
                        allowFiltering={true}
                        filterSettings={{type: 'CheckBox'}}
                        detailTemplate={getPersonSummary}
                        allowTextWrap={true}
                        textWrapSettings={{ wrapMode: 'Both' }}
                        allowExcelExport={true}
                        allowPdfExport={true}
                        toolbar={['ExcelExport','Search']}
                        toolbarClick={GridToolsClick}
                        excelExportComplete={excelExportComplete}
                        ref={g=> gridD = g}
                        selectionSettings={{
                            persistSelection: true,
                            type: "Multiple",
                            //checkboxOnly: true,
                            mode: "Row"}}
                        rowSelecting = {(event:any) => {console.log()}}
                        rowSelected = {(event:any) => {expandRow()}}
                        allowSelection={true}
                        allowResizing={true}
                        rowHeight={24}
                    >
                        <ColumnsDirective>
                            <ColumnDirective field='FName' headerText='First Name' customAttributes={{ class: ['e-attr']}}/>
                            <ColumnDirective field='MName' headerText='MI' customAttributes={{ class: ['e-attr'] }}/>
                            <ColumnDirective field='LName' headerText='Last Name' customAttributes={{ class: ['e-attr'] }}/>
                            <ColumnDirective field='DOB'   format={{ type: 'date', format: 'MM/dd/yyyy' }} headerText='DOB' customAttributes={{ class: ['e-attr'] }} />
                            <ColumnDirective field='FBI'   headerText='FBI' customAttributes={{ class: ['e-attr'] }} />
                            <ColumnDirective field='SBINo' headerText={`${GetStringNameForSite(SiteName,'SBI #')}`} customAttributes={{ class: ['e-attr'] }} />
                            <ColumnDirective field='DLNo' headerText='DL #' customAttributes={{ class: ['e-attr'] }} />
                            <ColumnDirective field='SSN1'  headerText='SSN' customAttributes={{ class: ['e-attr'] }}/>
                            <ColumnDirective field='TableType'  headerText='Record Type' customAttributes={{ class: ['e-attr'] }}/>
                            <ColumnDirective field='DateTimeIN'   format={{ type: 'date', format: 'MM/dd/yyyy' }} headerText="Incident Date" customAttributes={{ class: ['e-attr'] }} />
                            <ColumnDirective template={showDuplicateView}  headerText='View' />
                        </ColumnsDirective>
                        <Inject services={[ColumnMenu, Sort, Page, Filter, DetailRow, Toolbar, ExcelExport,PdfExport,Search, Resize, Reorder]}/>
                    </GridComponent>
            </div>
        )
    }
    
    const GridToolsClick = (args: ClickEventArgs) => {
        
        if (grid && args.item.text === "Excel Export") {

            let excelProp: ExcelExportProperties = {}
            excelProp.fileName = 'Duplicate_Person.xlsx';

            (grid.getColumnByField("image") as Column).visible = false;

            grid.excelExport(excelProp);

        } else if (grid && args.item.text === 'PDF Export') {
            grid.pdfExport();
        }
    }

    const excelExportComplete = () => {
        if (grid) {
            (grid.getColumnByField("image") as Column).visible = true;
        }
    }

    const excelQueryCellInfo = (args: { column: { customExcelTemplate: (arg0: any) => any; }; value: string; data: any; }) => {
        /*if (has(args.column, "customExcelTemplate")) {
            args.value = toString(args.column.customExcelTemplate(args.data));
        }*/
    };

    const dataBound = () => {
        if(grid){
            grid.autoFitColumns([])
        }
    }

    const expandRow = () => {
        if(grid)
        {
            const selectedrowindex: number[] = grid.getSelectedRowIndexes()
            for (let idx of selectedrowindex) {

                grid.detailRowModule.expand(idx)
            }
        }
    }

    let sortSettings: SortSettingsModel = { columns: [
            {field: 'FName', direction: 'Ascending' }
        ] };

    return (
        <div className={"pl-10 pr-10 mt-5"}>
            {typedPersons && typedPersons.length>0? <>
                <h4 className={"font-bold pl-5"}> {typedPersons?.length} Records Found</h4>
                <GridComponent
                    dataSource={typedPersons}
                    //dataBound={dataBound}
                    allowPaging={true}
                    pageSettings={{pageSize: 25, pageSizes: [10,25,50,100]}}
                    allowSorting={true}
                    sortSettings={sortSettings}
                    allowMultiSorting={true}
                    allowFiltering={true}
                    filterSettings={{type: 'CheckBox'}}
                    detailTemplate={showDuplicatePeople}
                    allowTextWrap={true}
                    textWrapSettings={{ wrapMode: 'Both' }}
                    allowExcelExport={true}
                    allowPdfExport={true}
                    toolbar={['ExcelExport','Search']}
                    toolbarClick={GridToolsClick}
                    excelExportComplete={excelExportComplete}
                    ref={g=> grid = g}
                    selectionSettings={{
                        persistSelection: true,
                        type: "Multiple",
                        //checkboxOnly: true,
                        mode: "Row"}}
                    rowSelecting = {(event:any) => {console.log()}}
                    rowSelected = {(event:any) => {expandRow()}}
                    allowSelection={true}
                    allowResizing={true}
                    rowHeight={24}
                >
                    <ColumnsDirective>
                        <ColumnDirective field='image' template={miniPersonImage} maxWidth={100} headerText='Image' customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='FName' headerText='First Name' customAttributes={{ class: ['e-attr']}}/>
                        <ColumnDirective field='MName' headerText='MI' customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='LName' headerText='Last Name' customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='DOB'   format={{ type: 'date', format: 'MM/dd/yyyy' }} headerText='DOB' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='FBI'   headerText='FBI' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='SBINo' headerText={`${GetStringNameForSite(SiteName,'SBI #')}`} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='DLNo' headerText='DL #' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='SSN1'  headerText='SSN' customAttributes={{ class: ['e-attr'] }} visible={false}/>
                        
                    </ColumnsDirective>
                    <Inject services={[ColumnMenu, Sort, Page, Filter, DetailRow, Toolbar, ExcelExport,PdfExport,Search, Resize, Reorder]}/>
                </GridComponent>

            </>:<></>}
        </div>
    );
};

export default SearchResults;