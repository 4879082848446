import { Alert, AlertColor, Autocomplete, Button, ButtonGroup, Card, CardContent, CardHeader, FormControl, IconButton, InputLabel, Select, Snackbar, TextField, Tooltip } from "@mui/material";
import { ColumnDirective, ColumnMenu, ColumnsDirective, DetailRow, ExcelExport, ExcelExportProperties, Filter, Grid, GridComponent, Inject, Page, PdfExport, Reorder, Resize, Search, Sort, Toolbar } from "@syncfusion/ej2-react-grids";
import React from "react";
import { getCounty } from "../../services/getDropdown.service";
import { GVRTFEmailUserInfo, GVRTFGroupInfo } from "../../interfaces/GVRTFEmailList.interface";
import { RunGetGRAILReferralEmail, RunGetGRAILReferralGroup, RunSetGRAILReferralEmail, RunSetGRAILReferralGroup } from "../../services/getDar.service";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import GVRTFEmailUsers from "./_resultsGVRTFEmailUsers";
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import {GetDefaultStateForSite, GetStringNameForSite} from "../../interfaces/config.interface";
import {SiteName} from "../../services/config.service";


interface DetailProps {
    Persons: any[]
    editUser : any

}

const GVRTFManageEmail: React.FC<DetailProps> = ({editUser}) => {
    const [showUserForm, setShowUserForm] = React.useState<boolean>(false)
    const [showGroupForm, setShowGroupForm] = React.useState<boolean>(false)
    const [emailData, setEmailData] = React.useState<GVRTFEmailUserInfo>({})
    const [groupData, setGroupData] = React.useState<GVRTFGroupInfo>({})
    const [groupList, setGroupList] = React.useState<any>([])
    const [emailList, setEmailList] = React.useState<any>([])
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false)
    const [alertText, setAlertText] = React.useState<String>('')
    const [alertType, setAlertType] = React.useState<AlertColor>("error")


    let grid: Grid | null;

    React.useEffect(() => {   
        handleInitialBuild()
    }, [editUser])

    const handleInitialBuild = async () => {    
       await handleData()
    }

    const handleData = async ()  => {
        setGroupList([])
        setEmailList([])
        var group = await RunGetGRAILReferralGroup(groupData)
        var email = await RunGetGRAILReferralEmail(emailData)
        if (group && group.length > 0){
            setGroupList(group)
        }

        if(email && email.length > 0){
            setEmailList(email)
        }        
    }

    const handleAlert = (type: string) =>{
        if(type === 'saveGroup'){
            setAlertText('Group Saved!')
            setAlertOpen(true)
            setAlertType("success")
        }
        else if(type === 'saveEmail'){
            setAlertText('Email User Saved!')
            setAlertOpen(true)
            setAlertType("success")

        }
        else if (type === 'groupDeleted'){
            setAlertText('Group has been deleted!')
            setAlertOpen(true)
            setAlertType("success")
        }
    }

    const AddEmail = async () => {
        setEmailData({})
        setShowGroupForm(false)
        setShowUserForm(true)
        await handleDropDown()        
    }

    const AddGroup =() => {
        setGroupData({})
        setShowUserForm(false)
        setShowGroupForm(true)
    }

    const handleDropDown = async () => {
        //let temp = {...emailData}
        let temp : GVRTFEmailUserInfo = {}
        temp.CountyList = await getCounty(GetDefaultStateForSite(SiteName), true)
        if(groupList?.length > 0) {
            temp.GroupName = groupList[0].GroupName ?? ''
            temp.GroupID = groupList[0].ID ?? ''
        }
        setEmailData(temp)

    }

    const handleValidation = (form:string) => {
        let res = true
        let notDup = true
        //validate email user form
        if(form === 'saveEmail'){
            if(!emailData.County || emailData.County.length === 0){
                res = false
            }
            if(!emailData.Email || emailData.Email.length === 0){
                res = false
            }
            if(!emailData.GroupName || emailData.GroupName.length === 0){
                res = false
            }
        }
        //validate group form
        else if (form === 'saveGroup'){
            if(!groupData.GroupName || groupData.GroupName.length === 0){
                res = false
            }
            if(groupData.GroupName && groupList?.length > 0){
                let dupName = groupList?.filter((item:any)=> item.GroupName.trim() === groupData.GroupName?.trim() && (!groupData.ID || item.ID != groupData.ID )  && item.deleted === false ) 
                //check if a group already exist with the same name when adding
                if(dupName.length > 0){
                    notDup = false
                    res = false
                }
            }
        }
        //Alerts
        if(!res && !notDup){
            setAlertText('This group name already exists.')
            setAlertOpen(true)
            setAlertType("error")
            
        }
        else if(!res){
            setAlertText('Please complete all required fields.')
            setAlertOpen(true)
            setAlertType("error")
        }
        return res
    }

    const handleSave = async (form : string) => {
        if(form === 'saveGroup'){
            if(handleValidation(form)){
                let res = await RunSetGRAILReferralGroup(groupData)
                if(res.isValid){
                    setShowGroupForm(false)
                    handleData()
                    handleAlert(form)
                }
            }
        }
        else if (form === 'saveEmail'){
            if(handleValidation(form)){
                //save
                let res = await RunSetGRAILReferralEmail(emailData)
                if(res.isValid){
                    setShowUserForm(false)
                    let tempList = [...emailList]
                    if(res && res?.ID && res?.ID !== 0){
                        let index:number = tempList.findIndex((e:any) => e.ID === res.ID)
                        if(index >= 0){
                            tempList[index] = res
                        }
                        else{
                            tempList.push(res)
                        }
                        setEmailList(tempList)
                        handleAlert(form)
                    }
                }
            }
        }
    }

    const handleCancel = () => {
        setShowUserForm(false)
        setShowGroupForm(false)
        handleData()
    }

    const handleEditGroup = (group : any) =>{
        setShowUserForm(false)
        setShowGroupForm(true)
        let temp: GVRTFGroupInfo = {
            ID : group.ID,
            GroupName: group.GroupName,
            deleted: group.deleted
        }
        setGroupData(temp)
    }

    const handleEditEmailUser = async (user:any) => {
        let temp: GVRTFEmailUserInfo = {
            ID: user.ID,
            FName: user.FName,
            LName: user.LName,
            Dept: user.Dept,
            County: user.County,
            Email: user.Email,
            GroupName: user?.GroupName,
            GroupID: user.GroupID,
            CountyList: await getCounty(GetDefaultStateForSite(SiteName), true),
            deleted: user.deleted,
        }
        setEmailData(temp)
        setShowUserForm(true)

    }

    const handleDeleteGroup = async (group:any) => {
        if(group){
            if (window.confirm("Do you want to remove this group permanently? All email users in this group will also be deleted.")){
                let temp : GVRTFGroupInfo = {
                    ID: group.ID,
                    GroupName: group?.GroupName,
                    deleted: true
                }
                let res = await RunSetGRAILReferralGroup(temp)
                if(res.isValid){
                    setShowGroupForm(false)
                    if(grid){
                        //@ts-ignore
                        let tempList = [...grid?.dataSource]
                        if(res && res?.ID && res?.ID !== 0){
                            let index:number = tempList.findIndex((e:any) => e.ID === res.ID)
                            if(index >= 0){
                                tempList.splice(index,1)
                            }
                            grid.dataSource = tempList ?? []  
                            grid?.refresh()
                            setGroupList(tempList)   
                        }
                    }
                    handleAlert('groupDeleted')
                }                
                    
            }     
        }    
    }

    const handleDeleteEmailUser = async (user: any) =>{      
        handleAlert('emailDeleted')    
    }

    const handleGroupName = (groupName : any) =>{
        let tempP= {...emailData}
        let temp = groupList?.filter((e:any)=>{return(e?.GroupName === groupName)})       
        if (temp.length > 0){
            tempP.GroupID = temp[0].ID
            tempP.GroupName = temp[0].GroupName
        }
        setEmailData(tempP)

    }
  
    const expandRow = () => {
        if(grid)
        {
            const selectedrowindex: number[] = grid.getSelectedRowIndexes()
            for (let idx of selectedrowindex) {

                grid.detailRowModule.expand(idx)
            }
        }
    }


    const gridTemplate= (props:any) =>
    {
        let userData = emailList.filter((item:any)=> item.GroupID === props.ID.toString())
        return(
            <div><GVRTFEmailUsers Users={userData} HandleEditEmailUser = {handleEditEmailUser} HandleDeleteEmailUser = {handleDeleteEmailUser}/></div>
        )
    }

    const groupActions = (group: any)=>{
        return(
            <span>
                
                <Tooltip title="Edit" placement="top" arrow>
                    <IconButton onClick={() => handleEditGroup(group)} ><EditIcon /></IconButton>
                </Tooltip>
                <Tooltip title="Delete" placement="top" arrow>
                    <IconButton onClick={()=> handleDeleteGroup(group)} ><DeleteIcon /></IconButton>
                </Tooltip>
            </span>
        )
    }

    const GridToolsClick = (args: ClickEventArgs) => {
        if (grid && args.item.text === "Excel Export") {
            let excelProp:ExcelExportProperties = {}
            excelProp.fileName = 'GVRTFGroups.xlsx'
            grid.excelExport(excelProp);
        }else if (grid && args.item.text === 'PDF Export') {
            grid.pdfExport();
        }
    }

    return(
        <div className="ml-5 p-5">
            <div>
                {!showUserForm && !showGroupForm ?
                    <div className={"space-x-5 mb-5"}>
                        <Button variant="outlined" size={"small"} type="submit" color="inherit" onClick={AddEmail}>Add Email User</Button>
                        <Button  variant="outlined" size={"small"} type="submit" color="inherit" onClick={AddGroup}>Add Group</Button>
                    </div>
                    :
                    <div></div>
                }                             
                <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={alertOpen} autoHideDuration={2000} onClose={() => { setAlertOpen(false) }}>
                    <Alert onClose={() => { setAlertOpen(false) }} severity={alertType} sx={{ width: '100%' }}>
                        {alertText}
                    </Alert>
                </Snackbar>

                {showUserForm 
                ? 
                <div>
                    <Card>            
                        <CardContent>
                            <span className = "text-blue-400 font-bold text-xl">
                                {!emailData?.ID || emailData.ID === 0 ? <span>Add Email User</span> :<span>Edit Email User</span>}
                            </span>                                             
                            <div className="grid grid-cols-6 mt-5 gap-y-5">                           
                                <div className="w-full pr-5">
                                    <TextField 
                                        margin='none' 
                                        size={"small"} 
                                        label={"First Name"}
                                        className="w-full"
                                        value={emailData.FName ? emailData.FName : ''}
                                        onChange={e => setEmailData({...emailData, FName: e.target.value})}  
                                    />
                                </div>
                                <div className="w-full pr-5">
                                    <TextField
                                        margin='none' 
                                        size={"small"} 
                                        label="Last Name"
                                        className="w-full"
                                        value={emailData.LName ?? ''}
                                        onChange={e => setEmailData({...emailData, LName: e.target.value})}  
                                    />
                                </div>
                                <div className="w-full pr-5">
                                    <TextField 
                                        margin='none' 
                                        size={"small"} 
                                        label="Agency"
                                        className="w-full"
                                        value={emailData.Dept ? emailData.Dept : ''}  
                                        onChange={e => setEmailData({...emailData, Dept: e.target.value})}
                                    />
                                </div>
                                <div className="w-full pr-5">
                                    <Autocomplete
                                        freeSolo
                                        disableClearable
                                        id="CountyID"
                                        options={emailData.CountyList ? emailData.CountyList?.map((option: any) => option.Key) : []}
                                        size="small"
                                        className="w-full"
                                        renderInput={(params)=> 
                                            <TextField 
                                                {...params} 
                                                label = {GetStringNameForSite(SiteName,"County")}
                                                error={!emailData?.County || emailData?.County == undefined ? true : false}
                                                helperText={!emailData?.County || emailData?.County == undefined ? 'Required' : ''}
                                            />}
                                        onInputChange={(event,value)=> setEmailData({...emailData, County: value})}
                                        value={emailData.County ? emailData.County : ''}
                                    />
                                </div>
                                <div className="w-full pr-5">
                                    <TextField 
                                        margin='none' 
                                        size={"small"} 
                                        label={"Email Address"}
                                        className="w-full"
                                        value={emailData.Email}
                                        onChange={e => setEmailData({...emailData, Email: e.target.value})} 
                                        error={!emailData.Email || emailData?.Email == undefined ? true : false}
                                        helperText={!emailData?.Email || emailData?.Email == undefined ? 'Required' : ''} 
                                    />
                                </div>
                                <div className="w-full pr-5">
                                    <Autocomplete                              
                                    disableClearable
                                    id="GroupID"
                                    options={groupList ? groupList?.map((option: any) => option.GroupName) : []}
                                    size="small"
                                    className="w-full"
                                    renderInput={(params)=>
                                         <TextField 
                                            {...params} 
                                            label = "Group"
                                            error={!emailData?.GroupName || emailData?.GroupName == undefined ? true : false}
                                            helperText={!emailData?.GroupName || emailData?.GroupName == undefined ? 'Required' : ''}                                        
                                         />}
                                    value={emailData?.GroupName ?? groupList[0]?.GroupName}
                                    onChange={(e,value) => handleGroupName(value)}
                                    />
                                </div>                                                        
                            </div>
                            <div className="mt-2" style={{"display":"flex", "justifyContent":"end"}}>
                                    <ButtonGroup variant="outlined" size={"large"} color={"inherit"} >
                                        <Button onClick={()=>{handleSave('saveEmail')}}>SAVE</Button>
                                        <Button onClick = {handleCancel}>CANCEL</Button>
                                    </ButtonGroup>
                            </div>                                                                             
                        </CardContent>                       
                    </Card>                   
                </div>                
                :                
                <div>
                    {showGroupForm ? <>
                    <div>
                        <Card>
                            <CardContent>
                            <span className = "text-blue-400 font-bold text-xl">
                                {!groupData?.ID || groupData.ID === 0 ? <span>Add Group</span> :<span>Edit Group</span>}
                            </span>
                            <div className="grid grid-cols-6 mt-5 gap-y-5">
                                <div className="w-full pr-5">
                                    <TextField 
                                        margin='none' 
                                        size={"small"} 
                                        label={"Group Name"}
                                        className="w-full"
                                        onChange={e => setGroupData({...groupData, GroupName: e.target.value })}
                                        error={!groupData.GroupName || groupData.GroupName  == undefined ? true : false}
                                        helperText={!groupData.GroupName  || groupData.GroupName  == undefined ? 'Required' : ''}  
                                        value={groupData?.GroupName}
                                    />
                                </div>                                                          
                            </div>
                             <div className="mt-2" style={{"display":"flex", "justifyContent":"end"}}>
                                <ButtonGroup variant="outlined" size={"large"} color={"inherit"} >
                                    <Button onClick={()=>{handleSave('saveGroup')}}>SAVE</Button>
                                    <Button onClick = {handleCancel}>CANCEL</Button>
                                </ButtonGroup>
                            </div> 
                            </CardContent>
                        </Card>
                    </div>
                </>:
                <div>
                    {groupList && groupList.length > 0 ? <>
                    <h4 className={"font-bold pl-5"}> {groupList?.length} Records Found</h4>
                    <GridComponent
                        dataSource={groupList}
                        //detailDataBound={detailDataBound}
                        //dataBound={dataBound}
                        allowPaging={true}
                        pageSettings={{pageSize: 25, pageSizes: [10,25,50,100]}}
                        allowSorting={true}
                        allowMultiSorting={true}
                        allowFiltering={true}
                        filterSettings={{type: 'CheckBox'}}
                        detailTemplate={gridTemplate}
                        allowTextWrap={true}
                        textWrapSettings={{ wrapMode: 'Both' }}
                        allowExcelExport={true}
                        allowPdfExport={true}
                        toolbar={[
                            'ExcelExport',
                            'Search'
                        ]}
                        toolbarClick={GridToolsClick}
                        ref={(g) => { if (g) { grid = g } }}
                        selectionSettings={{
                            persistSelection: true,
                            type: "Multiple",
                            checkboxOnly: true,
                            mode: "Row"}}
                        allowSelection={true}
                        allowResizing={true}
                        rowHeight={24}
                        rowSelected = {(event:any) => {expandRow()}}
                        rowDeselected = {(event:any) => {}}
                    >
                        <ColumnsDirective>
                            <ColumnDirective field='ID'  headerText='ID' visible={false} isPrimaryKey={true} />
                            <ColumnDirective field='GroupName' headerText='Group Name' customAttributes={{ class: ['e-attr']}}/>
                            <ColumnDirective template={groupActions} width={110} minWidth={110} maxWidth={110} headerText='Group Actions' customAttributes={{ class: ['e-attr'] }}/>
                        </ColumnsDirective>
                        <Inject services={[ DetailRow,ColumnMenu, Sort, Page, Filter, DetailRow, Toolbar, ExcelExport,PdfExport,Search, Resize, Reorder]}/>
                    </GridComponent>

                </>:
                <></>}
                </div>}
                </div> }
            </div>

        </div>
    );
}

export default GVRTFManageEmail;