import {PersonInfo} from "../../../interfaces/getDarInfo_interface";
import React from "react";
import { Disclosure } from '@headlessui/react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AgencyInteractionInfo from "../../../interfaces/AgencyInteractionInfo.interface";
import { TablePagination, CircularProgress, Button } from "@mui/material";
import InfoCopIcon from "../../../assets/Images/ic1.png";
import { Link } from "react-router-dom";
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent ,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search
} from '@syncfusion/ej2-react-grids';

interface PanelINFOCOPProps {
    person: PersonInfo,
    AgencyInteractionInfos: AgencyInteractionInfo[],
    isLoading: boolean
}

const  PanelINFOCOP: React.FC<PanelINFOCOPProps> = ({person,AgencyInteractionInfos, isLoading})=> {
    const [FormatData, setFormatData] = React.useState(0);

    const handleDataTyping = () => {
        let tempP: any = [...AgencyInteractionInfos]
        for (let p of tempP) {
            if (p.DateTimeIN) {
                p.DateTimeIN = new Date(p.DateTimeIN)
            }
        }
        setFormatData(tempP)
    }

    React.useEffect(() => {
        handleDataTyping()
    }, [AgencyInteractionInfos])

    return(
        <div className={"m-5 border"}>
            <Disclosure defaultOpen={false}>
                {({ open }) => (
                    <>
                        <Disclosure.Button className="bg-blue-100 p-2 w-auto w-full text-left">
                            <span style={{ "display": "flex", "justifyContent": "space-between" }}>
                                <span>
                                    <KeyboardArrowDownIcon
                                        className={`${open ? "transform rotate-180" : ""}`}
                                    />
                                    <span className={"font-bold"}>INFO-COP Interaction <img src={InfoCopIcon} alt={''} style={{ display: 'inline' }} /> {(isLoading) ? <CircularProgress size={20} style={{"marginLeft" : "5px"}} /> : <></>}</span>
                                </span>
                                <span className={"font-bold mr-2"} >Name: {person?.FName?.toUpperCase()} {person?.MName?.toUpperCase()} {person?.LName?.toUpperCase()} &nbsp;&nbsp;Records: {AgencyInteractionInfos?.length}</span>
                            </span>
                            
                        </Disclosure.Button>

                        <Disclosure.Panel className="text-gray-500 p-4">
                            <GridComponent
                                dataSource={FormatData}
                                allowPaging={true}
                                pageSettings={{pageSize: 50, pageSizes: [10,25,50,100]}}
                                allowSorting={true}
                                allowMultiSorting={true}
                                allowFiltering={true}
                                filterSettings={{type: 'CheckBox'}}
                                allowTextWrap={true}
                                textWrapSettings={{ wrapMode: 'Both' }}
                                rowHeight={24}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective field='DateTimeIN' headerText='Date of Inquiry' format={{ type: 'dateTime', format: 'MM/dd/yyyy HH:mm' }}/>
                                    <ColumnDirective field='DeptName' headerText='Infocop Dept'/>
                                    <ColumnDirective field='PlateSt' headerText='Plate St'/>
                                    <ColumnDirective field='PlateNumber' headerText='Plate #'/>
                                    <ColumnDirective field='DLNo' headerText='DL #'/>
                                    {/*<ColumnDirective template={(item: any) => (<Link to={'/detail/Infocop?ID=' + item?.ReqID + '&Dept=' + item?.Dept} target="_blank">{item?.ReqID}</Link>)} headerText='InfoCop ID' />*/}
                                    <ColumnDirective template={(item: any) => (<Button onClick={() => { window.open('/detail/Infocop?ID=' + item?.ReqID + '&Dept=' + item?.Dept, '_blank') }} >View</Button>)} headerText='View' />
                                </ColumnsDirective>
                                <Inject services={[Sort, Page, Filter]}/>
                            </GridComponent>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>

    )
};

export default PanelINFOCOP;