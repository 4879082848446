import React from 'react'
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent,
    Grid,
    Inject,
    Page,
    PageSettingsModel,
    Sort,
    Filter,
    FilterSettingsModel,
    DetailRow,
    ExcelExport, ExcelExportProperties, Toolbar, ToolbarItems, ColumnMenu, PdfExport, Search, Resize, Reorder
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import SearchResultGraph from '../ArrestAnalysis/_searchResultsArrestAnalysisGraph';
interface SearchResultsProps {
    AnalysisData: any[]
    AnalysisColumn: any[] | null
    AnalysisGraph: any | null
}

const SearchResults: React.FC<SearchResultsProps> = ({ AnalysisData, AnalysisColumn, AnalysisGraph }) => {
    //For Pagination
    const [page, setPage] = React.useState(0);
    
    React.useEffect(() => {
        setPage(0)//reset to page 1 on new search
    }, [AnalysisData, AnalysisColumn]);

    var pageOptions: PageSettingsModel = {
        pageSize: 25, pageSizes: [10, 25, 50, 100]
    };
    let grid: Grid | null;
    const GridToolsClick = (args: ClickEventArgs) => {
        if (grid && args.item.text === "Excel Export") {
            let excelProp:ExcelExportProperties = {}
            excelProp.fileName = 'ArrestAnalysis.xlsx'
            grid.excelExport(excelProp);

        } else if (grid && args.item.text === 'PDF Export') {
            grid.pdfExport();
        }
    }
    const dataBound = () => {
        if (grid) {
            grid.autoFitColumns([])
        }
    }

    return (
        <div className={"pl-10 pr-10 mt-5"}>            
            {AnalysisData && AnalysisData?.length !== 0 ?
                <>
                    <div>
                        <SearchResultGraph AnalysisGraph={AnalysisGraph} />
                    </div>
                    <GridComponent
                    id='grid'
                    dataSource={AnalysisData}
                    allowPaging={true}
                    pageSettings={pageOptions}
                    allowSorting={true}
                    allowMultiSorting={true}
                    allowFiltering={true}
                    filterSettings={{ type: 'Menu' }}
                    dataBound={dataBound}
                    allowTextWrap={true}
                    textWrapSettings={{ wrapMode: 'Both' }}
                    allowExcelExport={true}
                    allowPdfExport={true}
                    toolbar={['ExcelExport','Search']}
                    toolbarClick={GridToolsClick}
                    ref={g=> grid = g}
                    selectionSettings={{
                        persistSelection: true,
                        type: "Multiple",
                        //checkboxOnly: true,
                        mode: "Row"}}
                    allowSelection={true}
                    allowResizing={true}
                    rowHeight={30}
                    >
                        <ColumnsDirective>
                            {AnalysisColumn?.map((row: any) => {
                                return (<ColumnDirective field={Object.keys(row)[0]} headerText={Object.keys(row)[0]} minWidth={120} customAttributes={{ class: ['e-attr'] }} textAlign={"Center"} />)
                            })
                            }
                        </ColumnsDirective>
                        <Inject services={[ColumnMenu, Sort, Page, Filter, DetailRow, Toolbar, ExcelExport, PdfExport, Search, Resize, Reorder]} />
                        </GridComponent>
                </>
                    :<></>}
        </div>
    )
};

export default SearchResults