import React, { useState, useEffect } from "react";
import {
    Box, Tab,
    Tooltip
} from "@mui/material";

import {
    ColumnDirective,
    ColumnsDirective,
    ColumnMenu,
    GridComponent,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search, ExcelExportProperties,
    Resize, Reorder, SortSettingsModel, Column
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import {TabContext, TabList, TabPanel} from "@mui/lab";
import StorageIcon from '@mui/icons-material/Storage';
import PersonIcon from '@mui/icons-material/Person';
import NJTraceInputForm from "../tools/njtrace/_njtraceInputForm";

interface AuditLogSearchResultsProps {
    UserAuditLogResults: any[],
    SystemAuditLogResults: any[]
}

const AuditLogSearchResults: React.FC<AuditLogSearchResultsProps> = ({ UserAuditLogResults, SystemAuditLogResults }) => {
    const [typedPersons, setTypedAuditLogResults] = useState<any[]>([])
    const [typedSystem, setTypedSystemAuditLogResults] = useState<any[]>([])
    const [tabValue, setTabValue] = React.useState('1');

    const handleDataTyping = () => {
        if (UserAuditLogResults) {
            if (UserAuditLogResults) {
                let tempP: any = [...UserAuditLogResults]

                for (let p of tempP) {
                    if (p.LOG_DATETIME) {
                        p.LOG_DATETIME = new Date(p.LOG_DATETIME)
                    }
                }
                setTypedAuditLogResults(tempP)
            }
        }
        if (SystemAuditLogResults) {
            if (SystemAuditLogResults) {
                let tempP: any = [...SystemAuditLogResults]

                for (let p of tempP) {
                    if (p.LOG_DATETIME) {
                        p.LOG_DATETIME = new Date(p.LOG_DATETIME)
                    }
                }
                setTypedSystemAuditLogResults(tempP)
            }
        }
    }

    const handleTabChange = (event: React.SyntheticEvent , newValue: string) => {
        setTabValue(newValue);
    };

    useEffect(() => { handleDataTyping() }, [SystemAuditLogResults])
    let grid: Grid | null;
    let gridS: Grid | null;
    const GridToolsClick = (args: ClickEventArgs) => {
        
        if (grid && args.item.text === "Excel Export") {
            let excelProp: ExcelExportProperties = {}
            excelProp.fileName = 'BackTraceAuditLog.xlsx';

            grid.excelExport(excelProp);
        }
    }
    const excelExportComplete = () => {
    }

    const SystemGridToolsClick = (args: ClickEventArgs) => {

        if (gridS && args.item.text === "Excel Export") {
            let excelProp: ExcelExportProperties = {}
            excelProp.fileName = 'BackTraceSystemAuditLog.xlsx';

            gridS.excelExport(excelProp);
        }
    }
    const SystemExcelExportComplete = () => {
    }


    let sortSettings: SortSettingsModel = { columns: [{ field: 'LOG_DATETIME', direction: 'Descending' }] };

    return (
        <div>
            <div className={"pl-10 pr-10 mt-3"}>
                {typedPersons && typedPersons.length > 0 ? <>
                    <h4 className={"font-bold pl-5"}> {typedPersons?.length} Records Found</h4>
                    <GridComponent
                        dataSource={typedPersons}
                        allowPaging={true}
                        pageSettings={{pageSize: 25, pageSizes: [10, 25, 50, 100]}}
                        allowSorting={true}
                        sortSettings={sortSettings}
                        allowMultiSorting={true}
                        allowFiltering={true}
                        filterSettings={{type: 'CheckBox'}}
                        allowTextWrap={true}
                        textWrapSettings={{wrapMode: 'Both'}}
                        allowExcelExport={true}
                        allowPdfExport={true}
                        toolbar={['ExcelExport', 'Search']}
                        toolbarClick={GridToolsClick}
                        excelExportComplete={excelExportComplete}
                        ref={g => grid = g}
                        selectionSettings={{
                            persistSelection: true,
                            type: "Multiple",
                            mode: "Row"
                        }}
                        //rowSelecting={(event: any) => { console.log(event) }}
                        allowSelection={true}
                        rowHeight={24}
                    >
                        <ColumnsDirective>
                            <ColumnDirective field='ACTION' headerText='Action' width={200} minWidth={200}
                                             maxWidth={200} customAttributes={{class: ['e-attr']}}/>
                            <ColumnDirective field='MESSAGE' headerText='Message' maxWidth={1340}
                                             customAttributes={{class: ['e-attr']}}/>
                            <ColumnDirective field='LOG_DATETIME'
                                             format={{type: 'dateTime', format: 'MM/dd/yyyy HH:mm:ss'}}
                                             headerText='Date' width={200} minWidth={200} maxWidth={200}
                                             customAttributes={{class: ['e-attr']}}/>
                            <ColumnDirective field='UserID' headerText='Employee ID' width={200} minWidth={200}
                                             maxWidth={200} customAttributes={{class: ['e-attr']}}/>
                            <ColumnDirective field='DeptName' headerText='Department' width={200} minWidth={200}
                                             maxWidth={200} customAttributes={{class: ['e-attr']}}/>
                            <ColumnDirective field='DeptUnitName' headerText='Dept Unit' width={200} minWidth={200}
                                             maxWidth={200} customAttributes={{class: ['e-attr']}}/>
                            <ColumnDirective field='DeptCounty' headerText='Dept County' width={200} minWidth={200}
                                             maxWidth={200} customAttributes={{class: ['e-attr']}}/>
                            {/*<ColumnDirective field='AuthorizedBy' headerText='Supervisor' customAttributes={{ class: ['e-attr'] }} />*/}
                        </ColumnsDirective>
                        <Inject
                            services={[ColumnMenu, Sort, Page, Filter, DetailRow, Toolbar, ExcelExport, PdfExport, Search, Resize, Reorder]}/>
                    </GridComponent>
                </> : <></>}
            </div>
            {/*<Box className="pt-3" style={{justifyContent: 'center'}}>
                <TabContext value={tabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleTabChange} variant="fullWidth">
                            <Tab icon={<PersonIcon />} label="User Interaction Logs"  value={'1'}/>
                            <Tab icon={<StorageIcon />} label={"System Interaction Logs"} value={'2'} />
                        </TabList>
                    </Box>
                    <TabPanel value='1'>
                        <div className={"pl-10 pr-10"}>
                            {typedPersons && typedPersons.length > 0 ? <>
                                <h4 className={"font-bold pl-5"}> {typedPersons?.length} Records Found</h4>
                                <GridComponent
                                    dataSource={typedPersons}
                                    allowPaging={true}
                                    pageSettings={{pageSize: 25, pageSizes: [10, 25, 50, 100]}}
                                    allowSorting={true}
                                    sortSettings={sortSettings}
                                    allowMultiSorting={true}
                                    allowFiltering={true}
                                    filterSettings={{type: 'CheckBox'}}
                                    allowTextWrap={true}
                                    textWrapSettings={{wrapMode: 'Both'}}
                                    allowExcelExport={true}
                                    allowPdfExport={true}
                                    toolbar={['ExcelExport', 'Search']}
                                    toolbarClick={GridToolsClick}
                                    excelExportComplete={excelExportComplete}
                                    ref={g => grid = g}
                                    selectionSettings={{
                                        persistSelection: true,
                                        type: "Multiple",
                                        mode: "Row"
                                    }}
                                    //rowSelecting={(event: any) => { console.log(event) }}
                                    allowSelection={true}
                                    rowHeight={24}
                                >
                                    <ColumnsDirective>
                                        <ColumnDirective field='ACTION' headerText='Action' width={200} minWidth={200}
                                                         maxWidth={200} customAttributes={{class: ['e-attr']}}/>
                                        <ColumnDirective field='MESSAGE' headerText='Message' maxWidth={1340}
                                                         customAttributes={{class: ['e-attr']}}/>
                                        <ColumnDirective field='LOG_DATETIME'
                                                         format={{type: 'dateTime', format: 'MM/dd/yyyy HH:mm:ss'}}
                                                         headerText='Date' width={200} minWidth={200} maxWidth={200}
                                                         customAttributes={{class: ['e-attr']}}/>
                                        <ColumnDirective field='UserID' headerText='Employee ID' width={200}
                                                         minWidth={200} maxWidth={200}
                                                         customAttributes={{class: ['e-attr']}}/>
                                        <ColumnDirective field='DeptName' headerText='Department' width={200}
                                                         minWidth={200} maxWidth={200}
                                                         customAttributes={{class: ['e-attr']}}/>
                                        <ColumnDirective field='DeptUnitName' headerText='Dept Unit' width={200}
                                                         minWidth={200} maxWidth={200}
                                                         customAttributes={{class: ['e-attr']}}/>
                                        <ColumnDirective field='DeptCounty' headerText='Dept County' width={200}
                                                         minWidth={200} maxWidth={200}
                                                         customAttributes={{class: ['e-attr']}}/>
                                        <ColumnDirective field='AuthorizedBy' headerText='Supervisor' customAttributes={{ class: ['e-attr'] }} />
                                    </ColumnsDirective>
                                    <Inject
                                        services={[ColumnMenu, Sort, Page, Filter, DetailRow, Toolbar, ExcelExport, PdfExport, Search, Resize, Reorder]}/>
                                </GridComponent>
                            </> : <></>}
                        </div>
                    </TabPanel>
                    <TabPanel value='2'>
                        <div className={"pl-10 pr-10 mt-5"}>
                            {typedSystem && typedSystem.length > 0 ? <>
                                <h4 className={"font-bold pl-5"}> {typedSystem?.length} Records Found</h4>
                                <GridComponent
                                    dataSource={typedSystem}
                                    allowPaging={true}
                                    pageSettings={{pageSize: 25, pageSizes: [10, 25, 50, 100]}}
                                    allowSorting={true}
                                    sortSettings={sortSettings}
                                    allowMultiSorting={true}
                                    allowFiltering={true}
                                    filterSettings={{type: 'CheckBox'}}
                                    allowTextWrap={true}
                                    textWrapSettings={{wrapMode: 'Both'}}
                                    allowExcelExport={true}
                                    allowPdfExport={true}
                                    toolbar={['ExcelExport', 'Search']}
                                    toolbarClick={SystemGridToolsClick}
                                    excelExportComplete={SystemExcelExportComplete}
                                    ref={g => gridS = g}
                                    selectionSettings={{
                                        persistSelection: true,
                                        type: "Multiple",
                                        mode: "Row"
                                    }}
                                    //rowSelecting={(event: any) => { console.log(event) }}
                                    allowSelection={true}
                                    rowHeight={24}
                                >
                                    <ColumnsDirective>
                                        <ColumnDirective field='ACTION' headerText='Action' width={200} minWidth={200} maxWidth={200} customAttributes={{class: ['e-attr']}}/>
                                        <ColumnDirective field='MESSAGE' headerText='Message' maxWidth={1340} customAttributes={{class: ['e-attr']}}/>
                                        <ColumnDirective field='LOG_DATETIME' format={{type: 'dateTime', format: 'MM/dd/yyyy HH:mm:ss'}} headerText='Date' width={200} minWidth={200} maxWidth={200} customAttributes={{class: ['e-attr']}}/>
                                        <ColumnDirective field='UserID' headerText='Employee ID' width={200} minWidth={200} maxWidth={200} customAttributes={{class: ['e-attr']}}/>
                                        <ColumnDirective field='DeptName' headerText='Department' width={200} minWidth={200} maxWidth={200} customAttributes={{class: ['e-attr']}}/>
                                        <ColumnDirective field='DeptUnitName' headerText='Dept Unit' width={200} minWidth={200} maxWidth={200} customAttributes={{class: ['e-attr']}}/>
                                        <ColumnDirective field='DeptCounty' headerText='Dept County' width={200} minWidth={200} maxWidth={200} customAttributes={{class: ['e-attr']}}/>
                                        <ColumnDirective field='AuthorizedBy' headerText='Supervisor' customAttributes={{ class: ['e-attr'] }} />
                                    </ColumnsDirective>
                                    <Inject
                                        services={[ColumnMenu, Sort, Page, Filter, DetailRow, Toolbar, ExcelExport, PdfExport, Search, Resize, Reorder]}/>
                                </GridComponent>
                            </> : <></>}
                        </div>
                    </TabPanel>
                </TabContext>
            </Box>*/}

        </div>
    );
};
export default AuditLogSearchResults;

//<ColumnDirective field='EventType' headerText='EventType' customAttributes={{ class: ['e-attr'] }} />