import React from "react";
import { useLocation } from "react-router-dom";
import GVRTFOutputForm from "../../components/GRAIL/GVRTF_OutputForm";
import {JAOS} from "../../services/JAOS.service";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}


function GVRTFDetail() {
    let [data,setData] = React.useState<any[]>([])
    let query = useQuery();

    React.useEffect(() => {
        let l = new JAOS()
        let s = query.get("Hash")
        let temp = l.objFromStack(s ? s : "")
        setData(temp)
    },[])
    
    return (
        <div>
            <GVRTFOutputForm Persons={data} />
        </div>
    );
}

export default GVRTFDetail;