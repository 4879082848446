import React, { useEffect, useState } from "react";
import { Account } from "../../interfaces/auth_interface";
import { getUser } from "../../services/auth.service";
import _TRAINING from "../../components/Support/_TRAINING";

interface TRAININGProps {
}

const TRAINING: React.FC<TRAININGProps> = () => {
    let [initialRun, setInitialRun] = React.useState<boolean>(false)
    let [user, setUser] = useState<Account>(getUser())

    return (
        <div>
            <_TRAINING />
        </div>
    );
}

export default TRAINING;