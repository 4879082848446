import React from "react";
import { useLocation } from "react-router-dom";
import InfocopImageDetailInfo from "../../components/Detail/_InfocopImageDetailInfo";
import InfocopDetailInfo from "../../components/Detail/_InfocopDetailInfo";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

  
function InfocopDetail() {
    let query = useQuery();

    return (
        <div>
            {/*return tbody(*/}
            {/*for (var i = 0; i < numrows; i++) {*/}
            {/*    ObjectRow()*/}
            {/*}*/}
            <InfocopImageDetailInfo ID={query.get("ID")} department={query.get("Dept")} />
            <InfocopDetailInfo ID={query.get("ID")} department={query.get("Dept")} />
        </div>
    );
}

export default InfocopDetail;