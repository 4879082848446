import {
    Button,
    ButtonGroup,
    Checkbox,
    Dialog,
    FormControl,
    TextField,
    ToggleButton,
    ToggleButtonGroup
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SystemNotice from "../authentication/login/_systemNotice";
import UpdateUser from "./UpdateUser";
import { Account } from "../../interfaces/auth_interface";
import { getUser, isUserPermission } from "../../services/auth.service";
import UpdatePOI from "./UpdatePOI";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import {enableNJCAP5, SiteName} from "../../services/config.service";
import {CheckBoxSelection, FilteringEventArgs, Inject, MultiSelectComponent} from "@syncfusion/ej2-react-dropdowns";
import {Query} from "@syncfusion/ej2-data";
import {getPOIPurpose} from "../../services/getDropdown.service";
import {formatInputDate} from "../../services/formatDate.service";
import {TreeSelect} from "primereact/treeselect";
import {GetStringNameForSite} from "../../interfaces/config.interface";


interface UserSearchProps {
    SubmitSearch: any
    AddPOI:any
}


const POIs: React.FC<UserSearchProps> = ({ SubmitSearch, AddPOI }) => {

    let today = new Date();
    let fromday = new Date();
    fromday.setMonth(fromday.getMonth() - 1);
    fromday.setDate(fromday.getDate() + 1);
    const [params, setParams] = React.useState<any>({ Reason: [], FName: "", MName: "", LName: "", DOB: "", FBI: "", SBINo: "", DLNo: "", CaseNumber: "", PlateNumber: "", SSN: "", toDate: formatInputDate(today), fromDate: formatInputDate(fromday), showDate: true })
    const [dateType, setDateType] = React.useState<string>("Start Date");
    const [user, setUser] = React.useState<boolean>(false)
    const [initialRun, setInitialRun] = React.useState<boolean>(false)
    const [clear, setClear] = React.useState<number>(0);
    const [dropDownParams, setDropDownParams] = React.useState<any>({});
    const [purposeList, setPurposeList] = React.useState<any[]>([])
    const [purposeExpanded, setPurposeExpanded] = useState<any>([])

    useEffect(() => {
        if (!initialRun) {
            setInitialRun(true)
            handleDropDowns()
            SubmitSearch(params, dateType, dropDownParams, true)
        }
    });


    const handleSearch = () => {
        let temp = {...params}
        temp.Reason = temp.Reason ? Object?.keys(temp?.Reason)?.filter((key) => !key?.includes('BT_PURPOSE')) : []
        SubmitSearch(temp, dateType, dropDownParams)
    }

    const createUser = () => {
        setUser(true)
        //window.location.href = ("/admin/UpdateUser?ID=0")
    }

    const CloseBox = (poiObject:any, action:string) => {
        setUser(false)
        /*if(action === 'Add/Edit') {
            let temp = {...poiObject}
            AddPOI(temp)
        }*/
        if(action === 'Add/Edit') {
            SubmitSearch(params, dateType, dropDownParams, true)
        }
    }

    const handleDateType = (event: React.MouseEvent<HTMLElement>, dateType: string) => {
        if (dateType) {
            setDateType(dateType);
        }
    };

    const getValueTemplate = (selected: any, ddType:string) => {

        if(selected && selected.length > 0) {
            let count = 0;
            for (const item of selected) {
                if(!item || !item?.key?.includes('BT_PURPOSE'))
                    count++;
            }
            return (count + " selected");
        }
        else
            return (ddType)
    }

    const handleClear = () => {
        setParams({ ...params, Reason: [], FName: "", MName: "", LName: "", DOB: "", FBI: "", SBINo: "", DLNo: "", CaseNumber: "", PlateNumber: "", SSN: "", toDate: formatInputDate(today), fromDate: formatInputDate(fromday), showDate: true })
        setDateType("Start Date")
        setClear(clear + 1)
    }

    const handleDropDowns = () => {
        getPOIPurpose().then((res:any) => {
            let tempPurpose:any[] = []
            let defaultExpansion:any = {}
            if(res) {
                for (const [key, options] of Object.entries(res)) {
                    let purposeItem: any = {
                        key: "BT_PURPOSE " + key,
                        label: key,
                        children: []
                    }
                    defaultExpansion[purposeItem.key] = true
                    for (const item of (options as any)) {
                        purposeItem.children.push({
                            key: item.Key,
                            label: item.Value,
                        })
                    }

                    tempPurpose.push(purposeItem)
                }
                setPurposeList(tempPurpose)
                setPurposeExpanded(defaultExpansion)
            }  
        })
    }

    return (
        <div className="ml-5 p-5">
            <div >
                <div className={"mb-3"}>
                    <Button disabled={!isUserPermission('POIAdd')} variant="outlined" size={"small"} type="submit" color="inherit" onClick={createUser}>Add POI</Button>
                </div>
                <div className="grid grid-cols-6 gap-x-5 gap-y-2">
                    <ToggleButtonGroup
                        color="primary"
                        value={dateType}
                        exclusive
                        size="small"
                        fullWidth
                        onChange={handleDateType}
                        sx={{mr: 2}}
                    >
                        <ToggleButton value="Arrest Date" sx={{pr: 2, pl: 2}}>Expiry Date</ToggleButton>
                        <ToggleButton value="Start Date" sx={{pr: 2, pl: 2}}>Start Date</ToggleButton>
                    </ToggleButtonGroup>
                    {/*<Checkbox checked={params.showDate} icon={<EventBusyOutlinedIcon />} checkedIcon={<EventAvailableOutlinedIcon />} onChange={handleDate} />*/}

                    {(params.showDate) ?
                        <>
                            <div style={{width:'100%'}}>
                                <TextField variant="outlined" margin='none' type={"date"} fullWidth
                                           size={"small"} label="From Date"
                                           InputLabelProps={{shrink: true}}
                                           inputProps={{style: {textAlign: 'center'}}}
                                           value={params.fromDate}
                                           onChange={(e: any) => {
                                               setParams({...params, fromDate: e.target.value})
                                           }}
                                />
                            </div>
                            <div style={{width:'100%'}}>
                                <TextField variant="outlined" margin='none' type={"date"} fullWidth
                                           size={"small"} label="To Date"
                                           InputLabelProps={{shrink: true}}
                                           inputProps={{style: {textAlign: 'center'}}}
                                           value={params.toDate}
                                           onChange={(e: any) => {
                                               setParams({...params, toDate: e.target.value})
                                           }}
                                />
                            </div>
                        </>
                        : <></>
                    }
                    {/*
                    <div className="inline mr-5"><TextField margin='none' size={"small"} label={enableNJCAP5 ? "Purpose" : "Reason"} value={params.Reason} onChange={e => setParams({ ...params, Reason: e.target.value })} /></div>
*/}
                    <div>
                        {/*<FormControl style={{width: '100%'}}>
                            <MultiSelectComponent cssClass="e-outline" floatLabelType="Auto" popupHeight='400px'
                                                  popupWidth={"400px"} showSelectAll={true} enableGroupCheckBox={true}
                                                  value={params.Reason}
                                                  change={(e) => {
                                                      setParams({...params, Reason: e.value})
                                                  }} fields={purposeFields} dataSource={purposeList}
                                                  placeholder={enableNJCAP5 ? "Purpose" : "Reason"} mode="CheckBox" filtering={purposeFiltering}
                                                  allowFiltering={true} filterBarPlaceholder="Search Actions"
                            >
                                <Inject services={[CheckBoxSelection]}/>
                            </MultiSelectComponent>
                        </FormControl>*/}
                        <FormControl className="p-float-label" style={{width: '100%'}}>
                            <TreeSelect value={params.Reason} options={purposeList} filter dropdownIcon={"pi none"}
                                        resetFilterOnHide={true}
                                        onChange={(e) => { setParams({...params, Reason: e.value}) }}
                                        selectionMode="checkbox" showClear
                                        placeholder="Purpose"
                                        inputId={"dd-action"}
                                        style={{border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start'}}
                                        valueTemplate={(option: any) => getValueTemplate(option, 'Purpose')}
                                        expandedKeys={purposeExpanded}
                                        onToggle={(e) => {setPurposeExpanded(e.value)}}
                            ></TreeSelect>
                            <label htmlFor="dd-action">Purpose</label>
                        </FormControl>
                    </div>
                    <div><TextField margin='none' style={{width:'100%'}} size={"small"} label="FBI #" value={params.FBI} onChange={e => setParams({...params, FBI: e.target.value})}/></div>
                    <div><TextField margin='none' style={{width:'100%'}} size={"small"} label={GetStringNameForSite(SiteName,"SBI #")} value={params.SBINo} onChange={e => setParams({...params,SBINo: e.target.value})}/></div>
                </div>
                <div className="grid grid-cols-6 gap-x-5 gap-y-2 mt-2">
                    <div className="col-start-2"><TextField margin='none' style={{width:'100%'}} size={"small"} label="First Name" value={params.FName} onChange={e => setParams({...params,FName: e.target.value})}/></div>
                    <div className=""><TextField margin='none' style={{width:'100%'}} size={"small"} label="Middle Initial" value={params.MName} onChange={e => setParams({ ...params, MName: e.target.value})}/></div>
                    <div className=""><TextField margin='none' style={{width:'100%'}} size={"small"} label="Last Name" value={params.LName} onChange={e => setParams({...params, LName: e.target.value })}/></div>
                    <div className=""><TextField margin='none' style={{width:'100%'}} size={"small"} label="DL #" value={params.DLNo} onChange={e => setParams({...params,DLNo: e.target.value })}/></div>
                    <div className=""><TextField margin='none' style={{width:'100%'}} size={"small"} label="Plate #" value={params.PlateNumber} onChange={e => setParams({ ...params, PlateNumber: e.target.value})}/></div>
                </div>
            </div>
            <div className={"pt-3"} style={{"display": "flex", "justifyContent": "end"}}>
                <ButtonGroup size="large" color="inherit">
                    <Button type="submit" onClick={handleSearch}>Search</Button>
                    <Button onClick={handleClear}>CLEAR</Button>
                </ButtonGroup>
            </div>
            <Dialog
                open={user}
                maxWidth={'xl'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <UpdatePOI ID={"0"} CloseBox={CloseBox} />
            </Dialog>
        </div>
    );
}

export default POIs;