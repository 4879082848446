import React from "react";
import { Button, ButtonGroup, TextField} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import GeoCode_Position from "../../shared/GeoCodes/_GeoCode_GetPos";
import MapIcon from "@mui/icons-material/Map";
import InfoIcon from '@mui/icons-material/Info';
import { useHistory } from "react-router-dom";
import { GetStringNameForSite } from "../../../interfaces/config.interface";
import { SiteName } from "../../../services/config.service";


interface PlacesSearchProps {
    SubmitSearch: any,
    DataCleared: any,
}

const PlacesSearch: React.FC<PlacesSearchProps> = ({ SubmitSearch, DataCleared }) => {
    const [params, setParams] = React.useState({ Street: "", City: "", State: "", Zip: ""})
    const [lat, setLat] = React.useState <number | null>(null)
    const [lng, setLng] = React.useState<number | null>(null)
    const [submitError, setSubmitError] = React.useState<string>("")
    const [radius, setRadius] = React.useState<number>(1)
    const [showGeoCode, setShowGeoCode] = React.useState(false)
    const history = useHistory()

    const handleRadius = (e: any) => {
        setRadius(e.target.value)
    }

    const handleLat = (e: any) => {
        setLat(e.target.value)
    }

    const handleLng = (e: any) => {
        setLng(e.target.value)
    }

    const handleClear = () => {
        setParams({ ...params, Street: "", City: "", State: "", Zip: "" })
        setLat(0)
        setLng(0)
        setRadius(1)
        setSubmitError("")
        DataCleared()
    }
    
    const handleValidation = () => {
        var val = false
        setSubmitError("")
        
        if (params === null || (
            params.Street.length === 0 &&
            params.City.length === 0 &&
            params.State.length === 0 &&
            params.Zip.length === 0 &&
            (lat === null || lat === 0) &&
            (lng === null || lng === 0))
        ) {
            setSubmitError("Please enter a search criteria");
            return false
        }
        if ((params.City?.length > 0 || params.Zip?.length>0) || (lat && lng && (lat !== 0 && lng !== 0))) {
            val = true
        }
        else {
            setSubmitError("Please click GEOCODE to generate Latitude and Longitude");
            return false
        }
        return val
    }
    
    const handleSearch = () => {
        if (handleValidation())
            SubmitSearch(params, radius, lat, lng)
    }

    const openGeoCode = () => {
        setShowGeoCode(true)
    }

    const HandleGeoCodeClose = () => {
        setShowGeoCode(false)
    }

    return (
        <>
            <div className="grid grid-cols-4 gap-1 pl-5 pb-5 pr-5 pr-10 mt-5">
                <div><TextField margin='none' size={"small"} label="Street Address" value = {params.Street}  onChange={e => setParams({...params, Street: e.target.value})} /></div>
                <div><TextField margin='none' size={"small"} label={GetStringNameForSite(SiteName, "CITY") } value = {params.City} onChange={e => setParams({...params, City: e.target.value})} /></div>
                <div><TextField margin='none' size={"small"} label="State" value = {params.State} onChange={e => setParams({...params, State: e.target.value})}  /></div>
                <div><TextField margin='none' size={"small"} label="Zip Code" value = {params.Zip} onChange={e => setParams({...params, Zip: e.target.value})}  /></div>
            
                <div className="mt-3"><TextField margin='none' size={"small"} label="Latitude" placeholder="0" value={lat === 0 ? '' : lat} type="number" onChange={handleLat} InputLabelProps={{ shrink: true }} /></div>
                <div className="mt-3"><TextField margin='none' size={"small"} label="Longitude" placeholder="0" value={lng === 0 ? '' : lng} type="number" onChange={handleLng} InputLabelProps={{ shrink: true }}/></div>
                <div className="mt-3"><TextField margin='none' InputLabelProps ={{shrink:true}} type={"number"} size={"small"} label="Range (in Miles)"  value={radius} onChange={handleRadius} /></div>
                <div className="pt-3 col-start-4">
                    <div>
                        <ButtonGroup size="large" color="inherit" >
                            <Button onClick={openGeoCode} className={"p-1"}> <MapIcon color="success" style={{fontSize:"20"}} />GeoCode</Button>
                        </ButtonGroup>
                    </div>
                    {(showGeoCode) ? <div><GeoCode_Position SetLatLng={(lat:any,lng:any)=>{setLat(lat); setLng(lng)}} HandleGeoCodeClose={HandleGeoCodeClose} show={showGeoCode} pin={{lat:lat, lng:lng, address: params.Street, city:params.City, state:params.State, zip:params.Zip}} /></div>:<></>}
                </div>
            </div>
            <div className="flex justify-center items-center mb-5">
                <ButtonGroup size="large" color="inherit" >
                    <Button onClick={handleSearch}>Search</Button>
                    <Button onClick={handleClear}>CLEAR</Button>
                    <Button title="Advanced Places Search 'Tools-Person Nearby'" onClick={() => { history.push("/tools/person_nearby") }}><span className="text-blue-600">Adv. Search</span></Button>
                </ButtonGroup>
            </div>
            <div className={submitError?.length > 0 ? "flex justify-center items-center mb-5 font-bold" : ""}>
                <span>{submitError?.length > 0 ? <span style={{ color: "red", fontSize: 14 }}><ErrorIcon style={{ color: "red" }} fontSize='small' />{submitError}</span> : <></>}</span>
            </div>
        </>
    );
}

export default PlacesSearch;