import {
  Alert,
  Box,
  breadcrumbsClasses,
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import React from "react";
import {
  DropDown,
  InvestigationObject,
} from "../../../interfaces/NJPORT/InvestigationLog.interface";
import { formatDateStringForTextField } from "../../utilities/basics";
import { MultiSelect } from "primereact/multiselect";

import { InvestigationDropDown, SexDropdown, RaceDropdown } from "../../../services/njport.service";

interface InputFormProps {
  handleReportSubmit: any;
  editReport: InvestigationObject | null;
}

const InvestigationInputForm: React.FC<InputFormProps> = ({
  handleReportSubmit,
  editReport,
}) => {
  const [report, setReport] = React.useState<InvestigationObject>({});
  const [invStatusDD, setInvStatusDD] = React.useState<DropDown[]>([]);
  const [jobTitleDD, setJobTitleDD] = React.useState<DropDown[]>([]);
  const [pacCardDD, setPacCardDD] = React.useState<DropDown[]>([]);
  const [alertOpen, setAlertOpen] = React.useState<boolean>(false);
  const [alertText, setAlertText] = React.useState<String>("");
  const [alertType, setAlertType] = React.useState<any>("success");
  const [defaultstatus, setDefaultStatus] = React.useState<string>('');
  const [defaultjobTilte, setDefaultjobTilte] = React.useState<string>('');
  const [defaultpacCard, setDefaultpacCard] = React.useState<string>('');
  const [defaultSex, setDefaultSex] = React.useState<string>('');
  const [defaultRace, setDefaultRace] = React.useState<string>('');
  const [defaultRefer, setDefaultRefer] = React.useState<string>('');

  const [sexDD, setsexDD] = React.useState<DropDown[]>([]);
  const [raceDD, setRaceDD] = React.useState<any[]>([]);
  const [refferealSourceDD, setRefferealSourceDD] = React.useState<any[]>([]);
  const [sexValues, setSexValues] = React.useState<any[]>([])
  const [raceValues, setRaceValues] = React.useState<any[]>([])
  const [refferealSourceValues, setrefferealSourceValues] = React.useState<any[]>([])


  React.useEffect(() => {
    invStatusDropdown();
    jobTitleDropdown();
    pacCardDropdown();
    getSexDropdown();
    getRaceDropdown();
    getRefferalSourceDropdown();
    if (editReport) {
      console.log("edit report"+ editReport.Gender)
      setReport(editReport);
      

    };

    defaultStatusDD(invStatusDD);
    defaultjobTilteDD(jobTitleDD);
    defaultpacCardDD(pacCardDD);
    // defaultSexDD(sexDD);
    defaultRaceDD(raceDD);
    defaultReferDD(refferealSourceDD);

  }, []);


  const defaultSexDD = (d: DropDown[]) => {
    let v: string = d ? d.find(d => d.Key === editReport?.Gender)?.Value! : '';
    console.log(v+" value ");
    setDefaultSex(v);
  }

  const convertDate = (dateInput: Date | string | null | undefined) => {
    if (!dateInput) {
      const today = new Date();
      return `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
    }
  
    // If dateInput is a string, try to parse it to a Date object
    const date = typeof dateInput === 'string' ? new Date(dateInput) : dateInput;
  
    if (date instanceof Date && !isNaN(date.getTime())) {
      return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    }
  
    // If dateInput is invalid, fallback to today's date
    const today = new Date();
    return `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
  };
  const defaultRaceDD = (d: DropDown[]) => {
    let v: string = d ? d.find(d => d.Key === editReport?.Race)?.Value! : ''
    setDefaultRace(v);
  }

  const defaultReferDD = (d: DropDown[]) => {
    let v: string = d ? d.find(d => d.Key === editReport?.ReferralSource)?.Value! : ''
    setDefaultRefer(v);
  }

  const defaultStatusDD = (d: DropDown[]) => {
    let v: string = d ? d.find(d => d.Key === editReport?.Investigation_Status)?.Value! : ''
    setDefaultStatus(v);
  }

  const defaultjobTilteDD = (d: DropDown[]) => {
    let v: string = d ? d.find(d => d.Key === editReport?.Job_Code)?.Value! : ''
    setDefaultjobTilte(v);
  }

  const defaultpacCardDD = (d: DropDown[]) => {
    let v: string = d ? d.find(d => d.Key === editReport?.PAC_Card_Issued)?.Value! : ''
    setDefaultpacCard(v);
  }

  const handleValueChange = (field: string, val: any) => {
    let temp = { ...report };
    switch (field) {
      case "id":
        temp.ID = val;
        break;
      case "caseNo":
        temp.CaseNo = val;
        break;
      case "investigatorName":
        temp.InvestigatorName = val;
        break;
      case "date_Received":
        temp.Date_Received = val;
        break;
      case "date_Status_Change":
        temp.Date_Status_Change = val;
        break;
      case "investigation_Status":
        console.log("val changed", val);
        temp.Investigation_Status = val;
        setDefaultStatus(invStatusDD.find(d => d.Key === val)?.Value ?? '');
        break;
      case "firstName":
        temp.FirstName = val;
        break;
      case "middleName":
        temp.MiddleName = val;
        break;
      case "lastName":
        temp.LastName = val;
        break;
      // case "job_Code":
      //   temp.Job_Code = val;
      //   break;
      case "job_Title":
        temp.Job_Code = val;
        setDefaultjobTilte(jobTitleDD.find(d => d.Key === val)?.Value ?? '');
        break;
      case "sponsorName":
        temp.SponsorName = val;
        break;
      case "ssn":
        temp.SSN = val;
        break;
      case "dob":
        temp.DOB = val;
        break;
      case "phoneNo":
        temp.PhoneNo = val;
        break;
      case "email":
        temp.Email = val;
        break;
      case "date_Fingerprint_Return":
        temp.Date_Fingerprint_Return = val;
        break;
      case "pac_Card_Issued":
        temp.PAC_Card_Issued = val;
        setDefaultpacCard(pacCardDD.find(d => d.Key === val)?.Value ?? '');
        break;
      case "pac_Card_Number":
        temp.PAC_Card_Number = val;
        break;
      case "date_pac_Card_Issued":
        temp.Date_PAC_Card_Issued = val;
        break;
      case "notes":
        temp.Notes = val;
        break;
      case "createUser":
        temp.CreateUser = val;
        break;
      case "createDept":
        temp.CreateDept = val;
        break;
      case "createDate":
        temp.CreateDate = val;
        break;
      case "updateUser":
        temp.UpdateUser = val;
        break;
      case "updateDept":
        temp.UpdateDept = val;
        break;
      case "updateDate":
        temp.UpdateDate = val;
        break;
      case "deleted":
        temp.Deleted = val;
        break;
      case "sex":
        temp.Gender = val;
        setDefaultSex(sexDD.find(d => d.Key === val)?.Value ?? '');
        console.log(val)
        break;
      case "race":
        temp.Race = val;
        setDefaultRace(raceDD.find(d => d.Key === val)?.Value ?? '');
        break;
      case "refer":
        temp.ReferralSource = val;
        setDefaultRefer(refferealSourceDD.find(d => d.Key === val)?.Value ?? '');
        break;      
    }
    // console.log(temp.Date_Received);
    if(!temp.Date_Received){
      temp.Date_Received=convertDate(report.Date_Received);
      console.log("inner entered")
    }
    if(!temp.DOB){
      temp.DOB=convertDate(report.DOB);
      console.log("inner entered")
    }
    if(!temp.Date_PAC_Card_Issued ){
      temp.Date_PAC_Card_Issued =convertDate(report.Date_PAC_Card_Issued);
      console.log("inner entered")
    }
    if(!temp.CreateDate ){
      temp.CreateDate =convertDate(report.CreateDate);
      console.log("inner entered")
    }
    if(!temp.UpdateDate ){
      temp.UpdateDate =convertDate(report.UpdateDate);
      console.log("inner entered")
    }
    setReport(temp);
  };

  console.log(report?.PAC_Card_Issued)

  const handleSubmit = async () => {
    let data = report;
    if (editReport === null) data.EditStatus = "ADD";
    else data.EditStatus = "EDIT";
    if (!handleValidation()) handleReportSubmit(data);
  };

  const handleValidation = () => {
    let flag = false;
    let text = "";
    let temp = { ...report };
    if (temp) {
      if (!temp.FirstName || temp.FirstName.trim().length === 0) {
        flag = true;
        text = "FirstName";
      } else if (
        !temp.Investigation_Status ||
        temp.Investigation_Status.length === 0
      ) {
        flag = true;
        text = "Investigation Status";
      } else if (!temp.Email || temp.Email.trim().length === 0) {
        flag = true;
        text = "Email";
      } else if (!temp.Job_Code || temp.Job_Code.length === 0) {
        flag = true;
        text = "Job Title";
      } else if (!temp.SponsorName || temp.SponsorName.trim().length === 0) {
        flag = true;
        text = "Sponsor Name";
      }
      if (flag) {
        setAlertText("Please complete Mandatory fields" + text);
        setAlertOpen(true);
        setAlertType("error");
      }
    }
    return flag;
  };

  const invStatusDropdown = async () => {
    let data = {
      params: {
        DropDownName: "PORT_INVSTATUS",
        State: "NJ",
      },
    };
    let response: DropDown[] = await InvestigationDropDown(data);
    setInvStatusDD(response);
    defaultStatusDD(response);
  };

  const jobTitleDropdown = async () => {
    let data = {
      params: {
        DropDownName: "PORT_JOBCODE",
        State: "NJ",
      },
    };
    let response: DropDown[] = await InvestigationDropDown(data);
    setJobTitleDD(response);
    defaultjobTilteDD(response);
  };

  const pacCardDropdown = async () => {
    let data = {
      params: {
        DropDownName: "YESNO",
        State: "NJ",
      },
    };
    let response: DropDown[] = await InvestigationDropDown(data);
    setPacCardDD(response);
    defaultpacCardDD(response);
  };

  const getSexDropdown = async () => {
    // let response: DropDown[] = await SexDropdown();
    let data = {
      params: {
        DropDownName: "PORT_GENDER",
        State: "NJ",
      },
    };
    let response: DropDown[] = await InvestigationDropDown(data);
    console.log(response);
    setsexDD(response);
    defaultSexDD(response);
  };

  const getRaceDropdown = async () => {
    // let response: any[] = await RaceDropdown();
    // setRaceDD(response.map(val => {
    //   return { Key: val.ID, Value: val.Description }
    // }))
    let data = {
      params: {
        DropDownName: "PORT_RACE",
        State: "NJ",
      },
    };
    let response: DropDown[] = await InvestigationDropDown(data);
    setRaceDD(response);
    defaultRaceDD(response);
  };

  const getRefferalSourceDropdown = async () => {
    let data = {
      params: {
        DropDownName: "PORT_REFERRALSOURCE",
        State: "NJ",
      },
    };
    let response: DropDown[] = await InvestigationDropDown(data);
    setRefferealSourceDD(response);
    defaultReferDD(response);
  };

  return (
    <Box className="pt-1" sx={{ flexGrow: 1 }}>
      <div className=" border-2 border-gray-600 mx-4 mt-4">
        <div className="border-b-2 border-gray-600 bg-blue-300 h-2/12 font-bold text-center text-xl">
          Case
        </div>
        <div className="my-3">
          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Case No"
                value={report.CaseNo}
                onChange={(e) =>
                  handleValueChange("caseNo", e.target.value)
                }
                error={report.CaseNo ? false : true}
                helperText={report.CaseNo ? "" : "Required"}
              />
            </Grid>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Investigator Name"
                value={report.InvestigatorName}
                onChange={(e) =>
                  handleValueChange("investigatorName", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                type="date"
                label="Date Received"
                value={convertDate( report.Date_Received)}
                InputLabelProps={{ shrink: true }}
                onChange={(e) =>
                  handleValueChange("date_Received", e.target.value)
                }
                // error={report.Date_Received ? false : true}
                // helperText={report.Date_Received ? "" : "Required"}
              />
              
            </Grid>
          </Grid>

          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
            <Grid item xs={5} sx={{ pr: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="invStatus-Label">Inv Status</InputLabel>
                <Select
                  labelId="invStatus-Label"
                  id="invStatus-select"
                  value={defaultstatus}
                  label="InvStatus"
                  renderValue={() => defaultstatus}
                  onChange={(e: any) =>
                    handleValueChange("investigation_Status", e.target.value)
                  }
                  error={report.Investigation_Status ? false : true}

                >
                  {invStatusDD.map((d) => (
                    <MenuItem key={d.Key} value={d.Key}>
                      {d.Value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>


            <Grid item xs={5} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                type="date"
                label="Date Status Change"
                value={convertDate(report.Date_Status_Change)}
                InputLabelProps={{ shrink: true }}
                onChange={(e) =>
                  handleValueChange("date_Status_Change", e.target.value)
                }
              />
            </Grid>
          </Grid>
        </div>
      </div>

      <div className=" border-2 border-gray-600 mx-4 mt-4">
        <div className="border-b-2 border-gray-600 bg-blue-300 h-2/12 font-bold text-center text-xl">
          Person
        </div>
        <div className="my-3">
          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
            <Grid item xs={3} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="First Name"
                value={report.FirstName}
                onChange={(e) => handleValueChange("firstName", e.target.value)}
                error={report.FirstName ? false : true}
                helperText={report.FirstName ? "" : "Required"}
              />
            </Grid>
            <Grid item xs={3} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Middle Name"
                value={report.MiddleName}
                onChange={(e) =>
                  handleValueChange("middleName", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={3} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Last Name"
                value={report.LastName}
                onChange={(e) => handleValueChange("lastName", e.target.value)}
                error={report.LastName ? false : true}
                helperText={report.LastName ? "" : "Required"}
              />
            </Grid>

            <Grid item xs={3} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                type="date"
                label="DOB"
                value={convertDate( report.DOB)}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => handleValueChange("dob", e.target.value)}
                // error={report.DOB ? false : true}
                // helperText={report.DOB ? "" : "Required"}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="SSN"
                value={report.SSN}
                onChange={(e) => handleValueChange("ssn", e.target.value)}
              />
            </Grid>

            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="PhoneNo"
                value={report.PhoneNo}
                onChange={(e) => handleValueChange("phoneNo", e.target.value)}
              // error={report.PhoneNo ? false : true}
              // helperText={report.PhoneNo ? "" : "Required"}

              />
            </Grid>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Email"
                value={report.Email}
                onChange={(e) => handleValueChange("email", e.target.value)}
                error={report.Email ? false : true}
              />
            </Grid>
          </Grid>
        </div>
      </div>

      <div className=" border-2 border-gray-600 mx-4 mt-4">
        <div className="border-b-2 border-gray-600 bg-blue-300 h-2/12 font-bold text-center text-xl">
          Job
        </div>
        <div className="my-3">
          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
            {/* <Grid item xs={6} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Job Code"
                value={report.Job_Code}
                onChange={(e) => handleValueChange("job_Code", e.target.value)}
                error = {report.Job_Code ? false : true}
              />
            </Grid> */}
            <Grid item xs={6} sx={{ pr: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="jobTitle-Label">Job Title</InputLabel>
                <Select
                  labelId="jobTitle-Label"
                  id="jobTitle-select"
                  value={defaultjobTilte}
                  label="JobTitle"
                  renderValue={() => defaultjobTilte}
                  onChange={(e: any) =>
                    handleValueChange("job_Title", e.target.value)
                  }
                  error={report.Job_Code ? false : true}
                >
                  {jobTitleDD.map((d) => (
                    <MenuItem key={d.Key} value={d.Key}>
                      {d.Value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Sponsor Name"
                value={report.SponsorName}
                onChange={(e) =>
                  handleValueChange("sponsorName", e.target.value)
                }
                error={report.SponsorName ? false : true}
              />
            </Grid>
          </Grid>

        </div>
      </div>

      <div className=" border-2 border-gray-600 mx-4 mt-4">
        <div className="border-b-2 border-gray-600 bg-blue-300 h-2/12 font-bold text-center text-xl">
          PAC
        </div>
        <div className="my-3">
          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
            <Grid item xs={6} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                type="date"
                label="Date FingerPrint Return"
                value={ convertDate( report.Date_Fingerprint_Return)}
                InputLabelProps={{ shrink: true }}
                onChange={(e) =>
                  handleValueChange("date_Fingerprint_Return", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={6} sx={{ pr: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="pacCard-Label">PAC Card Issued</InputLabel>
                <Select
                  labelId="pacCard-Label"
                  id="pacCard-select"
                  value={defaultpacCard}
                  label="PAC_Card_Issued"
                  renderValue={() => defaultpacCard}
                  onChange={(e: any) =>
                    handleValueChange("pac_Card_Issued", e.target.value)
                  }
                >
                  {pacCardDD.map((d) => (
                    <MenuItem key={d.Key} value={d.Key}>
                      {d.Value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
            {report?.PAC_Card_Issued == 'Y' && (
              <Grid item xs={6} sx={{ pr: 2 }}>
                <TextField
                  fullWidth
                  margin="none"
                  label="Pac Card Number"
                  value={report.PAC_Card_Number}
                  onChange={(e) =>
                    handleValueChange("pac_Card_Number", e.target.value)
                  }
                />
              </Grid>)
            }
            <Grid item xs={6} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                type="date"
                label="Date Pac Card Issued"
                value={convertDate (report.Date_PAC_Card_Issued)}
                InputLabelProps={{ shrink: true }}
                onChange={(e) =>
                  handleValueChange("date_pac_Card_Issued", e.target.value)
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
            <Grid item xs={4} sx={{ pr: 2 }}>

            <FormControl fullWidth>
              <InputLabel id="sex-Label">Sex</InputLabel>
              <Select
                labelId="sex-Label"
                id="sex-select"
                value={defaultSex}
                label="Sex"
                renderValue={() => defaultSex}
                onChange={(e) =>
                  handleValueChange("sex", e.target.value)
                }
              >
                {sexDD.map((d) => (
                  <MenuItem value={d.Key} key={d.Key}>
                    {d.Value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            </Grid>
            <Grid item xs={4} sx={{ pr: 2 }}>


            <FormControl fullWidth>
              <InputLabel id="Race-Label">Race</InputLabel>
              <Select
                labelId="Race-Label"
                id="Race-select"
                value={defaultRace}
                label="Race"
                renderValue={() => defaultRace}
                onChange={(e) =>
                  handleValueChange("race", e.target.value)
                }
              >
                {raceDD.map((d) => (
                  <MenuItem value={d.Key} key={d.Key}>
                    {d.Value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            </Grid>
            <Grid item xs={4} sx={{ pr: 2 }}>


            <FormControl fullWidth>
              <InputLabel id="refer-Label">Referral Source </InputLabel>
              <Select
                labelId="refer-Label"
                id="refer-select"
                value={defaultRefer}
                label="Reffereal Source"
                renderValue={() => defaultRefer}
                onChange={(e) =>
                  handleValueChange("refer", e.target.value)
                }
              >
                {refferealSourceDD.map((d) => (
                  <MenuItem value={d.Key} key={d.Key}>
                    {d.Value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            </Grid>
          </Grid>
        </div>
      </div>

      <Snackbar
        open={alertOpen}
        autoHideDuration={7000}
        onClose={() => {
          setAlertOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setAlertOpen(false);
          }}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {alertText}
        </Alert>
      </Snackbar>
      <Grid container spacing={1} sx={{ pl: 2, mt: 2, justifyContent: 'flex-end' }}>
        <Grid item xs={8} sx={{ pr: 2 }}></Grid>
        <Grid item xs={2} sx={{ pr: 2 }}>
          <ButtonGroup fullWidth size="large" color="inherit">
            <Button type="submit" onClick={handleSubmit}>
              Submit
            </Button>
            <Button>CLEAR</Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InvestigationInputForm;
