import React, {useState} from "react";
import ReactECharts from "echarts-for-react";

interface ShootingsDashboardGridProps {
    location:string,
    dbData:any
}


const ShootingRaceDashboardGrid: React.FC<ShootingsDashboardGridProps> = ({location, dbData}) => {
    var option;

    const handleSeries = () =>
    {
        let series:any = []
    
        if(dbData.RaceData)
        {
            for(let race of dbData.RaceData)
            {
               series.push({ value: race.Count, name: race.Race + ' ' + race.Gender })
            }
        }
        return series
    }
    
    
    option = {
        height:'300',
        grid: {
            left: '3%',
            right: '5%',
            bottom: '3%',
            top: '3%',
            containLabel: true
        },
        toolbox: {
            feature: {
                saveAsImage: { show: true }
            }
        },
        tooltip: {
            trigger: 'item'
        },
        series: [
            {
                name: 'Race & Gender Breakdown',
                type: 'pie',
                radius: '50%',
                data: handleSeries(),
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };
    
    return (
        <div className = "border-gray-900 border-2 h-72">
            <div className="bg-blue-900 font-bold text-yellow-400 text-center h-8 text-lg">Shooting Victims by Race/Gender</div>
            {dbData && dbData.length !== 0 ?
                <div className = "border-gray-900 border-t-2">
                    <ReactECharts option={option} />
                </div>
                :
                <div>No Data</div>
            }
        </div>
    );
}

export default ShootingRaceDashboardGrid;