import { HttpTransportType, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { HubConnection } from "@microsoft/signalr/dist/esm/HubConnection";
import { API_URL } from "./config.service";

export const signalRConnection = (path: string): HubConnection => {
    const connection = new HubConnectionBuilder()
    .configureLogging(LogLevel.Debug)
    .withUrl(API_URL + path, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
    })
    .build();

    return connection;
};