import {PersonInfo} from "../../../interfaces/getDarInfo_interface";
import React from "react";
import { Disclosure } from '@headlessui/react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {Button, CircularProgress} from "@mui/material";
import MissingPersonIcon from "../../../assets/Images/MissingPersonIcon.png";

import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent,
    Inject,
    Page,
    Sort,
    Filter, SortSettingsModel,
} from '@syncfusion/ej2-react-grids';
import {JAOS} from "../../../services/JAOS.service";

interface PanelMissingPersonProps {
    person: PersonInfo,
    MissingPersonInfo: any[],
    isLoading:boolean
}

const  PanelMissingPerson: React.FC<PanelMissingPersonProps> = ({person,MissingPersonInfo,isLoading})=> {

    const [FormatData, setFormatData] = React.useState(0);

    const handleDataTyping = () => {
        let tempP: any = [...MissingPersonInfo]
        for (let p of tempP) {
            if (p.ReportDate) {
                p.ReportDate = new Date(p.ReportDate)
            }
        }
        setFormatData(tempP)
    }

    React.useEffect(() => {
        handleDataTyping()
    }, [MissingPersonInfo])

    let sortSettings: SortSettingsModel = { columns: [
            {field: 'ReportDate', direction: 'Descending' }
        ] };

    return(
        <div className={"m-5 border"}>
            <Disclosure>
                {({ open }) => (
                    <>
                        <Disclosure.Button className="bg-blue-100 p-2 w-auto w-full text-left">
                            <span style={{ "display": "flex", "justifyContent": "space-between" }}>
                                <span>
                                    <KeyboardArrowDownIcon
                                        className={`${open ? "transform rotate-180" : ""}`}
                                    />
                                    <span className={"font-bold"}>Missing Person {<img src={MissingPersonIcon} alt={''} width='30' style={{ display: 'inline' }} />} {(isLoading) ? <CircularProgress size={20} style={{"marginLeft" : "5px"}} /> : <></>}</span>
                                </span>
                                <span className={"font-bold mr-2"} >Name: {person?.FName?.toUpperCase()} {person?.MName?.toUpperCase()} {person?.LName?.toUpperCase()} &nbsp;&nbsp;Records: {MissingPersonInfo?.length}</span>
                            </span>
                        </Disclosure.Button>

                        <Disclosure.Panel className="text-gray-500 p-4">
                            
                            <GridComponent
                                dataSource={MissingPersonInfo}
                                allowPaging={true}
                                pageSettings={{pageSize: 50, pageSizes: [10,25,50,100]}}
                                allowSorting={true}
                                sortSettings={sortSettings}
                                allowMultiSorting={true}
                                allowFiltering={true}
                                filterSettings={{type: 'CheckBox'}}
                                allowTextWrap={true}
                                textWrapSettings={{ wrapMode: 'Both' }}
                                rowHeight={24}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective field='ReportDate' headerText='REPORT DATE' format={{ type: 'date', format: 'MM/dd/yyyy HH:mm' }} width={250}/>
                                    <ColumnDirective field='CCN' headerText='CCN#'/>
                                    <ColumnDirective field='STATUS' headerText='STATUS' width={180}/>
                                    <ColumnDirective field='RoleDesc' headerText='ROLE'/>
                                    <ColumnDirective field='LOCATION_SECTION' headerText='SECTION'/>
                                    <ColumnDirective field='LastKnownLocation' headerText='LAST KNOWN LOCATION'/>
                                    <ColumnDirective field='ReturnedLocation' headerText='RETURNED LOCATION' width={160}/>
                                    {/*<ColumnDirective template={(item: any) => (<Button onClick={() => { window.open('/detail/Jail?ID=' + new JAOS().objToStack(item) + '&Dept=', '_blank') }} >View</Button>)} headerText='View' />*/}

                                </ColumnsDirective>
                                <Inject services={[Sort, Page, Filter]}/>
                            </GridComponent>

                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>

    )
};

export default PanelMissingPerson;