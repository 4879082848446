import axios, {AxiosResponse} from "axios";
import AuthenticatedAPIReq from "./AuthenticatedAPIReq.service";
import UserAttributes from "../interfaces/UserAttributes.interface";
import {API_URL} from "./config.service";

export async function GetDeptSSODetails(dept: any): Promise<any> {
    let res: AxiosResponse<any> = await axios.get('/api/SSO/SSOClientDetails?dept='+dept,{
        baseURL: API_URL,
        withCredentials: false,
    })
    return (res?.data)
}
export async function SSOLoginRequest(params: any): Promise<any> {
    let res: AxiosResponse<any> = await axios.post('/api/SSO/SSOLoginRequest', params,{
        baseURL: API_URL,
        withCredentials: false,
    })
    return (res?.data)
}
export async function SSONewProfile(user: UserAttributes, token: string): Promise<any> {
    let res: AxiosResponse<any> = await axios.post('/api/SSO/SSONewProfile', user, {
        baseURL: API_URL,
        withCredentials: false,
        headers: {
            token: `${token}`
        }
    })
    return (res?.data)
}