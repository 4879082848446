import React from "react";
import {logoutUser} from "../../../services/auth.service";
import {JAOS} from "../../../services/JAOS.service";
import {
    Box,
    Button,
    Card,
    Container,

} from "@mui/material";

function LogOff() {
    new JAOS().clearStack();
    logoutUser();
    
    return (
        <div className = "min-w-screen min-h-screen bg-gray-800">
            {/*<div className = "min-w-screen min-h-screen" style ={{"backgroundColor":"#002B7A"}}></div>*/}
            <Container component="main" maxWidth="xs" >
                <Box
                    sx={{
                        marginTop: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Card variant = "outlined" style = {{"padding":"15px"}}
                          sx={{
                              marginTop: 15,
                              boxShadow: 25,
                          }}
                    >
                     <div className="text-center" style={{width:"25vw", height:"25vh"}}>
                         <div className={"text-gray-800 mt-12"} style={{fontSize:"20px", fontWeight:"bold"}}>
                             You have been logged out
                         </div>
                         <div className={"text-gray-800 mt-4 mb-1"} style={{fontSize:"16px"}}>
                             Thank you for using BackTrace
                         </div>
                         <hr/>
                         <div className={"text-gray-800 mt-4 "} style={{fontSize:"16px"}}>
                            <Button
                             type="button"
                             fullWidth
                             style = {{"backgroundColor":"#002B7A"}}
                             variant="contained"
                             sx={{ mt:3, mb: 2 }}
                             onClick={ () => {window.location.href = "/login"} }
                            >
                                Need to log in?
                            </Button>
                         </div>
                     </div>   
                    </Card>
                </Box>

            </Container>
        </div>
    );
}

export default LogOff;