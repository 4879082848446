import React, {useState} from "react";
import ReactECharts from "echarts-for-react";
import {SiteName} from "../../../services/config.service";
import {SITE} from "../../../interfaces/config.interface";

interface StolenVehiclesProps {
    data:any,
    tab:string,
    city:string,
    getAbbreviated:any
}


const SixHourBreakdown: React.FC<StolenVehiclesProps> = ({data, tab,city,getAbbreviated}) => {
    var option;

    const getSum = (list:any) => {
        let sum = 0
        for(let x of list)
        {
            sum += x
        }
            return sum
    }
    
    const handleFormatXAxis = () => {
        let xAxis:any = []
        switch(tab) {
            case '1':
                if ('SixHourBreakdownStolen' in data && Array.isArray(data.SixHourBreakdownStolen)) {
                    let counties = []
                    for (let d of data.SixHourBreakdownStolen) {
                        if (counties.findIndex((e: any) => e?.toUpperCase() === d.County?.toUpperCase()) === -1)
                            counties.push(d.County?.toUpperCase())
                    }
                    if((SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) && counties.length > 2){
                        for(let i = 0; i < counties.length; i++){
                            counties[i] = getAbbreviated(counties[i])
                        }
                    }
                    if (city === 'ALL CITIES' || counties.length === 0) {
                        xAxis = counties
                    } else {
                        xAxis.push(city?.toUpperCase())
                    }
                }
                break;
            case '2':
                if ('SixHourBreakdownRecovered' in data && Array.isArray(data.SixHourBreakdownRecovered)) {
                    let counties = []
                    for (let d of data.SixHourBreakdownRecovered) {
                        if (counties.findIndex((e: any) => e?.toUpperCase() === d.County?.toUpperCase()) === -1)
                            counties.push(d.County?.toUpperCase())
                    }
                    if((SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) && counties.length > 2){
                        for(let i = 0; i < counties.length; i++){
                            counties[i] = getAbbreviated(counties[i])
                        }
                    }
                    if (city === 'ALL CITIES' || counties.length === 0) {
                        xAxis = counties
                    } else {
                        xAxis.push(city?.toUpperCase())
                    }
                }
                break;
        }

        return xAxis
    }

    const handleSeries = () => {
        let list:any = []
        let listP:any = []
        let xAxis:any = handleFormatXAxis()
        let series:any = []

        switch(tab){
            case '1':
                if('SixHourBreakdownStolen' in data && Array.isArray(data.SixHourBreakdownStolen))
                {
                    if(city === 'ALL CITIES') {

                        for (let temp of xAxis) {
                            list.push(0)
                            listP.push(0)
                        }
                        for (let d of data.SixHourBreakdownStolen) {
                            let index = -1
                            if((SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) && xAxis.length > 2)
                                index = xAxis.findIndex((e: any) => e?.toUpperCase() === getAbbreviated(d?.County?.toUpperCase()))
                            else
                                index = xAxis.findIndex((e: any) => e?.toUpperCase() === d.County?.toUpperCase())
                            if (index !== -1) {
                                if(d.Type === 'STOLEN VEHICLE') {
                                    list[index] = d.Count
                                }
                                else{
                                    listP[index] = d.Count
                                }
                            }
                        }
                    }
                    else
                    {
                        for (let d of data.SixHourBreakdownStolen) {
                            if(d.Type === 'STOLEN VEHICLE') {
                                list.push(d.Count)
                            }
                            else{
                                listP.push(d.Count)
                            }

                        }
                    }
                    series.push({
                        name: 'Vehicles ' + getSum(list),
                        type: 'bar',
                        stack: 'total',
                        color:'#1e3a8a',
                        emphasis: {
                            focus: 'series'
                        },
                        label: {
                            show: true,
                            position: 'inside',
                            rotate:20,
                            fontSize:11,
                        },
                        data: list
                    },)
                    series.push({
                        name: 'Plates ' + getSum(listP),
                        type: 'bar',
                        stack: 'total',
                        color:'#8E1600',
                        emphasis: {
                            focus: 'series'
                        },
                        label: {
                            show: true,
                            position: 'inside',
                            rotate:20,
                            fontSize:11,
                        },
                        data: listP
                    },)
                }
                break;
            case '2':
                if('SixHourBreakdownRecovered' in data && Array.isArray(data.SixHourBreakdownRecovered))
                {
                    if(city === 'ALL CITIES') {

                        for (let temp of xAxis) {
                            list.push(0)
                            listP.push(0)
                        }
                        for (let d of data.SixHourBreakdownRecovered) {
                            let index = -1
                            if((SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) && xAxis.length > 2)
                                index = xAxis.findIndex((e: any) => e?.toUpperCase() === getAbbreviated(d?.County?.toUpperCase()))
                            else
                                index = xAxis.findIndex((e: any) => e?.toUpperCase() === d.County?.toUpperCase())
                            if (index !== -1) {
                                if(d.Type === 'RECOVERED VEHICLE') {
                                    list[index] = d.Count
                                }
                                else{
                                    listP[index] = d.Count
                                }
                            }
                        }
                    }
                    else
                    {
                        for (let d of data.SixHourBreakdownRecovered) {
                            if(d.Type === 'RECOVERED VEHICLE') {
                                list.push(d.Count)
                            }
                            else{
                                listP.push(d.Count)
                            }

                        }
                    }
                    series.push({
                        name: 'Vehicles',
                        type: 'bar',
                        stack: 'total',
                        color:'#1e3a8a',
                        emphasis: {
                            focus: 'series'
                        },
                        label: {
                            show: true,
                            position: 'inside',
                            rotate:20,
                            fontSize:11,
                        },
                        data: list
                    },)
                    series.push({
                        name: 'Plates',
                        type: 'bar',
                        stack: 'total',
                        color:'#8E1600',
                        emphasis: {
                            focus: 'series'
                        },
                        label: {
                            show: true,
                            position: 'inside',
                            rotate:20,
                            fontSize:11,
                        },
                        data: listP
                    },)
                }
                break;
        }

        return series
    }

    option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                // Use axis to trigger tooltip
                type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
            }
        },
        height:'180',
        width:'95%',
        toolbox: {
            feature: {
                saveAsImage: { show: true }
            }
        },
        legend: {
        },
        grid: {
            left: '1%',
            right: '1%',
            bottom: '1%',
            top:'10%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            axisLabel: {
                interval:0,
                rotate:(handleFormatXAxis().length <= 2 ? 0 : 45),
                fontSize:8,
            },
            data: handleFormatXAxis(),
        },
        yAxis: {
            type: 'value'
        },
        series: handleSeries()
    };

    return (
        <>
            {data && data?.SixHourBreakdownStolen?.length !== 0 ?
                <div>
                    <ReactECharts option={option} style={{height:'200px'}}/>
                </div>
                :
                <div className={"text-center text-3xl mt-6 mb-1"} style={{color:"red", fontWeight:500}}>No Data</div>
            }
        </>
    );
}

export default SixHourBreakdown;