import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import {getDashboardToken, getToken, logoutUser, RefreshUserToken} from "./auth.service";
import NProgress from 'nprogress'
import process from "process";
import {API_URL} from "./config.service";
const AuthenticatedDashboardAPIReq:AxiosInstance = axios.create();



export let RequestsCounter:number = 0

AuthenticatedDashboardAPIReq.interceptors.request.use((config: AxiosRequestConfig) =>{
    RequestsCounter++;
    NProgress.start()

    config.baseURL = API_URL
    config.withCredentials = false
    config.headers = {
        authToken: getDashboardToken(),
    }
    return config
}, (error: AxiosError):Promise<AxiosError>=>{
    if(--RequestsCounter==0)
        NProgress.done()

    console.error(`[HTTP CLIENT Error (Req)] [${JSON.stringify(error)}]`);
    return Promise.reject(error);
})

AuthenticatedDashboardAPIReq.interceptors.response.use((response: AxiosResponse): AxiosResponse => {
    if(--RequestsCounter==0)
        NProgress.done()

    return response;

}, (error: AxiosError): Promise<AxiosError> =>{
    if(--RequestsCounter==0)
        NProgress.done()
    if(error.response?.status === 401){
        logoutUser();
        window.location.href="/logoff"
        return Promise.reject(error)
    }else{
        console.error(error);
        return Promise.reject(error)
    }
})

const calculatePercentage = (loaded:number, total:number) => (Math.floor(loaded) / total)
const setupUpdateProgress = () => {
    const update = (e:any) => NProgress.inc(calculatePercentage(e.loaded, e.total))
    AuthenticatedDashboardAPIReq.defaults.onDownloadProgress = update
    AuthenticatedDashboardAPIReq.defaults.onUploadProgress = update
}
setupUpdateProgress();

export default AuthenticatedDashboardAPIReq