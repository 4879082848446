import {PersonInfo} from "../../../interfaces/getDarInfo_interface";
import React from "react";
import { Disclosure } from '@headlessui/react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { PrisonerReleaseInfo } from "../../../interfaces/PrisonerReleaseInfo.interface";
import { TablePagination, CircularProgress, Button } from "@mui/material";
import PrisonReleaseIcon from "../../../assets/Images/jail1.png";
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search, SortSettingsModel
} from '@syncfusion/ej2-react-grids';
import {Link} from "react-router-dom";

interface PanelPrisonProps {
    person: PersonInfo,
    PrisonerReleases: PrisonerReleaseInfo[],
    isLoading:boolean
}

const  PanelPrison: React.FC<PanelPrisonProps> = ({person,PrisonerReleases,isLoading})=> {
    //For Pagination
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [FormatData, setFormatData] = React.useState(0);

    const handleDataTyping = () => {
        let tempP: any = [...PrisonerReleases]
        for (let p of tempP) {
            if (p.Admission_Date) {
                p.Admission_Date = new Date(p.Admission_Date)
            }
            if (p.RELEASE_DATE) {
                p.RELEASE_DATE = new Date(p.RELEASE_DATE)
            }
        }
        setFormatData(tempP)
    }

    let sortSettings: SortSettingsModel = { columns: [
            {field: 'Admission_Date', direction: 'Descending' }, {field: 'STATUS', direction: 'Descending' }
        ] };

    React.useEffect(() => {
        handleDataTyping()
    }, [PrisonerReleases])

    return(
        <div className={"m-5 border"}>
            <Disclosure>
                {({ open }) => (
                    <>
                       <Disclosure.Button className="bg-blue-100 p-2 w-auto w-full text-left">
                            <span style={{ "display": "flex", "justifyContent": "space-between" }}>
                                <span>
                                    <KeyboardArrowDownIcon
                                        className={`${open ? "transform rotate-180" : ""}`}
                                    />
                                    <span className={"font-bold"}>Prison <img src={PrisonReleaseIcon} alt={''} style={{ display: 'inline' }} /> {(isLoading) ? <CircularProgress size={20} style={{"marginLeft" : "5px"}} /> : <></>}</span>
                                </span>
                                <span className={"font-bold mr-2"} >Name: {person?.FName?.toUpperCase()} {person?.MName?.toUpperCase()} {person?.LName?.toUpperCase()} &nbsp;&nbsp;Records: {PrisonerReleases?.length}</span>
                            </span>
                       </Disclosure.Button>
                    
                        <Disclosure.Panel className="text-gray-500 p-4">
                            <GridComponent
                                dataSource={FormatData}
                                allowPaging={true}
                                pageSettings={{pageSize: 50, pageSizes: [10,25,50,100]}}
                                allowSorting={true}
                                sortSettings={sortSettings}
                                allowMultiSorting={true}
                                allowFiltering={true}
                                filterSettings={{type: 'CheckBox'}}
                                allowTextWrap={true}
                                textWrapSettings={{ wrapMode: 'Both' }}
                                rowHeight={24}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective field='Admission_Date' headerText='Admission Date' format={{ type: 'date', format: 'MM/dd/yyyy' }}/>
                                    <ColumnDirective field='RELEASE_DATE' headerText='Release Date' format={{ type: 'date', format: 'MM/dd/yyyy' }}/>
                                    <ColumnDirective field='STATUS' headerText='Status'/>
                                    {/*<ColumnDirective field='CONVICTION_County' headerText='Conviction County'/>*/}
                                    <ColumnDirective field='STATUTE' headerText='Statute'/>
                                    <ColumnDirective field='STATUTEDESCRIPTION' headerText='Statute Description'/>
                                    {/*<ColumnDirective field='BOOKING_NO' headerText='Booking #'/>
                                    <ColumnDirective field='CASELOAD' headerText='Case Load'/>
                                    <ColumnDirective field='MOVEMENT_REASON_CODE' headerText='Movement Reason Code'/>
                                    <ColumnDirective field='Parole_supr' headerText='Parole Supr'/>*/}
                                    <ColumnDirective template={(item : any ) => (<Button onClick={ () => {window.open('/detail/PrisonRelease?ID=' + item?.ID + '&Dept=' + item?.INM, '_blank') }} >View</Button>)}  headerText='View' />
                                    {/* <ColumnDirective template={(item : any ) => (<Button onClick={ () => {console.log(item) }} >View</Button>)}  headerText='View' /> */}
                                   </ColumnsDirective>
                                <Inject services={[Sort, Page, Filter]}/>
                            </GridComponent>
                            
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>
        
    )
};

export default PanelPrison;