import React from 'react'

import {
    Button,
    Tooltip
} from "@mui/material";

import BTPersonReport from "../../shared/_BTPersonReport";
import {
    ColumnDirective,
    ColumnsDirective,
    ColumnMenu,
    GridComponent,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search, ExcelExportProperties,
    Resize, Reorder, SortSettingsModel
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import CodisPerson from './_searchResultsCodisPerson'
import CodisCaseDisplay from './_searchResultsCodisCase'
import {OpenCodisPDF} from "../../../services/detail.service";
import BTPersonIndicatorGrid from "../../shared/_PersonIndicators";
import {GetStringNameForSite} from "../../../interfaces/config.interface";
import {SiteName} from "../../../services/config.service";
interface SearchResultsProps {
    CodisHit: any[],
    CodisCase: any[],
    Persons: any[]
}

const SearchResults: React.FC<SearchResultsProps> = ({ CodisHit, CodisCase, Persons }) => {
    const [FormatData, setFormatData] = React.useState(0);

    const handleDataTyping = () => {
        if(CodisHit) {
            let tempP: any = [...CodisHit]
            for (let p of tempP) {
                if (p.CreateDate) {
                    p.CreateDate = new Date(p.CreateDate)
                }
                if (p.DateTimeIN) {
                    p.DateTimeIN = new Date(p.DateTimeIN)
                }
            }
            setFormatData(tempP)
        }
    }

    React.useEffect(() => {
        handleDataTyping()
    }, [CodisHit])

    let grid: Grid | null;
    const IndicatorTemplate = (person: any): any => {
        return (
            <BTPersonIndicatorGrid person={person} />
        );
    }

    const getPersonSummary = (p:any) =>
    {
        return (<BTPersonReport person={p}/>)
    }
    
    const getCODISDetailRow = (codisHit:any) =>
    {
        let CodisCaseInfo = CodisCase?.filter((x)=> x.HitNo === codisHit.HitNo)
        let PersonsInfo = Persons?.filter((x)=> x.HitNo === codisHit.HitNo)
        return (
            <div>
                <CodisCaseDisplay CodisCase={CodisCaseInfo} />
                <CodisPerson CodisPerson={PersonsInfo} />
            </div>
        )
    }
    
    const GridToolsClick = (args: ClickEventArgs) => {
        if (grid && args.item.text === "Excel Export") {
            let excelProp:ExcelExportProperties = {}
            excelProp.fileName = 'CODIS.xlsx'
            grid.excelExport(excelProp);
        }
    }

    const expandRow = () => {

        if (grid) {
            const selectedrowindex: number[] = grid.getSelectedRowIndexes()
            for (let idx of selectedrowindex) {

                grid.detailRowModule.expand(idx)
            }
        }
    }

    let sortSettings: SortSettingsModel = { columns: [
            {field: 'CreateDate', direction: 'Descending' }
        ] };
    
    return (
        <div className={"pl-10 pr-10 mt-5"}>
            <span style={{ display: "inline-block" }} className='mb-5'>
                <h4 className={"font-bold inline mr-5 text-lg"}> {CodisHit?.length} Records Found</h4>
            </span>
            {CodisHit && CodisHit?.length > 0 ? <>
            <GridComponent
                dataSource={FormatData}
                allowPaging={true}
                pageSettings={{pageSize: 25, pageSizes: [10,25,50,100]}}
                allowSorting={true}
                sortSettings={sortSettings}
                allowMultiSorting={true}
                allowFiltering={true}
                filterSettings={{type: 'CheckBox'}}
                detailTemplate={getCODISDetailRow}
                allowTextWrap={true}
                textWrapSettings={{ wrapMode: 'Both' }}
                allowExcelExport={true}
                allowPdfExport={true}
                toolbar={['ExcelExport','Search']}
                toolbarClick={GridToolsClick}
                ref={g=> grid = g}
                selectionSettings={{
                    persistSelection: true,
                    type: "Multiple",
                    //checkboxOnly: true,
                    mode: "Row"}}
                //rowSelecting={(event: any) => { console.log(event) }}
                rowSelected={(event: any) => { expandRow() }}
                allowSelection={true}
                allowResizing={true}
                rowHeight={24}
            >
                <ColumnsDirective>
                    <ColumnDirective field='CreateDate' headerText='Entry Date' format={{ type: 'date', format: 'MM/dd/yyyy' }} width={140} minWidth={140} maxWidth={140}  customAttributes={{ class: ['e-attr']}}/>
                    <ColumnDirective field='DateTimeIN' headerText='Report Date' format={{ type: 'date', format: 'MM/dd/yyyy' }} width={140} minWidth={140} maxWidth={140}  customAttributes={{ class: ['e-attr']}}/>
                    <ColumnDirective field='HitNo' headerText='Hit #' width={140} minWidth={140} maxWidth={140}  customAttributes={{ class: ['e-attr']}}/>
                    <ColumnDirective field='CODISAgency' headerText='Agency' width={140} minWidth={140} maxWidth={140}  customAttributes={{ class: ['e-attr']}}/>
                    <ColumnDirective field='CODISCounty' headerText={`${GetStringNameForSite(SiteName,'COUNTY')}`} width={140} minWidth={140} maxWidth={140}  customAttributes={{ class: ['e-attr']}}/>
                    <ColumnDirective field='People' headerText='People' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='Offense' headerText='Offense' width={140} minWidth={140} maxWidth={140}  customAttributes={{ class: ['e-attr']}}/>
                    <ColumnDirective template={IndicatorTemplate} headerText='Indicator' width={140} minWidth={140} maxWidth={140}  customAttributes={{ class: ['e-attr']}}/>
                    <ColumnDirective template={(item: any) => (<Button onClick={() => { OpenCodisPDF(item.FileName,'ALL') /**/ }} >{item.FileName} </Button>)} headerText='File Name' width={140} minWidth={140} maxWidth={140}  customAttributes={{ class: ['e-attr']}}/>
                  </ColumnsDirective>
                <Inject services={[ColumnMenu, Sort, Page, Filter, DetailRow, Toolbar, ExcelExport,PdfExport,Search, Resize, Reorder]}/>
                </GridComponent>
            </> : <></>}
        </div>

    )
};

export default SearchResults
