import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import React from "react";
import { DropDown } from "../../../interfaces/NJPORT/InvestigationLog.interface";
import { ArrestObject } from "../../../interfaces/NJPORT/arrest.interface";
import { InvestigationDropDown } from "../../../services/njport.service";
import { formatDateStringForTextField } from "../../utilities/basics";

interface InputFormProps {
  handleReportSubmit: any;
  editReport: ArrestObject | null;
}

const ArrestInputForm: React.FC<InputFormProps> = ({
  handleReportSubmit,
  editReport,
}) => {
  const [report, setReport] = React.useState<ArrestObject>({});
  const [invStatusDD, setInvStatusDD] = React.useState<DropDown[]>([]);
  const [jobTitleDD, setJobTitleDD] = React.useState<DropDown[]>([]);
  const [pacCardDD, setPacCardDD] = React.useState<DropDown[]>([]);
  const [arrestDispoDD, setarrestDispoDD] = React.useState<DropDown[]>([]);
  const [arrestTypeDD, setarrestTypeDD] = React.useState<DropDown[]>([]);
  const [alertOpen, setAlertOpen] = React.useState<boolean>(false);
  const [alertText, setAlertText] = React.useState<String>("");
  const [alertType, setAlertType] = React.useState<any>("success");
  const [defaultstatus, setDefaultStatus] = React.useState<string>("");
  const [defaultjobTilte, setDefaultjobTilte] = React.useState<string>("");
  const [defaultpacCard, setDefaultpacCard] = React.useState<string>("");

  React.useEffect(() => {
    invStatusDropdown();
    jobTitleDropdown();
    arrestDispoDropdown();
    arrestTypeDropdown();
    if (editReport) {
      console.log(editReport);
      setReport(editReport);
    }

    defaultStatusDD(invStatusDD);
    defaultjobTilteDD(jobTitleDD);
    defaultarrestDispoDD(arrestDispoDD);
    defaultarrestTypeDD(arrestTypeDD);
    // defaultpacCardDD(pacCardDD);
  }, []);

  const defaultStatusDD = (d: DropDown[]) => {
    let v: string = d
      ? d.find((d) => d.Key === editReport?.Investigation_Status)?.Value!
      : "";
    setDefaultStatus(v);
  };

  const defaultjobTilteDD = (d : DropDown[]) => {
    let v: string = d ? d.find(d => d.Key === editReport?.Job_Title)?.Value!:''
    setDefaultjobTilte(v);
  }

  const defaultarrestDispoDD = (d : DropDown[]) => {
    let v: string = d ? d.find(d => d.Key === editReport?.Dispo)?.Value!:''
    setDefaultjobTilte(v);
  }

  const defaultarrestTypeDD = (d : DropDown[]) => {
    let v: string = d ? d.find(d => d.Key === editReport?.Arrest_Type)?.Value!:''
    setDefaultjobTilte(v);
  }


  const handleValueChange = (field: string, val: any) => {
    setReport({ ...report, [field]: val });
  };

  const handleSubmit = async () => {
    let data = report;
    if (editReport === null) data.EditStatus = "ADD";
    else data.EditStatus = "EDIT";
    if (!handleValidation()) handleReportSubmit(data);
    // if (!handleValidation()) console.log(data)
  };

  const handleValidation = () => {
    let flag = false;
    let text = "";
    let temp = { ...report };
    if (temp) {
      if (!temp.CaseNo || temp.CaseNo.trim().length === 0) {
        flag = true;
        text = "Case No";
      } else if (
        !temp.InvestigatorName ||
        temp.InvestigatorName.trim().length === 0
      ) {
        flag = true;
        text = "Investigator Name";
      } else if (!temp.Date_Arrested) {
        flag = true;
        text = "Date Arrested";
      } else if (
        !temp.Victim_FirstName ||
        temp.Victim_FirstName.trim().length === 0
      ) {
        flag = true;
        text = "Victim First Name";
      } else if (!temp.Victim_MiddleName) {
        flag = true;
        text = "Victim Middle Name";
      } else if (
        !temp.Victim_LastName ||
        temp.Victim_LastName.trim().length === 0
      ) {
        flag = true;
        text = "Victim Last Name";
      } else if (!temp.Victim_DOB) {
        flag = true;
        text = "Victim Date of Birth";
      } else if (!temp.Victim_SSN || temp.Victim_SSN.trim().length === 0) {
        flag = true;
        text = "Victim SSN";
      } else if (!temp.Job_Code || temp.Job_Code.trim().length === 0) {
        flag = true;
        text = "Job Code";
      } else if (
        !temp.Accused_FirstName ||
        temp.Accused_FirstName.trim().length === 0
      ) {
        flag = true;
        text = "Accused First Name";
      } else if (
        !temp.Accused_MiddleName ||
        temp.Accused_MiddleName.trim().length === 0
      ) {
        flag = true;
        text = "Accused Middle Name";
      } else if (
        !temp.Accused_LastName ||
        temp.Accused_LastName.trim().length === 0
      ) {
        flag = true;
        text = "Accused Last Name";
      } else if (!temp.Accused_DOB) {
        flag = true;
        text = "Accused Date of Birth";
      } else if (!temp.Accused_SSN || temp.Accused_SSN.trim().length === 0) {
        flag = true;
        text = "Accused SSN";
      } else if (!temp.Address || temp.Address.trim().length === 0) {
        flag = true;
        text = "Address";
      } else if (!temp.City || temp.City.trim().length === 0) {
        flag = true;
        text = "City";
      } else if (!temp.Dispo || temp.Dispo.trim().length === 0) {
        flag = true;
        text = "Disposition";
      } else if (!temp.NCIC_Alarm || temp.NCIC_Alarm.trim().length === 0) {
        flag = true;
        text = "NCIC Alarm";
      } else if (!temp.Arrest_Type || temp.Arrest_Type.trim().length === 0) {
        flag = true;
        text = "Arrest Type";
      }
      if (flag) {
        setAlertText("Please complete Mandatory fields" + text);
        setAlertOpen(true);
        setAlertType("error");
      }
    }
    return flag;
  };

  const invStatusDropdown = async () => {
    let data = {
      params: {
        DropDownName: "PORT_INVSTATUS",
        State: "NJ",
      },
    };
    let response: DropDown[] = await InvestigationDropDown(data);
    setInvStatusDD(response);
    defaultStatusDD(response);
  };

  const jobTitleDropdown = async () => {
    let data = {
      params: {
        DropDownName: "PORT_JOBCODE",
        State: "NJ",
      },
    };
    let response: DropDown[] = await InvestigationDropDown(data);
    setJobTitleDD(response);
    defaultjobTilteDD(response);
  };


  const arrestDispoDropdown = async () => {
    let data = {
      params: {
        DropDownName: "PORT_ARRESTDISPO",
        State: "NJ",
      },
    };
    let response: DropDown[] = await InvestigationDropDown(data);
    setarrestDispoDD(response);
    defaultarrestDispoDD(response);
  };


  const arrestTypeDropdown = async () => {
    let data = {
      params: {
        DropDownName: "PORT_ARRESTTYPE",
        State: "NJ",
      },
    };
    let response: DropDown[] = await InvestigationDropDown(data);
    setarrestTypeDD(response);
    defaultarrestTypeDD(response);
  };


  
  return (
    <Box className="pt-1" sx={{ flexGrow: 1 }}>
      <div className=" border-2 border-gray-600 mx-4 mt-4">
        <div className="border-b-2 border-gray-600 bg-blue-300 h-2/12 font-bold text-center text-xl">
          Case
        </div>
        <div className="my-3">
          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Case No"
                value={report.CaseNo}
                onChange={(e) => handleValueChange("CaseNo", e.target.value)}
                error={report.CaseNo ? false : true}
                helperText={report.CaseNo ? "" : "Required"}
              />
            </Grid>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Investigator Name"
                value={report.InvestigatorName}
                onChange={(e) =>
                  handleValueChange("InvestigatorName", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                type="date"
                label="Date Arrested"
                value={report.Date_Arrested}
                InputLabelProps={{ shrink: true }}
                onChange={(e) =>
                  handleValueChange("Date_Arrested", e.target.value)
                }
                error={report.Date_Arrested ? false : true}
                helperText={report.Date_Arrested ? "" : "Required"}
              />
            </Grid>
          </Grid>
        </div>
      </div>

      <div className=" border-2 border-gray-600 mx-4 mt-4">
        <div className="border-b-2 border-gray-600 bg-blue-300 h-2/12 font-bold text-center text-xl">
          Victim
        </div>
        <div className="my-3">
          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Victim First Name"
                value={report.Victim_FirstName}
                onChange={(e) =>
                  handleValueChange("Victim_FirstName", e.target.value)
                }
                error={report.Victim_FirstName ? false : true}
                helperText={report.Victim_FirstName ? "" : "Required"}
              />
            </Grid>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Victim Middle Name"
                value={report.Victim_MiddleName}
                onChange={(e) =>
                  handleValueChange("Victim_MiddleName", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Victim Last Name"
                value={report.Victim_LastName}
                onChange={(e) =>
                  handleValueChange("Victim_LastName", e.target.value)
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
            <Grid item xs={6} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                type="date"
                label="DOB"
                value={report.Victim_DOB}
                InputLabelProps={{ shrink: true }}
                onChange={(e) =>
                  handleValueChange("Victim_DOB", e.target.value)
                }
              />
            </Grid>

            <Grid item xs={6} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="SSN"
                value={report.Victim_SSN}
                onChange={(e) =>
                  handleValueChange("Victim_SSN", e.target.value)
                }
              />
            </Grid>
          </Grid>
        </div>
      </div>

      <div className=" border-2 border-gray-600 mx-4 mt-4">
        <div className="border-b-2 border-gray-600 bg-blue-300 h-2/12 font-bold text-center text-xl">
          Job
        </div>
        <div className="my-3">
          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
            <Grid item xs={6} sx={{ pr: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="jobTitle-Label">Job Title</InputLabel>
                <Select
                  labelId="jobTitle-Label"
                  id="jobTitle-select"
                  value={defaultjobTilte}
                  label="JobTitle"
                  renderValue={() => defaultjobTilte}
                  onChange={(e: any) =>
                    handleValueChange("Job_Code", e.target.value)
                  }
                  error={report.Job_Code ? false : true}
                >
                  {jobTitleDD.map((d) => (
                    <MenuItem key={d.Key} value={d.Key}>
                      {d.Value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      </div>

      <div className=" border-2 border-gray-600 mx-4 mt-4">
        <div className="border-b-2 border-gray-600 bg-blue-300 h-2/12 font-bold text-center text-xl">
          Accused
        </div>
        <div className="my-3">
          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Accused First Name"
                value={report.Accused_FirstName}
                onChange={(e) =>
                  handleValueChange("Accused_FirstName", e.target.value)
                }
                error={report.Accused_FirstName ? false : true}
                helperText={report.Accused_FirstName ? "" : "Required"}
              />
            </Grid>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Accused Middle Name"
                value={report.Accused_MiddleName}
                onChange={(e) =>
                  handleValueChange("Accused_MiddleName", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Accused Last Name"
                value={report.Accused_LastName}
                onChange={(e) =>
                  handleValueChange("Accused_LastName", e.target.value)
                }
                error={report.Accused_LastName ? false : true}
                helperText={report.Accused_LastName ? "" : "Required"}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
            <Grid item xs={6} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                type="date"
                label="DOB"
                value={report.Accused_DOB}
                InputLabelProps={{ shrink: true }}
                onChange={(e) =>
                  handleValueChange("Accused_DOB", e.target.value)
                }
                error={report.Accused_DOB ? false : true}
                helperText={report.Accused_DOB ? "" : "Required"}
              />
            </Grid>

            <Grid item xs={6} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="SSN"
                value={report.Accused_SSN}
                onChange={(e) =>
                  handleValueChange("Accused_SSN", e.target.value)
                }
              />
            </Grid>
          </Grid>
        </div>
      </div>

      <div className=" border-2 border-gray-600 mx-4 mt-4">
        <div className="border-b-2 border-gray-600 bg-blue-300 h-2/12 font-bold text-center text-xl">
          PAC
        </div>
        <div className="my-3">
          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Address"
                value={report.Address}
                onChange={(e) => handleValueChange("Address", e.target.value)}
              />
            </Grid>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="City"
                value={report.City}
                onChange={(e) => handleValueChange("City", e.target.value)}
              />
            </Grid>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="NCIC Alarm"
                value={report.NCIC_Alarm}
                onChange={(e) =>
                  handleValueChange("NCIC_Alarm", e.target.value)
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
            <Grid item xs={6} sx={{ pr: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="dispo-Label">Dispo</InputLabel>
                <Select
                  labelId="dispo-Label"
                  id="dispo-select"
                  value={report.Dispo}
                  label="Dispo"
                  onChange={(e: any) =>
                    handleValueChange("Dispo", e.target.value)
                  }
                >
                  {arrestDispoDD.map((d) => (
                    <MenuItem key={d.Key} value={d.Key}>
                      {d.Value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sx={{ pr: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="arrestType-Label">Arrest Type</InputLabel>
                <Select
                  labelId="arrestType-Label"
                  id="arrestType-select"
                  value={report.Arrest_Type}
                  label="ArrestType"
                  onChange={(e: any) =>
                    handleValueChange("Arrest_Type", e.target.value)
                  }
                >
                  {arrestTypeDD.map((d) => (
                    <MenuItem key={d.Key} value={d.Key}>
                      {d.Value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      </div>

      <Snackbar
        open={alertOpen}
        autoHideDuration={7000}
        onClose={() => {
          setAlertOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setAlertOpen(false);
          }}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {alertText}
        </Alert>
      </Snackbar>
      <Grid container spacing={1} sx={{ pl: 2, mt: 2, justifyContent: 'flex-end' }}>
        <Grid item xs={8} sx={{ pr: 2 }}></Grid>
        <Grid item xs={2} sx={{ pr: 2 }}>
          <ButtonGroup fullWidth size="large" color="inherit">
            <Button type="submit" onClick={handleSubmit}>
              Submit
            </Button>
            <Button>CLEAR</Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ArrestInputForm;
