import {PersonInfo} from "../../../interfaces/getDarInfo_interface";
import React from "react";
import { Disclosure } from '@headlessui/react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CADRMSInfo from "../../../interfaces/CADRMSInfo.interface";
import { TablePagination, CircularProgress, Button } from "@mui/material";
import CADRMSIcon from "../../../assets/Images/Opencase1.png";
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent ,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search
} from '@syncfusion/ej2-react-grids';
import {Link} from "react-router-dom";
import { GetStringNameForSite } from "../../../interfaces/config.interface";
import { SiteName } from "../../../services/config.service";

interface PanelCADRMSProps {
    person: PersonInfo,
    CADRMSCases: CADRMSInfo[],
    isLoading:boolean
}

const  PanelCADRMS: React.FC<PanelCADRMSProps> = ({person,CADRMSCases,isLoading})=> {
    const [FormatData, setFormatData] = React.useState(0);

    const handleDataTyping = () => {
        let tempP: any = [...CADRMSCases]
        for (let p of tempP) {
            if (p.DateTimeIn) {
                p.DateTimeIn = new Date(p.DateTimeIn)
            }
        }
        setFormatData(tempP)
    }

    React.useEffect(() => {
        handleDataTyping()
    }, [CADRMSCases])

    return (
        <div className={"m-5 border"}>
            <Disclosure>
                {({ open }) => (
                    <>
                        <Disclosure.Button className="bg-blue-100 p-2 w-auto w-full text-left">
                            <span style={{ "display": "flex", "justifyContent": "space-between" }}>
                                <span>
                                    <KeyboardArrowDownIcon
                                        className={`${open ? "transform rotate-180" : ""}`}
                                    />
                                    <span className={"font-bold"}>{GetStringNameForSite(SiteName, "CAD/RMS")} Information <img src={CADRMSIcon} alt={''} style={{ display: 'inline' }} /> {(isLoading) ? <CircularProgress size={20} style={{"marginLeft" : "5px"}} /> : <></>}</span>
                                </span>
                                <span className={"font-bold mr-2"} >Name: {person?.FName?.toUpperCase()} {person?.MName?.toUpperCase()} {person?.LName?.toUpperCase()} &nbsp;&nbsp;Records: {CADRMSCases?.length}</span>
                            </span>
                        </Disclosure.Button>

                        <Disclosure.Panel className="text-gray-500 p-4">

                            <GridComponent
                                dataSource={FormatData}
                                allowPaging={true}
                                pageSettings={{pageSize: 50, pageSizes: [10,25,50,100]}}
                                allowSorting={true}
                                allowMultiSorting={true}
                                allowFiltering={true}
                                filterSettings={{type: 'CheckBox'}}
                                allowTextWrap={true}
                                textWrapSettings={{ wrapMode: 'Both' }}
                                rowHeight={28}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective field='DateTimeIn' headerText='Incident Date' format={{ type: 'dateTime', format: 'MM/dd/yyyy HH:mm' }}/>
                                    <ColumnDirective field='AgencyCaseNo' headerText={GetStringNameForSite(SiteName,"AGENCY CASE #")}/>
                                    <ColumnDirective field='CaseStatus' headerText='Case Status'/>
                                    <ColumnDirective field='RoleDesc' headerText='Role'/>
                                    <ColumnDirective field='NIBRSDesc' headerText='Incident Type(NIBRS)' width={200}/>
                                    <ColumnDirective field='STATUTE' headerText={GetStringNameForSite(SiteName,'Statute')}/>
                                    <ColumnDirective field='STATUTEDESCRIPTION' headerText={GetStringNameForSite(SiteName,'Statute') + ' Desc'} />
                                    <ColumnDirective field='IncidentAddress' headerText='Incident Address' />
                                    <ColumnDirective field='Latitude' headerText='Latitude' visible={false} />
                                    <ColumnDirective field='Longitude' headerText='Longitude' visible={false} />
                                    <ColumnDirective template={(item : any ) => (<Button onClick={ () => {window.open('/detail/CadRms?ID=' + item?.OpenCaseID + '&Dept=' + item?.AgencyCaseNo, '_blank') }} >View</Button>)}  headerText='View' />
                                    {/* <ColumnDirective template={(item : any ) => (<Button onClick={ () => {console.log(item) }} >View</Button>)}  headerText='View' /> */}

                                    {/*<ColumnDirective template={(item: any) => (<>({item.Latitude}, {item.Longitude})</>)} headerText='MSP (Latitude, Longitude)' visible={false} />*/}
                                </ColumnsDirective>
                                <Inject services={[Sort, Page, Filter]}/>
                            </GridComponent>

                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>

    )
};

export default PanelCADRMS;