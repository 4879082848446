import React, { useState, useEffect } from "react";
import { TextField, Checkbox, Button, ToggleButtonGroup, ToggleButton, Card, CardContent, TableContainer, Table, TableBody, TableRow, TableCell, Paper, Dialog, DialogTitle, DialogContent, CircularProgress, IconButton, Typography } from "@mui/material";
import ALPRDetail from "../../interfaces/Detail/ALPRDetail.interface"
import { RunViolentDetailQuery } from "../../services/detail.service";
import { GetALPRReportPDF } from "../../services/getAlpr.service";
import { formatDate } from "../../services/formatDate.service"
import CloseIcon from '@mui/icons-material/Close';

import './Detail.css'
import { JAOS } from "../../services/JAOS.service";

interface DetailProps {
    ID: string | null
    Dept: string | null
    maxRecords: string | null
}


const ALPRPDFReportInfo: React.FC<DetailProps> = ({ ID = null, Dept = null, maxRecords = null }) => {
    const [DataLoaded, setDataLoaded] = React.useState<boolean>(false)
    const [Data, setData] = React.useState<ALPRDetail>({})
    const [reportUrl, setReportUrl] = React.useState<string | undefined>(undefined);

    const loadData = async () => {
        
        if (!DataLoaded) {
            setDataLoaded(true)
            if(ID && ID?.trim().length > 0){
                let l = new JAOS()
                let ListID = l.objFromStack(ID);
                let PdfUrl= await GetALPRReportPDF(ListID, ';STAGING')                          
                if (PdfUrl){
                setReportUrl(PdfUrl);
                }
            }
        }
    }

    React.useEffect(() => {
        if (!DataLoaded) {
            setDataLoaded(true)
            loadData()
        }
    });

    return (
        <div className={"pl-10 pr-10 mt-5"}>
            {ID && ID?.length > 0 ?
            <Card>
                    {maxRecords && maxRecords.length > 0 ?
                        <Typography gutterBottom variant="h5" component="div">
                            Vehicle Observation Report (Showing Max Records {maxRecords})
                        </Typography>
                        :
                        <Typography gutterBottom variant="h5" component="div">
                            Vehicle Observation Report
                        </Typography>
                    }
                <CardContent>
                    {reportUrl && reportUrl !== undefined ? 
                        <>
                            <iframe src={reportUrl} style={{'width': '-webkit-fill-available', 'height': '900px'}}>
                            </iframe>
                        </>
                        :<><CircularProgress /></>}            
                </CardContent>
                
            </Card>                       
            :
            <Card>
                <Typography sx={{fontSize:25}}>
                    Report Unavailable
                </Typography>    
            </Card>}
        </div>
    );
}

export default ALPRPDFReportInfo;