import React, {useState} from "react";
import {useLocation} from "react-router-dom";
import UpdatePOI from "../../components/Admin/UpdatePOI";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function AddPOI() {
    let [personID,setPersonID] = React.useState<string>("0")
    let query = useQuery();

    //when this gets called grab from query?



    return (
        <div className="w-full">
            <UpdatePOI ID={"0"} CloseBox = {() => {window.close()}} Hash={query.get("Hash")}/>
        </div>
    );
}

export default AddPOI;