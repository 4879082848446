import {PersonInfo} from "../../../interfaces/getDarInfo_interface";
import React from "react";
import { Disclosure } from '@headlessui/react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {ShootingInfo} from "../../../interfaces/ShootingInfo.interface";
import { TablePagination, CircularProgress, Button } from "@mui/material";
import icon_njpop from "../../../assets/Images/Shooting.png"
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent ,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search
} from '@syncfusion/ej2-react-grids';
import {OpenNJPOPPDF} from "../../../services/detail.service";
import { iconButtonClasses } from "@mui/material";
import {SiteName} from "../../../services/config.service";
import {GetStringNameForSite, SITE} from "../../../interfaces/config.interface";

interface PanelShootingProps {
    person: PersonInfo,
    ShootingInfos: ShootingInfo[],
    isLoading:boolean
}

const PanelShooting: React.FC<PanelShootingProps> = ({ person, ShootingInfos, isLoading }) => {
    const [FormatData, setFormatData] = React.useState(0);

    const handleDataTyping = () => {
        let tempP: any = [...ShootingInfos]
        for (let p of tempP) {
            if (p.DateTimeIn) {
                p.DateTimeIn = new Date(p.DateTimeIn)
            }
        }
        setFormatData(tempP)
    }

    React.useEffect(() => {
        handleDataTyping()
    }, [ShootingInfos])

    return(
        <div className={"m-5 border"}>
            <Disclosure>
                {({ open }) => (
                    <>
                        <Disclosure.Button className="bg-blue-100 p-2 w-auto w-full text-left">
                            <span style={{ "display": "flex", "justifyContent": "space-between" }}>
                                <span>
                                    <KeyboardArrowDownIcon
                                        className={`${open ? "transform rotate-180" : ""}`}
                                    />
                                    <span className={"font-bold"}>{(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL)? 'DC Gun Violence':'NJ POP'}  <img src={icon_njpop} alt={''} style={{ display: 'inline' }} /> {(isLoading) ? <CircularProgress size={20} style={{"marginLeft" : "5px"}} /> : <></>}</span>
                                </span>
                                <span className={"font-bold mr-2"} >Name: {person?.FName?.toUpperCase()} {person?.MName?.toUpperCase()} {person?.LName?.toUpperCase()} &nbsp;&nbsp;Records: {ShootingInfos?.length}</span>
                            </span>
                        </Disclosure.Button>

                        <Disclosure.Panel className="text-gray-500 p-4">
                            <GridComponent
                                dataSource={FormatData}
                                allowPaging={true}
                                pageSettings={{pageSize: 50, pageSizes: [10,25,50,100]}}
                                allowSorting={true}
                                allowMultiSorting={true}
                                allowFiltering={true}
                                filterSettings={{type: 'CheckBox'}}
                                allowTextWrap={true}
                                textWrapSettings={{ wrapMode: 'Both' }}
                                rowHeight={24}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective field='DateTimeIn' headerText='Incident Date' format={{ type: 'dateTime', format: 'MM/dd/yyyy HH:mm' }}/>
                                    <ColumnDirective field='IncidentType' headerText='Incident Type'/>
                                    <ColumnDirective field='RoleDesc' headerText='Role'/>
                                    <ColumnDirective template={(item:any)=>(<>{item?.W_Type} {item?.W_Class} {item?.W_Status}</>)} headerText='Weapon (Type, Class, Status)'/>
                                    <ColumnDirective template={(item:any)=>(<>{item?.Dept} {item?.AgencyCaseNo}</>)} headerText={'Agency (Dept, ' + GetStringNameForSite(SiteName,'Case #') + ')'}/>
                                    <ColumnDirective field='IncidentAddress' headerText='Incident Address'/>
                                    {(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) &&<ColumnDirective template={(item : any ) => (<Button onClick={ () => {window.open('/detail/CadRms?ID=' + item?.IncidentID + '&Dept=' + item?.IncidentID, '_blank') }} >View</Button>)}  headerText='View' />}
                                    {!(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) &&<ColumnDirective template={(item: any) => (<Button onClick={() => { OpenNJPOPPDF(item.IncidentID) }} >View</Button>)} headerText='View' />}
                                </ColumnsDirective>
                                <Inject services={[Sort, Page, Filter]}/>
                            </GridComponent>
                            
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>

    )
};

export default PanelShooting;