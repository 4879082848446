import React, { useState, useEffect } from "react";
import { TextField, Checkbox, Button, ToggleButtonGroup, ToggleButton, Card, CardContent, TableContainer, Table, TableBody, TableRow, TableCell, Paper, Tooltip } from "@mui/material";
import CadRmsDetail, { CadRmsDetailPersonObject } from "../../interfaces/Detail/CadRmsDetail.interface"
import { RunCadRmsDetailQuery } from "../../services/detail.service";
import { formatDT, formatDate, formatPhone, formatSSN, hideSSN } from "../../services/formatDate.service"

import './Detail.css'
import DetailMap from "./_DetailMap";
import { GetStringNameForSite } from "../../interfaces/config.interface";
import { SiteName } from "../../services/config.service";

interface DetailProps {
    ID: string | null
    department: string | null
}


const CadRmsDetailInfo: React.FC<DetailProps> = ({ ID, department }) => {
    const [DataLoaded, setDataLoaded] = React.useState<boolean>(false)
    const [Data, setData] = React.useState<CadRmsDetail>({})
    const [pin, setPin] = React.useState<any>({})


    const loadData = async () => {
        
        if (!DataLoaded) {
            setDataLoaded(true)
            let res = await RunCadRmsDetailQuery(ID, department);
            setData(res)
            createPin(res)
        }
    }

    React.useEffect(() => {
        if (!DataLoaded) {
            setDataLoaded(true)
            loadData()
        }
    });

    const createPin = async (Data:any) => {
        let tPin:any = {}
        if (Data && Data?.Incident.Latitude && Data?.Incident.Longitude) {
            tPin = ({
                lat: Data?.Incident.Latitude,
                lng: Data?.Incident.Longitude,
                address: Data?.Incident.StreetName + " " + Data?.Incident.StreetName,
                city: Data?.Incident.City,
                statute: Data?.Incident.STATUTE,
                detail:"cadrms"
            })
        }
        setPin(tPin)
    }

    return (
        <div className="bg-gray-100 ml-5 mr-5 rounded p-5 ">
            <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }} >
                <CardContent>
                    <h3 className="mb-4 font-bold font text-xl text-blue-400">Incident Information</h3>                     
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">ID</span><span className ="DetailTableData">{Data.Incident?.ID}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">Incident Type</span><span className ="DetailTableData">{Data.Incident?.IncidentType}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">{GetStringNameForSite(SiteName, "CASE NUMBER") }</span><span className ="DetailTableData">{Data.Incident?.CaseNumber}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">Case Status</span><span className ="DetailTableData">{Data.Incident?.CaseStatus}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">Street Number</span><span className ="DetailTableData">{Data.Incident?.StreetNumber}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">Apartment Number</span><span className ="DetailTableData">{Data.Incident?.AptNo}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">Address</span><span className ="DetailTableData">{Data.Incident?.StreetName}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">City</span><span className ="DetailTableData">{Data.Incident?.City}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">Nibrs ID</span><span className ="DetailTableData">{Data.Incident?.NIBRSID}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">Nibrs Description</span><span className ="DetailTableData">{Data.Incident?.NIBRSDesc}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">Report Date</span><span className ="DetailTableData">{formatDT(Data.Incident?.ReportDate)}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">Statute</span><span className ="DetailTableData">{Data.Incident?.STATUTE}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">Statute Description</span><span className ="DetailTableData">{Data.Incident?.STATUTEDESCRIPTION}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">Vendor</span><span className ="DetailTableData">{Data.Incident?.Vendor}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">Server</span><span className ="DetailTableData">{Data.Incident?.SERVER}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">Latitude</span><span className ="DetailTableData">{Data.Incident?.Latitude}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">Longitude</span><span className ="DetailTableData">{Data.Incident?.Longitude}</span></div>
                    {pin && pin.lat && pin.lng ? <div className="DetailTableRow border-2 border-black"> <DetailMap data={pin} detail="cadrms" /> </div> : <></>}

                </CardContent>
            </Card>
            {Data.Persons && Data.Persons.length > 0 ?
                Data.Persons?.map((Person: CadRmsDetailPersonObject , idx)=>{
                    return(
                        <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                            <CardContent>
                                <h3 className="mb-5 font-bold font text-xl text-blue-400">Person Information</h3>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">ID</span><span className ="DetailTableData">{Person?.ID}</span></div>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">FBI</span><span className ="DetailTableData">{Person?.FBI}</span></div>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">SBI</span><span className ="DetailTableData">{Person?.SBINo}</span></div>
                                {/* <div className ="DetailTableRow" ><span className = "DetailTableLabel">Case Number</span><span className ="DetailTableData">{Person?.CaseNumber}</span></div> */}
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">SBI State</span><span className ="DetailTableData">{Person?.SBIState}</span></div>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">First Name</span><span className ="DetailTableData">{Person?.FIRSTNAME}</span></div>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Middle Name</span><span className ="DetailTableData">{Person?.MI}</span></div>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Last Name</span><span className ="DetailTableData">{Person?.LASTNAME}</span></div>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Suffix</span><span className ="DetailTableData">{Person?.Suffix}</span></div>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Age</span><span className ="DetailTableData">{Person?.Age}</span></div>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">DOB</span><span className ="DetailTableData">{formatDate(Person.DOB)}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">SSN</span><Tooltip placement="left" title={"" + formatSSN(Person?.SSN)}><span className="DetailTableData">{Person?.SSN ? hideSSN(Person?.SSN) : ""}</span></Tooltip></div>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Sex</span><span className ="DetailTableData">{Person?.Sex}</span></div>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Race</span><span className ="DetailTableData">{Person?.Race}</span></div>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Address</span><span className ="DetailTableData">{Person.Address}</span></div>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Phone </span><span className ="DetailTableData">{formatPhone(Person?.Phone)}</span></div>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Cellphone </span><span className ="DetailTableData">{formatPhone(Person?.CPhone)}</span></div>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">DL Number</span><span className ="DetailTableData">{Person?.DLNo}</span></div>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">DL State</span><span className ="DetailTableData">{Person?.DLSt}</span></div>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Role </span><span className ="DetailTableData">{Person?.Role}</span></div>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Role Description </span><span className ="DetailTableData">{Person?.RoleDesc}</span></div>
                            </CardContent>
                            <CardContent>
                                <h3 className="mb-5 font-bold font text-xl text-blue-400">Vehicle Information</h3>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Vehicle Year </span><span className ="DetailTableData">{Person?.VehicleYEAR}</span></div>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Vehicle Make </span><span className ="DetailTableData">{Person?.MAKE}</span></div>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Vehicle Model </span><span className ="DetailTableData">{Person?.MODEL}</span></div>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Color </span><span className ="DetailTableData">{Person?.COLOR}</span></div>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">VIN </span><span className ="DetailTableData">{Person?.VIN}</span></div>
                            </CardContent>
                        </Card>

                    )
                })
                :
                <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                        <CardContent>
                            <h3 className="mb-4 font-bold font text-xl text-blue-400">Person Information</h3>
                        </CardContent>
                        <CardContent>
                            <h3 className="mb-4 font-bold font text-xl text-blue-400">Vehicle Information</h3>
                        </CardContent>
                </Card> 
            }
        </div>
    );
}

export default CadRmsDetailInfo;