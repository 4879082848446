import React, {useState} from "react";
import { OpenReleaseNotesPDF } from "../../services/detail.service";
import { getUser } from "../../services/auth.service";
import { Account} from "../../interfaces/auth_interface";
import {Grid} from "@mui/material";
import GTBMLogo from "../../assets/Images/gtbmClear.png";
import InfoCopLogo from "../../assets/Images/infocop.png";
import Logo from "../../assets/Images/newbacktrace.png";
import {BTVERSION} from "../../services/config.service";

function Footer() {
    const today = new Date();
    const [user, setUser] = React.useState<Account>(getUser())
    
    return (
        <div className={"text-center infocop_footer mt-4"}>
            {/*<img src={in_lawenftoolslogo} className={"mx-auto w-3/12"} alt={""} />*/}
            <Grid container spacing={0}>
                <Grid item xs={2}>
                    <div className = "flex justify-center items-center h-full" >
                        <img src={GTBMLogo} style={{'height':'40px', 'width':'124px'}} alt={'BackTrace'}/>
                    </div>
                </Grid>
                <Grid item xs={8}>
                    <div id="infocop-content">
                        <h5>
                    <span>                        
                        <strong>Info-Cop Law Enforcement Tools&reg;</strong>
                        <p>Our complete line of Law Enforcement software has been deployed to many of our customers increase productivity and stopping criminal activity,
                        whatever your goal is, GTBM has the solution you've been looking for.</p>                        
                    </span>
                        </h5>
                    </div>
                    <div id="disclaimer-content">
                        <h5>
                    <span>                        
                        <strong>DISCLAIMER:&nbsp;</strong>
                        GTBM does not review, endorse or verify any of the report information. Client agrees and acknowledges that it must verify all arrest information
                        with the issuing agency. Client assumes all responsibility for their use of this information.
                        <p><strong>&copy;&nbsp;{today.getFullYear()}&nbsp;GTBM&nbsp;-&nbsp;BackTrace&nbsp;<a className ="text-blue-700" href="#" onClick={() => { OpenReleaseNotesPDF(user.EmpID, user.Dept)}}>(Version&nbsp;{BTVERSION})</a></strong></p>
                    </span>
                        </h5>
                    </div>
                </Grid>
                <Grid item xs={2}>
                    <div className = "flex justify-center items-center h-full inline">
                        <span className = "inline">
                            <img src={InfoCopLogo} style={{'height':'40px', 'width':'124px'}} alt={'BackTrace'}/>
                            <img src={Logo} style={{'height':'50px'}} alt={'BackTrace'}/>  
                        </span>
                        
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default Footer;