import React from 'react'

import {
    Button,
    Tooltip
} from "@mui/material";

import PersonPhotoIcon from "../../../assets/Images/photo1.png";
import ArrestIcon from "../../../assets/Images/ai1.png";
import InfoCopIcon from "../../../assets/Images/ic1.png";
import ETicketIcon from "../../../assets/Images/et1.png";
import ParkMobileIcon from "../../../assets/Images/pm1.png";
import ALPRIcon from "../../../assets/Images/alpr1.png";
import CADRMSIcon from "../../../assets/Images/Opencase1.png";
import CODISIcon from "../../../assets/Images/DNA1.png";
import TowingIcon from "../../../assets/Images/towing1.png";
import PrisonReleaseIcon from "../../../assets/Images/jail1.png";
import GunTraceIcon from "../../../assets/Images/WeaponTrace1.png";
import ShootingIcon from "../../../assets/Images/Shooting.png";
import ShootingSuspectIcon from "../../../assets/Images/ShootingRed.png";
import ShootingVictimIcon from "../../../assets/Images/ShootingGreen.png";
import NibinIcon from "../../../assets/Images/nibin1.png";
import NJGunTraceIcon from "../../../assets/Images/NjGunTrace1.png";
import PrisonParoleInactiveIcon from "../../../assets/Images/parole_Inactive.png";
import PrisonParoleActiveIcon from "../../../assets/Images/parole1.png";
import ViolentIcon from "../../../assets/Images/Violent1.png";
import HumanTrafficIcon from "../../../assets/Images/HumanTraffic1.png";
import ProfesionIcon from "../../../assets/Images/profession1.png";
import BTPersonReport from "../../shared/_BTPersonReport";
import OpencaseIcon from "../../../assets/Images/Opencase.png";
import {
    ColumnDirective,
    ColumnsDirective,
    ColumnMenu,
    GridComponent,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search, ExcelExportProperties,
    Resize, Reorder, SortSettingsModel
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import {GetStringNameForSite} from "../../../interfaces/config.interface";
import {SiteName} from "../../../services/config.service";

interface SearchResultsProps {
    CodisCase: any[],
}

const CodisCaseDisplay: React.FC<SearchResultsProps> = ({ CodisCase }) => {
    const [typedCase,setTypedCases] = React.useState<any>({})
    let grid: Grid | null;

    const handleDataTyping = () => {
        if (CodisCase) {
            let tempP: any = [...CodisCase]

            for (let p of tempP) {
                if (p.OffenseDate) {
                    p.OffenseDate = new Date(p.OffenseDate)
                }
            }
            setTypedCases(tempP)
        }

    }

    React.useEffect(() => {
        handleDataTyping()
    }, [CodisCase])

    const GridToolsClick = (args: ClickEventArgs) => {
        if (grid && args.item.text === "Excel Export") {
            grid.excelExport();
        }
    }

    const dataBound = () => {
        if (grid) {
            grid.autoFitColumns([])
        }
    }
    
    let sortSettings: SortSettingsModel = { columns: [
        {field: 'OffenseDate', direction: 'Descending' }
    ] };

    return (
        <div className={"pl-10 pr-10 mt-5 border-2 border-gray-100 pb-5 pt-5"}>
            <span style={{display:"inline-block", width:"full"}}>
                CODIS Case Information <img className={"inline ml-2"} src={OpencaseIcon} alt="ai" />
                {(CodisCase && CodisCase.length > 0) ? <h4 className={"font-bold pl-5"}> {CodisCase.length} Records Found</h4> : <h4 className={"font-bold pl-5"}>No Records Found</h4>}
            </span>
            {CodisCase && CodisCase?.length > 0 ? <>    
            <GridComponent
                dataSource={typedCase}
                allowPaging={true}
                pageSettings={{ pageSize: 25, pageSizes: [10, 25, 50, 100] }}
                allowSorting={true}
                sortSettings={sortSettings}
                allowMultiSorting={true}
                allowFiltering={true}
                filterSettings={{ type: 'CheckBox' }}
                allowTextWrap={true}
                textWrapSettings={{ wrapMode: 'Both' }}
                allowExcelExport={true}
                allowPdfExport={true}
                toolbar={['Search']}
                toolbarClick={GridToolsClick}
                ref={g => grid = g}
                selectionSettings={{
                    persistSelection: true,
                    type: "Multiple",
                    //checkboxOnly: true,
                    mode: "Row"
                }}
               // dataBound={dataBound}
                //rowSelecting={(event: any) => { console.log(event) }}
                allowSelection={true}
                allowResizing={true}
                rowHeight={24}
            >
                <ColumnsDirective>
                    <ColumnDirective field='OffenseDate' headerText='Offense Date' format={{ type: 'date', format: 'MM/dd/yyyy' }} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='Offense' headerText='Offense' customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='LabSpecimenID' headerText='Lab Specimen ID' customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='CODISAgency' headerText='Agency'  customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='AgencyCaseNo' headerText={GetStringNameForSite(SiteName,"Case #")}  customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='CaseType' headerText='Case Type'  customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='Profile' headerText='Profile'  customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='CODISCounty' headerText={`${GetStringNameForSite(SiteName,'COUNTY')}`}  customAttributes={{ class: ['e-attr'] }} />
                </ColumnsDirective>
                <Inject services={[ColumnMenu, Sort, Page, Filter, DetailRow, Toolbar, ExcelExport, PdfExport, Search, Resize, Reorder]} />
            </GridComponent>
            </>
            : <></>}
        </div>

    )
};

export default CodisCaseDisplay
