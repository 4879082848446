import React from "react";
import logo from "../assets/Images/newbacktrace.png";
export const Error404 = () => {
    return (
        <div className="bg-blue-900">
            <div className="w-9/12 m-auto py-16 min-h-screen flex items-center justify-center">
                <div className="bg-white shadow overflow-hidden sm:rounded-lg pb-8">
                    <div className="border-t border-gray-200 text-center pt-8">
                        {/*<img src={logo} className="m-auto" style={{"height":"150px"}} alt={"BackTrace"} />*/}
                        <h1 className="text-6xl font-bold text-red-900">404 <span className="font-medium text-black">Page Not Found</span></h1>

                        <p className="text-2xl pb-8 px-12 font-medium">
                            The page you are looking for does not exist. It might have been moved or deleted.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}