import {PersonInfo} from "../../../interfaces/getDarInfo_interface";
import React from "react";
import { Disclosure } from '@headlessui/react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ETInfo from "../../../interfaces/ETInfo.interface";
import { TablePagination,  CircularProgress, Button } from "@mui/material";
import { Link } from "react-router-dom";
import ETicketIcon from "../../../assets/Images/et1.png";
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent ,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search
} from '@syncfusion/ej2-react-grids';

interface PanelEticketProps {
    person: PersonInfo,
    ETInfos: ETInfo[],
    isLoading:boolean
}

const  PanelEticket: React.FC<PanelEticketProps> = ({person,ETInfos,isLoading})=> {
    const [FormatData, setFormatData] = React.useState(0);

    const handleDataTyping = () => {
        let tempP: any = [...ETInfos]
        for (let p of tempP) {
            if (p.eCreated) {
                p.eCreated = new Date(p.eCreated)
            }
        }
        setFormatData(tempP)
    }

    React.useEffect(() => {
        handleDataTyping()
    }, [ETInfos])

    return(
        <div className={"m-5 border"}>
            <Disclosure>
                {({ open }) => (
                    <>
                        <Disclosure.Button className="bg-blue-100 p-2 w-auto w-full text-left">
                            <span style={{ "display": "flex", "justifyContent": "space-between" }}>
                                <span>
                                    <KeyboardArrowDownIcon
                                        className={`${open ? "transform rotate-180" : ""}`}
                                    />
                                    <span className={"font-bold"}>E-Ticket Issued <img src={ETicketIcon} alt={''} style={{ display: 'inline' }} /> {(isLoading) ? <CircularProgress size={20} style={{"marginLeft" : "5px"}} /> : <></>}</span>
                                </span>
                                <span className={"font-bold mr-2"} >Name: {person?.FName?.toUpperCase()} {person?.MName?.toUpperCase()} {person?.LName?.toUpperCase()} &nbsp;&nbsp;Records: {ETInfos?.length}</span>
                            </span>
                        </Disclosure.Button>

                        <Disclosure.Panel className="text-gray-500 p-4">

                            <GridComponent
                                dataSource={FormatData}
                                allowPaging={true}
                                pageSettings={{pageSize: 50, pageSizes: [10,25,50,100]}}
                                allowSorting={true}
                                allowMultiSorting={true}
                                allowFiltering={true}
                                filterSettings={{type: 'CheckBox'}}
                                allowTextWrap={true}
                                textWrapSettings={{ wrapMode: 'Both' }}
                                rowHeight={24}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective field='eCreated' headerText='Date Created' format={{ type: 'dateTime', format: 'MM/dd/yyyy HH:mm' }}/>
                                    <ColumnDirective field='eMunicipality' headerText='Municipality'/>
                                    <ColumnDirective field='eOffenseStat' headerText='Offense Stat'/>
                                    <ColumnDirective field='eViolationDesc' headerText='Violation Desc'/>
                                    <ColumnDirective field='ePlateNbr' headerText='Plate'/>
                                    <ColumnDirective field='eDefDL' headerText='DL'/>
                                    {/*<ColumnDirective template={(item: any) => (<Link to={'/detail/ETicket?ID=' + item.ID + '&Dept=' + item.TicketNmbr} target="_blank">{item.TicketNmbr}</Link>)} headerText='Ticket #' />*/}
                                    <ColumnDirective template={(item: any) => (<Button onClick={() => { window.open('/detail/ETicket?ID=' + item?.ID + '&Dept=' + item?.TicketNmbr, '_blank') }} >View</Button>)} headerText='View' />
                                </ColumnsDirective>
                                <Inject services={[Sort, Page, Filter]}/>
                            </GridComponent>
                            
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>

    )
};

export default PanelEticket;