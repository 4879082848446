import Pin from "../../../interfaces/Pin.interface";
import * as React from "react";
import {GoogleMap, Marker, Polygon, useJsApiLoader} from "@react-google-maps/api";
import {Google_API_Key, SiteName} from "../../../services/config.service";
import icon_cctv from "../../../assets/Images/FixedCCTVCamera.png";
import {getCenterForSite} from "../../../interfaces/config.interface";
import {useEffect, useState} from "react";
import {Button, ButtonGroup, Slider} from "@mui/material";

interface GeoCodeHexBinProps {
    pins: Pin[]
}


const GeoCodeHexBin: React.FC<GeoCodeHexBinProps> = ({  pins}) => {
    const EARTH_RADIUS = 6371;
    const toRadians = (degree: number) => (degree * Math.PI) / 180;
    const toDegrees = (radian: number) => (radian * 180) / Math.PI;
    const calculateDestination = (lat: number, lng: number, distance: number, bearing: number) => {
        const angularDistance = distance / EARTH_RADIUS;
        const bearingRad = toRadians(bearing);
        const latRad = toRadians(lat);
        const lngRad = toRadians(lng);
        const destLatRad = Math.asin(
            Math.sin(latRad) * Math.cos(angularDistance) +
            Math.cos(latRad) * Math.sin(angularDistance) * Math.cos(bearingRad)
        );
        const destLngRad =
            lngRad +
            Math.atan2(
                Math.sin(bearingRad) * Math.sin(angularDistance) * Math.cos(latRad),
                Math.cos(angularDistance) - Math.sin(latRad) * Math.sin(destLatRad)
            );
        return { lat: toDegrees(destLatRad), lng: toDegrees(destLngRad) };
    };
    const generateHexagonVertices = (centerLat: number, centerLng: number, size: number) => {
        const vertices = [];
        for (let i = 0; i < 6; i++) {
            const angle = 60 * i;
            const vertex = calculateDestination(centerLat, centerLng, size, angle);
            vertices.push(vertex);
        }
        return vertices;
    };
    const generateHexbinGrid = (centerLat:number, centerLng:number, gridRadius:number, hexSize:number) => {
        const hexagons = [];
        const rowHeight = hexSize * 1.5;
        const colWidth = Math.sqrt(3) * hexSize;

        for (let row = -gridRadius; row <= gridRadius; row++) {
            for (let col = -gridRadius; col <= gridRadius; col++) {
                const latOffset = row * rowHeight;
                const lngOffset = col * colWidth + (row % 2) * (colWidth / 2);

                const hexCenterLat = centerLat + latOffset / EARTH_RADIUS * (180 / Math.PI);
                const hexCenterLng = centerLng + lngOffset / (EARTH_RADIUS * Math.cos(toRadians(centerLat))) * (180 / Math.PI);

                const hexagon = generateHexagonVertices(hexCenterLat, hexCenterLng, hexSize);
                hexagons.push(hexagon);
            }
        }

        return hexagons;
    };
    const pointInPolygon = (point:any, vs:any) => {
        const x = point.lat, y = point.lng;

        let inside = false;
        for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
            const xi = vs[i].lat, yi = vs[i].lng;
            const xj = vs[j].lat, yj = vs[j].lng;

            const intersect = ((yi > y) !== (yj > y)) &&
                (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) inside = !inside;
        }

        return inside;
    };
    const binDataPoints = (data:any, hexagons:any) => {
        const hexagonCounts = hexagons.map(() => 0);

        data.forEach((point:any) => {
            hexagons.forEach((hexagon:any, index:any) => {
                if (pointInPolygon(point, hexagon)) {
                    hexagonCounts[index] += 1;
                }
            });
        });

        return hexagonCounts;
    };

    const getColor = (density:number, maxDensity:number) => {
        const ratio = density / maxDensity;
        const r = Math.floor(255 * ratio);
        const g = 255 - r;
        return `rgba(${r},${g},0,0.8)`;
    };
    

    const [map, setMap] = React.useState<any>(null)
    const [zoom, setZoom] = useState(10); 
    const [gridRadius, setGridRadius] = useState(14); 
    const [hexSize, setHexSize] = useState(0.5); // in km

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: Google_API_Key
    })

    const containerStyle = {
        width: '1150px',
        height: '750px'
    };
    const handleCenter = ():Pin => {
        var centerLat = 0
        var centerLng = 0

        for(var i = 0; i < pins.length; i++)
        {
            centerLat += parseFloat(pins[i].lat)
            centerLng += parseFloat(pins[i].lng)
        }

        centerLat = centerLat/pins.length
        centerLng = centerLng/pins.length

        return {lat:centerLat, lng:centerLng}
    }
    
    const [hexagons, setHexagons] = useState<any>([]);
    const [hexagonColors, setHexagonColors] = useState<any>([]);
    const [center, setCenter] = useState<any>({
        lat: handleCenter().lat || getCenterForSite(SiteName).lat,
        lng: handleCenter().lng || getCenterForSite(SiteName).lng
    });
    
    useEffect(() => {
        
        const generatedHexagons = generateHexbinGrid(center.lat, center.lng, gridRadius, hexSize);
        setHexagons(generatedHexagons);

        const hexagonCounts = binDataPoints(pins, generatedHexagons);
        const maxDensity = Math.max(...hexagonCounts);
        const colors = hexagonCounts.map((count:any) => getColor(count, maxDensity));
        setHexagonColors(colors);
    }, [pins,center, hexSize, gridRadius]);
    
    const HandleMapRedraw = () => {
        if (map && map.state.map.center && map.state.map.center.lat() && map.state.map.center.lng()) {
            setCenter({
                lat: map.state.map.center.lat(),
                lng: map.state.map.center.lng()
            })
        }
    }
    return (
        <div>

            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={zoom}
                onUnmount={onUnmount}
                ref={(m: any) => setMap(m)}
                //onCenterChanged={() => {mapCenterChange()}}
                options={{
                    styles: [{
                        elementType: "labels",
                        featureType: "poi.business",
                        stylers: [{visibility: "off",}],
                    }],
                }}
            >
                {hexagons.map((hexagon: any, index: any) => (
                    <Polygon
                        key={index}
                        paths={hexagon}
                        options={{
                            fillColor: hexagonColors[index],
                            fillOpacity: 0.6,
                            strokeColor: 'black',
                            strokeOpacity: 1,
                            strokeWeight: 1,
                        }}
                    />
                ))}

                <div style={{position: 'absolute', bottom: 10, left:10}}>
                    <Slider
                        defaultValue={gridRadius}
                        step={1}
                        min={1}
                        max={20}
                        valueLabelDisplay="auto"
                        onChange={(e, v) => setGridRadius(v as number)}
                    />
                    <Slider
                        defaultValue={hexSize}
                        step={0.1}
                        min={0.10}
                        max={5}
                        valueLabelDisplay="auto"
                        onChange={(e, v) => setHexSize(v as number)}
                    />
                    <ButtonGroup size="large" variant="contained">
                        <Button onClick={HandleMapRedraw} variant="contained">REPOSITION HEXBIN</Button>
                    </ButtonGroup>
                        
    
                        
                    
                </div>
            </GoogleMap>

        </div>
    );
}

export default GeoCodeHexBin;