import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import {
    AccordionDetails,
    Badge,
    Button,
    FormControlLabel,
    Paper,
    Tab, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow,
    Tabs, Tooltip,
    Typography
} from '@mui/material';
import {Google_API_Key, showCrumbScore, SiteName} from "../../../services/config.service";
import Pin from "../../../interfaces/Pin.interface";
import icon_gun from "../../../assets/Images/Gun.png"
import icon_gunRed from "../../../assets/Images/gun_red.png"
import icon_alpr from "../../../assets/Images/FixedALPRCamera.png"
import icon_alprInactive from "../../../assets/Images/FixedALPRCameraInactive.png"
import icon_cctv from "../../../assets/Images/FixedCCTVCamera.png"
import icon_person from "../../../assets/Images/Person.png"
import icon_car from "../../../assets/Images/Vehicle.png"
import icon_stolenCar from "../../../assets/Images/StolenVehicle.png"
import icon_carjacking from "../../../assets/Images/carjacking.png"
import icon_arrest from "../../../assets/Images/ai1.png"
import icon_home from "../../../assets/Images/address.png"
import icon_njpop from "../../../assets/Images/Shooting.png"
import icon_infocop from "../../../assets/Images/ic1.png"
import icon_eticket from "../../../assets/Images/et1.png"
import icon_cadrms from "../../../assets/Images/Opencase.png"
import icon_njtrace from "../../../assets/Images/NjGunTrace1.png"
import icon_nibin from "../../../assets/Images/nibin1.png"
import icon_etrace from "../../../assets/Images/WeaponTrace.png"
import icon_parkmobile from "../../../assets/Images/pm1.png"
import icon_alprIndicator from "../../../assets/Images/alpr1.png"
import icon_shotSpotter from "../../../assets/Images/shotSpotter.png"
import { getALPRCameras, getCCTVCameras } from "../../../services/getDropdown.service";
import icon_openCase from "../../../assets/Images/Opencase.png"
import icon_device from "../../../assets/Images/DeviceSmall.png";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import Checkbox from '@mui/material/Checkbox';
import {JAOS} from "../../../services/JAOS.service";
import {
    ColumnDirective,
    ColumnMenu,
    ColumnsDirective,
    DetailRow, ExcelExport, ExcelExportProperties,
    Filter, Grid, GridComponent,
    Inject,
    Page, PdfExport, Reorder, Resize, Search,
    Sort, Toolbar
} from "@syncfusion/ej2-react-grids";
import {ClickEventArgs} from "@syncfusion/ej2-navigations";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import CloseIcon from '@mui/icons-material/Close';
import MailIcon from '@mui/icons-material/Mail';
import {Disclosure} from "@headlessui/react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {formatDate, formatDT} from "../../../services/formatDate.service";
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import MapIcon from '@mui/icons-material/Map';
import {getCenterForSite, GetStringNameForSite} from "../../../interfaces/config.interface";
import GeoCodeHexBin from "./_GeoCode_Hexbin";

interface GeoCodeProps {
    HandleGeoCodeClose: any,
    show: boolean,
    pins: Pin[]
    mapVersion: string
    exportData?: any | null
}

const GeoCode: React.FC<GeoCodeProps> = ({ HandleGeoCodeClose, show, pins, mapVersion, exportData = null}) => {
    const [open, setOpen] = React.useState<boolean>(show)
    const [loadALPR, setLoadALPR] = React.useState<boolean>(false)
    const [alprInfo, setAlprInfo] = React.useState<any[]>([])
    const [showALPR, setShowALPR] = React.useState<boolean>(false)
    const [loadCCTV, setLoadCCTV] = React.useState<boolean>(false)
    const [CCTVInfo, setCCTVInfo] = React.useState<any[]>([])
    const [showCCTV, setShowCCTV] = React.useState<boolean>(false)
    const [showStolen, setShowStolen] = React.useState<boolean>(false)
    const [showHexBin, setShowHexBin] = React.useState<boolean>(false)
    const [tabNum, setTabNum] = React.useState<any>('1')
    const [mapClusterData, setMapClusterData] = React.useState<any[]>([])
    const [deviceExportData, setDeviceExportData] = React.useState<any>()

    const NJBoundry = [
        [41.362453762936575, -74.6918236783217],
        [40.990338834229426, -73.91728763616442],
        [40.6952910099279, -74.01067141429685],
        [40.486723153439925, -74.26885009501595],
        [40.49090088462017, -74.01067141429685],
        [40.06763774018179, -73.8568628385493],
        [39.48504098031486, -74.21391846082041],
        [38.89324697993387, -74.93901603220169],
        [39.47232109991295, -75.55425033519187],
        [39.62903700481567, -75.55974349861143],
        [39.852904834638665, -75.40593492286388],
        [40.23558358525728, -74.79619378329326],
        [40.557709209883015, -75.20818103975989],
        [40.98204561057492, -75.15324940556434]
    ]

    React.useEffect(() => {
        if(!loadALPR && mapVersion !== 'alprCamera'){
            setLoadALPR(true)
            loadALPRCameras()
        }
        if(!loadCCTV && mapVersion !== 'alprCamera')
        {
            setLoadCCTV(true)
            loadCCTVCameras()
        }        
        if (mapVersion === 'carjacking_device' || mapVersion === 'device')
        {
            handleExportData()
        }
    }, [show]);
    
    const handleExportData = () => {
        if (exportData) {
            let temp: any = [...exportData]

            for (let data of temp) {
                if (data.EventDateTimeUTC) {
                    data.EventDateTimeUTC = new Date(data.EventDateTimeUTC)
                }
            }
            setDeviceExportData(temp)
        }
    }
    
    const loadALPRCameras = async () => {
        let r:any = await getALPRCameras()
        setAlprInfo(r)
    }

    const loadCCTVCameras = async () => {
        let r:any = await getCCTVCameras()
        setCCTVInfo(r)
    }
    
    const handleOpen = () => {
        setOpen(!open)
    }
    /////////////////////////////////////////////////////
    const containerStyle = {
        width: '1150px',
        height: '750px'
    };

    const handleCenter = ():Pin => {
        var centerLat = 0
        var centerLng = 0

        for(var i = 0; i < pins.length; i++)
        {
            centerLat += parseFloat(pins[i].lat)
            centerLng += parseFloat(pins[i].lng)
        }

        centerLat = centerLat/pins.length
        centerLng = centerLng/pins.length

        return {lat:centerLat, lng:centerLng}
    }

    const center = {
        lat: handleCenter().lat || getCenterForSite(SiteName).lat,
        lng: handleCenter().lng || getCenterForSite(SiteName).lng
    };
    
    
    const getPinIcon = (pin:Pin):string  => {
        if(pin.tool && pin.tool.length > 0){
            switch(pin.tool)
            {
                case ('statute'):
                case ('personNearbyInc'):
                case ('incident'):
                    return "https://maps.gstatic.com/mapfiles/ridefinder-images/mm_20_" + (getStatuteColor(pin.statute ? pin.statute : "")) + ".png";
                case('frequent'):
                    return "https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_red" + pin.arrestCount + '.png'
                case('crumb'):
                    return "https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_red" + pin.arrestCount + '.png'
                case('shooting'):
                    if(pin.inc_type === 'Murder' || pin.inc_type?.toUpperCase()?.includes('MURDER'))
                        return icon_gunRed
                    else 
                        return icon_gun
                case('person'):
                    return icon_person
                case('car'):
                    return icon_car
                case ('stolenCar'):
                    return icon_stolenCar
                case ("home"):
                case ("personNearbyRes"):
                    return icon_home
                case("arrest"):
                    return icon_arrest
                case("njpop"):
                    return icon_njpop
                case("infocop"):
                    return icon_infocop
                case("eticket"):
                    return icon_eticket
                case("cadrms"):
                case("case"):
                    return icon_cadrms
                case("etrace"):
                    return icon_etrace
                case("parkmobile"):
                    return icon_parkmobile
                case("alprCamera"):
                    return icon_alpr
                case("alpr"):
                    return icon_alprIndicator
                case ("shotSpotter"):
                    return icon_shotSpotter
                case ("personNearbyPoint"):
                    return "https://maps.google.com/mapfiles/kml/pal2/icon13.png"
                case ("openCase"):
                    return icon_openCase
                case ("device"):
                    return icon_device
                case ("carjacking"):
                    return icon_carjacking
                    
            }
        }
        return ""

    }

    const getStatuteColor = (statute:string):string  =>{
        var grp = statute.substring(0, 5);
        if (grp === '9:6-1' || grp === '26:2C' || grp === '53:1-' || grp === '2C:7-' || grp === '20:2-' ||
            grp === '12:1E' || grp === '26:2D' || grp === '2C:38' || grp === '2C:21' || grp === '02C:2') {
            return 'gray' //rgba(255, 0, 0, 0.5)
        }
        else if (grp === '2A:11' || grp === '2A:4A' || grp === '12:1E' || grp === '32:1-' || grp === '58:10' ||
            grp === '17:15' || grp === '2C:14' || grp === '2C:16' || grp === '54A:8' || grp === '2C:45') {
            return 'green'
        }
        else if (grp === '5:12-' || grp === '2C:5-' || grp === '2C:10' || grp === '2C:24' || grp === '13:1E' ||
            grp === '0012C' || grp === '23:4-' || grp === '33:1-' || grp === '2C:13' || grp === '2C:46') {
            return 'orange'
        }
        else if (grp === '9:6-8' || grp === '19:34' || grp === '2C:27' || grp === '2C:36' || grp === '2C:29' ||
            grp === '2C:34' || grp === '2C:40' || grp === '2C:41' || grp === '6:1-1' || grp === '21:1A') {
            return 'purple'
        }
        else if (grp === '38A:1' || grp === '2C:39' || grp === '2C:20' || grp === '52:17' || grp === '11A:1' ||
            grp === '24:21' || grp === '2C:37' || grp === '2A:10' || grp === '56:8-' || grp === '2C:58') {
            return 'red'
        }
        else if (grp === '19:63' || grp === '34:15' || grp === '39:2-' || grp === '2C:30' || grp === '30:41' ||
            grp === '45:19' || grp === '11A:2' || grp === '52:14' || grp === '2A:17' || grp === '2A:15') {
            return 'white'
        }
        else if (grp === '4:22-' || grp === '54:40' || grp === '39:4-' || grp === '39:3-' || grp === '30:4D' ||
            grp === '2C:18' || grp === '2C:43' || grp === '39:10' || grp === '2C:2-' || grp === '13:9-') {
            return 'yellow'
        }
        else if (grp === '4-8.2' || grp === '54:52' || grp === '2C:62' || grp === '12:7-' || grp === '23:7-' ||
            grp === '2C:22' || grp === '2C:35' || grp === '2C: 3' || grp === '2C:15' || grp === '18A:3') {
            return 'black'
        }
        else if (grp === '21:3-' || grp === '2C:17' || grp === '12:3-' || grp === '52:34' || grp === '2C:11' ||
            grp === '2C:25' || grp === '21:2-' || grp === '49:3-' || grp === '30:40' || grp === '55:13') {
            return 'blue'
        }
        else if (grp === '30:4-' || grp === '2C:12' || grp === '2C:28' || grp === '54:50' || grp === '2A:85' ||
            grp === '9:6-3' || grp === '2A:16' || grp === '2A:14' || grp === '2A:94' || grp === '2C:33') {
            return 'brown'
        }
        else if (grp === '99:10') {
            return 'shadow'
        }
        else {
            return 'red'
        }
    };

    const searchPeople = () =>  {
        let obj = {FName:"", MName:"",LName:"",DOB:""}
        if(exportData)
        {
            for (let p of exportData){
                if(p.DOB && p.FName && p.LName)
                {
                    obj.FName += p.FName + ",";
                    obj.MName += p.MName + ",";
                    obj.LName += p.LName + ",";
                    obj.DOB += p.DOB.substring(5, 2 + 5) + "/" + p.DOB.substring(8, 2 + 8) + "/" + p.DOB.substring(0, 4) + ",";
                }
            }
        }
        if(obj.FName.length !== 0) {
            let l = new JAOS()
            let hashID = l.objToStack(obj)
            window.open('/tools/arrest?personList=' + hashID, '_blank')
        }
    }
    
    const searchPlates = () =>  {
        if(exportData)
        {
            let obj = {Plates:""}
            if(exportData)
            {
                for (let p of exportData.filter((plate: any) => showStolen ? (plate?.POIReason?.toLowerCase() == 'stolen vehicle' || plate?.POIReason?.toLowerCase() == 'stolen plate' || plate?.POIReason?.toLowerCase() == 'felony stolen vehicle') : plate)){
                    if(p.red_vrm)
                    {
                        obj.Plates += p.red_vrm + ",";
                    }
                }
            }
            
            if(obj.Plates.length !== 0) {
                let l = new JAOS()
                let hashID = l.objToStack(obj)
                window.open('/tools/arrest?personList=' + hashID, '_blank')
            }
        }
    }
    
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: Google_API_Key
    })

    const [map, setMap] = React.useState(null)

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])
    ////////////////////////////////////////////

    const handlePinClick = (pin:Pin) => {
        let pLat = pin.lat;
        let pLng = pin.lng
        let temp = mapClusterData
        let same = pins.filter((pinx) => {if(pinx.lat === pLat && pinx.lng === pLng && pin.tool === pinx.tool) return pinx})
        
        if(same.length > 1 && temp.filter((t) => {if(t.data[0].lat === same[0].lat && t.data[0].lng === same[0].lng) return t}).length === 0)
        {
            temp = temp.concat([{'type':pin.tool, 'data':same, 'lat':pLat, 'lng':pLng}])
            setMapClusterData(temp)
        }
        
        
    }
    
    const getMarkerTitle = (pin:Pin):string => {
        
        let title = ""

        if(pin.tool && pin.tool.length > 0) {
            switch (pin.tool) {
                case('statute'):
                    title += 'Name: ' + pin.name + '\n'
                    title += 'Statute: ' + pin.statute + '\n'
                    title += 'Offense Date: ' + pin.offense + '\n'
                    title += 'City: ' + pin.city + '\n'
                    break;
                case('frequent'):
                    title += 'Name: ' + pin.name + '\n'
                    title += 'Address: ' + pin.address?.toUpperCase() + '\n\t' + pin.city?.toUpperCase() + ' ' + pin.state?.toUpperCase() + ' ' + pin.zip?.toUpperCase() +  '\n'
                    break;
                case('crumb'):
                    title += 'Name: ' + pin.name + '\n'
                    title += 'Crumb Score Date: ' + pin.crumbScoreDate + '\n'
                    title += 'Crumb Percentile: ' + pin.crumbPercentile + '\n'
                    title += 'Crumb Score: ' + pin.crumbScore + '\n'
                    break;
                case ('shooting'):
                case ('shotSpotter'):
                    title += 'Date: ' + pin.date + '\n'
                    if(pin.dept)
                        title += 'Dept: ' + pin.dept + '\n'
                    if(pin.victims)
                        title += 'Victims: ' + pin.victims + '\n'
                    if(pin.suspect)
                        title += 'Suspect: ' + pin.suspect + '\n'
                    break;
                case('person'):
                    title += 'Name: ' + pin.name + '\n'
                    if(pin.date)
                        title += 'Arrest Date: ' + pin.date + '\n'
                    if(pin.statute)
                        title += 'Statute: ' + pin.statute + '\n'
                    break;
                case('car'):
                    if(pin.date)
                    title += 'Date: ' + pin.date + '\n'
                    if(pin.plate)
                        title += 'Plate: ' + pin.plate + '\n'
                    if(pin.alprSource)
                        title += 'ALPR Source: ' + pin.alprSource + '\n'
                    break;
                case ('stolenCar'):
                    title += 'STOLEN VEHICLE' + '\n' 
                    if (pin.date)
                        title += 'Date: ' + pin.date + '\n'
                    if (pin.plate)
                        title += 'Plate: ' + pin.plate + '\n'
                    if (pin.alprSource)
                        title += 'ALPR Source: ' + pin.alprSource + '\n'
                    break;
                case('home'):
                    if(pin.address)
                        title += 'Address: ' + pin.address + '\n'
                    if(pin.city)
                        title += 'City: ' + pin.city + '\n'
                    if(pin.zip)
                        title += 'Zip: ' + pin.zip + '\n'
                    break;
                case('arrest'):
                    if(pin.date)
                        title += 'Arrest Date: ' + pin.date + '\n'
                    if(pin.statute)
                        title += 'Statute: ' + pin.statute + '\n'
                    if(pin.arrestedBy)
                        title += 'PD: ' + pin.arrestedBy + '\n'
                    break;
                case('njpop'):
                    if(pin.date)
                        title += 'Incident Date: ' + pin.date + '\n'
                    if(pin.offense)
                        title += 'Incident #: ' + pin.offense + '\n'
                    if(pin.weapon)
                        title += 'Weapon: ' + pin.weapon + '\n'
                    break;
                case('infocop'):
                    if(pin.date)
                        title += 'Interaction Date: ' + pin.date + '\n'
                    if(pin.offense)
                        title += 'Req ID: ' + pin.offense + '\n'
                    if(pin.arrestedBy)
                        title += 'PD: ' + pin.arrestedBy + '\n'
                    break;
                case('eticket'):
                    if(pin.date)
                        title += 'Time Stamp: ' + pin.date + '\n'
                    if(pin.dept)
                        title += 'Source: ' + pin.dept + '\n'
                    if(pin.plate)
                        title += 'Plate: ' + pin.plate + '\n'
                    break;
                case('cadrms'):
                    if(pin.date)
                        title += 'Incident Date: ' + pin.date + '\n'
                    if(pin.offense)
                        title += 'Case #: ' + pin.offense + '\n'
                    if(pin.role)
                        title += 'Role: ' + pin.role + '\n'
                    break;
                case('etrace'):
                    if(pin.date)
                        title += 'Recovery Date: ' + pin.date + '\n'
                    if(pin.offense)
                        title += 'ATF #: ' + pin.offense + '\n'
                    if(pin.weapon)
                        title += 'Weapon: ' + pin.weapon + '\n'
                    break;
                case('case'):
                    if(pin.date)
                        title += 'Date: ' + pin.date + '\n'
                    if(pin.offense)
                        title += 'Case #: ' + pin.offense + '\n'
                    break;
                case('openCase'):
                    if(pin.date)
                        title += 'Date: ' + pin.date + '\n'
                    if(pin.offense)
                        title += 'Case #: ' + pin.offense + '\n'
                    if(pin.inc_type)
                        title += 'Incident Type: ' + pin.inc_type + '\n'
                    break;
                case('alprCamera'):
                    if(pin.name)
                        title += 'Name: ' + pin.name + '\n'
                    if(pin.status)
                        title += 'Status: ' + pin.status + '\n'
                    if(pin.alprSource)      
                        title += 'Source: ' + pin.alprSource + '\n'
                    break;
                case('alpr'):
                    if(pin.date)
                        title += 'Time Stamp: ' + pin.date + '\n'
                    if(pin.name)
                        title += 'Camera: ' + pin.name + '\n'
                    if(pin.plate)
                        title += 'Plate: ' + pin.plate + '\n'
                    break;
                case ('parkmobile'):
                    if (pin.date)
                        title += 'Time Stamp: ' + pin.date + '\n'
                    if (pin.dept)
                        title += 'Zone: ' + pin.city + '\n'
                    if (pin.plate)
                        title += 'Plate: ' + pin.plate + '\n'
                    break;
                case ('personNearbyInc'):
                case ('personNearbyRes'):
                    if (pin.arrestCount)
                        title += 'Distance (in miles): ' + pin.arrestCount + '\n'
                    if (pin.name)
                        title += 'Name: ' + pin.name + '\n'
                    if (pin.address)
                        title += 'Address: ' + pin.address?.toUpperCase().trim() + ' ' + pin.city?.toUpperCase().trim() + ' ' + pin.state?.toUpperCase().trim() + ' ' + pin.zip?.toUpperCase().trim() + '\n'
                    if (pin.statute && pin.offense)
                        title += 'Offense: ' + pin.offense.trim() + ' - ' + pin.statute.trim()
                    break;
                case ('device'):
                    if(pin.name)
                        title += "Incident #" + pin.name + '\n'
                    if (pin.device)
                        title += 'DeviceID: ' + pin.device + '\n'
                    if (pin.date) {
                        title += 'Date: ' + formatDT(pin.date) + '\n'
                    }
                    if (pin.distance)
                        title += 'Distance: ' + pin.distance + '\n'
                    if (pin.accuracy)
                        title += 'Horizontal Accuracy: ' + pin.accuracy + '\n'
                    break;
                case ('carjacking'):
                    if(pin.date)
                        title += 'Date: ' + formatDT(pin.date) + '\n'
                    if(pin.dept)
                        title += 'Dept: ' + pin.dept + '\n'
                    if(pin.plate)
                        title += 'Vehicle Info: ' + pin.plate + (pin?.inc_type && pin.inc_type.length > 0 ? ' ' + pin.inc_type : '') + '\n'
                    if(pin.suspect)
                        title += 'Suspect: ' + pin.suspect + '\n'
                    break;
                case ('incident'):
                    if(pin.name)
                        title += "Incident #" + pin.name + '\n'
                    if(pin.date)
                        title += 'Date: ' + formatDT(pin.date) + '\n'
                    if(pin.address)
                        title += 'Address: ' + pin.address + (pin?.city  ? ' ' + pin.city : '') + (pin?.state  ? ' ' + pin.state : '') + (pin?.zip  ? ' ' + pin.zip : '') + '\n'
                    if(pin.lat)
                        title += 'Latitude: ' + pin.lat + '\n'
                    if(pin.lng)
                        title += 'Longitude: ' + pin.lng + '\n'
                    break;
            }
        }

        return title
    }

    React.useEffect(() => {
        if(!open)
            HandleGeoCodeClose()
    },[open])

    const handleALPRShow = (e:any) =>
    {
        setShowALPR(!showALPR)
    }
    const handleShowHexBin = (e:any) =>
    {
        setShowHexBin(!showHexBin)
    }

    const handleCCTVShow = (e:any) =>
    {
        setShowCCTV(!showCCTV)
    }

    const handleStolenOnly = (e: any) => {
        setShowStolen(!showStolen)
    }

    const getALPRTitle = (pin:any) => {
        let title = ''
        
        if(pin.Name)
            title += 'Name: ' + pin.Name + '\n'
        if(pin.Status)
            title += 'Status: ' + pin.Status + '\n'
        if(pin.Source)
            title += 'Source: ' + pin.Source + '\n'
        //removed to keep it the same across the board
        // if(pin.Server)
        //     title += 'Server: ' + pin.Server + '\n'        
        return title    
    }

    const getALPRIcon = (pin: any) => {
        if (pin.Status && pin.Status == "Active")
            return icon_alpr
        else
            return icon_alprInactive
    }

    //----------------------------- EXPORT --------------------
    let grid: Grid | null;
    
    const dataBound = () => {
        if(grid){
            grid.autoFitColumns([])
        }
    }

    const excelExportProperties: ExcelExportProperties = {
        includeHiddenColumn: true,
        fileName: mapVersion === 'car' || mapVersion === 'carjacking_car' ? 'VehicleList.xlsx' : 'DeviceList.xlsx'
    };
    
    const GridToolsClick = (args: ClickEventArgs) => {
        
        if (grid && args.item.text === "Excel Export") {

            
            grid.excelExport(excelExportProperties);

        }
    }
    
    const exportVehicles = () => {
        if (grid) {

            grid.excelExport(excelExportProperties);

        }
    }
    //----------------------------- EXPORT END --------------------
    
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabNum(newValue);
    };
    
    const list = (anchor: 'right') => (
        
        //There are 3 Map Versions
        //1 - No Buttons only key
        <Box
            sx={{ width: 1200 }}
            role="presentation"
        >
            {(isLoaded) ? (
                <>
                    <span className ="grid grid-cols-12 ml-2">
                        <div className="col-span-11">
                            <TabContext value={tabNum}>
                                <TabList onChange={handleTabChange} variant={"fullWidth"}>
                                    <Tab value="1"
                                         icon={
                                                <Tooltip title={'MAP'} arrow>
                                                    <MapIcon color="success" fontSize={'small'} />
                                                </Tooltip>
                                              }
                                         iconPosition="end"
                                    />
                                    {mapClusterData && mapClusterData.length > 0 && mapVersion !== 'PersonSummary' ?
                                        <Tab value="2"
                                             icon=
                                                 {
                                                     <Tooltip title={'DATA'} arrow>
                                                         <Badge badgeContent={mapClusterData ? mapClusterData.length : 0} color="primary">
                                                             <DocumentScannerIcon color="primary" fontSize={'small'} />
                                                         </Badge>
                                                     </Tooltip>
                                                 }
                                             iconPosition="end"
                                        />
                                        : 
                                        <Tab disabled/>
                                    }
                                </TabList>
                                <TabPanel value="1" >
                                    
                                    
                                    {showHexBin && <GeoCodeHexBin pins={pins}  /> }

                                    {!showHexBin && <GoogleMap
                                        mapContainerStyle={containerStyle}
                                        center={center}
                                        zoom={(mapVersion === 'personNearbyInc' || mapVersion === 'personNearbyRes') ? 14 : 8}
                                        onUnmount={onUnmount}
                                        ref={(m: any) => setMap(m)}
                                        options={{ styles: [{ elementType: "labels", featureType: "poi.business", stylers: [{ visibility: "off", }], }], }}
                                    >
                                        { /* Child components, such as markers, info windows, etc. */}
                                        <>
                                            {mapVersion === 'shooting' || mapVersion === 'car' || mapVersion === 'alprCamera' || mapVersion === 'openCase' || mapVersion === 'shotSpotter' || mapVersion === 'VINcar' || mapVersion === 'carjacking' || mapVersion === 'carjacking_car' ?
                                                <>
                                                    {showALPR ?
                                                        alprInfo.map((pin) =>
                                                            <Marker
                                                                position={{ lat: parseFloat(pin.Latitude), lng: parseFloat(pin.Longitude) }}
                                                                icon={getALPRIcon(pin)}
                                                                title={getALPRTitle(pin)}
                                                            />
                                                        )
                                                        : <></>}
                                                    {showCCTV ?
                                                        CCTVInfo.map((pin) =>
                                                            <Marker
                                                                position={{ lat: parseFloat(pin.Latitude), lng: parseFloat(pin.Longitude) }}
                                                                icon ={icon_cctv}
                                                                title ={getALPRTitle(pin)}
                                                            />
                                                        )
                                                        : <></>}
                                                </>
                                                :<></>}
                                            {(pins && pins.length > 0) ?
                                                pins.filter((pin) => showStolen ? pin.tool !== 'car' : pin).map((pin) =>
                                                    <Marker
                                                        position={{ lat: parseFloat(pin.lat), lng: parseFloat(pin.lng) }}
                                                        icon={getPinIcon(pin)}
                                                        title={getMarkerTitle(pin)}
                                                        clickable={true}
                                                        onClick={()=>{handlePinClick(pin)}}
                                                    />
                                                )
                                                : <> </>}
                                        </>
                                    </GoogleMap> }
                                    
                                    <span className = "w-full pt-2" style ={{display:"flex", justifyContent:"center"}}>
                                        {mapVersion === 'car' || mapVersion === 'device' || mapVersion === 'carjacking_car' || mapVersion === 'carjacking_device' ?
                                            <span className="mr-2">
                                                <Button variant="outlined" color="inherit" onClick={exportVehicles}>Export</Button>
                                            </span>
                                            :<></>}
                                            {mapVersion === 'shooting' || mapVersion === 'car' || mapVersion === 'shotSpotter' || mapVersion === 'openCase' || mapVersion === 'VINcar' || mapVersion === 'carjacking' || mapVersion === 'carjacking_car' ?
                                            <span className="inline">
                                                <FormControlLabel control={<Checkbox checked={showHexBin} onChange={handleShowHexBin} />} label="Hexbin Map" />

                                                <FormControlLabel control={<Checkbox checked={showALPR} onChange={handleALPRShow} checkedIcon={<img src={icon_alpr} />} />} label="ALPR Camera" />
                                                <FormControlLabel control={<Checkbox checked={showCCTV} checkedIcon={<img src={icon_cctv} />} onChange={handleCCTVShow} />} label="CCTV" />
                                                {mapVersion === 'car' || mapVersion === 'VINcar' || mapVersion === 'carjacking_car' ? <FormControlLabel control={<Checkbox checked={showStolen} checkedIcon={<img src={icon_stolenCar} />} onChange={handleStolenOnly} />} label="Stolen Vehicles" /> : <></>}
                                            </span>
                                            :<></>}
                                        {mapVersion === 'car' || mapVersion === 'carjacking_car' ?
                                            <span className="mr-4"><Button variant="outlined" size={"small"} color="inherit" onClick={searchPlates}><img src={icon_arrest}/>Vehicle Connection</Button></span>
                                            : <></>}
                                        {mapVersion === 'person' || mapVersion === 'carjacking_person' ?
                                            <span className="mr-4"><Button variant="outlined" size={"small"} color="inherit" onClick={searchPeople}><img src={icon_arrest}/>Person Connection</Button></span>
                                            : <></>}
                                        {mapVersion === 'shooting' || mapVersion === 'person' || mapVersion === 'car'?
                                            <Tooltip title={'Shooting'} arrow>
                                                <FormControlLabel control={<img src={icon_gun} />} label="" />
                                            </Tooltip>                                            
                                            : <></>}
                                        {mapVersion === 'shotSpotter' ?
                                            <Tooltip title={'Shot Spotter'} arrow>
                                                <FormControlLabel control={<img src={icon_shotSpotter} />} label="" />
                                            </Tooltip>
                                            : <></>}
                                        {mapVersion === 'car' || mapVersion === 'VINcar' || mapVersion === 'carjacking_car' ?
                                            <Tooltip title={'Vehicle'} arrow>
                                                <FormControlLabel control={<img src={icon_car} />} label="" />
                                            </Tooltip>                                            
                                            : <></>}
                                        {mapVersion === 'device' || mapVersion === 'carjacking_device' ?                                            
                                            <Tooltip title={'Device'} arrow>
                                                <FormControlLabel sx={{ marginLeft: '3px' }} control={<img src={icon_device} />} label="" />
                                            </Tooltip>  
                                        : <></>}
                                        {(mapVersion === 'alprCamera') ?
                                            <Tooltip title={'ALPR Camera'} arrow>
                                                <FormControlLabel control={<img src={icon_alpr} />} label="" />
                                            </Tooltip> 
                                            : <></>}
                                        {mapVersion === 'person' || mapVersion == 'carjacking_person'?
                                            <Tooltip title={'Arrested Person'} arrow>
                                                <FormControlLabel control={<img src={icon_person} />} label="" />
                                            </Tooltip>                                            
                                            : <></>}
                                        {mapVersion === 'carjacking' || mapVersion === 'carjacking_person'  || mapVersion === 'carjacking_car' || mapVersion === 'carjacking_device' ?
                                            <Tooltip title={'Carjacking'} arrow>
                                                <FormControlLabel control={<img src={icon_carjacking} />} label="" />
                                            </Tooltip> 
                                            : <></>}
                                        {mapVersion === 'personNearbyInc' || mapVersion === 'personNearbyRes' ?
                                            <Tooltip title={'Search Location'} arrow>
                                                <FormControlLabel control={<img src={"https://maps.google.com/mapfiles/kml/pal2/icon13.png"} />} label="" />
                                            </Tooltip> 
                                            : <></>}
                                        {mapVersion === 'personNearbyRes' || mapVersion === 'PersonSummary'?
                                            <Tooltip title={'Residency Address'} arrow>
                                                <FormControlLabel control={<img src={icon_home} />} label="" />
                                            </Tooltip> 
                                            : <></>}
                                        {mapVersion === 'personNearbyInc' ?
                                            <Tooltip title={'Incident Address'} arrow>
                                                <FormControlLabel control={<img src={"https://maps.gstatic.com/mapfiles/ridefinder-images/mm_20_red.png"} />} label="" />
                                            </Tooltip>                                            
                                            : <></>}
                                        {mapVersion === 'PersonSummary' ?
                                            <Tooltip title={'Arrest'} arrow>
                                                <FormControlLabel control={<img src={icon_arrest} />} label="" />
                                            </Tooltip> 
                                            : <></>}
                                        {mapVersion === 'PersonSummary' ?
                                            <Tooltip title={'Shooting'} arrow>
                                                <FormControlLabel control={<img src={icon_njpop} />} label="" />
                                            </Tooltip> 
                                            : <></>}
                                        {mapVersion === 'PersonSummary' ?
                                            <Tooltip title={'INFO-COP'} arrow>
                                                <FormControlLabel control={<img src={icon_infocop} />} label="" />
                                            </Tooltip>                                           
                                            : <></>}
                                        {mapVersion === 'PersonSummary' ?
                                            <Tooltip title={'E-Ticket'} arrow>
                                                <FormControlLabel control={<img src={icon_eticket} />} label="" />
                                            </Tooltip>
                                            : <></>}
                                        {mapVersion === 'PersonSummary' ?
                                            <Tooltip title={GetStringNameForSite(SiteName, "CAD/RMS")} arrow>
                                                <FormControlLabel control={<img src={icon_cadrms} />} label="" />
                                            </Tooltip>                                            
                                            : <></>}
                                        {mapVersion === 'PersonSummary' ?
                                            <Tooltip title={'E-Trace'} arrow>
                                                <FormControlLabel control={<img src={icon_etrace} />} label="" />
                                            </Tooltip> 
                                            : <></>}
                                        {mapVersion === 'PersonSummary' ?
                                            <Tooltip title={'NJ GunTrace'} arrow>
                                                <FormControlLabel control={<img src={icon_njtrace} />} label="" />
                                            </Tooltip>                                            
                                            : <></>}
                                        {mapVersion === 'PersonSummary' ?
                                            <Tooltip title={'NIBIN'} arrow>
                                                <FormControlLabel control={<img src={icon_nibin} />} label="" />
                                            </Tooltip>                                            
                                            : <></>}
                                        {mapVersion === 'PersonSummary' ?
                                            <Tooltip title={'Parking'} arrow>
                                                <FormControlLabel control={<img src={icon_parkmobile} />} label="" />
                                            </Tooltip> 
                                            : <></>}
                                        {mapVersion === 'PersonSummary' ?                                            
                                            <Tooltip title={'ALPR'} arrow>
                                                <FormControlLabel control={<img src={icon_alprIndicator} />} label="" />
                                            </Tooltip> 
                                            : <></>}
                                    </span>
                                    <div style={{display:"none"}}>
                                        {mapVersion === 'car' || mapVersion === 'carjacking_car' ? 
                                            <GridComponent
                                                dataSource={exportData}
                                                dataBound={dataBound}
                                                allowPaging={true}
                                                pageSettings={{pageSize: 25, pageSizes: [10,25,50,100]}}
                                                allowSorting={true}
                                                allowMultiSorting={true}
                                                allowFiltering={true}
                                                filterSettings={{type: 'CheckBox'}}
                                                allowTextWrap={true}
                                                textWrapSettings={{ wrapMode: 'Both' }}
                                                allowExcelExport={true}
                                                allowPdfExport={true}
                                                toolbar={['ExcelExport']}
                                                toolbarClick={GridToolsClick}
                                                ref={g=> grid = g}
                                                rowHeight={24}
                                            >
                                                <ColumnsDirective>
                                                    <ColumnDirective field='red_vrm' visible={true} width={140} minWidth={140} maxWidth={140} headerText='Plate' customAttributes={{ class: ['e-attr']}}/>
                                                    <ColumnDirective field='red_TimeStamp' visible={true} width={140} minWidth={140} maxWidth={140} headerText='TimeStamp' customAttributes={{ class: ['e-attr'] }} />
                                                    <ColumnDirective field='POIReasonType' visible={true} width={140} minWidth={140} maxWidth={140} headerText='POI Type' customAttributes={{ class: ['e-attr'] }} />
                                                    <ColumnDirective field='POIReason' visible={true} width={140} minWidth={140} maxWidth={140} headerText='POI Purpose' customAttributes={{ class: ['e-attr'] }} />
                                                    <ColumnDirective field='Distance' visible={true} width={140} minWidth={140} maxWidth={140} headerText='Distance' customAttributes={{ class: ['e-attr']}}/>
                                                    <ColumnDirective field='IncidentName' visible={true} width={140} minWidth={140} maxWidth={140} headerText='Incident Number' customAttributes={{ class: ['e-attr']}}/>
                                                    <ColumnDirective field='Latitude' visible={true} width={140} minWidth={140} maxWidth={140} headerText='Latitude' customAttributes={{ class: ['e-attr']}}/>
                                                    <ColumnDirective field='Longitude' visible={true} width={140} minWidth={140} maxWidth={140} headerText='Longitude' customAttributes={{ class: ['e-attr']}}/>
                                                    <ColumnDirective field='LprID' visible={true} width={140} minWidth={140} maxWidth={140} headerText='LprID' customAttributes={{ class: ['e-attr']}}/>
                                                    <ColumnDirective field='Occurrence' visible={true} width={140} minWidth={140} maxWidth={140} headerText='Occurrence' customAttributes={{ class: ['e-attr']}}/>
                                                    <ColumnDirective field='Red_ID' visible={true} width={140} minWidth={140} maxWidth={140} headerText='Red_ID' customAttributes={{ class: ['e-attr']}}/>
                                                    <ColumnDirective field='ServerDB' visible={true} width={140} minWidth={140} maxWidth={140} headerText='Server DB' customAttributes={{ class: ['e-attr']}}/>
                                                    <ColumnDirective field='Src_IPV4Address' visible={true} width={140} minWidth={140} maxWidth={140} headerText='Src_IPV4 Address' customAttributes={{ class: ['e-attr']}}/>
                                                    <ColumnDirective field='ViolationType' visible={true} width={140} minWidth={140} maxWidth={140} headerText='Violation Type' customAttributes={{ class: ['e-attr']}}/>
                                                    <ColumnDirective field='src_Description' visible={true} width={140} minWidth={140} maxWidth={140} headerText='Description' customAttributes={{ class: ['e-attr']}}/>
                                                    <ColumnDirective field='src_Name' visible={true} width={140} minWidth={140} maxWidth={140} headerText='Src Name' customAttributes={{ class: ['e-attr']}}/>
                                                    <ColumnDirective field='ste_Name' visible={true} width={140} minWidth={140} maxWidth={140} headerText='Dept' customAttributes={{ class: ['e-attr']}}/>
                                                </ColumnsDirective>
                                                <Inject services={[ColumnMenu, Sort, Page, Filter, DetailRow, Toolbar,ExcelExport]}/>
                                            </GridComponent>
                                        :
                                            <GridComponent
                                                dataSource={deviceExportData}
                                                dataBound={dataBound}
                                                allowPaging={true}
                                                pageSettings={{pageSize: 25, pageSizes: [10,25,50,100]}}
                                                allowSorting={true}
                                                allowMultiSorting={true}
                                                allowFiltering={true}
                                                filterSettings={{type: 'CheckBox'}}
                                                allowTextWrap={true}
                                                textWrapSettings={{ wrapMode: 'Both' }}
                                                allowExcelExport={true}
                                                allowPdfExport={true}
                                                toolbar={['ExcelExport']}
                                                toolbarClick={GridToolsClick}
                                                ref={g=> grid = g}
                                                rowHeight={24}
                                            >
                                                <ColumnsDirective>
                                                    <ColumnDirective field='DeviceID' visible={true} width={140} minWidth={140} maxWidth={140} headerText='Device ID' customAttributes={{ class: ['e-attr']}}/>
                                                    <ColumnDirective field='EventDateTimeUTC' format = {{type:'dateTime', format:'MM/dd/yyyy HH:mm:ss'}} visible={true} width={140} minWidth={140} maxWidth={140} headerText='Date' customAttributes={{ class: ['e-attr']}}/>
                                                    <ColumnDirective field='DeviceType' visible={true} width={140} minWidth={140} maxWidth={140} headerText='Device Type' customAttributes={{ class: ['e-attr'] }} />
                                                    <ColumnDirective field='Distance' visible={true} width={140} minWidth={140} maxWidth={140} headerText='Distance' customAttributes={{ class: ['e-attr'] }} />
                                                    <ColumnDirective field='IPAddress' visible={true} width={140} minWidth={140} maxWidth={140} headerText='IPAddress' customAttributes={{ class: ['e-attr'] }} />                                                    
                                                    <ColumnDirective field='HorizontalAccuracy' visible={true} width={140} minWidth={140} maxWidth={140} headerText='Horizontal Accuracy' customAttributes={{ class: ['e-attr'] }} />
                                                    <ColumnDirective field='Latitude' visible={true} width={140} minWidth={140} maxWidth={140} headerText='Latitude' customAttributes={{ class: ['e-attr']}}/>
                                                    <ColumnDirective field='Longitude' visible={true} width={140} minWidth={140} maxWidth={140} headerText='Longitude' customAttributes={{ class: ['e-attr']}}/>
                                                    <ColumnDirective field='Country' visible={true} width={140} minWidth={140} maxWidth={140} headerText='Country' customAttributes={{ class: ['e-attr']}}/>
                                                </ColumnsDirective>
                                                <Inject services={[ColumnMenu, Sort, Page, Filter, DetailRow, Toolbar,ExcelExport]}/>
                                            </GridComponent>
                                        }
                                    </div>
                                </TabPanel>
                                <TabPanel value="2" >
                                    {mapClusterData && mapClusterData.length > 0 ?
                                        mapClusterData.map((cluster) =>
                                            <Disclosure defaultOpen={true}>
                                                {({ open }) => (
                                                    <div className = "mb-3 w-full">
                                                        <Disclosure.Button className="bg-blue-900 p-2 w-auto w-full text-left rounded">
                                                            <span style={{ "display": "flex", "justifyContent": "space-between" }}>
                                                                <span className ="text-yellow-300">
                                                                    <KeyboardArrowDownIcon
                                                                        className={`${open ? "transform rotate-180" : ""}`}
                                                                    />
                                                                    {cluster.type === 'car' ? cluster?.data?.length + ' Vehicles at: (' + cluster.lat + ',' + cluster.lng + ') ' 
                                                                    : cluster.type === 'person' ? cluster?.data?.length + ' People at: (' + cluster.lat + ',' + cluster.lng + ')'
                                                                    : cluster.type === 'personNearbyRes' ? cluster?.data?.length + ' People at: (' + cluster.lat + ',' + cluster.lng + ')'
                                                                    : cluster.type === 'shooting' ? cluster?.data?.length + ' Shootings at: (' + cluster.lat + ',' + cluster.lng + ')'
                                                                    : cluster.type === 'device' ? cluster?.data?.length + ' Devices at: (' + cluster.lat + ',' + cluster.lng + ')'
                                                                    : cluster.type === 'statute' ? cluster?.data?.length + ' Statutes at: (' + cluster.lat + ',' + cluster.lng + ')'
                                                                    : cluster.type === 'infocop' ? cluster?.data?.length + ' Infocop interactions at: (' + cluster.lat + ',' + cluster.lng + ')'
                                                                    : cluster.type === 'stolenCar' ? cluster?.data?.length + ' Stolen Vehicles at: (' + cluster.lat + ',' + cluster.lng + ')'
                                                                    : cluster.type}
                                                                </span>
                                                            </span>
                                                        </Disclosure.Button>
    
                                                        <Disclosure.Panel className="text-gray-500 p-4 border 2px black">
                                                            {cluster.data && cluster.data.length > 0 ?
                                                                <div className={"border 1px black"}>
                                                                    <TableContainer component={Paper}>
                                                                        <Table sx={{ minWidth: 650 }} size="small">
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    {cluster.type === 'car' || cluster.type === 'stolenCar' ? <TableCell>Date</TableCell> : <></>}
                                                                                    {cluster.type === 'car' || cluster.type === 'stolenCar' ? <TableCell>Plate</TableCell> : <></>}
                                                                                    {cluster.type === 'car' || cluster.type === 'stolenCar' ? <TableCell>ALPR Source</TableCell> : <></>}
                                                                                    {cluster.type === 'person' ? <TableCell>Name</TableCell> : <></>}
                                                                                    {cluster.type === 'person' ? <TableCell>Arrest Date</TableCell> : <></>}
                                                                                    {cluster.type === 'person' ? <TableCell>Statute</TableCell> : <></>}
                                                                                    {cluster.type === 'personNearbyRes' ? <TableCell>Distance (in miles)</TableCell> : <></>}
                                                                                    {cluster.type === 'personNearbyRes' ? <TableCell>Name</TableCell> : <></>}
                                                                                    {cluster.type === 'personNearbyRes' ? <TableCell>Address</TableCell> : <></>}
                                                                                    {cluster.type === 'shooting' ? <TableCell>Inc Type</TableCell> : <></>}
                                                                                    {cluster.type === 'shooting' ? <TableCell>Date</TableCell> : <></>}
                                                                                    {cluster.type === 'shooting' ? <TableCell>Dept</TableCell> : <></>}
                                                                                    {cluster.type === 'shooting' ? <TableCell>Victims</TableCell> : <></>}
                                                                                    {cluster.type === 'shooting' ? <TableCell>Suspects</TableCell> : <></>}
                                                                                    {cluster.type === 'device' ? <TableCell>Device ID</TableCell> : <></>}
                                                                                    {cluster.type === 'device' ? <TableCell>Date</TableCell> : <></>}
                                                                                    {cluster.type === 'device' ? <TableCell>Distance</TableCell> : <></>}
                                                                                    {cluster.type === 'device' ? <TableCell>Horizontal Accuracy</TableCell> : <></>}
                                                                                    {cluster.type === 'statute' ? <TableCell>Name</TableCell> : <></>}
                                                                                    {cluster.type === 'statute' ? <TableCell>Statute</TableCell> : <></>}
                                                                                    {cluster.type === 'statute' ? <TableCell>Offense Date</TableCell> : <></>}
                                                                                    {cluster.type === 'statute' ? <TableCell>City</TableCell> : <></>}
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                {cluster.data.map((pin:Pin) => (
                                                                                    <TableRow
                                                                                        key={pin.plate}
                                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                                    >
                                                                                        {cluster.type === 'car' || cluster.type === 'stolenCar' ? <TableCell>{formatDT(pin.date)}</TableCell> : <></>}
                                                                                        {cluster.type === 'car' || cluster.type === 'stolenCar' ? <TableCell>{pin.plate}</TableCell> : <></>}
                                                                                        {cluster.type === 'car' || cluster.type === 'stolenCar' ? <TableCell>{pin.alprSource}</TableCell> : <></>}
                                                                                        {cluster.type === 'person' ? <TableCell>{pin.name}</TableCell> : <></>}
                                                                                        {cluster.type === 'person' ? <TableCell>{formatDate(pin.date)}</TableCell> : <></>}
                                                                                        {cluster.type === 'person' ? <TableCell>{pin.statute}</TableCell> : <></>}
                                                                                        {cluster.type === 'personNearbyRes' ? <TableCell>{pin.arrestCount}</TableCell> : <></>}
                                                                                        {cluster.type === 'personNearbyRes' ? <TableCell>{pin.name}</TableCell> : <></>}
                                                                                        {cluster.type === 'personNearbyRes' ? <TableCell>{pin.address} {pin.zip} {pin.state}</TableCell> : <></>}
                                                                                        {cluster.type === 'shooting' ? <TableCell>{pin.inc_type}</TableCell> : <></>}
                                                                                        {cluster.type === 'shooting' ? <TableCell>{formatDT(pin.date)}</TableCell> : <></>}
                                                                                        {cluster.type === 'shooting' ? <TableCell>{pin.dept}</TableCell> : <></>}
                                                                                        {cluster.type === 'shooting' ? <TableCell>{pin.victims ? pin.victims : 'UNK.'}</TableCell> : <></>}
                                                                                        {cluster.type === 'shooting' ? <TableCell>{pin.suspect ? pin.suspect : 'UNK.'}</TableCell> : <></>}
                                                                                        {cluster.type === 'device' ? <TableCell>{pin.device}</TableCell> : <></>}
                                                                                        {cluster.type === 'device' ? <TableCell>{formatDT(pin.date)}</TableCell> : <></>}
                                                                                        {cluster.type === 'device' ? <TableCell>{pin.distance}</TableCell> : <></>}
                                                                                        {cluster.type === 'device' ? <TableCell>{pin.accuracy}</TableCell> : <></>}
                                                                                        {cluster.type === 'statute' ? <TableCell>{pin.name}</TableCell> : <></>}
                                                                                        {cluster.type === 'statute' ? <TableCell>{pin.statute}</TableCell> : <></>}
                                                                                        {cluster.type === 'statute' ? <TableCell>{formatDate(pin.offense)}</TableCell> : <></>}
                                                                                        {cluster.type === 'statute' ? <TableCell>{pin.city}</TableCell> : <></>}
                                                                                    </TableRow>
                                                                                ))}
                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableContainer>
                                                                </div>
                                                            : 
                                                                <></>
                                                            }
                                                        </Disclosure.Panel>
                                                    </div>
                                                )}
                                            </Disclosure>
                                        )
                                   : <></>}
                                </TabPanel>
                            </TabContext>
                        </div>
                        <div className="col justify-self-end">
                            <Button onClick={handleOpen} color={"error"}><CloseIcon/></Button>
                        </div>
                    </span>
                    
                    
                </>
            ) : <></> }
        </Box>
    );

    return (
        <div>
            <React.Fragment key={'right'}>
                <Drawer
                    anchor={'right'}
                    open={open}
                    onClose={handleOpen}
                    variant="persistent"
                >
                    {list('right')}
                </Drawer>
            </React.Fragment>
        </div>
    );
}

export default GeoCode