import React, { forwardRef } from "react";

import {
    Autocomplete,
    Box,
    Button,
    ButtonGroup, Checkbox,
    FormControl, FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    ToggleButton,
    ToggleButtonGroup, Tooltip
} from "@mui/material";
import Slide, { SlideProps } from '@mui/material/Slide';
import { useHistory } from "react-router-dom";
import { SiteName } from "../../../services/config.service";
import { GetDefaultStateForSite, GetStringNameForSite } from "../../../interfaces/config.interface";
import { getDropDownCity, getDropDownQuery } from "../../../services/getDropdown.service";
import {formatInputDate} from "../../../services/formatDate.service";
import ArrestDropDowns from "../arrest/_arrestDropDowns";

interface TSCSearchProps {
    SubmitSearch: any,
    passedData?: any | null,
    RefreshTrigger: any
}

interface TSCSearchRef {
    handleSubmit: any
}

const TSCReportSearch = forwardRef<TSCSearchRef, TSCSearchProps>(({ SubmitSearch, RefreshTrigger, passedData = null }, ref) => {
    let today: Date = new Date();
    var toDate = new Date();
    var prevDate = new Date();
    var fromday = new Date();
    if (toDate.getDay() === 1) {
        prevDate = new Date(toDate.setDate(toDate.getDate() - 2));
        fromday = prevDate //Default - Previous weekend
    }
    else{
        prevDate = new Date(toDate.setDate(toDate.getDate() - 1));
        fromday = prevDate //Default - Previous weekend
        //fromday = toDate;
    }
        
    
    const [params, setParams] = React.useState({ FirstName: "", MI: "", LastName: "", DOB: "", FBI: "", SBI: "", toDate: formatInputDate(today), fromDate: formatInputDate(fromday), systemCall: false })
    const [dateType, setDateType] = React.useState<string>("Entry Date");
    const [andOrType, setAndOrType] = React.useState<string>("AND");
    const [dropDownParams, setDropDownParams] = React.useState<any>({});
    const [clear, setClear] = React.useState<number>(0);
    const [initialRun, setInitialRun] = React.useState<boolean>(false)
    const [hideJail, setHideJail] = React.useState<boolean>(false)
    
    /*const [params, setParams] = React.useState({
        toDate: "", fromDate: "", srn: "", firstName: "", lastName: "", nuin: "", docNo: "", finNo: "",
        sidNo: "", plateNum: "", dlNumber: "", state: "", ssn: "", dob: "", SystemCall: false
    });*/
    
    React.useEffect(() => {
        if (!initialRun) {
            setInitialRun(true)
            let p = { ...params }
            p.systemCall = true
            SubmitSearch(p, dateType, andOrType, dropDownParams)
        }
    }, [])

    const handleDateType = (event: React.MouseEvent<HTMLElement>, dateType: string) => {
        if (dateType) {
            setDateType(dateType);
        }
    };

    const HandleDropDowns = (dropDowns: any) => {
        setDropDownParams(dropDowns)
    }

    const handleAndOrType = (event: React.MouseEvent<HTMLElement>, andOrType: string) => {
        if (andOrType)
            setAndOrType(andOrType);
    };
    
    
    
    
    const [stateList, setStateList] = React.useState<any>([]);
    const [cityList, setCityList] = React.useState<any>([]);
    const history = useHistory();

    React.useImperativeHandle(ref, () => ({
        handleSubmit: () => handleClear(),
    }))

    const handleInitialBuild = async () => {
        await handleStateDropdown()
        handleSearch()
    }

    const handleStateDropdown = async () => {
        setStateList([])
        var state = await getDropDownQuery('State')
        if (state && state?.length > 0) {
            setStateList(state)
        }
        var city = await getDropDownCity(GetDefaultStateForSite(SiteName))
        let tempList = city?.filter(x => x.State === GetDefaultStateForSite(SiteName))
        const ids = tempList.map(({ City }) => City);
        const filtered = tempList.filter(({ City }, index) =>
            !ids.includes(City, index + 1));
        setCityList(filtered)
    }

    React.useEffect(() => {
        handleInitialBuild()
    }, [passedData, RefreshTrigger])

    /*
    const handleClear = () => {
        setParams({
            ...params, toDate: "", fromDate: "", srn: "", firstName: "", lastName: "", nuin: "", docNo: "",
            finNo: "", sidNo: "", plateNum: "", dlNumber: "", ssn: "", dob: "", SystemCall: false
        })
        history.push('/tools/terrorist_screening');
    }*/

    const handleClear = () => {
        setParams({ ...params, FirstName: "", MI: "", LastName: "", DOB: "", FBI: "", SBI: "", toDate: formatInputDate(today), fromDate: formatInputDate(fromday), systemCall: false })
        setDateType("Entry Date")
        setAndOrType("AND")
        setHideJail(false)

        setClear(clear + 1)
    }
    
    /*const handleSearch = () => {
        console.log(params)
        SubmitSearch(params)
    }*/
    
    const handleSearch = () => {
        let dropDownParam = {...dropDownParams};
        try{
            if(dropDownParam && dropDownParam.City)
                dropDownParam.City = Object?.keys(dropDownParam.City)?.filter((key) => !key?.includes('BT_UNIQUE_GROUP'))
        }catch (E){}
       
        SubmitSearch(params, dateType, andOrType, dropDownParam)
    }

    const handleHideJail = (value: any) => {
        setHideJail(value)
        //HandleHideJail(value)
    }
    

    return (
        <>
            <div className="bg-gray-100 ml-5 mr-5 rounded p-5">
                <div className="grid grid-cols-7 gap-x-4 gap-y-4">
                    <ToggleButtonGroup
                        color="primary"
                        value={dateType}
                        exclusive
                        size="small"
                        fullWidth
                        onChange={handleDateType}
                        sx={{mr: 2}}
                    >
                        <ToggleButton value="Report Date" sx={{pr: 2, pl: 2}}>Report Date</ToggleButton>
                        <ToggleButton value="Entry Date" sx={{pr: 2, pl: 2}}>Entry Date</ToggleButton>
                    </ToggleButtonGroup>
                    <div>
                        <TextField variant="outlined" margin='none' type={"date"} fullWidth
                                   size={"small"} label="From Date"
                                   InputLabelProps={{shrink: true}}
                                   inputProps={{style: {textAlign: 'center'}}}
                                   value={params.fromDate}
                                   onChange={(e: any) => {
                                       setParams({...params, fromDate: e.target.value})
                                   }}
                        />
                    </div>
                    <div>
                        <TextField variant="outlined" margin='none' type={"date"} fullWidth
                                   size={"small"} label="To Date"
                                   InputLabelProps={{shrink: true}}
                                   inputProps={{style: {textAlign: 'center'}}}
                                   value={params.toDate}
                                   onChange={(e: any) => {
                                       setParams({...params, toDate: e.target.value})
                                   }}
                        />
                    </div>
                </div>
                <div className="grid grid-cols-7 gap-x-4 gap-y-4" style={{"paddingTop": "10px", marginTop: '10px'}}>
                    <div><TextField margin='none' size={"small"} fullWidth label="First Name" value={params.FirstName}
                                    onChange={(e: any) => setParams({...params, FirstName: e.target.value})}/></div>
                    <div><TextField margin='none' size={"small"} fullWidth label="Middle Initial" value={params.MI}
                                    onChange={(e: any) => setParams({...params, MI: e.target.value})}/></div>
                    <div><TextField margin='none' size={"small"} fullWidth label="Last Name" value={params.LastName}
                                    onChange={(e: any) => setParams({...params, LastName: e.target.value})}/></div>
                    <div><TextField margin='none' size={"small"} fullWidth type="date" label="DOB"
                                    InputLabelProps={{shrink: true}} value={params.DOB}
                                    onChange={(e: any) => setParams({...params, DOB: e.target.value})}/></div>
                    <div><TextField margin='none' size={"small"} fullWidth label="FBI #" value={params.FBI}
                                    onChange={(e: any) => setParams({...params, FBI: e.target.value})}/></div>
                    <div><TextField margin='none' size={"small"} fullWidth
                                    label={GetStringNameForSite(SiteName, "SBI #")} value={params.SBI}
                                    onChange={(e: any) => setParams({...params, SBI: e.target.value})}/></div>
                </div>
                <div className="grid grid-cols-1 gap-0.5 gap-y-2" style={{"marginTop": "25px"}}>
                    <div><ArrestDropDowns HandleDropDowns={HandleDropDowns} clear={clear} tool={"TSC"}/></div>
                </div>
                <div className={"pt-3"} style={{"display": "flex", "justifyContent": "end"}}>
                    <ButtonGroup size="large" color="inherit">
                        <Button type="submit" onClick={handleSearch}>Search</Button>
                        <Button onClick={handleClear}>CLEAR</Button>
                    </ButtonGroup>
                </div>
            </div>
            
            {/*
            <div className="bg-gray-100 mt-5 ml-5 mr-5 p-5">
                <Box className="pt-3" sx={{ pl: 2 }}>
                    <div className="grid grid-cols-4 gap-6">
                        <div className="col-span-1">
                            <TextField fullWidth margin='none' type="date" label="From Date" InputLabelProps={{ shrink: true }} value={params.fromDate} onChange={(e: any) => setParams({ ...params, fromDate: e.target.value.toLocaleString() })} />
                        </div>
                        <div className="col-span-1">
                            <TextField fullWidth margin='none' type="date" label="Date of Birth" InputLabelProps={{ shrink: true }} value={params.dob} onChange={(e: any) => setParams({ ...params, dob: e.target.value.toLocaleString() })} />
                        </div>
                        <div className="col-span-1">
                            <TextField fullWidth margin='none' label="Service Request Number" InputLabelProps={{ shrink: true }} value={params?.srn} onChange={(e: any) => setParams({ ...params, srn: e.target.value })} />
                        </div>
                        <div className="col-span-1">
                            <TextField fullWidth margin='none' label="National Unique Identifying Number" InputLabelProps={{ shrink: true }} value={params?.nuin} onChange={(e: any) => setParams({ ...params, nuin: e.target.value })} />
                        </div>

                        <div className="col-span-1">
                            <TextField fullWidth margin='none' type="date" label="To Date" InputLabelProps={{ shrink: true }} value={params.toDate} onChange={(e: any) => setParams({ ...params, toDate: e.target.value.toLocaleString() })} />
                        </div>
                        <div className="col-span-1">
                            <TextField fullWidth margin='none' label="Social Security Number" InputLabelProps={{ shrink: true }} value={params?.ssn} onChange={(e: any) => setParams({ ...params, ssn: e.target.value })} />
                        </div>
                        <div className="col-span-1">
                            <TextField fullWidth margin='none' label="First Name" InputLabelProps={{ shrink: true }} value={params?.firstName} onChange={(e: any) => setParams({ ...params, firstName: e.target.value })} />
                        </div>
                        <div className="col-span-1">
                            <TextField fullWidth margin='none' label="Last Name" InputLabelProps={{ shrink: true }} value={params?.lastName} onChange={(e: any) => setParams({ ...params, lastName: e.target.value })} />
                        </div>

                        <div className="col-span-1"></div>
                        <div className="col-span-1">
                            <TextField fullWidth margin='none' label="Department of Corrections #" InputLabelProps={{ shrink: true }} value={params?.docNo} onChange={(e: any) => setParams({ ...params, docNo: e.target.value })} />
                        </div>
                        <div className="col-span-1">
                            <TextField fullWidth margin='none' label="Fingerprint Identification #" InputLabelProps={{ shrink: true }} value={params?.finNo} onChange={(e: any) => setParams({ ...params, finNo: e.target.value })} />
                        </div>
                        <div className="col-span-1">
                            <TextField fullWidth margin='none' label="State Identification #" InputLabelProps={{ shrink: true }} value={params?.sidNo} onChange={(e: any) => setParams({ ...params, sidNo: e.target.value })} />
                        </div>

                        <div className="col-span-1 flex justify-center">
                            <ButtonGroup size="large" color="inherit">
                                <Button type="submit" onClick={handleSearch} className="w-24 h-12">Search</Button>
                                <Button onClick={handleClear} className="w-24 h-12">CLEAR</Button>
                            </ButtonGroup>
                        </div>
                        <div className="col-span-1">
                            <TextField fullWidth margin='none' label="License Number" InputLabelProps={{ shrink: true }} value={params?.dlNumber} onChange={(e: any) => setParams({ ...params, dlNumber: e.target.value })} />
                        </div>
                        <div className="col-span-1">
                            <TextField fullWidth margin='none' label="Plate Number" InputLabelProps={{ shrink: true }} value={params?.plateNum} onChange={(e: any) => setParams({ ...params, plateNum: e.target.value })} />
                        </div>
                        <div className="col-span-1">
                            <Autocomplete
                                id="free-solo-demo"
                                autoComplete={true}
                                options={stateList.map((option: any) => option.Key)}
                                value={params?.state ?? ""}
                                onChange={(event, newValue) => setParams({ ...params, state: newValue as string })}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="State (for license and plate)"
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />
                        </div>
                        
                    </div>
                </Box>
            </div>
*/}
        </>
    )
})

export default TSCReportSearch;
