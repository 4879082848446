import React from "react";
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import {
    Button,
    ButtonGroup,
    FormControl,
    InputLabel,
    MenuItem,
    Select, Snackbar,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import ArrestDropDowns, {VinDropDowns} from "../arrest/_arrestDropDowns";
import Slide, { SlideProps } from '@mui/material/Slide';
import StolenVehicleSearch from "../stolenVehicle/_stolenVehicleSearch";
import {formatInputDate} from "../../../services/formatDate.service";
import {GetStringNameForSite} from "../../../interfaces/config.interface";
import {SiteName} from "../../../services/config.service";
type TransitionProps = Omit<SlideProps, 'direction'>;

interface StolenVehicleSearchProps {
    SubmitSearch: any
}

interface searchObject {
    option : string
    value : any
    type : string
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TransitionDown(props: TransitionProps) {
    return <Slide {...props} direction="down" />;
}

const DuplicatePersonSearch: React.FC<StolenVehicleSearchProps> = ({ SubmitSearch }) => {
    let today: Date = new Date();
    var toDate = new Date();
    var prevDate = new Date();
    var fromday = new Date();
    if (toDate.getDay() === 1) {
        prevDate = new Date(toDate.setDate(toDate.getDate() - 2));
        fromday = prevDate //Default - Previous weekend
    }
    else
        fromday = toDate;
    const [params, setParams] = React.useState({ DL: "", FBI: "", SBI: "", SSN:"", FName:"", MName:"", LName:"", DOB:"", toDate: formatInputDate(today), fromDate: formatInputDate(fromday), systemCall:false })
    const [dateType, setDateType] = React.useState<string>("Entry Date");
    const [clear, setClear] = React.useState<number>(0);
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false)

    const [options, setOptions] = React.useState<number>(1)
    const [optionList] = React.useState<string[]>(["Date", "DL #", "FBI #", "Name & DOB", GetStringNameForSite(SiteName,'SBI #'), "SSN"])
    const [searchOptions, setSearchOptions] = React.useState<searchObject[]>([{option:"Date", value:0, type:"Date"}])
    const [initialRun, setInitialRun] = React.useState<boolean>(false)

    React.useEffect(() => {
        if(!initialRun)
        {
            setInitialRun(true)
            let p = {...params}
            p.systemCall = true
            SubmitSearch(p, dateType, searchOptions.some(e => e.option == "Date"))
        }
    }, [])
    
    
    const handleDateType = (event: React.MouseEvent<HTMLElement>, dateType: string) => {
        if (dateType) {
            setDateType(dateType);
        }
    };

    const handleClear = async () => {
        await setSearchOptions([])
        setParams({ ...params, DL: "", FBI: "", SBI: "", SSN:"", FName:"", MName:"", LName:"", DOB:"", toDate: formatInputDate(today), fromDate: formatInputDate(fromday), systemCall:false })
        setDateType("Entry Date")
        setClear(clear + 1)
        setSearchOptions([{option:"Date", value:0, type:"Date"}])
        setOptions(options +1)
    }

    const handleSearch = () => {
        if(!searchOptions.some(e => e.option == "")) {
            SubmitSearch(params, dateType, searchOptions.some(e => e.option == "Date"))
        }
        else {
            setAlertOpen(true)
        }
    }

    const removeOption = (indx:number) => {
        let temp = searchOptions
        if(temp[indx]?.type == 'Name')
        {
            let t = params
            t.FName = ""
            t.MName = ""
            t.LName = ""
            t.DOB = ""
            setParams(t)
        }
        handleValueChange(temp[indx]?.option, "", indx)
        let del = temp.splice(indx, 1)
        setSearchOptions(temp)
        setOptions(options +1)
    }
    
    const handleOptions = () => {
        let temp = searchOptions
        temp.push({option:"", value:"", type:""})
        setSearchOptions(temp)
        setOptions(options +1)
    }

    const changeOption = (selected:string, indx:number) => {
        let temp = searchOptions
        if(temp[indx]?.type == 'Name')
        {
            let t = params
            t.FName = ""
            t.MName = ""
            t.LName = ""
            t.DOB = ""
            setParams(t)
        }
        handleValueChange(temp[indx]?.option, "", indx)
        temp[indx] = {option:selected, value:"", type:selected == "Date" ? "Date" : selected == "Name & DOB" ? "Name" : "String"}
        setSearchOptions(temp)
        setOptions(options + 1)
    }

    const handleValueChange = (type:string, value:string, indx:number) =>{
        let t:string = type.split("#")[0]
        let temp = searchOptions
        temp[indx] = {option:temp[indx].option, value:value, type:temp[indx].type}
        switch(t.trim()){
            case("DL"):
                setParams({...params, DL: value})
                break
            case("FBI"):
                setParams({...params, FBI: value})
                break
            case(GetStringNameForSite(SiteName,'SBI')):
                setParams({...params, SBI: value})
                break
            case("SSN"):
                setParams({...params, SSN: value})
                break
        }
        setSearchOptions(temp)
        setOptions(options +1)
    }

    return (
        <div className="bg-gray-100 ml-5 mr-5 rounded p-5">
            <Snackbar open={alertOpen} TransitionComponent={TransitionDown} anchorOrigin={{vertical:'top', horizontal:'center'}} autoHideDuration={6000} onClose={ () => { setAlertOpen(false)}}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={"error"} sx={{ width: '100%' }}>
                    {"One or More Search Condition is Invalid"}
                </Alert>
            </Snackbar>
            <div className = "flex">
                <ToggleButtonGroup
                    color="primary"
                    value={dateType}
                    exclusive
                    size="small"
                    onChange={handleDateType}
                    sx={{ mt: 1, mr: 2 }}
                >
                    <ToggleButton value="Arrest Date" sx={{ pr: 2, pl: 2 }} >Arrest Date</ToggleButton>
                    <ToggleButton value="Entry Date" sx={{ pr: 2, pl: 2 }} >Entry Date</ToggleButton>
                </ToggleButtonGroup>
                {/*<ToggleButtonGroup
                    color="primary"
                    value={vinType}
                    exclusive
                    size="small"
                    onChange={handleVinType}
                    sx={{ mt: 1}}
                >
                    <ToggleButton value="VIN" sx={{ pr: 2, pl: 2 }} >VIN</ToggleButton>
                    <ToggleButton value="No VIN" sx={{ pr: 2, pl: 2 }} >No VIN</ToggleButton>
                </ToggleButtonGroup>*/}
                <div className = "mt-2">
                    <IconButton size={"large"} onClick={handleOptions} color ={"inherit"} title={"Add Search Condition"}> <AddIcon/> </IconButton>
                </div>
            </div>
            {searchOptions.length > 0 ?
                ((searchOptions) ? searchOptions.map((obj, indx) => (
                    <div className="mt-5 flex">
                        <FormControl sx={{minWidth: 300, marginRight: 2}} size="small">
                            <InputLabel id="search-option">Search Option</InputLabel>
                            <Select
                                labelId="search-option"
                                value={obj.option}
                                label="Search Option"
                                onChange={(e) => changeOption(e.target.value, indx)}
                            >
                                {optionList.map((option) => (
                                    <MenuItem value={option} disabled={searchOptions.some(e => e.option == option)}>{option}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {obj.option && obj.option.length > 0 ?
                            (obj.type == "Date" ?
                                <span className={"flex gap-x-4"} style={{width: '30%'}}>
                                    <span className={"flex-1"}>
                                        <TextField variant="outlined" margin='none' type={"date"} fullWidth
                                                   size={"small"} label="From Date"
                                                   InputLabelProps={{shrink: true}}
                                                   inputProps={{style: {textAlign: 'center'}}}
                                                   value={params.fromDate}
                                                   onChange={(e: any) => {
                                                       setParams({...params, fromDate: e.target.value})
                                                   }}
                                        />
                                    </span>
                                    <span className={"flex-1"}>
                                        <TextField variant="outlined" margin='none' type={"date"} fullWidth
                                                   size={"small"} label="To Date"
                                                   InputLabelProps={{shrink: true}}
                                                   inputProps={{style: {textAlign: 'center'}}}
                                                   value={params.toDate}
                                                   onChange={(e: any) => {
                                                       setParams({...params, toDate: e.target.value})
                                                   }}
                                        />
                                    </span>
                                </span>
                                :
                                obj.type == 'Name' ?
                                    <span>
                                   <TextField
                                       sx={{minWidth: 100, marginRight: 2}}
                                       className="mx-2"
                                       label={'First Name'}
                                       value={params?.FName}
                                       onChange={(e: any) => {
                                           setParams({...params, FName: e.target.value})
                                       }}
                                       size="small"
                                   /> 
                                    <TextField
                                        sx={{minWidth: 100, marginRight: 2}}
                                        className="mx-2"
                                        label={"Middle Name"}
                                        value={params?.MName}
                                        onChange={(e: any) => {
                                            setParams({...params, MName: e.target.value})
                                        }}
                                        size="small"
                                    />
                                    <TextField
                                        sx={{minWidth: 100, marginRight: 2}}
                                        className="mx-2"
                                        label={"Last Name"}
                                        value={params?.LName}
                                        onChange={(e: any) => {
                                            setParams({...params, LName: e.target.value})
                                        }}
                                        size="small"
                                    />
                                    <TextField
                                        margin='none'
                                        size={"small"}
                                        type="date"
                                        label="DOB"
                                        InputLabelProps={{shrink: true}}
                                        value={params.DOB}
                                        onChange={(e: any) => {
                                            setParams({...params, DOB: e.target.value})
                                        }}/>
                                </span>
                                :
                                <TextField
                                    sx={{minWidth: 510}}
                                    className="mx-2"
                                    label={obj.option}
                                    value={obj.value}
                                    type={obj.type}
                                    onChange={(e) => {
                                        handleValueChange(obj.option, e.target.value, indx)
                                    }}
                                    size="small"
                                />
                            )
                            :
                            <></>
                        }
                        <span className="ml-10 inline">
                            <IconButton aria-label="delete" size="small" onClick={() => removeOption(indx)}
                                        color={"error"} title="Remove Search Condition">
                                <DeleteIcon/>
                            </IconButton>
                        </span>
                    </div>

                )) : <></>)
                :
                <div className="text-lg text-gray-500 mt-5"
                     style={{"display": "flex", "justifyContent": "center"}}>Please add Search Condtions</div>
            }
            <div className={"pt-3"} style={{"display": "flex", "justifyContent": "end"}}>
                <ButtonGroup size="large" color="inherit">
                    <Button type="submit" onClick={handleSearch} disabled={searchOptions.length == 0}>Search</Button>
                    <Button onClick={handleClear}>CLEAR</Button>
                </ButtonGroup>
            </div>
        </div>
    );
}


export default DuplicatePersonSearch;