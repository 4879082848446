import React from 'react'
import {
    Button, Collapse, IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow, Tooltip,
    Switch,
    FormControlLabel,
    FormGroup,
    FormControl, ButtonGroup, Icon, Dialog, Alert, Snackbar
} from "@mui/material";
import { formatDT } from '../../services/formatDate.service';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateUser from "./UpdateUser";
import { RunSetUser } from "../../services/account.service";
import {
    ColumnDirective,
    ColumnsDirective,
    ColumnMenu,
    GridComponent,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search, ExcelExportProperties,
    Resize, Reorder, SortSettingsModel, Column
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import BTPersonReport from "../shared/_BTPersonReport";
import PersonIcon from "../../assets/Images/Person.png";
import VehicleIcon from "../../assets/Images/Vehicle.png";
import UpdateUnitLevel from './UpdateUnitLevel';


interface SearchResultsProps {
    Persons: any[]
    NewUnit: any
}

const SearchResults: React.FC<SearchResultsProps> = ({ Persons, NewUnit }) => {
    const [personHolder, setPersonHolder] = React.useState(Persons);
    const [user, setUser] = React.useState<boolean>(false)
    const [userID, setUserID] = React.useState<string>("0")
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false)
    const [alertText, setAlertText] = React.useState<string>("")
    const [formatData, setFormatData] = React.useState<any>([])

    const handleDataTyping = () => {
        let tempP: any = [...Persons]
        for (let p of tempP) {
            if (p.CreateDate) {
                p.CreateDate = new Date(p.CreateDate)
            }
            if (p.DeptCode) {
                p.DeptCode = p.DeptCode.toUpperCase()
            }
            if (p.DeptName) {
                p.DeptName = p.DeptName.toUpperCase()
            }
            if (p.CreateUID) {
                p.CreateUID = p.CreateUID.toUpperCase()
            }
            if (p.DeptUnitName) {
                p.DeptUnitName = p.DeptUnitName.toUpperCase()
            }

        }
        setFormatData(tempP)
    }


    React.useEffect(() => {
        handleDataTyping()
    }, [Persons])

    React.useEffect(() => {
        if(NewUnit)
            CloseBox(NewUnit, 'Add/Edit')
    }, [NewUnit])

    const CloseBox = (unitObj:any, action:string) => {
        setUser(false)
        if(action === 'Add/Edit' && unitObj){
            let temp = {...unitObj}
            if(grid) {
                // @ts-ignore
                let unitList:any = [...grid?.dataSource]
                if (temp.CreateDate) {
                    temp.CreateDate = new Date(temp.CreateDate)
                }
                if (temp.DeptCode) {
                    temp.DeptCode = temp.DeptCode.toUpperCase()
                }
                if (temp.DeptName) {
                    temp.DeptName = temp.DeptName.toUpperCase()
                }
                if (temp.CreateUID) {
                    temp.CreateUID = temp.CreateUID.toUpperCase()
                }
                if (temp.DeptUnitName) {
                    temp.DeptUnitName = temp.DeptUnitName.toUpperCase()
                }
                if(temp.UniqueID && temp.UniqueID != 0) {
                    let index:number = unitList.findIndex((e: any) => e.UniqueID === temp.UniqueID)
                    if(index >= 0)
                    {
                        unitList[index] = temp
                        grid.dataSource = unitList
                        grid?.refresh()
                    }
                    else {
                        grid.dataSource = unitList.concat(temp)
                        grid?.refresh()
                    }
                }

            }
        }
    }

    const editUser = (id: string) => {
        setUserID(id)
        setUser(true)
        //window.location.href = ("/admin/UpdateUser?ID=" + id)
    }

    const deleteUser = async (person: any) => {
        let temp = [...formatData]
        let p = temp[temp.indexOf(temp.find(element => element?.UniqueID === person?.UniqueID))]
        if (window.confirm("Remove This User Permanently? '" + p.EmpID + "'")) {
            p.EditStatus = "Delete"
            let r = await RunSetUser(p)
            
            if (r.AnyData.IsValid && r.AnyData.ErrorMessage?.length <= 0) {
                setAlertText("Delete Successful '" + p.EmpID + "'")
                setAlertOpen(true)
                temp.splice(temp.indexOf(temp.find(element => element?.UniqueID === person?.UniqueID)), 1)
                setFormatData([])
                setFormatData(temp)
                //GetUserList()
            }
            else {
                setAlertText(r?.AnyData?.ErrorMessage)
            }
        }
    }

    const resendUser = async (person: any) => {
        let temp = [...formatData]
        let p = temp[temp.indexOf(temp.find(element => element?.UniqueID === person?.UniqueID))]
        if (window.confirm("Are You Sure Want To Resend The Login Link To Selected User?\n(This Will Reset The Account For '" + p.EmpID + "')")) {
            p.EditStatus = "Resend"
            let r = await RunSetUser(p)
            
            if (r.AnyData.IsValid && r.AnyData.ErrorMessage?.length <= 0) {
                setAlertText("Reset Successful '" + p.EmpID + "'")
                setAlertOpen(true)
            }
            else {
                setAlertText(r?.AnyData?.ErrorMessage)
            }
        }
    }

    const ActivateDeactivateUser = async (person: any) => {
        let temp = [...formatData]
        let p = temp[temp.indexOf(temp.find(element => element?.UniqueID === person?.UniqueID))]
        p.EditStatus = p.Active === "Active" ? "Inactive" : "Active"
        let r = await RunSetUser(p)
        if (r.AnyData?.IsValid) {
            setAlertOpen(true)
            p.Active = p.Active === "Active" ? "Inactive" : "Active"
            temp[temp.indexOf(temp.find(element => element?.UniqueID === person?.UniqueID))] = p
            setFormatData([])
            setFormatData(temp)
        }
    }

    React.useEffect(() => {
        setPersonHolder(Persons)
    }, [Persons
    ])

    let grid: Grid | null;

    const GridToolsClick = (args: ClickEventArgs) => {
        
        if (grid && args.item.text === "Excel Export") {

            // let excelProp:ExcelExportProperties = {}
            // excelProp.fileName = 'ArrestExport.xlsx'
            //grid.excelExport(excelProp);
            (grid.columns[0] as Column).visible = false;
            (grid.columns[1] as Column).visible = false;
            (grid.columns[5] as Column).visible = false;
            (grid.columns[6] as Column).visible = true;
            (grid.columns[7] as Column).visible = true;
            (grid.columns[8] as Column).visible = true;

            grid.excelExport();

            //grid.excelExport();

        } else if (grid && args.item.text === 'PDF Export') {
            grid.pdfExport();
        }
    }

    const excelExportComplete = () => {
        if (grid) {
            (grid.columns[0] as Column).visible = true;
            (grid.columns[1] as Column).visible = true;
            (grid.columns[5] as Column).visible = true;
            (grid.columns[6] as Column).visible = false;
            (grid.columns[7] as Column).visible = false;
            (grid.columns[8] as Column).visible = false;
        }
    }

    const dataBound = () => {
        if (grid) {
            grid.autoFitColumns([])
        }
    }

    const statusTemplate = (person: any): any => {
        return (
            <span>
                <FormControl component="fieldset" variant="standard">
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch onChange={() => ActivateDeactivateUser(person)} checked={person.Active === "Active"} />
                            }
                            label={person.Active === "Active" ? "Active" : "Inactive"}
                        />
                    </FormGroup>
                </FormControl>
            </span>);
    }

    const actionTemplate = (person: any): any => {
        return (
            <span>
                <ButtonGroup>
                    <Tooltip title="Edit" placement="top" arrow>
                        <IconButton onClick={() => editUser(person.UniqueID)}><EditIcon /></IconButton>
                    </Tooltip>
                {/*    <Tooltip title="Delete" placement="top" arrow>*/}
                {/*        <IconButton onClick={() => deleteUser(person)}><DeleteIcon /></IconButton>*/}
                {/*    </Tooltip>*/}
                {/*    <Tooltip title="Reset User" placement="top" arrow>*/}
                {/*        <IconButton onClick={() => resendUser(person)}><EmailIcon /></IconButton>*/}
                {/*    </Tooltip>*/}
                </ButtonGroup>
            </span>);
    }

    return (
        <div className={" pr-10"}>
            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={() => { setAlertOpen(false) }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity="success" sx={{ width: '100%' }}>
                    {alertText}
                </Alert>
            </Snackbar>
            {formatData && formatData.length > 0 ? <>
                <h4 className={"font-bold pl-5"}> {formatData?.length} Records Found</h4>
                <GridComponent
                    dataSource={formatData}
                    //dataBound={dataBound}
                    allowPaging={true}
                    pageSettings={{ pageSize: 25, pageSizes: [10, 25, 50, 100] }}
                    allowSorting={true}
                    allowMultiSorting={true}
                    allowFiltering={true}
                    filterSettings={{ type: 'CheckBox' }}
                    allowTextWrap={true}
                    textWrapSettings={{ wrapMode: 'Both' }}
                    allowExcelExport={true}
                    allowPdfExport={true}
                    toolbar={['ExcelExport', 'Search']}
                    toolbarClick={GridToolsClick}
                    ref={(g) => { if (g) { grid = g } }}
                    selectionSettings={{
                        persistSelection: true,
                        type: "Multiple",
                        //checkboxOnly: true,
                        mode: "Row"
                    }}
                    allowSelection={true}
                    allowResizing={true}
                    rowHeight={24}
                    excelExportComplete={excelExportComplete}
                >
                    <ColumnsDirective>
                        <ColumnDirective template={actionTemplate} headerText='Action' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='DeptName' headerText='Department' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='DeptUnitName' headerText='Unit Name' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='DeptCode' headerText='Dept Code' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='CreateUID' headerText='Created By' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='CreateDate' headerText='Create Date' format={{ type: 'date', format: 'MM/dd/yyyy' }} width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                    </ColumnsDirective>
                    <Inject services={[ColumnMenu, Sort, Page, Filter, DetailRow, Toolbar, ExcelExport, PdfExport, Search, Resize, Reorder]} />
                </GridComponent>

            </> : <></>}
            <Dialog
                open={user}
                maxWidth={'xl'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <UpdateUnitLevel ID={userID} CloseBox={CloseBox} />
            </Dialog>
        </div>
    )
};
export default SearchResults