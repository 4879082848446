import React from "react";
import { useLocation } from "react-router-dom";
import {JAOS} from "../../services/JAOS.service";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}


function NJPOPMultipleOccurrence() {
    let query = useQuery();
    let jaos = new JAOS();

    let token = query.get("BTAUTH")
    if(token)
        localStorage.setItem("user",token)
    else
        window.location.href = '/logoff'
    
    let ALPRList = query.get("ALPRList")
    if(ALPRList)
    {
        let hash = jaos.objToStack(JSON.parse(decodeURI(ALPRList)))
        window.location.href = '/tools/alpr_multiple_occurrence?ALPRList='+hash
    }
    else
    {
        window.location.href = '/tools/alpr_multiple_occurrence'
    }
        
        
    return (
        <div className={"bg-gray-900 h-screen w-screen"}>
            
        </div>
    );
}

export default NJPOPMultipleOccurrence;