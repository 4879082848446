import React, {useState} from "react";
import ReactECharts from "echarts-for-react";
import { Box, Card, Divider } from "@mui/material";

interface NibinBreakdownDashboardGridProps {
    location:string,
    dbData:any
}


const NibinBreakdownDashboardGrid: React.FC<NibinBreakdownDashboardGridProps> = ({location, dbData}) => {
    var option;

    const handleSeries = () =>
    {
        let series:any = []
        if(dbData?.nibinBreakdown)
        {
            for(let item of dbData.nibinBreakdown)
            {
                    series.push({ value: item.Count, name: item.Type})
            }
        }
        return series
    }
    
    
    option = {
        height:'300',
        grid: {
            left: '3%',
            right: '5%',
            bottom: '3%',
            top: '3%',
            containLabel: true
        },
        toolbox: {
            feature: {
                saveAsImage: { show: true }
            }
        },
        tooltip: {
            trigger: 'item'
        },
        series: [
            {
                name: 'NIBIN Breakdown',
                type: 'pie',
                radius: '50%',
                data: handleSeries(),
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                },
                label:{
                    formatter: '{b}:{@Type} \n({d}%)'
                }
            }
        ]
    };
    
    return (
        <div >                          
            {dbData && dbData?.nibinBreakdown?.length !== 0 ?
                <div>
                        <ReactECharts option={option} />    
                </div>            
                :
                <div className={"text-center text-3xl mt-7 mb-1"} style={{color:"red", fontWeight:500}}>No Data</div>
            }                          
        </div>
        
    );
}

export default NibinBreakdownDashboardGrid;