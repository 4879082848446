import {PersonInfo} from "../../../interfaces/getDarInfo_interface";
import React from "react";
import { Disclosure } from '@headlessui/react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import WeaponTraceInfo from "../../../interfaces/WeaponTraceInfo.interface";
import { TablePagination, CircularProgress, Button } from "@mui/material";
import GunTraceIcon from "../../../assets/Images/GunStat.png";
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent ,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search
} from '@syncfusion/ej2-react-grids';

interface PanelGunStatProps {
    person: PersonInfo,
    GunStatInfo: WeaponTraceInfo[],
    isLoading:boolean
}

const PanelGunStat: React.FC<PanelGunStatProps> = ({ person, GunStatInfo, isLoading }) => {
    const [FormatData, setFormatData] = React.useState(0);

    const handleDataTyping = () => {
        let tempP: any = [...GunStatInfo]
   
        setFormatData(tempP)
    }

    React.useEffect(() => {
        handleDataTyping()
    }, [GunStatInfo])

    return (
        <div className={"m-5 border"}>
            <Disclosure>
                {({ open }) => (
                    <>
                        <Disclosure.Button className="bg-blue-100 p-2 w-auto w-full text-left">
                            <span style={{ "display": "flex", "justifyContent": "space-between" }}>
                                <span>
                                    <KeyboardArrowDownIcon
                                        className={`${open ? "transform rotate-180" : ""}`}
                                    />
                                    <span className={"font-bold"}>Gun Stat <img src={GunTraceIcon} alt={''} width={25} style={{ display: 'inline' }} /> {(isLoading) ? <CircularProgress size={20} style={{ "marginLeft": "5px" }} /> : <></>}</span>
                                </span>
                                <span className={"font-bold mr-2"} >Name: {person?.FName?.toUpperCase()} {person?.MName?.toUpperCase()} {person?.LName?.toUpperCase()} &nbsp;&nbsp;Records: {GunStatInfo?.length}</span>
                            </span>
                        </Disclosure.Button>

                        <Disclosure.Panel className="text-gray-500 p-4">

                            <GridComponent
                                dataSource={FormatData}
                                allowPaging={true}
                                pageSettings={{ pageSize: 50, pageSizes: [10, 25, 50, 100] }}
                                allowSorting={true}
                                allowMultiSorting={true}
                                allowFiltering={true}
                                filterSettings={{ type: 'CheckBox' }}
                                allowTextWrap={true}
                                textWrapSettings={{ wrapMode: 'Both' }}
                                rowHeight={24}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective field='MPDPD' headerText='MPD PD' />
                                    <ColumnDirective field='PDID' headerText='PDID' />
                                    <ColumnDirective field='CustodyStatus' headerText='Custody Status' />
                                    <ColumnDirective field='Gang' headerText='Gang' />
                                    <ColumnDirective field='LastKnownAddress' headerText='Last Known Address' />
                                    <ColumnDirective field='LastKnownPhoneNumber' headerText='Last Known Phone #' />
                                    <ColumnDirective field='State' headerText='State' />
                                </ColumnsDirective>
                                <Inject services={[Sort, Page, Filter]} />
                            </GridComponent>

                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>

    )
};

export default PanelGunStat;