import React, {useState} from "react";
import ShootingRaceDashboardGrid from "./_shootingRaceDashboardGraph";
import DashboardOverallGrid from "./_dashboardOverallGrid";
import {Card, CardContent, FormControl, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import MurdersDashboardGrid from "./_murdersDashboardGraph";
import ShootingHitsDashboardGrid from "./_shootingHitsDashboardGraph";
import CombinedDashboardGraph from "./_combinedDashboardGraph";
import HeatMap from "./_dashboardHeatMap";
import ShootingAgeDashboardGraph from "./_shootingAgeDashboard";
import ShootingVictimDashboardGraph from "./_shootingVictimsDashboard";
import {
    GetDailyShooting,
    RunGetDashboardRefreshToken,
    RunGetDashboardToken,
    RunGetDefaultCity
} from "../../../services/dashboard.service";
import {DashboardMarqueeTimer, DashboardRefresh, DashboardTimer, SiteName} from "../../../services/config.service";
import DashboardPriorGrid from "./_dashboardPriorHistory";
import DashboardFilters from "./_dashboardFilters";
import Marquee from "react-fast-marquee";
import {RunDarQuery} from "../../../services/getDar.service";
import {formatDT} from "../../../services/formatDate.service";
import DashboardMurderDataGrid from "./_dashboardOverallMurderGrid";
import DashboardReferralGrid from "./_dashboardReferralShooting";
import DashboardGoalsGrid from "./_shootingGoalsDashboard";
import {isUserPermission} from "../../../services/auth.service";
import {GetDefaultStateForSite, GetStringNameForSite, SITE} from "../../../interfaces/config.interface";


interface DashboardLayoutProps {
    GetStats:any,
    data:any,
}


const DashboardLayout: React.FC<DashboardLayoutProps> = ({GetStats, data}) => {
    const [location, setLocation] = React.useState<string>("Loading")
    const [county, setCounty] = React.useState<string>("")
    const [dateUpdate, setDateUpdate] = React.useState<boolean>(true)
    let today = new Date()
    let monthAgoDate = new Date();
    monthAgoDate.setDate(monthAgoDate.getDate() - 28);
    const [toDate, setToDate] = React.useState<Date>(today)
    const [fromDate, setFromDate] = React.useState<Date>(monthAgoDate)
    const [initialLoad, setInitialLoad] = React.useState<boolean>(false)
    const [shootingNot, setShootingNot] = React.useState<any[]>([ {time:"", description:"", address:""} ])
    const [cityList, setCityList] = React.useState<any[]>([])
    
    React.useEffect(() => {
        const interval = setInterval(() => {
            if (location !== "" && location !== "Loading") {
                let params = {
                    ToDate: toDate.toISOString(),
                    FromDate: fromDate.toISOString(),
                    City: location,
                    Dept: county,
                }
                GetStats(params)
                if(dateUpdate){
                    let today = new Date()
                    setToDate(today)
                }
            }
        }, DashboardTimer);
        return () => clearInterval(interval);
    }, [location,toDate,fromDate,county]);

    React.useEffect(() => {
        const x = setInterval(async () => {
            let dashboard = await RunGetDashboardRefreshToken()
            if (dashboard !== null && dashboard?.AuthToken) {
                localStorage.setItem("dashboard", dashboard.AuthToken)
            }
        }, DashboardRefresh);
        return () => clearInterval(x);
    }, []);

    React.useEffect(() => {
        const intervalMarq = setInterval(() => {
            if (location !== "" && location !== "Loading") {
                let DarParam = {
                    QueryType: "6",
                    Dept: "CENTRAL",
                    EmpID: "",
                    DeptORI: "NJ000000",
                    DateRange: true,
                    DateTypeValue: 0,
                    FromDate: new Date().toLocaleDateString(),
                    ToDate: new Date().toLocaleDateString(),
                    SearchType: "AND",
                    IndicatorOn: true,
                    TopArrestNumber: 0,
                    FNames: [],
                    MNames: [],
                    LNames: [],
                    DOBs: [],
                    SEX: [],
                    Address: "",
                    Cities: location ? (location === 'WHOLE COUNTY' || location === "WHOLE DISTRICT/COUNTY") ? cityList : (location === 'ALL NJ' || location === 'ALL DC') ? [] : location?.replace(/CITY|BORO|TWP/gi, '').trim().split(",") : [],
                    States: [],
                    Zip: "",
                    Statutes: [],
                    Gang: [],
                    FBIs: [],
                    SBIs: [],
                    DLNos: [],
                    PlateNos: [],
                    VINs: [],
                    AgencyArrested: [],
                    InfocopDept: [],
                    Indicators: [],
                    Latitude: null,
                    Longitude: null,
                    Radius: 0,
                    ResidentCounty: [],
                    ArrestedCounty: [],
                    Arrest_Type: [],
                    CaseNumbers: [],
                    SSN:[],
                    PlaceOfBirth: [],
                    AgeRangeStart: null,
                    AgeRangeEnd: null,
                }
                GetDailyShooting(DarParam).then((response:any) => {
                    if(response.AnyTable) {
                        let p:any = []
                        for (var s of response.AnyTable) {
                            p.push({time:(s?.DateTimeIN) ? s?.DateTimeIN : "", description:(s?.STATUTEDESCRIPTION) ? s?.STATUTEDESCRIPTION : "", address: (s?.Address) ? s?.Address : ""})
                        }
                        setShootingNot(p)
                    }
                })
            }
        }, DashboardMarqueeTimer);
        return () => clearInterval(intervalMarq);
    }, [location]);
    
    React.useEffect( () => {
        if(!initialLoad)
        {
            setInitialLoad(true)
            RunGetDashboardToken().then((dashboard) => {
                if (dashboard !== null && dashboard?.AuthToken) {
                    localStorage.setItem("dashboard", dashboard.AuthToken)
                    RunGetDefaultCity().then((res) => {
                        setLocation(res[0].city)
                    })
                }
            })
        }
    }, []);


    React.useEffect(() => {
        if(location !== "" && location !== "Loading") {
            let params = {
                ToDate: toDate,
                FromDate: fromDate,
                City: location,
                Dept: county,
            }
            GetStats(params)
        }
    },[location,toDate,fromDate,county])

    React.useEffect(() => {
        if(location !== "" && location !== "Loading") {
            
            let DarParam = {
                QueryType: "6",
                Dept: "CENTRAL",
                EmpID: "",
                DeptORI: "NJ000000",
                DateRange: true,
                DateTypeValue: 0,
                FromDate: new Date().toLocaleDateString(),
                ToDate: new Date().toLocaleDateString(),
                SearchType: "AND",
                IndicatorOn: true,
                TopArrestNumber: 0,
                FNames: [],
                MNames: [],
                LNames: [],
                DOBs: [],
                SEX: [],
                Address: "",
                Cities: location ? (location === 'WHOLE COUNTY' || location === "WHOLE DISTRICT/COUNTY") ? cityList : (location === 'ALL NJ' || location === 'ALL DC') ? [] : location?.replace(/CITY|BORO|TWP/gi, '').trim().split(",") : [],
                States: [],
                Zip: "",
                Statutes: [],
                Gang: [],
                FBIs: [],
                SBIs: [],
                DLNos: [],
                PlateNos: [],
                VINs: [],
                AgencyArrested: [],
                InfocopDept: [],
                Indicators: [],
                Latitude: null,
                Longitude: null,
                Radius: 0,
                ResidentCounty: [],
                ArrestedCounty: [],
                Arrest_Type: [],
                CaseNumbers: [],
                SSN:[],
                PlaceOfBirth: [],
                AgeRangeStart: null,
                AgeRangeEnd: null,
            }
            GetDailyShooting(DarParam).then((response:any) => {
                if(response.AnyTable) {
                    let p:any = []
                    for (var s of response.AnyTable) {
                        p.push({time:(s?.DateTimeIN) ? s?.DateTimeIN : "", description:(s?.STATUTEDESCRIPTION) ? s?.STATUTEDESCRIPTION : "", address: (s?.Address) ? s?.Address : ""})
                    }
                    setShootingNot(p)
                }
            })
        }
    },[location,cityList])
    
    const UpdateCityList = (list:any[]) => {
        setCityList(list)
    }

    //BackTrace NJ Shooting Strategic Dashboard 
    return (
        <div>
            {location !== "Loading" ?
            <div>
                  {shootingNot && shootingNot?.length > 0 ? 
                      <Marquee speed = {50} pauseOnHover={true}>
                            {shootingNot?.map((shot, idx) =>
                                <Card sx={{ minWidth: 1000, maxWidth: 1000, maxHeight: 90 }}>
                                    <CardContent className = "text-center">
                                        <Typography sx={{ fontSize: 14 }} variant="caption" component="div">
                                            <span >[{idx + 1}]</span> <span className = "font-bold"> {shot?.address} - {formatDT(shot?.time)}</span>
                                        </Typography>
                                        <Typography sx={{ fontSize: 14 }} variant="h6" component="div">
                                            <span dangerouslySetInnerHTML={{__html:shot?.description}} />
                                            {/*shot?.description*/}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            )}
                    </Marquee>
                : <div className = "text-center font-bold text-3xl mt text-black mt-3"> 
                          <span >BackTrace {GetDefaultStateForSite(SiteName)} Shooting Strategic Dashboard </span>
                          <span className = "text-yellow-400">{(location === "WHOLE COUNTY") ? county.replace(/([A-Z])/g, ' $1').trim() : location}</span>
                </div>}
                <div className = {shootingNot?.length <= 0 ? "mt-3" : ""}>
                    <div className = "float-left" style = {{width:"34.5%"}}>
                        <div className = "ml-10"><HeatMap data={data} location={location}/></div>
                    </div>
                    <div className = "float-right mt" style = {{"width":"15%"}} >
                        <div className={"pr-3"}><DashboardOverallGrid location={location} data={data}/></div>
                        <div className={"mt-2 pr-3"}><DashboardMurderDataGrid location={location} data={data}/></div>
                        {/*<div className={"mt-3 pr-3"}>
                            <DashboardReferralGrid
                                location={location}
                                data={data}
                                toDate={(toDate.getMonth() + 1).toString() + "/" + toDate.getDate().toString() + "/" + toDate.getFullYear().toString()}
                                fromDate={(fromDate.getMonth() + 1).toString() + "/" + fromDate.getDate().toString() + "/" + fromDate.getFullYear().toString()}
                            />
                        </div>*/}
                        <div className={"mt-2 pr-3"}>
                            <DashboardPriorGrid
                                location={location}
                                data={data}
                                toDate={(toDate.getMonth() + 1).toString() + "/" + toDate.getDate().toString() + "/" + toDate.getFullYear().toString()}
                                fromDate={(fromDate.getMonth() + 1).toString() + "/" + fromDate.getDate().toString() + "/" + fromDate.getFullYear().toString()}
                            />
                        </div>
                        <div className={"mt-2 pr-3"}>
                            <DashboardFilters 
                                location={location}
                                UpdateCity={(city:string)=> {setLocation(city)}}
                                CustomDate={(custom:any)=> {setDateUpdate(custom)}}
                                UpdateToDate={(date:any)=> {setToDate(date)}}
                                UpdateFromDate={(date:any)=> {setFromDate(date)}}
                                UpdateCounty={(county:string)=> {setCounty(county)}}
                                UpdatedDateTime={toDate}
                                UpdateCityList={UpdateCityList}
                            />
                        </div>
                        {isUserPermission('NJSPDashboard') && location === 'ALL NJ' ? <div className={"mt-2 pr-3"}><DashboardGoalsGrid location={location} data={data}/></div> : <></>}
                    </div>
                    <div className = "float-right mt pr-3" style = {{"width":"50%"}} >
                        <div className = "grid grid-cols-2 gap-y-1 gap-x-1 auto-rows-32 ">
                            <div className = "col-span-2"><MurdersDashboardGrid location={location} dbData={data}/></div>
                            <div><CombinedDashboardGraph location={location} dbData={data}/></div>
                            <div><ShootingRaceDashboardGrid location={location} dbData={data}/></div>
                            <div ><ShootingAgeDashboardGraph location={location} dbData={data}/></div>
                            <div><ShootingVictimDashboardGraph location={location} dbData={data}/></div>
                        </div>
                    </div>
                </div>
            </div> 
            :
            <></>}
        </div>
    );
}

export default DashboardLayout;
