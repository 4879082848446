import React, {useEffect} from "react";
import {GetPersonAnalysis} from "../../services/linkanalysis.service";
import {useLocation} from "react-router-dom";
import {isUserPermission} from "../../services/auth.service";
import {Button, Menu, MenuItem} from "@mui/material";
import {JAOS} from "../../services/JAOS.service";
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import PersonTimeLineHorizontal from "../../components/LinkAnalysis/_PersonTimeLineHorizontal";
import _PersonTimeLineVertical from "../../components/LinkAnalysis/_PersonTimeLineVertical";
import PersonTimeLineGroupedVertical from "../../components/LinkAnalysis/_PersonTimeLineGroupedVertical";
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function PersonTimeLine() {
    let query = useQuery();

    const [data, setData] = React.useState<any>({type: 'LinkChart', items: []})
    const [loading, setLoading] = React.useState<boolean>(true)
    const [displayMode, setDisplayMode] = React.useState<string>('Horizontal')
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (layout : string | null) => {
        setDisplayMode(layout ? layout : displayMode);
        setAnchorEl(null);
    };

    useEffect(() => {
        if (query && query.get("Hash")) {
            let hash = query.get("Hash");
            let tmpData = JSON.parse(JSON.stringify(data));
            if (hash) {
                let personInfoObj = new JAOS().objFromStack(hash)
                tmpData.items.push(...GetPersonAnalysis(personInfoObj).items)
            }
            let filteredData = [];
            let temp = tmpData.items.filter((item: any) => item.type === "node" && item.dt)
            let temp2 = tmpData.items.filter((item: any) => item.type === "node" && item.dt1)
            filteredData.push(...temp)
            filteredData.push(...temp2)

            //need to sort filteredData by dt/dt1 asc order
            filteredData = filteredData.sort((a: any, b: any) => {
                if (a.dt && b.dt) {
                    return a.dt.getTime() > b.dt.getTime() ? 1 : -1
                } else if (a.dt1 && b.dt1) {
                    return a.dt1.getTime() > b.dt1.getTime() ? 1 : -1
                } else if (a.dt && b.dt1) {
                    return a.dt.getTime() > b.dt1.getTime() ? 1 : -1
                } else if (a.dt1 && b.dt) {
                    return a.dt1.getTime() > b.dt.getTime() ? 1 : -1
                } else {
                    return 0
                }
            })


            setData(filteredData)
        }
        setLoading(false);
    }, []);

    
    return (
        <>
            {<div style={{float:'right', zIndex:'100'}}>
                <Button
                    id="switch_layout_button"
                    aria-controls={open ? 'layout-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <ViewQuiltIcon />
                </Button>
                <Menu
                    id="layout-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => handleClose(null)}
                    MenuListProps={{
                        'aria-labelledby': 'switch_layout_button',
                    }}
                >
                    <MenuItem onClick={()=> handleClose('Horizontal')}>Horizontal</MenuItem>
{/*
                    <MenuItem onClick={()=> handleClose('Vertical')}>Serpentine</MenuItem>
*/}
                    <MenuItem onClick={()=> handleClose('GroupedVertical')}>Grouped Vertical</MenuItem>
                   
                </Menu>
            </div>}
            
            {isUserPermission('Arrest') && !loading && displayMode === 'Vertical' ? <_PersonTimeLineVertical data={data} /> : <></>}
            {isUserPermission('Arrest') && !loading && displayMode === 'GroupedVertical' ? <PersonTimeLineGroupedVertical data={data} /> : <></>}
            {isUserPermission('Arrest') && !loading && displayMode === 'Horizontal'? <PersonTimeLineHorizontal data={data} /> : <></>}
            
        </>
    );
}

export default PersonTimeLine;