import React from "react";
import Login from "../../components/authentication/login/login";
import {SiteName} from "../../services/config.service";
import {GetSSOTypeForSite, SSO_TYPE} from "../../interfaces/config.interface";
import SSOLogin from "../../components/authentication/login/sso_login";

function LoginPage() {
    return (
        GetSSOTypeForSite(SiteName) === SSO_TYPE.DEPT_QUESTION 
        || GetSSOTypeForSite(SiteName) === SSO_TYPE.DEPT_HARDCODED
        || GetSSOTypeForSite(SiteName) === SSO_TYPE.DEPT_USERID_PARSE
            ? <SSOLogin /> : <Login />
    );
}

export default LoginPage;