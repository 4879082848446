import React from "react";
import { useLocation } from "react-router-dom";
import ProfessionDetailInfo from "../../components/Detail/_ProfessionDetailInfo";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

  
function ProfessionDetail() {
    let query = useQuery();

    return (
        <div>
            <ProfessionDetailInfo ID={query.get("ID")} department={query.get("Dept")}/>
        </div>
    );
}

export default ProfessionDetail;