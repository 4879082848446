import React, { useState } from 'react';
import { SiteName } from '../../services/config.service';
import { getUser, isUserPermission } from "../../services/auth.service";
import { getDarInfoResponse, GetDarInfo_Query, PersonInfo, ProfileReportPersonInfo } from "../../interfaces/getDarInfo_interface";
import { Account } from "../../interfaces/auth_interface";
import { RunDarQuery } from "../../services/getDar.service";
import { JAOS } from "../../services/JAOS.service";
import TSCSearchResults from '../../components/tools/terrorist_screening/_searchResultsTSC';
import TSCSearch from '../../components/tools/terrorist_screening/_tscSearch';
import {handleSearchTime} from "../../services/formatDate.service";
import SearchResults from "../../components/tools/jail/_searchResultsJail";


function TSCForm() {
    let [searchResults, setSearchResults] = useState<any[]>([])
    let [user, setUser] = useState<Account>(getUser())
    let [noData, setNoData] = useState<boolean>(false)
    let [hideJail, setHideJail] = useState<boolean>(false)
    const [reloadTriggerCount, setReloadTriggerCount] = useState(0);

    const handleFormSubmit = () => {

    }

    const reloadData = () => {
        setReloadTriggerCount(reloadTriggerCount + 1);
    }

    const SubmitSearch = async (params: any, dateType: string, andOrType: string, dropDowns: any) => {
        let search_query: GetDarInfo_Query = {
            "QueryType": "TSC",
            "Dept": user.Dept,
            "EmpID": user.EmpID,
            "DeptORI": user.DeptORI,
            "DateRange": true,
            "DateTypeValue": (dateType === "Entry Date") ? 0 : 1,
            "FromDate": handleSearchTime(params?.fromDate, 'fromDate')?.toLocaleString(),
            "ToDate": handleSearchTime(params?.toDate, 'toDate')?.toLocaleString(),
            "SearchType": andOrType,
            "IndicatorOn": true,
            "TopArrestNumber": 0,
            "FNames": params.FirstName ? params.FirstName.split(',') : [],
            "MNames": params.MI ? params.MI.split(',') : [],
            "LNames": params.LastName ? params.LastName.split(',') : [],
            "DOBs": params.DOB ? params.DOB.split(',') : [],
            "SEX": dropDowns.Sex ? dropDowns.Sex : [],
            "Address": "",
            "Cities": dropDowns.City ? dropDowns.City : [],
            "IncidentCities": dropDowns.IncidentCity ? dropDowns.IncidentCity : [],
            "States": dropDowns.State ? dropDowns.State : [],
            "Zip": "",
            "Statutes": dropDowns.Statute ? dropDowns.Statute : [],
            "Gang": [],
            "FBIs": params.FBI ? params.FBI.split(',') : [],
            "SBIs": params.SBI ? params.SBI.split(',') : [],
            "DLNos": params.DL ? params.DL.split(',') : [],
            "PlateNos": params.Plate ? params.Plate.split(',') : [],
            "VINs": [],
            "AgencyArrested": dropDowns.AgencyArrested ? dropDowns.AgencyArrested : [],
            "InfocopDept": dropDowns.InfoCopDept ? dropDowns.InfoCopDept : [],
            "Indicators": dropDowns.Indicator ? dropDowns.Indicator : [],
            "IndicatorOccurrence": dropDowns.IndicatorOccurrence ? dropDowns.IndicatorOccurrence : null,
            "Latitude": null,
            "Longitude": null,
            "Radius": 0,
            "ResidentCounty": dropDowns.ResidentCounty ? dropDowns.ResidentCounty : [],
            "ArrestedCounty": dropDowns.ArrestedCounty ? dropDowns.ArrestedCounty : [],
            "Arrest_Type": dropDowns.ArrestType ? dropDowns.ArrestType : [],
            "CaseNumbers": params.Case ? params.Case.split(',') : [],
            "SSN": params.SSN ? params.SSN?.split(',') : [],
            "PlaceOfBirth": dropDowns.PlaceOfBirth ? dropDowns.PlaceOfBirth : [],
            "AgeRangeStart": params.AgeRangeStart,
            "AgeRangeEnd": params.AgeRangeEnd,
            "FTSIDs": params.FTSID ? params.FTSID.split(',') : [],
            "ShootingPersonTypes": dropDowns.ShootingPersonType ? dropDowns.ShootingPersonType : [],
            "PhoneNumbers":  params.Phone ? params.Phone?.replaceAll('+1','')?.replaceAll('-','')?.split(',') : [],
            "SystemCall": params.systemCall ?? false,
        }
        let qRes: getDarInfoResponse = await RunDarQuery(search_query)
        setSearchResults([])
        setSearchResults(qRes.AnyTable)

        if (qRes?.AnyTable?.length === 0) {
            setNoData(true)
        }
        else {
            setNoData(false)
        }
    }

    const HandleHideJail = (value: any) => {
        setHideJail(value)
    }

    return (
        <div id="ToolsArrest">
            {isUserPermission('TSC')  ?
                <>
                    <TSCSearch SubmitSearch={SubmitSearch} RefreshTrigger={reloadTriggerCount} />
                    <TSCSearchResults Records={searchResults} RefreshTrigger={reloadData} />
                </>
                : <></>
            }
        </div>
    )
}

export default TSCForm;