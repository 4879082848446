/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { NJPortDashboardDataApi } from "../../../services/njport.dashboard.service";

interface PDOHireStatsProps {
    HandleRefreshCount: any
    RefreshCount: number
    StartDate: Date,
    EndDate: Date
}

const PDOHireStackedBarChart: React.FC<PDOHireStatsProps> = ({
        HandleRefreshCount,
        RefreshCount,
        StartDate,
        EndDate
    }) => {
    const [data, setData] = useState<any[]>([]);
    const [option, setOption] = useState<any>({});
    //const [checkerRequests, setCheckerRequests] = useState<number>(0);
    //const [checkerAvailable, setCheckerAvailable] = useState<number>(0);
    //const [checkerRemaining, setCheckerRemaining] = useState<number>(0);
    //const [checkerUnfilled, setCheckerUnfilled] = useState<number>(0);    
    //const [refresh, setRefresh] = useState<number>(0);

    useEffect(() => {
        const payload = {
            queryType: "PDOHIRE_DAY",
            dateTypeValue: 1,
            FromDate: StartDate,
            ToDate: EndDate,
        };
        NJPortDashboardDataApi(payload).then((qRes) => {
            setData(qRes?.AnyTable);
        });
    }, [RefreshCount, StartDate, EndDate]);

    useEffect(() => {
        if (data) {
            setOption({
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                    },
                },
                toolbox: {
                    feature: {
                        saveAsImage: { show: true },
                    },
                },
                grid: {
                    left: "1%",
                    right: "1%",
                    bottom: "1%",
                    top: "3%",
                    containLabel: true,
                },
                legend: {
                    //position: 'bottom',
                    //horizontalAlign: 'center',
                    //offsetX: 40,
                    ////orient: "horizontal",
                    ////bottom: "bottom",
                    ////top: "bottom",
                    //////position: "bottom",
                },
                xAxis: {
                    type: "category",
                    axisLabel: {
                        interval: 0,
                        rotate: 45,
                        fontSize: 8,
                    },
                    data: handleFormatXAxis(),
                },
                yAxis: {
                    type: "value",
                    axisLabel: {
                        show: false,
                    },
                    axisLine: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                },
                series: handleSeries(),
            });
        }
    }, [data]);

    const handleFormatXAxis = () => {
        let xAxis: any[] = [];
        data?.forEach((item: any) => {
            if (item?.JobID === "CK") {
                const state = item.StateID;
                xAxis.push(state);
            }
        });
        return xAxis;
    };

    const handleSeries = () => {
        let requested: any = [];
        let hired: any = [];
        let short: any = [];
        let series: any = [];

        data?.forEach((item) => {
            if (item?.JobID === "CK") {
                requested.push(item.Requested);
                hired.push(item.Hired);  
                short.push(item.Short);  
            }
        });
        series.push({
            name: 'Requested',
            type: 'bar',
            stack: true,
            color: 'blue',
            label: {
                show: true,
                position: "inside",
            },
            emphasis: {
                focus: 'series'
            },
            data: requested
        },)
        series.push({
            name: 'Hired',
            type: 'bar',
            stack: true,
            color: 'orange',
            label: {
                show: true,
                position: "inside",
            },
            emphasis: {
                focus: 'series'
            },
            data: hired
        },)
        series.push({
            name: 'Short',
            type: 'bar',
            stack: true,
            color: 'red',
            label: {
                show: true,
                position: "inside",
                //formatter: genFormatter(series),
            },
            emphasis: {
                focus: 'series'
            },
            data: short
        },)
        return series;
    };

    //const genFormatter = (series: any) => {
    //    return (param: any) => {
    //        let sum = 0;
    //        series.forEach((item: any) => {
    //            sum += item.data[param.dataIndex];
    //        });
    //        return sum
    //    }
    //};

    return (
        <>
            {data && data.length !== 0 ? (
                <div>
                    <ReactECharts option={option} style={{ height: "200px" }} />
                </div>
            ) : (
                <div
                    className={"text-center text-base xl:text-3xl mt-6 mb-1"}
                    style={{ color: "red", fontWeight: 500 }}
                >
                    No Data
                </div>
            )}
        </>
    );
};

export default PDOHireStackedBarChart;
