import React from "react";
import ReactECharts from "echarts-for-react";

interface ShootingsDashboardProps {
  location: string;
  dbData: any;
  title: string;
}

const LeftApplicationReceived: React.FC<ShootingsDashboardProps> = ({
  location,
  dbData,
  title,
}) => {
  const handleFormatXAxis = () => {
    let xAxis: any = [];
    if (dbData.AnyTable) {
      dbData.AnyTable.forEach((entry: any) => {
        if (!xAxis.includes(entry.DateGroup_Desc)) {
          xAxis.push(entry.DateGroup_Desc);
        }
      });
    }
    return xAxis;
  };

  const handleSeries = () => {
    let series: any = [];
    let subGroups: any = {};

    if (dbData.AnyTable) {
      dbData.AnyTable
        .filter((entry: any) => entry.SubGroup === "RC")
        .forEach((entry: any) => {
          if (!subGroups[entry.SubGroup]) {
            subGroups[entry.SubGroup] = {
              name: entry.SubGroup,
              type: "line",
              label: {
                show: true,
                position: "top",
              },
              emphasis: {
                focus: "series",
              },
              data: [],
            };
          }
          subGroups[entry.SubGroup].data.push({
            value: entry.Total,
            name: entry.DateGroup_Desc,
          });
        });

      series = Object.values(subGroups);
    }

    return series;
  };

  const xAxisData = handleFormatXAxis();
  const seriesData = handleSeries();

  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "line",
      },
    },
    toolbox: {
      feature: {
        saveAsImage: { show: true },
      },
    },
    legend: {
      data: Object.keys(
        seriesData.reduce((acc: any, series: any) => {
          acc[series.name] = true;
          return acc;
        }, {})
      ),
      top: "0%",
      textStyle: {
        fontSize: 12,
        fontWeight: "bold",
      },
      padding: 10,
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "10%",
      top: "20%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: xAxisData,
      axisLabel: {
        interval: 0,
        rotate: 30,
        fontSize: 10,
      },
    },
    yAxis: {
      type: "value",
      axisLabel: {
        fontSize: 10,
      },
    },
    series: seriesData,
  };

  return (
    <div className="border-gray-900 border-2 h-80">
      <div className="bg-blue-900 font-bold text-yellow-400 text-center h-8 text-lg">
        {title}
      </div>
      {dbData && dbData?.AnyTable?.length !== 0 ? (
        <div className="border-gray-900 border-t-2">
          <ReactECharts option={option} />
        </div>
      ) : (
        <div>No Data</div>
      )}
    </div>
  );
};

export default LeftApplicationReceived;
