import React, {memo, useEffect, useState} from "react";
import {formatDate} from "../../services/formatDate.service";
import noImage from "../../assets/Images/cbimage.png";
import {Alert, AlertColor, Button, ButtonGroup, IconButton, Snackbar, TextField, Tooltip} from "@mui/material";
import DoubleArrowSharpIcon from "@mui/icons-material/DoubleArrowSharp";
import KeyboardArrowLeftSharpIcon from "@mui/icons-material/KeyboardArrowLeftSharp";
import KeyboardArrowRightSharpIcon from "@mui/icons-material/KeyboardArrowRightSharp";
import {GRAILReferral} from "../../interfaces/GRAILReferral.interface";
import {JAOS} from "../../services/JAOS.service";
import {RunGetGRAILReferralGroup, RunSetGRAILReferral} from "../../services/getDar.service";
import {Account} from "../../interfaces/auth_interface";
import {getUser, isUserPermission} from "../../services/auth.service";
import DeleteIcon from "@mui/icons-material/Delete";
import PrintIcon from "@mui/icons-material/Print";
import EditIcon from "@mui/icons-material/Edit";
import { GVRTFGroupInfo } from "../../interfaces/GVRTFEmailList.interface";
import {SiteName} from "../../services/config.service";
import {GetStringNameForSite, SITE} from "../../interfaces/config.interface";

interface DetailProps {
    Persons: any[]
    Additions?: any[]
}

const GVRTFOutputForm: React.FC<DetailProps> = ({Persons, Additions}) => {
    const [Referrals, setReferrals] = React.useState<any[]>([])
    const [pos, setPos] = React.useState<number>(0)
    const [b1color, setB1Color] = React.useState<string>('#FFFFFF')
    const [b2color, setB2Color] = React.useState<string>('#FFFFFF')
    const [b3color, setB3Color] = React.useState<string>('#FFFFFF')
    const [b4color, setB4Color] = React.useState<string>('#FFFFFF')

    //Alerts
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false)
    const [alertText, setAlertText] = React.useState<string>("")
    const [alertType, setAlertType] = React.useState<AlertColor>("success")

    const [count, setCount] = React.useState<number>(0)
    const [distributionOptions, setDistributionOptions] = React.useState<any>([])
    const [groupData, setGroupData] = React.useState<GVRTFGroupInfo>({})

    
    const handleDataTyping = async () =>
    {
        if (Persons) {
            setPos(0)
            let tempP: any = [...Persons]
            for (var i of tempP)
            {
                if(i.DOB)
                {
                    i.DOB = new Date(i.DOB)
                }
                //get option list if there are distribution lists selected
                if(i.Distribution_List && i.Distribution_List.length > 0){
                    let res = await RunGetGRAILReferralGroup(groupData)
                    if(res){
                        setDistributionOptions(res)
                    }                                       
                }
            }
            setReferrals(tempP)
        }
    }

    React.useEffect(() => {
        handleDataTyping()
    },[Persons])
    
    /*useEffect(() => {
        function checkReferralData() {
        function checkReferralData() {
            const item = sessionStorage.getItem("referral")
            if (item) {
                setReferrals([])
                //setReferrals(JSON.parse(item))
            }
            sessionStorage.removeItem("referral")
        }
        window.addEventListener('storage', checkReferralData)

        return () => {
            window.removeEventListener('storage', checkReferralData)
        };
    }, []);*/
    
    const handleEdit = () => {
        let GRAIL:GRAILReferral =
            {
                "PersonID": Referrals[pos]?.PersonID,
                "ID": Referrals[pos]?.ID,
                "TblGunPersonID": Referrals[pos]?.TblGunPersonID,
                "SubmitDate": Referrals[pos]?.SubmitDate,
                "FIRSTNAME": Referrals[pos]?.FName,
                "MI": Referrals[pos]?.MName,
                "LASTNAME": Referrals[pos]?.LName,
                "DOB": Referrals[pos]?.DOB,
                "SSN": null,
                "FBI": Referrals[pos]?.FBI,
                "eCDR": Referrals[pos]?.eCDR,
                "SBINo": Referrals[pos]?.SBINo,
                "SBIState": "NJ",
                "DLNo": null,
                "DLState": null,
                "City": Referrals[pos]?.City,
                "Resident_County": Referrals[pos]?.Resident_County,
                "Custody_Status": Referrals[pos]?.Custody_Status,
                "Custody_County": Referrals[pos]?.Custody_County,
                "Total_Arrests": parseInt(Referrals[pos]?.Total_Arrests),
                "Gun_Arrests": parseInt(Referrals[pos]?.Gun_Arrests),
                "Felony_Convictions": Referrals[pos]?.Felony_Convictions,
                "Shooting_Victim": Referrals[pos]?.Shooting_Victim,
                "Shooting_Suspect": Referrals[pos]?.Shooting_Suspect,
                "Gang": Referrals[pos]?.Gang,
                "isNJPOP": Referrals[pos]?.isNJPOP,
                "isParole": Referrals[pos]?.isParole,
                "isCODIS": Referrals[pos]?.isCODIS,
                "isNIBIN": Referrals[pos]?.isNIBIN,
                "isCaseExplorer": Referrals[pos]?.isCaseExplorer,
                "isInfoShare": Referrals[pos]?.isInfoShare,
                "isShootingArrest": Referrals[pos]?.isShootingArrest,
                "isGang": Referrals[pos]?.isGang,
                "isViolentPerson": Referrals[pos]?.isViolentPerson,
                "Arrest": null,
                "NJPOP": Referrals[pos]?.NJPOP,
                "Parole": Referrals[pos]?.Parole,
                "CODIS": Referrals[pos]?.CODIS,
                "NIBIN": Referrals[pos]?.NIBIN,
                "CaseExplorer": Referrals[pos]?.CaseExplorer,
                "InfoShare": Referrals[pos]?.InfoShare,
                "NJPopNum": Referrals[pos]?.NJPopNum,
                "NJPopNumOld": Referrals[pos]?.NJPopNum,
                "Pending_Charges_Count": Referrals[pos]?.Pending_Charges_Count,
                "Pending_GunCharge_Count": Referrals[pos]?.Pending_GunCharge_County,
                "Pending_Charges": null,
                "Pending_GunCharge": null,
                "Analytic_Notes": Referrals[pos]?.Analytic_Notes,
                "Adopted": Referrals[pos]?.Adopted,
                "Prosecutor": Referrals[pos]?.Prosecutor,
                "Judge": Referrals[pos]?.Judge,
                "Prosecution_Outcome": Referrals[pos]?.Prosecution_Outcome,
                "Referral_County": Referrals[pos]?.Referral_County,
                "Distribution_List": Referrals[pos]?.Distribution_List,
                "Comments": Referrals[pos]?.Comments,
                "RTCC": Referrals[pos]?.RTCC,
                "Status": Referrals[pos]?.Status,
                "EditStatus": "Edit",
                "CreateUID": null,
                "CreateDate": null,
                "UpdUID":  null,
                "UpdDate": null,
                "Photos": [{"Image": (Referrals[pos]?.Pic!= null && Referrals[pos]?.Pic.length > 0) ? ("data:image/jpeg;base64," + Referrals[pos]?.Pic) : ""}]
            }
        let l = new JAOS()
        let hashID = l.objToStack(GRAIL)
        window.open('/GRAIL?Hash='+hashID,'_blank')
    }

    const handleDelete = async () => {
        try {
            if (window.confirm("Remove This Specific Record Permanently? Record ID = " + Referrals[pos]?.ID)) {
                let GRAIL: GRAILReferral =
                    {
                        "PersonID": Referrals[pos]?.PersonID, 
                        "ID": Referrals[pos]?.ID,
                        "TblGunPersonID": Referrals[pos]?.TblGunPersonID,
                        "SubmitDate": Referrals[pos]?.SubmitDate,
                        "FIRSTNAME": Referrals[pos]?.FName,
                        "MI": Referrals[pos]?.MName,
                        "LASTNAME": Referrals[pos]?.LName,
                        "DOB": Referrals[pos]?.DOB,
                        "SSN": null,
                        "FBI": Referrals[pos]?.FBI,
                        "eCDR": Referrals[pos]?.eCDR,
                        "SBINo": Referrals[pos]?.SBINo,
                        "SBIState": "NJ",
                        "DLNo": null,
                        "DLState": null,
                        "City": Referrals[pos]?.City,
                        "Resident_County": Referrals[pos]?.Resident_County,
                        "Custody_Status": Referrals[pos]?.Custody_Status,
                        "Custody_County": Referrals[pos]?.Custody_County,
                        "Total_Arrests": parseInt(Referrals[pos]?.Total_Arrests),
                        "Gun_Arrests": parseInt(Referrals[pos]?.Gun_Arrests),
                        "Felony_Convictions": Referrals[pos]?.Felony_Convictions,
                        "Shooting_Victim": Referrals[pos]?.Shooting_Victim,
                        "Shooting_Suspect": Referrals[pos]?.Shooting_Suspect,
                        "Gang": Referrals[pos]?.Gang,
                        "isNJPOP": Referrals[pos]?.isNJPOP,
                        "isParole": Referrals[pos]?.isParole,
                        "isCODIS": Referrals[pos]?.isCODIS,
                        "isNIBIN": Referrals[pos]?.isNIBIN,
                        "isCaseExplorer": Referrals[pos]?.isCaseExplorer,
                        "isInfoShare": Referrals[pos]?.isInfoShare,
                        "isShootingArrest": Referrals[pos]?.isShootingArrest,
                        "isGang": Referrals[pos]?.isGang,
                        "isViolentPerson": Referrals[pos]?.violentPerson,
                        "Arrest": null,
                        "NJPOP": Referrals[pos]?.NJPOP,
                        "Parole": Referrals[pos]?.Parole,
                        "CODIS": Referrals[pos]?.CODIS,
                        "NIBIN": Referrals[pos]?.NIBIN,
                        "CaseExplorer": Referrals[pos]?.CaseExplorer,
                        "InfoShare": Referrals[pos]?.InfoShare,
                        "NJPopNum": Referrals[pos]?.NJPopNum,
                        "NJPopNumOld" : Referrals[pos]?.NJPopNum,
                        "Pending_Charges_Count": Referrals[pos]?.Pending_Charges_Count,
                        "Pending_GunCharge_Count": Referrals[pos]?.Pending_GunCharge_County,
                        "Pending_Charges": null,
                        "Pending_GunCharge": null,
                        "Analytic_Notes": Referrals[pos]?.Analytic_Notes,
                        "Adopted": Referrals[pos]?.Adopted,
                        "Prosecutor": Referrals[pos]?.Prosecutor,
                        "Judge": Referrals[pos]?.Judge,
                        "Prosecution_Outcome": Referrals[pos]?.Prosecution_Outcome,
                        "Referral_County": Referrals[pos]?.Referral_County,
                        "Distribution_List": Referrals[pos]?.Distribution_List,
                        "Comments": Referrals[pos]?.Comments,
                        "RTCC": Referrals[pos]?.RTCC,
                        "Status": Referrals[pos]?.Status,
                        "EditStatus": "Delete",
                        "CreateUID": null,
                        "CreateDate": null,
                        "UpdUID": null,
                        "UpdDate": null,
                        "Photos": [{"Image": (Referrals[pos]?.Pic != null && Referrals[pos]?.Pic.length > 0) ? ("data:image/jpeg;base64," + Referrals[pos]?.Pic) : ""}]
                    }
                let ID = await RunSetGRAILReferral(GRAIL)
                if( ID )
                {
                    setAlertType("success")
                    setAlertText(("Referral: '" + Referrals[pos]?.ID + "'" + " has been Deleted. Changes will reflect after Search click."))
                    setAlertOpen(true)
                }
                else {
                    setAlertType("error")
                    setAlertText(("An Error has occured while attempting to Delete that record. '" + Referrals[pos]?.ID + "'"))
                    setAlertOpen(true)
                }
            }
        }
        catch(e)
        {
            console.log(e)
        }
    }
    
    const handleDistributionList = (distributionList: any) => {
        //make string array
        let distributionArr = distributionList.split(',')
        let newDistributionList = []
        //map id to group options to get name
        let distributionNames= distributionArr.map((e:any)=> distributionOptions.find((f:any) => f.ID.toString() === e))
        for(let i of distributionNames){
            if(i.GroupName){
                newDistributionList.push(i.GroupName)
            }
        }
        //return list of group names
        return newDistributionList.join(", ")
        
    }

    const handleFirstRecord = () => {
        setPos(0)
        setCount(count+1)
    }

    const handlePriorRecord = () => {
        if(pos != 0)
        {
            setPos(pos-1)
            setCount(count+1)
        }
    }

    const handleNextRecord = () => {
        if(pos < Referrals.length-1 && (pos + 1 < Referrals.length))
        {
            setPos(pos+1)
            setCount(count+1)
        }
    }

    const handleLastRecord = () => {
        setPos(Referrals.length-1)
        setCount(count+1)
    }


    return (
        (Referrals && Referrals.length > 0 ?
            <div>
                <div className="">
                    <Snackbar open={alertOpen} autoHideDuration={6000} onClose={ () => { setAlertOpen(false)}}>
                        <Alert onClose={() => { setAlertOpen(false) }} severity={alertType} sx={{ width: '100%' }}>
                            {alertText}
                        </Alert>
                    </Snackbar>
                    <div className = "w-full border-2 border-gray-400 mb-5 bg-black">
                        <div className="grid grid-cols-12 text-white py-2" style={{backgroundColor:"#1E3A8A"}}>
                            <div className = ''>
                                <div className = "pl-4">{pos+1} of {Referrals?.length}</div>
                            </div>
                            <div className="text-4xl font-bold text-center col-span-10">{Referrals[pos]?.FName} {Referrals[pos]?.MName} {Referrals[pos]?.LName}</div>
                            <div className="">
                                {(isUserPermission('NJSP') || (SiteName==SITE.DCPROD || SiteName==SITE.DCDEMO || SiteName==SITE.DCLOCAL) ) && (!window.location.href.includes('detail'))
                                    ? <Tooltip title="Edit Referral" placement="top" arrow >
                                        <IconButton size={"small"} style={{ color: "gray" }} onClick={handleEdit}><EditIcon color ="primary" style ={{height:22, width:22, marginRight:4, color:"white"}}/></IconButton>
                                    </Tooltip>
                                    : <></>}
                                {(isUserPermission('NJSP') || (SiteName==SITE.DCPROD || SiteName==SITE.DCDEMO || SiteName==SITE.DCLOCAL) ) && (!window.location.href.includes('detail'))
                                    ? <Tooltip title="Delete Referral" placement="top" arrow >
                                        <IconButton size={"small"} style={{ color: "gray" }} onClick={handleDelete}><DeleteIcon color ="error" style ={{height:22, width:22, marginRight:4, color:"red"}}/></IconButton>
                                    </Tooltip>
                                    : <></>}
                            </div>
                        </div>
                        <div className = " mt-1 text-center text-sm text-red-500">UNCLASSIFIED//LAW ENFORCEMENT SENSITIVE</div>
                        <div className = "grid grid-cols-12">
                            {/*FIRST AREA */}
                            <div className="col-span-4 text-white">
                                <div className="grid grid-cols-12 gap-y-1.5">
                                    <div className="col-start-1 col-end-12 inline">
                                        <div className="grid grid-cols-12 gap-x-1.5">
                                            <div className = "2xl:col-span-3 col-span-4 text-right">Date Submitted</div>
                                            <div className = "2xl:col-span-4 col-span-6 text-center text-gray-300 border-gray-300 border pl-4 pr-4">{formatDate(Referrals[pos]?.SubmitDate)}</div>
                                        </div>
                                    </div>
                                    <div className="col-start-1 col-end-12 inline">
                                        <div className="grid grid-cols-12 gap-x-1.5">
                                            <div className = "2xl:col-span-3 col-span-4 text-right">DOB</div>
                                            <div className = "2xl:col-span-4 col-span-6 text-center text-gray-300 border-gray-300 border pl-4 pr-4">{ Referrals[pos] && Referrals[pos]?.DOB ? ((Referrals[pos]?.DOB.getMonth() + 1).toString()).padStart(2, '0') + '/' + Referrals[pos]?.DOB.getDate().toString().padStart(2, '0') + '/'+ Referrals[pos]?.DOB.getFullYear()  : ""}</div>
                                        </div>
                                    </div>
                                    <div className="col-start-1 col-end-12 inline">
                                        <div className="grid grid-cols-12 gap-x-1.5">
                                            <div className = "2xl:col-span-3 col-span-4 text-right">{GetStringNameForSite(SiteName,"SBI")}</div>
                                            <div className = "2xl:col-span-4 col-span-6 text-center text-gray-300 border-gray-300 border pl-4 pr-4">{Referrals[pos]?.SBINo}</div>
                                        </div>
                                    </div>
                                    <div className="col-start-1 col-end-12 inline">
                                        <div className="grid grid-cols-12 gap-x-1.5">
                                            <div className = "2xl:col-span-3 col-span-4 text-right">FBI</div>
                                            <div className = "2xl:col-span-4 col-span-6 text-center text-gray-300 border-gray-300 border pl-4 pr-4">{Referrals[pos]?.FBI}</div>
                                        </div>
                                    </div>
                                    <div className="col-start-1 col-end-12 inline">
                                        <div className="grid grid-cols-12 gap-x-1.5">
                                            <div className = "2xl:col-span-3 col-span-4  text-right">eCDR</div>
                                            <div className = "2xl:col-span-4 col-span-6 text-center text-gray-300 border-gray-300 border pl-4 pr-4">{Referrals[pos]?.eCDR}</div>
                                        </div>
                                    </div>
                                    <div className="col-start-1 col-end-12 inline ">
                                        <div className="grid grid-cols-12 gap-x-1.5">
                                            <div className = "2xl:col-span-3 col-span-4  text-right">City</div>
                                            <div className = "2xl:col-span-4 col-span-6 text-center text-gray-300 border-gray-300 border pl-4 pr-4">{Referrals[pos]?.City}</div>
                                        </div>
                                    </div>
                                    <div className="col-start-1 col-end-12 inline ">
                                        <div className="grid grid-cols-12 gap-x-1.5">
                                            <div className = "2xl:col-span-3 col-span-4  text-right">{GetStringNameForSite(SiteName,"County")}</div>
                                            <div className = "2xl:col-span-4 col-span-6 text-center text-gray-300 border-gray-300 border pl-4 pr-4">{Referrals[pos]?.Resident_County}</div>
                                        </div>
                                    </div>
                                    <div className="col-start-1 col-end-12 inline ">
                                        <div className="grid grid-cols-12 gap-x-1.5">
                                            <div className = "2xl:col-span-3 col-span-4  text-right">Custody Status</div>
                                            <div className = "2xl:col-span-4 col-span-6 text-center text-gray-300 border-gray-300 border pl-4 pr-4">{(Referrals[pos]?.Custody_Status?.length > 0 ? Referrals[pos]?.Custody_Status == 'N' ? "No" : "Yes " + Referrals[pos]?.Custody_County : "")} </div>
                                        </div>
                                    </div>
                                    <div className="col-start-1 col-end-12 inline ">
                                        <div className="grid grid-flow-col auto-cols-auto gap-x-1.5">
                                            <div className = "  text-right">Total Arrests</div>
                                            <div className = " text-center text-gray-300 border-gray-300 border pl-1 pr-1">{Referrals[pos]?.Total_Arrests != null ? Referrals[pos]?.Total_Arrests : 0 }</div>
                                            <div className = "  text-right">Gun Arrests</div>
                                            <div className = " text-center text-gray-300 border-gray-300 border pl-1 pr-1">{Referrals[pos]?.Gun_Arrests != null ? Referrals[pos]?.Gun_Arrests : 0 }</div>
                                            <div className = "  text-right">Felony Convictions</div>
                                            <div className = " text-center text-gray-300 border-gray-300 border pl-1 pr-1">{Referrals[pos]?.Felony_Convictions != null ? Referrals[pos]?.Felony_Convictions : 0 }</div>
                                        </div>
                                    </div>
                                    <div className="col-start-1 col-end-12  ">
                                        <div className="grid grid-cols-1 gap-x-1.5 row-end-1 justify-items-center">
                                            <div>
                                                <div className = " text-right inline mr-4 pr-0.5"># of Times<span className = "font-bold">&nbsp;Victim&nbsp;</span>in Shooting</div>
                                                <div className = "text-center text-gray-300 border-gray-300 border pl-4 pr-4 inline" style={{width:'4em'}}>{Referrals[pos]?.Shooting_Victim != null ? Referrals[pos]?.Shooting_Victim : 0}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-start-1 col-end-12 ">
                                        <div className="grid grid-cols-1 gap-x-1.5 row-end-1 justify-items-center">
                                            <div>
                                                <div className = " text-right inline mr-2"># of Times<span className = "font-bold">&nbsp;Suspect&nbsp;</span>in Shooting</div>
                                                <div className = "text-center text-gray-300 border-gray-300 border pl-4 pr-4 inline ">{Referrals[pos]?.Shooting_Suspect != null ? Referrals[pos]?.Shooting_Suspect : 0}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-start-1 col-end-12 inline ">
                                        <div className="grid grid-cols-12 gap-x-1.5">
                                            <div className = "col-span-3  text-right">{GetStringNameForSite(SiteName,"NJ POP")}</div>
                                            <div className = "2xl:col-span-6 col-span-9 text-center text-gray-300 border-gray-300 border pl-4">
                                                { Referrals[pos]?.isNJPOP == 'Y' ?
                                                    <div className = "text-gray-300 pr-4 pl-4 bg-black overflow-y-auto overflow-x-hidden max-h-32 text-center" > {Referrals[pos]?.NJPOP && Referrals[pos]?.NJPOP.length > 0 ? Referrals[pos]?.NJPOP : "Yes"}</div>
                                                    :
                                                    <span>No</span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-start-1 col-end-12 inline ">
                                        <div className="grid grid-cols-12 gap-x-1.5">
                                            <div className = "col-span-3  text-right">Gang</div>
                                            <div className = "2xl:col-span-6 col-span-9 text-center text-gray-300 border-gray-300 border pl-4">
                                                { Referrals[pos]?.isGang == 'Y' ?
                                                    <div className = "text-gray-300 col-span-6 pr-4 pl-4 bg-black overflow-y-auto overflow-x-hidden max-h-32 text-start" > {Referrals[pos]?.Gang && Referrals[pos]?.Gang.length > 0 ? Referrals[pos]?.Gang : "Yes"}</div>
                                                    :
                                                    <span>No</span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-start-1 col-end-12 inline ">
                                        <div className="grid grid-cols-12 gap-x-1.5">
                                            <div className = "col-span-3  text-right">Parole</div>
                                            <div className = "2xl:col-span-6 col-span-9 text-center text-gray-300 border-gray-300 border pl-4">
                                                { Referrals[pos]?.isParole == 'Y' ?
                                                    <div className = "text-gray-300 col-span-6 pr-4 pl-4 bg-black overflow-y-auto overflow-x-hidden max-h-32 text-start" > {Referrals[pos].Parole && Referrals[pos]?.Parole.length > 1 ? Referrals[pos].Parole : "Yes"}</div>
                                                    :
                                                    <span>No</span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-start-1 col-end-12 inline ">
                                        <div className="grid grid-cols-12 gap-x-1.5">
                                            <div className = "col-span-3  text-right">CODIS</div>
                                            <div className = "2xl:col-span-6 col-span-9 text-center text-gray-300 border-gray-300 border pl-4">
                                                { Referrals[pos]?.isCODIS == 'Y' ?
                                                    <div className = "text-gray-300 col-span-6 pr-4 pl-4 bg-black overflow-y-auto overflow-x-hidden max-h-32 text-start" > {Referrals[pos]?.CODIS && Referrals[pos]?.CODIS.length > 0 ? Referrals[pos]?.CODIS : "Yes"}</div>
                                                    :
                                                    <span>No</span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-start-1 col-end-12 inline ">
                                        <div className="grid grid-cols-12 gap-x-1.5">
                                            <div className = "2xl:col-span-7 col-span-11  text-right">Pending Charges Prior to Last Arrest</div>
                                            <div className = "col-span-1 text-center text-gray-300 border-gray-300 border pl-4 pr-4">{Referrals[pos]?.Pending_Charges_Count != null ? Referrals[pos]?.Pending_Charges_Count : 0}</div>
                                        </div>
                                    </div>
                                    <div className="col-start-1 col-end-12 inline ">
                                        <div className="grid grid-cols-12 gap-x-1.5">
                                            <div className = "2xl:col-span-7 col-span-11  text-right">Pending<span className = "font-bold">&nbsp;Gun Charges&nbsp;</span>Prior to Last Arrest</div>
                                            <div className = "col-span-1 text-center text-gray-300 border-gray-300 border pl-4 pr-4">{Referrals[pos]?.Pending_GunCharge_Count != null ? Referrals[pos]?.Pending_GunCharge_Count : 0}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-span-8 text-white">
                                <div className="grid grid-cols-12 gap-y-3 ">
                                    <div className = "col-span-6 ">
                                        <div className="grid grid-cols-12 gap-y-1.5">
                                            <div className="col-start-1 col-end-12">
                                                <div className="grid grid-cols-12 gap-x-1.5">
                                                    <div className = "2xl:col-span-3 2xl:col-start-2 col-span-5 text-right">Referral Status</div>
                                                    <div className = "2xl:col-span-4 col-span-5 text-center text-gray-300 border-gray-300 border pl-4 pr-4">{Referrals[pos]?.Status}</div>
                                                </div>
                                            </div>
                                            <div className="2xl:col-start-4  col-start-5 col-span-12">
                                                <div className="grid grid-cols-12 gap-x-1">
                                                    <div className = "2xl:col-span-6 2xl:ml-6 col-span-8">
                                                        <img src={Referrals[pos]?.Pic ? ("data:image/jpeg;base64,"+Referrals[pos]?.Pic) : noImage} style={{height:180, width:180}} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-start-1 col-end-12">
                                                <div className="grid grid-cols-12 gap-x-1.5">
                                                    <div className = "2xl:col-span-3 2xl:col-start-2 col-span-5 text-right">Previous Referral</div>
                                                    <div className = "2xl:col-span-4 col-span-5 text-center text-gray-300 border-gray-300 border pl-4 pr-4">{(Referrals[pos]?.FoundReferral > 1) ? 'Yes' : 'No'}</div>
                                                </div>
                                            </div>
                                            <div className="col-start-1 col-end-12">
                                                <div className="grid grid-cols-12 gap-x-1.5">
                                                    <div className = "2xl:col-span-3 2xl:col-start-2 col-span-5 text-right">Violent Person</div>
                                                    <div className = "2xl:col-span-4 col-span-5 text-center text-gray-300 border-gray-300 border pl-4 pr-4">{!Referrals[pos]?.isViolentPerson || Referrals[pos]?.isViolentPerson === 'N' ? 'No' : 'Yes'}</div>
                                                </div>
                                            </div>
                                            <div className="col-start-1 col-end-12">
                                                <div className="grid grid-cols-12 gap-x-1.5">
                                                    <div className = "2xl:col-span-3 2xl:col-start-2 col-span-5 text-right">Federal Adoption</div>
                                                    <div className = "2xl:col-span-4 col-span-5 text-center text-gray-300 border-gray-300 border pl-4 pr-4">{Referrals[pos]?.Adopted && Referrals[pos]?.Adopted.length > 0 ? Referrals[pos]?.Adopted == 'Y' ? "Yes" : "No" :""}</div>
                                                </div>
                                            </div>
                                            <div className="col-start-1 col-end-12">
                                                <div className="grid grid-cols-12 gap-x-1.5">
                                                    <div className = "2xl:col-span-3 2xl:col-start-2 col-span-5 text-right">Referral {GetStringNameForSite(SiteName,"County")}</div>
                                                    <div className = "2xl:col-span-4 col-span-5 text-center text-gray-300 border-gray-300 border pl-4 pr-4">{Referrals[pos]?.Referral_County}</div>
                                                </div>
                                            </div>
                                            <div className="col-start-1 col-end-12">
                                                <div className="grid grid-cols-12 gap-x-1.5">
                                                    <div className = "2xl:col-span-3 2xl:col-start-2 col-span-5 text-right">Distribution List</div>
                                                    <div className = "2xl:col-span-4 col-span-5 text-center break-words text-sm text-gray-300 border-gray-300 border pl-4 pr-4">
                                                        {Referrals[pos]?.Distribution_List && Referrals[pos]?.Distribution_List.length > 0
                                                         ? handleDistributionList(Referrals[pos]?.Distribution_List)
                                                         :""}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-start-1 col-end-12">
                                                <div className="grid grid-cols-12 gap-x-1.5">
                                                    <div className = "2xl:col-span-3 2xl:col-start-2 col-span-5 text-right">Prosecutor</div>
                                                    <div className = "2xl:col-span-4 col-span-5 text-center text-gray-300 border-gray-300 border pl-4 pr-4">{Referrals[pos]?.Prosecutor}</div>
                                                </div>
                                            </div>
                                            <div className="col-start-1 col-end-12">
                                                <div className="grid grid-cols-12 gap-x-1.5">
                                                    <div className = "2xl:col-span-3 2xl:col-start-2 col-span-5 text-right">Prosecution Outcome</div>
                                                    <div className = "2xl:col-span-4 col-span-5 text-center text-gray-300 border-gray-300 border pl-4 pr-4">{Referrals[pos]?.Prosecutor}</div>
                                                </div>
                                            </div>
                                            {(SiteName==SITE.NJPROD || SiteName==SITE.NJUAT || SiteName==SITE.NJDEMO || SiteName==SITE.LOCAL) &&
                                                <div className="col-start-1 col-end-12">
                                                    <div className="grid grid-cols-12 gap-x-1.5">
                                                        <div className = "2xl:col-span-3 2xl:col-start-2 col-span-5 text-right">RTCC</div>
                                                        <div className = "2xl:col-span-4 col-span-5 text-center text-gray-300 border-gray-300 border pl-4 pr-4">{Referrals[pos]?.RTCC}</div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className = "col-span-6 ">
                                        <div className="col-start-1 col-end-12">
                                            <div className = " text-left text-sm">Notes</div>
                                            <div className="grid grid-cols-12 gap-x-1.5">
                                                <TextField className="2xl:col-span-10 col-span-12 text-left text-gray-300 border-gray-300 border pl-4 pr-4" multiline={true} minRows={9} maxRows={15}
                                                    fullWidth margin='none' size={"small"} sx={{ border: '1px solid white' }} inputProps={{ style: { fontSize: 14, color: "white" } }}
                                                    value={Referrals[pos]?.Analytic_Notes && Referrals[pos]?.Analytic_Notes.length > 0 ? Referrals[pos]?.Analytic_Notes : ""}>
                                                </TextField>
                                            </div>
                                        </div>
                                        <div className="col-start-1 col-end-12">
                                            {/* <div className="grid grid-cols-12 gap-x-1.5 "> */}
                                            <div className ="grid grid-flow-col auto-cols-auto place-content-start gap-x-1.5">
                                                <span className = "text-left mt-3">Was this arrest a result of a shooting?</span>
                                                    <div className = " col-auto text-center text-gray-300 border-gray-300 border mt-3">
                                                        { Referrals[pos]?.isShootingArrest == 'Y' ?
                                                                <div className = "text-gray-300 pr-4 pl-4 bg-black overflow-y-auto overflow-x-hidden max-h-32 text-left" > {Referrals[pos]?.NJPopNum && Referrals[pos]?.NJPopNum.length > 0 ? Referrals[pos]?.NJPopNum : "Yes"}</div>
                                                                :
                                                                <span className="text-center 2xl:px-16 px-10">No</span>
                                                            }
                                                    </div>                                           
                                            </div>
                                        </div>
                                    </div>      
                                        {/*<div className="ml-2 mb-2 mt-2">*/}
                                        {/*    <span className={"ml-2 text-sm text-gray-600"}>Notes</span>*/}
                                        {/*    <span className="align-middle flex inline mt-2">*/}
                                        {/*        <div className="w-96"><TextField multiline={true} minRows={9} maxRows={15} fullWidth margin='none' size={"small"} inputProps={{ style: { fontSize: 14 } }}*/}
                                        {/*            value={Referrals[pos]?.Analytic_Notes && Referrals[pos]?.Analytic_Notes.length > 0 ? Referrals[pos]?.Analytic_Notes : ""} /> </div>*/}
                                        {/*    </span>*/}
                                        {/*</div>*/}
                                        {/*<div className="col-start-1 col-end-12">*/}
                                        {/*    <div className = " text-left text-sm">Comments</div>*/}
                                        {/*    <div className="grid grid-cols-12 gap-x-1.5">*/}
                                        {/*        <div className = "lg:h-40 2xl:col-span-10 col-span-12 text-left text-gray-300 border-gray-300 border pl-4 pr-4 overflow-y-auto overflow-x-hidden">{Referrals[pos]?.Comments && Referrals[pos]?.Comments.length > 0 ? Referrals[pos]?.Comments : "" }</div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        {/* </div> */}
                                        <div className="col-span-12">
                                        <div className={(SiteName==SITE.NJPROD || SiteName==SITE.NJUAT || SiteName==SITE.NJDEMO || SiteName==SITE.LOCAL) ? "grid grid-flow-col auto-cols-auto place-content-around justify-items-start gap-x-2 mr-2" : "grid grid-cols-12 gap-y-3 "}>
                                            <div className ={(SiteName==SITE.NJPROD || SiteName==SITE.NJUAT || SiteName==SITE.NJDEMO || SiteName==SITE.LOCAL) ? "grid grid-flow-col auto-cols-auto place-content-start gap-x-1.5" : "col-start-2 grid grid-flow-col auto-cols-auto place-content-start gap-x-1.5"}>
                                                <span className = " text-right">NIBIN</span>
                                                <span className = "text-gray-300 border-gray-300 border">
                                                    { Referrals[pos]?.isNIBIN == 'Y' ?
                                                        <div className = "text-gray-300 pl-1 bg-black overflow-y-auto overflow-x-hidden text-start max-h-24 max-w-sm lg:min-w-sm" > {Referrals[pos]?.NIBIN && Referrals[pos]?.NIBIN.length > 0 ? Referrals[pos]?.NIBIN : "Yes"}</div>
                                                        :
                                                        <span className = "text-center 2xl:px-16 px-10 ">No</span>
                                                    }
                                                </span>
                                            </div>
                                            {(SiteName==SITE.NJPROD || SiteName==SITE.NJUAT || SiteName==SITE.NJDEMO || SiteName==SITE.LOCAL) &&
                                                <div className ="grid grid-flow-col auto-cols-auto place-content-start gap-x-1.5">
                                                    <div className = " text-left">CaseExplorer</div>
                                                    <div className = "text-gray-300 border-gray-300 border">
                                                        { Referrals[pos]?.isCaseExplorer == 'Y' ?
                                                            <div className = "text-gray-300 pr-1 pl-1 bg-black overflow-y-auto overflow-x-hidden text-left max-h-24 max-w-sm" > {Referrals[pos]?.CaseExplorer && Referrals[pos]?.CaseExplorer.length > 0 ? Referrals[pos]?.CaseExplorer : "Yes"}</div>
                                                            :
                                                            <span className = "text-center 2xl:px-16 px-10">No</span>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            {(SiteName==SITE.NJPROD || SiteName==SITE.NJUAT || SiteName==SITE.NJDEMO || SiteName==SITE.LOCAL) &&
                                                <div className ="grid grid-flow-col auto-cols-auto place-content-start gap-x-1.5">
                                                    <div className = " text-right">InfoShare</div>
                                                    <div className = "text-gray-300 border-gray-300 border">
                                                        { Referrals[pos]?.isInfoShare == 'Y' ?
                                                            <div className = "text-gray-300 pr-1 pl-1 bg-black overflow-y-auto overflow-x-hidden text-left max-h-24 max-w-sm" > {Referrals[pos]?.InfoShare && Referrals[pos]?.InfoShare.length > 0 ? Referrals[pos]?.InfoShare : "Yes"}</div>
                                                            :
                                                            <span className = "text-center 2xl:px-16 px-10">No</span>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-flow-col auto-cols-auto mt-3 place-content-center gap-x-2">
                            <div className = "" onMouseEnter={()=>{setB1Color("#0DA2EF")}} onMouseLeave={()=>{setB1Color("#FFFFFF")}}><Button color={"primary"} size={'large'} style={{color:b1color, border:"1px solid", borderColor:b1color, width:"full"}} onClick={handleFirstRecord}><DoubleArrowSharpIcon className="transform rotate-180"/>First Record</Button></div>
                            <div className = "" onMouseEnter={()=>{setB2Color("#0DA2EF")}} onMouseLeave={()=>{setB2Color("#FFFFFF")}}><Button color={"primary"} size={'large'} style={{color:b2color, border:"1px solid", borderColor:b2color, width:"full"}} onClick={handlePriorRecord}><KeyboardArrowLeftSharpIcon/>Prior Record</Button></div>
                            <div className = "" onMouseEnter={()=>{setB3Color("#0DA2EF")}} onMouseLeave={()=>{setB3Color("#FFFFFF")}}><Button color={"primary"} size={'large'} style={{color:b3color, border:"1px solid", borderColor:b3color, width:"full"}} onClick={handleNextRecord}>Next Record<KeyboardArrowRightSharpIcon/></Button></div>
                            <div className = "" onMouseEnter={()=>{setB4Color("#0DA2EF")}} onMouseLeave={()=>{setB4Color("#FFFFFF")}}><Button color={"primary"} size={'large'} style={{color:b4color, border:"1px solid", borderColor:b4color, width:"full"}} onClick={handleLastRecord}>Last Record<DoubleArrowSharpIcon/></Button></div>
                        </div>
                        <div className = "text-sm w-11/12 mt-5 text-white m-auto" style={{backgroundColor:"#919292"}}>
                            (U) INFORMATION NOTICE: This product contains unclassified information that is law enforcement sensitive. (U/LES) Recipients should not release any portion of this product to the media,the public, or other personnel who do not have a valid need-to-know.
                        </div>
                        <div className = " mt-1 text-center text-sm text-red-500">UNCLASSIFIED//LAW ENFORCEMENT SENSITIVE</div>
                    </div>
                </div>
            </div>
            : <></>)
    );
}



export default GVRTFOutputForm;