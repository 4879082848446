import React, {useState} from "react";
import {getUser, isUserPermission} from "../../services/auth.service";
import {getDarInfoResponse, GetDarInfo_Query, PersonInfo, ProfileReportPersonInfo} from "../../interfaces/getDarInfo_interface";
import {Account} from "../../interfaces/auth_interface";
import {useLocation} from "react-router-dom";
import ProfileReportSearch from "../../components/tools/profileReport/_profileReportSearch";
import SearchResults from "../../components/tools/profileReport/_searchResultsProfileReport";
import { RunDarQuery } from "../../services/getDar.service";
import { JAOS } from "../../services/JAOS.service";
import NCICMultipleOccurrence from "../../components/tools/ncic_multiple_occurrence/_ncicMultipleOccurrence";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function NCICMultipleSearch() {
    let [darTbl, setDarTbl] = useState<PersonInfo[]>([])
    let query = useQuery();
    const [initialLoading, setInitialLoading] = React.useState(false);

    const checkPersonData = () => {
        let person = query.get("person");
        let data = [];
        if (person) {
            let l = new JAOS()
            data = l.objFromStack(person);
        }
        if (data && data.length > 0) {
            setDarTbl(data);
        }
    }

    React.useEffect(() => {
        if(!initialLoading){
            setInitialLoading(true);
            checkPersonData();
        }
    },[])

    
    
    return (
        <div id="NCICMultiple">
            {isUserPermission('NCIC') ?
               <>
                    {darTbl && darTbl.length > 0 ?
                    <NCICMultipleOccurrence Persons={darTbl} />
                    :
                    <></>}
                </>
            :<></>}
        </div>
    );
}

export default NCICMultipleSearch;