import React, { forwardRef } from "react";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import Slide, { SlideProps } from "@mui/material/Slide";
import {
  DropDown,
  InvestigationQuery,
} from "../../../interfaces/NJPORT/InvestigationLog.interface";
import { InvestigationDropDown } from "../../../services/njport.service";
import { ShipEntryQuery } from "../../../interfaces/NJPORT/ShipEntry.interface";
import { fr } from "date-fns/locale";
import { VolunteerHireQuery } from "../../../interfaces/NJPORT/VolunteerHire.interface";

const InvestigationReportSearch: React.FC<any> = ({ SubmitSearch }) => {

  let today: Date;
  let fromday: Date;
  let iscreate = localStorage.getItem('voluntercreate') === 'true'; // Convert to boolean

  if (iscreate) {
    let fromDateString = localStorage.getItem('volunterFromDate');
    let toDateString = localStorage.getItem('volunterToDate');

    if (fromDateString && toDateString) {
      fromday = new Date(fromDateString);
      today = new Date(toDateString);
    } else {
      today = new Date();
      fromday = new Date(today);
    }
  } else {
    today = new Date();
    fromday = new Date(today);

    if (today.getDay() === 1) {
      fromday.setDate(today.getDate() - 2);
    }
  }

  //console.log(`From Date: ${fromday}`);
  //console.log(`To Date: ${today}`);


  const [invdata, setInvData] = React.useState<VolunteerHireQuery>({
    fromDate: fromday.toISOString(),
    toDate: today.toISOString(),
  });
  const [initialRun, setInitialRun] = React.useState<boolean>(false);
  const [params, setParams] = React.useState({
    FirstName: "",
    MI: "",
    LastName: "",
    DOB: "",
    FBI: "",
    SBI: "",
    AgeRangeStart: "",
    AgeRangeEnd: "",
    toDate: today,
    fromDate: fromday,
    Gender: "",
    Status: "",
    ReportNum: "",
    SystemCall: false,
  });
  const [dateType, setDateType] = React.useState<string>("Entry Date");
  const [invStatusDD, setInvStatusDD] = React.useState<DropDown[]>([]);
  const [jobTitleDD, setJobTitleDD] = React.useState<DropDown[]>([]);
  const [pacCardDD, setPacCardDD] = React.useState<DropDown[]>([]);
  const [invStatus, setInvStatus] = React.useState("");
  const [jobTitle, setJobTitle] = React.useState("");
  const [pacCard, setPacCard] = React.useState("");

  React.useEffect(() => {
    if (!initialRun) {
      setInitialRun(true);
      SubmitSearch(invdata);
      invStatusDropdown();
      jobTitleDropdown();
      pacCardDropdown();
      setDateValues();
    }
  });

  const convertDate = (date: Date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const setDateValues = () => {
    let data = { ...invdata };
    data.fromDate = convertDate(fromday);
    data.toDate = convertDate(today);
    setInvData(data);
  };

  const handleChange = (field: string, val: any) => {
    let data = { ...invdata };
    switch (field) {
      case "fromDate":
        data.fromDate = val;
        break;
      case "toDate":
        data.toDate = val;
        break;
    }
    setInvData(data);
  };

  const handleSubmit = () => {
    let data = { ...invdata };
    data.fromDate = `${data.fromDate} 00:00:00`;
    data.toDate = `${data.toDate} 23:59:59`;

    // Store in localStorage
    localStorage.setItem('volunterFromDate', data.fromDate);
    localStorage.setItem('volunterToDate', data.toDate);
    SubmitSearch(data);  // Ensure you pass the updated 'data' object
  };


  const handleDateType = (
    event: React.MouseEvent<HTMLElement>,
    dateType: string
  ) => {
    if (dateType === "Entry Date") {
      setInvData({ ...invdata, dateTypeValue: 0 });
    } else {
      setInvData({ ...invdata, dateTypeValue: 1 });
    }
    if (dateType) {
      setDateType(dateType);
    }
  };

  const invStatusDropdown = async () => {
    let data = {
      params: {
        DropDownName: "PORT_INVSTATUS",
        State: "NJ",
      },
    };
    let response: DropDown[] = await InvestigationDropDown(data);
    setInvStatusDD(response);
  };

  const jobTitleDropdown = async () => {
    let data = {
      params: {
        DropDownName: "PORT_JOBCODE",
        State: "NJ",
      },
    };
    let response: DropDown[] = await InvestigationDropDown(data);
    setJobTitleDD(response);
  };

  const pacCardDropdown = async () => {
    let data = {
      params: {
        DropDownName: "YESNO",
        State: "NJ",
      },
    };
    let response: DropDown[] = await InvestigationDropDown(data);
    setPacCardDD(response);
  };

  const handleClear = () => {
    //console.log("Clear button clicked");
    // window.location.reload();
    let data = { ...invdata };
    data.fromDate = convertDate(fromday);
    data.toDate = convertDate(today);
    setInvData(data);
  };

  return (
    <div className="mt-5 ml-5 mr-5 p-5">
      <Box className="pt-3" sx={{ flexGrow: 1 }}>
        <Grid container spacing={1} sx={{ pl: 2 }}>
          <Grid item xs={2} sx={{ pr: 2 }}>
            <ToggleButtonGroup
              color="primary"
              value={dateType}
              exclusive
              fullWidth
              onChange={handleDateType}
            >
              <ToggleButton value="Report Date">Report Date</ToggleButton>
              <ToggleButton value="Entry Date">Entry Date</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={5} sx={{ pr: 2 }}>
            <TextField
              fullWidth
              margin="none"
              type="date"
              name="fromDate"
              label="From Date"
              value={invdata.fromDate}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => handleChange("fromDate", e.target.value)}
            />
          </Grid>
          <Grid item xs={5} sx={{ pr: 2 }}>
            <TextField
              fullWidth
              margin="none"
              type="date"
              name="toDate"
              label="To Date"
              value={invdata.toDate}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => handleChange("toDate", e.target.value)}
            />
          </Grid>

        </Grid>

        <Grid container spacing={1} sx={{ pl: 2, mt: 2, justifyContent: 'flex-end' }}>
          <Grid item xs={8} sx={{ pr: 2 }}></Grid>
          <Grid item xs={2} sx={{ pr: 2 }}>
            <ButtonGroup fullWidth size="large" color="inherit">
              <Button type="submit" onClick={handleSubmit}>
                Search
              </Button>
              <Button onClick={handleClear} >CLEAR</Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default InvestigationReportSearch;
