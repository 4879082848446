import React, {useState} from "react";
import {Redirect, useLocation, useParams} from "react-router-dom";
import {Main_URL} from "../../services/config.service";
import {GetDeptSSODetails, SSOLoginRequest} from "../../services/sso.service";
import UserAttributes from "../../interfaces/UserAttributes.interface";
import OIDCNewUser from "./OIDC_NewUser";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function SAML_LOGIN() {
    const { DeptCode } = useParams<any>();
    const [loginState, setLoginState] = React.useState<string>("INIT");
    
    const [acct, setAcct] = React.useState<UserAttributes>({});
    const [token, setToken] = React.useState<string>("");
    const [initialRun, setInitialRun] = React.useState<boolean>(false);

    let q = useQuery();
    React.useEffect(() => {
        if(!initialRun)
        {
            setInitialRun(true);
            let code = q.has('guid') ? q.get('guid') : null;
            SSOLoginRequest({
                Type: 'SAML',
                DeptCode: null,
                MainURL: Main_URL,
                Code: code
            }).then((res) => {
                if(res && res.resp_type === "NEW_PROFILE"){
                    setAcct(res.acct);
                    setToken(res.token);
                    setLoginState("NEW_PROFILE");
                } else if (res && res.resp_type === "TOKEN") {
                    localStorage.setItem('user', res.token ? res.token : "")
                    window.location.href = "/"
                }
            }).catch(()=>{});
        }
    });

    // @ts-ignore
    return (
        <>
            {loginState === "INIT" && <></>}
            {loginState === "NEW_PROFILE" && <OIDCNewUser acct={acct} token={token} />}
        </>
    );
}

export default SAML_LOGIN;