import React, {useState} from 'react'
import {
Box, Slide, Tab
} from "@mui/material";
import GVRTFOutputForm from "./GVRTF_OutputForm";
import ReferralGrid1 from "./_resultsReferralGrid1";
import GVRTFManageEmail from './GVRTF_ManageEmail';
import {TransitionProps} from "@mui/material/transitions";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {List, PostAdd} from "@mui/icons-material";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import EmailIcon from '@mui/icons-material/Email';

interface SearchResultsProps {
    Persons: any[]
    GetReferrals? : any
}

const SearchResults: React.FC<SearchResultsProps> = ({Persons})=> {
    const [typedPersons, setTypedPersons] = React.useState<any[]>([])
    const [referralList, setReferralList] = React.useState<any[]>([])
    const [tabValue, setTabValue] = React.useState('2');

    React.useEffect(() => {
        handleDataTyping()
    },[Persons])

    const handleDataTyping = () =>
    {
        if(Persons) {
            if (Persons) {
                let tempP: any = [...Persons]

                for (let p of tempP) {
                    if (p.DOB) {
                        p.DOB = new Date(p.DOB)
                    }
                    if (p.DTOFARREST) {
                        p.DTOFARREST = new Date(p.DTOFARREST)
                    }
                    if (p.DTOFUPDATE) {
                        p.DTOFUPDATE = new Date(p.DTOFUPDATE)
                    }
                }
                setTypedPersons(tempP)
            }
        }

    }
    
    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    return (
        <div className={"pl-10 pr-10 mt-5"}>
            <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleTabChange} variant="fullWidth">
                        <Tab icon={<List />} label="List View"  value={'1'}/>
                        <Tab icon={<AssignmentIndIcon />} label={'Data View'} value={'2'} />
                        <Tab icon={<EmailIcon />} label={'Manage Email List'} value={'3'} />
                    </TabList>
                </Box>
                <TabPanel value='1'>
                    <ReferralGrid1 Persons={typedPersons}/>
                </TabPanel>
                <TabPanel value='2'>
                    <GVRTFOutputForm Persons={typedPersons} Additions={referralList}/>
                </TabPanel>
                <TabPanel value='3'>
                    <GVRTFManageEmail Persons={typedPersons} editUser={null}/>
                </TabPanel>
            </TabContext>
        </div>
    );
};

export default SearchResults;