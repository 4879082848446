import { AxiosResponse } from 'axios';
import AuthenticatedAPIReq from '../../../services/AuthenticatedAPIReq.service';
// import AuthenticatedAPIReq from './path/to/authenticatedAPIReq'; // Adjust the import path accordingly

// Define the types for the request and response
interface EditPdoHireRequest {
  hiringDateTime: string;
}
interface SetTerminalHireRequest {
  HiringTime:string,
  HiringDate:string
}

interface EditPdoHireResponse {
    id: number;
    caseNo: string;
    caseNo_Original: string;
    hiringDateTime: Date;
    port: string;
    shipTerminal: string;
    terminalDesc: string;
    job_Code: string;
    HiringDate: string;
    poolBiginning: number;
    jobRequests: number;
    companyHiring: number;
    requestsFilled: number;
    remaining: number;
    unfilled: number;
    didNotAnswer: number;
    stillWorking: number;
    mainRec: number;
    notes: string;
    fileContent: string;
    createUser: string;
    createDept: string;
    createDate: Date;
    updateUser: string;
    updateDept: string;
    updateDate: Date;
    deleted: boolean;
    editStatus: string;
    appDataPath: string;
    isValid: boolean;
    errorMessages: string[];
    workersHired: number;
    shipTerminal_Desc: string;
  }
  interface TerminalHirePayload {
    id: number;
    caseNo: string;
    caseNo_Original: string;
    Port: string;
    ShipTerminal: string;
    HiringDateTime: string; // "2024-02-27 13:00"
    HiringDate: string; // "2024-02-27" –ONLY date part
    HiringTime: string; // "13:00" –ONLY Time part
    Job_Code: string;
    PoolBiginning: number | null; // Integer Value or NULL
    WorkersHired: number; // Integer Value
    TotalHired: number; // Total workers in all terminal for the day
    createUser: string;
    createDept: string;
    createDate: string; // "2024-02-27T02:47:22.154Z"
    updateUser: string;
    updateDept: string;
    updateDate: string; // "2024-02-27T02:47:22.154Z"
    deleted: boolean;
    editStatus: string; // ADD/EDIT/DELETE
    isValid: boolean; // Return value after api success status
    errorMessages: string[]; // Returns errors if any
  }
export async function editPdoHire(
  data: EditPdoHireRequest
): Promise<EditPdoHireResponse> {
  const res: AxiosResponse<EditPdoHireResponse> = await AuthenticatedAPIReq.post(
    '/api/NJPORT/GetTerminalHires', // Update this with your actual endpoint
    data
  );
  return res.data;
}
export async function setTerminalHire(
  data: SetTerminalHireRequest
): Promise<EditPdoHireResponse> {
  const res: AxiosResponse<EditPdoHireResponse> = await AuthenticatedAPIReq.post(
    '/api/NJPORT/SetTerminalHire', // Update this with your actual endpoint
    data
  );

  return res.data;
}
