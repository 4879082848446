import React, {useState} from "react";

interface DashboardDataGridProps {
    location:string,
    data:any
}

const DashboardDataGrid: React.FC<DashboardDataGridProps> = ({location, data}) => {
    const [percentChange, setPercentChange] = React.useState<number>(0)
    const [lastYearTotal, setLastYearTotal] = React.useState<number>(0)
    let today = new Date()
    let thisYear = today.getFullYear()
    const [changeType, setChangeType] = React.useState<string>('')
     

    React.useEffect(() => {
        let change:any = 0
        if(data.YearlyData)
        {
            let x = data.YearlyData.find((e:any) => e?.Year === (new Date().getFullYear() - 1))
            if(x && x.hasOwnProperty('Alive') && x.hasOwnProperty('Dead'))
            {
                setLastYearTotal((x.Alive + x.Dead))
            }
        }
        if(data.YTDData)
        {
            let decrease = data.YTDData[1]?.YTDCount - data.YTDData[0]?.YTDCount 
            change = (decrease / data.YTDData[1]?.YTDCount) * 100
            if(Math.sign(change) === -1)
            {
                setChangeType('Increase')
                change = (change * -1)
                setPercentChange(change)
            }
            else
            {
                setChangeType('Decrease')
            }
            change = (Math.round(change * 100) / 100) 
        }
        setPercentChange(change)
    }, [data]);
    
    return (
        <div className = "border-gray-900 border-2">
            <div className="bg-blue-900 font-bold text-yellow-400 text-center text-medium">
                Total Year To Date Victims
                <div className = "text-center font-semibold text-xs" >{location}</div>
            </div>
            <div className = "border-gray-900 border-t-2">
                
                {data.YTDData ? data.YTDData.map((data:any) => (
                    <div className = "text-center font-bold">
                        <span style={{display:'inline'}} className={"mr-1"}>{data.Year}: {data.YTDCount}</span>
                        {(data.Year === new Date().getFullYear() -1 ) ? <span className={"text-right"}>Total: {lastYearTotal}</span> : <span></span>}
                    </div>
                )) : <></>}
                {percentChange ?
                <div className = {changeType === 'Increase' ? "text-center font-bold text-sm text-red-600" : "text-center font-bold text-sm text-green-500"}>%{changeType} : {percentChange ? percentChange : 0}%</div>
                    :
                    <></>
                }
            </div>
            
        </div>

    );
}

export default DashboardDataGrid;
