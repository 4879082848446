import React, {useState} from "react";
//import ArrestSearch from "../../components/tools/codis/_codisSearch";
import SearchResults from "../../components/tools/codis/_searchResultsCodis";
import CodisSearch from "../../components/tools/codis/_codisSearch";
import { CodisHit, CodisCase } from "../../interfaces/Codis.interface";
import { GetDarInfo_Query, PersonInfo, getDarInfoResponse } from "../../interfaces/getDarInfo_interface";
import {RunDarQuery} from "../../services/getDar.service";
import {Account} from "../../interfaces/auth_interface";
import {getUser, isUserPermission} from "../../services/auth.service";
import {handleSearchTime} from "../../services/formatDate.service";

function CodisNotification() {
    let [codisHit, setCodisHit] = useState<any[]>([])
    let [codisCase, setCodisCase] = useState<any[]>([])
    let [persons, setCodisPersons] = useState<any[]>([])
    let [user, setUser] = useState<Account>(getUser())
    let [noData, setNoData] = useState<boolean>(false)

    const SubmitSearch = async (params:any, dateType:string, andOrType:string, dropDowns:any) => {
        let search_query: GetDarInfo_Query = {
            "QueryType": "8",
            "Dept": user.Dept,
            "EmpID": user.EmpID,
            "DeptORI": user.DeptORI,
            "DateRange": true,
            "DateTypeValue": (dateType === "Entry Date") ? 0 : 1,
            "FromDate": handleSearchTime(params?.fromDate, 'fromDate')?.toLocaleString(),
            "ToDate": handleSearchTime(params?.toDate, 'toDate')?.toLocaleString(),
            "SearchType": andOrType,
            "IndicatorOn": true,
            "TopArrestNumber": 0,
            "FNames": params.FirstName ? params.FirstName.split(',') : [],
            "MNames": params.MI ? params.MI.split(',') : [],
            "LNames": params.LastName ? params.LastName.split(',') : [],
            "DOBs": params.DOB ? params.DOB.split(',') : [],
            "SEX": dropDowns.Sex ? dropDowns.Sex : [],
            "Address": "",
            "Cities": dropDowns.City ? dropDowns.City : [],
            "States": dropDowns.State ? dropDowns.State : [],
            "Zip": "",
            "Statutes": dropDowns.CodisOffense ? dropDowns.CodisOffense : [],
            "Gang": params.HitNo ? params.HitNo.split(',') : [],
            "FBIs": params.FBI ? params.FBI.split(',') : [],
            "SBIs": params.SBI ? params.SBI.split(',') : [],
            "DLNos": params.DL ? params.DL.split(',') : [],
            "PlateNos": params.DNANo ? params.DNANo.split(',') : [],
            "VINs": [],
            "AgencyArrested": dropDowns.AgencyArrested ? dropDowns.AgencyArrested : [],
            "InfocopDept": dropDowns.InfoCopDept ? dropDowns.InfoCopDept : [],
            "Indicators": dropDowns.Indicator ? dropDowns.Indicator : [],
            "IndicatorOccurrence": dropDowns.IndicatorOccurrence ? dropDowns.IndicatorOccurrence : null,
            "Latitude": null,
            "Longitude": null,
            "Radius": 0,
            "ResidentCounty": dropDowns.ResidentCounty ? dropDowns.ResidentCounty : [],
            "ArrestedCounty": dropDowns.ArrestedCounty ? dropDowns.ArrestedCounty : [],
            "Arrest_Type": dropDowns.ArrestType ? dropDowns.ArrestType : [],
            "CaseNumbers": params.Case ? params.Case.split(',') : [],
            "FTSIDs": params.LabSpecimen ? params.LabSpecimen.split(',') : [],
            "SystemCall": params.systemCall ?? false,
        }
        let qRes:getDarInfoResponse = await RunDarQuery(search_query)
        let codisHit:any[] = []
        let codisCase:any[] = []
        let codisPerson:any[] = []

        setCodisHit([])
        setCodisCase([])
        setCodisPersons([])
    
        if(qRes && qRes?.AnyTable) {
            for (var i of qRes.AnyTable) {
                if (!codisHit.some((hit: any) => hit.HitNo === i.HitNo)) {
                    codisHit.push(i)
                }
                if (!codisCase.some((cCase: any) => cCase.CodisCaseID === i.CodisCaseID)) {
                    codisCase.push(i)
                }
                if (!codisPerson.some((person: any) => person.CodisPersonID === i.CodisPersonID)) {
                    codisPerson.push(i)
                }
            }
        }

        if (qRes?.AnyTable?.length === 0) {
            setNoData(true)
        }
        else {
            setNoData(false)
        }

        setCodisHit(codisHit)
        setCodisCase(codisCase)
        setCodisPersons(codisPerson)
    }
    return (
        <div id="ToolsCodis">
            {isUserPermission('CODIS')?
                <>
                    <CodisSearch SubmitSearch={SubmitSearch} />
                    {!noData ? <SearchResults CodisHit={codisHit} CodisCase={codisCase} Persons={persons} /> : <div className="font-bold text-lg ml-5">NO Matching Records Found</div>}
                </>
            :<></>}
        </div>
    );
}

export default CodisNotification;
