import React, {useState} from 'react'

import {
    Alert,
    Box,
    Button,
    ButtonGroup,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    IconButton,
    List,
    Slide, Snackbar,
    Switch,
    Tab,
    Tooltip
} from "@mui/material";
import PersonPhotoIcon from "../../../assets/Images/photo1.png";
import ArrestIcon from "../../../assets/Images/ai1.png";
import InfoCopIcon from "../../../assets/Images/ic1.png";
import ETicketIcon from "../../../assets/Images/et1.png";
import ParkMobileIcon from "../../../assets/Images/pm1.png";
import ALPRIcon from "../../../assets/Images/alpr1.png";
import CADRMSIcon from "../../../assets/Images/Opencase1.png";
import CODISIcon from "../../../assets/Images/DNA1.png";
import TowingIcon from "../../../assets/Images/towing1.png";
import PrisonReleaseIcon from "../../../assets/Images/jail1.png";
import GunTraceIcon from "../../../assets/Images/WeaponTrace1.png";
import ShootingIcon from "../../../assets/Images/Shooting.png";
import ShootingSuspectIcon from "../../../assets/Images/ShootingRed.png";
import ShootingVictimIcon from "../../../assets/Images/ShootingGreen.png";
import NibinIcon from "../../../assets/Images/nibin1.png";
import NJGunTraceIcon from "../../../assets/Images/NjGunTrace1.png";
import PrisonParoleInactiveIcon from "../../../assets/Images/parole_Inactive.png";
import PrisonParoleActiveIcon from "../../../assets/Images/parole1.png";
import ViolentIcon from "../../../assets/Images/Violent1.png";
import HumanTrafficIcon from "../../../assets/Images/HumanTraffic1.png";
import ProfesionIcon from "../../../assets/Images/profession1.png";
import ReferralIcon from "../../../assets/Images/Referral1.png";
import ReferralRearrestedIcon from "../../../assets/Images/ReferralRearrested1.png";
import ArrestRecentIcon from "../../../assets/Images/aiRecent.png";
import PrisonReleaseRecentIcon from "../../../assets/Images/jailRecent.png";
import GunTraceRecentIcon from "../../../assets/Images/WeaponTraceTTC.png";
import NJGunTraceTriggerLockIcon from "../../../assets/Images/NjGunTraceTrigerLock.png";
import ProfileIcon from "../../../assets/Images/profile.png";
import DomViolenceIcon from "../../../assets/Images/domesticviolence.png";
import SexCrimeIcon from "../../../assets/Images/sexcrime.png";
import BTPersonReport from "../../shared/_BTPersonReport";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { PostAdd, Close } from '@mui/icons-material';
import { TransitionProps } from '@mui/material/transitions';
import {
    ColumnDirective,
    ColumnsDirective,
    ColumnMenu,
    GridComponent,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search, ExcelExportProperties,
    Resize, Reorder, SortSettingsModel, CommandColumn, setChecked,
    expandChildGrid,
    GridModel
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import NJTracePerson from './_searchResultsNJTracePerson'
import { ETraceResults_PDF, GetNJTrace, NJTracePDFReport, RunSharedToken, SendNJTracetoATF, SetNJTrace } from '../../../services/account.service';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import NJTraceInputForm from './_njtraceInputForm';
import { JAOS } from '../../../services/JAOS.service';
import { NJTraceObject, NJTraceMainObject } from "../../../interfaces/Detail/NJTraceDetail.interface"
import {GetDefaultStateForSite, GetStringNameForSite} from "../../../interfaces/config.interface";
import {SiteName} from "../../../services/config.service";
import {getUser, isUserPermission} from "../../../services/auth.service";
import RTCCIcon from '../../../assets/Images/RTCC.png';
import { Account } from '../../../interfaces/auth_interface';
import SearchResultsWeapon from './_searchResultsNJTraceWeapon';
import AttachFileSharpIcon from '@mui/icons-material/AttachFileSharp';
import NJTraceSearch from './_njtraceSearch';
import DescriptionIcon from '@mui/icons-material/Description';

interface SearchResultsProps {
    SubmitSearch:any
    NJTraceMain: any[],
    NJTraceCase: any[],
    Persons: any[],
    NJTraceWeapon: any[],
    NJTraceWeaponStatus: any[],
    DashboardInformation: any[],
    passedData?: any | null
    isNew?: boolean | null
    searchListViewSelected?: string

}

const SearchResults: React.FC<SearchResultsProps> = ({ NJTraceMain, NJTraceCase, Persons, NJTraceWeapon, NJTraceWeaponStatus, DashboardInformation, passedData = null, isNew = false, searchListViewSelected = "Trace",  SubmitSearch }) => {
    const [formatData, setFormatData] = React.useState<any>([])
    const [Data, setData] = React.useState<NJTraceObject>({})
    const [tabValue, setTabValue] = React.useState('1');
    const [editTabName, setEditTabName] = React.useState(`NEW ${GetStringNameForSite(SiteName,'NJTRACE')}`);
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false)
    const [alertText, setAlertText] = React.useState<string>("") 
    const [alertType, setAlertType] = React.useState<any>("success")
    const [hash, setHash] = React.useState<string | null>(null);
    const [njTraceList, setNjTraceList] = React.useState<any[]>([])
    const [pdfReportDialog, setPdfReportDialog] = React.useState<boolean>(false)
    const [user, setUser] = useState<Account>(getUser())
    const [searchListView, setSearchListView] = React.useState<string>("") 
    const [openDialog, setOpenDialog] = React.useState(false);


    //initialize object to keep track of status while page is not refreshed. Couldn't use state variable in synfusion table
    let updatedWeaponStatus=[{ID:0, WEAPONSTATUS:""}]
    let updatedPossesorStatus=[{ID:0, PROCESSORSTATUS:""}]

    let grid: Grid | null;
    let selectedRows: any[] = [];


    const handleDataTyping = () => {
        if (isNew)
            setTabValue('2');
        else if (NJTraceMain) {
            let tempP: any = [...NJTraceMain]
            for (let p of tempP) {
                if (p.CreateDate) {
                    p.CreateDate = new Date(p.CreateDate)
                }
                if (p.OffenseDate) {
                    p.OffenseDate = new Date(p.OffenseDate)
                }
                if (p.IncidentDate) {
                    p.IncidentDate = new Date(p.IncidentDate)
                }
                if (p.ATF_trace_ReceivedDate) {
                    p.ATF_trace_ReceivedDate = new Date(p.ATF_trace_ReceivedDate)
                }

            }
            setFormatData(tempP)
            checkPassedData();
        }
        else if (passedData) {
            let l = new JAOS();
            let hashID = l.objToStack(passedData);
            setHash(hashID);
            setEditTabName(`Edit ${GetStringNameForSite(SiteName,'NJTRACE')}`);
            setTabValue('2');
        }
        setSearchListView(searchListViewSelected)    
    }

    const checkPassedData = async () => {
        if (passedData && NJTraceMain.length > 0) {
            setOpenDialog(true);
        }
        else {
            setTabValue('1');
        }
    }

    const handleNJTraceSubmit = (type:string, record:any = null) => {
        setHash(null);
        setEditTabName(`NEW ${GetStringNameForSite(SiteName,'NJTRACE')}`);
        setTabValue('1');
        setAlertOpen(false)
        //console.log(type, record)
        switch(type){
            case 'New':
                setAlertOpen(true)
                setAlertType("success")
                setAlertText("New Record: " + record?.main?.CaseNumber + " Successful")
            break
            case 'Edit':
                setAlertOpen(true)
                setAlertType("success")
                setAlertText("Update Record: " + record?.main?.CaseNumber + " Successful")
            break            
        }
        //Refresh page after saving
        let today = new Date()
        let p ={ FirstName: "", MI: "", LastName: "", DOB: "", FBI: "", SBI: "", Case: "", Serial: "", toDate: today, fromDate: today, PersonDetail: !isUserPermission('NJTrace') ? "Person" : "Trace", systemCall: false }
        let dateType ="Recovery Date"
        let andOrType = "AND"
        SubmitSearch(p, dateType, andOrType, {Status:[], ResponseStatus:[]})
        
    }



    React.useEffect(() => {
        handleDataTyping()
    }, [NJTraceMain])

    
    const getNJTraceDetailRow = (NJTraceMain: any) => {
        //let NJTraceCaseInfo = NJTraceCase?.filter((x) => x.FTS_ID === NJTraceMain.FTS_ID)
        let PersonsInfo = Persons?.filter((x) => x.FTS_ID === NJTraceMain.FTS_ID)
        let WeaponInfo = NJTraceWeapon?.filter((x)=> x.FTS_ID === NJTraceMain.FTS_ID)
        let WeaponStatusInfo = NJTraceWeaponStatus?.filter((x)=> x.FTS_ID === NJTraceMain.FTS_ID)
        return (
            <div>
                {/*<NJTraceCaseDisplay NJTraceCase={NJTraceCaseInfo} />*/}
                <SearchResultsWeapon NJTraceWeapon={WeaponInfo} NJTraceWeaponStatus={WeaponStatusInfo} />
                <NJTracePerson NJTracePerson={PersonsInfo}/>
            </div>
        )
    }

    const GridToolsClick = (args: ClickEventArgs) => {
        if (grid && args.item.text === "Excel Export") {
            //detailed excel can only be seen by NJTrace Permission
            let excelProp: ExcelExportProperties = isUserPermission('NJTrace') ? {dataSource:NJTraceCase, includeHiddenColumn:true} : {includeHiddenColumn:false}
            excelProp.fileName = 'NJTrace.xlsx'
            grid.excelExport(excelProp);
        }
    }

    const actionTemplate = (njtrace: any): any => {
        return (
            <span>
                <ButtonGroup>
                     <Tooltip title="ATF Results" placement="top" arrow>
                        <span>
                            <IconButton disabled={!isUserPermission('NJTraceAdd')|| njtrace?.ATF_Received_Multiple == null} onClick={() => viewATFResults(njtrace)}><DescriptionIcon/></IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title="Edit" placement="top" arrow>
                        <IconButton disabled={!isUserPermission('NJTrace')} onClick={() => editNJTrace(njtrace)}><EditIcon /></IconButton>
                    </Tooltip>
                    <Tooltip title="Delete" placement="top" arrow>
                        <IconButton disabled={!isUserPermission('NJTrace')}  onClick={() => deleteNJTrace(njtrace)}><DeleteIcon /></IconButton>
                    </Tooltip>
                </ButtonGroup>
            </span>);
    }

    const statusWeaponTemplate = (njTrace: any): any => {
        return (
            <ReactiveSlider1 njTrace={njTrace} grid={grid} />
        )
    }

    const statusPossessorTemplate = (njTrace: any): any => {
        return (
            <ReactiveSlider2 njTrace={njTrace} grid={grid}/>
        )
    }

    //Weapon Status toggle
    const ReactiveSlider1: React.FC<any> = ({ njTrace, grid }) => {
        let [weaponstatus, setweaponStatus] = useState<string>(njTrace?.WeaponStatus);
        let [newPossessorStatus, setpossessorStatus] = useState<string>(njTrace?.PossessorStatus); 
        const setNJTraceStatus = async (e: any) => { 
            //linked value change to event 
            let tempDataSource:any = [...grid?.dataSource]
            let tempWeaponStatus="";
            if(e){
                tempWeaponStatus = "COMPLETE"
            }
            else{
                tempWeaponStatus = "PENDING"
            }
            
            //updatedWeaponStatus is an array to keep track of changes
            //if object doesn't exist in the array 
            if(!updatedWeaponStatus.some(res => res.ID === njTrace.MainID))
            {
                //add it with the ID and status selected 
                updatedWeaponStatus.push({ID: njTrace.MainID, WEAPONSTATUS: tempWeaponStatus})   
            }
            else {
                //update list with the status selected
                for (let wStatus of updatedWeaponStatus){
                    if(wStatus.ID === njTrace.MainID){
                        wStatus.WEAPONSTATUS = tempWeaponStatus

                    }
                }         
            }

    //         //Before Setting NjtraceMain check updatedPossesorStatus array to get the latest value
            for(let item of updatedPossesorStatus){
                if(item.ID != 0)
                    if(item.ID === njTrace.MainID && item.PROCESSORSTATUS !== newPossessorStatus)
                        newPossessorStatus = item.PROCESSORSTATUS
            }
            let tempTraceType = njTrace.TraceType ?? GetDefaultStateForSite(SiteName)
            let njTraceMain: NJTraceMainObject = { ID: njTrace.MainID, FTS_ID: njTrace.FTS_ID, FTS_IDOriginal: njTrace.FTS_ID, TraceType: tempTraceType , WeaponStatus: tempWeaponStatus, PossessorStatus: newPossessorStatus , EditStatus:9  };
            let r = await SetNJTrace({ main: njTraceMain })
            // if (!r?.IsValid) {
            //     tempWeaponStatus = weaponstatus; 
            // }
            if(r?.IsValid){
                //update data source with updated weapon value
                let index:number = tempDataSource.findIndex((e: any) => e.MainID === njTrace.MainID)
                if(index >= 0){
                    tempDataSource[index].WeaponStatus = tempWeaponStatus
                    tempDataSource[index].PossessorStatus = newPossessorStatus
                    grid.dataSource = tempDataSource
                    grid?.refresh()
                }
            } 
            setweaponStatus(tempWeaponStatus)
            setpossessorStatus(newPossessorStatus)
            SendNJTraceToATF(njTraceMain,grid, tempDataSource)            

        }        
        
        return (
            <span>
                <FormControl component="fieldset" variant="standard">
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch disabled={!isUserPermission('NJTrace') || njTrace?.ATF_trace_Complete === "Y"}  onChange={(e) => setNJTraceStatus(e.target.checked)} checked={weaponstatus === "COMPLETE" } />                        }
                                label={weaponstatus === "COMPLETE" ? "COMPLETE" : "PENDING"}
                        />
                    </FormGroup>
                </FormControl>
            </span>
        );
    }

    // //Possessor Status toggle
    const ReactiveSlider2: React.FC<any> = ({ njTrace , grid }) => {
        let [possessorStatus, setpossessorStatus] = useState<string>(njTrace?.PossessorStatus);   
        let [newWeaponStatus, setweaponStatus] = useState<string>(njTrace?.WeaponStatus);  
        const setNJTraceStatus = async (e: any) => {

            let tempDataSource:any = [...grid?.dataSource]
            //linked value change to event 
            let tempPossessorStatus="";
            if(e){
                tempPossessorStatus = "COMPLETE"
            }
            else{
                tempPossessorStatus = "PENDING"
            }
            //updatedPossesorStatus is an array to keep track of changes
            //if object doesn't exist in the array 
            if(!updatedPossesorStatus.some(res => res.ID === njTrace.MainID))
            {
                //add it with the ID and status selected 
                updatedPossesorStatus.push({ID: njTrace.MainID, PROCESSORSTATUS: tempPossessorStatus}) 
            }
            //update list with the status selected
            else {
                for (let pStatus of updatedPossesorStatus){
                    if(pStatus.ID === njTrace.MainID){
                        pStatus.PROCESSORSTATUS = tempPossessorStatus
                    }
                }         
            }
            for(let item of updatedWeaponStatus){
                if(item.ID != 0) 
                    if(item.ID === njTrace.MainID && item.WEAPONSTATUS !== newWeaponStatus)
                        newWeaponStatus = item.WEAPONSTATUS

            }
            let tempTraceType = njTrace.TraceType ?? GetDefaultStateForSite(SiteName)
            let njTraceMain: NJTraceMainObject = { ID: njTrace.MainID, FTS_ID: njTrace.FTS_ID, FTS_IDOriginal: njTrace.FTS_ID, TraceType: tempTraceType , WeaponStatus: newWeaponStatus, PossessorStatus: tempPossessorStatus, EditStatus:9};
            let r = await SetNJTrace({ main: njTraceMain })
            // if (!r?.IsValid) {
            //     tempPossessorStatus = possessorStatus;
            // }  
            if(r?.IsValid){
                //update data source with updated possessor value
                let index:number = tempDataSource.findIndex((e: any) => e.MainID === njTrace.MainID)
                if(index >= 0){
                    tempDataSource[index].PossessorStatus = tempPossessorStatus
                    tempDataSource[index].WeaponStatus = newWeaponStatus
                    grid.dataSource = tempDataSource
                    grid?.refresh()
                }
            } 
            setpossessorStatus(tempPossessorStatus);
            setweaponStatus(newWeaponStatus)
            SendNJTraceToATF(njTraceMain, grid, tempDataSource)
        }

        return (
            <span>
                <FormControl component="fieldset" variant="standard">
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch disabled={!isUserPermission('NJTrace') || njTrace?.ATF_trace_Complete === "Y"} onChange={(e) => setNJTraceStatus(e.target.checked)} checked={possessorStatus === "COMPLETE"} />
                            }
                            label={possessorStatus === "COMPLETE" ? "COMPLETE" : "PENDING"}
                        />
                    </FormGroup>
                </FormControl>
            </span>
        );
    }

    const viewATFResults = async (njTrace: any) =>{
        if(njTrace?.ATF_trace_id){
            if(njTrace?.ATF_trace_id && njTrace?.ATF_trace_id.length > 0){
                let sStatus = true;
                let njTraceMain: NJTraceMainObject = {FTS_ID: njTrace.ATF_trace_id};
                let eTracePDF = await ETraceResults_PDF({main:njTraceMain})

            }        
            
        }
       


    }

    const editNJTrace = (njTrace: any) => {
        let njTraceMain: NJTraceMainObject = { ID: njTrace.MainID, FTS_ID: njTrace.FTS_ID, FTS_IDOriginal: njTrace.FTS_ID,};

        let l = new JAOS();
        let hashID = l.objToStack(njTraceMain);
        setHash(hashID);
        setEditTabName(`Edit ${GetStringNameForSite(SiteName,'NJTRACE')}`);
        setTabValue('2');
    }

    const deleteNJTrace = async (njTrace: any) => {
        let p = { ...njTrace }
        if (window.confirm(`Remove This ${GetStringNameForSite(SiteName, 'NJTRACE')} Record Permanently?`)) {
            let sStatus = true;
            let njTraceMain: NJTraceMainObject = { ID: njTrace.MainID, FTS_ID: njTrace.FTS_ID, FTS_IDOriginal: njTrace.FTS_ID, Deleted: sStatus };
            let r = await SetNJTrace({main:njTraceMain})
            if (r.IsValid) {
                setAlertType("success")
                setAlertOpen(true)
                setAlertText("Delete Successful (Changes Will Take Effect After The Next Search)")
            }
            else {
                let errString=""
                if(r?.AnyData?.ErrorMessages && r?.AnyData?.ErrorMessages?.length > 0){
                    for(let error of r?.AnyData?.ErrorMessages){
                        errString+= error + '\n'
                    }
                    alert(errString ? errString : 'Something went wrong when deleting.')
                }
                else{
                    alert('Something went wrong when deleting.')

                }
            }                  
        }
    }

    const SendNJTraceToATF = async (njTraceMain: any, grid:Grid, tempDataSource:any) => {
        if (njTraceMain?.WeaponStatus === "COMPLETE" && njTraceMain?.PossessorStatus === "COMPLETE") {
            if( window.confirm(`Do you want to submit ${GetStringNameForSite(SiteName, 'NJTRACE')} record to ATF(eTrace)?`)){
                //if user click ok to send to atf
                let atfResultsString="" 
                let statusResult = []
                let r = await SendNJTracetoATF({ main: njTraceMain})
                if (r?.IsValid) {
                    //Need to handle if response from atf is valid and each weapon has Accepted/Retained status disable edit/status toogle 
                    if(r?.ATFResults && r?.ATFResults?.length > 0){
                        for(let atfResult of r?.ATFResults){
                            if(atfResult.traces && atfResult.traces?.length > 0){
                                for(let trace of atfResult.traces){
                                    //let status = trace?.some((e:any) => e.status === "Accepted" || e.status === "Retained" || e.status === "Duplicate") 
                                    //statusResult.push(status)    
                                    if(trace?.record_number){
                                        atfResultsString += "Record Number: " + trace?.record_number + "\n"
                                    }
                                    if(trace?.trace_id){
                                        atfResultsString += "ATF ID: " + trace?.trace_id + "\n"
                                    }                               
                                    if(trace?.status){
                                        atfResultsString += "ATF Status: " + trace?.status + "\n"
                                        //any of these status means that there is a trace id 
                                        if(trace.status === "Accepted" || trace.status === "Retained" || trace.status === "Duplicate" || trace.status === "Update Rejected"){
                                            statusResult.push(true)
                                        }
                                        else{
                                            statusResult.push(false)
                                        }
                                    }  
                                    if(trace?.messages?.errors){
                                        for(let error of trace?.messages?.errors){
                                            if(error?.description){
                                                atfResultsString+= "Error: " + error?.description + "\n"
                                            }
                                        }
                                    }
                                    if (trace?.messages?.warnings){
                                        for(let warning of trace?.messages?.warnings){
                                            atfResultsString+= "Warning: " + warning?.description + "\n"
                                        }
                                    }                           
                                                                                                                                 
                                }
                            }
                        }                       
                        //display results
                        if(atfResultsString && atfResultsString.length > 0){
                            alert(atfResultsString)
                        }
                        //if traces were not rejected (existing trace id)                   
                        if(statusResult.some((e:any)=> e === true)){
                            updateDataSource(tempDataSource, njTraceMain, false , grid , r?.Main?.CaseNumber)                                  
                        }
                        else{

                            njTraceMain.WeaponStatus = "PENDING" 
                            njTraceMain.EditStatus = 9                                               
                            let r = await SetNJTrace({ main: njTraceMain})
                            if(r?.IsValid){
                                updateDataSource(tempDataSource, njTraceMain, true , grid , r?.Main?.CaseNumber)                       
                            }                                  
                        }                       
                    }
                    if((r?.Weapons && r?.Weapons.length > 0)){
                        for(let weapon of r?.Weapons){
                            if(weapon?.ATFStatus && weapon?.ATFStatus.length > 0){
                                let status = weapon?.ATFStatus.some((e:any) => e.ATF_trace_id != null)
                                statusResult.push(status)                                                                        
                            }
                        }
                    }                   
                }
                else {
                    if(r?.ErrorMessages){
                        alert(r?.ErrorMessages)
                        njTraceMain.WeaponStatus = "PENDING"
                        njTraceMain.EditStatus = 9
                        let res = await SetNJTrace({ main: njTraceMain})
                        if(res?.IsValid){
                            updateDataSource(tempDataSource, njTraceMain, true , grid , r?.Main?.CaseNumber)                     
                        }  

                    }
                    else{
                        alert("Something went wrong when sending to ATF. Please refresh the page.")
                    }                        
                } 
            }
            else {
                //If user clicks cancel send to atf set weapon status back to pending 
                njTraceMain.WeaponStatus = "PENDING"
                njTraceMain.EditStatus = 9
                let r = await SetNJTrace({ main: njTraceMain})
                if(r?.IsValid){
                    let index:number = tempDataSource.findIndex((e: any) => e.MainID === njTraceMain.ID)
                    if(index >= 0){
                            tempDataSource[index].WeaponStatus = "PENDING"
                            grid.dataSource = tempDataSource
                            grid?.refresh()
                    }
                }        
            }      
        }
    }

    const updateDataSource = (tempDataSource:any, njTraceMain :any , failed : boolean, grid : Grid , caseNumber:any) =>{

        if (failed){
            let index:number = tempDataSource.findIndex((e: any) => e.MainID === njTraceMain.ID)
            if(index >= 0){
                tempDataSource[index].WeaponStatus = "PENDING"
                if(grid){
                    grid.dataSource = tempDataSource
                    if(grid?.pageSettings?.currentPage !== 1){
                        grid.pageSettings.currentPage = 1
                    }
                    grid?.refresh()
                }
                
            }
            setAlertType("error")
            setAlertOpen(true)
            setAlertText(`Unable to send ${GetStringNameForSite(SiteName, 'NJTRACE')} record `+ (caseNumber ?? '') + ".") 
        }
        else{
                let index:number = tempDataSource.findIndex((e: any) => e.MainID === njTraceMain.ID)
                if(index >= 0){
                    tempDataSource[index].ATF_trace_Complete = "Y"
                    if(grid){
                        grid.dataSource = tempDataSource
                        //data reloads and sets to first page without updating the current page
                        if(grid?.pageSettings?.currentPage !== 1){
                            grid.pageSettings.currentPage = 1
                        }
                    }
                    
                }            
                setAlertType("info")
                setAlertOpen(true)
                setAlertText(`${GetStringNameForSite(SiteName, 'NJTRACE')} record was sent to ATF for case #: `+ caseNumber + ".") 
            }        
    }


    const expandRow = (args:any) => {
        // if (grid) {
        //     const selectedrowindex: number[] = grid.getSelectedRowIndexes()
        //     for (let idx of selectedrowindex) {

        //         grid.detailRowModule.expand(idx)
        //     }
        // }
        let rows = [...selectedRows];
           if  (args?.data && args?.data?.length !== formatData.length) {
               var result = rows.findIndex(x => x.PersonID === args?.data?.PersonID)
               if (result === -1) {
                   let person = args?.data;
                   if (person && person?.PersonID) {
                       rows.push(person)
                   }
               }
               else {
                   rows.splice(result,1)
               }
           }
       
       selectedRows=rows
      
    }

    let sortSettings: SortSettingsModel = {
        columns: [
            { field: 'CreateDate', direction: 'Descending' },
        ]
    };

    const handleTabChange = (event: React.SyntheticEvent , newValue: string) => {
        if (newValue == '1') {
            setHash(null);
            setEditTabName(`NEW ${GetStringNameForSite(SiteName,'NJTRACE')}`);
        }
        setTabValue(newValue);
    };

    const selectTemplate = (njtrace: any): any => {
        return (
        <>
            <Checkbox 
                onClick={() => {buildNJTraceList(njtrace)}}              
            />
        </>);
    }
    
    const buildNJTraceList = (njtrace : any) => {
        let temp = njTraceList
        if(njtrace){
            if(njtrace.FTS_ID){
                var result = temp.findIndex(x => x === njtrace.MainID)
                if(result === -1){
                    temp.push(njtrace.MainID.toString())
                }
                else{
                    temp.splice(result,1)
                }
                setNjTraceList(temp)
            }
        }
    }

    const createReport = async () => {
        //let temp = {IDs:njTraceList, ForceIt: true}
        if (njTraceList.toString().trim().length > 0){
            let l = new JAOS()
            let hashID = l.objToStack(njTraceList.toString());
            let ListID = l.objFromStack(hashID);
            window.open("/detail/ALPRPDFReport" + '?ID=' + hashID + '&Dept=', '_blank')
        } 
       
    }


    const imageTemplate =  (njtrace: any): any => {
        return(
            <>
                <Tooltip title='Weapon Images Attached' arrow> 
                    {njtrace?.Weapon_Image && njtrace?.Weapon_Image > 0 ?
                    <AttachFileSharpIcon/>
                    :<></>}  
                </Tooltip>              
            </>
        )
    }

    const parseATFRejecteNotes =(njtrace: any) :any =>{
        let rejectedNotes = ""
        if(njtrace){
            try{
                if(njtrace?.ATFRejectedNotes != null && njtrace?.ATFRejectedNotes?.length > 0){
                    let tempE = JSON.parse(njtrace?.ATFRejectedNotes)
                    if(tempE?.errors){
                        for(let err of tempE?.errors){
                            if(err?.description){
                                rejectedNotes+= err?.description + "\n"
                            }
                        }
    
                    }
                }           
            }
            catch(e){
                console.log(e)
                rejectedNotes=""
            }

        }
       
        return(
            <>
            {rejectedNotes}
            </>
        )    
    }

   
      
    const onDataBound = () => {
    if (grid) {
        grid?.detailRowModule?.expandAll();
    }
    }

    return (
        <div className="m-5 p-5">        
            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={ () => { setAlertOpen(false)}}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertType} sx={{ width: '100%' }}>
                    {alertText}
                </Alert>
            </Snackbar>
            <Box className="" style={{ justifyContent:'center' }}>
                <TabContext value={tabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleTabChange} variant="fullWidth">
                            <Tab icon={<List />} label="List View"  value={'1'}/>
                           <Tab icon={<PostAdd />} label={editTabName} value={'2'} />                         
                        </TabList>
                    </Box>
                    <TabPanel value='1'>
                        <div>
                            {                         
                                searchListView === "Trace" && DashboardInformation && NJTraceMain && NJTraceMain?.length > 0 ? 
                                <>                                                
                                    <span style ={{display:"inline-block"}} className='mb-5'>
                                        <h4 className={"font-bold inline mr-5 text-lg"}> {NJTraceMain?.length} Records Found
                                        {
                                            DashboardInformation?.map((dash : any ) => (
                                            <>
                                            &nbsp;&nbsp;&nbsp;&nbsp;YTD Weapon: {dash.YTD_Weapon}, YTD Possesor: {dash.YTD_Possesor},  24 Hour Weapon: {dash["24Hour_Weapon"]}, 24 Hour Possesor: {dash["24Hour_Possesor"]}

                                            </>))
                                        }
                                        </h4> 
                                    </span>      
                                    <GridComponent
                                        dataSource={formatData}
                                        allowPaging={true}
                                        pageSettings={{ pageSize: 25, pageSizes: [10, 25, 50, 100] }}
                                        allowSorting={true}
                                        sortSettings={sortSettings}
                                        allowMultiSorting={true}
                                        allowFiltering={true}
                                        filterSettings={{ type: 'CheckBox' }}
                                        detailTemplate={getNJTraceDetailRow}
                                        allowTextWrap={true}
                                        textWrapSettings={{ wrapMode: 'Both' }}
                                        allowExcelExport={true}
                                        allowPdfExport={true}
                                        toolbar={['ExcelExport', 'Search']}
                                        toolbarClick={GridToolsClick}
                                        //ref={g => grid = g}
                                        ref={(g) => { if (g) { grid = g } }}
                                        selectionSettings={{
                                            persistSelection: true,
                                            type: "Multiple",
                                            //checkboxOnly: true,
                                            mode: "Row"}}
                                        //rowSelecting={(event: any) => { console.log(event) }}
                                        rowSelected={(event: any) => { expandRow(event) }}
                                        //dataBound={onDataBound}
                                        //allowSelection={true}
                                        allowResizing={true}
                                        rowHeight={40}

                                        
                                    >
                                        <ColumnsDirective>
                                            {/* <ColumnDirective template={selectTemplate} headerText='Select' width={100} /> */}
                                            {/* hidden columns to show when exporting */}
                                            {/* Main */}
                                            <ColumnDirective field='MainID' headerText='Main ID' isPrimaryKey={true} width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='FTS_ID' headerText='FTS_ID' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective template={imageTemplate} headerText='Image' maxWidth={120} customAttributes={{ class: ['e-attr'] }}/>
                                            <ColumnDirective template={actionTemplate} headerText='Action' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                                            <ColumnDirective field='WeaponStatus' template={statusWeaponTemplate} headerText='Weapon Status' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                                            <ColumnDirective field='PossessorStatus' template={statusPossessorTemplate} headerText='Possessor Status' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                                            <ColumnDirective field='CreateDate' headerText='Entry Date' format={{ type: 'dateTime', format: 'MM/dd/yyyy HH:mm' }} width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                                            {/* <ColumnDirective field='IncidentDate' headerText='Recovery Date' format={{ type:'date' , format: 'MM/dd/yyyy' }} width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} /> */}
                                            <ColumnDirective field='Agency' headerText='Agency' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                                            <ColumnDirective field='AgencyCaseNo' headerText={GetStringNameForSite(SiteName,"Case #")} width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                                            <ColumnDirective field='NIBIN_NUMBER' headerText='NIBIN_NUMBER' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='OFFICE' headerText='OFFICE' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='MainDept' headerText='MainDept' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='CONTACTNo' headerText='CONTACTNo' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='REMARK' headerText='REMARK' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='TraceType' headerText='TraceType' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='Status' headerText='Status' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='CreateUser' headerText='CreateUser' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='CreateDept' headerText='CreateDept' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='UpDateUser' headerText='UpDateUser' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='UpdateDept' headerText='UpdateDept' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='UpdateDateTime' headerText='UpdateDateTime' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='Priority' headerText='Priority' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='crime_code' headerText='crime_code' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='completion_date' headerText='completion_date' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='completion_code' headerText='completion_code' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='completion_status' headerText='completion_status' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='completion_description' headerText='completion_description' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='completion_remarks' headerText='completion_remarks' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            {/* Reporting Officer*/}
                                            <ColumnDirective field='ORI_ID' headerText='ORI_ID' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='ORI_FIRSTNAME' headerText='ORI_FIRSTNAME' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='ORI_MIDDLENAME' headerText='ORI_MIDDLENAME' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='ORI_LASTNAME' headerText='ORI_LASTNAME' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='ORI_NUMBER' headerText='ORI_NUMBER' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='ORI_NAME' headerText='ORI_NAME' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='ORI_STREET' headerText='ORI_STREET' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='ORI_CITY' headerText='ORI_CITY' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='ORI_COUNTY' headerText='ORI_COUNTY' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='ORI_STATE' headerText='ORI_STATE' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='ORI_ZIP' headerText='ORI_ZIP' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='ORI_COUNTRY' headerText='ORI_COUNTRY' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='ORI_INV_NUMBER' headerText='ORI_INV_NUMBER' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='ENTERED_USER' headerText='ENTERED_USER' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='COMP_DATE' headerText='COMP_DATE' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='ORI_PRIORITY' headerText='ORI_PRIORITY' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='CRIME_CD' headerText='CRIME_CD' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='ORI_badge_number' headerText='ORI_badge_number' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            {/*Incident */}
                                            <ColumnDirective field='NJTraceCaseID' headerText='IncidentID' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='IncidentAddress' headerText='Incident Address' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='IncidentCity' headerText='Incident City' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                                            <ColumnDirective field='IncidentCounty' headerText='Incident County' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                                            <ColumnDirective field='IncidentState' headerText='Incident State' width={140} minWidth={140} maxWidth={140} visible={false}  />                                       
                                            <ColumnDirective field='IncidentZIP' headerText='Incident Zip' width={140} minWidth={140} maxWidth={140} visible={false} />  
                                            <ColumnDirective field='IncidentLatitude' headerText='Incident Latitude' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='IncidentLongitude' headerText='Incident Longitude' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='REC_STREET_NUMBER' headerText='REC_STREET_NUMBER' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='REC_STREET_NAME' headerText='REC_STREET_NAME' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='REC_STREET_SUFFIX' headerText='REC_STREET_SUFFIX' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='REC_STREET' headerText='REC_STREET' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='REC_COUNTRY' headerText='REC_COUNTRY' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='REC_TIME_TO_CRIME' headerText='REC_TIME_TO_CRIME' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='REC_APT_NUMBER' headerText='REC_APT_NUMBER' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='REC_STREET_DIRECTION' headerText='REC_STREET_DIRECTION' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='REC_ROUTE_NUMBER' headerText='REC_ROUTE_NUMBER' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='REC_Location_Type' headerText='REC_Location_Type' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            {/*Weapon*/}
                                            <ColumnDirective field='NJTraceWeaponID' headerText='Weapon ID' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='MANUFACTURER_NAME' headerText='Manufacturer Name' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='MODEL' headerText='Model' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='CALIBER' headerText='CALIBER' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='SERIAL_NUMBER' headerText='Serial Number' width={140} minWidth={140} maxWidth={140} visible={false} />                                        
                                            <ColumnDirective field='SERIAL_NUMBERs' headerText='Serial Number(s)' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                                            <ColumnDirective field='RECOVERY_DATE' headerText='RECOVERY_DATE' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='MANUFACTURER' headerText='MANUFACTURER' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='W_TYPE' headerText='W_TYPE' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='ORIGIN' headerText='ORIGIN' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='ORIGIN_COUNTRY_NAME' headerText='ORIGIN_COUNTRY_NAME' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='IMPORTER_NAME' headerText='IMPORTER_NAME' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='IDENTIFYING_MARKS' headerText='IDENTIFYING_MARKS' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='DEFACED_GUN' headerText='DEFACED_GUN' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='WEAPON_COMPLETION_CODE' headerText='WEAPON_COMPLETION_CODE' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='COMPLETION_TEXT' headerText='COMPLETION_TEXT' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='drill_size' headerText='drill_size' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='barrel_size' headerText='barrel_size' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='finish' headerText='finish' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='NIBIN' headerText='NIBIN' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='privately_made_firearm' headerText='privately_made_firearm' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='privately_made_firearm_category' headerText='privately_made_firearm_category' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='privately_made_firearm_source' headerText='privately_made_firearm_source' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='privately_made_firearm_material_composition' headerText='privately_made_firearm_material_composition' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='privately_made_firearm_material_color' headerText='privately_made_firearm_material_color' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='Agency_Possession_How' headerText='Agency_Possession_How' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='Not_Recovered' headerText='Not_Recovered' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='Vaultreview_TraceStudy' headerText='Vaultreview_TraceStudy' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='CONVERSION_DEVICE' headerText='CONVERSION_DEVICE' width={140} minWidth={140} maxWidth={140} visible={false} />                                       

                                            {/* ATF Status */}
                                            {/* <ColumnDirective field='ATFRejectedNotes' headerText='Latest ATF Rejected Notes' template={parseATFRejecteNotes} width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} /> */}
                                            <ColumnDirective field='ATF_trace_id' headerText='ATF ID' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                                            <ColumnDirective field='W_TYPE_DESC' headerText='Weapon Type Description' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='ATF_Status' headerText='ATF Status' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='ATF_trace_SentDate' headerText='ATF Sent Date' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='ATF_trace_Received' headerText='ATF Received' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='ATF_trace_ReceivedDate' headerText='ATF Received Date' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='ATF_Notes' headerText='ATF Notes' width={140} minWidth={140} maxWidth={140} visible={false} />
                                            <ColumnDirective field='ATF_trace_ReceivedNotes' headerText='ATF Received Notes' width={140} minWidth={140} maxWidth={140} visible={false} />

                                            {/*Vehicle*/}
                                            {/*Person*/}
                                            <ColumnDirective field='PersonID' headerText='PersonID' width={140} minWidth={140} maxWidth={140} visible={false} />   
                                            <ColumnDirective field='INDIV_ROLE' headerText='INDIV_ROLE' width={140} minWidth={140} maxWidth={140} visible={false} />                                                                           
                                            <ColumnDirective field='RoleDesc' headerText='Person Role' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='FName' headerText='First Name' width={140} minWidth={140} maxWidth={140} visible={false} />      
                                            <ColumnDirective field='MName' headerText='MI' width={140} minWidth={140} maxWidth={140} visible={false} />                                                                        
                                            <ColumnDirective field='LName' headerText='Last Name' width={140} minWidth={140} maxWidth={140} visible={false} /> 
                                            <ColumnDirective field='DOB' headerText='DOB' format={'MM/dd/yyyy'} width={140} minWidth={140} maxWidth={140} visible={false} />                                                                             
                                            <ColumnDirective field='RACE' headerText='Race' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='SEX' headerText='Sex' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='STREET#' headerText='Street Number' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='STREETNAME' headerText='Street Name' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='City' headerText='City' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='State' headerText='State' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='ZIP' headerText='ZIP' width={140} minWidth={140} maxWidth={140} visible={false} />    
                                            <ColumnDirective field='CRIMEHISTORY' headerText='Crime History' width={140} minWidth={140} maxWidth={140} visible={false} />                                                                          
                                            <ColumnDirective field='SBINo' headerText='SBINo' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='FBI' headerText='FBI' width={140} minWidth={140} maxWidth={140} visible={false} />        
                                            <ColumnDirective field='INDIV_SEQ' headerText='INDIV_SEQ' width={140} minWidth={140} maxWidth={140} visible={false} />                                                                         
                                            <ColumnDirective field='INDIV_ROLE' headerText='INDIV_ROLE' width={140} minWidth={140} maxWidth={140} visible={false} />     
                                            <ColumnDirective field='INDIV_NAME_SUFFIX' headerText='INDIV_NAME_SUFFIX' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='INDIV_STREET' headerText='INDIV_STREET' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='INDIV_COUNTY' headerText='INDIV_COUNTY' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='INDIV_COUNTRY' headerText='INDIV_COUNTRY' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='INDIV_POB' headerText='INDIV_POB' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='INDIV_RACE' headerText='INDIV_RACE' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='INDIV_SEX' headerText='INDIV_SEX' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='INDIV_HEIGHT' headerText='INDIV_HEIGHT' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='INDIV_WEIGHT' headerText='INDIV_WEIGHT' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='INDIV_STREET_NUMBER' headerText='INDIV_STREET_NUMBER' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='INDIV_STREET_NAME' headerText='INDIV_STREET_NAME' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='INDIV_STREET_SUFFIX' headerText='INDIV_STREET_SUFFIX' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='INDIV_CITY' headerText='INDIV_CITY' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='INDIV_STATE' headerText='INDIV_STATE' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='INDIV_ZIP' headerText='INDIV_ZIP' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='INDIV_POB_STREET' headerText='INDIV_POB_STREET' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='INDIV_POB_ZIP' headerText='INDIV_POB_ZIP' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='INDIV_STREET_DIRECTION' headerText='INDIV_STREET_DIRECTION' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='INDIV_POB_COUNTY' headerText='INDIV_POB_COUNTY' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='INDIV_APT_NUMBER' headerText='INDIV_APT_NUMBER' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='INDIV_ROUTE_NUMBER' headerText='INDIV_ROUTE_NUMBER' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='GANG_NAME' headerText='GANG_NAME' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='INDIV_CRIME_HISTORY' headerText='INDIV_CRIME_HISTORY' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='INDIV_TRIGGER_LOCK' headerText='INDIV_TRIGGER_LOCK' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='SBIState' headerText='SBIState' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='DailyCrimeGun' headerText='DailyCrimeGun' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='Arrests' headerText='Arrests' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='Felonys' headerText='Felonys' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='PriorGun' headerText='PriorGun' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='CRUMB' headerText='CRUMB' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='POPHITS' headerText='POPHITS' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='NOTES' headerText='NOTES' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='INDIV_suffix' headerText='INDIV_suffix' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='INDIV_Address_Latitude' headerText='INDIV_Address_Latitude' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='INDIV_Address_Longitude' headerText='INDIV_Address_Longitude' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='INDIV_AKA_LASTNAME' headerText='INDIV_AKA_LASTNAME' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='INDIV_AKA_FIRSTNAME' headerText='INDIV_AKA_FIRSTNAME' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='INDIV_AKA_MIDDLENAME' headerText='INDIV_AKA_MIDDLENAME' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='INDIV_AKA_SUFFIX' headerText='INDIV_AKA_SUFFIX' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='INDIV_AKA_DOB' headerText='INDIV_AKA_DOB' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='INDIV_ID1_STATE' headerText='INDIV_ID1_STATE' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='INDIV_ID1_TYPE' headerText='INDIV_ID1_TYPE' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='INDIV_ID1_NUMBER' headerText='INDIV_ID1_NUMBER' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='INDIV_ID2_STATE' headerText='INDIV_ID2_STATE' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='INDIV_ID2_TYPE' headerText='INDIV_ID2_TYPE' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='INDIV_ID2_NUMBER' headerText='INDIV_ID2_NUMBER' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='JUVENILE_RELATED' headerText='JUVENILE_RELATED' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            <ColumnDirective field='YOUTH_RELATED' headerText='YOUTH_RELATED' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                                            {/* <ColumnDirective field='ATF_trace_Received' headerText='ATF Received' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} /> */}
                                            </ColumnsDirective>
                                            <Inject services={[ColumnMenu, Sort, Page, Filter, DetailRow, Toolbar, ExcelExport,PdfExport,Search, Resize, Reorder]} />
                                        </GridComponent>
                                </> 
                                    :
                                        searchListView === "Weapon" && DashboardInformation && NJTraceWeapon && NJTraceWeapon?.length > 0 ? 
                                        <>
                                        <span style ={{display:"inline-block"}} className='mb-5'>
                                            <h4 className={"font-bold inline mr-5 text-lg"}> {NJTraceWeapon?.length} Records Found
                                            {
                                                DashboardInformation?.map((dash : any ) => (
                                                <>
                                                &nbsp;&nbsp;&nbsp;&nbsp;YTD Weapon: {dash.YTD_Weapon}, YTD Possesor: {dash.YTD_Possesor},  24 Hour Weapon: {dash["24Hour_Weapon"]}, 24 Hour Possesor: {dash["24Hour_Possesor"]}

                                                </>))
                                            }
                                            </h4> 
                                        </span>   
                                        <SearchResultsWeapon NJTraceWeapon={NJTraceWeapon} NJTraceWeaponStatus={NJTraceWeaponStatus} />
                                        </>
                                    :
                                        searchListView === "Person" && DashboardInformation && Persons && Persons.length > 0 ?
                                        <>
                                        <span style ={{display:"inline-block"}} className='mb-5'>
                                            <h4 className={"font-bold inline mr-5 text-lg"}> {Persons?.length} Records Found
                                            {
                                                DashboardInformation?.map((dash : any ) => (
                                                <>
                                                &nbsp;&nbsp;&nbsp;&nbsp;YTD Weapon: {dash.YTD_Weapon}, YTD Possesor: {dash.YTD_Possesor},  24 Hour Weapon: {dash["24Hour_Weapon"]}, 24 Hour Possesor: {dash["24Hour_Possesor"]}

                                                </>))
                                            }
                                            </h4> 
                                        </span>   
                                        {/* <NJTracePerson  NJTracePerson={Persons?.filter((elem, idx)=> Persons?.findIndex(obj => obj?.PersonID === elem?.PersonID) === idx)}/> */}
                                        <NJTracePerson  NJTracePerson={Persons}/>
                                        </>                                    
                                :
                                <div className="font-bold text-center text-lg pt-3">NO Matching Records Found</div>
                            
                            }
                        </div>
                    </TabPanel>
                    <TabPanel value='2'>
                        <NJTraceInputForm handleReportSubmit={handleNJTraceSubmit} isEdit={true} trace={hash} />
                    </TabPanel>
                </TabContext>                
            </Box>
        </div>
    )
};

export default SearchResults

