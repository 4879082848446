import React from 'react';
import { Box, FormControl, Tab, Tooltip, Snackbar, Alert } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Banner from '../../../assets/Images/ecrawl-banner.png';
import { PostAdd, Close, FormatListBulleted } from '@mui/icons-material';
import ECrawlInput from './_ecrawlInputForm';
import { GetDefaultStateForSite, GetStringNameForSite } from '../../../interfaces/config.interface';
import { SiteName } from '../../../services/config.service';
import {
    ColumnDirective,
    ColumnsDirective,
    ColumnMenu,
    GridComponent,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search, 
    ExcelExportProperties,
    Resize,
    Reorder,
    SortSettingsModel,
    CommandColumn,
    setChecked
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import { getUser, isUserPermission } from '../../../services/auth.service';
import { Account } from '../../../interfaces/auth_interface';
import './ecrawl-styles.css';

const ECrawlSearchResults: React.FC = () => {
    const [tabValue, setTabValue] = React.useState('1');
    const [editTabName, setEditTabName] = React.useState(`NEW ${GetStringNameForSite(SiteName, 'eCRAWL')}`);
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false)
    const [alertText, setAlertText] = React.useState<string>("")
    const [alertType, setAlertType] = React.useState<any>("success")
    const [ecrawlList, setEcrawlList] = React.useState<any[]>([])
    const [user, setUser] = React.useState<Account>(getUser())

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        if (newValue == '1') {
            setEditTabName(`NEW ${GetStringNameForSite(SiteName, 'eCRAWL')}`);
        }
        setTabValue(newValue);
    }

    return (
        <div className="m-5 p-5">
            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={() => { setAlertOpen(false) }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertType} sx={{ width: "100%" }}>
                    {alertText}
                </Alert>
            </Snackbar>
            <Box className="" style={{ justifyContent: 'center' }}>
                <img className='main-logo' alt='Electronic Carrier Roadside Accessible Watch List' src={Banner} />
            <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleTabChange} variant="fullWidth">
                        <Tab icon={<FormatListBulleted />} label="List View" value={'2'} />
                        <Tab icon={<PostAdd />} label="Create New Record" value={'1'} />
                    </TabList>
                </Box>
                <TabPanel value='2'>
                    To Be Added
                </TabPanel>
                <TabPanel value='1'>
                    <ECrawlInput handleReportSubmit={undefined} isEdit={false} />
                </TabPanel>
                </TabContext>
            </Box>
        </div>
    );
};

export default ECrawlSearchResults;


