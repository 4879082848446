import React, { useState } from "react";
import {PersonInfo } from "../../interfaces/getDarInfo_interface";
import {RunPOIList} from "../../services/account.service";

import SearchResults from "../../components/Admin/_searchResultsPOIs";
import POIs from "../../components/Admin/POIs";
import {isUserPermission} from "../../services/auth.service";

function classNames(...classes:string[]) {
    return classes.filter(Boolean).join(' ')
}

function ManagePOI() {
    let [darTbl, setDarTbl] = useState<PersonInfo[]>([])
    const [newPOI, setNewPOI] = React.useState<any>({})

    const AddPOI = (newPOI:any) => {
        if(newPOI){
            let temp = {...newPOI}
            setNewPOI(temp)
        }
    }
    
    const SubmitSearch = async (params: any, dateType: string, dropDowns: any, systemCall:boolean = false) => {
        let search_query: any = {            
            "DateRange": params.showDate,
            "DateTypeValue": (dateType === "Start Date") ? 0 : 1,
            "FromDate": params?.fromDate?.toLocaleString(),
            "ToDate": params?.toDate?.toLocaleString(),

            //"DeptEmpIDs": dropDowns.AgencyArrested ? dropDowns.DeptEmpID : [],
            //"DeptCodes": dropDowns.InfoCopDept ? dropDowns.DeptCode : [],
            //"DeptUnits": dropDowns.Indicator ? dropDowns.DeptUnit : [],
            //"DeptORIs": dropDowns.AgencyArrested ? dropDowns.DeptORI : [],
            //"DeptCitys": dropDowns.InfoCopDept ? dropDowns.DeptCity : [],
            //"DeptCountys": dropDowns.Indicator ? dropDowns.DeptCounty : [],
            //"DeptStates": dropDowns.Indicator ? dropDowns.DeptState : [],

            "DLNos": params.DLNo ? params.DLNo.split(',') : [],
            "DLSts": params.DLSt ? params.DLSt.split(',') : [],
            "ReqIDs": params.ReqID ? params.ReqID.split(',') : [],
            "PlateNumbers": params.PlateNumber ? params.PlateNumber.split(',') : [],
            "PlateSts": params.PlateSt ? params.PlateSt.split(',') : [],
            "CaseNumbers": params.CaseNumber ? params.CaseNumber.split(',') : [],
            "Addresss": params.Address ? params.Address.split(',') : [],
            "Citys": params.City ? params.City.split(',') : [],
            "Countys": params.County ? params.County.split(',') : [],
            "States": params.State ? params.State.split(',') : [],
            "Zips": params.Zip ? params.Zip.split(',') : [], 
            "Reasons": params.Reason ?? [],
            "FNames": params.FName ? params.FName.split(',') : [],
            "MNames": params.MName ? params.MName.split(',') : [],
            "LNames": params.LName ? params.LName.split(',') : [],
            "DOBs": params.DOB ? params.DOB.split(',') : [],
            "FBIs": params.FBI ? params.FBI.split(',') : [],
            "SBINos": params.SBINo ? params.SBINo.split(',') : [],
            "SSNs": params.SSN ? params.SSN.split(',') : [],
            "GANGRELATEDs": params.GANGRELATED ? params.GANGRELATED.split(',') : [],
            "SEXs": params.SEX ? params.SEX.split(',') : [],
            "SystemCall": systemCall ?? false,
        }
        let qRes: any = await RunPOIList(search_query)
        setDarTbl([])
        setDarTbl(qRes?.AnyData)
    }

    return (
        <div className="w-full">
            {isUserPermission('POI')?
                <>
                    <POIs SubmitSearch={SubmitSearch} AddPOI={AddPOI}/>
                    <div className = "ml-10 mt-3">
                        <SearchResults POIs = {darTbl} NewPOI={newPOI}/>
                    </div>
                </>
            :<></>}
        </div>
    );
}

export default ManagePOI;