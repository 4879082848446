import React, {useEffect, useState} from "react";
import {getFAQList} from "../../services/getDropdown.service";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import ReactPlayer from 'react-player'
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import parse from "html-react-parser";
import {Account} from "../../interfaces/auth_interface";
import {getUser} from "../../services/auth.service";
import {SiteName} from "../../services/config.service";
import {SITE} from "../../interfaces/config.interface";

interface FAQList {
    QUESTION:string,
    ANSWER:string,
}

const FAQSearch: React.FC = () => {
    let [FAQs, setFAQs] = React.useState<FAQList[]>([])
    const [initialRun, setInitialRun] = useState<boolean>(false)
    
    const [expanded, setExpanded] = React.useState<string | false>(false);
    
    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };


    useEffect(() => {
        if (!initialRun) {
            setInitialRun(true)
            getFAQList().then((res) => { setFAQs(res) })
        }
    });

    const AccordionSummary = styled((props: AccordionSummaryProps) => (
        <MuiAccordionSummary
            {...props}
        />
    ))(({ theme }) => ({
        backgroundColor:
            theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, .05)'
                : 'rgba(0, 0, 0, .03)',
    }));

    return (
        <div className="mb-2">
            <div className="text-center text-5xl mt-4 mb-8"> Frequently Asked Questions (FAQ)</div>
            {FAQs.map((item, indx) =>
                <div className="mx-auto w-4/6 border-2 border-gray-400 my-2 rounded-lg">
                    <Accordion expanded={expanded === 'panel' + indx} onChange={handleChange('panel' + indx)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{fontSize: '2.2rem'}}/>}
                        >
                            <div className="w-full border-l-4 border-blue-700 grid grid-cols-12"
                                 style={{borderLeftWidth: "6px"}}>
                                <span
                                    className="text-4xl font-normal text-blue-700 text-center col-span-1 self-center">Q.</span>
                                <span
                                    className="text-2xl font-semibold col-span-11 self-center"> {item?.QUESTION} </span>
                            </div>
                            {/*<Typography sx={{ color: 'text.secondary' }}>I am an accordion</Typography>*/}
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <div className="border-l-4 border-gray-600 grid grid-cols-12"
                                     style={{borderLeftWidth: "6px"}}>
                                    <span
                                        className="text-4xl font-normal text-gray-600 col-span-1 text-center self-center">A.</span>
                                    <span
                                        className="text-base text-gray-700 col-span-11 self-center"> {parse(item?.ANSWER)} </span>
                                </div>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
            )}
            
        </div>
    );

}

export default FAQSearch;