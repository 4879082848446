import React, { useState } from "react";

import { Account } from "../../interfaces/auth_interface";
import { getUser, isUserPermission } from "../../services/auth.service";
import TrafficCrashSearch from "../../components/tools/traffic_crash/_trafficCrashSearch";

export default function TrafficCrash() {
    let [trafficCrash, setTrafficCrash] = useState<any[]>([])
    let [user, setUser] = useState<Account>(getUser())
    let [noData, setNoData] = useState<boolean>(false)

   return (
        <div id="ToolsTrafficCrash">
            {isUserPermission('Arrest') ?
                <>
                    <TrafficCrashSearch SubmitSearch={()=>{}} />
                    {/*{!noData ? <SearchResults CADRMSInfo={cadrms} Persons={persons} /> : <div className="font-bold text-lg ml-5">NO Matching Records Found</div>}*/}
                </>
                : <></>}
        </div>
    );
}
