import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { PIRReport, PIRReportCameraList } from '../../../interfaces/PIR.interface';
import { Button, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, Paper, IconButton, Tooltip, Alert, TablePagination } from '@mui/material';
import QueueIcon from '@mui/icons-material/Queue';
import CheckIcon from '@mui/icons-material/Check';
import Geocode from "react-geocode";
import {getCCTVCamerasInRange} from "../../../services/pir.service";

interface CameraSearchProps {
    handleCameraSubmit: (cameras: PIRReportCameraList[]) => void,
    handleCameraClose: any
}

const PreliminaryCameraSearch: React.FC<CameraSearchProps> = ({ handleCameraSubmit, handleCameraClose}) => {
    const [addedCameraList, setAddedCameraList] = useState<PIRReportCameraList[]>([]);
    const [res, setRes] = useState<any>([]);
    const [importedCameras, setImportedCameras] = useState<string[]>([]);
    const [alertOpen, setAlertOpen] = useState(false);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [params, setParams] = useState({
        startDate: null,
        endDate: null,
        address: '',
        radius: ''
    });

    const handleInputChange = (e: { target: { name: string; value: any; }; }) => {
        const { name, value } = e.target;

        let temp: any = { ...params }
        temp[name] = (value instanceof Date ? value.toLocaleString() : value)

        setParams(temp)
    };
    
    const handleSearchCamera = async () => {
        if(params?.address?.length > 0 && params?.radius.length > 0) {
            let response = await Geocode.fromAddress(params?.address + ', Washington, DC')
            const {lat, lng} = response.results[0].geometry.location
            console.log(lat,lng)
            
            if(lat && lng){
                let res = await getCCTVCamerasInRange(lat, lng, params.radius)
                console.log(res)
                if (res.length > 0) {
                    setRes(res);
                }
                else {
                    setRes(res);
                }
            }
        }
    }

    const addCameraToObject = (camera: any) => {
        const { Name, distance } = camera;
        if (new Set(importedCameras).has(Name)) {
            setAlertOpen(true);
        }
        else {
            const newCamera = { CCTV_Location: Name, CCTV_Distance: distance, Camera_Type: "CCTV/CAMERA", CCTV_Department: camera?.AgencyName ?? "MPDC" };
            let ncList = [...addedCameraList, newCamera]
            setAddedCameraList([...addedCameraList, newCamera]);
            setImportedCameras([...importedCameras, Name]);
            console.log(ncList);
        }
    }

    const deleteCameraFromObject = (CCTV_Location: string) => {
        const newCameraList = addedCameraList.filter((camera: any) => camera.CCTV_Location !== CCTV_Location);
        const newImportedCameras = importedCameras.filter(id => id !== CCTV_Location);
        setAddedCameraList(newCameraList);
        setImportedCameras(newImportedCameras);
        console.log(newCameraList);
    }

    const deleteAllCameras = () => {
        if (window.confirm("Are you sure you would like to undo all of your current camera imports?")) { 
            let newCameraList: any = []
            setAddedCameraList(newCameraList);
            setImportedCameras([]);
            let temp = addedCameraList;
            console.log(newCameraList);
        }
    }

    const importAllCameras = () => {
        let newCameras: any = res.map((camera: any, index: number) => ({
            Camera_Type: "CCTV/CAMERA",
            CCTV_Department: camera?.AgencyName ?? "MPDC",
            CCTV_Location: camera.Name,
            CCTV_Distance: camera.distance
        }));
        
        setAddedCameraList(newCameras);
        
        const allIds = res.map((camera: any) => camera.Name);
        setImportedCameras(allIds);
        
        console.log(newCameras);
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDoneClick = () => {
        if (importedCameras?.length > 0) {
            handleCameraSubmit(addedCameraList);
        }
        handleCameraClose();
    };


    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className="p-4">
                <form className="border-double border-2 border-inherit p-4">
                    <div className="mt-4">
                        {/*Camera Search Header*/}
                        <div className="flex justify-center items-center w-full mb-4 bg-pir-blue border-solid border-2 border-black">
                            <Typography variant="h5" className="text-white">
                                CAMERA SEARCH
                            </Typography>
                        </div>

                        <div className="flex flex-wrap -mx-2 justify-center">
                            {/*<div className="w-full md:w-1/4 px-2 mb-4">*/}
                            {/*    <DateTimePicker*/}
                            {/*        label="Start Date"*/}
                            {/*        ampm={false}*/}
                            {/*        value={params?.startDate ?? ""}*/}
                            {/*        onChange={(date) => handleInputChange({*/}
                            {/*            target: {*/}
                            {/*                name: "startDate",*/}
                            {/*                value: (date ?? "")*/}
                            {/*            }*/}
                            {/*        })}*/}
                            {/*        renderInput={(params) => <TextField {...params} fullWidth />}*/}
                            {/*    />*/}
                            {/*</div>*/}
                            {/*<div className="w-full md:w-1/4 px-2 mb-4">*/}
                            {/*    <DateTimePicker*/}
                            {/*        label="End Date"*/}
                            {/*        ampm={false}*/}
                            {/*        value={params?.endDate ?? ""}*/}
                            {/*        onChange={(date) => handleInputChange({*/}
                            {/*            target: {*/}
                            {/*                name: "endDate",*/}
                            {/*                value: (date ?? "")*/}
                            {/*            }*/}
                            {/*        })}*/}
                            {/*        renderInput={(params) => <TextField {...params} fullWidth />}*/}
                            {/*    />*/}
                            {/*</div>*/}
                            <div className="w-full md:w-1/4 px-2 mb-4">
                                <TextField
                                    label="Address"
                                    name="address"
                                    value={params?.address ?? ""}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </div>
                            <div className="w-full md:w-1/4 px-2 mb-4">
                                <TextField
                                    label="Radius (feet)"
                                    name="radius"
                                    value={params?.radius ?? ""}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </div>
                        </div>
                        <div className="flex justify-end">
                            <Button
                                variant="contained"
                                color="inherit"
                                onClick={() => handleSearchCamera() }
                            >
                                Search
                            </Button>
                        </div>
                        {alertOpen && (
                            <div>
                                <Alert className="mt-2" variant="filled" severity="error">You cannot import the same camera twice.</Alert>
                            </div>
                        )}
                        {res && res?.length > 0 && (
                            <div className="flex justify-between items-center mt-2">
                                <div style={{ display: 'flex', gap: '10px' }}>
                                    <Button
                                        variant="contained"
                                        onClick={() => importAllCameras() }
                                    >
                                        Import All
                                    </Button>
                                    {addedCameraList && addedCameraList.length > 0 && (
                                        <Button
                                            variant="contained"
                                            color="error"
                                            onClick={() => deleteAllCameras()}
                                        >
                                            Undo All
                                        </Button>
                                    ) }            
                                </div>
                                <Typography variant="h6" className="font-bold">When finished importing, click "Done" at the bottom.</Typography>
                                <Typography variant="h6" className="font-bold">
                                    Total Cameras: {res.length}
                                </Typography>
                            </div>
                        )}

                        {res && res?.length > 0 && (
                            <TableContainer className="mt-2" component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Actions</TableCell>
                                            <TableCell>Camera Name</TableCell>
                                            <TableCell>Distance (feet)</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {res.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((camera: any, index: any) => (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    {!importedCameras.includes(camera.Name) && (
                                                        <Tooltip title="Import Camera" placement="top" arrow>
                                                            <IconButton onClick={() => addCameraToObject(camera)}>
                                                                <QueueIcon fontSize='small' color='success' />
                                                            </IconButton>
                                                        </Tooltip>
                                                    ) }
                                                    {importedCameras.includes(camera.Name) && <CheckIcon color="success" />}
                                                    {importedCameras.includes(camera.Name) && (
                                                        <Tooltip title="Undo Import" placement="top" arrow>
                                                            <IconButton
                                                                color="error"
                                                                onClick={() => deleteCameraFromObject(camera?.Name) }
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    ) }
                                                </TableCell>
                                                <TableCell>{camera?.Name}</TableCell>
                                                <TableCell>{camera?.distance + " feet"}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    component="div"
                                    count={res.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableContainer>
                        )}
                        {res.length === 0 && (
                            <div>
                                <Typography variant="h6">No cameras found. Change the address or radius parameter and try again.</Typography>
                            </div>
                        ) }
                        <div className="float-right justify-end mt-8 mb-2">
                            <Button
                                variant="contained"
                                color="inherit"
                                onClick={handleDoneClick}
                            >
                                {(addedCameraList?.length > 0 ? "Done" : "Close")}
                            </Button>
                        </div>
                    </div>
                </form>                   
            </div>
        </LocalizationProvider>
    );
}

export default PreliminaryCameraSearch;
