import React, { useState } from "react";
import ScorVariationSearch from "../../components/tools/scorVariation/_scorVariationSearch";
import SearchResults from "../../components/tools/scorVariation/_searchResultsScorVariation";
import { GetDarInfo_Query, PersonInfo, getDarInfoResponse, getGraphResponse } from "../../interfaces/getDarInfo_interface";
import { RunDarQuery, RunGraphQuery } from "../../services/getDar.service";
import { Account } from "../../interfaces/auth_interface";
import {getUser, isUserPermission} from "../../services/auth.service";
import {handleSearchTime} from "../../services/formatDate.service";


function ScorVariation() {
    let [darTbl, setDarTbl] = useState<PersonInfo[]>([])
    let [variationGraph, setvariationGraph] = useState<any[]>([])
    let [variationColumn, setvariationColumn] = useState<any[]>([])
    let [user, setUser] = useState<Account>(getUser())
    let [hideJail, setHideJail] = useState<boolean>(false)
    let [noData, setNoData] = useState<boolean>(false)

    const SubmitSearch = async (params: any, dateType: string, andOrType: string, dropDowns: any, top: number) => {
        let search_query: GetDarInfo_Query = {
            "QueryType": "U",
            "Dept": user.Dept,
            "EmpID": user.EmpID,
            "DeptORI": user.DeptORI,
            "DateRange": true,
            "DateTypeValue": (dateType === "SCOR Date") ? 0 : ((dateType === "Arrest Date") ? 1 : 2),
            "FromDate": handleSearchTime(params?.fromDate, 'fromDate')?.toLocaleString(),
            "ToDate": handleSearchTime(params?.toDate, 'toDate')?.toLocaleString(),
            "SearchType": andOrType,
            "IndicatorOn": true,
            "TopArrestNumber": params.Variation,
            "FNames": params.FirstName ? params.FirstName.split(',') : [],
            "MNames": params.MI ? params.MI.split(',') : [],
            "LNames": params.LastName ? params.LastName.split(',') : [],
            "DOBs": params.DOB ? params.DOB.split(',') : [],
            "SEX": dropDowns.Sex ? dropDowns.Sex : [],
            "Address": "",
            "Cities": dropDowns.City ? dropDowns.City : [],
            "States": dropDowns.State ? dropDowns.State : [],
            "Zip": "",
            "Statutes": dropDowns.Statute ? dropDowns.Statute : [],
            "Gang": params.CrumbPercentile ? [params.CrumbPercentile] : [],
            "FBIs": params.FBI ? params.FBI.split(',') : [],
            "SBIs": params.SBI ? params.SBI.split(',') : [],
            "DLNos": params.header ? params.header.split(',') : [],  
            "PlateNos": params.detail ? params.detail.split(',') : [],
            "VINs": params.group ? params.group.split(',') : [], 
            "AgencyArrested": dropDowns.AgencyArrested ? dropDowns.AgencyArrested : [],
            "InfocopDept": dropDowns.InfoCopDept ? dropDowns.InfoCopDept : [],
            "Indicators": dropDowns.Indicator ? dropDowns.Indicator : [],
            "IndicatorOccurrence": dropDowns.IndicatorOccurrence ? dropDowns.IndicatorOccurrence : null,
            "Latitude": null,
            "Longitude": null,
            "Radius": 0,
            "ResidentCounty": dropDowns.ResidentCounty ? dropDowns.ResidentCounty : [],
            "ArrestedCounty": dropDowns.ArrestedCounty ? dropDowns.ArrestedCounty : [],
            "Arrest_Type": [],
            "CaseNumbers": [],
            "FTSIDs": [],
            "AgeRangeStart": params.AgeRangeStart,
            "AgeRangeEnd": params.AgeRangeEnd,
            "ShootingPersonTypes": dropDowns.ShootingPersonType ? dropDowns.ShootingPersonType : [],
            "SystemCall": params.systemCall ?? false,
        }        
        setDarTbl([])
        setvariationColumn([])     
        setvariationGraph([])
        let qRes: getDarInfoResponse = await RunDarQuery(search_query)
        setDarTbl(qRes.AnyTable)
        //setvariationColumn(qRes?.SecondaryTable)
        setvariationGraph(JSON.parse(qRes.JsonObject))
        if (qRes.AnyTable?.length === 0) {
            setNoData(true)
        }
        else {
            setNoData(false)
        }
    }

    const HandleHideJail = (value: any) => {
        setHideJail(value)
    }

    return (
        <div>
            {isUserPermission('Arrest')?
                <>
                    <ScorVariationSearch SubmitSearch={SubmitSearch} HandleHideJail={HandleHideJail} />
                    {!noData ? <SearchResults Persons={darTbl} hideJail={hideJail} VariationColumn={variationColumn} VariationGraph={variationGraph} /> : <div className="font-bold text-lg ml-5">NO Matching Records Found</div>}
                </>
            :<></>}
        </div>
    );
}

export default ScorVariation;