import React, { useState, useEffect } from "react";
import { TextField, Checkbox, Button, ToggleButtonGroup, ToggleButton, Card, CardContent, TableContainer, Table, TableBody, TableRow, TableCell, Paper } from "@mui/material";
import PrisonReleaseDetail, { PrisonReleaseDetailMainObject, PrisonReleaseDetailActivitysObject, PrisonReleaseDetailAssociationsObject, PrisonReleaseDetailEntitysObject, PrisonReleaseDetailIncarcerationsObject, PrisonReleaseDetailJudgementsObject, PrisonReleaseDetailOffensesObject, PrisonReleaseDetailPersonsObject } from "../../interfaces/Detail/PrisonReleaseDetail.interface"


import { RunPrisonReleaseDetailQuery } from "../../services/detail.service";
import { formatDate, formatTime } from "../../services/formatDate.service"

import './Detail.css'

import { Disclosure } from '@headlessui/react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface DetailProps {
    ID: string | null
    department: string | null
}

const PrisonReleaseDetailInfo: React.FC<DetailProps> = ({ ID, department }) => {
    const [DataLoaded, setDataLoaded] = React.useState<boolean>(false)
    const [Data, setData] = React.useState<PrisonReleaseDetail>({})

    function checkIsDate(date: any) {
        let flag = (Object.prototype.toString.call(date) === "[object Date]");
        if (flag) {
            flag = !Number.isNaN(date);
        }
        return flag;
    };

    const loadData = async () => {        
        if (!DataLoaded) {
            let res = await RunPrisonReleaseDetailQuery(ID, department);
            let judgeLen = Number(res?.Judgements?.length);
            if (!isNaN(judgeLen) && (judgeLen > 1)) {
                res?.Judgements?.sort((a, b: PrisonReleaseDetailJudgementsObject) => {
                    let str1: string = String(a?.SentenceDate);
                    let date1: Date = new Date(str1);
                    let isDateValid1 = checkIsDate(date1);

                    let str2: string = String(b?.SentenceDate);
                    let date2: Date = new Date(str2);
                    let isDateValid2 = checkIsDate(date2);

                    if (!isDateValid1 && !isDateValid2) {
                        return 0;
                    }
                    if (!isDateValid1) {
                        return 1;
                    }
                    if (!isDateValid2) {
                        return -1;
                    }

                    let retval = Number(date2) - Number(date1);
                    return retval;
                })
            }
            setData(res);
            setDataLoaded(true);
        }
    }

    React.useEffect(() => {
        if (!DataLoaded) {
            setDataLoaded(true);
            loadData();
        }
    });

    return (
        <div className="bg-gray-100 ml-5 mr-5 rounded p-5;">          
            <Card sx = {{maxWidth: 900, m:'auto', mb:2}} >
                <CardContent>
                    <h3 className = "mb-4 font-bold font text-xl text-blue-400">Main</h3>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">ID</span><span className ="DetailTableData">{Data?.Main?.ID}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Entry Date</span><span className="DetailTableData">{formatDate(Data.Main?.EntryDate)+ ' ' + formatTime(Data.Main?.EntryDate) }</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">Reference ID</span><span className ="DetailTableData">{Data?.Main?.ReferenceID}</span></div>
                    {/*<div className="DetailTableRow" ><span className="DetailTableLabel">Record Type</span><span className="DetailTableData">{Data?.Main?.RecordType}</span></div>*/}
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">Dept ORI</span><span className ="DetailTableData">{Data?.Main?.DeptORI}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">Dept Name</span><span className ="DetailTableData">{Data?.Main?.DeptName}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">Data Owner ORI</span><span className ="DetailTableData">{Data?.Main?.DataOwnerORI}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Data Owner Dept Name</span><span className="DetailTableData">{Data?.Main?.DataOwnerDeptName}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Data Owner Name</span><span className="DetailTableData">{Data?.Main?.DataOwnerName}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Data Owner Phone</span><span className="DetailTableData">{Data?.Main?.DataOwnerPhone}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Data Owner Organization</span><span className="DetailTableData">{Data?.Main?.DataOwnerOrganization}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Create Date</span><span className="DetailTableData">{formatDate(Data?.Main?.CreateDate) + ' ' + formatTime(Data.Main?.CreateDate)}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Deleted</span><span className="DetailTableData">{Data?.Main?.Deleted}</span></div>
                </CardContent>
            </Card>

            <Disclosure defaultOpen={false}>
                {({ open }) => (
                    <>
                        <Disclosure.Button className="bg-blue-100 mb-2 p-2 w-full w-auto text-left ">
                            <span style={{ "display": "flex", "justifyContent": "space-between" }}>
                                <span>
                                    <KeyboardArrowDownIcon
                                        className={`${open ? "transform rotate-180" : ""}`}
                                    />
                                    <span className={"font-bold"}>Persons</span>
                                </span>
                                <span className={"font-bold mr-2"} >Records: {Data?.Persons?.length ? Data?.Persons?.length : 0}</span>
                            </span>
                        </Disclosure.Button>

                        <Disclosure.Panel className="text-gray-500 p-4">
                            {Data.Persons && Data.Persons.length > 0 ?
                                Data.Persons?.map((Persons: PrisonReleaseDetailPersonsObject) => {
                                    return (
                                        <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                                            <CardContent>
                                                <div className="DetailTableRow" ><span className="DetailTableLabel">ID</span><span className="DetailTableData">{Persons.ID}</span></div>
                                                <div className="DetailTableRow" ><span className="DetailTableLabel">Reference ID</span><span className="DetailTableData">{Persons.ReferenceID}</span></div>
                                                <div className="DetailTableRow" ><span className="DetailTableLabel">Category Name</span><span className="DetailTableData">{Persons.CategoryName}</span></div>
                                                <div className="DetailTableRow" ><span className="DetailTableLabel">First Name</span><span className="DetailTableData">{Persons.FIRST_NAME}</span></div>
                                                <div className="DetailTableRow" ><span className="DetailTableLabel">Middle Name</span><span className="DetailTableData">{Persons.MIDDLE_NAME}</span></div>
                                                <div className="DetailTableRow" ><span className="DetailTableLabel">Last Name</span><span className="DetailTableData">{Persons.LAST_NAME}</span></div>
                                                <div className="DetailTableRow" ><span className="DetailTableLabel">DOB</span><span className="DetailTableData">{formatDate(Persons.DOB)}</span></div>
                                                <div className="DetailTableRow" ><span className="DetailTableLabel">FBI</span><span className="DetailTableData">{Persons.FBI}</span></div>
                                                <div className="DetailTableRow" ><span className="DetailTableLabel">SBI No</span><span className="DetailTableData">{Persons.SBINo}</span></div>
                                                <div className="DetailTableRow" ><span className="DetailTableLabel">SBI State</span><span className="DetailTableData">{Persons.SBIState}</span></div>
                                                <div className="DetailTableRow" ><span className="DetailTableLabel">Sex</span><span className="DetailTableData">{Persons.Sex}</span></div>
                                                <div className="DetailTableRow" ><span className="DetailTableLabel">SSN</span><span className="DetailTableData">{Persons.SSN}</span></div>
                                                <div className="DetailTableRow" ><span className="DetailTableLabel">Height</span><span className="DetailTableData">{Persons.Height}</span></div>
                                                <div className="DetailTableRow" ><span className="DetailTableLabel">Weight</span><span className="DetailTableData">{Persons.Weight}</span></div>
                                                <div className="DetailTableRow" ><span className="DetailTableLabel">Eye Color</span><span className="DetailTableData">{Persons.EyeColor}</span></div>
                                                <div className="DetailTableRow" ><span className="DetailTableLabel">Hair Color</span><span className="DetailTableData">{Persons.HairColor}</span></div>
                                                <div className="DetailTableRow" ><span className="DetailTableLabel">Physical Feature</span><span className="DetailTableData">{Persons.PhysicalFeature}</span></div>
                                                <div className="DetailTableRow" ><span className="DetailTableLabel">Race</span><span className="DetailTableData">{Persons.Race}</span></div>
                                                <div className="DetailTableRow" ><span className="DetailTableLabel">Ethnicity</span><span className="DetailTableData">{Persons.Ethnicity}</span></div>
                                                <div className="DetailTableRow" ><span className="DetailTableLabel">INM</span><span className="DetailTableData">{Persons.INM}</span></div>
                                                <div className="DetailTableRow" ><span className="DetailTableLabel">Deleted</span><span className="DetailTableData">{Persons.Deleted}</span></div>
                                            </CardContent>
                                        </Card>
                                    )
                                })
                                :
                                <></>
                            }
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>

            <Disclosure defaultOpen={false}>
                {({ open }) => (
                    <>
                        <Disclosure.Button className="bg-blue-100 p-2 mb-2 w-full w-auto text-left ">
                            <span style={{ "display": "flex", "justifyContent": "space-between" }}>
                                <span>
                                    <KeyboardArrowDownIcon
                                        className={`${open ? "transform rotate-180" : ""}`}
                                    />
                                    <span className={"font-bold"}>Offenses</span>
                                </span>
                                <span className={"font-bold mr-2"} >Records: {Data?.Offenses?.length? Data?.Offenses?.length : 0}</span>
                            </span>
                        </Disclosure.Button>

                        <Disclosure.Panel className="text-gray-500 p-4">
                            {Data.Offenses && Data.Offenses.length > 0 ?
                                Data.Offenses?.map((Offenses: PrisonReleaseDetailOffensesObject) => {
                                    return (
                                        <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                                            <CardContent>
                                                <div className="DetailTableRow" ><span className="DetailTableLabel">ID</span><span className="DetailTableData">{Offenses.ID}</span></div>
                                                <div className="DetailTableRow" ><span className="DetailTableLabel">Reference ID</span><span className="DetailTableData">{Offenses.ReferenceID}</span></div>
                                                <div className="DetailTableRow" ><span className="DetailTableLabel">Statue</span><span className="DetailTableData">{Offenses.Statue}</span></div>
                                                <div className="DetailTableRow" ><span className="DetailTableLabel">Statute Description</span><span className="DetailTableData">{Offenses.Statute_Desc}</span></div>
                                                <div className="DetailTableRow" ><span className="DetailTableLabel">Sentence Imposed</span><span className="DetailTableData">{Offenses.SentenceImposed}</span></div>
                                                <div className="DetailTableRow" ><span className="DetailTableLabel">Disposition Code</span><span className="DetailTableData">{Offenses.DispositionCode}</span></div>
                                                <div className="DetailTableRow" ><span className="DetailTableLabel">Deleted</span><span className="DetailTableData">{Offenses.Deleted}</span></div>
                                            </CardContent>
                                        </Card>
                                    )
                                })
                                :
                                <></>
                            }
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>

            <Disclosure defaultOpen={false}>
                {({ open }) => (
                    <>
                        <Disclosure.Button className="bg-blue-100 p-2 mb-2 w-full w-auto text-left ">
                            <span style={{ "display": "flex", "justifyContent": "space-between" }}>
                                <span>
                                    <KeyboardArrowDownIcon
                                        className={`${open ? "transform rotate-180" : ""}`}
                                    />
                                    <span className={"font-bold"}>Judgements</span>
                                </span>
                                <span className={"font-bold mr-2"} >Records: {Data?.Judgements?.length ? Data?.Judgements?.length : 0}</span>
                            </span>
                        </Disclosure.Button>

                        <Disclosure.Panel className="text-gray-500 p-4">
                            {Data.Judgements && Data.Judgements.length > 0 ?
                                Data.Judgements?.map((Judgements: PrisonReleaseDetailJudgementsObject) => {
                                    return (
                                        <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                                            <CardContent>
                                                <div className="DetailTableRow" ><span className="DetailTableLabel">ID</span><span className="DetailTableData">{Judgements.ID}</span></div>
                                                <div className="DetailTableRow" ><span className="DetailTableLabel">Reference ID</span><span className="DetailTableData">{Judgements.ReferenceID}</span></div>
                                                <div className="DetailTableRow" ><span className="DetailTableLabel">Name</span><span className="DetailTableData">{Judgements.Name}</span></div>
                                                <div className="DetailTableRow" ><span className="DetailTableLabel">Case Docket ID</span><span className="DetailTableData">{Judgements.CaseDocketID}</span></div>
                                                <div className="DetailTableRow" ><span className="DetailTableLabel">Commit Date</span><span className="DetailTableData">{formatDate(Judgements.CommitDate)}</span></div>
                                                <div className="DetailTableRow" ><span className="DetailTableLabel">Sentence Date</span><span className="DetailTableData">{formatDate(Judgements.SentenceDate)}</span></div>
                                                <div className="DetailTableRow" ><span className="DetailTableLabel">Deleted</span><span className="DetailTableData">{Judgements.Deleted}</span></div>
                                            </CardContent>
                                        </Card>
                                    )
                                })
                                :
                                <></>
                            }
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>

            <Disclosure defaultOpen={false}>
                {({ open }) => (
                    <>
                        <Disclosure.Button className="bg-blue-100 p-2 mb-2 w-full w-auto text-left ">
                            <span style={{ "display": "flex", "justifyContent": "space-between" }}>
                                <span>
                                    <KeyboardArrowDownIcon
                                        className={`${open ? "transform rotate-180" : ""}`}
                                    />
                                    <span className={"font-bold"}>Incarcerations</span>
                                </span>
                                <span className={"font-bold mr-2"} >Records: {Data?.Incarcerations?.length ? Data?.Incarcerations?.length : 0}</span>
                            </span>
                        </Disclosure.Button>

                        <Disclosure.Panel className="text-gray-500 p-4">
                            {Data.Incarcerations && Data.Incarcerations.length > 0 ?
                                Data.Incarcerations?.map((Incarcerations: PrisonReleaseDetailIncarcerationsObject) => {
                                    return (
                                        <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                                            <CardContent>
                                                <div className="DetailTableRow" ><span className="DetailTableLabel">ID</span><span className="DetailTableData">{Incarcerations.ID}</span></div>
                                                <div className="DetailTableRow" ><span className="DetailTableLabel">Name</span><span className="DetailTableData">{Incarcerations.Name}</span></div>
                                                <div className="DetailTableRow" ><span className="DetailTableLabel">Parole Eligibility Date</span><span className="DetailTableData">{formatDate(Incarcerations.ParoleEligibilityDate)}</span></div>
                                                <div className="DetailTableRow" ><span className="DetailTableLabel">Projected Release Date</span><span className="DetailTableData">{formatDate(Incarcerations.ProjectedReleaseDate)}</span></div>
                                                <div className="DetailTableRow" ><span className="DetailTableLabel">Prison Status</span><span className="DetailTableData">{Incarcerations.PrisonStatus}</span></div>
                                                <div className="DetailTableRow" ><span className="DetailTableLabel">Location Building</span><span className="DetailTableData">{Incarcerations.LocationBuilding}</span></div>
                                                <div className="DetailTableRow" ><span className="DetailTableLabel">Location Cell</span><span className="DetailTableData">{Incarcerations.LocationCell}</span></div>
                                                <div className="DetailTableRow" ><span className="DetailTableLabel">Location Section</span><span className="DetailTableData">{Incarcerations.LocationSection}</span></div>
                                                <div className="DetailTableRow" ><span className="DetailTableLabel">Deleted</span><span className="DetailTableData">{Incarcerations.Deleted}</span></div>
                                            </CardContent>
                                        </Card>
                                    )
                                })
                                :
                                <></>
                            }
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>

                            {/*
            {Data.Activitys && Data.Activitys.length > 0 ?
                Data.Activitys?.map((Activity: PrisonReleaseDetailActivitysObject) => {
                    return (
                        <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                            <CardContent>
                                <h3 className="mb-4 font-bold font text-xl text-blue-400">Activities</h3>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">ID</span><span className="DetailTableData">{Activity.ID}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">CategoryID</span><span className="DetailTableData">{Activity.CategoryID}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">CategoryName</span><span className="DetailTableData">{Activity.CategoryName}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">CategoryType</span><span className="DetailTableData">{Activity.CategoryType}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">CategoryDate</span><span className="DetailTableData">{formatDate(Activity.CategoryDate)}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Reference ID</span><span className="DetailTableData">{Activity.ReferenceID}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Deleted</span><span className="DetailTableData">{Activity.Deleted}</span></div>
                            </CardContent>
                        </Card>
                    )
                })
                :
                null
            }

            {Data.Associations && Data.Associations.length > 0 ?
                Data.Associations?.map((Associations: PrisonReleaseDetailAssociationsObject) => {
                    return (
                        <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                            <CardContent>
                                <h3 className="mb-4 font-bold font text-xl text-blue-400">Associations</h3>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">ID</span><span className="DetailTableData">{Associations.ID}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Reference ID</span><span className="DetailTableData">{Associations.ReferenceID}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Type</span><span className="DetailTableData">{Associations.Type}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Primary</span><span className="DetailTableData">{Associations.Primary}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Link</span><span className="DetailTableData">{Associations.Link}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Deleted</span><span className="DetailTableData">{Associations.Deleted}</span></div>
                            </CardContent>
                        </Card>
                    )
                })
                :
                null
            }

            {Data.Entitys && Data.Entitys.length > 0 ?
                Data.Entitys?.map((Entitys: PrisonReleaseDetailEntitysObject) => {
                    return (
                        <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                            <CardContent>
                                <h3 className="mb-4 font-bold font text-xl text-blue-400">Entities</h3>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">ID</span><span className="DetailTableData">{Entitys.ID}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Reference ID</span><span className="DetailTableData">{Entitys.ReferenceID}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Location Name</span><span className="DetailTableData">{Entitys.LocationName}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Location City</span><span className="DetailTableData">{Entitys.LocationCity}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Location State</span><span className="DetailTableData">{Entitys.LocationState}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Location Zip</span><span className="DetailTableData">{Entitys.LocationZip}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Deleted</span><span className="DetailTableData">{Entitys.Deleted}</span></div>
                            </CardContent>
                        </Card>
                    )
                })
                :
                null
            }
            */}
        </div>
    );
}

export default PrisonReleaseDetailInfo;