import React, { useEffect, useRef, useState } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Sort,
  Page,
  Filter,
  Toolbar,
  ExcelExport,
  PdfExport,
  Search,
  Resize,
  Reorder,
  Edit,
  CommandColumn,
  SortSettingsModel,
  EditSettingsModel,
  RecordDoubleClickEventArgs,
  Aggregate,
  AggregateColumnsDirective,
  AggregateColumnDirective,
  AggregateDirective,
  AggregatesDirective,
} from "@syncfusion/ej2-react-grids";
import { Alert, Box, Button, ButtonGroup, Grid, Snackbar, TextField } from "@mui/material";
import { submitTerminalHires } from "../../../services/njport.service";
import { TerminalHireObject } from "../../../interfaces/NJPORT/TerminalHire.interface";
import { editPdoHire, setTerminalHire } from "./apical";

interface TableDialogProps {
  dataSource: TerminalHireObject[];
  handleTableRecordUpdate: (saveRequest: TerminalHireObject) => void;
  isAdd: boolean;
}

const TableDialog: React.FC<TableDialogProps> = ({
  dataSource,
  handleTableRecordUpdate,
  isAdd

}) => {
  const gridRef = useRef<GridComponent | null>(null);
  const sortSettings: SortSettingsModel = {
    columns: [{ field: "ID", direction: "Ascending" }],
  };
  const editOptions: EditSettingsModel = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
  };

  const convertDate = (date: Date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const [editableData, setEditableData] = useState<any>([]);
  const [totalWorkersHire, setTotalWorkersHire] = useState<number>(0);
  const [selectedDate, setSelectedDate] = useState<string>(new Date().toISOString().split('T')[0]);
  const [formdate, setformdata] = useState<string>(convertDate(new Date()));
  // const [formdate, setFormDate] = useState<string>(convertDate(new Date())); // State to manage toDate field

  const [alertType, setAlertType] = React.useState<any>("success");
  const [alertOpen, setAlertOpen] = React.useState<boolean>(false);
  const [alertText, setAlertText] = React.useState<string>("");
  useEffect(() => {

    setEditableData(dataSource);
    return () => setEditableData([]);
  }, [dataSource]);

  useEffect(() => {
    const sumOfWorkersHired = editableData.reduce(
      (total: number, record: TerminalHireObject) => {
        return total + (record?.WorkersHired || 0);
      },
      0
    );
    setTotalWorkersHire(sumOfWorkersHired);
  }, [editableData]);

  const actionBegin = (args: any) => {
    if (args.requestType === "save") {
      const updatedData = [...editableData];
      const index = updatedData.findIndex(

        (item) => item.ID === args.data.ID
      );
      if (index > -1) {
        updatedData[index] = { ...args.data };
        setEditableData(updatedData);
      }
    }
  };
  let today: Date = new Date();

  const actionComplete = (args: any) => {
    if (args.requestType === "save") {
      const gridInstance = gridRef.current as any;
      const updatedData = gridInstance.getCurrentViewRecords();
      setEditableData(updatedData);
      const sumOfWorkersHired = updatedData.reduce(
        (total: number, record: TerminalHireObject) => {
          return total + (record?.WorkersHired || 0);
        },
        0
      );
      setTotalWorkersHire(sumOfWorkersHired);
    }
  };

  const handleSubmit = async () => {
    if (gridRef.current) {
      const gridInstance = gridRef.current as any;
      const updatedData = gridInstance.getCurrentViewRecords();
      console.log({ updatedData });
      const dataToSend = updatedData.map((record: TerminalHireObject) => ({
        ...record,
        WorkersHired: record.WorkersHired,
      }));

      try {
        const saveRequest = await submitTerminalHires(dataToSend);
        handleTableRecordUpdate(saveRequest);
        setAlertOpen(true); // Set alert open after response is returned
        setAlertText("Data added successfulyy");
      } catch (error) {
        // Optionally, handle error state here
      }
    }
  };



  const handleRowDoubleClick = (args: RecordDoubleClickEventArgs) => {
    const clickedRecord = args.row as unknown as TerminalHireObject;
    console.log("Row double-clicked : ", clickedRecord);
  };

  const handleChange = async (val: any) => {
    setformdata(val);
    try {
      let dateWithZeroTime = val;

      const requestData = {
        hiringDateTime: dateWithZeroTime,
      };
      const response = await editPdoHire(requestData);
      console.log("Add Report Response:", response);
      setEditableData(response);


    } catch (error) {
      console.error("Error adding report:", error);
    }
  };

  const handledatesubmit = async () => {
    try {
      let date = formdate.toString();

      const requestData = {
        HiringDate: date,
        HiringTime: '00:00'
      };
      const response = await setTerminalHire(requestData);
      console.log("response is :" + response)

      let isAdded = await await setTerminalHire(requestData);
      console.log("response is :" + isAdded)
      if (isAdded) {
        setAlertType("success");
        setAlertOpen(true);
        setAlertText(
          "New Data Added Successfully",
        );

      } else {
        setAlertType("error");
        setAlertOpen(true);
        setAlertText("Unable to add Data.");
      }

    } catch (e) {

    }

  }

  const handleclear = () => {
    console.log('clear button pressed');
    setformdata(convertDate(new Date()));

    // setFormDate(new Date().toISOString().split('T')[0]); // Set formdate to current date
  };

  const dateTemplate = (data: TerminalHireObject) => {
    if (!data.HiringDateTime) {
      return '';  // Return empty string if HiringDateTime is null or undefined
    }
    const date = new Date(data.HiringDateTime);
    return date.toLocaleDateString();  // Formats the date to "MM/DD/YYYY"
  };
  return (
    <>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={() => {
          setAlertOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setAlertOpen(false);
          }}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {alertText}
        </Alert>
      </Snackbar>
      <div>
        {isAdd && (
          <Box display="flex" alignItems="center" gap={2} width="100%">
            <Box flex="0 1 70%">
              <TextField
                fullWidth
                margin="none"
                type="date"
                name="toDate"
                label="To Date"
                defaultValue={formdate}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => handleChange(e.target.value)}
              />
            </Box>
          
          </Box>
        )}
      </div>
      {editableData.length > 0 ? (
        <div>


          <GridComponent
            ref={gridRef}
            dataSource={editableData}
            allowPaging={true}
            pageSettings={{
              pageSize: 25,
              pageSizes: [10, 25, 50, 100],
            }}
            allowSorting={true}
            sortSettings={sortSettings}
            allowFiltering={true}
            filterSettings={{ type: "CheckBox" }}
            allowTextWrap={true}
            textWrapSettings={{ wrapMode: "Both" }}
            allowExcelExport={true}
            allowPdfExport={true}
            toolbar={["ExcelExport", "Search"]}
            allowResizing={true}
            rowHeight={24}
            editSettings={editOptions}
            actionBegin={actionBegin}
            actionComplete={actionComplete}
          >
            <ColumnsDirective>
              <ColumnDirective
                field="HiringDateTime"
                width={115}
                minWidth={115}
                maxWidth={115}
                template={dateTemplate}
                headerText="HiringDateTime"
                allowEditing={false}
                customAttributes={{ class: ["e-attr"] }}
              />
              <ColumnDirective
                field="Port"
                width={115}
                minWidth={115}
                maxWidth={115}
                headerText="Port"
                customAttributes={{ class: ["e-attr"] }}
                allowEditing={false}
              />
              <ColumnDirective
                field="ShipTerminal"
                width={115}
                minWidth={115}
                maxWidth={115}
                headerText="ShipTerminal"
                customAttributes={{ class: ["e-attr"] }}
                allowEditing={false}
              />
              <ColumnDirective
                field="TerminalDesc"
                width={115}
                minWidth={115}
                maxWidth={115}
                headerText="TerminalDesc"
                customAttributes={{ class: ["e-attr"] }}
                allowEditing={false}
              />
              <ColumnDirective
                field="WorkersHired"
                width={115}
                headerText="Workers Hired"
                customAttributes={{ class: ["e-attr"] }}
                allowEditing={true}
                template={(props: any) => {
                  return (
                    <div className="custom-edit-box">{props.WorkersHired}</div>
                  );
                }}
              />
            </ColumnsDirective>
            <Grid item xs={8} sx={{ pt: 2 }}></Grid>
            <AggregatesDirective>
              <AggregateDirective>
                <AggregateColumnsDirective>
                  <AggregateColumnDirective
                    field="WorkersHired"
                    type="Sum"
                    footerTemplate={(props: any) => {
                      return (
                        <div className="e-footer-template mt-2 text-dark">
                          <span>Workers Hired Total:</span> {props.Sum}
                        </div>
                      );
                    }}
                  />
                </AggregateColumnsDirective>
              </AggregateDirective>
            </AggregatesDirective>
            <Inject
              services={[
                Sort,
                Page,
                Filter,
                Toolbar,
                ExcelExport,
                PdfExport,
                Search,
                Resize,
                Reorder,
                Edit,
                CommandColumn,
                Aggregate,
              ]}
            />
          </GridComponent>
          <Grid
            container
            spacing={1}
            sx={{ pl: 2, mt: 2, justifyContent: "flex-end" }}
          >
            <Grid item xs={8} sx={{ pr: 2 }}></Grid>
            <Grid item xs={2} sx={{ pr: 2 }}>
              <Box sx={{ flexDirection: "row" }}>
                <ButtonGroup size="large" color="inherit">
                  <Button type="submit" onClick={handleSubmit}>
                    Submit
                  </Button>
                  {/* <Button onClick={handleclear}>CLEAR</Button> */}
                </ButtonGroup>
                {/* <div>Workers Hired: </div>
                <div>{totalWorkersHire}</div> */}
              </Box>

            </Grid>
          </Grid>
        </div>
      ) : (
        <div className="font-bold text-center text-lg pt-3" style={{ width: '100%' }}>
          NO Matching Records Found
        </div>
      )}
    </>
  );
};

export default TableDialog;