import {PersonInfo} from "../../../interfaces/getDarInfo_interface";
import React from "react";
import { Disclosure } from '@headlessui/react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ProfessionInfo from "../../../interfaces/ProfessionInfo.interface";
import { TablePagination, CircularProgress } from "@mui/material";
import GunTraceIcon from "../../../assets/Images/WeaponTrace1.png";
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent ,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search
} from '@syncfusion/ej2-react-grids';

interface PanelAssociatedFirearmsProps {
    person: PersonInfo,
    firearms: any[],
    isLoading:boolean
}

const  PanelAssociatedFirearms: React.FC<PanelAssociatedFirearmsProps> = ({person,firearms,isLoading})=> {
    const [FormatData, setFormatData] = React.useState(0);

    const handleDataTyping = () => {
        let tempP: any = [...firearms]
        for (let p of tempP) {
            // if (p.IssueDate) {
            //     p.IssueDate = new Date(p.IssueDate)
            // }
        }
        setFormatData(tempP)
    }

    React.useEffect(() => {
        handleDataTyping()
    }, [firearms])

    return(
        <div className={"m-5 border"}>
            <Disclosure >
                {({ open }) => (
                    <>
                       <Disclosure.Button className="bg-blue-100 p-2 w-auto w-full text-left">
                            <span style={{ "display": "flex", "justifyContent": "space-between" }}>
                                <span>
                                    <KeyboardArrowDownIcon
                                        className={`${open ? "transform rotate-180" : ""}`}
                                    />
                                    <span className={"font-bold"}>Associated Firearms <img src={GunTraceIcon} alt={''} style={{ display: 'inline' }} /> {(isLoading) ? <CircularProgress size={20} style={{"marginLeft" : "5px"}} /> : <></>}</span>
                                </span>
                                <span className={"font-bold mr-2"} >Name: {person?.FName?.toUpperCase()} {person?.MName?.toUpperCase()} {person?.LName?.toUpperCase()} &nbsp;&nbsp;Records: {firearms?.length}</span>
                            </span>
                       </Disclosure.Button>
                    
                        <Disclosure.Panel className="text-gray-500 p-4">

                            <GridComponent
                                dataSource={FormatData}
                                allowPaging={true}
                                pageSettings={{pageSize: 50, pageSizes: [10,25,50,100]}}
                                allowSorting={true}
                                allowMultiSorting={true}
                                allowFiltering={true}
                                filterSettings={{type: 'CheckBox'}}
                                allowTextWrap={true}
                                textWrapSettings={{ wrapMode: 'Both' }}
                                rowHeight={24}
                            >
                                <ColumnsDirective>
                                    {/*<ColumnDirective field='IssueDate' headerText='Issue Date' format={{ type: 'date', format: 'MM/dd/yyyy' }}/>*/}
                                    <ColumnDirective field='reportNumber' headerText='CCN #'/>
                                    <ColumnDirective field='categoryFullName' headerText='CLASSIFICATION'/>
                                    <ColumnDirective field='firearmMake' headerText='MAKE'/>
                                    <ColumnDirective field='model' headerText='MODEL'/>
                                    <ColumnDirective field='serialNumber' headerText='SERIAL #'/>
                                    <ColumnDirective field='storageFacility' headerText='STORAGE FACILITY'/>
                                    <ColumnDirective field='storageLocation' headerText='STORAGE LOCATION'/>
                                    <ColumnDirective field='nameItemAssociation' headerText='ROLE'/>
                                </ColumnsDirective>
                                <Inject services={[Sort, Page, Filter]}/>
                            </GridComponent>
                            
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>
        
    )
};

export default PanelAssociatedFirearms;