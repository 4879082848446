import React from 'react';
import { Box, FormControl, FormControlLabel, FormLabel, Tab, Tooltip, Snackbar, Alert, ButtonGroup, IconButton, Dialog, DialogTitle, DialogContent, Button, TextField, Typography, DialogActions, Container, Checkbox, RadioGroup, Radio, DialogContentText, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { Grid as MUIGrid } from '@mui/material';
import { DateTimePicker, LocalizationProvider, TabContext, TabList, TabPanel } from '@mui/lab';
import {PostAdd, Close, FormatListBulleted, ExpandMore, PictureAsPdf} from '@mui/icons-material';
import { SiteName } from '../../../services/config.service';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
    ColumnDirective,
    ColumnsDirective,
    ColumnMenu,
    GridComponent,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search,
    ExcelExportProperties,
    Resize,
    Reorder,
    SortSettingsModel,
    CommandColumn,
    setChecked
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import { GetDarInfo_Query, getDarInfoResponse } from "../../../interfaces/getDarInfo_interface";
import { base64_to_blob, GetDeviceToken, RunShotSpotterQuery } from "../../../services/detail.service";
import { RunDarQuery } from "../../../services/getDar.service";
import { handleSearchTime } from "../../../services/formatDate.service";
import { getUser, isUserPermission } from '../../../services/auth.service';
import { Account } from '../../../interfaces/auth_interface';
import './styles.css';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import PreliminaryReportSearch from "./_preliminaryReportSearch";
import PreliminaryReportInputForm from './_preliminaryReportInputForm';
import DeleteIcon from '@mui/icons-material/Delete';
import { PIRReport } from '../../../interfaces/PIR.interface';
import {PersonInfo} from "../../../interfaces/getDarInfo_interface";
import {GetPIR, GetPIRPDF, SetPIRReport, SetPIRSupplement} from "../../../services/pir.service";
import DifferenceIcon from '@mui/icons-material/Difference';
import QueueIcon from '@mui/icons-material/Queue';
import {formatDT} from "../../../services/formatDate.service";
import Swal from "sweetalert2";
import PreliminarySupplementReportInputForm from "./_PreliminarySupplementInputForm";

interface PIRSearchResultsProps {
    Records: PIRReport[]
    RefreshTrigger: any
}

const PreliminaryReportSearchResults: React.FC<PIRSearchResultsProps> = ({ Records, RefreshTrigger }) => {
    const [user, setUser] = React.useState<Account>(getUser());
    const [shotSpotterData, setShotSpotterData] = React.useState<{ [key: string]: any[] }>({});
    const [typedRecords, setTypedRecords] = React.useState<any[]>([])
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [tabValue, setTabValue] = React.useState('2');
    const [tabModLabelValue, setTabModLabelValue] = React.useState('CREATE NEW');
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false)
    const [alertText, setAlertText] = React.useState<string>("")
    const [alertType, setAlertType] = React.useState<any>("success")
    const [editingRecord, setEditingRecord] = React.useState<null>(null);
    const [personDetails, setPersonDetails] = React.useState<null>(null);

    const [expandedRows, setExpandedRows] = React.useState<Set<number>>(new Set());
    const [openAddDialog, setOpenAddDialog] = React.useState(false);
    const [currentRowData, setCurrentRowData] = React.useState<any>();
    const [currentSupplementRowData, setCurrentSupplementRowData] = React.useState<any>();
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);


    const handleDataTyping = async () =>
    {
        if (Records) {
            let completedRecords = Records.filter(record => (record?.Completed !== false) || (record?.Completed == null))
            let tempR: any = [...completedRecords]
            setIsLoading(true)
            await new Promise(r => setTimeout(r, 1000)); //Why I need to sleep... This fixes the syncfusion no rerender on my machine
            setTypedRecords(tempR)
            setIsLoading(false)
        }
    }

    React.useEffect(() => {
        
        handleDataTyping()
    }, [Records])
    
 
    const openDeleteConfirmation = (rowData: any) => {
        setCurrentRowData(rowData);
        setOpenDeleteDialog(true);
    };

    const handleDeleteSupplement = () => {
        const updatedResults = typedRecords.map(row => {
            if (row.ccn === currentRowData.ccn) {
                const updatedRow = { ...row, supplementData: undefined };
                return updatedRow;
            }
            return row;
        });
        setTypedRecords(updatedResults);
        setOpenDeleteDialog(false);
    };
    
    const openPDF = (row:any) => {
        if(row && row.ID){
            GetPIRPDF(row.ID)
        }
    }


    let grid: Grid | null;

    const handleTabStateChange = (tab:any, edit:boolean = false)=>{
        if(tab === '2'){
            setTabModLabelValue('CREATE NEW')
            setEditingRecord(null)
        } else if (tab === '1'){
            if(!edit) {
                setEditingRecord(null)
                setTabModLabelValue('CREATE NEW')
            }else {
                setTabModLabelValue('EDIT')
            }
        }
       
        setTabValue(tab)
    }
    const handleFormSubmit = (formData: any) => {
        if(formData)
            RefreshTrigger()
        
        setEditingRecord(null)
        handleTabStateChange('2')

        setAlertType('success')
        setAlertText("Your report has successfully been submitted.")
        setAlertOpen(true);
    };

    const handleSuppSubmit = (event: any) => {
        if(event)
            RefreshTrigger()
        
        setOpenAddDialog(!openAddDialog);
    };
    
    let sortSettings: SortSettingsModel = {
        columns: [{ field: 'ccn', direction: 'Descending' }]
    };


    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        handleTabStateChange(newValue)
    }

    const handleEditPIR = (rowData: any) => {
        if(rowData && rowData.ID) {
            setEditingRecord(rowData?.ID)
            handleTabStateChange('1',true)
        }

    };
    const handleDeletePIR = (rowData: any) => {
        if (window.confirm("Are you sure you would like to delete this PIR? This will delete PIR with CCN: " + rowData.Summary_CCN)) {
            let temp = {...rowData}
            temp.deleted = true
            SetPIRReport(temp)
            if(grid) {
                // @ts-ignore
                let dSource:any = [...grid?.dataSource]
                if(temp.ID && temp.ID != 0) {
                    let index:number = dSource.findIndex((e: any) => e.ID === temp.ID)
                    if(index >= 0)
                    {
                        dSource.splice(index,1)
                        grid.dataSource = dSource
                        setTypedRecords(dSource)
                        grid?.refresh()
                    }
                }
            }
        }
        
    };
    
    const handleEditSupplement = (rowData: any) => {
        setCurrentSupplementRowData(rowData)
        setOpenAddDialog(!openAddDialog)

    };
    const actionTemplate = (rowData: any) => {
        return (
            <ButtonGroup>
                <Tooltip title="View PDF" placement="top" arrow>
                    <IconButton onClick={() => openPDF(rowData)}>
                        <PictureAsPdf fontSize='small' color='inherit'/>
                    </IconButton>
                </Tooltip>
    
                <Tooltip title="Add Supplement" placement="top" arrow>
                    <IconButton onClick={() => openAddSupplementDialog(rowData)}>
                        <QueueIcon fontSize='small' color='success'/>
                    </IconButton>
                </Tooltip>
    
                <Tooltip title="Edit Preliminary Investigative Report" placement="top" arrow>
                    <IconButton onClick={() => handleEditPIR(rowData)}>
                        <EditIcon fontSize='small' color='primary'/>
                    </IconButton>
                </Tooltip>
    
                <Tooltip title="Delete Preliminary Investigative Report" placement="top" arrow>
                    <IconButton onClick={() => handleDeletePIR(rowData)}>
                        <DeleteIcon fontSize='small' color='error'/>
                    </IconButton>
                </Tooltip>
    
            </ButtonGroup>
        );
        
    };
    const actionTemplateSupplement = (rowData: any) => {
        return (
            <ButtonGroup>
                <Tooltip title="Edit Supplement" placement="top" arrow>
                    <IconButton onClick={() => handleEditSupplement(rowData)}>
                        <EditIcon fontSize='small' color='primary' />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>
        );
    };



    const openAddSupplementDialog = (rowData: any) => {
        setCurrentRowData(rowData);
        setCurrentSupplementRowData(null);
        setOpenAddDialog(true);
    };

    const GridToolsClick = (args: ClickEventArgs) => {
        if (grid && args.item.text == "Excel Export") {
            let excelProp: ExcelExportProperties = {}
            excelProp.fileName = 'Preliminary_Report.xlsx';

            grid.excelExport(excelProp);
        }
    }

    const playShotSound = async (FileID: any, ShotID: any, FileMeme: any) => {
        let res: any = await RunShotSpotterQuery(FileID, ShotID);
        let snd = new Audio("data:" + FileMeme + ";base64," + res.AudioFile);
        snd.play();
    }

    const downloadShotFile = async (FileID: any, ShotID: any, FileMeme: any) => {
        let res: any = await RunShotSpotterQuery(FileID, ShotID);
        if (res.AudioFile) {
            base64_to_blob(res.AudioFile, FileMeme).then(r => {
                var blobUrl = URL.createObjectURL(r);
                window.open(blobUrl, '_blank');
            });
        }
    }
    
    const AccordionSupplements = ({ supplements }: any) => {

        return (
            <>
                <GridComponent
                    dataSource={supplements}
                    allowPaging={true}
                    pageSettings={{ pageSize: 25, pageSizes: [10, 25, 50, 100] }}
                    allowSorting={true}
                    sortSettings={sortSettings}
                    allowMultiSorting={true}
                    allowFiltering={true}
                    filterSettings={{ type: 'CheckBox' }}
                    allowTextWrap={true}
                    textWrapSettings={{ wrapMode: 'Both' }}
                    allowResizing={true}
                    rowHeight={24}
                >
                    <ColumnsDirective>
                        <ColumnDirective field='CreateDate' allowSorting={false} template={(r: any) => (formatDT(r.CreateDate))} width={140} minWidth={140} maxWidth={140} headerText='Supplement Created' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='Summary_DateTime' allowSorting={false}  template={(r:any)=>(formatDT(r.Summary_DateTime))} width={140} minWidth={140} maxWidth={140} headerText='Supplement Date' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='Summary_CCN' visible={true} width={140} minWidth={140} maxWidth={140} headerText='CCN' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='Summary_Type' width={140} minWidth={140} maxWidth={140} headerText='Incident Type' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='Summary_Location' width={140} minWidth={140} maxWidth={140} headerText='Location' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='Background_CaseRelationship' width={140} minWidth={140} maxWidth={140} headerText='Case Relationship' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective template={actionTemplateSupplement} headerText="Actions" width="100" textAlign="Center" />
                    </ColumnsDirective>
                    <Inject services={[ColumnMenu, Sort, Page, Filter, DetailRow, Toolbar, ExcelExport, PdfExport, Search, Resize, Reorder, CommandColumn]} />
                </GridComponent>
                
            </>
        );
    };


    const renderDetailTemplate = (rowData: any) => {
        var shotSpotterDetails:any[] =  [];
        if(rowData.ShotSpotter_List && rowData.ShotSpotter_List.length > 0){
            for(var s of rowData.ShotSpotter_List){
                if(s?.ShotSpotterFiles && s?.ShotSpotterFiles.length > 0)
                {
                    shotSpotterDetails = shotSpotterDetails.concat(s?.ShotSpotterFiles)
                }
            }
        }
        
        return (
            <div>
                {rowData.SupplementReports && rowData.SupplementReports.length > 0 && (
                    <div className={"mt-2 font-bold"}>
                        Supplements: {rowData.SupplementReports.length}
                        <AccordionSupplements supplements={rowData.SupplementReports} />
                    </div>
                )}

                {/* Add ShotSpotter Audio Files */}
                {shotSpotterDetails && shotSpotterDetails.length > 0 ? (
                    <div className={"mt-5 font-bold"}>
                         ShotSpotter Files: {shotSpotterDetails?.length}
                        {shootingAudioFiles(shotSpotterDetails)}
                    </div>
                ) : (
                    <Typography>No ShotSpotter data found.</Typography>
                )}
            </div>
        );
    };

    const shootingAudioFiles = (shotSpotterDetails: any[]) => {
        return (
            <div className="border-2 border-gray-100">
                <GridComponent
                    dataSource={shotSpotterDetails}
                    allowPaging={true}
                    pageSettings={{ pageSize: 10, pageSizes: [10, 25, 50, 100] }}
                    allowSorting={true}
                    sortSettings={{ columns: [{ field: 'location', direction: 'Descending' }] }}
                    allowTextWrap={true}
                    textWrapSettings={{ wrapMode: 'Both' }}
                    rowHeight={24}
                >
                    <ColumnsDirective>
                        <ColumnDirective field="location" headerText="Associated Shot Spotter" />
                        <ColumnDirective field="sensor_distance" headerText="Sensor Distance (Meters)" />
                        <ColumnDirective field="mime" headerText="Sensor Media Type" />
                        <ColumnDirective
                            template={(shot: any) => (
                                <span>
                                    <Tooltip title="Play Audio File">
                                        <Button onClick={() => playShotSound(shot.ID, shot.shotid, shot.mime)}>
                                            Play
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title="Download Audio File">
                                        <Button onClick={() => downloadShotFile(shot.ID, shot.shotid, shot.mime)}>
                                            Download
                                        </Button>
                                    </Tooltip>
                                </span>
                            )}
                            headerText="Actions"
                        />
                    </ColumnsDirective>
                    <Inject services={[Sort, Page, Filter]} />
                </GridComponent>
            </div>
        );
    };


    
    return (
        <div className="m-5 p-5">
            <Box className="" style={{ justifyContent: 'center' }}>
                <TabContext value={tabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleTabChange} variant="fullWidth">
                            <Tab icon={<FormatListBulleted />} label="LIST VIEW" value={'2'} />
                            <Tab icon={<PostAdd />} label={`${tabModLabelValue} REPORT`} value={'1'} />
                        </TabList>
                    </Box>
                    <TabPanel value='2'>
                        <Snackbar open={alertOpen} autoHideDuration={6000} onClose={() => { setAlertOpen(false) }} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                            <Alert onClose={() => { setAlertOpen(false) }} severity={alertType} sx={{ width: '100%', height: '25%', fontSize: '1.2rem' }}>
                                {alertText}
                            </Alert>
                        </Snackbar>
                        <div className={"pl-10 pr-10 mt-5"}>
                            <h4 className={"font-bold pl-5"}>
                                {typedRecords?.length} Records Found</h4>

                            {!isLoading && typedRecords && typedRecords.length>0 && 
                                <GridComponent
                                    dataSource={typedRecords}
                                    detailTemplate={renderDetailTemplate}
                                    allowPaging={true}
                                    pageSettings={{ pageSize: 25, pageSizes: [10, 25, 50, 100] }}
                                    allowSorting={true}
                                    sortSettings={sortSettings}
                                    allowMultiSorting={true}
                                    allowFiltering={true}
                                    filterSettings={{ type: 'CheckBox' }}
                                    allowTextWrap={true}
                                    textWrapSettings={{ wrapMode: 'Both' }}
                                    allowExcelExport={true}
                                    allowPdfExport={true}
                                    toolbar={['ExcelExport', 'Search']}
                                    toolbarClick={GridToolsClick}
                                    ref={(g) => { if (g) { grid = g } }}
                                    allowSelection={true}
                                    allowResizing={true}
                                    rowHeight={24}
                                    detailDataBound={renderDetailTemplate}
                                >
                                    <ColumnsDirective>
                                        <ColumnDirective field='CreateDate' visible={true} width={140} template={(r: any) => (formatDT(r.CreateDate))} minWidth={140} maxWidth={140} headerText='Report Created' customAttributes={{ class: ['e-attr'] }} />
                                        <ColumnDirective field='Summary_CCN' visible={true} width={140} minWidth={140} maxWidth={140} headerText='CCN' customAttributes={{ class: ['e-attr'] }} />
                                        <ColumnDirective field='Summary_SpecialistFirstName' width={140} minWidth={140} maxWidth={140} headerText='First Name' customAttributes={{ class: ['e-attr'] }} />
                                        <ColumnDirective field='Summary_SpecialistLastName' width={140} minWidth={140} maxWidth={140} headerText='Last Name' customAttributes={{ class: ['e-attr'] }} />
                                        <ColumnDirective field='Summary_DateTime' allowSorting={false}  template={(r:any)=>(formatDT(r.Summary_DateTime))} width={140} minWidth={140} maxWidth={140} headerText='Incident Date' customAttributes={{ class: ['e-attr'] }} />
                                        <ColumnDirective field='Summary_Type' width={140} minWidth={140} maxWidth={140} headerText='Incident Type' customAttributes={{ class: ['e-attr'] }} />
                                        <ColumnDirective field='Summary_Location' width={120} minWidth={120} maxWidth={120} headerText='Location' customAttributes={{ class: ['e-attr'] }} />
                                        <ColumnDirective template={actionTemplate} headerText="Actions" width="135" textAlign="Center" />
                                    </ColumnsDirective>
                                    <Inject services={[ColumnMenu, Sort, Page, Filter, DetailRow, Toolbar, ExcelExport, PdfExport, Search, Resize, Reorder, CommandColumn]} />
                                </GridComponent>
                            }
                        </div>
                        <Dialog open={openAddDialog} fullWidth={true} maxWidth="lg" onClose={() => { setOpenAddDialog(false); }}>
                            <DialogTitle>Add Supplement</DialogTitle>
                            <DialogContent>
                                <PreliminarySupplementReportInputForm handleReportSubmit={handleSuppSubmit} ParentReportId={currentRowData?.ID} Record={currentSupplementRowData} btPerson={null} drawerDisplay={false} HandleSuppClose={null} SummaryInfo={currentRowData} ccnList={null} />
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            open={openDeleteDialog}
                            onClose={() => setOpenDeleteDialog(false)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Are you sure you want to delete this supplement?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
                                    Cancel
                                </Button>
                                <Button onClick={handleDeleteSupplement} color="primary" autoFocus>
                                    Delete
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </TabPanel>
                    <TabPanel value='1'>
                        {tabValue === '1' && <PreliminaryReportInputForm handleReportSubmit={handleFormSubmit} editReport={editingRecord} personDetails={personDetails} currentTab={tabValue} />}
                    </TabPanel>
                </TabContext>
            </Box>
        </div>
    );
};

export default PreliminaryReportSearchResults;