import React, {useRef ,useState} from "react";
import {GetLinkAnalysisData,} from "../../services/linkanalysis.service";
import {formatDate, formatDT} from "../../services/formatDate.service";
import {useLocation} from "react-router-dom";
import {isUserPermission} from "../../services/auth.service";
import {json} from "stream/consumers";
import '../../components/LinkAnalysis/_PersonTimeLineVertical.scss'
import {
    Badge,
    Card, CardContent,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from "@mui/material";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function NIBINTimeLine() {
    const [callData, setCallData] = React.useState<boolean>(false)
    const [firstIncidentIdx, setFirstIncidentIdx] = React.useState<number>(-1)
    const [flag, setFlag] = React.useState<boolean>(false)
    const [data, setData] = React.useState<any>({type: 'LinkChart', items: []})
    const [linkColor, setLinkColor] = React.useState<string>('#566573')
    const [layout, setLayout] = React.useState<string>('HORIZONTAL') //Nibin passes in SNAKE
    
    let query = useQuery();

    React.useEffect(() => {
        if(!callData)
        {
            setCallData(true)
            loadChartData()
        }
    }, []);
    

    const loadChartData = () => {
        let search_query: any =query.get("ReportID") //http://localhost:3000/link_analysis?ReportID=7B5430AA-B351-48CF-BA99-3D0BCDEE9C2B
        let APIKEY: string | null = query.get("APIKEY")
        let AUTHOTP: string | null = query.get("AUTHOTP")
        let layout_mode: string | null = query.get("L")
        if(layout_mode){
            setLayout(layout_mode)
        }
        GetLinkAnalysisData(search_query,APIKEY,AUTHOTP).then(response => {
                if(response.length > 0) {
                    makeReport(response[0])
                }
            }
        )
    }

    const numberToExcelFormat = (num:number) : string => {
        return num <= 0 ? '' : numberToExcelFormat(Math.floor((num - 1) / 26)) + String.fromCharCode((num - 1) % 26 + 65)
    };

const sortReportsIncidents = ( a:any, b:any ) => {
        if( new Date(a.OccurrenceDate) && new Date(b.OccurrenceDate)){
            if ( new Date(a.OccurrenceDate) > new Date(b.OccurrenceDate) ){
                return 1;
            }
            if ( new Date(a.OccurrenceDate) < new Date(b.OccurrenceDate) ){
                return -1;
            }
            if(a.OccurrenceDate == b.OccurrenceDate){
                if(a.ID && b.ID && a.ID > b.ID)
                    return 1;
                if(a.ID && b.ID && a.ID < b.ID)
                    return -1;
            }
        }
        return 0;
    }


    //Creating data for Chart via JSON file
    const makeReport = async (report:any) => {
        let tempData = data
        //var node = { type: 'node', id: 'n1', c: 'blue', t: 'Hello World!' }
        let incIdx = 0;
        let bE_Sum = 0;
        let ttc_date = null;

        if(report.EnteredDateTime){
            tempData.items.push({
                type: 'node',
                id: 'R' + report.ID,
                dt: new Date(report.EnteredDateTime),
                day_diff: 0,
                badge: 'RPT',
                sort_priority:999999,
                u: 'i2icons/Document.png',
                t:  'REPORT GENERATED FOR ' + (report.AssociatedEvidences.AssociatedWeapon.length > 0
                    ? report.Lab + " " +  report.ReferenceNumber + " (" + (report.NIBINHit ? "HIT" : "POTENTIAL") + ")" + '\n RECOVERED WEAPON'
                    : report.Lab + " " +  report.ReferenceNumber + " (" + (report.NIBINHit ? "HIT" : "POTENTIAL") + ")" + ' \n UNRECOVERED WEAPON'),
                d:{
                    type:'Report',
                    data:report
                },
                ha0: {
                    c: ( report.AssociatedEvidences.AssociatedWeapon.length > 0 ?'rgb(0, 160, 0)' : 'rgb(255,0,0)'),
                    r: 30,
                    w: 8
                }
            })
        }
        
        
        for(var incident of report.Incidents.sort(sortReportsIncidents)) {
            incIdx++;
            let eviIdx = 0;
            
            //---------------INCIDENTS-------------
            tempData.items.push(
                {
                    type: 'node',
                    id: 'I' + incident.ID,
                    dt: new Date(incident.OccurrenceDate),
                    day_diff: 0,
                    sort_priority:parseInt(`${incIdx}0`),//0,
                    badge: numberToExcelFormat(incIdx),
                    t:  incident.Dept.trim() + ' / ' + incident.CaseNumber + ' - ' + incident.Inc_Type,
                    u: 'i2icons/Case.png',
                    d:{
                        type:'Incident',
                        data:incident
                    }
                })
            if(incident.arrests && incident.arrests.length > 0){
                for(let arrest of incident.arrests){
                    if(!arrest.DTOFARREST)
                        continue;
                    
                    tempData.items.push(
                        {
                            type: 'node',
                            id: 'iA' + arrest.ID,
                            dt: new Date(arrest.DTOFARREST),
                            sort_priority:parseInt(`${incIdx}1`),
                            day_diff: day_diff_calc(new Date(arrest.DTOFARREST), new Date(incident.OccurrenceDate)),
                            badge: numberToExcelFormat(incIdx) + ".A",
                            t:  `${arrest?.FIRSTNAME} ${arrest?.LASTNAME} ${formatDate(arrest?.DOB)} ${arrest?.FBI? 'FBI:'+arrest.FBI:''} ${arrest?.SBI? 'SBI:'+arrest.SBI:''}`,
                            u: 'i2icons/Arrest.png',
                            d:{
                                type:'Arrest',
                                data:arrest,
                                inc_data: incident
                            },
                        })
                }
            }
            if(incident.etraces && incident.etraces.length > 0){
                for(let etrace of incident.etraces){
                    if(etrace.TracePersons && etrace.TracePersons.length > 0){
                        for(let tracePerson of etrace.TracePersons){
                            if(!tracePerson.INVOLVE_DATE)
                                continue;
                            
                            if(!report.AssociatedEvidences.AssociatedWeapon.some((aWeapon:any) => aWeapon.SERIAL_NUMBER === tracePerson.WPN_SERIAL))
                                continue;
                            
                            tempData.items.push(
                                {
                                    type: 'node',
                                    id: 'eTP' + tracePerson.FIRSTNAME+tracePerson.LASTNAME+tracePerson.DOB,
                                    dt: new Date(tracePerson.INVOLVE_DATE),
                                    sort_priority:5,
                                    day_diff: day_diff_calc(new Date(tracePerson.INVOLVE_DATE), new Date(incident.OccurrenceDate)),
                                    badge: numberToExcelFormat(incIdx) +'.' + tracePerson?.ROLE[0],
                                    t: `${tracePerson?.ROLE} : ${tracePerson?.FIRSTNAME} ${tracePerson?.LASTNAME} ${formatDate(tracePerson?.DOB)}`,
                                    u: 'i2icons/Person.png',
                                    d:{
                                        type:'ETrace Person',
                                        data:tracePerson,
                                        inc_data: incident
                                    },
                                })
                        }
                    }
                    
                    if(etrace.TraceDealers && etrace.TraceDealers.length > 0){
                        for(let traceDealer of etrace.TraceDealers){
                            if(!traceDealer.DEALER_SHIP_DATE)
                                continue;
                            if(!report.AssociatedEvidences.AssociatedWeapon.some((aWeapon:any) => aWeapon.SERIAL_NUMBER === traceDealer.WPN_SERIAL))
                                continue;
                            
                            if(ttc_date === null){
                                ttc_date = new Date(traceDealer.DEALER_SHIP_DATE)
                            }
                            
                            tempData.items.push(
                                {
                                    type: 'node',
                                    id: 'eTD' + traceDealer.DEALER_FFL_NUMBER,
                                    dt: new Date(traceDealer.DEALER_SHIP_DATE),
                                    sort_priority:5,
                                    day_diff: day_diff_calc(new Date(traceDealer.DEALER_SHIP_DATE), new Date(incident.OccurrenceDate)),
                                    badge: numberToExcelFormat(incIdx) + ".D",
                                    t: `${traceDealer?.DEALER_NAME} ${traceDealer?.DEALER_FFL_NUMBER}`,
                                    u: 'i2icons/Shopping Mall.png',
                                    d:{
                                        type:'ETrace Dealer',
                                        data:traceDealer,
                                        inc_data: incident
                                    },
                                })
                        }
                    }
                }
            }

            if (incident.Weapon.length !== 0)
            {
                for(var weapon of incident.Weapon) {
                    if(!report.AssociatedEvidences.AssociatedWeapon.some((aWeapon:any) => aWeapon.ID === weapon.ID))
                        continue;
                    eviIdx++;
                    
                    tempData.items.push(
                        {
                            type: 'node',
                            id: 'W' + weapon.ID,
                            dt: new Date(weapon.DTOFCOLLECTION ?? incident.OccurrenceDate),
                            sort_priority:parseInt(`${incIdx}2`),
                            day_diff: day_diff_calc(new Date(weapon.DTOFCOLLECTION ?? incident.OccurrenceDate), new Date(incident.OccurrenceDate)),
                            badge: numberToExcelFormat(incIdx) + ("."+eviIdx),
                            t: 'AGENCY COLLECTED RECOVERED ' + (weapon.MAKE ? weapon.MAKE : "") + ' ' + (weapon.MODEL ? weapon.MODEL : ""),
                            u: 'i2icons/Gun.png',
                            d:{
                                type:'Weapon Collected',
                                data:weapon,
                                inc_data: incident
                            },
                            ha0: {
                                c: ( report.AssociatedEvidences.AssociatedWeapon.some((aWeapon:any) => aWeapon.ID === weapon.ID) ? 'rgb(0, 160, 0)' : 'rgb(255,255,255)'),
                                r: 30,
                                w: 8
                            },
                        })

                    if(weapon.DTOFSUBMISSION){
                        tempData.items.push(
                            {
                                type: 'node',
                                id: 'W' + weapon.ID,
                                dt: new Date(weapon.DTOFSUBMISSION ?? incident.OccurrenceDate),
                                sort_priority:parseInt(`${incIdx}3`),
                                day_diff: day_diff_calc(new Date(weapon.DTOFSUBMISSION ?? incident.OccurrenceDate), new Date(incident.OccurrenceDate)),
                                badge: numberToExcelFormat(incIdx) + ("."+eviIdx),
                                t: 'LAB SUBMISSION FOR ' +'RECOVERED ' + (weapon.MAKE ? weapon.MAKE : "") + ' ' + (weapon.MODEL ? weapon.MODEL : ""),
                                u: 'i2icons/Digital Evidence Analysis Gun.png',
                                d:{
                                    type:'Weapon Lab Submission',
                                    data:weapon,
                                    inc_data: incident
                                },
                                ha0: {
                                    c: ( report.AssociatedEvidences.AssociatedWeapon.some((aWeapon:any) => aWeapon.ID === weapon.ID) ? 'rgb(0, 160, 0)' : 'rgb(255,255,255)'),
                                    r: 30,
                                    w: 8
                                },
                            })
                    }
                  
                }
            }
            //---------------------------------------
            //---------------BALISTICS---------------
            if (incident.Ballistics.length !== 0)
            {
                for(var ballistic of incident.Ballistics) {
                    if(!report.AssociatedEvidences.AssociatedBallistic.some((aBallistic:any) => aBallistic.ID === ballistic.ID))
                        continue;
                    eviIdx++;
                    
                    //Item_Quantity
                    bE_Sum += ballistic.Item_Quantity ?? 0;

                    tempData.items.push(
                        {
                            type: 'node',
                            id: 'B' + ballistic.ID,
                            dt: new Date(ballistic.DTOFCOLLECTION ?? incident.OccurrenceDate),
                            sort_priority:parseInt(`${incIdx}4`),
                            day_diff: day_diff_calc(new Date(ballistic.DTOFCOLLECTION ?? incident.OccurrenceDate), new Date(incident.OccurrenceDate)),
                            badge: numberToExcelFormat(incIdx) + ("."+eviIdx),
                            t:  'AGENCY COLLECTED '+(ballistic.Caliber_Type?.length > 0 ? ballistic.Caliber_Type + ' ' : "")  + (ballistic.VGID_CPart?.length > 0 ? ballistic.VGID_CPart : ""),
                            u: 'i2icons/Bulcases.png',
                            d:{
                                type:'Ballistic Evidence Collected',
                                data:ballistic,
                                inc_data: incident
                            },
                            ha0: {
                                c: ( report.AssociatedEvidences.AssociatedBallistic.some((aBallistic:any) => aBallistic.ID === ballistic.ID) ?
                                    (report.AssociatedEvidences.AssociatedWeapon.length > 0 ?'rgb(0, 160, 0)' : 'rgb(255,0,0)') : 'rgb(255,255,255)'),
                                r: 30,
                                w: 8
                            }
                        })
                    
                    if(ballistic.DTOFSUBMISSION){
                        tempData.items.push(
                            {
                                type: 'node',
                                id: 'B' + ballistic.ID,
                                dt: new Date(ballistic.DTOFSUBMISSION ?? incident.OccurrenceDate),
                                sort_priority:parseInt(`${incIdx}5`),
                                day_diff: day_diff_calc(new Date(ballistic.DTOFSUBMISSION ?? incident.OccurrenceDate), new Date(incident.OccurrenceDate)),
                                badge: numberToExcelFormat(incIdx)  + ("."+eviIdx),
                                t:  'LAB SUBMISSION FOR ' + (ballistic.Caliber_Type?.length > 0 ? ballistic.Caliber_Type + ' ' : "")  + (ballistic.VGID_CPart?.length > 0 ? ballistic.VGID_CPart : ""),
                                u: 'i2icons/Digital Evidence Analysis Bullet.png',
                                d:{
                                    type:'Ballistic Evidence Lab Submission',
                                    data:ballistic,
                                    inc_data: incident
                                },
                                ha0: {
                                    c: ( report.AssociatedEvidences.AssociatedBallistic.some((aBallistic:any) => aBallistic.ID === ballistic.ID) ?
                                        (report.AssociatedEvidences.AssociatedWeapon.length > 0 ?'rgb(0, 160, 0)' : 'rgb(255,0,0)') : 'rgb(255,255,255)'),
                                    r: 30,
                                    w: 8
                                }
                            })
                    }
                    
                }
            }
            //---------------------------------------
        }
        
        let filteredData = [];
        let temp = tempData.items.filter((item: any) => item.type === "node" && item.dt)
        let temp2 = tempData.items.filter((item: any) => item.type === "node" && item.dt1)
        filteredData.push(...temp)
        filteredData.push(...temp2)

        //need to sort filteredData by dt/dt1 asc order
        filteredData = filteredData.sort((a: any, b: any) => {
            if (a.dt && b.dt) {
                if(a.dt.getYear() === b.dt.getYear() && a.dt.getMonth() === b.dt.getMonth() && a.dt.getDate() === b.dt.getDate()){
                    if(a.sort_priority < b.sort_priority)
                        return -1
                    else if(a.sort_priority > b.sort_priority)
                        return 1
                    else
                        return 0
                }
                return a.dt.getTime() > b.dt.getTime() ? 1 : -1
            } else if (a.dt1 && b.dt1) {
                //a.dt1.getTime() === b.dt1.getTime()
                if(a.dt1.getYear() === b.dt1.getYear() && a.dt1.getMonth() === b.dt1.getMonth() && a.dt1.getDate() === b.dt1.getDate()){
                    if(a.sort_priority < b.sort_priority)
                        return -1
                    else if(a.sort_priority > b.sort_priority)
                        return 1
                    else
                        return 0
                }
                return a.dt1.getTime() > b.dt1.getTime() ? 1 : -1
            } else if (a.dt && b.dt1) {
                if(a.dt.getYear() === b.dt1.getYear() && a.dt.getMonth() === b.dt1.getMonth() && a.dt.getDate() === b.dt1.getDate()){
                    if(a.sort_priority < b.sort_priority)
                        return -1
                    else if(a.sort_priority > b.sort_priority)
                        return 1
                    else
                        return 0
                }
                return a.dt.getTime() > b.dt1.getTime() ? 1 : -1
            } else if (a.dt1 && b.dt) {
                if(a.dt1.getYear() === b.dt.getYear() && a.dt1.getMonth() === b.dt.getMonth() && a.dt1.getDate() === b.dt.getDate()){
                    if(a.sort_priority < b.sort_priority)
                        return -1
                    else if(a.sort_priority > b.sort_priority)
                        return 1
                    else
                        return 0
                }
                return a.dt1.getTime() > b.dt.getTime() ? 1 : -1
            } else {
                if(a.dt.getTime() === b.dt1.getTime()){
                    if(a.sort_priority < b.sort_priority)
                        return -1
                    else if(a.sort_priority > b.sort_priority)
                        return 1
                    else
                        return 0
                }
                return 0;
            }
        })
        
        //find the index of the report node
        let report_index = -1;
        for(let i=0;i<filteredData.length;i++) {
            if(filteredData[i].d.type === 'Report') {
                report_index = i;
                break;
            }
        }
        
        //set day diff for the incidents
        let first_incident_index=-1;
        let last_incident_index=-1;
        let incCount = 0;
        for(let i=0;i<filteredData.length;i++) {
            if(filteredData[i].d.type === 'Incident') {
                
                if(first_incident_index === -1){
                    setFirstIncidentIdx(i)
                    first_incident_index=i;
                }
                //if last incident index is not -1 find the difference between the last incident and the current incident
                if(last_incident_index !== -1) {
                    filteredData[i].day_diff = day_diff_calc(filteredData[i].dt, filteredData[last_incident_index].dt)
                }
                last_incident_index= i;
            }
        }
        
        if (first_incident_index >= 0 && report_index >= 0) {
            try{
                if(filteredData[report_index].dt && filteredData[first_incident_index].dt)
                    filteredData[report_index].day_diff = day_diff_calc(filteredData[report_index].dt, filteredData[first_incident_index].dt)
            } catch(e){
                console.log(e)
            }
        }
        
        //Add the Weapon Summary
        if(report && report.AssociatedEvidences && report.AssociatedEvidences.AssociatedWeapon && report.AssociatedEvidences.AssociatedWeapon.length > 0){
            for(let w of report.AssociatedEvidences.AssociatedWeapon){
                filteredData.push(
                    {
                        type: 'node',
                        id: 'SUN_W' + weapon.ID,
                        dt: filteredData[report_index].dt,
                        sort_priority:parseInt(`${incIdx}1`),
                        day_diff:  day_diff_calc(filteredData[report_index].dt, filteredData[first_incident_index].dt),
                        ttc:  ttc_date? day_diff_calc(filteredData[report_index].dt, ttc_date) : null,
                        badge: "",
                        t: 'WEAPON ' + (weapon.MAKE ? weapon.MAKE : "") + ' ' + (weapon.MODEL ? weapon.MODEL : ""),
                        u: 'i2icons/Gun.png',
                        d:{
                            type:'Weapon Recovery Summary',
                            data:weapon,
                            inc_count: incIdx,
                            bE_Sum: bE_Sum
                        },
                        ha0: {
                            c: ( report.AssociatedEvidences.AssociatedWeapon.some((aWeapon:any) => aWeapon.ID === weapon.ID) ? 'rgb(0, 160, 0)' : 'rgb(255,255,255)'),
                            r: 30,
                            w: 8
                        },
                    })
            }
        }



        //Remove Incidents from being displayed per LT request on Sep 18 2023 
        //filteredData = filteredData.filter((item: any) => item.d.type !== "Incident")

        setData({type: 'LinkChart', items: [...filteredData]})
        setFlag(true)
    }

    const day_diff_calc = (date1: Date, date2: Date) => {
        if(date1.getMonth()===date2.getMonth() && date1.getDate()===date2.getDate() && date1.getFullYear()===date2.getFullYear())
            return 0;
        let diff = Math.floor(date1.getTime() - date2.getTime());
        let day = 1000 * 60 * 60 * 24;
        return Math.floor(diff/day);
    }

    const diffExplain = (node: any, days: number) => {
    
        if(node){
            switch (node.d.type) {
                case("Report"):
                case("Weapon Recovery Summary"):
                    return "After First Incident"
                case("Incident"):
                    if(days === 0)
                        return "as Previous Incident";
                    return "After Previous Incident"
                case("Arrest"):
                case("ETrace Person"):
                case("ETrace Dealer"):
                case("Weapon Collected"):
                case("Weapon Lab Submission"):
                case("Ballistic Evidence Collected"):
                case("Ballistic Evidence Lab Submission"):
                    if(days ===0)
                        return "as Incident Occurrence";
                    return `${days >0  ? "After" : "Before" } Incident Occurrence`
            }
        }
    }
    const createTooltip = (item: any, enableShadow: boolean = false) => {
        const node = item
        const sx: any = {maxWidth: 400, m: 'auto', mb: 1}
        const classNameStr: string = "border-2 border-blue-900 rounded-lg"
      
        const boxShadow: any = enableShadow? {textAlign:'left', width:'100%'} : {height:'100%'};
        if (node) {
            if (node?.d?.type) {
                switch (node.d.type) {
                    case("PERSON_NODE"):
                        return (
                            <div>
                                <Card className={classNameStr} sx={sx} style={boxShadow}>
                                    <CardContent>
                                        <div><span className="font-bold">First Name: </span> {node?.d?.data?.FName}
                                        </div>
                                        <div><span className="font-bold">Middle Name: </span> {node?.d?.data?.MName}
                                        </div>
                                        <div><span className="font-bold">Last Name: </span> {node?.d?.data?.LName}</div>
                                        <div><span className="font-bold">DOB: </span> {formatDate(node?.d?.data?.DOB)} </div>
                                        <div><span className="font-bold">SEX: </span> {node?.d?.data?.SEX} </div>
                                        <div><span className="font-bold">ADDRESS: </span> {node?.d?.data?.Address}</div>
                                        <div><span className="font-bold">CITY: </span> {node?.d?.data?.City} </div>
                                        <div><span className="font-bold">STATE: </span> {node?.d?.data?.State} </div>
                                        <div><span className="font-bold">FBI: </span> {node?.d?.data?.FBI} </div>
                                        <div><span className="font-bold">SBI: </span> {node?.d?.data?.SBINo} </div>
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;

                    case("Report"):
                        return (
                            <div>
                                <Card className={classNameStr} sx={sx} style={boxShadow}>
                                    <CardContent>
                                        <div><span className="font-bold">ALERT TYPE: </span> {node?.d?.data?.NIBINHit? 'CONFIRMED HIT':'POTENTIAL LEAD'}</div>
                                        <div><span className="font-bold">ALERT CATEGORY: </span> {
                                            node?.d?.data?.AssociatedEvidences &&
                                            node?.d?.data?.AssociatedEvidences?.AssociatedWeapon &&
                                            node?.d?.data?.AssociatedEvidences?.AssociatedWeapon?.length > 0 ? 'RECOVERED GUN':'UNRECOVERED GUN'
                                        }</div>
                                        <div><span className="font-bold">LAB: </span> {node?.d?.data?.Lab}</div>
                                        <div><span className="font-bold">REPORT #: </span> {node?.d?.data?.ReferenceNumber}</div>
                                        <div><span className="font-bold">REPORT DATE: </span> {formatDate(node?.d?.data?.EnteredDateTime)}</div>
                                        <div><span className="font-bold">EXAMINER: </span> {node?.d?.data?.Examiner}</div>
                                        <div><span className="font-bold">REVIEWER: </span> {node?.d?.data?.Reviewer}</div>
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    
                    case("Incident"):
                        return (
                            <div>
                                <Card className={classNameStr} sx={sx} style={boxShadow}>
                                    <CardContent>
                                        <div><span className="font-bold">TYPE: </span> {node?.d?.data?.Inc_Type}</div>
                                        {node.d.data.Inc_RelatedArea && <div><span className="font-bold">CASE EVENT TYPE:</span> {node.d.data.Inc_RelatedArea}</div>}
                                        <div><span className="font-bold">LAB: </span> {node?.d?.data?.Lab} / {node?.d?.data?.LabNumber}</div>
                                        <div><span className="font-bold">AGENCY: </span> {node?.d?.data?.Dept} / {node?.d?.data?.CaseNumber}</div>
                                        <div><span className="font-bold">OCCURRENCE: </span> {formatDT(node?.d?.data?.OccurrenceDate)}</div>
                                        <div><span className="font-bold">ADDRESS: </span> {node?.d?.data?.Inc_Location}</div>
                                        <div><span className="font-bold">CITY: </span> {node?.d?.data?.Inc_City}</div>
                                        <div><span className="font-bold">STATE: </span> {node?.d?.data?.Inc_State}</div>
                                        {node.d.data.Weapon && node.d.data.Weapon?.length > 0 && <div><span className="font-bold">TOTAL FIREARMS RECOVERED:</span> {node.d.data.Weapon?.length}</div>}
                                        {node.d.data.ReportChainList && node.d.data.ReportChainList?.length > 0 && <div><span className="font-bold">OTHER HIT REPORTS:</span> {node.d.data.ReportChainList.map((r:any)=>
                                            <a href={`/nibin_report_timeline?ReportID=${r.ReportID}&L=SNAKE`}>
                                                &nbsp;{r.Lab}/{r.ReferenceNumber}
                                            </a>
                                        )}</div>}

                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;

                    case("Arrest"):
                        return (
                            <div>
                                <Card className={classNameStr} sx={sx} style={boxShadow}>
                                    <CardContent>
{/*
                                        <div><span className="font-bold">LAB: </span> {node?.d?.inc_data?.Lab} / {node?.d?.inc_data?.LabNumber}</div>
*/}
                                        <div><span className="font-bold">AGENCY: </span> {node?.d?.inc_data?.Dept} / {node?.d?.inc_data?.CaseNumber}</div>
                                        <div><span className="font-bold">TYPE: </span> {node?.d?.inc_data?.Inc_Type}</div>
                                        {node.d.data.Role && <div><span className="font-bold">ROLE:</span> {node.d.data.Role}</div>}
                                        <div><span className="font-bold">NAME: </span> {node?.d?.data?.FIRSTNAME} {node?.d?.data?.LASTNAME}</div>
                                        {node.d.data.DOB && <div><span className="font-bold">DOB:</span> {formatDate(node.d.data.DOB)}</div>}
                                        {node.d.data.FBI && <div><span className="font-bold">FBI:</span> {node.d.data.FBI}</div>}
                                        {node.d.data.SBI && <div><span className="font-bold">SBI:</span> {node.d.data.SBI}</div>}
                                        <div><span className="font-bold">ARREST DATE: </span> {formatDate(node?.d?.data?.DTOFARREST)}</div>
                                        <div><span className="font-bold">CHARGE(S): </span>
                                            <ul>
                                                {node?.d?.data?.STATUTE?.split(',')?.map((s:any, idx: any)=> (
                                                    <li><b>*</b> {s} <br /></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                        

                    case("Weapon Collected & Lab Submission"):
                    case("Weapon Lab Submission"):
                    case("Weapon Collected"):
                        return (
                            <div>
                                <Card className={classNameStr} sx={sx} style={boxShadow}>
                                    <CardContent>
                                        <div><span className="font-bold">EVENT: </span> {node?.t}</div>
                                        <div><span className="font-bold">LAB: </span> {node?.d?.inc_data?.Lab} / {node?.d?.inc_data?.LabNumber}</div>
                                        <div><span className="font-bold">AGENCY: </span> {node?.d?.inc_data?.Dept} / {node?.d?.inc_data?.CaseNumber}</div>
                                        {node.d.data.CALIBER && <div><span className="font-bold">CALIBER:</span> {node.d.data.CALIBER}</div>}
                                        {node.d.data.MAKE && <div><span className="font-bold">MAKE:</span> {node.d.data.MAKE}</div>}
                                        {node.d.data.W_CLASS && <div><span className="font-bold">CLASS:</span> {node.d.data.W_CLASS}</div>}
                                        {node.d.data.MODEL && <div><span className="font-bold">MODEL:</span> {node.d.data.MODEL}</div>}
                                        {node.d.data.SERIAL_NUMBER && <div><span className="font-bold">SERIAL #:</span> {node.d.data.SERIAL_NUMBER}</div>}
                                        {node.d.data.EvidenceMarker && <div><span className="font-bold">EVIDENCE MARKER:</span> {node.d.data.EvidenceMarker}</div>}
                                        {node.d.data.PreviousHit && <div><span className="font-bold">HIT REF #:</span> {node.d.data.PreviousHit}</div>}
                                        {node.d.data.SearModifyFlag && node.d.data.SearModifyFlag === true && <div><span className="font-bold">SEAR MODIFIED</span> </div>}
                                        {node.d.data.CT_Ghost_Gun && node.d.data.CT_Ghost_Gun === true && <div><span className="font-bold">GHOST GUN</span> </div>}

                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("Weapon Recovery Summary"):
                        return (
                            <div>
                                <Card className={classNameStr} sx={sx} style={boxShadow}>
                                    <CardContent>
                                        <div><span className="font-bold">WEAPON RECOVERY SUMMARY</span></div>
                                        {node.ttc && <div><span className="font-bold">TIME TO CRIME:</span> {node.ttc}</div>}
                                        <div><span className="font-bold">TIME TO RECOVERY: </span> {node?.day_diff}</div>
                                        <div><span className="font-bold"># OF INCIDENTS: </span> {node?.d?.inc_count}</div>
                                        <div><span className="font-bold"># OF ASSOCIATED ITEMS: </span> {node?.d?.bE_Sum}</div>
                                        {node.d.data.CALIBER && <div><span className="font-bold">CALIBER:</span> {node.d.data.CALIBER}</div>}
                                        {node.d.data.MAKE && <div><span className="font-bold">MAKE:</span> {node.d.data.MAKE}</div>}
                                        {node.d.data.W_CLASS && <div><span className="font-bold">CLASS:</span> {node.d.data.W_CLASS}</div>}
                                        {node.d.data.MODEL && <div><span className="font-bold">MODEL:</span> {node.d.data.MODEL}</div>}
                                        {node.d.data.SERIAL_NUMBER && <div><span className="font-bold">SERIAL #:</span> {node.d.data.SERIAL_NUMBER}</div>}
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("Ballistic Evidence Collected & Lab Submission"):
                    case("Ballistic Evidence Lab Submission"):
                    case("Ballistic Evidence Collected"):
                        return (
                            <div>
                                <Card className={classNameStr} sx={sx} style={boxShadow}>
                                    <CardContent>
                                        <div><span className="font-bold">EVENT: </span> {node?.t}</div>
                                        <div><span className="font-bold">LAB: </span> {node?.d?.inc_data?.Lab} / {node?.d?.inc_data?.LabNumber}</div>
                                        <div><span className="font-bold">AGENCY: </span> {node?.d?.inc_data?.Dept} / {node?.d?.inc_data?.CaseNumber}</div>
                                        {node.d.data.VGID_CPart && <div><span className="font-bold">TYPE:</span> DISCHARGED {node.d.data.VGID_CPart}</div>}
                                        {node.d.data.Item_Quantity && <div><span className="font-bold">QUANTITY:</span> {node.d.data.Item_Quantity}</div>}
                                        {node.d.data.Headstamp && <div><span className="font-bold">HEADSTAMP:</span> {node.d.data.Headstamp}</div>}
                                        {node.d.data.Caliber_Type && <div><span className="font-bold">CALIBER:</span> {node.d.data.Caliber_Type}</div>}
                                        {node.d.data.EvidenceMarker && <div><span className="font-bold">EVIDENCE MARKER:</span> {node.d.data.EvidenceMarker}</div>}
                                        {node.d.data.CL_LG && <div><span className="font-bold">CL LG:</span> {node.d.data.CL_LG}</div>}
                                        {node.d.data.CL_Twist && <div><span className="font-bold">CL TWIST:</span> {node.d.data.CL_Twist}</div>}
                                        {node.d.data.PreviousHit && <div><span className="font-bold">HIT REF #:</span> {node.d.data.PreviousHit}</div>}
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("ETrace Person"):
                        return (
                            <div>
                                <Card className={classNameStr} sx={sx} style={boxShadow}>
                                    <CardContent>
                                        {/*<div><span className="font-bold">LAB: </span> {node?.d?.inc_data?.Lab} / {node?.d?.inc_data?.LabNumber}</div>*/}
                                        {/*<div><span className="font-bold">AGENCY: </span> {node?.d?.inc_data?.Dept} / {node?.d?.inc_data?.CaseNumber}</div>*/}
                                        <div><span className="font-bold">ROLE: </span> {node?.d?.data?.ROLE}</div>
                                        <div><span className="font-bold">NAME: </span> {node?.d?.data?.FIRSTNAME}  {node?.d?.data?.LASTNAME}</div>
                                        {node.d.data.DOB &&  <div><span className="font-bold">DOB: </span> {formatDate(node?.d?.data?.DOB)}</div>}
                                        <div><span className="font-bold">ADDRESS: </span> {node?.d?.data?.INDIV_STREET_NUMBER} {node?.d?.data?.INDIV_STREET_NAME} {node?.d?.data?.INDIV_STREET_SUFFIX} {node?.d?.data?.INDIV_CITY} {node?.d?.data?.INDIV_STATE}</div>
                                        {node.d.data.WPN_MAKE && <div><span className="font-bold">WEAPON MAKE:</span> {node.d.data.WPN_MAKE}</div>}
                                        {node.d.data.WPN_SERIAL && <div><span className="font-bold">WEAPON SERIAL #:</span> {node.d.data.WPN_SERIAL}</div>}
                                        {node.d.data.WPN_CAL && <div><span className="font-bold">WEAPON CALIBER:</span> {node.d.data.WPN_CAL}</div>}
                                        {node.d.data.WPN_MDL && <div><span className="font-bold">WEAPON MODEL:</span> {node.d.data.WPN_MDL}</div>}

                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("ETrace Dealer"):
                        return (
                            <div>
                                <Card className={classNameStr} sx={sx} style={boxShadow}>
                                    <CardContent>
                                        {/*<div><span className="font-bold">LAB: </span> {node?.d?.inc_data?.Lab} / {node?.d?.inc_data?.LabNumber}</div>*/}
                                        {/*<div><span className="font-bold">AGENCY: </span> {node?.d?.inc_data?.Dept} / {node?.d?.inc_data?.CaseNumber}</div>*/}
                                        
                                        <div><span className="font-bold">FFL NUMBER: </span> {node?.d?.data?.DEALER_FFL_NUMBER}</div>
                                        <div><span className="font-bold">DEALER NAME: </span> {node?.d?.data?.DEALER_NAME}</div>
                                        {node.d.data.DEALER_SHIP_DATE &&  <div><span className="font-bold">SHIP DATE: </span> {formatDate(node?.d?.data?.DEALER_SHIP_DATE)}</div>}
                                        <div><span className="font-bold">ADDRESS: </span> {node?.d?.data?.DEALER_STREET} {node?.d?.data?.DEALER_CITY} {node?.d?.data?.DEALER_STATE} {node?.d?.data?.DEALER_ZIP}</div>
                                        {node.d.data.WPN_MAKE && <div><span className="font-bold">WEAPON MAKE:</span> {node.d.data.WPN_MAKE}</div>}
                                        {node.d.data.WPN_SERIAL && <div><span className="font-bold">WEAPON SERIAL #:</span> {node.d.data.WPN_SERIAL}</div>}
                                        {node.d.data.WPN_CAL && <div><span className="font-bold">WEAPON CALIBER:</span> {node.d.data.WPN_CAL}</div>}
                                        {node.d.data.WPN_MDL && <div><span className="font-bold">WEAPON MODEL:</span> {node.d.data.WPN_MDL}</div>}
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    default:
                        return (<></>)
                        break;
                }
            }

        } else {
            return (<></>)
        }
    }



    return (
        <>

            {layout === 'HORIZONTAL' ? <>
                <ol className="items-center sm:flex" style={{margin: '1rem 2rem 2rem 2rem', padding:'2rem'}} >
                    {flag && data.items?.map((node : any, index : any) => (
                        <li className="relative mb-6 sm:mb-0" style={{minWidth:450, height:'60vh'}} key={index}>
                            <div className={"text-center text-xs text-blue-900 font-bold"}>
                                {index !== firstIncidentIdx ? <>
                                    {(node?.day_diff != 0? (node?.day_diff < 0? -1  * node?.day_diff: node?.day_diff) + (node?.day_diff>1 || node?.day_diff<-1 ? ' Days':' Day'): 'Same Day')} {diffExplain(node, node?.day_diff)}
                                </>:<></>}
                            </div>
                            <div className="flex items-center">

                                <div className="z-10 flex items-center justify-center w-9 h-9">
                                    {node?.u ? <img src={node?.u} alt={""} style={{height:'60'}}/>
                                        : <svg className="w-10 h-10 text-blue-800 dark:text-blue-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"/>
                                        </svg> }
                                </div>


                                <div className="hidden sm:flex justify-center w-full bg-gray-200 h-0.5 dark:bg-gray-700"></div>
                            </div>
                            <div className="mt-3 sm:pr-8">
                                <time className="block mb-2 text-sm leading-none text-blue-900 dark:text-gray-500 text-center font-bold">
                                    {node?.dt ? formatDate(node?.dt) : node?.dt1? formatDate(node?.dt1) : ""}
                                </time>
                                {createTooltip(node)}
                            </div>
                        </li>
                    ))}
                </ol>
            </> : <>
                <div style={{width:'80%', margin:'auto'}}>
                    <div className="steps-list-looped">
                        {flag && data.items?.map((node : any, index : any) => (
                            <div className="step-wrapper" key={index}>
                                <div className="step-content">
                                    <h2 className="step-number-box">
                                        
                                        <span>
                                            <img src={node?.u} alt={""} className={node.d.type === "Weapon Collected" ? "bg-green-500 rounded-full": ""} style={{height:'50px', display:'inline-block', marginRight:'5px', padding: '5px'}}/>
                                            <span>
                                                {formatDate(node?.dt)} <br />
                                                {index !== firstIncidentIdx ? <>
                                                    {(node?.day_diff != 0? (node?.day_diff < 0? -1  * node?.day_diff: node?.day_diff) + (node?.day_diff>1 || node?.day_diff<-1 ? ' Days':' Day'): 'Same Day')} {diffExplain(node, node?.day_diff)}
                                                </>:<></>}
                                            </span>
                                        </span> <br />
                                        

                                        {createTooltip(node, true)}

                                    </h2>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </>}
        </>
    );
}

export default NIBINTimeLine;