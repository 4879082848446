import React, { useState, useEffect } from "react";
import { Button, ButtonGroup, FormControl, TextField } from "@mui/material";
import {getAuditLogAction, getAuditLogDept, getAuditLogUser} from "../../services/getDropdown.service";
import {formatInputDate} from "../../services/formatDate.service";
import {Account} from "../../interfaces/auth_interface";
import {getUser} from "../../services/auth.service";
import { MultiSelect } from 'primereact/multiselect';
import { TreeSelect } from 'primereact/treeselect';

interface AuditLogSearchProps {
    SubmitSearch: any
}

const AuditLogSearch: React.FC<AuditLogSearchProps> = ({ SubmitSearch }) => {
    let today:Date = new Date();
    var toDate = new Date();
    var prevDate = new Date();
    var fromday = new Date();
    if (toDate.getDay() === 1) {
        prevDate = new Date(toDate.setDate(toDate.getDate() - 2));
        fromday = prevDate //Default - Previous weekend
    }
    else
        fromday = toDate;
    let [creds, setCreds] = useState<Account>(getUser())
    const [params, setParams] = useState<any>({ FromDate: formatInputDate(fromday), ToDate: formatInputDate(today), Action: [], EventType: "", Message: "", EmpID:[], Dept:[] })
    const [clear, setClear] = useState<number>(0);
    const [initialRun, setInitialRun] = useState<boolean>(false)
    
    const [entireEmpList, setEntireEmpList] = useState<any>([])
    const [empList, setEmpList] = useState<any>([])
    const [actionsList, setActionsList] = useState<any>([])
    const [actionsExpanded, setActionsExpanded] = useState<any>([])
    const [deptsList, setDeptsList] = useState<any>([])

    useEffect(() => {
        if (!initialRun) {
            setInitialRun(true)
            handleDropDowns()
        }
    });    
    
    const handleDropDowns = async () => {
        let temp = {...params}
        let tDEPTList: any[] | null | undefined = []
        getAuditLogAction().then((res) => {
            let tempActions:any[] = []
            let defaultExpansion:any = {}
            if(res) {
                for (const [key, options] of Object.entries(res)) {
                    let actionItem: any = {
                        key: "BT_ACTION " + key,
                        label: key,
                        children: []
                    }
                    defaultExpansion[actionItem.key] = true
                    for (const item of (options as any)) {
                        actionItem.children.push({
                            key: item.Key,
                            label: item.Value,
                        })
                    }

                    tempActions.push(actionItem)
                }
                setActionsList(tempActions)
                setActionsExpanded(defaultExpansion)
            }
        })
        await getAuditLogDept().then((res) => {
            setDeptsList(res)
            temp.Dept = [creds.Dept];
            tDEPTList = res 
        })
        await getAuditLogUser().then((res) => {
            let tempUsers:any[] = []
            for (const [key, options] of Object.entries(res)) {
                let empItem: any = {
                    key:"BT_DEPT_NAME " + key,
                    label:key,
                    children:[]
                }
                for (const item of (options as any)) {
                    if(empItem?.key?.includes("BT_DEPT_NAME") && (item?.Additional && item?.Additional?.length > 0))
                        empItem.key = "BT_DEPT_CODE " + item.Additional 
                    
                    empItem.children.push({
                        key: item.Key,
                        label: item.Value + ' (' + item.Key + ')', 
                    })
                }
                
                tempUsers.push(empItem)
            }
            setEmpList(tempUsers)
            setEntireEmpList(tempUsers)
            handleEmpListFilter([creds.Dept ?? ''], tempUsers, tDEPTList)
        })
        setParams(temp)
        SubmitSearch(temp)
    }
    
    const handleEmpListFilter = (deptCodes:string[], empList:any[] | null = null, deptList:any[] | null = null) => {
        let tEMPList = [...entireEmpList]
        let filteredEmpList: any[] = []
        
        if(empList)
            tEMPList = empList
        for(let deptCode of deptCodes){
            filteredEmpList = filteredEmpList.concat(tEMPList.filter((e:any) => { 
                let dCode = e?.key?.split('BT_DEPT_CODE')[1]
                return(dCode?.trim() === deptCode?.trim()) 
            }))
            setEmpList(filteredEmpList)
        }
    }
    
    
    const handleClear = () => {
        setParams({ ...params, FromDate: formatInputDate(fromday), ToDate: formatInputDate(today), Action: [], EventType: "", Message: "", EmpID:[], Dept:[creds.Dept] })
        setClear(clear + 1)
    }
    const handleSearch = () => {
        let temp = {...params}
        temp.EmpID = temp.EmpID ? Object?.keys(temp.EmpID)?.filter((key) => !key?.includes('BT_DEPT_CODE')) : []
        temp.Action = temp.Action ? Object?.keys(temp.Action)?.filter((key) => !key?.includes('BT_ACTION')) : []
        SubmitSearch(temp)
    }

    const getValueTemplate = (selected: any, ddType:string) => {

        if(selected && selected.length > 0) {
            let count = 0;
            for (const item of selected) {
                if(!item || !item?.key?.includes('BT_ACTION') || !item?.key?.includes('BT_DEPT_CODE'))
                    count++;
            }
            return (count + " selected");
        }
        else
            return (ddType)
    }
    
    return (
        <div className="bg-gray-100 ml-5 mr-5 rounded pl-5 pr-5 pb-5 pt-8">
            <div className="grid grid-cols-7 gap-x-2 gap-y-2">
                <div>
                    <TextField variant="outlined" margin='none' type={"date"} fullWidth
                               size={"small"} label="From Date"
                               InputLabelProps={{shrink: true}}
                               inputProps={{style: {textAlign: 'center'}}}
                               value={params.FromDate}
                               onChange={(e: any) => {
                                   setParams({...params, FromDate: e.target.value})
                               }}
                    />
                </div>
                <div>
                    <TextField variant="outlined" margin='none' type={"date"} fullWidth
                               size={"small"} label="To Date"
                               InputLabelProps={{shrink: true}}
                               inputProps={{style: {textAlign: 'center'}}}
                               value={params.ToDate}
                               onChange={(e: any) => {
                                   setParams({...params, ToDate: e.target.value})
                               }}
                    />
                </div>
                <div>
                    <FormControl className="p-float-label" style={{width: '100%'}}>
                        <TreeSelect value={params.Action} options={actionsList} filter dropdownIcon={"pi none"}
                                    resetFilterOnHide={true}
                                    onChange={(e) => { setParams({...params, Action: e.value}) }}
                                    selectionMode="checkbox" showClear
                                    placeholder="Action"
                                    inputId={"dd-action"}
                                    style={{border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start'}}
                                    valueTemplate={(option: any) => getValueTemplate(option, 'Action')}
                                    expandedKeys={actionsExpanded}
                                    onToggle={(e) => {setActionsExpanded(e.value)}}
                        ></TreeSelect>
                        <label className={"bg-gray-100"} htmlFor="dd-action">Action</label>
                    </FormControl>
                </div>
                <div>
                    <FormControl className="p-float-label" style={{width: '100%'}}>
                        <MultiSelect
                            value={params.Dept}
                            onChange={(e) => {
                                setParams({...params, Dept: e.value});
                                handleEmpListFilter(e.value)
                            }}
                            style={{border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start'}} 
                            resetFilterOnHide={true} showClear={true} 
                            inputId={"dd-dept"} options={deptsList} filter optionLabel="Value" optionValue="Key"
                            placeholder="Department" maxSelectedLabels={1} className="w-full md:w-20rem"/>
                        <label className={"bg-gray-100"} htmlFor="dd-dept">Department</label>
                    </FormControl>
                </div>
                <div>
                    <FormControl className="p-float-label" style={{width: '100%'}}>
                        <TreeSelect value={params.EmpID} options={empList} filter dropdownIcon={"pi none"}
                                    resetFilterOnHide={true}
                                    onChange={(e) => { setParams({...params, EmpID: e.value}) }}
                                    selectionMode="checkbox" showClear
                                    placeholder="Employee ID"
                                    inputId={"dd-emp"}
                                    style={{border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start'}}
                                    valueTemplate={(option: any) => getValueTemplate(option, 'Employee ID')}
                        ></TreeSelect>
                        <label className={"bg-gray-100"} htmlFor="dd-emp">Employee ID</label>
                    </FormControl>
                </div>
            </div>


            <div className={"pt-3"} style={{"display": "flex", "justifyContent": "end"}}>
                <ButtonGroup size="large" color="inherit">
                    <Button type="submit" onClick={handleSearch}>Search</Button>
                    <Button onClick={handleClear}>CLEAR</Button>
                </ButtonGroup>
            </div>
        </div>
    );
}

export default AuditLogSearch;