import {PersonInfo, ProfileReportPersonInfo} from "../../../interfaces/getDarInfo_interface";
import React from "react";
import { Disclosure } from '@headlessui/react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {CircularProgress, Button, Avatar} from "@mui/material";
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent ,
    Inject,
    Page,
    Sort,
    Filter,
} from '@syncfusion/ej2-react-grids';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import { green } from '@mui/material/colors';
import {JAOS} from "../../../services/JAOS.service";
import {isUserPermission} from "../../../services/auth.service";

interface PanelProfileReportProps {
    person: PersonInfo,
    ProfileReport: ProfileReportPersonInfo[],
    isLoading:boolean
}


const PanelProfileReport: React.FC<PanelProfileReportProps> = ({person, ProfileReport, isLoading})=> {
    const [FormatData, setFormatData] = React.useState(0);

    const handleDataTyping = () => {
        let tempP: any = [...ProfileReport]
        for (let p of tempP) {
            if (p.DOB) {
                p.DOB = new Date(p.DOB)
            }
            if (p.ReportDate) {
                p.ReportDate = new Date(p.ReportDate)
            }
            if(p.Pic) {
                p.Pic = "data:image/jpeg;base64," + p.Pic;
            }
            if(p.Status) {
                switch(p.Status) {
                    case ('InProgress'):
                        p.Status = 'In Progress'
                        break;
                    case ('ForReview'):
                        p.Status = 'For Review'
                        break;
                }
            }
        }
        setFormatData(tempP)
    }

    React.useEffect(() => {
        handleDataTyping()
    }, [ProfileReport])

    const handleProfileReportView = (p:any) => {
        let temp : any = {
            PersonInfo: person,
            ID: p.ID,
        }
        let l = new JAOS()
        let hash = l.objToStack(temp);
        window.open('/tools/profile_report/?person=' + hash);
    }
    
    return(
        <div className={"m-5 border"}>
            <Disclosure defaultOpen={false}>
                {({ open }) => (
                    <>
                        <Disclosure.Button className="bg-blue-100 p-2 w-auto w-full text-left">                    
                            <span style={{ "display": "flex", "justifyContent": "space-between" }}>
                                <span>
                                    <KeyboardArrowDownIcon
                                        className={`${open ? "transform rotate-180" : ""}`}
                                    />
                                    <span className={"font-bold"}>Profile Report <Avatar  sx={{ bgcolor: green[700], height:30, width:30, marginRight:4, display:"-webkit-inline-box" }}><ContactPageOutlinedIcon /></Avatar> {(isLoading) ? <CircularProgress size={20} style={{"marginLeft" : "5px"}} /> : <></>}</span>
                                </span>
                                <span className={"font-bold mr-2"} >Name: {person?.FName?.toUpperCase()} {person?.MName?.toUpperCase()} {person?.LName?.toUpperCase()} &nbsp;&nbsp;Records: {ProfileReport?.length}</span>
                           </span>
                        </Disclosure.Button>

                        <Disclosure.Panel className="text-gray-500 p-4">

                            <GridComponent
                                dataSource={FormatData}
                                allowPaging={true}
                                pageSettings={{pageSize: 50, pageSizes: [10,25,50,100]}}
                                allowSorting={true}
                                allowMultiSorting={true}
                                allowFiltering={true}
                                filterSettings={{type: 'CheckBox'}}
                                allowTextWrap={true}
                                textWrapSettings={{ wrapMode: 'Both' }}
                                rowHeight={24}
                            >
                                <ColumnsDirective>
                                        <ColumnDirective field='FName'  headerText='First Name' customAttributes={{ class: ['e-attr']}}/>
                                        <ColumnDirective field='MName' headerText='MI' customAttributes={{ class: ['e-attr'] }}/>
                                        <ColumnDirective field='LName' headerText='Last Name' customAttributes={{ class: ['e-attr'] }}/>
                                        <ColumnDirective field='ReportDate' format={{ type: 'date', format: 'MM/dd/yyyy' }} headerText='Report Date' customAttributes={{ class: ['e-attr'] }} />
                                        <ColumnDirective field='ReportType' headerText='Type' customAttributes={{ class: ['e-attr'] }} />
                                        <ColumnDirective field='ReportNum' headerText='Report #' customAttributes={{ class: ['e-attr'] }} />
                                        {/* <ColumnDirective field='Status' headerText='Status' customAttributes={{ class: ['e-attr'] }} /> */}
                                    {isUserPermission('Arrest') ? <ColumnDirective template={(item : any ) => (<Button onClick={ (e:any) => {handleProfileReportView(item)} } >View</Button>)}  headerText='View' /> : <></>}
                                </ColumnsDirective>
                                <Inject services={[Sort, Page, Filter]}/>
                            </GridComponent>

                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>

    )
};

export default PanelProfileReport;