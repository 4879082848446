import React, { useState } from "react";
import { Account } from "../../../interfaces/auth_interface";
import { getUser, isUserPermission } from "../../../services/auth.service";
import {
  GetStolenVehicleDashboard,
  RunGetDailyStats,
} from "../../../services/dashboard.service";
import { getDarInfoResponse } from "../../../interfaces/getDarInfo_interface";
import { Category } from "@mui/icons-material";
import NJDashboardLayout from "../../../components/Dashboard/NJPort/_njLayout";
import {
  NJPortDashboardDataApi,
  PDohireDashboardDataApi,
  VolunteerDashboardDataApi,
} from "../../../services/njport.dashboard.service";

function NJDashboard() {
  let [user, setUser] = useState<Account>(getUser());
  const [data, setData] = useState<any>({});
  const [njPortData, setNJPortData] = useState<any>([]);
  const [volunteerData, setVolunteerData] = useState<any>([]);
  const [pDohire, setPDohire] = useState<any>([]);
  const [queryList, setQueryList] = useState<string[]>([
    "SHIPENTRY_HEATMAP",
    // "VOLUNTEERHIRE_MAIN",
    // "PDOHIRE_MAIN",
  ]);

  const GetStats = async (params: any) => {
    for (let i of queryList) {
      let temp = { ...params };
      temp.queryType = i;
      console.log(temp.queryType);
      switch (temp.queryType) {
        case "SHIPENTRY_HEATMAP":
          NJPortDashboardDataApi(temp).then((qRes) => {
            setNJPortData(qRes);
          });
          break;
        case "VOLUNTEERHIRE_MAIN":
          // VolunteerDashboardDataApi(temp).then((qRes) => {
          //   setVolunteerData(qRes);
          // });
          break;
        case "PDOHIRE_MAIN":
          // PDohireDashboardDataApi(temp).then((qRes) => {
          //   setPDohire(qRes);
          // });
          break;
      }
    }
  };

  React.useEffect(() => {
    handleDashboardData();
  }, [njPortData, volunteerData, pDohire]);

  const handleDashboardData = () => {
    let data: any = {};
    data.njPortData = njPortData;
    data.volunteerData = volunteerData;
    data.pDohire = pDohire;
    setData(data);
  };

  return (
    <div>
      {isUserPermission("Arrest") ? (
        <>
          <NJDashboardLayout GetStats={GetStats} data={data} />
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default NJDashboard;
