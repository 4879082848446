import React from "react";
import { useLocation } from "react-router-dom";
import PrisonReleaseDetailInfo from "../../components/Detail/_PrisonReleaseDetailInfo";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

  
function PrisonReleaseDetail() {
    let query = useQuery();

    return (
        <div>
            <PrisonReleaseDetailInfo ID={query.get("ID")} department={query.get("Dept")}/>
        </div>
    );
}

export default PrisonReleaseDetail;