import React, {useState} from "react";
import ReactECharts from "echarts-for-react";

interface MurdersDashboardGridProps {
    location:string,
    dbData:any,
}


const MurdersDashboardGrid: React.FC<MurdersDashboardGridProps> = ({location,dbData}) => {
    var option;
    let curYear = new Date().getFullYear().toString()
    let prevYear = (new Date().getFullYear() -1).toString()

    const handleXAxis = () => {
        let xAxis:any = new Set()
        if(dbData?.TrendData)
        {
           
            for(let x of dbData?.TrendData)
            {
                let date = new Date(x.Date)
                let fullDate = (date.getMonth() + 1).toString() + '/' + date.getDate().toString()
                xAxis.add(fullDate)
            }
        }

        return Array.from(xAxis)
    }

    const handleSeries = () => {
        let series: any = []
        let curYearBar = []
        let prevYearBar = []
        let twoYearsAgoBar = []
        let curSum:any = []
        let prevSum:any = []
        let twoYearAgoSum:any = []

        if(dbData?.TrendData)
        {
            let today = new Date().getFullYear()
            for(let x of dbData?.TrendData)
            {
                let date = new Date(x.Date).getFullYear()
                switch(date.toString())
                {
                    case today.toString():
                        curYearBar.push(x.Count)
                        if(curSum.length === 0)
                            curSum.push(x.Count)
                        else 
                            curSum.push(curSum[curSum.length -1] + x.Count)
                        break;
                    case (today -1).toString():
                        prevYearBar.push(x.Count)
                        if(prevSum.length === 0)
                            prevSum.push(x.Count)
                        else
                            prevSum.push(prevSum[prevSum.length -1] + x.Count)
                        break;
                    case (today -2).toString():
                        twoYearsAgoBar.push(x.Count)
                        if(twoYearAgoSum.length === 0)
                            twoYearAgoSum.push(x.Count)
                        else
                            twoYearAgoSum.push(twoYearAgoSum[twoYearAgoSum.length -1] + x.Count)
                        break;
                }
            }
            // series.push({
            //     name: today.toString() + ' Daily',
            //     type: 'bar',
            //     data: curYearBar,
            //     color:'#1e3a8a',
            // },)
            series.push({
                name: today.toString() + ' Accumulative',
                type: 'line',
                data: curSum,
                color:'#2c51b9',
            },)
            // series.push({
            //     name: (today -1).toString() + ' Daily',
            //     type: 'bar',
            //     color: '#FEBE00',
            //     data: prevYearBar
            // },)
            series.push({
                name: (today -1).toString() + ' Accumulative',
                type: 'line',
                data: prevSum,
                color: '#fcbd24',
            },)
            // series.push({
            //     name: (today -2).toString() + ' Daily',
            //     type: 'bar',
            //     color: '#8E1600',
            //     data: twoYearsAgoBar,
            // },)
            series.push({
                name: (today -2).toString() + ' Accumulative',
                type: 'line',
                data: twoYearAgoSum,
                color: '#c21d00',
            },)
        }
        return series
    }

    option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                animation: true
            }
        },
        height: 165,
        toolbox: {
            feature: {
                saveAsImage: { show: true }
            }
        },
        legend: {
        },
        grid: {
            left: '3%',
            right: '3%',
            top: '15%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            boundaryGap: true,
            data: handleXAxis()
        },
        yAxis: [
            {
                type: 'value',
                name: 'Shootings',
            }
        ],
        series: handleSeries()
    };




    return (
        <div className = "border-gray-900 border-2 h-64">
            <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg">Yearly Shooting Trends</div>
            {dbData && dbData.length !== 0 ?
                <div className = "border-gray-900 border-t-2">
                    <ReactECharts option={option} />
                </div>
            :
                <div>No Data</div>
            }
        </div>
    );
}

export default MurdersDashboardGrid;