import React from 'react';

const getFileTypeFromBase64 = (base64: string | undefined) => {
  if (!base64) return;
  const mimeTypeMatch = base64.match(/data:([^;]+);base64,/);
  return mimeTypeMatch ? mimeTypeMatch[1] : "";
};

const FilePreview = ({ base64Data }: { base64Data: string | undefined }) => {
  const fileType = getFileTypeFromBase64(base64Data);

  if (!base64Data || !fileType) {
    return null;
  }

  const isImage = fileType.startsWith("image/");
  const isPDF = fileType === "application/pdf";
  const isWord = fileType === "application/msword" || fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

  return (
    <div style={{ height: "200px", width: "200px", marginTop: "1rem" }}>
      {isImage && (
        <img
          src={base64Data}
          alt="Preview"
          style={{ width: "200px", height: "200px" }}
        />
      )}
      {isPDF && (
        <embed
          src={base64Data}
          type="application/pdf"
          width="200"
          height="200"
        />
      )}
      {isWord && (
        <div style={{ width: "200px", height: "200px" }}>
          <a href={base64Data} download="document.docx">
            Download Word Document
          </a>
          <p>Word documents cannot be previewed directly in the browser.</p>
        </div>
      )}
      {!isImage && !isPDF && !isWord && (
        <div>
          <p>File type not supported for preview.</p>
          <p>Base64 Data:</p>
          <textarea
            value={base64Data}
            readOnly
            rows={10}
            cols={50}
          ></textarea>
        </div>
      )}
    </div>
  );
};

export default FilePreview;
