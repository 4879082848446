import React from "react";

export const formatDate = (dateString:string | Date | null | undefined) => {
    try{
        if(dateString)
        {
            let date:any = new Date(dateString)
            let year = date.getFullYear();
            let month = (1 + date.getMonth()).toString().padStart(2, '0');
            let day = (date.getDate()).toString().padStart(2, '0');

            return month + '/' + day + '/' + year;
        }
        else{
            return ""
        }
    }catch (e) {
        
    }
    
}

export const  ToISOLocal = (d:Date | null | undefined) => {
    if (!d) return null;
    
    var z:any  = (n: string | number) =>  ('0' + n).slice(-2);
    var zz = (n: string | number) => ('00' + n).slice(-3);
    var off = d?.getTimezoneOffset();
    var sign = off > 0? '-' : '+';
    off = Math.abs(off);

    return d.getFullYear() + '-'
        + z(d.getMonth()+1) + '-' +
        z(d.getDate()) + 'T' +
        z(d.getHours()) + ':'  +
        z(d.getMinutes()) + ':' +
        z(d.getSeconds()) + '.' +
        zz(d.getMilliseconds()) +
        sign + z(off/60|0) + ':' + z(off%60);
}


export const formatInputDate = (dateString:string | Date | null | undefined) => {
    if(dateString)
    {
        let date:any = new Date(dateString)
        let year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, '0');
        let day = (date.getDate()).toString().padStart(2, '0');
        return year + '-' + month + '-' + day  ;
    }
    else{
        return ""
    }
}

export const isNullOrWhiteSpace = (str:string) => {
    console.log(str === null || str.match(/^ *$/) !== null)
    return str === null || str.match(/^ *$/) !== null;
}

export const formatDT = (dateTimeString:string | Date | null | undefined) => {
    if(dateTimeString)
    {
        let date:Date = new Date(dateTimeString)
        let year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, '0');
        let day = (date.getDate()).toString().padStart(2, '0');
        let h = date.getHours().toString().padStart(2,"0")
        let m = date.getMinutes().toString().padStart(2,"0")
        let s = date.getSeconds().toString().padStart(2,"0")
  
        return month + '/' + day + '/' + year + ' ' +  h + ':' + m +':' + s; 
    }
    else{
        return ""
    }
}

export const formatTime = (dateTimeString:string | Date | null | undefined) => {
    if(dateTimeString)
    {
        let date:Date = new Date(dateTimeString)
        let year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, '0');
        let day = (date.getDate()).toString().padStart(2, '0');
        let h = date.getHours().toString().padStart(2,"0")
        let m = date.getMinutes().toString().padStart(2,"0")
        let s = date.getSeconds().toString().padStart(2,"0")

        //return h + ':' + m;
        let res = h + ':' + m;
        return res === "00:00" ? "" : "@ " + res;
    }
    else{
        return ""
    }
}

export const convertLocalTime = (dateTimeString:string | Date | null | undefined, format:boolean = true) => {
    if(dateTimeString)
    {
        let tOff = new Date().getTimezoneOffset()
        let date = new Date(dateTimeString)
        let milliseconds = Date.parse(date.toString())
        let localTime = new Date(milliseconds - (tOff * 60 * 1000))

        if(format)
            return formatDT(localTime)
        else 
            return localTime
    }
    else{
        return ""
    }
}

export const convertUTCtoLocalTimeRange = (utcStartDate: string | null, utcEndDate: string | null): string => {
    if (utcStartDate === null && utcEndDate === null) {
        return '';
    }
    const startDate = utcStartDate ? new Date(utcStartDate) : null;
    const endDate = utcEndDate ? new Date(utcEndDate) : null;
    const localStartDateString = startDate ? startDate.toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }) : '';

    const localEndDateString = endDate ? endDate.toLocaleString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }) : '';

    if (endDate === null) {
        return localStartDateString;
    } else {
        return `${localStartDateString} - ${localEndDateString}`;
    }
}

export const formatPhone = (phoneString:string | null | undefined) => {
    if(phoneString && phoneString?.length > 9)
    {
        return (phoneString.substring(0,3) + '-' + phoneString.substring(3,6) + '-' + phoneString.substring(6,10))
    }
    else
    {
        return phoneString
    }
}

export function DateToString(d:Date) {
    var yyyy = d.getFullYear().toString();
    var mm = (d.getMonth()+1).toString();      
    var dd  = d.getDate().toString();
    var hh = d.getHours().toString();
    var mi = d.getMinutes().toString();
    return yyyy + '-' + (mm[1]?mm:"0"+mm[0]) + '-' + (dd[1]?dd:"0"+dd[0]) + ' '+(hh[1]?hh:"0"+hh[0])+':'+(mi[1]?mi:"0"+mi[0]);
}

export function handleSearchTime(date:Date, type:string){
    if(date){
        let rDate = date;
        if(typeof date === 'string')
        {
            rDate = new Date(rDate + 'T00:00:00');
        }
        if(type === 'fromDate'){
            rDate.setHours(0)
            rDate.setMinutes(0)
            rDate.setSeconds(0)
            return (rDate)
        }
        else
        {
            rDate.setHours(23)
            rDate.setMinutes(59)
            rDate.setSeconds(59)
            return (rDate)
        } 
    }
    return date
}


export const formatSSN = (ssnString: string | null | undefined) => {
    if (ssnString && ssnString?.length === 9) {
        return (ssnString.substring(0, 3) + '-' + ssnString.substring(3, 5) + '-' + ssnString.substring(5))
    }
    else {
        return ""
    }
}

export const hideSSN = (ssnString: string | null | undefined) => {
    if (ssnString && ssnString?.length === 9) {
        return "***-**-" + ssnString.substring(ssnString.length - 4)
    }
    else {
        return ""
    }
}

//Used to get the active window size so width = 1145px height = 1029px etc
export const useMatchMedia = (mediaQuery:any, initialValue:any) => {
    const [isMatching, setIsMatching] = React.useState(initialValue)
    React.useEffect(() => {
        const watcher = window.matchMedia(mediaQuery)
        setIsMatching(watcher.matches)
        const listener = (matches:any) => {
            setIsMatching(matches.matches)
        }
        if (watcher.addEventListener) {
            watcher.addEventListener('change', listener)
        } else {
            watcher.addListener(listener)
        }
        return () => {
            if (watcher.removeEventListener) {
                return watcher.removeEventListener('change', listener)
            } else {
                return watcher.removeListener(listener)
            }
        }
    }, [mediaQuery])

    return isMatching
}

//Used to get DISTINCT values in an array.
export function uniqueArrayItems(value:any, index:number, array:any[]) {
    return array.indexOf(value) === index;
}

export async function readFileAsDataURL(file:any) {
    let result_base64 = await new Promise((resolve) => {
        let fileReader = new FileReader();
        fileReader.onload = (e) => resolve(fileReader.result);
        fileReader.readAsDataURL(file);
    });

    return result_base64;
}