import React, {useState} from "react";
import {getUser, isUserPermission} from "../../services/auth.service";
import {getDarInfoResponse, GetDarInfo_Query, PersonInfo, ProfileReportPersonInfo} from "../../interfaces/getDarInfo_interface";
import {Account} from "../../interfaces/auth_interface";
import {useLocation} from "react-router-dom";
import ProfileReportSearch from "../../components/tools/profileReport/_profileReportSearch";
import SearchResults from "../../components/tools/profileReport/_searchResultsProfileReport";
import { RunDarQuery } from "../../services/getDar.service";
import { JAOS } from "../../services/JAOS.service";
import {handleSearchTime} from "../../services/formatDate.service";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function ProfileReport() {
    let [darTbl, setDarTbl] = useState<ProfileReportPersonInfo[]>([])
    let [user, setUser] = useState<Account>(getUser())
    let query = useQuery();
    let [personData, setPersonData] = useState<any>(null)
    const searchRef = React.useRef<any>(null);
    let [noData, setNoData] = useState<boolean>(false)
    let [initialBuild, setInitialBuild] = useState<boolean>(false)


    React.useEffect(() => {
        if(!initialBuild){
            setInitialBuild(true)
            checkPersonData()
        }
    }, [])



    const checkPersonData = () => {
        let person = query.get("person");
        let data = {};
        if (person) {
            let l = new JAOS()
            data = l.objFromStack(person);
            l.clearStack()
        }
        if (Object.keys(data).length > 0) {
            setPersonData(data)            
        }
        return true;
    }
    
    const SubmitSearch = async (params: any, dateType: string, andOrType: string, dropDowns: any) => {


        let search_query: GetDarInfo_Query = {
                "QueryType": "Q",
                "Dept": user.Dept,
                "EmpID": user.EmpID,
                "DeptORI": user.DeptORI,
                "DateRange": true,
                "DateTypeValue": (dateType === "Entry Date") ? 0 : 1,
                "FromDate": handleSearchTime(params?.fromDate, 'fromDate')?.toLocaleString(),
                "ToDate": handleSearchTime(params?.toDate, 'toDate')?.toLocaleString(),
                "SearchType": andOrType,
                "IndicatorOn": true,
                "TopArrestNumber": 0,
                "FNames": params.FirstName ? params.FirstName.split(',') : [],
                "MNames": params.MI ? params.MI.split(',') : [],
                "LNames": params.LastName ? params.LastName.split(',') : [],
                "DOBs": params.DOB ? params.DOB.split(',') : [],
                "SEX": params.Gender ? params.Gender.split(',') : [],
                "Address": "",
                "Cities": dropDowns.City ? dropDowns.City : [],
                "States": dropDowns.State ? dropDowns.State : [],
                "Zip": "",
                "Statutes": dropDowns.Statute ? dropDowns.Statute : [],
                "Gang": [],
                "FBIs": params.FBI ? params.FBI.split(',') : [],
                "SBIs": params.SBI ? params.SBI.split(',') : [],
                "DLNos": params.DL ? params.DL.split(',') : [],
                "PlateNos": params.Plate ? params.Plate.split(',') : [],
                "VINs": [],
                "AgencyArrested": dropDowns.AgencyArrested ? dropDowns.AgencyArrested : [],
                "InfocopDept": dropDowns.InfoCopDept ? dropDowns.InfoCopDept : [],
                "Indicators": dropDowns.Indicator ? dropDowns.Indicator : [],
                "IndicatorOccurrence": dropDowns.IndicatorOccurrence ? dropDowns.IndicatorOccurrence : null,
                "Latitude": null,
                "Longitude": null,
                "Radius": 0,
                "ResidentCounty": dropDowns.ResidentCounty ? dropDowns.ResidentCounty : [],
                "ArrestedCounty": dropDowns.ArrestedCounty ? dropDowns.ArrestedCounty : [],
                "Arrest_Type": dropDowns.ArrestType ? dropDowns.ArrestType : [],
                "CaseNumbers": params.Case ? params.Case.split(',') : [],
                "SSN": params.SSN ? params.SSN?.split(',') : [],
                "PlaceOfBirth": dropDowns.PlaceOfBirth ? dropDowns.PlaceOfBirth : [],
                "AgeRangeStart": params.AgeRangeStart,
                "AgeRangeEnd": params.AgeRangeEnd,
                "FTSIDs": params.FTSID ? params.FTSID.split(',') : [],
                "Status": params.Status ? params.Status.split(',') : [],
                "ReportNum": params.ReportNum ? params.ReportNum.split(',') : [],
                "SystemCall": params.SystemCall ?? false,
            }            
            let qRes:getDarInfoResponse = await RunDarQuery(search_query);
            setDarTbl([]);
            if(qRes && qRes.AnyTable && qRes.AnyTable.length > 0) {
                setDarTbl(qRes.AnyTable);
                return qRes.AnyTable
            }
            // if (qRes?.AnyTable?.length === 0) {
            //     setNoData(true)
            //     //setDarTbl(qRes.AnyTable);
            // }
            else {
                setNoData(false)
            }
        
    }

    const setStatusValue = (options: any[]) => {
        options.forEach((option: string, idx, array) => {
            switch (option) {
                case 'In Progress':
                    array[idx] = 'InProgress';
                    break;
                case 'For Review':
                    array[idx] = 'ForReview';
                    break;
                default:
                    break;
            }
        });  
        
        return options;
    }

    const SubmitSearchConnection = async (params: any, dateType: string, andOrType: string, dropDowns: any) => {
        
        let search_query: GetDarInfo_Query = {
            "QueryType": "Q",
            "Dept": user.Dept,
            "EmpID": user.EmpID,
            "DeptORI": user.DeptORI,
            "DateRange": true,
            "DateTypeValue": (dateType === "Entry Date") ? 0 : 1,
            "FromDate": handleSearchTime(params?.fromDate, 'fromDate').toLocaleString(),
            "ToDate": handleSearchTime(params?.toDate, 'toDate').toLocaleString(),
            "SearchType": andOrType,
            "IndicatorOn": true,
            "TopArrestNumber": 0,
            "FNames": params.FirstName ? params.FirstName.split(',') : [],
            "MNames": params.MI ? params.MI.split(',') : [],
            "LNames": params.LastName ? params.LastName.split(',') : [],
            "DOBs": params.DOB ? params.DOB.split(',') : [],
            "SEX": params.Gender ? params.Gender.split(',') : [],
            "Address": "",
            "Cities": dropDowns.City ? dropDowns.City : [],
            "States": dropDowns.State ? dropDowns.State : [],
            "Zip": "",
            "Statutes": dropDowns.Statute ? dropDowns.Statute : [],
            "Gang": [],
            "FBIs": params.FBI ? params.FBI.split(',') : [],
            "SBIs": params.SBI ? params.SBI.split(',') : [],
            "DLNos": [],
            "PlateNos": [],
            "VINs": [],
            "AgencyArrested": [],
            "InfocopDept": [],
            "Indicators": dropDowns.Indicator ? dropDowns.Indicator : [],
            "IndicatorOccurrence": dropDowns.IndicatorOccurrence ? dropDowns.IndicatorOccurrence : null,
            "Latitude": null,
            "Longitude": null,
            "Radius": 0,
            "ResidentCounty": dropDowns.ResidentCounty ? dropDowns.ResidentCounty : [],
            "ArrestedCounty": [],
            "Arrest_Type": [],
            "CaseNumbers": [],
            "SSN": [],
            "PlaceOfBirth": [],
            "AgeRangeStart": params.AgeRangeStart,
            "AgeRangeEnd": params.AgeRangeEnd,
            "FTSIDs": params.FTSID ? params.FTSID.split(',') : [],
            "Status": params.Status ? params.Status.split(',') : [],
            "ReportNum": params.ReportNum ? params.ReportNum.split(',') : [],
        }
        
        let qRes:getDarInfoResponse = await RunDarQuery(search_query);
        if(qRes && qRes.AnyTable && qRes.AnyTable.length > 0) {
            setDarTbl(qRes.AnyTable);
        }
        // else {
        //     setDarTbl([]);
        // }
    }

    const handleSubmit = async () => {
        if (searchRef.current) {
            searchRef.current?.handleSubmit();
        }
    }
    
    return (
        <div id="ToolsArrest">
            {isUserPermission('Arrest') ?
            <>
               <ProfileReportSearch ref={searchRef} SubmitSearch={SubmitSearch} />
               <SearchResults  handleSubmit={handleSubmit} data={darTbl} Persons={personData} SubmitSearch={SubmitSearch} /> 

           </>
            :<></>}
        </div>
    );
}

export default ProfileReport;