import React, { useEffect, useState } from "react";
import {useLocation} from "react-router-dom";
import {RunSharedToken} from "../../services/account.service";
import VerifyingAuthentication from "../shared/_VerifyingAuthentication";
import NJTraceInputForm from "../tools/njtrace/_njtraceInputForm";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function SecuredNJTraceView() {
    let query = useQuery();
    let [id , setID] = React.useState<any>(query.get("ID"))
    let [dept , setDept] = React.useState<any>(query.get("Dept"))
    let [initialRun, setInitialRun] = React.useState<boolean>(false)
    let [njTraceDetails, setNJTraceDetails] = React.useState<any>(null)
    
    
    React.useEffect(()=>{
        if(!initialRun)
        {
            setInitialRun(true)
            if(id != null && id.length > 0){
                handleGetExpiryToken()
            }
            else{
                setNJTraceDetails({})
            }
        }
        }, [])
    
    const handleGetExpiryToken = async () => {
        RunSharedToken(id, dept).then((res) => {
            if(res.AuthToken && res.AuthToken.length > 0) {
                if (localStorage.getItem('user') == null) {
                    localStorage.setItem('user', res.AuthToken)
                }
            }
            if (res.AnyData) {
                let temp = JSON.parse(res.AnyData);
                setNJTraceDetails(temp)
            }
        })
    }
    
    return (
        <div>
            {njTraceDetails != null  ?
                <div className = "m-auto w-10/12">
                    <NJTraceInputForm handleReportSubmit={() => { window.location.href = "/login" } } isEdit={false} trace={null}/>
                </div>
                :
                <VerifyingAuthentication />
            }
        </div>
    );
}

export default SecuredNJTraceView;