
import { useLocation } from "react-router-dom";
import ChangePassword from "../../components/Admin/ChangePassword";

function ManagePassword() {
    return (
        <div>
            <ChangePassword/>
        </div>
    );
}

export default ManagePassword;