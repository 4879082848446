import React, { ChangeEvent, useRef, useState } from 'react';
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/lab';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Autocomplete, StepConnector, Table, TableBody, TableCell, TableHead, TableRow, TextareaAutosize, Tooltip, Alert, AlertColor, Snackbar, StepButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import TSCLogo from '../../../assets/Images/Terrorist_Screening_Center.png';
import VideoIcon from '../../../assets/Images/video-file.png';
import DeleteIcon from '@mui/icons-material/Delete';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { getDropDownCity, getDropDownQuery } from "../../../services/getDropdown.service";
import { GetDefaultStateForSite, GetStringNameForSite } from "../../../interfaces/config.interface";
import { SiteName } from "../../../services/config.service";

import { InfoObject, TSC, TSCFiles, TSCPhotoObject } from "../../../interfaces/TSC.interface";
import {DateToString, formatDate, readFileAsDataURL, ToISOLocal} from "../../../services/formatDate.service";
import { GetGUID, GetTSCContent } from "../../../services/tsc.service";
import { Box, ButtonGroup, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormLabel, IconButton, Radio, RadioGroup, TextField } from '@mui/material';
import { base64ToBlob, convertBlobToBase64 } from '../../../services/detail.service';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';

interface TSCFormProps {
    handleFormSubmit: any,
    editForm: any
    personDetails: any
}

interface dropdown {
    value: string,
    key: string
}

const steps = ['Start', 'Tier 1 General Information', 'Tier 1 Vehicles, Criminal History, Indices Checks', 'Tier 1 Additional Information',
    'Tier 2 General Information & Permits/Licenses', 'Tier 2 Corporate Affiliations & Employment', 'Tier 2 Associates & Insurance Claims', 'Tier 3 Financial'];

const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
    '&.MuiStepConnector-root': {
        top: 10,  // Adjust this value as needed
        left: 'calc(-50% + 20px)',
        right: 'calc(50% + 20px)',
    },
    '&.MuiStepConnector-active .MuiStepConnector-line': {
        backgroundColor: theme.palette.primary.main,
    },
    '&.MuiStepConnector-completed .MuiStepConnector-line': {
        backgroundColor: theme.palette.primary.main,
    },
    '&.MuiStepConnector-line': {
        borderColor: theme.palette.grey[400],
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

const TSCInputForm: React.FC<TSCFormProps> = ({ handleFormSubmit, editForm = null, personDetails}) => {
    const [form, setForm] = useState<TSC>({
        RFIPrevConducted: "No",
        TSCPhotoList: [
            {
                GUID: GetGUID(),
            }],
        AddressList: [],
        VehicleList: [],
        CriminalHistoryList: [],
        IndicesChecks_CourtCaseInfoList: [],
        InfoObjectList: [
            {
                GUID: GetGUID(),
                Title: "SID Number (include two-letter state code or codes if multistate offender)",
                Type: 1,
                DetailsTitle: "Number",
                Source: "",
            },
            {
                GUID: GetGUID(),
                Title: "eCitation Information",
                Type: 1,
                DetailsTitle: "Number",
                Source: "",
            },
            {
                GUID: GetGUID(),
                Title: "Fingerprint Images and/or Fingerprint Identification Network (FIN) Number",
                Type: 1,
                DetailsTitle: "Number",
                Source: "",
            },
            {
                GUID: GetGUID(),
                Title: "Scars/Marks/Tattoos",
                Type: 2,
                DetailsTitle: "Description",
                Source: ""
            },
            {
                GUID: GetGUID(),
                Title: "Video/Audio Recordings",
                Type: 3,
                Source: ""
            },
            {
                GUID: GetGUID(),
                Title: "Aviation License",
                Type: 1,
                DetailsTitle: "Miscellaneous"
            },
            {
                GUID: GetGUID(),
                Title: "Concealed Weapons Permit",
                Type: 2,
                DetailsTitle: "Status",
                LicenseNo: "",
                ExpirationDate: "",
            },
            {
                GUID: GetGUID(),
                Title: "Department of Health",
                Type: 1,
                DetailsTitle: "Miscellaneous",
            },
            {
                GUID: GetGUID(),
                Title: "Business Regulation Agency",
                Type: 1,
                DetailsTitle: "Miscellaneous",
            },
            {
                GUID: GetGUID(),
                Title: "Financial Regulation Agency",
                Type: 1,
                DetailsTitle: "Miscellaneous"
            },
            {
                GUID: GetGUID(),
                Title: "Financial Services Agency",
                Type: 1,
                DetailsTitle: "Miscellaneous"
            },
            {
                GUID: GetGUID(),
                Title: "Agriculture Department",
                Type: 1,
                DetailsTitle: "Miscellaneous"
            },
            {
                GUID: GetGUID(),
                Title: "Gaming Enforcement",
                Type: 1,
                DetailsTitle: "Miscellaneous"
            }
        ],
        CorporateEmployment_AffiliationsList: [],
        CorporateEmployment_CorporateLicensingList: [],
        CorporateEmployment_EmploymentHistoryList: [],
        AssociateList: [],
        InsuranceClaimList: [],
        FinancialObjectList: [],
        TSCPerson: [{}]
    });

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set<number>());

    const [stateList, setStateList] = React.useState<any>([]);
    const [countyList, setCountyList] = React.useState<any>([])
    const [cityList, setCityList] = React.useState<any>([]);
    const [systemList, setSystemList] = React.useState<any>(["Case Management Software", "Intelligence System", "Other"]);

    const [photoClearOpen, setPhotoClearOpen] = React.useState<boolean>(false)
    const [historicalAddressClearOpen, setHistoricalAddressClearOpen] = React.useState<boolean>(false)
    const [telephoneNumberClearOpen, setTelephoneNumberClearOpen] = React.useState<boolean>(false)
    const [emailAddressClearOpen, setEmailAddressClearOpen] = React.useState<boolean>(false)
    const [associatedVehicleClearOpen, setAssociatedVehicleClearOpen] = React.useState<boolean>(false)
    const [priorVehicleClearOpen, setPriorVehicleClearOpen] = React.useState<boolean>(false)
    const [stateHistoryClearOpen, setStateHistoryClearOpen] = React.useState<boolean>(false)
    const [arrestWarrantClearOpen, setArrestWarrantClearOpen] = React.useState<boolean>(false)
    const [indicesClearOpen, setIndicesClearOpen] = React.useState<boolean>(false)
    const [courtClearOpen, setCourtClearOpen] = React.useState<boolean>(false) 
    const [affiliationsClearOpen, setAffiliationsClearOpen] = React.useState<boolean>(false)
    const [fictitiousClearOpen, setFictitiousClearOpen] = React.useState<boolean>(false)
    const [licensingClearOpen, setLicensingClearOpen] = React.useState<boolean>(false)
    const [employmentClearOpen, setEmploymentClearOpen] = React.useState<boolean>(false)
    const [associatesClearOpen, setAssociatesClearOpen] = React.useState<boolean>(false)
    const [claimsClearOpen, setClaimsClearOpen] = React.useState<boolean>(false)
    const [registryClearOpen, setRegistryClearOpen] = React.useState<boolean>(false)
    const [uniformClearOpen, setUniformClearOpen] = React.useState<boolean>(false)
    const [bankruptcyClearOpen, setBankruptcyClearOpen] = React.useState<boolean>(false)
    const [taxClearOpen, setTaxClearOpen] = React.useState<boolean>(false)
    const [resetOpen, setResetOpen] = React.useState<boolean>(false)
    const [displayPhotoList, setDisplayPhotoList] = React.useState<TSCPhotoObject[]>([{}])
    const [displayInfoObjectList, setDisplayInfoObjectList] = React.useState<InfoObject[]>([{}])
    const [reportDate, setReportDate] = React.useState<Date | undefined>(new Date())
    const [pageLoading, setPageLoading] = React.useState<boolean>(true)


    const isSupportedInFirefox = () => {
        return !(navigator.userAgent.toLowerCase().indexOf('firefox') > -1);
    }

    React.useEffect(() => {
        handleInitialBuild()
    }, [])

    React.useEffect(() => {

    }, [form?.InfoObjectList])

    const handleInitialBuild = async () => {
        await handleDropdowns()
        if(editForm){
            let temp = { ...editForm }
            await getFileContent(temp)
            if (!temp.TSCPerson) {
                temp.TSCPerson = [{}]
            }
            if(temp?.TSCPhotoList && temp.TSCPhotoList?.length > 0){
                await handlePhotoList(temp?.TSCPhotoList , 'TSC Photo')  

            }
            else{
                setDisplayPhotoList([{}])
                //setForm(temp);
            }
            if(temp?.InfoObjectList && temp?.InfoObjectList.length > 0){
                await handlePhotoList(temp?.InfoObjectList , 'TSC Info')  

            }
            setReportDate(temp?.ReportDate) 
            setForm(temp)

        }
        else if (personDetails) {
            let temp = { ...form }
            if (temp.TSCPerson) {
                temp.TSCPerson.map((row, index) => {
                    row.Person_FirstName = personDetails.FName ?? "";
                    row.Person_LastName = personDetails.LName ?? "";
                    row.Person_SSN = personDetails.SSN ?? "";
                    row.Person_DOB = personDetails.DOB ?? "";

                    row.Person_DLNo = personDetails.DLNo ?? "";
                    row.Person_DLState = personDetails.DLSt ?? "";

                    const aliasInfo = personDetails.AKAInfo
                        .filter((person: any) => person.FName && person.LName) // Filter out any entries with missing names
                        .map((person: any) => `${person.FName} ${person.LName}`) // Concatenate FName and LName
                        .join(', '); // Join all names with a comma and space
                    row.Person_Alias = aliasInfo ?? "";
                })
            }

            if(personDetails && personDetails.Arrests){
                temp.CriminalHistoryList = [];
                for (const arrest of personDetails.Arrests) {
                    temp.CriminalHistoryList.push({
                        Date: arrest?.DTOFARREST,
                        Charge: `${arrest?.STATUTE} ${arrest?.STATUTEDESCRIPTION}`,
                        Origination:  "State Criminal History",
                        Disposition:'',
                        deleted:false
                    });
                }
            }
            if (temp.AddressList) {
                personDetails?.Address?.forEach((address: any) => {
                    const street = address.Address.trim();

                    // Check if the street already exists in the AddressList
                    const isDuplicate = temp?.AddressList?.some(existingAddress =>
                        existingAddress?.Street?.trim() === street
                    );

                    // Push the address only if it's not a duplicate
                    if (!isDuplicate) {
                        temp?.AddressList?.push({
                            Street: street,
                            City: address.City.trim(),
                            State: address.State.trim(),
                            ZIP: address.Zip,
                            Origination: "Historical Address"
                        });
                    }
                });
            }
            if(temp.TSCPhotoList){
                setDisplayPhotoList(temp.TSCPhotoList)
            }
            else{
                setDisplayPhotoList([{}])
            }
            if(temp.InfoObjectList){
                setDisplayInfoObjectList(temp.InfoObjectList)
            }
            else{
                setDisplayInfoObjectList([{}])
            }
            setForm(temp);
        }
        else{
            let temp = { ...form }
            if(temp?.TSCPhotoList){
                setDisplayPhotoList(temp?.TSCPhotoList)
            }
            if(temp?.InfoObjectList){
                setDisplayInfoObjectList(temp?.InfoObjectList)
            }
        }
        setPageLoading(false)

    }

    const getFileContent = async (data:any) => {
        let temp = {...data}
        if(temp.TSCPhotoList && temp.TSCPhotoList.length > 0){
            for(let photo of temp.TSCPhotoList){
                if(photo.Files && photo.Files.length > 0){
                    for(let fileObj of photo.Files){
                        let res = await GetTSCContent(fileObj)
                        fileObj.Content = res.Content
                    }
                }
                else{                  
                    photo.Files = []
                }
            }
        }
        if(temp?.InfoObjectList && temp?.InfoObjectList?.length > 0){
            for(let photo of temp?.InfoObjectList){
                if(photo.Files && photo.Files.length > 0){
                    for(let fileObj  of photo.Files){
                        let res = await GetTSCContent(fileObj)
                        fileObj.Content = res.Content
                    }
                }
            }
        }

    }

    const handlePhotoList = async (photoList : any , photoListName:string ) => {
        let tempPhotoList:any = []
        if(photoListName === 'TSC Photo'){
            for(let photo of photoList){
                if(photo?.Files && photo?.Files?.length > 0){
                    const base64Array =[]
                    let contentType = ''
                    let tempFileName = 'temp'             
                    //make sure photo file array is sorted based on ChunkNo
                    if(checkIfSorted(photo?.Files , 'ChunkNo')){
                        for(let fileObj of photo?.Files){
                            if(fileObj.isChunk){
                                contentType = fileObj.FileEncoding
                                tempFileName = fileObj?.FileName
                                if(fileObj && fileObj?.Content?.length > 0){
                                    base64Array.push(fileObj.Content)
                                }
                            }
                            else{
                                tempPhotoList.push(photo)
                            }
                        }
                    }
                    else{
                        const sortedData = [...photo?.Files].sort((a, b) => {
                            if (a.ChunkNo < b.ChunkNo)
                                return -1;
                            if (a.ChunkNo > b.ChunkNo) 
                                return 1;
                            return 0;
                            });
                        if(sortedData && sortedData?.length > 0){
                            for(let fileObj of sortedData){
                                if(fileObj.isChunk){
                                    contentType = fileObj.FileEncoding
                                    if(fileObj && fileObj?.Content?.length > 0){
                                        base64Array.push(fileObj.Content)
                                    }
                                }
                                else{
                                    tempPhotoList.push(photo)
                                }
                            }
                        }
    
                    }                  
                    if(base64Array.length > 0 ){
                        const mergedBlob = await mergeBase64Blobs(base64Array, contentType);
                        if(mergedBlob){
                            let base64Content = await convertBlobToBase64(mergedBlob)
                            if(base64Content){
                                tempPhotoList.push({
                                    ReportID: photo.ReportID,
                                    ParentID: photo.ID,
                                    GUID: photo.GUID,
                                    PhotoDate: photo.PhotoDate ?? null,
                                    Source: photo.Source ?? null,
                                    CreateUser: photo.CreateUser ?? null,
                                    CreateDate : photo.CreateDate ?? null,
                                    UpdateUser : photo.UpdateUser ?? null,
                                    UpdateDate : photo.UpdateDate ?? null,
                                    deleted : photo.deleted ?? false,
                                    Files :[{
                                        ReportID: photo.ReportID,
                                        ParentID: photo.ID,
                                        GUID: photo.GUID,
                                        Origination:"TSC Photo",
                                        FileName: tempFileName,
                                        FileEncoding: mergedBlob?.type,
                                        Content: base64Content
                                    }]
                                })
                            }
                            
                        }
                    }
                
                }
                else{
                    photo.Files = []
                    tempPhotoList.push(photo)
                }
                
            }
            setDisplayPhotoList(tempPhotoList) 
        }
        else{
            //handle TSC Info object and files
            for(let infoObj of photoList){
                tempPhotoList.push({
                    CreateDate: infoObj?.CreateDate ?? null,
                    CreateUser: infoObj?.CreateUser ?? null,
                    DetailsContent: infoObj?.DetailsContent ?? null,
                    DetailsTitle: infoObj?.DetailsTitle ?? null,
                    ExpirationDate: infoObj?.ExpirationDate ?? null,
                    Files: infoObj?.Files && infoObj?.Files.length > 0 ? [] : null,
                    GUID: infoObj?.GUID ?? null,
                    ID: infoObj?.ID ?? null,
                    ImagesAttached: infoObj?.ImagesAttached ?? null,
                    LicenseNo: infoObj?.LicenseNo ?? null,
                    Origination: infoObj?.Origination ?? null,
                    ReportID: infoObj?.ReportID ?? null,
                    Source: infoObj?.Source ?? null,
                    State: infoObj?.State ?? null,
                    Status: infoObj?.Status ?? null,
                    Title: infoObj?.Title ?? null,
                    Type: infoObj?.Type ?? null,
                    UpdateDate: infoObj.UpdateDate ?? null,
                    UpdateUser: infoObj?.UpdateUser ?? null,
                    deleted: infoObj?.deleted ?? null
                })
                if(infoObj && infoObj.Files){                  
                    //split into different arrays based on file name since one box can have multiple images
                    const splitByFileName:any[] = await handleSplitArray(infoObj?.Files, 'FileName')
                    if(splitByFileName && splitByFileName.length > 0){
                        for(let itemArr of splitByFileName){
                            const base64Array =[]
                            let contentType = ''
                            let tempFileName = 'temp'
                            let tempReportID=0
                            let tempParentID=0 
                            let tempGUID=''
                            let tempOrigination
                            if(checkIfSorted(itemArr , 'ChunkNo')){
                                for(let item of itemArr){
                                    if(item?.isChunk){
                                        contentType = item.FileEncoding
                                        tempFileName = item?.FileName
                                        tempReportID = item?.ReportID 
                                        tempParentID = item?.ParentID 
                                        tempGUID = item?.GUID
                                        tempOrigination = item?.Origination
                                        base64Array.push(item?.Content)
                                    }
                                    else{
                                        let index = tempPhotoList.findIndex((f:any)=> f.GUID === infoObj.GUID)
                                        if(index >= 0){
                                            if(!tempPhotoList[index]?.Files){
                                                tempPhotoList[index].Files=[]
                                            }
                                            tempPhotoList[index]?.Files?.push(item)                                        }
                                    }

                                }

                            }
                            else{
                                const sortedData = [itemArr].sort((a, b) => {
                                    if (a.ChunkNo < b.ChunkNo)
                                        return -1;
                                    if (a.ChunkNo > b.ChunkNo) 
                                        return 1;
                                    return 0;
                                    });
                                if(sortedData && sortedData?.length > 0){
                                    for(let item of sortedData){
                                        if(item.isChunk){
                                            contentType = item.FileEncoding
                                            if(item && item?.Content?.length > 0){
                                                base64Array.push(item.Content)
                                            }
                                        }
                                        else{
                                            let index = tempPhotoList.findIndex((f:any)=> f.GUID === infoObj.GUID)
                                            if(index >=0){
                                                if(!tempPhotoList[index]?.Files){
                                                    tempPhotoList[index].Files=[]
                                                }
                                                tempPhotoList[index]?.Files?.push(item) 
                                            }                                          
                                        }
                                    }
                                }
            
                            }     
                            if(base64Array.length > 0 ){
                                const mergedBlob = await mergeBase64Blobs(base64Array, contentType);
                                if(mergedBlob){
                                    let base64Content = await convertBlobToBase64(mergedBlob)
                                    if(base64Content){
                                        let index = tempPhotoList.findIndex((f:any)=> f.GUID === infoObj.GUID)
                                        if(index >= 0){
                                            tempPhotoList[index]?.Files.push(
                                                {
                                                    ReportID: tempReportID,
                                                    ParentID: tempParentID,
                                                    GUID: tempGUID,
                                                    Origination: tempOrigination,
                                                    FileName: tempFileName,
                                                    FileEncoding: mergedBlob?.type,
                                                    Content: base64Content
                                                }
                                            )
                                        }                                                                           
                                    }                                   
                                }
                            }  
                        }
                    }     
                }
            }
            setDisplayInfoObjectList(tempPhotoList)           
        }   
    }

    const handleSplitArray= async (array:any, propertyName:string)=> {

        const result:any = {};
        let temp = array
        for(let item of temp){
            const propertyValue = item[propertyName]
            if(!result[propertyValue]){
                result[propertyValue]=[]
            }
            result[propertyValue].push(item);
        }
        return Object.values(result)
    }

    const mergeBase64Blobs = async (base64Array:any, contentType:any)=>{
        let blobArr = []
        for(let base64 of base64Array){
            let res = await base64ToBlob(base64,contentType)
            if(res){
                blobArr.push(res)
            }
        }
        const mergedBlob = new Blob(blobArr, { type: contentType }); // Merge all Blobs
        return mergedBlob;

    }

    const checkIfSorted = (objectArray :any , property : any) => {
        for (let i = 1; i < objectArray.length; i++) {
          if (objectArray[i - 1][property] > objectArray[i][property]) {
            return false;
          }
        }
        return true;
      };


    const handleDropdowns = async () => {
        getDropDownQuery('State').then(state => {
            if (state && state?.length > 0) {
                setStateList(state)
            }
        }).catch(e=>{console.log(e)})

        getDropDownCity(GetDefaultStateForSite(SiteName)).then(city=>{
            let tempList = city?.filter(x => x.State === GetDefaultStateForSite(SiteName))
            const ids = tempList.map(({ City }) => City);
            const filtered = tempList.filter(({ City }, index) =>
                !ids.includes(City, index + 1));
            setCityList(filtered)
        }).catch(e=>{console.log(e)})

        getDropDownQuery('ResidentCounty').then(counties => {
            if (counties && counties?.length > 0) {
                setCountyList(counties)
            }
        }).catch(e=>{console.log(e)})
        
      
    }

    const handleDialog = (section: string, value: boolean) => {
        switch (section) {
            case 'TSC Photo':
                setPhotoClearOpen(value);
                break;
            case 'Historical Address':
                setHistoricalAddressClearOpen(value);
                break;
            case 'Telephone Numbers':
                setTelephoneNumberClearOpen(value);
                break;
            case 'Email Addresses':
                setEmailAddressClearOpen(value);
                break;
            case 'Associated Vehicles':
                setAssociatedVehicleClearOpen(value);
                break;
            case 'Prior Vehicles':
                setPriorVehicleClearOpen(value);
                break;
            case 'State Criminal History':
                setStateHistoryClearOpen(value);
                break;
            case 'Warrant and Arrest':
                setArrestWarrantClearOpen(value);
                break;
            case 'Indices Checks':
                setIndicesClearOpen(value);
                break;
            case 'Court Case':
                setCourtClearOpen(value);
                break;
            case 'Affiliations':
                setAffiliationsClearOpen(value);
                break;
            case 'Fictitious':
                setFictitiousClearOpen(value);
                break;
            case 'Licensing':
                setLicensingClearOpen(value);
                break;
            case 'Employment':
                setEmploymentClearOpen(value);
                break;
            case 'Associates':
                setAssociatesClearOpen(value);
                break;
            case 'Claims':
                setClaimsClearOpen(value);
                break;
            case 'Registry':
                setRegistryClearOpen(value);
                break;
            case 'Uniform':
                setUniformClearOpen(value);
                break;
            case 'Bankruptcy':
                setBankruptcyClearOpen(value);
                break;
            case 'Tax':
                setTaxClearOpen(value);
                break;
            case 'Reset':
                setResetOpen(value);
                break;
        }

    }

    const handleDrop = (event: React.DragEvent<HTMLDivElement>, item: any | null, origination: string, table: string = "") => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        if (files.length > 0) {
            const file = files[0]; // Assuming only one file is handled per drop event
            handlePasteOrDrop(file, item, origination, table); // Use the item and origination
        } else {
            console.warn("No valid image found in drop event");
        }
    };

    const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>, item: any | null, origination: string, table: string = "") => {
        event.preventDefault();
        const clipboardItems = event.clipboardData.items;
        let imageBlob: Blob | null = null;
        let originalFileName: string | undefined;
        for (let i = 0; i < clipboardItems.length; i++) {
            const item = clipboardItems[i];
            if (item.type.startsWith('image/') || item.type.startsWith('video') || item.type.startsWith('audio')) {
                imageBlob = item.getAsFile();
                originalFileName = `pasted-file.${imageBlob?.type.split('/')[1]}`; // Default to extension from MIME type
                break; // Stop after finding the first image
            }
        }

        if (imageBlob) {
            const file = new File([imageBlob], originalFileName || 'pasted-image.png', { type: imageBlob.type });
            handlePasteOrDrop(file, item, origination, table); // Use the item and origination
        } else {
            console.warn("No valid image found in clipboard");
        }
    };

    // const handleContextMenu = async (
    //     event: React.MouseEvent<HTMLDivElement>,
    //     item: any | null, // Use GUID for lists or null for standalone areas
    //     origination: string,
    //     table: string = ""
    //     ) => {
    //     event.preventDefault();

    //     const menu = document.createElement('div');
    //     menu.innerHTML = `
    //     <ul style="list-style: none; padding: 10px; border: 1px solid #ccc; background: #fff;">
    //         <li style="margin-bottom: 5px; cursor: pointer;" id="paste-option">Paste</li>
    //     </ul>
    //     `;
    //     menu.style.position = 'absolute';
    //     menu.style.left = `${event.pageX}px`;
    //     menu.style.top = `${event.pageY}px`;
    //     document.body.appendChild(menu);

    //     const pasteOption = document.getElementById('paste-option');
    //     if (pasteOption) {
    //         pasteOption.addEventListener('click', async () => {

    //             try {
    //                 const clipboardItems = await navigator.clipboard.read();
    //                 let imageBlob: Blob | null = null;
    //                 let originalFileName: string | undefined;

    //                 for (const item of clipboardItems) {
    //                     const imageType = item.types.find(type => type.startsWith('image/') || type.startsWith('video') || type.startsWith('audio'));
    //                     if (imageType) {
    //                         imageBlob = await item.getType(imageType);
    //                         originalFileName = `pasted-image.${imageBlob.type.split('/')[1]}`; // Default to extension from MIME type
    //                         break; // Stop after finding the first image
    //                     }
    //                 }

    //                 if (imageBlob) {
    //                     const file = new File([imageBlob], originalFileName || 'pasted-image.png', { type: imageBlob.type });
    //                     handlePasteOrDrop(file, item, origination, table); // Use the item and origination
    //                 } else {
    //                     console.warn("No valid image found in clipboard, skipping HTML data");
    //                 }
    //             } catch (err) {
    //                 console.error('Failed to read clipboard contents: ', err);
    //             } finally {
    //                 if (menu && menu.parentNode) {
    //                     menu.parentNode.removeChild(menu);
    //                 }
    //             }
    //         });
    //     }

    //     document.addEventListener('click', () => {
    //         if (menu && menu.parentNode) {
    //             menu.parentNode.removeChild(menu);
    //         }
    //     }, { once: true });
    //};

    const handlePasteOrDrop = (file: File, item: any | null, origination: string, table: string = "") => {
        const maxFileSize = 15 * 1024 * 1024;
        const fileType = file.type.split('/')[0]; // 'image', 'video', 'audio'

        if ((fileType !== 'image') && (fileType !== 'video') && (fileType !== 'audio')) {
            alert('This file file type is not supported by the form.')
            return;
        }

        if (file.size > maxFileSize && fileType !== 'video') {
            alert(`File size exceeds the limit of 15 MB. Please upload a smaller file.`);
            return;
        }

        let temp = { ...form }
        let tempPhotoList = [...displayPhotoList] 
        let tempInfoObjList = [...displayInfoObjectList]
        const reader = new FileReader();

        reader.onload = (event) => {
            const result = event.target?.result;
   
            if (typeof result === 'string') {
                    if (table !== "") {
                        let index: number | undefined;
                        let displayIndex: number | undefined;

                        if (table === "TSCPhotoList" && fileType === 'image') {
                            index = temp?.TSCPhotoList?.findIndex((f: any) => f.GUID === item.GUID)
                            displayIndex = tempPhotoList?.findIndex((f:any)=> f.GUID === item.GUID)
                            if (index !== null && (index as number) >= 0) {
                                let updatedItem = { ...item }
                                if (!Array.isArray(item.Files)) {
                                    updatedItem.Files = [];
                                }
                                if (origination === "TSC Photo") {
                                    if (temp && temp.TSCPhotoList && index != null && index >=0) {
                                        if(!temp.TSCPhotoList[index].Files){
                                            temp.TSCPhotoList[index].Files = []
                                        }
                                        if(temp.TSCPhotoList[index].Files?.length === 0){
                                            //first time adding photo
                                            if (!updatedItem.Files.some((f: any) => f.FileName === file.name)) {
                                                updatedItem.Files.push({
                                                GUID: temp.TSCPhotoList[index].GUID,
                                                Content: result,
                                                FileName: file.name,
                                                FileEncoding: file.type,
                                                Origination: origination,
                                                Type: fileType,
                                                deleted : false
                                                })
                                            }
                                            if(!temp.TSCPhotoList[index].Files?.some((f:any)=> f?.FileName === file.name)){
                                                temp.TSCPhotoList[index].Files?.push({
                                                    GUID: temp.TSCPhotoList[index].GUID,
                                                    Content: result,
                                                    FileName: file.name,
                                                    FileEncoding: file.type,
                                                    Origination: origination,
                                                    Type: fileType,
                                                    deleted : false
                                                })
                                            }

                                        }
                                        else{
                                            //limit to only one image per entry
                                            if(temp.TSCPhotoList[index].Files && temp.TSCPhotoList[index].Files?.length as any > 0){
                                                if(temp.TSCPhotoList[index].Files?.filter((f:any)=> !f?.deleted && f?.Content).length as any > 0){
                                                    alert('There is an existing photo. Delete the current photo to add a new one or click the add another photo button.')
                                                }
                                                else{
                                                    if (!updatedItem.Files.some((f: any) => f.FileName === file.name)) {
                                                        updatedItem.Files.push({
                                                        GUID: temp.TSCPhotoList[index].GUID,
                                                        Content: result,
                                                        FileName: file.name,
                                                        FileEncoding: file.type,
                                                        Origination: origination,
                                                        Type: fileType,
                                                        deleted : false
                                                        })
                                                    }
                                                    if(!temp.TSCPhotoList[index].Files?.some((f:any)=> f?.FileName === file.name)){
                                                        temp.TSCPhotoList[index].Files?.push({
                                                            GUID: temp.TSCPhotoList[index].GUID,
                                                            Content: result,
                                                            FileName: file.name,
                                                            FileEncoding: file.type,
                                                            Origination: origination,
                                                            Type: fileType,
                                                            deleted : false
                                                        })
                                                    }

                                                }
                                            }                                       
                                        }
                                    }                           
                                }
                                //update display photo list
                                if(tempPhotoList && displayIndex != null){
                                    tempPhotoList[displayIndex] = updatedItem;
                                }
                            }
                        }
                        if (table === "InfoObjectList") {
                            index = temp?.InfoObjectList?.findIndex((f: any) => f.GUID === item.GUID)
                            displayIndex = tempInfoObjList?.findIndex((f: any) => f.GUID === item.GUID)                        

                            if (index !== null && index !== undefined && (index as number) >= 0) {
                                let updatedItem = { ...item }
                                if (!Array.isArray(item.Files)) {
                                    updatedItem.Files = [];
                                }
                                if(temp?.InfoObjectList && temp?.InfoObjectList[index]){
                                    if (origination === "Marks" && fileType === 'image') {
                                        if (!updatedItem.Files.some((f: any) => f.FileName === file.name)) {
                                            updatedItem.Files.push({
                                                GUID: temp?.InfoObjectList[index]?.GUID,
                                                Content: result.split('base64,')[1],
                                                FileName: file.name,
                                                FileEncoding: file.type,
                                                Origination: origination,
                                                Type: fileType
                                            })
                                        }
                                        if(!temp?.InfoObjectList[index].Files){

                                            temp.InfoObjectList[index].Files=[]
                                        }
                                        if(!temp.InfoObjectList[index].Files?.some((f:any)=> f.fileName === file.name)) {
                                            temp.InfoObjectList[index].Files?.push({
                                                GUID: temp?.InfoObjectList[index]?.GUID,
                                                Content: result.split('base64,')[1],
                                                FileName: file.name,
                                                FileEncoding: file.type,
                                                Origination: origination,
                                                Type: fileType
                                            })
                                        }                                                                                                                                                                                           
                                }
                                else if (origination === "Marks") {
                                    alert('Please place your video/audio recordings in the Video/Audio Recordings section.')
                                }
                                if (origination === "Fingerprints" && fileType === 'image') {
                                    if (!updatedItem.Files.some((f: any) => f.FileName === file.name)) {
                                        updatedItem.Files.push({
                                            GUID: temp?.InfoObjectList[index]?.GUID,
                                            Content: result.split('base64,')[1],
                                            FileName: file.name,
                                            FileEncoding: file.type,
                                            Origination: origination,
                                            Type: fileType
                                        })
                                    }
                                    if(!temp?.InfoObjectList[index].Files){

                                        temp.InfoObjectList[index].Files=[]
                                    }
                                    if(!temp.InfoObjectList[index].Files?.some((f:any)=> f.fileName === file.name)) {
                                        temp.InfoObjectList[index].Files?.push({
                                            GUID: temp?.InfoObjectList[index]?.GUID,
                                            Content: result.split('base64,')[1],
                                            FileName: file.name,
                                            FileEncoding: file.type,
                                            Origination: origination,
                                            Type: fileType
                                        })
                                    }
                                } 
                                else if (origination === "Fingerprints") {
                                    alert('Please place your video/audio recordings in the Video/Audio Recordings section.')
                                }

                                if (origination === "Video/Audio" && (fileType === 'video' || fileType === 'audio')) {
                                    if (!updatedItem.Files.some((f: any) => f.FileName === file.name)) {
                                        updatedItem.Files.push({
                                            GUID: temp?.InfoObjectList[index]?.GUID,
                                            Content: result.split('base64,')[1],
                                            FileName: file.name,
                                            FileEncoding: file.type,
                                            Origination: origination,
                                            Type: fileType
                                        })
                                    }
                                    if(!temp?.InfoObjectList[index].Files){

                                        temp.InfoObjectList[index].Files=[]
                                    }
                                    if(!temp.InfoObjectList[index].Files?.some((f:any)=> f.fileName === file.name)) {
                                        temp.InfoObjectList[index].Files?.push({
                                            GUID: temp?.InfoObjectList[index]?.GUID,
                                            Content: result.split('base64,')[1],
                                            FileName: file.name,
                                            FileEncoding: file.type,
                                            Origination: origination,
                                            Type: fileType
                                        })
                                    }
                                }
                                else if (origination === "Video/Audio" && fileType === 'image') {
                                    alert('Images are not supported in this section. Please put them in the scars or fingerprints section if applicable.')
                                }

                                // if (temp && temp.InfoObjectList && index != null) {
                                //     temp.InfoObjectList[index] = updatedItem;
                                // }
                                if (tempInfoObjList && displayIndex != null) {
                                    tempInfoObjList[displayIndex] = updatedItem;
                                }

                                }
                                
                            }
                        }
                    } else {
                        console.warn("Table is not recognized");
                }
                setForm(temp);
                setDisplayPhotoList(tempPhotoList)
                setDisplayInfoObjectList(tempInfoObjList)
            }
        };

        reader.readAsDataURL(file);
    };

    const handleRemoveFile = (file: any, table: string = "", item: any = null) => {
        let temp: any = { ...form };
        let tempPhotoList = [...displayPhotoList]
        let tempInfoObjList =[...displayInfoObjectList]
        if (item && table?.length > 0) {
            let objectIndex = temp[table]?.findIndex((fi: any) => fi.GUID === item.GUID);
            let displayObjectIndex = tempPhotoList?.findIndex((fi:any) => fi.GUID === item.GUID)
            let displayInfoObjIndex = tempInfoObjList?.findIndex((fi:any)=> fi.GUID === item.GUID)
            if ((objectIndex as any) >= 0) {
                let targetItem = temp[table][objectIndex];
                if(targetItem?.ID){
                    if ((table !== "") && targetItem?.Files) {
                        for(let targetFile of targetItem?.Files){
                            if(targetFile?.FileName === file?.FileName){
                                if(targetFile?.ID){
                                    targetFile.deleted = true
                                }
                                else{                               
                                    let targetIndx = targetItem?.Files.findIndex((f:any) => JSON.stringify(f) === JSON.stringify(file))
                                    if(targetIndx >=0){
                                        targetItem?.Files?.splice(targetIndx, 1)
                                    }
                                }
                            }
                           
                        }
                    }
                    else{
                        targetItem.Files=[]
                    }

                }              
                else {
                    
                    let findIndex = temp[table][objectIndex]?.Files?.findIndex((f: any) => JSON.stringify(f) === JSON.stringify(file));
                    if ((findIndex as any) >= 0) {
                        if (temp[table][objectIndex]?.Files[findIndex]?.ID && (temp[table][objectIndex]?.Files[findIndex].ID as any) > 0) {
                            temp[table][objectIndex].Files[findIndex].deleted = true;
                        } else {
                            temp[table][objectIndex]?.Files?.splice(findIndex, 1);
                        }
                    }
                }
            }
            //display object to show merged images
            if((displayObjectIndex as any) >= 0){
                let displayTargetItem = tempPhotoList[displayObjectIndex]
                if(displayTargetItem?.ID){
                    if ((table !== "") && displayTargetItem?.Files) {
                        for(let displayTargetFile of displayTargetItem.Files){
                            if(displayTargetFile?.ID){
                                displayTargetFile.deleted = true
                            }
                            else{
                                let targetIndx = displayTargetItem?.Files.findIndex((f:any) => JSON.stringify(f) === JSON.stringify(displayTargetFile))
                                displayTargetItem.Files?.splice(targetIndx,1)
                            }
                        }
                    }
                    
                }
                else {  
                        displayTargetItem.Files=[]
                    }              
            }
            if((displayInfoObjIndex as any) >=0){
                let displayTargetItem = tempInfoObjList[displayInfoObjIndex]
                if(displayTargetItem?.ID){
                    if ((table !== "") && displayTargetItem?.Files) {
                        for(let displayTargetFile of displayTargetItem.Files){
                            if(displayTargetFile?.FileName === file?.FileName){
                                if(displayTargetFile?.ParentID){
                                    displayTargetFile.deleted = true
                                }
                                else{
                                    let targetIndx = displayTargetItem?.Files.findIndex((f:any) => JSON.stringify(f) === JSON.stringify(file))
                                    if(targetIndx != undefined && targetIndx>=0){
                                        displayTargetItem.Files?.splice(targetIndx,1)
                                    }
                                }
                            }                           
                        }
                    }                 
                }
                else {  
                        let targetIndx = displayTargetItem?.Files?.findIndex((f:any) => f === file)
                       if(targetIndx != undefined && targetIndx >=0){
                            displayTargetItem.Files?.splice(targetIndx,1)
                       }
                    }          
            }
            
        } 
        else if (temp?.Files && temp?.Files?.length > 0) {
            let findIndex = temp?.Files?.findIndex((f: any) => f === file);
            if ((findIndex as any) >= 0) {
                if (temp?.Files[findIndex]?.ID && (temp.Files[findIndex].ID as any) > 0) {
                    temp.Files[findIndex].deleted = true;
                } else {
                    temp?.Files?.splice(findIndex, 1);
                }
            }
        }
        setDisplayPhotoList(tempPhotoList)
        setDisplayInfoObjectList(tempInfoObjList)
        setForm(temp); // This will trigger a re-render with the updated state
    };

    const handleInputChange = (e: { target: { name: string; value: any; }; }, table: string = "", item: any = null) => {

        const { name, value } = e.target;
        let temp: any = { ...form }
        let tempDisplay = [...displayPhotoList]
        let tempInfoDisplay = [...displayInfoObjectList]
        if (table?.length > 0 && item) {
            if(table !== 'TSCPhotoList' && table !== 'InfoObjectList'){
                let index = temp[table]?.findIndex((f: any) => f === item)
                if (index >= 0) {
                    temp[table][index][name] = (value instanceof Date ? ToISOLocal(value) : value)
                }       
            }else{
                //handles file display cannot compare item since files are differently stored and display
                let index = temp[table]?.findIndex((f: any) => f.GUID === item.GUID)
                if (index >= 0) {
                    temp[table][index][name] = (value instanceof Date ? ToISOLocal(value) : value)
                } 
            }
            
            if(table === 'TSCPhotoList'){
                
                //handle display file list
                let displayIndex= tempDisplay.findIndex((f:any)=> f === item)
                if(displayIndex >=0){
                    switch (name) {
                        case 'Source':
                            tempDisplay[displayIndex].Source = value
                            break;
                        case 'PhotoDate':
                            tempDisplay[displayIndex].PhotoDate = (value instanceof Date ? ToISOLocal(value) : value)
                            break;
                    }
                }
            }
            else if(table === 'InfoObjectList'){
                let displayIndex= tempInfoDisplay.findIndex((f:any)=> f === item)
                if(displayIndex >=0){
                    switch (name){
                        case 'DetailsContent':
                            tempInfoDisplay[displayIndex].DetailsContent=value
                            break;
                        case 'Source':
                            tempInfoDisplay[displayIndex].Source=value
                            break;
                        case 'Status':
                            tempInfoDisplay[displayIndex].Status=value
                            break;
                        case 'State':
                            tempInfoDisplay[displayIndex].State=value
                            break;
                        case 'LicenseNo':
                            tempInfoDisplay[displayIndex].LicenseNo=value
                            break;
                        case 'ExpirationDate':
                            tempInfoDisplay[displayIndex].ExpirationDate=(value instanceof Date ? ToISOLocal(value) : value)
                            break;
                    }
                }
            }
        }
        else {
            temp[name] = (value instanceof Date ? ToISOLocal(value) : value)
        }
        setForm(temp)
        setDisplayPhotoList(tempDisplay)
        setDisplayInfoObjectList(tempInfoDisplay)
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, table: string = "", item: any = null) => {
        const { name, checked } = e.target;
        let temp: any = { ...form }

        if (table?.length > 0 && item) {
            let index = temp[table]?.findIndex((f: any) => f === item)
            if (index >= 0) {
                temp[table][index][name] = checked
            }
        }
        else {
            temp[name] = checked
        }

        setForm(temp)
    }

    const addTableRow = (table: string, field: string = "", value: string = "") => {
        var temp: any = { ...form }
        var tempPhotoList = [...displayPhotoList]
        if (!Array.isArray(temp[table])) {
            temp[table] = [];
        }

        if (field && value) {
            let newRow = { [field]: value };
            temp[table].push(newRow);
            tempPhotoList.push(newRow)
        } else {
            temp[table].push({});
            tempPhotoList.push({});

        }
        setDisplayPhotoList(tempPhotoList)
        setForm(temp)
    };

    const clearTableRows = (table: string, field = "", value = "") => {
        let temp: any = { ...form };
        var tempPhotoList = [...displayPhotoList]
        if (temp[table]) {
            if (table && field && value) {
                // Clears specific items in Address based on field and value
                temp[table] = temp[table]?.filter((item: any) => item[field] !== value || item.deleted);
            } else { 
                for(let fileObj of temp[table]){
                    if(fileObj && fileObj.ID){
                        if(fileObj?.Files && fileObj?.Files?.length > 0){                       
                            for(let photo of fileObj.Files){
                                photo.deleted = true
                            }
                        }
                    }                   
                    else{
                        fileObj.Files = []
                    }
                }              
            }
        }
        setForm(temp);
        if(table === 'TSCPhotoList'){
            setDisplayPhotoList(temp?.TSCPhotoList)
        }
    };

    const deleteTableRow = (table: string, item: any) => {
        var temp: any = { ...form }
        var tempPhotoList = [...displayPhotoList]
        if (table?.length > 0 && item) {
            if (temp[table]) {
                let index = temp[table]?.findIndex((f: any) => f === item)   
                if(table === 'TSCPhotoList'){
                    index = temp[table]?.findIndex((f: any) => f.GUID === item.GUID)   
                }             
                if ((index as any) >= 0) {
                    if (temp[table][index]?.ID && (temp[table][index].ID as any) > 0) {
                        temp[table][index].deleted = true
                    } else {
                        temp[table]?.splice(index, 1)
                    }
                    setForm(temp)
                }
            }
            if(tempPhotoList){
                let displayIndex = tempPhotoList?.findIndex((f:any)=> f.GUID === item.GUID)
                if ((displayIndex as any) >= 0) {
                    if (tempPhotoList[displayIndex]?.ID && (tempPhotoList[displayIndex].ID as any) > 0) {
                        tempPhotoList[displayIndex].deleted = true
                    } else {
                       tempPhotoList?.splice(displayIndex, 1)
                    }
                    setDisplayPhotoList(tempPhotoList)
                }
            }
        }
    };

    const renderInfoObjectFields = (infoObject: any) => {
        return (
            <div key={infoObject.GUID} className="grid grid-cols-2 border-2 border-black gap-1 p-2 bg-tsc-bg">
                {/* Positive/Negative Radio Group */}
                <div className="col-span-2">
                    <label><b>{infoObject.Title}:</b></label>
                    <div className="flex space-x-4">
                        <FormControl component="fieldset">
                            <RadioGroup row className="mt-2" aria-label="Status"
                                name="Status"
                                value={infoObject?.Status}
                                onChange={(e) => handleInputChange(e, "InfoObjectList", infoObject)}>
                                <FormControlLabel value="Positive" control={<Radio />} label="Positive" />
                                <FormControlLabel value="Negative" control={<Radio />} label="Negative" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>

                {/* Conditional "Number" or "Description" field */}
                <div className="col-span-1"></div>

                <div className="col-span-1"></div>
                {infoObject.Type === 1 ? (
                    <div className="col-span-1">
                        <label>Number:</label>
                        <input type="text" className="border border-gray-300 w-full p-1" placeholder="Enter Number" name="DetailsContent" value={infoObject.DetailsContent ?? ""} onChange={(e) => handleInputChange(e, "InfoObjectList", infoObject)} />
                    </div>
                ) : infoObject.Type === 2 ? (
                    <div className="col-span-1">
                        <label>Description:</label>
                        <input type="text" className="border border-gray-300 w-full p-1" placeholder="Enter Description" name="DetailsContent" value={infoObject.DetailsContent ?? ""} onChange={(e) => handleInputChange(e, "InfoObjectList", infoObject)} />
                    </div>
                ) : null}
                {/* Source Field */}
                <div className="col-span-1">
                    <label>Source:</label>
                    <input type="text" className="border border-gray-300 w-full p-1" placeholder="Enter Source" name="Source" value={infoObject.Source ?? ""} onChange={(e) => handleInputChange(e, "InfoObjectList", infoObject)} />
                </div>
                {(infoObject.Title === "Scars/Marks/Tattoos" || infoObject.Title === "Fingerprint Images and/or Fingerprint Identification Network (FIN) Number") && (
                    <div
                        className="col-span-2"
                        onDrop={(e) => handleDrop(e, infoObject, infoObject.Title === "Scars/Marks/Tattoos" ? "Marks" : "Fingerprints", "InfoObjectList")}
                        // onPaste={(e) => handlePaste(e, infoObject, infoObject.Title === "Scars/Marks/Tattoos" ? "Marks" : "Fingerprints", "InfoObjectList")}
                        // onContextMenu={(e) => handleContextMenu(e, infoObject, infoObject.Title === "Scars/Marks/Tattoos" ? "Marks" : "Fingerprints", "InfoObjectList")}
                        onDragOver={(e) => e.preventDefault()}
                        style={{
                            border: '5px dashed #ccc',
                            padding: '20px',
                            textAlign: 'center',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        {infoObject.Files && infoObject.Files.length > 0 ? (
                            infoObject.Files.filter((file: any) => !file.deleted).map((file: any, idx: number) => (
                                <div key={idx}>
                                    <Box position="relative" display="inline-block">
                                        <img
                                            src={file?.Content ?  file?.Content.includes('base64') ? file?.Content : `data:${file.FileEncoding};base64,${file.Content}`: ""}                                                                             
                                            alt=""
                                            style={{ maxWidth: '100%' }}
                                        />
                                        <IconButton
                                            aria-label="delete"
                                            onClick={() => handleRemoveFile(file, "InfoObjectList", infoObject)}
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                right: 0,
                                                zIndex: 1,
                                                color: 'red'
                                            }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                </div>
                            ))
                        ) : (
                                `Drag & Drop Your Photos of ${infoObject.Title === 'Scars/Marks/Tattoos' ? 'Scars/Marks/Tattoos' : 'Fingerprint Images'}`
                        )}
                    </div>
                )}
                {infoObject.Title === "Video/Audio Recordings" && (
                    <div
                        className="col-span-2"
                        onDrop={(e) => handleDrop(e, infoObject, "Video/Audio", "InfoObjectList")}
                        // onPaste={(e) => handlePaste(e, infoObject, "Video/Audio", "InfoObjectList")}
                        onDragOver={(e) => e.preventDefault()}
                        style={{
                            border: '5px dashed #ccc',
                            padding: '20px',
                            textAlign: 'center',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        {infoObject.Files && infoObject.Files.length > 0 ? (
                            infoObject.Files.filter((file: any) => !file.deleted).map((file: any, idx: number) => (
                                <div key={idx}>
                                    <Box position="relative" display="inline-block">
                                        {file.FileEncoding.includes('video') ? (
                                            <>
                                                {file.FileEncoding === 'video/x-matroska' && !isSupportedInFirefox() ? (
                                                    <>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <p style={{fontSize: '0.8rem'} }>
                                                                This video format (.mkv) is not supported for playback outside of Edge and Chrome. This file will still be submitted,
                                                                but you will have to download it to play it back later. Feel free to keep adding files.
                                                            </p>
                                                            <br />
                                                            <img
                                                                src={VideoIcon}
                                                                alt="Unsupported file icon"
                                                                className="h-16 w-16"
                                                                style={{ margin: '0 auto' }} // Ensures image is centered
                                                            />
                                                        </div>
                                                    </>
                                                ) : (
                                                    <video
                                                        controls
                                                        style={{ maxWidth: '100%' }}
                                                        onError={(e) => {
                                                            const videoElement = e.target as HTMLVideoElement; // Cast EventTarget to HTMLVideoElement
                                                            videoElement.onerror = null; // prevent looping in case of subsequent errors
                                                            videoElement.outerHTML = `
                                                                                        <p>This video format is not supported by your browser. You can download the video instead:</p>
                                                                                        <a href="${file?.Content ? file?.Content.includes('base64') ? file?.Content : `data:${file.FileEncoding};base64,${file.Content}`:""}" download="${file.FileName}">Download Video</a>
                                                                                    `;
                                                        }}
                                                    >
                                                        <source src={file?.Content ? file?.Content.includes('base64') ? file?.Content : `data:${file.FileEncoding};base64,${file.Content}`:""} type={`${file.FileEncoding === 'video/x-matroska' ? 'video/mp4' : file.FileEncoding}`} />
                                                        Your browser does not support the video tag.
                                                    </video>
                                                )}
                                            </>
                                        ) : file.FileEncoding.includes('audio') ? (
                                            <audio controls>
                                                <source src={file?.Content ? file?.Content.includes('base64') ? file?.Content : `data:${file.FileEncoding};base64,${file.Content}`:""} />
                                                Your browser does not support the audio element.
                                            </audio>
                                        ) : null}
                                        <IconButton
                                            aria-label="delete"
                                            onClick={() => handleRemoveFile(file, "InfoObjectList", infoObject)}
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                right: 0,
                                                zIndex: 1,
                                                color: 'red'
                                            }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                </div>
                            ))
                        ) : (
                            `Drag & Drop Your ${infoObject.Title}`
                        )}
                    </div>
                ) }
            </div>
        );
    };

    const renderPermitFields = (infoObject: any) => {
        return (
            <div key={infoObject.GUID} className="grid grid-cols-2 border-2 border-black gap-1 p-2 bg-tsc-bg">
                <div className="col-span-2">
                    <label><b>{infoObject.Title}:</b></label>
                    <div className="flex space-x-4">
                        <FormControl component="fieldset">
                            <RadioGroup row className="mt-2" aria-label="Status"
                                name="Status"
                                value={infoObject?.Status}
                                onChange={(e) => handleInputChange(e, "InfoObjectList", infoObject)}>
                                <FormControlLabel value="Positive" control={<Radio />} label="Positive" />
                                <FormControlLabel value="Negative" control={<Radio />} label="Negative" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
                <div className="col-span-1"></div>
                <div className="col-span-1"></div>
                {infoObject.Type === 1 ? (
                    <>
                        <div className="col-span-1">
                            <label>Miscellaneous:</label>
                            <input type="text" className="border border-gray-300 w-full p-1" placeholder="Enter Misc. Details" name="DetailsContent" value={infoObject.DetailsContent ?? ""} onChange={(e) => handleInputChange(e, "InfoObjectList", infoObject)} />
                        </div>
                        <div className="col-span-1 pl-4 pt-5">
                            <Autocomplete
                                id="free-solo-demo"
                                autoComplete={true}
                                options={stateList.map((option: any) => option.Key)}
                                value={infoObject?.State ?? ""}
                                onChange={(event, newValue) => handleInputChange({ target: { name: "State", value: newValue as string } }, "InfoObjectList", infoObject)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="State"
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                    />
                                )}
                            />
                        </div>
                    </>
                ) : infoObject.Type === 2 ? (
                    <>
                        <div className="col-span-1">
                            <label>License Number:</label>
                            <input type="text" className="border border-gray-300 w-full p-1" placeholder="Enter License #" name="LicenseNo" value={infoObject.LicenseNo ?? ""} onChange={(e) => handleInputChange(e, "InfoObjectList", infoObject)} />
                            <label>Status:</label>
                            <input type="text" className="border border-gray-300 w-full p-1" placeholder="Enter Status" name="DetailsContent" value={infoObject.DetailsContent ?? ""} onChange={(e) => handleInputChange(e, "InfoObjectList", infoObject)} /> 
                        </div>
                        <div className="col-span-1 pl-4 pt-5">
                            <DatePicker
                                label="Expiration Date"
                                value={infoObject?.ExpirationDate ?? ""}
                                onChange={(date) => handleInputChange({ target: { name: "ExpirationDate", value: (date ?? "") } }, "InfoObjectList", infoObject)}
                                    renderInput={(params) => <TextField {...params} error={infoObject?.ExpirationDate && infoObject?.ExpirationDate?.trim()?.length > 1  ?  Date.parse(infoObject?.ExpirationDate) ? false : true : false} size="small" fullWidth/>}
                            />
                            <Autocomplete
                                id="free-solo-demo"
                                autoComplete={true}
                                options={stateList.map((option: any) => option.Key)}
                                value={infoObject?.State ?? ""}
                                onChange={(event, newValue) => handleInputChange({ target: { name: "State", value: newValue as string } }, "InfoObjectList", infoObject)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="State"
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        style={{marginTop: 18} }
                                    />
                                )}
                            />
                        </div>
                    </>
                ) : null}
            </div>
        )
    }

    const isStepOptional = (step: number) => {
        return (step >= 3);
    }

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    }

    const handleNext = async () => {
        let bValid: boolean = true;
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }    
        
        if (activeStep === steps.length - 1) {
            if(!form.TSCPerson || form.TSCPerson.length === 0){
                bValid = false
            }
            if(form?.TSCPerson && form?.TSCPerson.length > 0){
                for(let person of form?.TSCPerson){
                    if(!person?.Person_FirstName || person.Person_FirstName.trim().length < 1){
                        bValid = false
                    }
                    if(!person?.Person_LastName || person?.Person_LastName.trim().length < 1){
                        bValid = false
                    }
                    if(!person?.Person_DOB){
                        bValid = false
                    }
                }
            }
            if(bValid){               
                form.ReportDate = typeof(reportDate) === 'string' ? reportDate : DateToString(reportDate ?? new Date())
                handleFormSubmit(form);
            }
            else{
                alert('Please fill out first name, last name and DOB (mandatory fields) in the Tier 1 General Information section before submitting the report.')
            }
        }
        if(bValid){
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setSkipped(newSkipped);
        }
    }

    const handleStep = (step: number) => () => {
        setActiveStep(step);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        })
    }

    const handleReset = () => {
        let temp = { ...form }
        setActiveStep(0);

        temp = ({})
        setForm(temp)
    }
    return (
        pageLoading ? 
            <div className="mx-auto" style={{textAlign:'center'}}>
                <div className="mx-auto px-10 py-8 font-bold" style={{width:'40%', borderRadius:'6px', fontSize:'18px'}}>
                    <div> Please wait... Your page is being prepared. </div>
                    <div className={"mt-8"}>
                        <HourglassBottomIcon
                            sx={{
                                animation: "spin 2s linear infinite",
                                "@keyframes spin": {
                                    "0%": {
                                        transform: "rotate(360deg)",
                                    },
                                    "100%": {
                                        transform: "rotate(0deg)",
                                    },
                                },
                                fontSize:'60px',
                                color:'#3b63c1'
                            }}
                        />
                    </div>
                </div>
            </div> 
            
        :
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className="w-full mt-4">
                <Stepper activeStep={activeStep} connector={<CustomStepConnector /> }>
                    {steps.map((label, index) => {
                        const stepProps: { completed?: boolean } = {};
                        const labelProps: {
                            optional?: React.ReactNode;
                        } = {};
                        if (isStepOptional(index)) {
                            labelProps.optional = (
                                <Typography variant="caption">Optional</Typography>
                            );
                        }
                        if (isStepSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={label} {...stepProps}>
                                <StepButton color="inherit" onClick={handleStep(index)}>{label}</StepButton>
                            </Step>
                        );
                    })}
                </Stepper>
                {activeStep === steps.length ? (
                    <React.Fragment>
                        <Typography className="mt-2 mb-1" variant="h3" align="center">
                            All steps completed - you&apos;re finished
                        </Typography>
                        <div className="flex flex-row pt-2">
                            <div className="flex-auto" />
                            <Button onClick={() => handleDialog("Reset", true)}>Reset</Button>
                            <Dialog
                                open={resetOpen}
                                onClose={() => handleDialog("Reset", false)}
                                aria-labelledby="clear-dialog"
                                aria-describedby="clear-dyrs"
                            >
                                <DialogTitle id="clear-dialog-title">
                                    {"Reset the form?"}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="clear-dyrs">
                                        Are you sure you would like to reset the form? All data will be lost.
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => handleDialog("Reset", false)}>No</Button>
                                    <Button onClick={() => { handleReset(); handleDialog("Reset", false) }} autoFocus>
                                        Yes
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {activeStep === 0 && (
                            <>
                                <div className="flex justify-center my-4">
                                        <img src={TSCLogo} alt="TSC Logo" className="h-32" />
                                </div>
                                <div className="grid grid-cols-2 gap-4 w-3/5 mx-auto mt-4 h-1/2">
                                        <div className="bg-tsc-bg p-4 border border-black col-span-1 row-span-2 justify-center">
                                            <FormControl component="fieldset">
                                                <FormLabel component="legend">Has an RFI been conducted previously on this individual?
                                                </FormLabel>
                                                <RadioGroup row className="mt-2" aria-label="RFIPrevConducted"
                                                    name="RFIPrevConducted"
                                                    value={form?.RFIPrevConducted}
                                                    onChange={handleInputChange}
                                                    defaultValue="No">
                                                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                                </RadioGroup>
                                            </FormControl>
                                            <TextField
                                                label="Prior Encounter Dates"
                                                name="PriorEncounterDates"
                                                value={form?.PriorEncounterDates ?? ""}
                                                onChange={handleInputChange}
                                                multiline
                                                style={{marginTop: '10px'} }
                                                fullWidth
                                            />
                                            <FormControl component="fieldset">
                                                <FormControlLabel
                                                    control={<Checkbox checked={form?.NoUpdatedInformation ?? false}
                                                                       onChange={(e) => handleCheckboxChange(e)}
                                                                       name="NoUpdatedInformation"
                                                                       />}
                                                    label="There is no updated information since the last RFI."
                                                    className="mt-10"
                                                />

                                            </FormControl>
                                        </div>
                                        <div className="bg-tsc-bg p-4 border border-black col-span-1 row-span-2">
                                            <div className="grid grid-cols-2 grid-rows-2 gap-4 items-center">
                                                {/* {form?.TSCPhotoList?.filter(p => !p?.deleted)?.map((row, index) => ( */}
                                                {displayPhotoList?.filter(p => !p?.deleted)?.map((row, index) => (

                                                    <React.Fragment key={index}>
                                                        {/* Photo Area */}
                                                        <div className="row-span-2">
                                                            <div>
                                                                <Button
                                                                    onClick={() => deleteTableRow("TSCPhotoList", row)}
                                                                    variant="outlined"
                                                                    color="error"
                                                                    sx={{marginBottom: 2} }
                                                                >
                                                                    Delete Entry
                                                                </Button>
                                                            </div>
                                                            <div
                                                                onDrop={(e) => handleDrop(e, row, "TSC Photo", "TSCPhotoList")}
                                                                // onPaste={(e) => handlePaste(e, row, "TSC Photo", "TSCPhotoList")}
                                                                // onContextMenu={(e) => handleContextMenu(e, row, "TSC Photo", "TSCPhotoList")}
                                                                onDragOver={(e) => e.preventDefault()}
                                                                style={{
                                                                    border: '5px dashed #ccc',
                                                                    padding: '20px',
                                                                    textAlign: 'center',
                                                                    height: '100%',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center'
                                                                }}
                                                            >
                                                                {row?.Files && row?.Files?.length > 0 ? (
                                                                    row.Files.filter((file: any) => file.Origination === "TSC Photo" && !file?.deleted).map((file: any, idx: number) => (                                                                                                                                         
                                                                        <div key={idx}>
                                                                            {file?.Content && file?.Content?.length > 0 ?
                                                                             <>
                                                                                 <Box position="relative" display="inline-block">                                                                                                                                                                  
                                                                                    <img
                                                                                    src={file?.Content ? file?.Content.includes('base64') ? file?.Content : `data:${file.FileEncoding};base64,${file.Content}`:""}                                                                             
                                                                                    alt=""
                                                                                    style={{ maxWidth: '100%' }}
                                                                                    />
                                                                                    <IconButton
                                                                                    aria-label="delete"
                                                                                    onClick={() => handleRemoveFile(file, "TSCPhotoList", row)}
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        top: 0,
                                                                                        right: 0,
                                                                                        zIndex: 1,
                                                                                        color: 'red'
                                                                                    }}
                                                                                    >
                                                                                    <CloseIcon />
                                                                                    </IconButton>
                                                                                                                                                                                                                                           
                                                                                </Box>                                                                                                                                                    
                                                                            </>
                                                                            :
                                                                            ("Drag & Drop Your Photo Here")
                                                                            }
                                                                                                                                                                                                                                                                                                           
                                                                        </div>                                                                    
                                                                    ))
                                                                ) : (
                                                                    "Drag & Drop Your Photo Here"
                                                                )}
                                                            </div>
                                                        </div>

                                                        {/* Photo Date Field */}
                                                        <div>
                                                            <DatePicker
                                                                label="Photo Date"
                                                                value={row?.PhotoDate ?? ""}
                                                                onChange={(date) => handleInputChange({ target: { name: "PhotoDate", value: (date ?? "") } }, "TSCPhotoList", row)}
                                                                renderInput={(params) => <TextField {...params} error={row?.PhotoDate && row?.PhotoDate.trim().length > 1  ?  Date.parse(row?.PhotoDate) ? false : true : false} className="w-full" />}
                                                            />
                                                        </div>

                                                        {/* Source Field */}
                                                        <div>
                                                            <TextField
                                                                label="Source"
                                                                name="Source"
                                                                value={row?.Source ?? ""}
                                                                onChange={(e) => handleInputChange(e, "TSCPhotoList", row)}
                                                                className="w-full bg-blue-50"
                                                            />
                                                        </div>
                                                    </React.Fragment>
                                                ))}
                                            </div>
                                            <div className="flex justify-center mt-4">
                                                <ButtonGroup variant="contained" aria-label="Table buttons" size="small"
                                                    color="inherit">
                                                    <Button
                                                        color="inherit"
                                                        onClick={() => addTableRow("TSCPhotoList", "GUID", GetGUID())}
                                                        startIcon={<AddIcon />}
                                                        variant="contained"
                                                    >
                                                        Add Another Photo
                                                    </Button>
                                                    <Button
                                                        color="inherit"
                                                        onClick={() => handleDialog("TSC Photo", true)}
                                                        startIcon={<ClearIcon />}
                                                        variant="contained"
                                                    >
                                                        Clear All Photos
                                                    </Button>
                                                    <Dialog
                                                        open={photoClearOpen}
                                                        onClose={() => handleDialog("TSC Photo", false)}
                                                        aria-labelledby="clear-dialog"
                                                        aria-describedby="clear-dyrs"
                                                    >
                                                        <DialogTitle id="clear-dialog-title">
                                                            {"Clear all existing photos?"}
                                                        </DialogTitle>
                                                        <DialogContent>
                                                            <DialogContentText id="clear-dyrs">
                                                                Are you sure you would like to clear all photos?
                                                            </DialogContentText>
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button onClick={() => handleDialog("TSC Photo", false)}>No</Button>
                                                            <Button onClick={() => { clearTableRows("TSCPhotoList"); handleDialog("TSC Photo", false) }} autoFocus>
                                                                Yes
                                                            </Button>
                                                        </DialogActions>
                                                    </Dialog>
                                                </ButtonGroup>
                                            </div>
                                        </div>

                                    <div className="bg-tsc-bg p-4 border border-black col-span-2 flex space-x-4">
                                            <DatePicker
                                                label="Date"
                                                // value={form?.ReportDate ?? ""}
                                                value={reportDate}
                                                // onChange={(date) => handleInputChange({ target: { name: "ReportDate", value: (date ?? "") } }) ;}
                                                onChange={(date)=> setReportDate(date ?? new Date()) }
                                                renderInput={(params) => <TextField {...params} className="flex-1" />}
                                            />
                                            <TextField
                                                label="Service Request Number (SRN)"
                                                name="SRN"
                                                value={form?.SRN ?? ""}
                                                onChange={handleInputChange}
                                                className="flex-1"
                                            />
                                            <TextField
                                                label="National Unique Identifying Number (NUIN)"
                                                name="NUIN"
                                                value={form?.NUIN ?? ""}
                                                onChange={handleInputChange}
                                                className="flex-1"
                                            />
                                    </div>
                                </div>
                            </>
                        )}
                        { activeStep === 1 && (
                            <>
                                <div className="mt-4 w-3/5 border gap-4 mx-auto border-black p-4 bg-tsc-bg">
                                    <div className="grid grid-cols-2 gap-4 justify-center text-red">
                                        {/* First row, spans both columns */}
                                        <div className="col-span-2">
                                            <Typography align="center" sx={{color: 'red', fontFamily: 'sans-serif', fontStyle: 'bold', fontSize: '2rem', fontWeight: 1200, WebkitTextStroke: '1px black'}}>General Information</Typography>
                                        </div>
                                        {form?.TSCPerson?.map((row, index) => (
                                            <>
                                                <div className="col-span-1 pr-10">
                                                    <TextField
                                                        label="First Name"
                                                        name="Person_FirstName"
                                                        value={row?.Person_FirstName ?? ""}
                                                        onChange={(e) => handleInputChange(e, "TSCPerson", row)}
                                                        fullWidth
                                                        error={row?.Person_FirstName && row?.Person_FirstName?.trim().length > 0 ? false : true }
                                                    />
                                                </div>
                                                <div className="col-span-1 pl-10">
                                                    <TextField
                                                        label="Last Name"
                                                        name="Person_LastName"
                                                        value={row?.Person_LastName ?? ""}
                                                        onChange={(e) => handleInputChange(e, "TSCPerson", row)}
                                                        fullWidth
                                                        error={row?.Person_LastName && row?.Person_LastName?.trim().length > 0 ? false : true }
                                                    />
                                                </div>
                                                <div className="col-span-1 pr-10">
                                                    <DatePicker
                                                        label="Date of Birth"
                                                        value={row?.Person_DOB ?? ""}
                                                        onChange={(date) => handleInputChange({ target: { name: "Person_DOB", value: (date ?? "") } }, "TSCPerson", row)}
                                                        renderInput={(params) => <TextField {...params} error={row?.Person_DOB && row?.Person_DOB.trim().length > 0 && Date.parse(row?.Person_DOB) ? false : true } fullWidth />}
                                                    />
                                                </div>
                                                <div className="col-span-1 pl-10">
                                                    <TextField
                                                        label="Social Security Number (SSN)"
                                                        name="Person_SSN"
                                                        value={row?.Person_SSN ?? ""}
                                                        onChange={(e) => handleInputChange(e, "TSCPerson", row)}
                                                        fullWidth
                                                    />
                                                </div>
                                                <div className="col-span-1 pr-10">
                                                    <TextField
                                                        label="Possible Alias Information"
                                                        name="Person_Alias"
                                                        value={row?.Person_Alias ?? ""}
                                                        onChange={(e) => handleInputChange(e, "TSCPerson", row)}
                                                        fullWidth
                                                        multiline
                                                    />
                                                </div>
                                                <div className="col-span-1 pl-10">
                                            
                                                </div>
                                                <div className="col-span-2">
                                                    <Typography variant="h6">Place of Birth</Typography>
                                                </div>
                                                <div className="col-span-1 pr-10">
                                                    <TextField
                                                        label="City"
                                                        name="Person_POBCity"
                                                        value={row?.Person_POBCity ?? ""}
                                                        onChange={(e) => handleInputChange(e, "TSCPerson", row)}
                                                        fullWidth
                                                    />
                                                </div>
                                                <div className="col-span-1 pl-10">
                                                    <Autocomplete
                                                        id="free-solo-demo"
                                                        autoComplete={true}
                                                        options={stateList.map((option: any) => option.Key)}
                                                        value={row?.Person_POBState ?? ""}
                                                        onChange={(event, newValue) => handleInputChange({ target: { name: "Person_POBState", value: newValue as string } }, "TSCPerson", row)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="State"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-span-1 pr-10">
                                                    <TextField
                                                        label="ZIP"
                                                        name="Person_POBZIP"
                                                        value={row?.Person_POBZIP ?? ""}
                                                        onChange={(e) => handleInputChange(e, "TSCPerson", row)}
                                                        fullWidth
                                                    />
                                                </div>
                                                <div className="col-span-1 pl-10">
                                                    
                                                </div>
                                                <div className="col-span-2">
                                                    <Typography variant="h6">Driver's License</Typography>
                                                </div>
                                                <div className="col-span-1 pr-10">
                                                    <TextField
                                                        label="Driver's License #"
                                                        name="Person_DLNo"
                                                        value={row?.Person_DLNo ?? ""}
                                                        onChange={(e) => handleInputChange(e, "TSCPerson", row)}
                                                        fullWidth
                                                    />
                                                </div>
                                                <div className="col-span-1 pl-10">
                                                    <Autocomplete
                                                        id="free-solo-demo"
                                                        autoComplete={true}
                                                        options={stateList.map((option: any) => option.Key)}
                                                        value={row?.Person_DLState ?? ""}
                                                        onChange={(event, newValue) => handleInputChange({ target: { name: "Person_DLState", value: newValue as string } }, "TSCPerson", row)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="State Issued"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-span-1 pr-10">
                                                    <DatePicker
                                                        label="Date Issued"
                                                        value={row?.Person_DLIssDate ?? ""}
                                                        onChange={(date) => handleInputChange({ target: { name: "Person_DLIssDate", value: (date ?? "") } }, "TSCPerson", row)}
                                                        renderInput={(params) => <TextField {...params} error={row?.Person_DLIssDate && row?.Person_DLIssDate.trim().length > 1  ?  Date.parse(row?.Person_DLIssDate) ? false : true : false} fullWidth />}
                                                    />
                                                </div>
                                                <div className="col-span-1 pl-10">
                                                    <DatePicker
                                                        label="Expiration Date"
                                                        value={row?.Person_DLExpDate ?? ""}
                                                        onChange={(date) => handleInputChange({ target: { name: "Person_DLExpDate", value: (date ?? "") } }, "TSCPerson", row)}
                                                        renderInput={(params) => <TextField {...params} error={row?.Person_DLExpDate && row?.Person_DLExpDate.trim().length > 1  ?  Date.parse(row?.Person_DLExpDate) ? false : true : false} fullWidth />}
                                                    />
                                                </div>
                                                <div className="col-span-1 pr-10">
                                                    <TextField
                                                        label="Valid Class"
                                                        name="Person_DLClass"
                                                        value={row?.Person_DLClass ?? ""}
                                                        onChange={(e) => handleInputChange(e, "TSCPerson", row)}
                                                        fullWidth
                                                    />
                                                </div>
                                                <div className="col-span-1 pl-10">
                                                    <TextField
                                                        label="Restrictions, Conditional Messages, Endorsements"
                                                        name="Person_DLRestriction"
                                                        value={row?.Person_DLRestriction ?? ""}
                                                        onChange={(e) => handleInputChange(e, "TSCPerson", row)}
                                                        fullWidth
                                                        multiline
                                                    />
                                                </div>
                                                <div className="col-span-1">
                                                    <Typography variant="h6">Department of Corrections</Typography>
                                                </div>
                                                <div className="col-span-1"></div>
                                                <div className="col-span-1 pr-10">
                                                    <TextField
                                                        label="State Department of Corrections Number"
                                                        name="Person_DOCNo"
                                                        value={row?.Person_DOCNo ?? ""}
                                                        onChange={(e) => handleInputChange(e, "TSCPerson", row)}
                                                        fullWidth
                                                    />
                                                </div>
                                                <div className="col-span-1 pl-10">
                                                    <TextField
                                                        label="Department of Corrections Number Source"
                                                        name="Person_DOCSource"
                                                        value={row?.Person_DOCSource ?? ""}
                                                        onChange={(e) => handleInputChange(e, "TSCPerson", row)}
                                                        fullWidth
                                                    />
                                                </div>
                                                <div className="col-span-1 pr-10">
                                                    <Typography variant="h6">State Driver's License Address</Typography>
                                                </div>
                                                <div className="col-span-1">
                                                </div>
                                                <div className="col-span-1 pr-10">
                                                    <TextField
                                                        label="Source"
                                                        name="Person_DLAddressSource"
                                                        value={row?.Person_DLAddressSource ?? ""}
                                                        onChange={(e) => handleInputChange(e, "TSCPerson", row)}
                                                        fullWidth
                                                    />
                                                </div>
                                                <div className="col-span-1 pl-10">
                                                    <TextField
                                                        label="Street"
                                                        name="Person_DLAddress"
                                                        value={row?.Person_DLAddress ?? ""}
                                                        onChange={(e) => handleInputChange(e, "TSCPerson", row)}
                                                        fullWidth
                                                    />
                                                </div>
                                                <div className="col-span-1 pr-10">
                                                    <TextField
                                                        label="City"
                                                        name="Person_DLAddressCity"
                                                        value={row?.Person_DLAddressCity ?? ""}
                                                        onChange={(e) => handleInputChange(e, "TSCPerson", row)}
                                                        fullWidth
                                                    />
                                                </div>
                                                <div className="col-span-1 pl-10">
                                                        <Autocomplete
                                                            id="free-solo-demo"
                                                            autoComplete={true}
                                                            options={stateList.map((option: any) => option.Key)}
                                                            value={row?.Person_DLAddressState ?? ""}
                                                            onChange={(event, newValue) => handleInputChange({ target: { name: "Person_DLAddressState", value: newValue as string } }, "TSCPerson", row)}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="State"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                />
                                                            )}
                                                        />
                                                </div>
                                                <div className="col-span-1 pr-10">
                                                    <TextField
                                                        label="ZIP Code"
                                                        name="Person_DLAddressZIP"
                                                        value={row?.Person_DLAddressZIP ?? ""}
                                                        onChange={(e) => handleInputChange(e, "TSCPerson", row)}
                                                        fullWidth
                                                    />
                                                </div>
                                            </>
                                        ))}
                                        <div className="col-span-1 pl-10"></div>
                                        <div className="col-span-1 pr-10">
                                            <Typography variant="h6">Historical Address(es)</Typography>
                                        </div>
                                        <div className="col-span-1"></div>
                                        <div className="col-span-1 pr-10">
                                            <ButtonGroup>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<AddIcon />}
                                                    onClick={() => addTableRow("AddressList", "Origination", "Historical Address")}
                                                >
                                                    Add Address
                                                </Button>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<ClearIcon />}
                                                    onClick={() => handleDialog("Historical Address", true)}
                                                >
                                                    Clear All Addresses
                                                </Button>
                                                <Dialog 
                                                    open={historicalAddressClearOpen}
                                                    onClose={() => handleDialog("Historical Address", false)}
                                                    aria-labelledby="clear-dialog"
                                                    aria-describedby="clear-dialog"
                                                >
                                                    <DialogTitle id="clear-dialog-title2">
                                                        {"Clear all existing historical addresses?"}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="clear-historical-addresses">
                                                            Are you sure you would like to clear all historical addresses in the list?
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={() => handleDialog("Historical Address", false)}>No</Button>
                                                        <Button onClick={() => {clearTableRows("AddressList", "Origination", "Historical Address"); handleDialog("Historical Address", false)} } autoFocus>
                                                            Yes
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </ButtonGroup>
                                        </div>
                                        <div className="col-span-1"></div>
                                        {form?.AddressList?.filter(p => !p?.deleted && p.Origination === "Historical Address")?.map((row, index) => (
                                            <>
                                                <div className="col-span-2 flex items-center justify-center">
                                                    <Typography align="center" variant="body1">Address {index + 1}</Typography>
                                                    <Tooltip title="Delete Address">
                                                        <IconButton
                                                            aria-label="delete"
                                                            onClick={() => deleteTableRow("AddressList", row)}
                                                        >
                                                            <DeleteIcon sx={{ color: "red" }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                                <div className="col-span-1 pr-10">
                                                    <TextField
                                                        label="Source"
                                                        name="Source"
                                                        value={row?.Source ?? ""}
                                                        fullWidth
                                                        onChange={(e) => handleInputChange(e, "AddressList", row)}
                                                    />
                                                </div>
                                                <div className="col-span-1 pl-10">
                                                    <TextField
                                                        label="Street"
                                                        name="Street"
                                                        value={row?.Street ?? ""}
                                                        fullWidth
                                                        onChange={(e) => handleInputChange(e, "AddressList", row)}
                                                    />
                                                </div>
                                                <div className="col-span-1 pr-10">
                                                    <TextField
                                                        label="City"
                                                        name="City"
                                                        value={row?.City ?? ""}
                                                        fullWidth
                                                        onChange={(e) => handleInputChange(e, "AddressList", row)}
                                                    />
                                                </div>
                                                <div className="col-span-1 pl-10">
                                                    <Autocomplete
                                                        id="free-solo-demo"
                                                        autoComplete={true}
                                                        options={stateList.map((option: any) => option.Key)}
                                                        value={row?.State ?? ""}
                                                        onChange={(event, newValue) => handleInputChange({ target: { name: "State", value: newValue as string } }, "AddressList", row)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="State"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-span-1 pr-10">
                                                    <TextField
                                                        label="ZIP Code"
                                                        name="ZIP"
                                                        fullWidth
                                                        value={row?.ZIP ?? ""}
                                                        onChange={(e) => handleInputChange(e, "AddressList", row)}
                                                    />
                                                </div>
                                                <div className="col-span-1"></div>
                                            </>
                                        ))}
                                        <div className="col-span-1 pr-10">
                                            <Typography variant="h6">Associated Telephone Number(s)</Typography>
                                        </div>
                                        <div className="col-span-1"></div>
                                        <div className="col-span-1 pr-10">
                                            <ButtonGroup>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<AddIcon />}
                                                    onClick={() => addTableRow("AddressList", "Origination", "Telephone Numbers")}
                                                >
                                                    Add Telephone Number
                                                </Button>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<ClearIcon />}
                                                    onClick={() => handleDialog("Telephone Numbers", true)}
                                                >
                                                    Clear All Numbers
                                                </Button>
                                                <Dialog 
                                                    open={telephoneNumberClearOpen}
                                                    onClose={() => handleDialog("Telephone Numbers", false)}
                                                    aria-labelledby="clear-dialog"
                                                    aria-describedby="clear-dialog"
                                                >
                                                    <DialogTitle id="clear-dialog-title3">
                                                        {"Clear all existing telephone numbers?"}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="clear-telephone-numbers">
                                                            Are you sure you would like to clear all telephone numbers in the list?
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={() => handleDialog("Telephone Numbers", false)}>No</Button>
                                                        <Button onClick={() => {clearTableRows("AddressList", "Origination", "Telephone Numbers"); handleDialog("Telephone Numbers", false)} } autoFocus>
                                                            Yes
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </ButtonGroup>
                                        </div>
                                        <div className="col-span-1"></div>
                                        {form?.AddressList?.filter(p => !p?.deleted && p.Origination === "Telephone Numbers")?.map((row, index) => (
                                            <>
                                                <div className="col-span-2 flex items-center justify-center">
                                                <Typography align="center" variant="body1">Telephone Number {index + 1}</Typography>
                                                <Tooltip title="Delete Telephone Number">
                                                    <IconButton
                                                        aria-label="delete"
                                                        onClick={() => deleteTableRow("AddressList", row)}
                                                    >
                                                        <DeleteIcon sx={{ color: "red" }} />
                                                    </IconButton>
                                                </Tooltip>
                                                </div >
                                                <div className="col-span-1 pr-10">
                                                    <TextField
                                                        label="Telephone Number"
                                                        name="TelephoneNumbers"
                                                        fullWidth
                                                        value={row?.TelephoneNumbers ?? ""}
                                                        onChange={(e) => handleInputChange(e, "AddressList", row)}
                                                    />
                                                </div>
                                                <div className="col-span-1 pl-10">
                                                    <TextField
                                                        label="Source"
                                                        name="Source"
                                                        fullWidth
                                                        value={row?.Source ?? ""}
                                                        onChange={(e) => handleInputChange(e, "AddressList", row)}
                                                    />
                                                </div>
                                            </>
                                        ))}
                                        <div className="col-span-1 pr-10">
                                            <Typography variant="h6">Associated Email Address(es)</Typography>
                                        </div>
                                        <div className="col-span-1"></div>
                                        <div className="col-span-1 pr-10">
                                            <ButtonGroup>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<AddIcon />}
                                                    onClick={() => addTableRow("AddressList", "Origination", "Email Addresses")}
                                                >
                                                    Add Email Address
                                                </Button>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<ClearIcon />}
                                                    onClick={() => handleDialog("Email Addresses", true)}
                                                >
                                                    Clear All Email Addresses
                                                </Button>
                                                <Dialog 
                                                    open={emailAddressClearOpen}
                                                    onClose={() => handleDialog("Email Addresses", false)}
                                                    aria-labelledby="clear-dialog"
                                                    aria-describedby="clear-dialog"
                                                >
                                                    <DialogTitle id="clear-dialog-title4">
                                                        {"Clear all existing email addresses?"}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="clear-email-addresses">
                                                            Are you sure you would like to clear all email addresses in the list?
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={() => handleDialog("Email Addresses", false)}>No</Button>
                                                        <Button onClick={() => {clearTableRows("AddressList", "Origination", "Email Addresses"); handleDialog("Email Addresses", false)} } autoFocus>
                                                            Yes
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </ButtonGroup>
                                        </div>
                                        <div className="col-span-1"></div>
                                        {form?.AddressList?.filter(p => !p?.deleted && p.Origination === "Email Addresses")?.map((row, index) => (
                                            <>
                                            <div className="col-span-2 flex items-center justify-center">
                                                <Typography align="center" variant="body1">Email Address {index + 1}</Typography>
                                                <Tooltip title="Delete Email Address">
                                                    <IconButton
                                                        aria-label="delete"
                                                        onClick={() => deleteTableRow("AddressList", row)}
                                                    >
                                                        <DeleteIcon sx={{ color: "red" }} />
                                                    </IconButton>
                                                </Tooltip>
                                                </div >
                                            <div className="col-span-1 pr-10">
                                                <TextField
                                                    label="Email Address"
                                                    name="EmailAddress"
                                                    value={row?.EmailAddress ?? ""}
                                                    fullWidth
                                                    onChange={(e) => handleInputChange(e, "AddressList", row)}
                                                />
                                            </div>
                                            <div className="col-span-1 pl-10">
                                                <TextField
                                                    label="Source"
                                                    name="Source"
                                                    fullWidth
                                                    value={row?.Source ?? ""}
                                                    onChange={(e) => handleInputChange(e, "AddressList", row)}
                                                />
                                            </div>
                                            </>
                                        ))}
                                    </div>
                                </div>

                            </>
                        )}
                        {activeStep === 2 && (
                            <>
                               <div className="mt-4 w-4/5 border gap-4 mx-auto border-black p-4 bg-tsc-bg">
                                    <div className="grid grid-cols-2 gap-4 justify-center text-red">
                                        <div className="col-span-2">
                                            <Typography align="center" sx={{color: 'red', fontFamily: 'sans-serif', fontStyle: 'bold', fontSize: '2rem', fontWeight: 1200, WebkitTextStroke: '1px black'}}>Vehicles, Criminal History, Indices Checks</Typography>
                                        </div>
                                        <div className="col-span-1">
                                            <Typography variant="h6">Associated Vehicles</Typography>
                                        </div>
                                        <div className="col-span-1"></div>
                                        <div className="col-span-1">
                                            <ButtonGroup>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<AddIcon />}
                                                    onClick={() => addTableRow("VehicleList", "Origination", "Associated Vehicles")}
                                                >
                                                    Add Vehicle
                                                </Button>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<ClearIcon />}
                                                    onClick={() => handleDialog("Associated Vehicles", true)}
                                                >
                                                    Clear All Vehicles
                                                </Button>
                                                <Dialog 
                                                    open={associatedVehicleClearOpen}
                                                    onClose={() => handleDialog("Associated Vehicles", false)}
                                                    aria-labelledby="clear-dialog"
                                                    aria-describedby="clear-dialog"
                                                >
                                                    <DialogTitle id="clear-dialog-title5">
                                                        {"Clear all existing vehicles?"}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="clear-historical-addresses">
                                                            Are you sure you would like to clear all associated vehicles in the list?
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={() => handleDialog("Associated Vehicles", false)}>No</Button>
                                                        <Button onClick={() => {clearTableRows("VehicleList", "Origination", "Associated Vehicles"); handleDialog("Associated Vehicles", false)} } autoFocus>
                                                            Yes
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </ButtonGroup>
                                        </div>
                                        <div className="col-span-1"></div>
                                        {form?.VehicleList?.filter(p => !p?.deleted && p.Origination === "Associated Vehicles")?.map((row, index) => (
                                            <>
                                                <div className="col-span-2 flex items-center justify-center">
                                                    <Typography variant="body1" align="center">Vehicle {index + 1}</Typography>
                                                    <Tooltip title="Delete Vehicle">
                                                        <IconButton
                                                            aria-label="delete"
                                                            onClick={() => deleteTableRow("VehicleList", row)}
                                                        >
                                                            <DeleteIcon sx={{ color: "red" }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                                <div className="col-span-1 pr-10">
                                                    <TextField
                                                        label="Year"
                                                        name="Year"
                                                        fullWidth
                                                        value={row?.Year ?? ""}
                                                        onChange={(e) => handleInputChange(e, "VehicleList", row)}
                                                    />
                                                </div>
                                                <div className="col-span-1 pl-10">
                                                    <TextField
                                                        label="Color"
                                                        name="Color"
                                                        fullWidth
                                                        value={row?.Color ?? ""}
                                                        onChange={(e) => handleInputChange(e, "VehicleList", row)}
                                                    />
                                                </div>
                                                <div className="col-span-1 pr-10">
                                                    <TextField
                                                        label="Make"
                                                        name="Make"
                                                        fullWidth
                                                        value={row?.Make ?? ""}
                                                        onChange={(e) => handleInputChange(e, "VehicleList", row)}
                                                    />
                                                </div>
                                                <div className="col-span-1 pl-10">
                                                    <TextField
                                                        label="Model"
                                                        name="Model"
                                                        fullWidth
                                                        value={row?.Model ?? ""}
                                                        onChange={(e) => handleInputChange(e, "VehicleList", row)}
                                                    />
                                                </div>
                                                <div className="col-span-1 pr-10">
                                                    <TextField
                                                        label="License Plate Number"
                                                        name="LicensePlateNo"
                                                        fullWidth
                                                        value={row?.LicensePlateNo ?? ""}
                                                        onChange={(e) => handleInputChange(e, "VehicleList", row)}
                                                    />
                                                </div>
                                                <div className="col-span-1 pl-10">
                                                    <Autocomplete
                                                        id="free-solo-demo"
                                                        autoComplete={true}
                                                        options={stateList.map((option: any) => option.Key)}
                                                        value={row?.LicensePlateState ?? ""}
                                                        onChange={(event, newValue) => handleInputChange({ target: { name: "LicensePlateState", value: newValue as string } }, "VehicleList", row)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="License State"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-span-1 pr-10">
                                                    <TextField
                                                        label="Source"
                                                        name="Source"
                                                        fullWidth
                                                        value={row?.Source ?? ""}
                                                        onChange={(e) => handleInputChange(e, "VehicleList", row)}
                                                    />
                                                </div>
                                                <div className="col-span-1 pl-10">
                                                    <TextField
                                                        label="Co-Owner/Registrant"
                                                        name="CoOwnerRegistrant"
                                                        fullWidth
                                                        value={row?.CoOwnerRegistrant ?? ""}
                                                        onChange={(e) => handleInputChange(e, "VehicleList", row)}
                                                    />
                                                </div>
                                            </>
                                        ))}

                                        <div className="col-span-1">
                                            <Typography variant="h6">Prior Vehicles</Typography>
                                        </div>
                                        <div className="col-span-1"></div>
                                        <div className="col-span-1">
                                            <ButtonGroup>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<AddIcon />}
                                                    onClick={() => addTableRow("VehicleList", "Origination", "Prior Vehicles")}
                                                >
                                                    Add Vehicle
                                                </Button>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<ClearIcon />}
                                                    onClick={() => handleDialog("Prior Vehicles", true)}
                                                >
                                                    Clear All Vehicles
                                                </Button>
                                                <Dialog 
                                                    open={priorVehicleClearOpen}
                                                    onClose={() => handleDialog("Prior Vehicles", false)}
                                                    aria-labelledby="clear-dialog"
                                                    aria-describedby="clear-dialog"
                                                >
                                                    <DialogTitle id="clear-dialog-title5">
                                                        {"Clear all existing prior vehicles?"}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="clear-historical-addresses">
                                                            Are you sure you would like to clear all prior vehicles in the list?
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={() => handleDialog("Prior Vehicles", false)}>No</Button>
                                                        <Button onClick={() => {clearTableRows("VehicleList", "Origination", "Prior Vehicles"); handleDialog("Prior Vehicles", false)} } autoFocus>
                                                            Yes
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </ButtonGroup>
                                        </div>
                                        <div className="col-span-1"></div>
                                        {form?.VehicleList?.filter(p => !p.deleted && p.Origination === "Prior Vehicles").map((row, index) => (
                                            <>
                                                <div className="col-span-2 flex items-center justify-center">
                                                    <Typography variant="body1" align="center">Vehicle {index + 1}</Typography>
                                                    <Tooltip title="Delete Vehicle">
                                                        <IconButton
                                                            aria-label="delete"
                                                            onClick={() => deleteTableRow("VehicleList", row)}
                                                        >
                                                            <DeleteIcon sx={{ color: "red" }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                                <div className="col-span-1 pr-10">
                                                    <TextField
                                                        label="Year"
                                                        name="Year"
                                                        fullWidth
                                                        value={row?.Year ?? ""}
                                                        onChange={(e) => handleInputChange(e, "VehicleList", row)}
                                                    />
                                                </div>
                                                <div className="col-span-1 pl-10">
                                                    <TextField
                                                        label="Color"
                                                        name="Color"
                                                        fullWidth
                                                        value={row?.Color ?? ""}
                                                        onChange={(e) => handleInputChange(e, "VehicleList", row)}
                                                    />
                                                </div>
                                                <div className="col-span-1 pr-10">
                                                    <TextField
                                                        label="Make"
                                                        name="Make"
                                                        fullWidth
                                                        value={row?.Make ?? ""}
                                                        onChange={(e) => handleInputChange(e, "VehicleList", row)}
                                                    />
                                                </div>
                                                <div className="col-span-1 pl-10">
                                                    <TextField
                                                        label="Model"
                                                        name="Model"
                                                        fullWidth
                                                        value={row?.Model ?? ""}
                                                        onChange={(e) => handleInputChange(e, "VehicleList", row)}
                                                    />
                                                </div>
                                                <div className="col-span-1 pr-10">
                                                    <TextField
                                                        label="License Plate Number"
                                                        name="LicensePlateNo"
                                                        fullWidth
                                                        value={row?.LicensePlateNo ?? ""}
                                                        onChange={(e) => handleInputChange(e, "VehicleList", row)}
                                                    />
                                                </div>
                                                <div className="col-span-1 pl-10">
                                                    <Autocomplete
                                                        id="free-solo-demo"
                                                        autoComplete={true}
                                                        options={stateList.map((option: any) => option.Key)}
                                                        value={row?.LicensePlateState ?? ""}
                                                        onChange={(event, newValue) => handleInputChange({ target: { name: "LicensePlateState", value: newValue as string } }, "VehicleList", row)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="License State"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-span-1 pr-10">
                                                    <TextField
                                                        label="Source"
                                                        name="Source"
                                                        fullWidth
                                                        value={row?.Source ?? ""}
                                                        onChange={(e) => handleInputChange(e, "VehicleList", row)}
                                                    />
                                                </div>
                                                <div className="col-span-1 pl-10">
                                                    <TextField
                                                        label="Co-Owner/Registrant"
                                                        name="CoOwnerRegistrant"
                                                        fullWidth
                                                        value={row?.CoOwnerRegistrant ?? ""}
                                                        onChange={(e) => handleInputChange(e, "VehicleList", row)}
                                                    />
                                                </div>    
                                            </>
                                        ))}

                                        <div className="col-span-1">
                                            <Typography variant="h6">State Criminal History</Typography>
                                        </div>
                                        <div className="col-span-1"></div>
                                        <div className="col-span-1">
                                            <ButtonGroup>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<AddIcon />}
                                                    onClick={() => addTableRow("CriminalHistoryList", "Origination", "State Criminal History")}
                                                >
                                                    Add State Criminal History
                                                </Button>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<ClearIcon />}
                                                    onClick={() => handleDialog("State Criminal History", true)}
                                                >
                                                    Clear All State History
                                                </Button>
                                                <Dialog 
                                                    open={stateHistoryClearOpen}
                                                    onClose={() => handleDialog("State Criminal History", false)}
                                                    aria-labelledby="clear-dialog"
                                                    aria-describedby="clear-dialog"
                                                >
                                                    <DialogTitle id="clear-dialog-title5">
                                                        {"Clear all existing state criminal history?"}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="clear-historical-addresses">
                                                            Are you sure you would like to clear all state criminal history in the list?
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={() => handleDialog("State Criminal History", false)}>No</Button>
                                                        <Button onClick={() => {clearTableRows("CriminalHistoryList", "Origination", "State Criminal History"); handleDialog("State Criminal History", false)} } autoFocus>
                                                            Yes
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </ButtonGroup>
                                        </div>
                                        <div className="col-span-1"></div>

                                        {form?.CriminalHistoryList?.filter(p => !p.deleted && p.Origination === "State Criminal History").map((row, index) => (
                                            <>
                                                <div className="col-span-2 flex items-center justify-center">
                                                    <Typography variant="body1" align="center">State Criminal History Summary {index + 1}</Typography>
                                                    <Tooltip title="Delete Criminal History">
                                                        <IconButton
                                                            aria-label="delete"
                                                            onClick={() => deleteTableRow("CriminalHistoryList", row)}
                                                        >
                                                            <DeleteIcon sx={{ color: "red" }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                                <div className="col-span-1 pr-5">
                                                    <DatePicker
                                                        label="Date"
                                                        value={row?.Date ?? ""}
                                                        onChange={(date) => handleInputChange({ target: { name: "Date", value: (date ?? "") } }, "CriminalHistoryList", row)}
                                                        renderInput={(params) => <TextField {...params} error={row?.Date && row?.Date.trim().length > 1  ?  Date.parse(row?.Date) ? false : true : false} fullWidth />}
                                                    />
                                                </div>
                                                <div className="col-span-1 pl-5">
                                                    <TextField
                                                        label="Charge"
                                                        name="Charge"
                                                        fullWidth
                                                        value={row?.Charge ?? ""}
                                                        onChange={(e) => handleInputChange(e, "CriminalHistoryList", row)}
                                                    />
                                                </div>
                                                <div className="col-span-2">
                                                    <TextField
                                                        label="Disposition"
                                                        name="Disposition"
                                                        fullWidth
                                                        value={row?.Disposition ?? ""}
                                                        onChange={(e) => handleInputChange(e, "CriminalHistoryList", row)}
                                                    />
                                                </div>
                                            </>
                                        ))}

                                        <div className="col-span-1">
                                            <Typography variant="h6">Local Warrant Information/Arrest Information</Typography>
                                        </div>
                                        <div className="col-span-1"></div>
                                        <div className="col-span-1">
                                            <ButtonGroup>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<AddIcon />}
                                                    onClick={() => addTableRow("CriminalHistoryList", "Origination", "Warrant and Arrest")}
                                                >
                                                    Add Warrant/Arrest Information
                                                </Button>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<ClearIcon />}
                                                    onClick={() => handleDialog("Warrant and Arrest", true)}
                                                >
                                                    Clear All Warrants
                                                </Button>
                                                <Dialog 
                                                    open={arrestWarrantClearOpen}
                                                    onClose={() => handleDialog("Warrant and Arrest", false)}
                                                    aria-labelledby="clear-dialog"
                                                    aria-describedby="clear-dialog"
                                                >
                                                    <DialogTitle id="clear-dialog-title5">
                                                        {"Clear all existing arrest and warrant information?"}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="clear-historical-addresses">
                                                            Are you sure you would like to clear all arrest and warrant information in the list?
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={() => handleDialog("Warrant and Arrest", false)}>No</Button>
                                                        <Button onClick={() => {clearTableRows("CriminalHistoryList", "Origination", "Warrant and Arrest"); handleDialog("Warrant and Arrest", false)} } autoFocus>
                                                            Yes
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </ButtonGroup>
                                        </div>
                                        <div className="col-span-1"></div>

                                        {form?.CriminalHistoryList?.filter(p => !p.deleted && p.Origination === "Warrant and Arrest").map((row, index) => (
                                            <>
                                                <div className="col-span-2 flex items-center justify-center">
                                                    <Typography variant="body1" align="center">Warrant/Arrest Summary {index + 1}</Typography>
                                                    <Tooltip title="Delete Arrest/Warrant">
                                                        <IconButton
                                                            aria-label="delete"
                                                            onClick={() => deleteTableRow("CriminalHistoryList", row)}
                                                        >
                                                            <DeleteIcon sx={{ color: "red" }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                                <div className="col-span-1 pr-5">
                                                    <DatePicker
                                                        label="Date"
                                                        value={row?.Date ?? ""}
                                                        onChange={(date) => handleInputChange({ target: { name: "Date", value: (date ?? "") } }, "CriminalHistoryList", row)}
                                                        renderInput={(params) => <TextField {...params} error={row?.Date && row?.Date.trim().length > 1  ?  Date.parse(row?.Date) ? false : true : false} fullWidth />}
                                                    />
                                                </div>
                                                <div className="col-span-1 pl-5">
                                                    <TextField
                                                        label="Charge"
                                                        name="Charge"
                                                        fullWidth
                                                        value={row?.Charge ?? ""}
                                                        onChange={(e) => handleInputChange(e, "CriminalHistoryList", row)}
                                                    />
                                                </div>
                                                <div className="col-span-2">
                                                    <TextField
                                                        label="Disposition"
                                                        name="Disposition"
                                                        fullWidth
                                                        value={row?.Disposition ?? ""}
                                                        onChange={(e) => handleInputChange(e, "CriminalHistoryList", row)}
                                                    />
                                                </div>    
                                            </>
                                        ))}

                                        <div className="col-span-1">
                                            <Typography variant="h6">State, Regional, and Local Indices Checks</Typography>
                                        </div>
                                        <div className="col-span-1"></div>
                                        <div className="col-span-1">
                                            <ButtonGroup>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<AddIcon />}
                                                    onClick={() => addTableRow("IndicesChecks_CourtCaseInfoList", "Origination", "Indices Checks")}
                                                >
                                                    Add Indices Check
                                                </Button>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<ClearIcon />}
                                                    onClick={() => handleDialog("Indices Checks", true)}
                                                >
                                                    Clear All Indices Checks
                                                </Button>
                                                <Dialog 
                                                    open={indicesClearOpen}
                                                    onClose={() => handleDialog("Indices Checks", false)}
                                                    aria-labelledby="clear-dialog"
                                                    aria-describedby="clear-dialog"
                                                >
                                                    <DialogTitle id="clear-dialog-title5">
                                                        {"Clear all existing indices checks?"}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="clear-historical-addresses">
                                                            Are you sure you would like to clear all indices checks in the list?
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={() => handleDialog("Indices Checks", false)}>No</Button>
                                                        <Button onClick={() => {clearTableRows("IndicesChecks_CourtCaseInfoList", "Origination", "Indices Checks"); handleDialog("Indices Checks", false)} } autoFocus>
                                                            Yes
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </ButtonGroup>
                                        </div>
                                        <div className="col-span-1"></div>

                                        {form?.IndicesChecks_CourtCaseInfoList?.filter(p => !p.deleted && p.Origination === "Indices Checks").map((row, index) => (
                                            <>
                                                <div className="col-span-2 flex items-center justify-center">
                                                    <Typography variant="body1" align="center">Indices Check {index + 1}</Typography>
                                                    <Tooltip title="Delete Indices Check">
                                                        <IconButton
                                                            aria-label="delete"
                                                            onClick={() => deleteTableRow("IndicesChecks_CourtCaseInfoList", row)}
                                                        >
                                                            <DeleteIcon sx={{ color: "red" }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                                <div className="col-span-1 pr-5 mt-5">
                                                    <Autocomplete
                                                        id="free-solo-demo"
                                                        autoComplete={true}
                                                        options={systemList}
                                                        value={row?.SystemType ?? ""}
                                                        onChange={(event, newValue) => handleInputChange({ target: { name: "SystemType", value: newValue as string } }, "IndicesChecks_CourtCaseInfoList", row)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="System Type"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div className="grid grid-cols-5 col-span-1 gap-4">
                                                    <div className="col-span-2">
                                                        <FormLabel component="legend">Findings</FormLabel>
                                                        <RadioGroup
                                                            aria-label="findings"
                                                            name="Findings"
                                                            value={row?.Findings ?? ""}
                                                            onChange={(e) => handleInputChange(e, "IndicesChecks_CourtCaseInfoList", row)}
                                                        >
                                                            <FormControlLabel value="Positive" control={<Radio />} label="Positive" />
                                                            <FormControlLabel value="Negative" control={<Radio />} label="Negative" />
                                                        </RadioGroup>
                                                    </div>
                                                    <div className="col-span-3">
                                                        <TextareaAutosize
                                                            id="AdditionalInfo"
                                                            name="AdditionalInfo"
                                                            placeholder="Additional Information"
                                                            value={row?.AdditionalInfo ?? ""}
                                                            onChange={(e) => handleInputChange(e, "IndicesChecks_CourtCaseInfoList", row)}
                                                            style={{
                                                                width: '100%',
                                                                marginTop: '10px',
                                                                border: '1px solid rgba(0, 0, 0, 0.23)',
                                                                borderRadius: '4px',
                                                                padding: '16.5px 14px',
                                                                fontSize: '16px',
                                                                lineHeight: '1.4375em',
                                                                backgroundColor: 'inherit',
                                                                resize: 'vertical'
                                                            }}
                                                            minRows={3}
                                                        />
                                                    </div>
                                                </div>

                                            </>
                                        ))}

                                        <div className="col-span-1">
                                            <Typography variant="h6">Court Case Information</Typography>
                                        </div>
                                        <div className="col-span-1"></div>
                                        <div className="grid grid-rows-2 grid-cols-1 col-span-2">
                                            <div><Typography variant="body1">In addition to traffic-related offenses, the subject is referenced in the following court cases.</Typography></div>
                                            <div><Typography variant="body1">**Includes any state/local case information that is not available in NCIC.</Typography></div>
                                        </div>
                                        <div className="col-span-1">
                                            <ButtonGroup>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<AddIcon />}
                                                    onClick={() => addTableRow("IndicesChecks_CourtCaseInfoList", "Origination", "Court Case")}
                                                >
                                                    Add Court Case
                                                </Button>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<ClearIcon />}
                                                    onClick={() => handleDialog("Court Case", true)}
                                                >
                                                    Clear All Court Cases
                                                </Button>
                                                <Dialog 
                                                    open={courtClearOpen}
                                                    onClose={() => handleDialog("Court Case", false)}
                                                    aria-labelledby="clear-dialog"
                                                    aria-describedby="clear-dialog"
                                                >
                                                    <DialogTitle id="clear-dialog-title5">
                                                        {"Clear all existing court cases?"}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="clear-historical-addresses">
                                                            Are you sure you would like to clear all court cases in the list?
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={() => handleDialog("Court Case", false)}>No</Button>
                                                        <Button onClick={() => {clearTableRows("IndicesChecks_CourtCaseInfoList", "Origination", "Court Case"); handleDialog("Court Case", false)} } autoFocus>
                                                            Yes
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </ButtonGroup>
                                        </div>
                                        <div className="col-span-1"></div>

                                        {form?.IndicesChecks_CourtCaseInfoList && form?.IndicesChecks_CourtCaseInfoList?.filter(f => !f?.deleted && f.Origination === "Court Case").length > 0 && (
                                            <div className="col-span-2">
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Case Number</TableCell>
                                                            <TableCell>Source</TableCell>
                                                            <TableCell>File Date</TableCell>
                                                            <TableCell>County</TableCell>
                                                            <TableCell>Case Type and/or Statute</TableCell>
                                                            <TableCell>Status</TableCell>
                                                            <TableCell>Actions</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {form?.IndicesChecks_CourtCaseInfoList?.filter(f => !f?.deleted && f.Origination === "Court Case")?.map((row, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell style={{ width: 150 }}>
                                                                    <TextField
                                                                        name="CaseNo"
                                                                        value={row?.CaseNo ?? ""}
                                                                        multiline
                                                                        onChange={(e) => handleInputChange(e, "IndicesChecks_CourtCaseInfoList", row)}
                                                                        inputProps={{
                                                                            style: {
                                                                                borderRadius: "1px",
                                                                                textAlign: 'center'
                                                                            }
                                                                        }}
                                                                        fullWidth
                                                                    />
                                                                </TableCell>
                                                                <TableCell style={{ width: 200 }}>
                                                                    <TextField
                                                                        name="Source"
                                                                        value={row?.Source ?? ""}
                                                                        multiline
                                                                        onChange={(e) => handleInputChange(e, "IndicesChecks_CourtCaseInfoList", row)}
                                                                        inputProps={{
                                                                            style: {
                                                                                borderRadius: "1px",
                                                                                textAlign: 'center'
                                                                            }
                                                                        }}
                                                                        fullWidth
                                                                    />
                                                                </TableCell>
                                                                <TableCell style={{ width: 260 }}>
                                                                    <DatePicker
                                                                        value={row?.FileDate ?? ""}
                                                                        onChange={(date) => handleInputChange({ target: { name: "FileDate", value: (date ?? "") } }, "IndicesChecks_CourtCaseInfoList", row)}
                                                                        renderInput={(params) => <TextField {...params} error={row?.FileDate && row?.FileDate.trim().length > 1  ?  Date.parse(row?.FileDate) ? false : true : false} fullWidth />}
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Autocomplete
                                                                        id="free-solo-demo"
                                                                        autoComplete={true}
                                                                        options={countyList.map((option: any) => option.Key)}
                                                                        value={row?.County ?? ""}
                                                                        onChange={(event, newValue) => handleInputChange({ target: { name: "County", value: newValue as string } }, "IndicesChecks_CourtCaseInfoList", row)}
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                label="County"
                                                                                variant="outlined"
                                                                                fullWidth
                                                                            />
                                                                        )}
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <TextField
                                                                        name="CaseType"
                                                                        value={row?.CaseType ?? ""}
                                                                        multiline
                                                                        onChange={(e) => handleInputChange(e, "IndicesChecks_CourtCaseInfoList", row)}
                                                                        inputProps={{
                                                                            style: {
                                                                                borderRadius: "1px",
                                                                                textAlign: 'center'
                                                                            }
                                                                        }}
                                                                        fullWidth
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <TextField
                                                                        multiline
                                                                        name="Status"
                                                                        value={row?.Status ?? ""}
                                                                        onChange={(e) => handleInputChange(e, "IndicesChecks_CourtCaseInfoList", row)}
                                                                        inputProps={{
                                                                            style: {
                                                                                borderRadius: "1px",
                                                                                textAlign: 'center',
                                                                                wordBreak: 'break-word',
                                                                                whiteSpace: 'pre-wrap',
                                                                                overflowWrap: 'break-word',
                                                                            }
                                                                        }}
                                                                        fullWidth
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Tooltip title="Delete Case">
                                                                        <IconButton
                                                                            aria-label="delete"
                                                                            onClick={() => deleteTableRow("IndicesChecks_CourtCaseInfoList", row)}
                                                                        >
                                                                            <DeleteIcon sx={{ color: "red" }} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}

                        {activeStep === 3 && (
                            <>
                                <div className="mt-4 w-3/5 border gap-4 mx-auto border-black p-4 bg-tsc-bg">
                                    <div className="grid grid-cols-1 gap-4 justify-center">
                                        <div className="col-span-1">
                                            <Typography align="center" sx={{color: 'red', fontFamily: 'sans-serif', fontStyle: 'bold', fontSize: '2rem', fontWeight: 1200, WebkitTextStroke: '1px black'}}>Additional Information</Typography>
                                        </div>
                                        <div className="col-span-1"></div>
                                        <div className="col-span-1">
                                            <Typography variant="body1">Enter any information that resulted from your search but was not included above, such as visa
                                                                        number, alien registration number, naturalization certificate number, passport number/country, Immigration
                                                                        and Customs Enforcement (ICE) records, El Paso Intelligence Center (EPIC) checks (<a className="text-blue-600 underline" href="https://esp.usdoj.gov">https://esp.usdoj.gov</a>),
                                                                        National Law Enforcement Telecommunications System (Nlets) checks (<a className="text-blue-600 underline" href="https://www.nlets.org">https://www.nlets.org</a>), deconfliction system
                                                                        checks (<a className="text-blue-600 underline" href="https://www.ncirc.gov/Deconfliction">https://www.ncirc.gov/Deconfliction</a>), and/or RISSIntel checks. In addition, include any information garnered
                                                                        from open source inquiries to include financial information, social networking profile information, media reporting, etc. Be sure to include the entire URL to the subject's various social media platforms.
                                            </Typography>
                                        </div>
                                        <div className="col-span-1">
                                            <TextareaAutosize
                                                id="AdditionalInfo_Summary"
                                                name="AdditionalInfo_Summary"
                                                placeholder="Additional Information"
                                                value={form?.AdditionalInfo_Summary ?? ""}
                                                onChange={handleInputChange}
                                                style={{
                                                    width: '100%',
                                                    marginTop: '10px',
                                                    border: '1px solid rgba(0, 0, 0, 0.23)',
                                                    borderRadius: '4px',
                                                    padding: '16.5px 14px',
                                                    fontSize: '16px',
                                                    lineHeight: '1.4375em',
                                                    backgroundColor: 'inherit',
                                                    resize: 'vertical'
                                                }}
                                                minRows={12}
                                            />
                                        </div>
                                        <div className="border-t-2 border-black col-span-1">
                                            <TextareaAutosize
                                                id="Disclaimers"
                                                name="Disclaimers"
                                                placeholder="Disclaimers"
                                                value={form?.Disclaimers ?? ""}
                                                onChange={handleInputChange}
                                                style={{
                                                    width: '100%',
                                                    marginTop: '20px',
                                                    border: '1px solid rgba(0, 0, 0, 0.23)',
                                                    borderRadius: '4px',
                                                    padding: '16.5px 14px',
                                                    fontSize: '16px',
                                                    lineHeight: '1.4375em',
                                                    backgroundColor: 'inherit',
                                                    resize: 'vertical'
                                                }}
                                                minRows={3}
                                            />
                                        </div>
                                        <div className="col-span-1">
                                            <Typography variant="h6">Analyst Information</Typography>
                                        </div>
                                        <div className="grid grid-cols-2 gap-2 col-span-1">
                                            <div className="col-span-1">
                                                <TextField
                                                    label="First Name"
                                                    name="AnalystFirstName"
                                                    fullWidth
                                                    value={form?.AnalystFirstName ?? ""}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="col-span-1">
                                                <TextField
                                                    label="Last Name"
                                                    name="AnalystLastName"
                                                    fullWidth
                                                    value={form?.AnalystLastName ?? ""}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-2 gap-2 col-span-1">
                                            <div className="col-span-1">
                                                <TextField
                                                    label="Email Address"
                                                    name="AnalystEmail"
                                                    fullWidth
                                                    value={form?.AnalystEmail ?? ""}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="col-span-1">
                                                <TextField
                                                    label="Phone Number"
                                                    name="AnalystTelephoneNumber"
                                                    fullWidth
                                                    value={form?.AnalystTelephoneNumber ?? ""}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                        {activeStep === 4 && (
                            <>
                                <div className="mt-4 w-4/5 gap-4 mx-auto p-4">
                                    <div className="grid grid-cols-2 justify-center gap-1 text-red">
                                        <div className="col-span-2">
                                            <Typography align="center" sx={{color: 'red', fontFamily: 'sans-serif', fontStyle: 'bold', fontSize: '2rem', fontWeight: 1200, WebkitTextStroke: '1px black'}}>Tier 2 General Information</Typography>
                                        </div>
                                            {displayInfoObjectList?.filter((obj: any) => obj.Title === "SID Number (include two-letter state code or codes if multistate offender)"
                                            || obj.Title === "eCitation Information" || obj.Title === "Fingerprint Images and/or Fingerprint Identification Network (FIN) Number" 
                                                || obj.Title === "Scars/Marks/Tattoos" || obj.Title === "Video/Audio Recordings").map((infoObject) => renderInfoObjectFields(infoObject))
                                            }
                                        
                                        <div className="col-span-2 border-2 border-black bg-blue-200">
                                            <Typography align="center" variant="h5">Permits and Licenses</Typography>
                                        </div>
                                            {displayInfoObjectList?.filter((obj: any) => !(obj.Title === "SID Number (include two-letter state code or codes if multistate offender)"
                                                || obj.Title === "eCitation Information" || obj.Title === "Fingerprint Images and/or Fingerprint Identification Network (FIN) Number"
                                                || obj.Title === "Scars/Marks/Tattoos" || obj.Title === "Video/Audio Recordings")).map((infoObject) => renderPermitFields(infoObject))
                                            }
                                        <div className="col-span-2">
                                                <Typography align="center" sx={{ color: 'red', fontFamily: 'sans-serif', fontStyle: 'bold', fontSize: '1.8rem', fontWeight: 1200, WebkitTextStroke: '1px black' }}>Disclaimer</Typography>
                                        </div>
                                        <div className="col-span-2">
                                            <TextareaAutosize
                                                id="Disclaimers"
                                                name="Permits_Disclaimer"
                                                placeholder="Disclaimer"
                                                value={form?.Permits_Disclaimer ?? ""}
                                                onChange={handleInputChange}
                                                style={{
                                                    width: '100%',
                                                    marginTop: '20px',
                                                    border: '1px solid rgba(0, 0, 0, 0.23)',
                                                    borderRadius: '4px',
                                                    padding: '16.5px 14px',
                                                    fontSize: '16px',
                                                    lineHeight: '1.4375em',
                                                    backgroundColor: 'inherit',
                                                    resize: 'vertical'
                                                }}
                                                minRows={3}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        {activeStep === 5 && (
                            <>
                                <div className="mt-4 w-4/5 border gap-4 mx-auto border-black p-4 bg-tsc-bg">
                                    <div className="grid grid-cols-2 gap-4 justify-center text-red">
                                        <div className="col-span-2">
                                                <Typography align="center" sx={{color: 'red', fontFamily: 'sans-serif', fontStyle: 'bold', fontSize: '2rem', fontWeight: 1200, WebkitTextStroke: '1px black'}}>Corporation and Employment Information</Typography>
                                        </div>
                                        <div className="col-span-1">
                                            <Typography variant="h6">Corporate Affiliations</Typography>
                                        </div>
                                        <div className="col-span-1"></div>
                                        <div className="col-span-1">
                                            <ButtonGroup>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<AddIcon />}
                                                    onClick={() => addTableRow("CorporateEmployment_AffiliationsList", "Origination", "Affiliations")}
                                                >
                                                    Add Affiliation
                                                </Button>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<ClearIcon />}
                                                    onClick={() => handleDialog("Affiliations", true)}
                                                >
                                                    Clear All Affiliations
                                                </Button>
                                                <Dialog 
                                                    open={affiliationsClearOpen}
                                                    onClose={() => handleDialog("Affiliations", false)}
                                                    aria-labelledby="clear-dialog"
                                                    aria-describedby="clear-dialog"
                                                >
                                                    <DialogTitle id="clear-dialog-title5">
                                                        {"Clear all existing affiliations?"}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="clear-historical-addresses">
                                                            Are you sure you would like to clear all corporate affiliations in the list?
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={() => handleDialog("Affiliations", false)}>No</Button>
                                                        <Button onClick={() => {clearTableRows("CorporateEmployment_AffiliationsList", "Origination", "Affiliations"); handleDialog("Affiliations", false)} } autoFocus>
                                                            Yes
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </ButtonGroup>
                                        </div>
                                        <div className="col-span-1"></div>

                                        {form?.CorporateEmployment_AffiliationsList && form?.CorporateEmployment_AffiliationsList?.filter(f => !f?.deleted && f.Origination === "Affiliations").length > 0 && (
                                            <div className="col-span-2">
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Company</TableCell>
                                                            <TableCell>Date Filed</TableCell>
                                                            <TableCell>Position</TableCell>
                                                            <TableCell>Status</TableCell>
                                                            <TableCell>FEI Number and/or Document Number</TableCell>
                                                            <TableCell>Other Agents/Officers</TableCell>
                                                            <TableCell>Source</TableCell>
                                                            <TableCell>Actions</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                            {form?.CorporateEmployment_AffiliationsList?.filter(f => !f?.deleted && f.Origination === "Affiliations")?.map((row, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell>
                                                                    <TextField
                                                                        name="CompanyFiling"
                                                                        value={row?.CompanyFiling ?? ""}
                                                                        multiline
                                                                        onChange={(e) => handleInputChange(e, "CorporateEmployment_AffiliationsList", row)}
                                                                        inputProps={{
                                                                            style: {
                                                                                borderRadius: "1px",
                                                                                textAlign: 'center'
                                                                            }
                                                                        }}
                                                                        fullWidth
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <DatePicker
                                                                        value={row?.DateFiled ?? ""}
                                                                        onChange={(date) => handleInputChange({ target: { name: "DateFiled", value: (date ?? "") } }, "CorporateEmployment_AffiliationsList", row)}
                                                                        renderInput={(params) => <TextField {...params} error={row?.DateFiled && row?.DateFiled.trim().length > 1  ?  Date.parse(row?.DateFiled) ? false : true : false} fullWidth />}
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <TextField
                                                                        name="Position"
                                                                        value={row?.Position ?? ""}
                                                                        multiline
                                                                        onChange={(e) => handleInputChange(e, "CorporateEmployment_AffiliationsList", row)}
                                                                        inputProps={{
                                                                            style: {
                                                                                borderRadius: "1px",
                                                                                textAlign: 'center'
                                                                            }
                                                                        }}
                                                                        fullWidth
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <TextField
                                                                        name="Status"
                                                                        value={row?.Status ?? ""}
                                                                        multiline
                                                                        onChange={(e) => handleInputChange(e, "CorporateEmployment_AffiliationsList", row)}
                                                                        inputProps={{
                                                                            style: {
                                                                                borderRadius: "1px",
                                                                                textAlign: 'center'
                                                                            }
                                                                        }}
                                                                        fullWidth
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <TextField
                                                                        name="FEIDocNo"
                                                                        value={row?.FEIDocNo ?? ""}
                                                                        multiline
                                                                            onChange={(e) => handleInputChange(e, "CorporateEmployment_AffiliationsList", row)}
                                                                        inputProps={{
                                                                            style: {
                                                                                borderRadius: "1px",
                                                                                textAlign: 'center'
                                                                            }
                                                                        }}
                                                                        fullWidth
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <TextField
                                                                        multiline
                                                                        name="OwnersOtherAgents"
                                                                        value={row?.OwnersOtherAgents ?? ""}
                                                                            onChange={(e) => handleInputChange(e, "CorporateEmployment_AffiliationsList", row)}
                                                                        inputProps={{
                                                                            style: {
                                                                                borderRadius: "1px",
                                                                                textAlign: 'center',
                                                                                wordBreak: 'break-word',
                                                                                whiteSpace: 'pre-wrap',
                                                                                overflowWrap: 'break-word',
                                                                            }
                                                                        }}
                                                                        fullWidth
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <TextField
                                                                        multiline
                                                                        name="Source"
                                                                        value={row?.Source ?? ""}
                                                                            onChange={(e) => handleInputChange(e, "CorporateEmployment_AffiliationsList", row)}
                                                                        inputProps={{
                                                                            style: {
                                                                                borderRadius: "1px",
                                                                                textAlign: 'center',
                                                                                wordBreak: 'break-word',
                                                                                whiteSpace: 'pre-wrap',
                                                                                overflowWrap: 'break-word',
                                                                            }
                                                                        }}
                                                                        fullWidth
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Tooltip title="Delete Affiliation">
                                                                        <IconButton
                                                                            aria-label="delete"
                                                                            onClick={() => deleteTableRow("CorporateEmployment_AffiliationsList", row)}
                                                                        >
                                                                            <DeleteIcon sx={{ color: "red" }} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </div>
                                        )}
                                        <div className="col-span-1">
                                            <Typography variant="h6">Fictitious Filings of Above-Referenced Businesses</Typography>
                                        </div>
                                        <div className="col-span-1"></div>
                                        <div className="col-span-1">
                                            <ButtonGroup>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<AddIcon />}
                                                    onClick={() => addTableRow("CorporateEmployment_AffiliationsList", "Origination", "Fictitious")}
                                                >
                                                    Add Fictitious Filing
                                                </Button>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<ClearIcon />}
                                                    onClick={() => handleDialog("Fictitious", true)}
                                                >
                                                    Clear All Fictitious Filings
                                                </Button>
                                                <Dialog 
                                                    open={fictitiousClearOpen}
                                                    onClose={() => handleDialog("Fictitious", false)}
                                                    aria-labelledby="clear-dialog"
                                                    aria-describedby="clear-dialog"
                                                >
                                                    <DialogTitle id="clear-dialog-title5">
                                                        {"Clear all existing fictitious filings?"}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="clear-historical-addresses">
                                                            Are you sure you would like to clear all fictitious filings in the list?
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={() => handleDialog("Fictitious", false)}>No</Button>
                                                        <Button onClick={() => {clearTableRows("CorporateEmployment_AffiliationsList", "Origination", "Fictitious"); handleDialog("Fictitious", false)} } autoFocus>
                                                            Yes
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </ButtonGroup>
                                        </div>
                                        <div className="col-span-1"></div>

                                        {form?.CorporateEmployment_AffiliationsList && form?.CorporateEmployment_AffiliationsList?.filter(f => !f?.deleted && f.Origination === "Fictitious").length > 0 && (
                                            <div className="col-span-2">
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Fictitious Filing</TableCell>
                                                            <TableCell>Date Filed</TableCell>
                                                            <TableCell>Position</TableCell>
                                                            <TableCell>Status</TableCell>
                                                            <TableCell>FEI Number and/or Document Number</TableCell>
                                                            <TableCell>Owner(s)</TableCell>
                                                            <TableCell>Source</TableCell>
                                                            <TableCell>Actions</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {form?.CorporateEmployment_AffiliationsList?.filter(f => !f?.deleted && f.Origination === "Fictitious")?.map((row, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell>
                                                                    <TextField
                                                                        name="CompanyFiling"
                                                                        value={row?.CompanyFiling ?? ""}
                                                                        multiline
                                                                        onChange={(e) => handleInputChange(e, "CorporateEmployment_AffiliationsList", row)}
                                                                        inputProps={{
                                                                            style: {
                                                                                borderRadius: "1px",
                                                                                textAlign: 'center'
                                                                            }
                                                                        }}
                                                                        fullWidth
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <DatePicker
                                                                        value={row?.DateFiled ?? ""}
                                                                        onChange={(date) => handleInputChange({ target: { name: "DateFiled", value: (date ?? "") } }, "CorporateEmployment_AffiliationsList", row)}
                                                                        renderInput={(params) => <TextField {...params} error={row?.DateFiled && row?.DateFiled.trim().length > 1  ?  Date.parse(row?.DateFiled) ? false : true : false} fullWidth />}
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <TextField
                                                                        name="Position"
                                                                        value={row?.Position ?? ""}
                                                                        multiline
                                                                        onChange={(e) => handleInputChange(e, "CorporateEmployment_AffiliationsList", row)}
                                                                        inputProps={{
                                                                            style: {
                                                                                borderRadius: "1px",
                                                                                textAlign: 'center'
                                                                            }
                                                                        }}
                                                                        fullWidth
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <TextField
                                                                        name="Status"
                                                                        value={row?.Status ?? ""}
                                                                        multiline
                                                                        onChange={(e) => handleInputChange(e, "CorporateEmployment_AffiliationsList", row)}
                                                                        inputProps={{
                                                                            style: {
                                                                                borderRadius: "1px",
                                                                                textAlign: 'center'
                                                                            }
                                                                        }}
                                                                        fullWidth
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <TextField
                                                                        name="FEIDocNo"
                                                                        value={row?.FEIDocNo ?? ""}
                                                                        multiline
                                                                            onChange={(e) => handleInputChange(e, "CorporateEmployment_AffiliationsList", row)}
                                                                        inputProps={{
                                                                            style: {
                                                                                borderRadius: "1px",
                                                                                textAlign: 'center'
                                                                            }
                                                                        }}
                                                                        fullWidth
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <TextField
                                                                        multiline
                                                                        name="OwnersOtherAgents"
                                                                        value={row?.OwnersOtherAgents ?? ""}
                                                                            onChange={(e) => handleInputChange(e, "CorporateEmployment_AffiliationsList", row)}
                                                                        inputProps={{
                                                                            style: {
                                                                                borderRadius: "1px",
                                                                                textAlign: 'center',
                                                                                wordBreak: 'break-word',
                                                                                whiteSpace: 'pre-wrap',
                                                                                overflowWrap: 'break-word',
                                                                            }
                                                                        }}
                                                                        fullWidth
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <TextField
                                                                        multiline
                                                                        name="Source"
                                                                        value={row?.Source ?? ""}
                                                                            onChange={(e) => handleInputChange(e, "CorporateEmployment_AffiliationsList", row)}
                                                                        inputProps={{
                                                                            style: {
                                                                                borderRadius: "1px",
                                                                                textAlign: 'center',
                                                                                wordBreak: 'break-word',
                                                                                whiteSpace: 'pre-wrap',
                                                                                overflowWrap: 'break-word',
                                                                            }
                                                                        }}
                                                                        fullWidth
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Tooltip title="Delete Fictitious Filing">
                                                                        <IconButton
                                                                            aria-label="delete"
                                                                            onClick={() => deleteTableRow("CorporateEmployment_AffiliationsList", row)}
                                                                        >
                                                                            <DeleteIcon sx={{ color: "red" }} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </div>
                                        )}
                                        <div className="col-span-2">
                                             <Typography align="center" sx={{ color: 'red', fontFamily: 'sans-serif', fontStyle: 'bold', fontSize: '1.8rem', fontWeight: 1200, WebkitTextStroke: '1px black' }}>Analyst Note</Typography>
                                        </div>
                                        <div className="col-span-2">
                                            <TextareaAutosize
                                                id="AdditionalInfo"
                                                name="CorporateEmployment_CorporateAnalystNote"
                                                placeholder="Analyst Note"
                                                value={form?.CorporateEmployment_CorporateAnalystNote ?? ""}
                                                onChange={handleInputChange}
                                                style={{
                                                    width: '100%',
                                                    marginTop: '10px',
                                                    border: '1px solid rgba(0, 0, 0, 0.23)',
                                                    borderRadius: '4px',
                                                    padding: '16.5px 14px',
                                                    fontSize: '16px',
                                                    lineHeight: '1.4375em',
                                                    backgroundColor: 'inherit',
                                                    resize: 'vertical'
                                                }}
                                                minRows={3}
                                            />
                                        </div>
                                        <div className="col-span-1">
                                            <Typography variant="h6">Corporate Licensing</Typography>
                                        </div>
                                        <div className="col-span-1"></div>
                                        <div className="col-span-1">
                                            <ButtonGroup>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<AddIcon />}
                                                    onClick={() => addTableRow("CorporateEmployment_CorporateLicensingList", "Origination", "Licensing")}
                                                >
                                                    Add License
                                                </Button>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<ClearIcon />}
                                                    onClick={() => handleDialog("Licensing", true)}
                                                >
                                                    Clear All Licenses
                                                </Button>
                                                <Dialog 
                                                    open={licensingClearOpen}
                                                    onClose={() => handleDialog("Licensing", false)}
                                                    aria-labelledby="clear-dialog"
                                                    aria-describedby="clear-dialog"
                                                >
                                                    <DialogTitle id="clear-dialog-title5">
                                                        {"Clear all existing licenses?"}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="clear-historical-addresses">
                                                            Are you sure you would like to clear all licenses in the list?
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={() => handleDialog("Licensing", false)}>No</Button>
                                                        <Button onClick={() => {clearTableRows("CorporateEmployment_CorporateLicensingList", "Origination", "Licensing"); handleDialog("Licensing", false)} } autoFocus>
                                                            Yes
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </ButtonGroup>
                                        </div>
                                        <div className="col-span-1"></div>

                                        {form?.CorporateEmployment_CorporateLicensingList && form?.CorporateEmployment_CorporateLicensingList?.length > 0 && (
                                            <div className="col-span-2">
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>License</TableCell>
                                                            <TableCell>License Type</TableCell>
                                                            <TableCell>Rank</TableCell>
                                                            <TableCell>Status</TableCell>
                                                            <TableCell>Issue Date</TableCell>
                                                            <TableCell>Expiration Date</TableCell>
                                                            <TableCell>Licensee Information</TableCell>
                                                            <TableCell>Actions</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                            {form?.CorporateEmployment_CorporateLicensingList?.filter(f => !f?.deleted)?.map((row, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell>
                                                                    <TextField
                                                                        name="License"
                                                                        value={row?.License ?? ""}
                                                                        multiline
                                                                        onChange={(e) => handleInputChange(e, "CorporateEmployment_CorporateLicensingList", row)}
                                                                        inputProps={{
                                                                            style: {
                                                                                borderRadius: "1px",
                                                                                textAlign: 'center'
                                                                            }
                                                                        }}
                                                                        fullWidth
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                        <TextField
                                                                            name="LicenseType"
                                                                            value={row?.LicenseType ?? ""}
                                                                            multiline
                                                                            onChange={(e) => handleInputChange(e, "CorporateEmployment_CorporateLicensingList", row)}
                                                                            inputProps={{
                                                                                style: {
                                                                                    borderRadius: "1px",
                                                                                    textAlign: 'center'
                                                                                }
                                                                            }}
                                                                            fullWidth
                                                                        />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <TextField
                                                                        name="Rank"
                                                                        value={row?.Rank ?? ""}
                                                                        multiline
                                                                        onChange={(e) => handleInputChange(e, "CorporateEmployment_CorporateLicensingList", row)}
                                                                        inputProps={{
                                                                            style: {
                                                                                borderRadius: "1px",
                                                                                textAlign: 'center'
                                                                            }
                                                                        }}
                                                                        fullWidth
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <TextField
                                                                        name="Status"
                                                                        value={row?.Status ?? ""}
                                                                        multiline
                                                                        onChange={(e) => handleInputChange(e, "CorporateEmployment_CorporateLicensingList", row)}
                                                                        inputProps={{
                                                                            style: {
                                                                                borderRadius: "1px",
                                                                                textAlign: 'center'
                                                                            }
                                                                        }}
                                                                        fullWidth
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <DatePicker
                                                                        value={row?.IssueDate ?? ""}
                                                                        onChange={(date) => handleInputChange({ target: { name: "IssueDate", value: (date ?? "") } }, "CorporateEmployment_CorporateLicensingList", row)}
                                                                        renderInput={(params) => <TextField {...params} error={row?.IssueDate && row?.IssueDate.trim().length > 1  ?  Date.parse(row?.IssueDate) ? false : true : false} fullWidth />}
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <DatePicker
                                                                        value={row?.ExpirationDate ?? ""}
                                                                        onChange={(date) => handleInputChange({ target: { name: "ExpirationDate", value: (date ?? "") } }, "CorporateEmployment_CorporateLicensingList", row)}
                                                                        renderInput={(params) => <TextField {...params} error={row?.ExpirationDate && row?.ExpirationDate.trim().length > 1  ?  Date.parse(row?.ExpirationDate) ? false : true : false} fullWidth />}
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <TextField
                                                                        multiline
                                                                        name="LicenseeInfo"
                                                                        value={row?.LicenseeInfo ?? ""}
                                                                        onChange={(e) => handleInputChange(e, "CorporateEmployment_CorporateLicensingList", row)}
                                                                        inputProps={{
                                                                            style: {
                                                                                borderRadius: "1px",
                                                                                textAlign: 'center',
                                                                                wordBreak: 'break-word',
                                                                                whiteSpace: 'pre-wrap',
                                                                                overflowWrap: 'break-word',
                                                                            }
                                                                        }}
                                                                        fullWidth
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Tooltip title="Delete License">
                                                                        <IconButton
                                                                            aria-label="delete"
                                                                            onClick={() => deleteTableRow("CorporateEmployment_CorporateLicensingList", row)}
                                                                        >
                                                                            <DeleteIcon sx={{ color: "red" }} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </div>
                                        )}
                                        <div className="col-span-1">
                                            <Typography variant="h6">Possible Employment History</Typography>
                                        </div>
                                        <div className="col-span-1"></div>
                                        <div className="col-span-1">
                                            <ButtonGroup>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<AddIcon />}
                                                    onClick={() => addTableRow("CorporateEmployment_EmploymentHistoryList", "Origination", "Employment")}
                                                >
                                                    Add Employment
                                                </Button>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<ClearIcon />}
                                                    onClick={() => handleDialog("Employment", true)}
                                                >
                                                    Clear All Employment History
                                                </Button>
                                                <Dialog 
                                                    open={employmentClearOpen}
                                                    onClose={() => handleDialog("Employment", false)}
                                                    aria-labelledby="clear-dialog"
                                                    aria-describedby="clear-dialog"
                                                >
                                                    <DialogTitle id="clear-dialog-title5">
                                                        {"Clear all existing employment history?"}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="clear-historical-addresses">
                                                            Are you sure you would like to clear all employment history from the list?
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={() => handleDialog("Employment", false)}>No</Button>
                                                        <Button onClick={() => {clearTableRows("CorporateEmployment_EmploymentHistoryList", "Origination", "Employment"); handleDialog("Employment", false)} } autoFocus>
                                                            Yes
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </ButtonGroup>
                                        </div>
                                        <div className="col-span-1"></div>

                                        {form?.CorporateEmployment_EmploymentHistoryList && form?.CorporateEmployment_EmploymentHistoryList?.length > 0 && (
                                            <div className="col-span-2">
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Company</TableCell>
                                                                <TableCell>Position</TableCell>
                                                                <TableCell>Start Date</TableCell>
                                                                <TableCell>End Date</TableCell>
                                                                <TableCell>Source</TableCell>
                                                                <TableCell>Actions</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                                {form?.CorporateEmployment_EmploymentHistoryList?.filter(f => !f?.deleted)?.map((row, index) => (
                                                                <TableRow key={index}>
                                                                    <TableCell>
                                                                        <TextField
                                                                            name="Company"
                                                                            value={row?.Company ?? ""}
                                                                            multiline
                                                                            onChange={(e) => handleInputChange(e, "CorporateEmployment_EmploymentHistoryList", row)}
                                                                            inputProps={{
                                                                                style: {
                                                                                    borderRadius: "1px",
                                                                                    textAlign: 'center'
                                                                                }
                                                                            }}
                                                                            fullWidth
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                            <TextField
                                                                                name="Position"
                                                                                value={row?.Position ?? ""}
                                                                                multiline
                                                                                onChange={(e) => handleInputChange(e, "CorporateEmployment_EmploymentHistoryList", row)}
                                                                                inputProps={{
                                                                                    style: {
                                                                                        borderRadius: "1px",
                                                                                        textAlign: 'center'
                                                                                    }
                                                                                }}
                                                                                fullWidth
                                                                            />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <DatePicker
                                                                            value={row?.StartDate ?? ""}
                                                                            onChange={(date) => handleInputChange({ target: { name: "StartDate", value: (date ?? "") } }, "CorporateEmployment_EmploymentHistoryList", row)}
                                                                            renderInput={(params) => <TextField {...params} error={row?.StartDate && row?.StartDate.trim().length > 1  ?  Date.parse(row?.StartDate) ? false : true : false} fullWidth />}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <DatePicker
                                                                            value={row?.EndDate ?? ""}
                                                                            onChange={(date) => handleInputChange({ target: { name: "EndDate", value: (date ?? "") } }, "CorporateEmployment_EmploymentHistoryList", row)}
                                                                            renderInput={(params) => <TextField {...params} error={row?.EndDate && row?.EndDate.trim().length > 1  ?  Date.parse(row?.EndDate) ? false : true : false} fullWidth />}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <TextField
                                                                            name="Source"
                                                                            value={row?.Source ?? ""}
                                                                            multiline
                                                                            onChange={(e) => handleInputChange(e, "CorporateEmployment_EmploymentHistoryList", row)}
                                                                            inputProps={{
                                                                                style: {
                                                                                    borderRadius: "1px",
                                                                                    textAlign: 'center'
                                                                                }
                                                                            }}
                                                                            fullWidth
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Tooltip title="Delete Employment History">
                                                                            <IconButton
                                                                                aria-label="delete"
                                                                                onClick={() => deleteTableRow("CorporateEmployment_EmploymentHistoryList", row)}
                                                                            >
                                                                                <DeleteIcon sx={{ color: "red" }} />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </div>
                                        )}
                                            
                                        <div className="col-span-2">
                                             <Typography align="center" sx={{ color: 'red', fontFamily: 'sans-serif', fontStyle: 'bold', fontSize: '1.8rem', fontWeight: 1200, WebkitTextStroke: '1px black' }}>Analyst Note</Typography>
                                        </div>
                                        <div className="col-span-2">
                                            <TextareaAutosize
                                                id="AdditionalInfo"
                                                name="CorporateEmployment_EmploymentAnalystNote"
                                                placeholder="Analyst Note"
                                                value={form?.CorporateEmployment_EmploymentAnalystNote ?? ""}
                                                onChange={handleInputChange}
                                                style={{
                                                    width: '100%',
                                                    marginTop: '10px',
                                                    border: '1px solid rgba(0, 0, 0, 0.23)',
                                                    borderRadius: '4px',
                                                    padding: '16.5px 14px',
                                                    fontSize: '16px',
                                                    lineHeight: '1.4375em',
                                                    backgroundColor: 'inherit',
                                                    resize: 'vertical'
                                                }}
                                                minRows={3}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        {activeStep === 6 && (
                            <>
                                <div className="mt-4 w-4/5 border gap-4 mx-auto border-black p-4 bg-tsc-bg">
                                    <div className="grid grid-cols-2 gap-4 justify-center text-red">
                                        <div className="col-span-2">
                                            <Typography align="center" sx={{color: 'red', fontFamily: 'sans-serif', fontStyle: 'bold', fontSize: '2rem', fontWeight: 1200, WebkitTextStroke: '1px black'}}>Associates and Insurance Claims</Typography>
                                        </div>
                                        <div className="col-span-1">
                                            <Typography variant="h6">Known or Suspected Family/Associates</Typography>
                                        </div>
                                        <div className="col-span-1"></div>
                                        <div className="col-span-1">
                                            <ButtonGroup>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<AddIcon />}
                                                    onClick={() => addTableRow("AssociateList", "Origination", "Associates")}
                                                >
                                                    Add Associate
                                                </Button>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<ClearIcon />}
                                                    onClick={() => handleDialog("Associates", true)}
                                                >
                                                    Clear All Associates
                                                </Button>
                                                <Dialog 
                                                    open={associatesClearOpen}
                                                    onClose={() => handleDialog("Associates", false)}
                                                    aria-labelledby="clear-dialog"
                                                    aria-describedby="clear-dialog"
                                                >
                                                    <DialogTitle id="clear-dialog-title5">
                                                        {"Clear all existing associates?"}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="clear-associates">
                                                            Are you sure you would like to clear all associates in the list?
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={() => handleDialog("Associates", false)}>No</Button>
                                                        <Button onClick={() => {clearTableRows("AssociateList", "Origination", "Associates"); handleDialog("Associates", false)} } autoFocus>
                                                            Yes
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </ButtonGroup>
                                        </div>
                                        <div className="col-span-1"></div>
                                        {form?.AssociateList && form?.AssociateList?.length > 0 && (
                                            <div className="col-span-2">
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>First Name</TableCell>
                                                            <TableCell>Last Name</TableCell>
                                                            <TableCell>Association</TableCell>
                                                            <TableCell>Source</TableCell>
                                                            <TableCell>Actions</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {form?.AssociateList?.filter(f => !f?.deleted)?.map((row, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell>
                                                                <TextField
                                                                    name="FirstName"
                                                                    value={row?.FirstName ?? ""}
                                                                    multiline
                                                                    onChange={(e) => handleInputChange(e, "AssociateList", row)}
                                                                    inputProps={{
                                                                        style: {
                                                                            borderRadius: "1px",
                                                                            textAlign: 'center'
                                                                        }
                                                                    }}
                                                                    fullWidth
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    name="LastName"
                                                                    value={row?.LastName ?? ""}
                                                                    multiline
                                                                    onChange={(e) => handleInputChange(e, "AssociateList", row)}
                                                                    inputProps={{
                                                                        style: {
                                                                            borderRadius: "1px",
                                                                            textAlign: 'center'
                                                                        }
                                                                    }}
                                                                    fullWidth
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    name="Association"
                                                                    value={row?.Association ?? ""}
                                                                    multiline
                                                                    onChange={(e) => handleInputChange(e, "AssociateList", row)}
                                                                    inputProps={{
                                                                        style: {
                                                                            borderRadius: "1px",
                                                                            textAlign: 'center'
                                                                        }
                                                                    }}
                                                                    fullWidth
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    name="Source"
                                                                    value={row?.Source ?? ""}
                                                                    multiline
                                                                    onChange={(e) => handleInputChange(e, "AssociateList", row)}
                                                                    inputProps={{
                                                                        style: {
                                                                            borderRadius: "1px",
                                                                            textAlign: 'center'
                                                                        }
                                                                    }}
                                                                    fullWidth
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Tooltip title="Delete Association">
                                                                    <IconButton
                                                                        aria-label="delete"
                                                                        onClick={() => deleteTableRow("AssociateList", row)}
                                                                    >
                                                                        <DeleteIcon sx={{ color: "red" }} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                    </TableBody>
                                                </Table>
                                            </div>
                                        )}
                                        <div className="col-span-1">
                                            <Typography variant="h6">Insurance Claims</Typography>
                                        </div>
                                        <div className="col-span-1"></div>
                                        <div className="col-span-1">
                                            <ButtonGroup>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<AddIcon />}
                                                    onClick={() => addTableRow("InsuranceClaimList", "Origination", "Claims")}
                                                >
                                                    Add Claim
                                                </Button>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<ClearIcon />}
                                                    onClick={() => handleDialog("Claims", true)}
                                                >
                                                    Clear All Claims
                                                </Button>
                                                <Dialog 
                                                    open={claimsClearOpen}
                                                    onClose={() => handleDialog("Claims", false)}
                                                    aria-labelledby="clear-dialog"
                                                    aria-describedby="clear-dialog"
                                                >
                                                    <DialogTitle id="clear-dialog-title5">
                                                        {"Clear all existing claims?"}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="clear-associates">
                                                            Are you sure you would like to clear all claims in the list?
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={() => handleDialog("Claims", false)}>No</Button>
                                                        <Button onClick={() => {clearTableRows("InsuranceClaimList", "Origination", "Claims"); handleDialog("Claims", false)} } autoFocus>
                                                            Yes
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </ButtonGroup>
                                        </div>
                                        <div className="col-span-1"></div>

                                        {form?.InsuranceClaimList?.filter(p => !p.deleted).map((row, index) => (
                                            <>
                                                <div className="col-span-2 flex items-center justify-center">
                                                    <Typography variant="h6" align="center">Claim {index + 1}</Typography>
                                                    <Tooltip title="Delete Claim">
                                                        <IconButton
                                                            aria-label="delete"
                                                            onClick={() => deleteTableRow("InsuranceClaimList", row)}
                                                        >
                                                            <DeleteIcon sx={{ color: "red" }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                                <div className="col-span-1 pr-5">
                                                    <TextField
                                                        label="File Number"
                                                        name="FileNo"
                                                        fullWidth
                                                        value={row?.FileNo ?? ""}
                                                        onChange={(e) => handleInputChange(e, "InsuranceClaimList", row)}
                                                    />
                                                </div>
                                                <div className="col-span-1 pl-5">
                                                    <DatePicker
                                                        label="Date"
                                                        value={row?.Date ?? ""}
                                                        onChange={(date) => handleInputChange({ target: { name: "Date", value: (date ?? "") } }, "InsuranceClaimList", row)}
                                                        renderInput={(params) => <TextField {...params} error={row?.Date && row?.Date.trim().length > 1  ?  Date.parse(row?.Date) ? false : true : false} fullWidth />}
                                                    />
                                                </div>
                                                <div className="col-span-1 pr-5">
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">Activity:</FormLabel>
                                                        <FormControlLabel
                                                            control={<Checkbox checked={row?.Activity_Property ?? false}
                                                                onChange={(e) => handleCheckboxChange(e, "InsuranceClaimList", row)}
                                                                name="Activity_Property"
                                                            />}
                                                            label="Property"
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox checked={row?.Activity_Casualty ?? false}
                                                                onChange={(e) => handleCheckboxChange(e, "InsuranceClaimList", row)}
                                                                name="Activity_Casualty"
                                                            />}
                                                            label="Casualty"
                                                        />
                                                    </FormControl>
                                                </div>
                                                <div className="col-span-1 pl-5">
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">Loss Type:</FormLabel>
                                                        <FormControlLabel
                                                            control={<Checkbox checked={row?.LossType_Personal ?? false}
                                                                onChange={(e) => handleCheckboxChange(e, "InsuranceClaimList", row)}
                                                                name="LossType_Personal"
                                                            />}
                                                            label="Personal"
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox checked={row?.LossType_Automobile ?? false}
                                                                onChange={(e) => handleCheckboxChange(e, "InsuranceClaimList", row)}
                                                                name="LossType_Automobile"
                                                            />}
                                                            label="Automobile"
                                                        />
                                                    </FormControl>
                                                </div>
                                                <div className="col-span-2">
                                                    <TextareaAutosize
                                                        id="AdditionalInfo"
                                                        name="AdditionalInfo"
                                                        placeholder="Additional Information"
                                                        value={row?.AdditionalInfo ?? ""}
                                                        onChange={(e) => handleInputChange(e, "InsuranceClaimList", row)}
                                                        style={{
                                                            width: '100%',
                                                            marginTop: '10px',
                                                            border: '1px solid rgba(0, 0, 0, 0.23)',
                                                            borderRadius: '4px',
                                                            padding: '16.5px 14px',
                                                            fontSize: '16px',
                                                            lineHeight: '1.4375em',
                                                            backgroundColor: 'inherit',
                                                            resize: 'vertical'
                                                        }}
                                                        minRows={3}
                                                    />
                                                </div>
                                                <div className="col-span-2">
                                                    <TextField
                                                        label="Claimant and Insured"
                                                        name="ClaimantAndInsured"
                                                        fullWidth
                                                        value={row?.ClaimantAndInsured ?? ""}
                                                        onChange={(e) => handleInputChange(e, "InsuranceClaimList", row)}
                                                    />
                                                </div>
                                                <div className="col-span-2">
                                                    <Box display="flex" flexDirection="row" gap={2}>
                                                        <TextField
                                                            label="Street Address"
                                                            name="Street"
                                                            fullWidth
                                                            value={row?.Street ?? ""}
                                                            onChange={(e) => handleInputChange(e, "InsuranceClaimList", row)}
                                                        />
                                                        <TextField
                                                            label="City"
                                                            name="City"
                                                            fullWidth
                                                            value={row?.City ?? ""}
                                                            onChange={(e) => handleInputChange(e, "InsuranceClaimList", row)}
                                                        />
                                                        <Autocomplete
                                                            id="free-solo-demo"
                                                            autoComplete={true}
                                                            options={stateList.map((option: any) => option.Key)}
                                                            value={row?.State ?? ""}
                                                            onChange={(event, newValue) => handleInputChange({ target: { name: "State", value: newValue as string } }, "InsuranceClaimList", row)}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="State"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    style={{ width: 'auto' }}
                                                                />
                                                            )}
                                                        />
                                                        <TextField
                                                            label="ZIP"
                                                            name="ZIP"
                                                            value={row?.ZIP ?? ""}
                                                            fullWidth
                                                            onChange={(e) => handleInputChange(e, "InsuranceClaimList", row)}
                                                        />
                                                    </Box>
                                                </div>
                                                <div className="col-span-2">
                                                    <TextareaAutosize
                                                        id="TelephoneNumbers"
                                                        name="TelephoneNumbers"
                                                        placeholder="Telephone Number(s)"
                                                        value={row?.TelephoneNumbers ?? ""}
                                                        onChange={(e) => handleInputChange(e, "InsuranceClaimList", row)}
                                                        style={{
                                                            width: '100%',
                                                            marginTop: '10px',
                                                            border: '1px solid rgba(0, 0, 0, 0.23)',
                                                            borderRadius: '4px',
                                                            padding: '16.5px 14px',
                                                            fontSize: '16px',
                                                            lineHeight: '1.4375em',
                                                            backgroundColor: 'inherit',
                                                            resize: 'vertical'
                                                        }}
                                                        minRows={3}
                                                    />
                                                </div>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            </>
                        )}
                        {activeStep === 7 && (
                            <>
                                <div className="mt-4 w-4/5 border gap-4 mx-auto border-black p-4 bg-tsc-bg">
                                    <div className="grid grid-cols-2 gap-4 justify-center text-red">
                                        <div className="col-span-2">
                                            <Typography align="center" sx={{color: 'red', fontFamily: 'sans-serif', fontStyle: 'bold', fontSize: '2rem', fontWeight: 1200, WebkitTextStroke: '1px black'}}>Financial</Typography>
                                        </div>
                                        <div className="col-span-1">
                                            <Typography variant="h6">Secured Transaction Registry (Uniform Commercial Code Search)</Typography>
                                        </div>
                                        <div className="col-span-1"></div>
                                        <div className="col-span-1">
                                            <ButtonGroup>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<AddIcon />}
                                                    onClick={() => addTableRow("FinancialObjectList", "Origination", "Registry")}
                                                >
                                                    Add Registry
                                                </Button>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<ClearIcon />}
                                                    onClick={() => handleDialog("Registry", true)}
                                                >
                                                    Clear All Registries
                                                </Button>
                                                <Dialog 
                                                    open={registryClearOpen}
                                                    onClose={() => handleDialog("Registry", false)}
                                                    aria-labelledby="clear-dialog"
                                                    aria-describedby="clear-dialog"
                                                >
                                                    <DialogTitle id="clear-dialog-title5">
                                                        {"Clear all existing secured transaction registries??"}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="clear-historical-addresses">
                                                            Are you sure you would like to clear all secured transaction registries in the list?
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={() => handleDialog("Registry", false)}>No</Button>
                                                        <Button onClick={() => {clearTableRows("FinancialObjectList", "Origination", "Registry"); handleDialog("Registry", false)} } autoFocus>
                                                            Yes
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </ButtonGroup>
                                        </div>
                                        <div className="col-span-1"></div>
                                        {form?.FinancialObjectList && form?.FinancialObjectList?.filter(f => !f?.deleted && f.Origination === "Registry").length > 0 && (
                                            <>
                                                <div className="col-span-2">
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Document Number</TableCell>
                                                                <TableCell>Type</TableCell>
                                                                <TableCell>Secured Party</TableCell>
                                                                <TableCell>Debtor Party</TableCell>
                                                                <TableCell>Date Filed</TableCell>
                                                                <TableCell>Expires</TableCell>
                                                                <TableCell>Status</TableCell>
                                                                <TableCell>Actions</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                                {form?.FinancialObjectList?.filter(f => !f?.deleted && f.Origination === "Registry")?.map((row, index) => (
                                                                <TableRow key={index}>
                                                                    <TableCell>
                                                                        <TextField
                                                                            name="DocumentNo"
                                                                            value={row?.DocumentNo ?? ""}
                                                                            multiline
                                                                            onChange={(e) => handleInputChange(e, "FinancialObjectList", row)}
                                                                            inputProps={{
                                                                                style: {
                                                                                    borderRadius: "1px",
                                                                                    textAlign: 'center'
                                                                                }
                                                                            }}
                                                                            fullWidth
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <TextField
                                                                            name="Type"
                                                                            value={row?.Type ?? ""}
                                                                            multiline
                                                                            onChange={(e) => handleInputChange(e, "FinancialObjectList", row)}
                                                                            inputProps={{
                                                                                style: {
                                                                                    borderRadius: "1px",
                                                                                    textAlign: 'center'
                                                                                }
                                                                            }}
                                                                            fullWidth
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <TextField
                                                                            name="SecuredParty"
                                                                            value={row?.SecuredParty ?? ""}
                                                                            multiline
                                                                            onChange={(e) => handleInputChange(e, "FinancialObjectList", row)}
                                                                            inputProps={{
                                                                                style: {
                                                                                    borderRadius: "1px",
                                                                                    textAlign: 'center'
                                                                                }
                                                                            }}
                                                                            fullWidth
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <TextField
                                                                            name="DebtorParty"
                                                                            value={row?.DebtorParty ?? ""}
                                                                            multiline
                                                                            onChange={(e) => handleInputChange(e, "FinancialObjectList", row)}
                                                                            inputProps={{
                                                                                style: {
                                                                                    borderRadius: "1px",
                                                                                    textAlign: 'center'
                                                                                }
                                                                            }}
                                                                            fullWidth
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <DatePicker
                                                                            value={row?.DateFiled ?? ""}
                                                                            onChange={(date) => handleInputChange({ target: { name: "DateFiled", value: (date ?? "") } }, "FinancialObjectList", row)}
                                                                            renderInput={(params) => <TextField {...params} error={row?.DateFiled && row?.DateFiled.trim().length > 1  ?  Date.parse(row?.DateFiled) ? false : true : false} fullWidth />}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <DatePicker
                                                                            value={row?.Expires ?? ""}
                                                                            onChange={(date) => handleInputChange({ target: { name: "Expires", value: (date ?? "") } }, "FinancialObjectList", row)}
                                                                            renderInput={(params) => <TextField {...params} error={row?.Expires && row?.Expires.trim().length > 1  ?  Date.parse(row?.Expires) ? false : true : false} fullWidth />}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <TextField
                                                                            multiline
                                                                            name="Status"
                                                                            value={row?.Status ?? ""}
                                                                                onChange={(e) => handleInputChange(e, "FinancialObjectList", row)}
                                                                            inputProps={{
                                                                                style: {
                                                                                    borderRadius: "1px",
                                                                                    textAlign: 'center',
                                                                                    wordBreak: 'break-word',
                                                                                    whiteSpace: 'pre-wrap',
                                                                                    overflowWrap: 'break-word',
                                                                                }
                                                                            }}
                                                                            fullWidth
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Tooltip title="Delete Secured Transaction Registry">
                                                                            <IconButton
                                                                                aria-label="delete"
                                                                                onClick={() => deleteTableRow("FinancialObjectList", row)}
                                                                            >
                                                                                <DeleteIcon sx={{ color: "red" }} />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </div>
                                                <div className="col-span-2">
                                                    <TextareaAutosize
                                                            id="SecuredTransaction_Source"
                                                            name="SecuredTransaction_Source"
                                                            placeholder="Source"
                                                            value={form?.SecuredTransaction_Source ?? ""}
                                                            onChange={handleInputChange}
                                                            style={{
                                                                width: '100%',
                                                                marginTop: '10px',
                                                                border: '1px solid rgba(0, 0, 0, 0.23)',
                                                                borderRadius: '4px',
                                                                padding: '16.5px 14px',
                                                                fontSize: '16px',
                                                                lineHeight: '1.4375em',
                                                                backgroundColor: 'inherit',
                                                                resize: 'vertical'
                                                            }}
                                                            minRows={3}
                                                        />
                                                </div>
                                                <div className="col-span-2">
                                                    <Typography align="center" sx={{ color: 'red', fontFamily: 'sans-serif', fontStyle: 'bold', fontSize: '1.8rem', fontWeight: 1200, WebkitTextStroke: '1px black' }}>Analyst Note</Typography>
                                                </div>
                                                <div className="col-span-2">
                                                    <TextareaAutosize
                                                        id="SecuredTransaction_AnalystNote"
                                                        name="SecuredTransaction_AnalystNote"
                                                        placeholder="Analyst Note"
                                                        value={form?.SecuredTransaction_AnalystNote ?? ""}
                                                        onChange={handleInputChange}
                                                        style={{
                                                            width: '100%',
                                                            marginTop: '10px',
                                                            border: '1px solid rgba(0, 0, 0, 0.23)',
                                                            borderRadius: '4px',
                                                            padding: '16.5px 14px',
                                                            fontSize: '16px',
                                                            lineHeight: '1.4375em',
                                                            backgroundColor: 'inherit',
                                                            resize: 'vertical'
                                                        }}
                                                        minRows={3}
                                                    />
                                                </div>
                                            </>
                                        )}
                                        <div className="col-span-1">
                                            <Typography variant="h6">Unconfirmed Uniform Commercial Code Filings</Typography>
                                        </div>
                                        <div className="col-span-1"></div>
                                        <div className="col-span-1">
                                            <ButtonGroup>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<AddIcon />}
                                                    onClick={() => addTableRow("FinancialObjectList", "Origination", "Uniform")}
                                                >
                                                    Add Filing
                                                </Button>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<ClearIcon />}
                                                    onClick={() => handleDialog("Uniform", true)}
                                                >
                                                    Clear All Filings
                                                </Button>
                                                <Dialog
                                                    open={uniformClearOpen}
                                                    onClose={() => handleDialog("Uniform", false)}
                                                    aria-labelledby="clear-dialog"
                                                    aria-describedby="clear-dialog"
                                                >
                                                    <DialogTitle id="clear-dialog-title5">
                                                        {"Clear all uniform commercial code filings?"}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="clear-historical-addresses">
                                                            Are you sure you would like to clear all uniform commercial code filings in the list?
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={() => handleDialog("Uniform", false)}>No</Button>
                                                        <Button onClick={() => { clearTableRows("FinancialObjectList", "Origination", "Uniform"); handleDialog("Uniform", false) }} autoFocus>
                                                            Yes
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </ButtonGroup>
                                        </div>
                                        <div className="col-span-1"></div>
                                        {form?.FinancialObjectList && form?.FinancialObjectList?.filter(f => !f?.deleted && f.Origination === "Uniform").length > 0 && (
                                            <>
                                                <div className="col-span-2">
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Document Number</TableCell>
                                                                <TableCell>Legal Type</TableCell>
                                                                <TableCell>Secured Party</TableCell>
                                                                <TableCell>Debtor Party</TableCell>
                                                                <TableCell>Date</TableCell>
                                                                <TableCell>Filing</TableCell>
                                                                <TableCell>Actions</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {form?.FinancialObjectList?.filter(f => !f?.deleted && f.Origination === "Uniform")?.map((row, index) => (
                                                                <TableRow key={index}>
                                                                    <TableCell>
                                                                        <TextField
                                                                            name="DocumentNo"
                                                                            value={row?.DocumentNo ?? ""}
                                                                            multiline
                                                                            onChange={(e) => handleInputChange(e, "FinancialObjectList", row)}
                                                                            inputProps={{
                                                                                style: {
                                                                                    borderRadius: "1px",
                                                                                    textAlign: 'center'
                                                                                }
                                                                            }}
                                                                            fullWidth
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <TextField
                                                                            name="Type"
                                                                            value={row?.Type ?? ""}
                                                                            multiline
                                                                            onChange={(e) => handleInputChange(e, "FinancialObjectList", row)}
                                                                            inputProps={{
                                                                                style: {
                                                                                    borderRadius: "1px",
                                                                                    textAlign: 'center'
                                                                                }
                                                                            }}
                                                                            fullWidth
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <TextField
                                                                            name="SecuredParty"
                                                                            value={row?.SecuredParty ?? ""}
                                                                            multiline
                                                                            onChange={(e) => handleInputChange(e, "FinancialObjectList", row)}
                                                                            inputProps={{
                                                                                style: {
                                                                                    borderRadius: "1px",
                                                                                    textAlign: 'center'
                                                                                }
                                                                            }}
                                                                            fullWidth
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <TextField
                                                                            name="DebtorParty"
                                                                            value={row?.DebtorParty ?? ""}
                                                                            multiline
                                                                            onChange={(e) => handleInputChange(e, "FinancialObjectList", row)}
                                                                            inputProps={{
                                                                                style: {
                                                                                    borderRadius: "1px",
                                                                                    textAlign: 'center'
                                                                                }
                                                                            }}
                                                                            fullWidth
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <DatePicker
                                                                            value={row?.DateFiled ?? ""}
                                                                            onChange={(date) => handleInputChange({ target: { name: "DateFiled", value: (date ?? "") } }, "FinancialObjectList", row)}
                                                                            renderInput={(params) => <TextField {...params} error={row?.DateFiled && row?.DateFiled.trim().length > 1  ?  Date.parse(row?.DateFiled) ? false : true : false} fullWidth />}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <TextField
                                                                            multiline
                                                                            name="Filing"
                                                                            value={row?.Filing ?? ""}
                                                                            onChange={(e) => handleInputChange(e, "FinancialObjectList", row)}
                                                                            inputProps={{
                                                                                style: {
                                                                                    borderRadius: "1px",
                                                                                    textAlign: 'center',
                                                                                    wordBreak: 'break-word',
                                                                                    whiteSpace: 'pre-wrap',
                                                                                    overflowWrap: 'break-word',
                                                                                }
                                                                            }}
                                                                            fullWidth
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Tooltip title="Delete Uniform Commercial Code Filing">
                                                                            <IconButton
                                                                                aria-label="delete"
                                                                                onClick={() => deleteTableRow("FinancialObjectList", row)}
                                                                            >
                                                                                <DeleteIcon sx={{ color: "red" }} />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </div>
                                                <div className="col-span-2">
                                                    <TextareaAutosize
                                                        id="Uniform_Source"
                                                        name="Uniform_Source"
                                                        placeholder="Source"
                                                        value={form?.Uniform_Source ?? ""}
                                                        onChange={handleInputChange}
                                                        style={{
                                                            width: '100%',
                                                            marginTop: '10px',
                                                            border: '1px solid rgba(0, 0, 0, 0.23)',
                                                            borderRadius: '4px',
                                                            padding: '16.5px 14px',
                                                            fontSize: '16px',
                                                            lineHeight: '1.4375em',
                                                            backgroundColor: 'inherit',
                                                            resize: 'vertical'
                                                        }}
                                                        minRows={3}
                                                    />
                                                </div>
                                                <div className="col-span-2">
                                                    <Typography align="center" sx={{ color: 'red', fontFamily: 'sans-serif', fontStyle: 'bold', fontSize: '1.8rem', fontWeight: 1200, WebkitTextStroke: '1px black' }}>Analyst Note</Typography>
                                                </div>
                                                <div className="col-span-2">
                                                    <TextareaAutosize
                                                        id="Uniform_AnalystNote"
                                                        name="Uniform_AnalystNote"
                                                        placeholder="Analyst Note"
                                                        value={form?.Uniform_AnalystNote ?? ""}
                                                        onChange={handleInputChange}
                                                        style={{
                                                            width: '100%',
                                                            marginTop: '10px',
                                                            border: '1px solid rgba(0, 0, 0, 0.23)',
                                                            borderRadius: '4px',
                                                            padding: '16.5px 14px',
                                                            fontSize: '16px',
                                                            lineHeight: '1.4375em',
                                                            backgroundColor: 'inherit',
                                                            resize: 'vertical'
                                                        }}
                                                        minRows={3}
                                                    />
                                                </div>
                                            </>
                                        )}
                                        <div className="col-span-1">
                                            <Typography variant="h6">Unconfirmed Bankruptcy</Typography>
                                        </div>
                                        <div className="col-span-1"></div>
                                        <div className="col-span-1">
                                            <ButtonGroup>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<AddIcon />}
                                                    onClick={() => addTableRow("FinancialObjectList", "Origination", "Bankruptcy")}
                                                >
                                                    Add Bankruptcy
                                                </Button>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<ClearIcon />}
                                                    onClick={() => handleDialog("Bankruptcy", true)}
                                                >
                                                    Clear All Bankruptcies
                                                </Button>
                                                <Dialog
                                                    open={bankruptcyClearOpen}
                                                    onClose={() => handleDialog("Bankruptcy", false)}
                                                    aria-labelledby="clear-dialog"
                                                    aria-describedby="clear-dialog"
                                                >
                                                    <DialogTitle id="clear-dialog-title5">
                                                        {"Clear all bankruptcies?"}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="clear-historical-addresses">
                                                            Are you sure you would like to clear all bankruptcies in the list?
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={() => handleDialog("Bankruptcy", false)}>No</Button>
                                                        <Button onClick={() => { clearTableRows("FinancialObjectList", "Origination", "Bankruptcy"); handleDialog("Bankruptcy", false) }} autoFocus>
                                                            Yes
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </ButtonGroup>
                                        </div>
                                        <div className="col-span-1"></div>
                                        {form?.FinancialObjectList && form?.FinancialObjectList?.filter(f => !f?.deleted && f.Origination === "Bankruptcy").length > 0 && (
                                            <>
                                                <div className="col-span-2">
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Filing Number</TableCell>
                                                                <TableCell>Status</TableCell>
                                                                <TableCell>Trustee</TableCell>
                                                                <TableCell>Debtor</TableCell>
                                                                <TableCell>Date Filed</TableCell>
                                                                <TableCell>Filing</TableCell>
                                                                <TableCell>Disposition</TableCell>
                                                                <TableCell>Actions</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {form?.FinancialObjectList?.filter(f => !f?.deleted && f.Origination === "Bankruptcy")?.map((row, index) => (
                                                                <TableRow key={index}>
                                                                    <TableCell>
                                                                        <TextField
                                                                            name="DocumentNo"
                                                                            value={row?.DocumentNo ?? ""}
                                                                            multiline
                                                                            onChange={(e) => handleInputChange(e, "FinancialObjectList", row)}
                                                                            inputProps={{
                                                                                style: {
                                                                                    borderRadius: "1px",
                                                                                    textAlign: 'center'
                                                                                }
                                                                            }}
                                                                            fullWidth
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <TextField
                                                                            name="Status"
                                                                            value={row?.Status ?? ""}
                                                                            multiline
                                                                            onChange={(e) => handleInputChange(e, "FinancialObjectList", row)}
                                                                            inputProps={{
                                                                                style: {
                                                                                    borderRadius: "1px",
                                                                                    textAlign: 'center'
                                                                                }
                                                                            }}
                                                                            fullWidth
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <TextField
                                                                            name="Trustee"
                                                                            value={row?.Trustee ?? ""}
                                                                            multiline
                                                                            onChange={(e) => handleInputChange(e, "FinancialObjectList", row)}
                                                                            inputProps={{
                                                                                style: {
                                                                                    borderRadius: "1px",
                                                                                    textAlign: 'center'
                                                                                }
                                                                            }}
                                                                            fullWidth
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <TextField
                                                                            name="DebtorParty"
                                                                            value={row?.DebtorParty ?? ""}
                                                                            multiline
                                                                            onChange={(e) => handleInputChange(e, "FinancialObjectList", row)}
                                                                            inputProps={{
                                                                                style: {
                                                                                    borderRadius: "1px",
                                                                                    textAlign: 'center'
                                                                                }
                                                                            }}
                                                                            fullWidth
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <DatePicker
                                                                            value={row?.DateFiled ?? ""}
                                                                            onChange={(date) => handleInputChange({ target: { name: "DateFiled", value: (date ?? "") } }, "FinancialObjectList", row)}
                                                                            renderInput={(params) => <TextField {...params} error={row?.DateFiled && row?.DateFiled.trim().length > 1  ?  Date.parse(row?.DateFiled) ? false : true : false} fullWidth />}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <TextField
                                                                            multiline
                                                                            name="Filing"
                                                                            value={row?.Filing ?? ""}
                                                                            onChange={(e) => handleInputChange(e, "FinancialObjectList", row)}
                                                                            inputProps={{
                                                                                style: {
                                                                                    borderRadius: "1px",
                                                                                    textAlign: 'center',
                                                                                    wordBreak: 'break-word',
                                                                                    whiteSpace: 'pre-wrap',
                                                                                    overflowWrap: 'break-word',
                                                                                }
                                                                            }}
                                                                            fullWidth
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <TextField
                                                                            multiline
                                                                            name="Disposition"
                                                                            value={row?.Disposition ?? ""}
                                                                            onChange={(e) => handleInputChange(e, "FinancialObjectList", row)}
                                                                            inputProps={{
                                                                                style: {
                                                                                    borderRadius: "1px",
                                                                                    textAlign: 'center',
                                                                                    wordBreak: 'break-word',
                                                                                    whiteSpace: 'pre-wrap',
                                                                                    overflowWrap: 'break-word',
                                                                                }
                                                                            }}
                                                                            fullWidth
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Tooltip title="Delete Bankruptcy">
                                                                            <IconButton
                                                                                aria-label="delete"
                                                                                onClick={() => deleteTableRow("FinancialObjectList", row)}
                                                                            >
                                                                                <DeleteIcon sx={{ color: "red" }} />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </div>
                                                <div className="col-span-2">
                                                    <TextareaAutosize
                                                        id="Bankruptcy_Source"
                                                        name="Bankruptcy_Source"
                                                        placeholder="Source"
                                                        value={form?.Bankruptcy_Source ?? ""}
                                                        onChange={handleInputChange}
                                                        style={{
                                                            width: '100%',
                                                            marginTop: '10px',
                                                            border: '1px solid rgba(0, 0, 0, 0.23)',
                                                            borderRadius: '4px',
                                                            padding: '16.5px 14px',
                                                            fontSize: '16px',
                                                            lineHeight: '1.4375em',
                                                            backgroundColor: 'inherit',
                                                            resize: 'vertical'
                                                        }}
                                                        minRows={3}
                                                    />
                                                </div>
                                                <div className="col-span-2">
                                                    <Typography align="center" sx={{ color: 'red', fontFamily: 'sans-serif', fontStyle: 'bold', fontSize: '1.8rem', fontWeight: 1200, WebkitTextStroke: '1px black' }}>Analyst Note</Typography>
                                                </div>
                                                <div className="col-span-2">
                                                    <TextareaAutosize
                                                        id="Bankruptcy_AnalystNote"
                                                        name="Bankruptcy_AnalystNote"
                                                        placeholder="Analyst Note"
                                                        value={form?.Bankruptcy_AnalystNote ?? ""}
                                                        onChange={handleInputChange}
                                                        style={{
                                                            width: '100%',
                                                            marginTop: '10px',
                                                            border: '1px solid rgba(0, 0, 0, 0.23)',
                                                            borderRadius: '4px',
                                                            padding: '16.5px 14px',
                                                            fontSize: '16px',
                                                            lineHeight: '1.4375em',
                                                            backgroundColor: 'inherit',
                                                            resize: 'vertical'
                                                        }}
                                                        minRows={3}
                                                    />
                                                </div>
                                            </>
                                        )}
                                        <div className="col-span-1">
                                            <Typography variant="h6">Unconfirmed Tax Liens and Judgments</Typography>
                                        </div>
                                        <div className="col-span-1"></div>
                                        <div className="col-span-1">
                                            <ButtonGroup>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<AddIcon />}
                                                    onClick={() => addTableRow("FinancialObjectList", "Origination", "Tax")}
                                                >
                                                    Add Tax Lien
                                                </Button>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<ClearIcon />}
                                                    onClick={() => handleDialog("Tax", true)}
                                                >
                                                    Clear All Tax Liens
                                                </Button>
                                                <Dialog
                                                    open={taxClearOpen}
                                                    onClose={() => handleDialog("Tax", false)}
                                                    aria-labelledby="clear-dialog"
                                                    aria-describedby="clear-dialog"
                                                >
                                                    <DialogTitle id="clear-dialog-title5">
                                                        {"Clear all tax liens?"}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="clear-historical-addresses">
                                                            Are you sure you would like to clear all tax liens in the list?
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={() => handleDialog("Tax", false)}>No</Button>
                                                        <Button onClick={() => { clearTableRows("FinancialObjectList", "Origination", "Tax"); handleDialog("Tax", false) }} autoFocus>
                                                            Yes
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </ButtonGroup>
                                        </div>
                                        <div className="col-span-1"></div>
                                        {form?.FinancialObjectList && form?.FinancialObjectList?.filter(f => !f?.deleted && f.Origination === "Tax").length > 0 && (
                                            <>
                                                <div className="col-span-2">
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Case Number</TableCell>
                                                                <TableCell>Type</TableCell>
                                                                <TableCell>Debtor</TableCell>
                                                                <TableCell>Date Filed</TableCell>
                                                                <TableCell>Filing State</TableCell>
                                                                <TableCell>Actions</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {form?.FinancialObjectList?.filter(f => !f?.deleted && f.Origination === "Tax")?.map((row, index) => (
                                                                <TableRow key={index}>
                                                                    <TableCell>
                                                                        <TextField
                                                                            name="DocumentNo"
                                                                            value={row?.DocumentNo ?? ""}
                                                                            multiline
                                                                            onChange={(e) => handleInputChange(e, "FinancialObjectList", row)}
                                                                            inputProps={{
                                                                                style: {
                                                                                    borderRadius: "1px",
                                                                                    textAlign: 'center'
                                                                                }
                                                                            }}
                                                                            fullWidth
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <TextField
                                                                            name="Type"
                                                                            value={row?.Type ?? ""}
                                                                            multiline
                                                                            onChange={(e) => handleInputChange(e, "FinancialObjectList", row)}
                                                                            inputProps={{
                                                                                style: {
                                                                                    borderRadius: "1px",
                                                                                    textAlign: 'center'
                                                                                }
                                                                            }}
                                                                            fullWidth
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <TextField
                                                                            name="DebtorParty"
                                                                            value={row?.DebtorParty ?? ""}
                                                                            multiline
                                                                            onChange={(e) => handleInputChange(e, "FinancialObjectList", row)}
                                                                            inputProps={{
                                                                                style: {
                                                                                    borderRadius: "1px",
                                                                                    textAlign: 'center'
                                                                                }
                                                                            }}
                                                                            fullWidth
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <DatePicker
                                                                            value={row?.DateFiled ?? ""}
                                                                            onChange={(date) => handleInputChange({ target: { name: "DateFiled", value: (date ?? "") } }, "FinancialObjectList", row)}
                                                                            renderInput={(params) => <TextField {...params} error={row?.DateFiled && row?.DateFiled.trim().length > 1  ?  Date.parse(row?.DateFiled) ? false : true : false} fullWidth />}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <TextField
                                                                            multiline
                                                                            name="Filing"
                                                                            value={row?.Filing ?? ""}
                                                                            onChange={(e) => handleInputChange(e, "FinancialObjectList", row)}
                                                                            inputProps={{
                                                                                style: {
                                                                                    borderRadius: "1px",
                                                                                    textAlign: 'center',
                                                                                    wordBreak: 'break-word',
                                                                                    whiteSpace: 'pre-wrap',
                                                                                    overflowWrap: 'break-word',
                                                                                }
                                                                            }}
                                                                            fullWidth
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Tooltip title="Delete Tax Lien">
                                                                            <IconButton
                                                                                aria-label="delete"
                                                                                onClick={() => deleteTableRow("FinancialObjectList", row)}
                                                                            >
                                                                                <DeleteIcon sx={{ color: "red" }} />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </div>
                                                <div className="col-span-2">
                                                    <TextareaAutosize
                                                        id="Tax_Source"
                                                        name="Tax_Source"
                                                        placeholder="Source"
                                                        value={form?.Tax_Source ?? ""}
                                                        onChange={handleInputChange}
                                                        style={{
                                                            width: '100%',
                                                            marginTop: '10px',
                                                            border: '1px solid rgba(0, 0, 0, 0.23)',
                                                            borderRadius: '4px',
                                                            padding: '16.5px 14px',
                                                            fontSize: '16px',
                                                            lineHeight: '1.4375em',
                                                            backgroundColor: 'inherit',
                                                            resize: 'vertical'
                                                        }}
                                                        minRows={3}
                                                    />
                                                </div>
                                                <div className="col-span-2">
                                                    <Typography align="center" sx={{ color: 'red', fontFamily: 'sans-serif', fontStyle: 'bold', fontSize: '1.8rem', fontWeight: 1200, WebkitTextStroke: '1px black' }}>Analyst Note</Typography>
                                                </div>
                                                <div className="col-span-2">
                                                    <TextareaAutosize
                                                        id="Tax_AnalystNote"
                                                        name="Tax_AnalystNote"
                                                        placeholder="Analyst Note"
                                                        value={form?.Tax_AnalystNote ?? ""}
                                                        onChange={handleInputChange}
                                                        style={{
                                                            width: '100%',
                                                            marginTop: '10px',
                                                            border: '1px solid rgba(0, 0, 0, 0.23)',
                                                            borderRadius: '4px',
                                                            padding: '16.5px 14px',
                                                            fontSize: '16px',
                                                            lineHeight: '1.4375em',
                                                            backgroundColor: 'inherit',
                                                            resize: 'vertical'
                                                        }}
                                                        minRows={3}
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="flex flex-row pt-2">
                            <Button
                                variant="contained"
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                className="pl-4 ml-4"
                            >
                                Back
                            </Button>
                            <div className="flex-auto" />
                            {isStepOptional(activeStep) && (
                                <Button color="inherit" onClick={handleSkip} className="mr-1">
                                    Skip
                                </Button>
                            )}
                            <Button className="pr-4 mr-4" variant="contained" onClick={handleNext}>
                                {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                            </Button>
                        </div>
                    </React.Fragment>)}
            </div>
        </LocalizationProvider>
    )
}

export default TSCInputForm;