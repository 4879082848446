import React, {useState} from "react";
import {Account} from "../../../interfaces/auth_interface";
import {getUser, isUserPermission} from "../../../services/auth.service";
import DashboardLayout from "../../../components/Dashboard/NJPOP/_dashboardLayout"
import {RunGetDailyStats} from "../../../services/dashboard.service";
import {getDarInfoResponse} from "../../../interfaces/getDarInfo_interface";

function Dashboard() {
    let [user, setUser] = useState<Account>(getUser())
    const [data, setData] = useState<any>({})

    const GetStats = async ( params:any) => {
        let qRes:getDarInfoResponse = await RunGetDailyStats(params)
        
        setData(qRes)
    }
    
    
    return (
        <div>
            {isUserPermission('Arrest')?
                <>
                    <DashboardLayout GetStats={GetStats} data={data}/>
                </>
            :<></>}
        </div>
    );
}

export default Dashboard;