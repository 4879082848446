import React, {useState} from "react";

interface DashboardDataGridProps {
    location:string,
    data:any
}

const DashboardMurderDataGrid: React.FC<DashboardDataGridProps> = ({location, data}) => {
    const [percentChange, setPercentChange] = React.useState<number>(0)
    const [changeType, setChangeType] = React.useState<string>('')

    React.useEffect(() => {
        let change:any = 0
        if(data.YTDMurderData)
        {
            let decrease = data.YTDMurderData[1]?.YTDCount - data.YTDMurderData[0]?.YTDCount
            change = (decrease / data.YTDMurderData[1]?.YTDCount) * 100
            if(Math.sign(change) === -1)
            {
                setChangeType('Increase')
                change = (change * -1)
                setPercentChange(change)
            }
            else
            {
                setChangeType('Decrease')
            }
            change = (Math.round(change * 100) / 100)
        }
        setPercentChange(change)
    }, [data]);

    return (
        <div className = "border-gray-900 border-2">
            <div className="bg-blue-900 font-bold text-yellow-400 text-center text-medium">
                Shooting Murder Victims
                <div className = "text-center font-semibold text-xs" >{location}</div>
            </div>
            <div className = "border-gray-900 border-t-2">
                {data.YTDMurderData ? data.YTDMurderData.map((data:any) => (
                    <div>
                        <div className = "text-center font-bold">{data.Year}:{data.YTDCount}</div>
                    </div>
                )) : <></>}
                {percentChange ?
                    <div className = {changeType === 'Increase' ? "text-center font-bold text-sm text-red-600" : "text-center font-bold text-sm text-green-500"}>%{changeType} : {percentChange ? percentChange : 0}%</div>
                    :
                    <></>
                }
            </div>

        </div>

    );
}

export default DashboardMurderDataGrid;