import React, { useState, useEffect } from "react";
import { TextField, Checkbox, Button, ToggleButtonGroup, ToggleButton, Card, CardContent, TableContainer, Table, TableBody, TableRow, TableCell, Paper } from "@mui/material";
import PrisonParoleDetail from "../../interfaces/Detail/PrisonParoleDetail.interface"
import { RunPrisonParoleDetailQuery } from "../../services/detail.service";
import { formatDate } from "../../services/formatDate.service"

import './Detail.css'

interface DetailProps {
    ID: string | null
    department: string | null
}


const PrisonParoleDetailInfo: React.FC<DetailProps> = ({ ID, department }) => {
    const [DataLoaded, setDataLoaded] = React.useState<boolean>(false)
    const [Data, setData] = React.useState<PrisonParoleDetail>({})

    const loadData = async () => {
        
        if (!DataLoaded) {
            setDataLoaded(true)
            let res = await RunPrisonParoleDetailQuery(ID, department);
            setData(res)
        }
    }

    React.useEffect(() => {
        if (!DataLoaded) {
            setDataLoaded(true)
            loadData()
        }
    });

    return (
        <div className="bg-gray-100 ml-5 mr-5 rounded p-5 ">
            <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }} >
                <CardContent>
                    <h3 className="mb-4 font-bold font text-xl text-blue-400">Parole Information</h3>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">ID</span><span className ="DetailTableData">{Data?.Paroles?.ID}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">Begin Date</span><span className ="DetailTableData">{formatDate(Data?.Paroles?.Begin_Date)}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">End Date</span><span className ="DetailTableData">{formatDate(Data?.Paroles?.End_Date)}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">Statute</span><span className ="DetailTableData">{Data?.Paroles?.STATUTE}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">Statute Description</span><span className ="DetailTableData">{Data?.Paroles?.STATUTEDESCRIPTION}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">Status</span><span className ="DetailTableData">{Data?.Paroles?.Status}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">City</span><span className ="DetailTableData">{Data?.Paroles?.City}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">State</span><span className="DetailTableData">{Data?.Paroles?.State}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Officer Name</span><span className="DetailTableData">{Data?.Paroles?.PO_Assigned}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Officer Phone #</span><span className="DetailTableData">{Data?.Paroles?.PhoneNo}</span></div>

                </CardContent>
            </Card>

            <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                <CardContent>
                    <h3 className="mb-4 font-bold font text-xl text-blue-400">Person Information</h3>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">FBI</span><span className ="DetailTableData">{Data?.Paroles?.FBI}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">SBI</span><span className ="DetailTableData">{Data?.Paroles?.SBINo}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">First Name</span><span className ="DetailTableData">{Data?.Paroles?.FIRSTNAME}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">Middle Name</span><span className ="DetailTableData">{Data?.Paroles?.MIDDLENAME}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">Last Name</span><span className ="DetailTableData">{Data?.Paroles?.LASTNAME}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">DOB</span><span className ="DetailTableData">{formatDate(Data?.Paroles?.DOB)}</span></div>

                </CardContent>
            </Card>
        </div>
    );
}

export default PrisonParoleDetailInfo;