import React, { useState } from "react";
import ArrestAnalysisSearch from "../../components/tools/ArrestAnalysis/_ArrestAnalysisSearch";
import SearchResults from "../../components/tools/ArrestAnalysis/_searchResultsArrestAnalysis";
import SearchResultGraph from "../../components/tools/ArrestAnalysis/_searchResultsArrestAnalysisGraph";
import { GetDarInfo_Query, PersonInfo, getDarInfoResponse, getGraphResponse } from "../../interfaces/getDarInfo_interface";
import { RunDarQuery, RunGraphQuery } from "../../services/getDar.service";
import { Account } from "../../interfaces/auth_interface";
import {getUser, isUserPermission} from "../../services/auth.service";
import {handleSearchTime} from "../../services/formatDate.service";

function ArrestAnalysis() {
    let [analysis, setAnalysis] = useState<any[]>([])
    let [analysisColumn, setAnalysisColumn] = useState<any[]>([])
    let [analysisGraph, setAnalysisGraph] = useState<any>()
    let [user, setUser] = useState<Account>(getUser())

    const SubmitSearch = async (params: any, dateType: string, andOrType: string, dropDowns: any, top: number) => {
        let search_query: GetDarInfo_Query = {
            "QueryType": "4",
            "Dept": user.Dept,
            "EmpID": user.EmpID,
            "DeptORI": user.DeptORI,
            "DateRange": true,
            "DateTypeValue": (dateType === "Entry Date") ? 0 : ((dateType === "Arrest Date") ? 1 : 2),
            "FromDate": handleSearchTime(params?.fromDate, 'fromDate')?.toLocaleString(),
            "ToDate": handleSearchTime(params?.toDate, 'toDate')?.toLocaleString(),
            "SearchType": andOrType,
            "IndicatorOn": true,
            "TopArrestNumber": 0,
            "FNames": [],
            "MNames": [],
            "LNames": [],
            "DOBs": [],
            "SEX": [],
            "Address": "",
            "Cities": dropDowns.City ? dropDowns.City : [],
            "States": dropDowns.State ? dropDowns.State : [],
            "Zip": "",
            "Statutes": dropDowns.Statute ? dropDowns.Statute : [],
            "Gang": [],
            "FBIs": [],
            "SBIs": [],
            "DLNos": params.header ? params.header.split(',') : [],     //TopColumn      Header
            "PlateNos": params.detail ? params.detail.split(',') : [],  //LeftColumn     Detail
            "VINs": params.group ? params.group.split(',') : [],        //CenterColumn   Group
            "AgencyArrested": dropDowns.AgencyArrested ? dropDowns.AgencyArrested : [],
            "InfocopDept": [],
            "Indicators": [],
            "IndicatorOccurrence": null,
            "Latitude": null,
            "Longitude": null,
            "Radius": 0,
            "ResidentCounty": dropDowns.ResidentCounty ? dropDowns.ResidentCounty : [],
            "ArrestedCounty": dropDowns.ArrestedCounty ? dropDowns.ArrestedCounty : [],
            "Arrest_Type": [],
            "CaseNumbers": [],
            "FTSIDs": [],
            "SystemCall": params.systemCall ?? false,
        }
        let qRes: getDarInfoResponse = await RunDarQuery(search_query)
        setAnalysis([])
        setAnalysisColumn([])
        setAnalysisGraph([])
        setAnalysis(qRes.AnyTable)
        setAnalysisColumn(qRes.SecondaryTable)
        setAnalysisGraph(JSON.parse(qRes.JsonObject))

        //SubmitGraphSearch(params, dateType, andOrType, dropDowns, top);
    }

    return (
        <div>
            {isUserPermission('Arrest')?
                <>
                    <ArrestAnalysisSearch SubmitSearch={SubmitSearch} />            
                    <SearchResults AnalysisData={analysis} AnalysisColumn={analysisColumn} AnalysisGraph={analysisGraph} />
                </>
            :<></>}
        </div>
    );
}

export default ArrestAnalysis;